
import { Component, Vue } from 'vue-property-decorator'
import { codemirror } from 'vue-codemirror'

@Component({
  name: 'formly_markdown-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    codemirror,
  },
  data() {
    return {
      editorOptions: {
        // codemirror options
        tabSize: 4,
        mode: {
          name: 'markdown',
        },
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
      },
    }
  },
})
export default class MarkdownField extends Vue {}


import Vue from 'vue'
import Component from 'vue-class-component'

@Component({})
// Calling it NavFooter because 'footer' is reserved html tag
export default class NavFooter extends Vue {
  get backendVersion() {
    return this.$store.state.global.backendVersion
  }

  get isLoggedIn() {
    return this.$store.state.global.isLoggedIn
  }
}

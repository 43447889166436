import {
  TransientBaseObject,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

export class ImageObject extends TransientBaseObject {
  organisation: string
  title: string
  specs: {
    width: number
    height: number
    format: string
  }
  meta?: {}
  attachment: string
  url: string

  static apiUrl = 'brelag/image'
  static objectType: string = 'image'

  static get defaultModel() {
    return JSON.parse(JSON.stringify(IMAGE_DEFAULT))
  }
}

export const IMAGE_DEFAULT: ImageObject = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  title: '',
  specs: {
    width: 0,
    height: 0,
    format: '',
  },
  attachment: '',
  url: '',
}

<template>
  <router-view />
</template>

<script>
export default {
  name: 'RouterPassThrough',
}
</script>

<style scoped></style>

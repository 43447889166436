
import { Component, Vue, Watch } from 'vue-property-decorator'

import { ApiListSubscription, Collection } from '@/api/ApiClientV2'
import { BaseListColumn, TransientBaseObject } from '@/models/core/base'

import BaseListTable from './BaseListTable.vue'
import ParentSelectorsV2 from '@/components/common/lists/ParentSelectorsV2.vue'
import SearchFields from '@/components/common/lists/SearchFields.vue'
import ListPagination from './ListPagination.vue'

import { getClientAppOfRoute } from '@/apps/routingUtils'
import { clientAppRouteName } from '@/apps/clientAppRegistry'
import { objectFromMap } from '@/util/util'
import { getObjectTypesUp } from '@/models/objectRegistry'

@Component({
  components: {
    ParentSelectorsV2,
    SearchFields,
    ListPagination,
    BaseListTable,
  },
  props: {
    modelClass: {
      required: true,
    },
    customActions: {
      default: () => [],
    },
    hasActions: {
      default: true,
    },
    canCopy: {
      default: false,
    },
    canAdd: {
      default: true,
    },
    canDelete: {
      default: true,
    },
    canSelect: {
      default: false,
    },
    canReorder: {
      default: false,
    },
    canCopyData: {
      default: true,
    },
    singleSelection: {
      default: false,
    },
    preSelectedRows: {
      default: () => [],
    },
    unselectableRows: {
      default: () => [],
    },
    hasDetailView: {
      default: true,
    },
    detailExtraParams: {
      default: () => {
        return
      },
    },
    createRouteExtras: {
      default: () => {
        return
      },
    },
    detailRouteName: {
      default: '',
    },
    createRouteName: {
      default: '',
    },
    customDelete: {
      default: null,
    },
    customDetail: {
      default: null,
    },
    customColumns: {
      default: null,
    },
    loading: {
      default: false,
    },
    errorMessage: {
      default: () => {
        return {
          cantSelectRow: 'Row cannot be selected',
        }
      },
    },
    collectionFilter: {
      default: null,
    },
    filterPrefix: {
      default: '',
    },
    helpMessage: {
      default: null,
    },
    isStriped: {
      default: false,
    },
    detailed: {
      default: false,
    },
    showFilters: {
      default: true,
    },
  },
  data() {
    return {
      columns: this.$props.customColumns || this.$props.modelClass.columns(),
      columnsClone:
        this.$props.customColumns || this.$props.modelClass.columns(),
      objectCollection: this.$props.modelClass.collection(this, null, {
        page: 1,
        pageSize: 10,
      }),
      objectType: this.$props.modelClass.objectType,
    }
  },
})
export default class BaseListV2<T> extends Vue {
  objectCollection: ApiListSubscription<T>
  errorMessages: string[] = []
  error: boolean = false
  columns: BaseListColumn[] = []
  columnsClone: BaseListColumn[] = []
  objectType: string
  modelClass: typeof TransientBaseObject

  created() {
    this.beforeMountCheck()
    this.filterCollection()
  }

  pagination(): Collection.Pagination {
    return this.$routerHandler.pagination(this.prefix)
  }

  filter() {
    return this.$routerHandler.query(this.prefix)
  }

  async refreshCollection() {
    await this.objectCollection.refresh()
  }

  @Watch('$route.query')
  filterCollection() {
    const newFilter = this.filter()
    const newPagination = this.pagination()

    let combinedFilter = {
      ...this.$props.collectionFilter,
      ...newFilter,
    }

    if (!this.modelClass.isValidFilter(combinedFilter)) {
      // the id of a required filter parameter is not given,
      // e.g. model_id not given for classification filter
      // hence set filter to null
      combinedFilter = null
    }
    this.objectCollection.setFilterAndPagination(combinedFilter, newPagination)
  }

  get prefix() {
    return this.$props.filterPrefix !== '' ? `${this.$props.filterPrefix}~` : ''
  }

  @Watch('$props.collectionFilter', { deep: true })
  onFilter() {
    this.$routerHandler.setQuery(this.prefix, {
      ...this.$props.collectionFilter,
    })
  }

  /**
   * This function should check that the modelClass is properly configured with all required fields
   * so that the BaseList can work
   */
  beforeMountCheck() {
    let errors = []
    if (!this.$props.modelClass) {
      errors.push(`BaseListV2: The object class has not been defined.`)
    }
    if (!this.objectType) {
      // If the 'objectType' is not defined on the class, we can try and find the class name
      let className = ''
      try {
        // Try to create an instance of the class to get the name, if this fails, use constructor name
        const instance = new this.$props.modelClass()
        className = instance.constructor.name
      } catch (_) {
        className = this.$props.modelClass.constructor.name
      }
      errors.push(
        `BaseListV2: The object class ${className} has not defined its 'objectType'`
      )
    }
    if (this.objectType && !this.columns) {
      errors.push(
        `BaseList2: The object class '${this.objectType}' has not defined any columns!`
      )
    }
    if (this.objectType && !this.objectCollection) {
      errors.push(
        `BaseList2: The objectCollection for type '${this.objectType}' is null/undefined!`
      )
    }
    if (errors.length > 0) {
      this.$router.replace({
        name: 'error',
        params: {
          errors: JSON.stringify(errors),
        },
      })
    }
  }

  onPageChange(page) {
    this.$routerHandler.setPage(this.prefix, page)
  }

  onSort(field, order) {
    let sortField = field
    this.columnsClone.forEach((column: BaseListColumn) => {
      if (column.fieldName === field) {
        if (column.sortFieldName) {
          sortField = column.sortFieldName
        }
      }
    })

    const query = {
      ...this.$route.query,
      order_by: sortField + (order === 'desc' ? '_dsc' : ''),
    }
    this.$routerHandler.setQuery(this.prefix, query)
  }

  getCreateRouteName() {
    if (this.$props.createRouteName === '') {
      const clientApp = getClientAppOfRoute(this.$route)
      return clientApp
        ? clientAppRouteName(clientApp.view_id, this.objectType + '-create')
        : ''
    }
    return this.$props.createRouteName
  }

  addNew() {
    let createRouteExtras
    if (!this.$props.createRouteExtras) {
      // no explicit create route extras given. generate query ids from context
      // use ancestors objectTypes
      let ancestors = getObjectTypesUp(this.objectType)
      createRouteExtras = {
        query: objectFromMap(
          this.$store.getters['global/idsFromContext'](ancestors)
        ),
      }
      ancestors = [
        ...this.$props.modelClass.ancestors.map(
          (ancestor) => ancestor.modelClass.objectType
        ),
        ...ancestors,
      ]
      // remove duplicates
      ancestors = [...new Set(ancestors)]

      for (const ancestor of ancestors) {
        if (this.$route.query.hasOwnProperty(ancestor)) {
          createRouteExtras.query = {
            ...createRouteExtras.query,
            [ancestor]: this.$route.query[ancestor],
          }
        }
      }
    } else {
      createRouteExtras = {
        query: this.$props.createRouteExtras,
      }
    }
    return {
      name: this.getCreateRouteName(),
      ...createRouteExtras,
    }
  }

  get prettyName() {
    if (this.$props.modelClass.prettyName) {
      return this.$props.modelClass.prettyName()
    } else {
      return this.objectType.charAt(0).toUpperCase() + this.objectType.slice(1)
    }
  }

  onSelect(selection) {
    this.$emit('row-selection', selection)
  }

  clearErrors() {
    this.errorMessages = []
    this.error = false
  }

  handleError(error) {
    this.errorMessages = this.$errorHandler.errorToStrings(error)
    this.error = true
  }
}

import { Module } from 'vuex'
import { CollectionSubscriber } from '@/api/ApiClient'
import { Project, DeviceGroup } from '@/apps/brelag/common/models/project'
import {
  EquipmentGroup,
  EquipmentCategory,
} from '@/apps/brelag/common/models/equipment'
import {
  DeviceNode,
  DeviceTree,
  ProjectEditor,
} from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import { DeviceGroupNode } from '@/apps/brelag/mandator-user/project-editor/deviceGroupHandler'
import Vue from 'vue'
import { EditorConfig } from '@/apps/brelag/mandator-user/models/editor'
import { ApiListSubscription } from '@/api/ApiClientV2'
import store from '@/store'

export interface BrelagContext {
  albums: CollectionSubscriber
  images: CollectionSubscriber
  customers: CollectionSubscriber
  projects: CollectionSubscriber
  buildings: CollectionSubscriber
  floors: CollectionSubscriber
  equipment_groups: ApiListSubscription<EquipmentGroup>

  selection: {
    project: Project
    equipment_group: EquipmentGroup
  }
}

export class BrelagState {
  isBrelagAdmin: boolean = false
  isSupporter: boolean = false
  isMandatorAdmin: boolean = false
  context: BrelagContext = {
    albums: null,
    images: null,
    customers: null,
    projects: null,
    buildings: null,
    floors: null,
    equipment_groups: null,

    selection: {
      project: null,
      equipment_group: null,
    },
  }
  project: {
    readOnly: boolean
    isLockedByWinApp: boolean
    knockautEditorActive: boolean
    lockedBy: string
    unlockedBy: string
    editorConfigs: EditorConfig[]
    unlinkedTeachingOptions: EditorConfig[]

    selectedGroup: DeviceGroup
    selectedChannel: number
    selectedSenders: string[]
    selectedReceivers: string[]
    selectedLinkedReceivers: string[]
    selectedUnlinkedReceivers: string[]
    selectedAnnotations: string[]

    // SenderTree contains buildings and floors
    senderTree: DeviceTree[]

    // Receivers only care about their groups
    unlinkedReceivers: DeviceGroupNode[]
    linkedReceivers: DeviceGroupNode[]
    unlinkedDeviceGroupTree: DeviceGroupNode[]
    linkedDeviceGroupTree: DeviceGroupNode[]

    selectedReceiversInGroups: {
      linked: {
        deviceId: string
        groupId: string
      }[]
      unlinked: {
        deviceId: string
        groupId: string
      }[]
    }
  } = {
    readOnly: false,
    isLockedByWinApp: false,
    knockautEditorActive: false,
    lockedBy: null,
    unlockedBy: null,
    editorConfigs: [],
    unlinkedTeachingOptions: [],
    selectedGroup: null,
    selectedChannel: 0,
    selectedSenders: [],
    selectedReceivers: [],
    selectedLinkedReceivers: [],
    selectedUnlinkedReceivers: [],
    selectedAnnotations: [],

    senderTree: [],

    unlinkedReceivers: [],
    linkedReceivers: [],
    unlinkedDeviceGroupTree: [],
    linkedDeviceGroupTree: [],

    selectedReceiversInGroups: {
      linked: [],
      unlinked: [],
    },
  }
}

export class BrelagModule<R> implements Module<BrelagState, R> {
  namespaced = true
  state = new BrelagState()

  getters = {
    /* EDITOR */
    isBrelagAdmin: () =>
      store.getters['global/organisation']
        ? store.getters['global/organisation']._permissions.is_brelag_admin
        : false,
    isSupporter: () =>
      store.getters['global/organisation']
        ? store.getters['global/organisation']._permissions.is_brelag_supporter
        : false,
    isMandatorAdmin: () =>
      store.getters['global/organisation']
        ? store.getters['global/organisation']._permissions.unlock_override
        : false,
    canOverrideProjectLock: () =>
      store.getters['global/organisation']
        ? store.getters['global/organisation']._permissions.unlock_override
        : false,
    readOnly: (state) => state.project.readOnly,
    isLockedByWinApp: (state) => state.project.isLockedByWinApp,
    knockautEditorActive: (state) => state.project.knockautEditorActive,
    lockedBy: (state) => state.project.lockedBy,
    unlockedBy: (state) => state.project.unlockedBy,
    editorConfigs: (state) => state.project.editorConfigs,
    unlinkedTeachingOptions: (state) => state.project.unlinkedTeachingOptions,

    /* TREES */
    senderTree: (state) => state.project.senderTree,
    unlinkedReceivers: (state) => state.project.unlinkedReceivers,
    unlinkedDeviceGroupTree: (state) => state.project.unlinkedDeviceGroupTree,
    linkedDeviceGroupTree: (state) => state.project.linkedDeviceGroupTree,
    linkedGroups: (state) => {
      return state.project.linkedDeviceGroupTree.map((tree) => tree.data.id)
    },
    linkedReceiverIds: (state: BrelagState) =>
      state.project.linkedReceivers.map((dev) => dev.data.id),
    linkedReceivers: (state) => state.project.linkedReceivers,

    /* SELECTIONS */
    selectedReceiver: (state) => {
      if (state.project.selectedReceivers.length === 1) {
        return ProjectEditor.getInstance().deviceHandler.getDeviceAssignmentById(
          state.project.selectedReceivers[0],
          EquipmentCategory.RECEIVER
        )
      } else {
        return null
      }
    },
    selectedSender: (state) => {
      if (state.project.selectedSenders.length === 1) {
        return ProjectEditor.getInstance().deviceHandler.getDeviceAssignmentById(
          state.project.selectedSenders[0],
          EquipmentCategory.SENDER
        )
      } else {
        return null
      }
    },
    selectedGroup: (state) => state.project.selectedGroup,
    selectedGroupIsLinked: (state) => {
      if (
        state.project.selectedGroup &&
        state.project.selectedSenders.length === 1
      ) {
        try {
          const idx = ProjectEditor.getInstance().groupHandler.findGroupLink(
            state.project.selectedGroup.id,
            state.project.selectedSenders[0],
            state.project.selectedChannel
          )
          return idx > -1
        } catch (err) {
          return false
        }
      } else {
        return false
      }
    },
    selectedChannel: (state) => state.project.selectedChannel,
    selectedSenders: (state) => state.project.selectedSenders,
    selectedReceivers: (state) => state.project.selectedReceivers,
    selectedAnnotations: (state) => state.project.selectedAnnotations,
    selectedDevices: (state) =>
      state.project.selectedReceivers.concat(state.project.selectedSenders),
    selectedElements: (state) =>
      state.project.selectedReceivers
        .concat(state.project.selectedSenders)
        .concat(state.project.selectedAnnotations),

    selectedLinkedReceivers: (state) => state.project.selectedLinkedReceivers,
    selectedUnlinkedReceivers: (state) =>
      state.project.selectedUnlinkedReceivers,
    receiversInSelectedGroup: (state) => {
      return (
        (state.project.selectedGroup &&
          state.project.selectedGroup.deviceIds) ||
        []
      )
    },
    selectedReceiversInGroups: (state) => {
      return state.project.selectedReceiversInGroups.unlinked.concat(
        state.project.selectedReceiversInGroups.linked
      )
    },
  }

  actions = {
    /* EDITOR */
    setReadOnly(context, readOnly) {
      context.commit('setReadOnly', readOnly)
    },
    setLockedByWinApp(context, isLockedByWinApp) {
      context.commit('setLockedByWinApp', isLockedByWinApp)
    },
    setKnockautEditorActive(context, knockautEditorActive) {
      context.commit('setKnockautEditorActive', knockautEditorActive)
    },
    setLockedBy(context, lockedBy) {
      context.commit('setLockedBy', lockedBy)
    },
    setUnlockedBy(context, unlockedBy) {
      context.commit('setUnlockedBy', unlockedBy)
    },
    setEditorConfigs(context, configs) {
      context.commit('setEditorConfigs', configs)
    },
    /* TEACHING OPTIONS */
    setUnlinkedTeachingOptions(context, configs) {
      context.commit('setUnlinkedTeachingOptions', configs)
    },
    /* TREE */
    updateSenderTree(context, tree) {
      context.commit('updateSenderTree', tree)
    },
    updateReceiverNodes(
      context,
      data: {
        linkedReceivers: DeviceNode[]
        unlinkedReceivers: DeviceNode[]
      }
    ) {
      const selectedReceivers = new Set(context.state.project.selectedReceivers)
      const selectedLinkedReceivers: string[] = []
      const selectedUnlinkedReceivers: string[] = []
      for (const receiver of data.linkedReceivers) {
        if (selectedReceivers.has(receiver.data.id)) {
          selectedLinkedReceivers.push(receiver.data.id)
        }
      }
      for (const receiver of data.unlinkedReceivers) {
        if (selectedReceivers.has(receiver.data.id)) {
          selectedUnlinkedReceivers.push(receiver.data.id)
        }
      }
      context.commit('setSelectedLinkedReceivers', selectedLinkedReceivers)
      context.commit('setSelectedUnlinkedReceivers', selectedUnlinkedReceivers)
      context.commit('updateLinkedReceivers', data.linkedReceivers)
      context.commit('updateUnlinkedReceivers', data.unlinkedReceivers)
    },
    async updateUnlinkedDeviceGroupTree(context, tree) {
      const selectedReceivers = []
      for (const group of tree) {
        for (const device of group.children) {
          if (device.state.selected === true) {
            selectedReceivers.push({
              deviceId: device.data.id,
              groupId: group.data.id,
            })
          }
        }
      }
      context.commit('updateUnlinkedDeviceGroupTree', tree)
      context.commit('setSelectedReceiversInUnlinkedGroups', selectedReceivers)
    },
    updateLinkedDeviceGroupTree(context, tree) {
      const selectedReceivers = []
      for (const group of tree) {
        for (const device of group.children) {
          if (device.state.selected === true) {
            selectedReceivers.push({
              deviceId: device.data.id,
              groupId: group.data.id,
            })
          }
        }
      }
      context.commit('updateLinkedDeviceGroupTree', tree)
      context.commit('setSelectedReceiversInLinkedGroups', selectedReceivers)
    },

    /** SELECTION */
    async selectGroup(context, groupId) {
      if (groupId !== null) {
        context.commit('setSelectedGroup', groupId)
      } else {
        context.commit('setSelectedGroup', null)
      }
    },
    async selectSender(context, sender) {
      if (sender !== null) {
        context.commit('setSelectedSenders', [sender])
      } else {
        context.commit('setSelectedSenders', [])
      }
      context.commit('setSelectedChannel', 0)

      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async selectSenderAndChannel(context, { senderId, channel }) {
      if (senderId !== null) {
        context.commit('setSelectedSenders', [senderId])
      } else {
        throw new Error('sender must not be null')
      }
      context.commit('setSelectedChannel', channel)

      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async selectChannel(context, channel) {
      context.commit('setSelectedChannel', channel)

      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async selectReceiver(context, receiver) {
      if (receiver !== null) {
        context.commit('setSelectedReceivers', [receiver])
      } else {
        context.commit('setSelectedReceivers', [])
      }
      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async selectReceivers(context, receivers: string[]) {
      context.commit('setSelectedReceivers', receivers)
      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async selectSenders(context, senders) {
      context.commit('setSelectedSenders', senders)
      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async addSenderToSelection(context, sender) {
      context.commit('addSenderToSelection', sender)
    },
    async removeSenderFromSelection(context, sender) {
      context.commit('removeSenderFromSelection', sender)
      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async addReceiverToSelection(context, receiver) {
      context.commit('addReceiverToSelection', receiver)
      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async removeReceiverFromSelection(context, receiver) {
      context.commit('removeReceiverFromSelection', receiver)
      // Update group tree
      await ProjectEditor.getInstance().groupHandler.updateDeviceGroupTree()
    },
    async setSelectedAnnotations(context, annotations: string[]) {
      context.commit('setSelectedAnnotations', annotations)
    },
    async setSelectedSenders(context, senders: string[]) {
      context.commit('setSelectedSenders', senders)
    },
    async setSelectedReceivers(context, receivers: string[]) {
      context.commit('setSelectedReceivers', receivers)
    },
    async clearSelection(context) {
      context.commit('setSelectedSenders', [])
      context.commit('setSelectedReceivers', [])
      context.commit('setSelectedLinkedReceivers', [])
      context.commit('setSelectedUnlinkedReceivers', [])
      context.commit('setSelectedGroup', null)
      context.commit('setSelectedAnnotations', [])
    },
  }
  mutations = {
    /** Editor Operations */
    setReadOnly(state, readOnly) {
      state.project.readOnly = readOnly
    },
    setLockedByWinApp(state, isLockedByWinApp) {
      state.project.isLockedByWinApp = isLockedByWinApp
    },
    setKnockautEditorActive(state, knockautEditorActive) {
      state.project.knockautEditorActive = knockautEditorActive
    },
    setLockedBy(state, lockedBy) {
      state.project.lockedBy = lockedBy
    },
    setUnlockedBy(state, unlockedBy) {
      state.project.unlockedBy = unlockedBy
    },
    setEditorConfigs(state, configs) {
      state.project.editorConfigs = configs
    },
    /** Teachign Options */
    setUnlinkedTeachingOptions(state, configs) {
      state.project.unlinkedTeachingOptions = configs
    },
    /** Tree Operations **/
    updateSenderTree(state, { newTree }) {
      state.project.senderTree = newTree
    },
    updateUnlinkedReceivers(state: BrelagState, newTree) {
      state.project.unlinkedReceivers = newTree
    },
    updateLinkedReceivers(state: BrelagState, linkedReceivers) {
      state.project.linkedReceivers = linkedReceivers
    },
    updateUnlinkedDeviceGroupTree(state, newTree) {
      state.project.unlinkedDeviceGroupTree = newTree
    },
    updateLinkedDeviceGroupTree(state, newTree) {
      state.project.linkedDeviceGroupTree = newTree
    },

    /** Selection Operations **/
    setSelectedUnlinkedReceivers(state, receivers: string[]) {
      Vue.set(state.project, 'selectedUnlinkedReceivers', receivers)
    },
    setSelectedLinkedReceivers(state, receivers) {
      Vue.set(state.project, 'selectedLinkedReceivers', receivers)
    },
    setSelectedGroup(state, group) {
      Vue.set(state.project, 'selectedGroup', group)
    },
    setSelectedSenders(state, senders) {
      Vue.set(state.project, 'selectedSenders', senders)
    },
    setSelectedChannel(state, channel) {
      Vue.set(state.project, 'selectedChannel', channel)
    },
    removeReceiverFromSelection(state, receiverId) {
      const idx = state.project.selectedReceivers.findIndex(
        (id) => id === receiverId
      )
      if (idx !== -1) {
        state.project.selectedReceivers.splice(idx, 1)
      }
    },
    removeSenderFromSelection(state, senderId) {
      const idx = state.project.selectedSenders.findIndex(
        (id) => id === senderId
      )
      if (idx !== -1) {
        state.project.selectedSenders.splice(idx, 1)
      }
    },
    removeAnnotationFromSelection(state, annotationId) {
      const idx = state.project.selectedAnnotations.findIndex(
        (id) => id === annotationId
      )
      if (idx !== -1) {
        state.project.selectedAnnotations.splice(idx, 1)
      }
    },
    addSenderToSelection(state, senderId) {
      if (
        state.project.selectedSenders.findIndex((id) => id === senderId) === -1
      ) {
        state.project.selectedSenders.push(senderId)
      }
    },
    addReceiverToSelection(state, receiverId) {
      if (
        state.project.selectedReceivers.findIndex((id) => id === receiverId) ===
        -1
      ) {
        state.project.selectedReceivers.push(receiverId)
      }
    },
    addAnnotationToSelection(state, annotationId) {
      if (
        state.project.selectedAnnotations.findIndex(
          (id) => id === annotationId
        ) === -1
      ) {
        state.project.selectedAnnotations.push(annotationId)
      }
    },
    setSelectedReceivers(state, receivers) {
      Vue.set(state.project, 'selectedReceivers', receivers)
    },
    setSelectedAnnotations(state, annotations) {
      Vue.set(state.project, 'selectedAnnotations', annotations)
    },
    setSelectedReceiversInLinkedGroups(state, data) {
      Vue.set(state.project.selectedReceiversInGroups, 'linked', data)
    },
    setSelectedReceiversInUnlinkedGroups(state, data) {
      Vue.set(state.project.selectedReceiversInGroups, 'unlinked', data)
    },

    /** Collection Operations **/
    setContextSubscribers(
      state: BrelagState,
      subs: { [key: string]: CollectionSubscriber }
    ): void {
      Object.keys(subs).forEach((key) => {
        const suffix = key.endsWith('s') ? 'es' : 's'
        state.context[key + suffix] = subs[key]
      })
    },
    setContextSelection(state: BrelagState, { objectType, object }): void {
      state.context.selection[objectType] = object
    },
  }
}

import Vue from 'vue'
import { ApiClientV2, Collection } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

/*
The wallet models the state that a user of an organisation has withdrawn
a certain amount of the credit for its own use to perform a certain action.
The credit balance is decreased when a wallet is created / amount is updated. The balance in the referenced credit must remain >= 0 when updating wallets.
*/
export class Wallet extends TransientBaseObject {
  owner: string
  balance: string

  static apiUrl = 'brelag/wallet'
  static langPath: string = 'apps.brelag.models.wallet'
  static objectType: string = 'wallet'
  static fields: ModelField[] = []
  static listFields: ListModelField[] = []

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: Wallet.formFields(),
      model: { ...WALLET_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Wallet, Wallet>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(WALLET_DEFAULT))
  }

  static async adjustBalance(
    apiClient: ApiClientV2,
    organisation: string,
    numCoins: number
  ): Promise<void> {
    await apiClient.customPost(`brelag/wallet/adjust-balance`, {
      organisation,
      num_coins: numCoins,
    })
  }
}

export const WALLET_DEFAULT: Wallet = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  owner: undefined,
  balance: undefined,
}


import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue'
import { getLocationPreservingState } from '@/apps/routingUtils'

import { brelagMandatorRouteName } from '@/apps/brelag/mandator-admin/app'

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'main-menu',
  components: {
    'main-menu-entry': MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-chart-pie',
        location: getLocationPreservingState(
          brelagMandatorRouteName('dashboard'),
          this.$router
        ),
      },
      {
        iconName: 'mdi-account',
        location: { name: brelagMandatorRouteName('profile-root') },
      },
      {
        iconName: 'mdi-cash',
        location: { name: brelagMandatorRouteName('webshop-root') },
      },
      {
        iconName: 'mdi-cloud-print-outline',
        location: { name: brelagMandatorRouteName('order-root') },
      },
    ]
  }

  get text() {
    return {
      [brelagMandatorRouteName('dashboard')]: 'Dashboard',
      [brelagMandatorRouteName('profile-root')]: this.$i18n.tc(
        'apps.brelag.user',
        2
      ),
      [brelagMandatorRouteName('webshop-root')]: this.$i18n.tc(
        'apps.brelag.webshop',
        2
      ),
      [brelagMandatorRouteName('order-root')]: this.$i18n.tc(
        'apps.brelag.order',
        2
      ),
    }
  }
}

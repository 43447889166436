
import BaseForm from '@/components/common/BaseForm.vue'
import { RequestEmailUpdate } from '@/models/core/models'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'request_change_email',
  components: {
    'base-form': BaseForm,
  },
  props: {
    id: {
      required: true,
    },
    old_email: {
      default: '',
    },
  },
  data() {
    return {
      requestEmailChange: {
        email1: '',
        email2: '',
      },
    }
  },
})
export default class RequestChangeEmail extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  requestEmailChange: RequestEmailUpdate

  mounted() {
    if (this.$props.id !== this.$store.state.global.profileId) {
      this.$router.push({ name: 'profile-list' })
    }
  }

  changeEmail() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    this.$api
      .requestUpdateEmail(this.$props.id, this.requestEmailChange)
      .then((response) => {
        loadingComponent.close()
        this.$router.push({
          name: 'verify_change_email',
          params: {
            id: this.$props.id,
            email: this.requestEmailChange.email2,
            nonce: response.data.nonce,
          },
        })
      })
      .catch((error) => {
        loadingComponent.close()
        this.$refs.baseForm.handleError(error)
      })
  }
}

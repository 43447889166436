export const SVG_DEFAULTS = {
  // a60a9520-615d-41ad-b3cc-d986d0a830e4
  SWW: {
    bgColor: '#AEA1FF',
    borderColor: '#FFFFFF',
    color: '#1d77b6',
    linksDisplay: true,
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // cf4df438-27d4-4eac-9690-881d1d0816ae
  'MaxFlex M8': {
    bgColor: '#FFFFFF',
    borderColor: '#0062B1',
    color: '#1d77b6',
    linksDisplay: true,
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // c02e6e2f-8828-4496-8d27-9fc9bd332457
  'MX FE UP3': {
    bgColor: '#68CCCA',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  'MX FE ULTRA': {
    bgColor: '#68CCCA',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // c7400059-12b2-4b3e-bcd5-92ad2242f689
  'MX FE SLIM': {
    bgColor: '#68CCCA',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // 47174e86-a8ed-4b10-ba5f-2f68f7d12584
  'MX FE PRO': {
    bgColor: '#68CCCA',
    borderColor: '#FFFFFF',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // a19a8e7f-1b3c-4746-a864-356bf1c7f673
  'LX RLUP10A': {
    bgColor: '#FCDC00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // ed493716-b3b6-4c19-bac4-fe4c4f59333b
  'LX RLUP1A': {
    bgColor: '#FCDC00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // c3442e8b-c3c1-4b7d-b8e7-907ad69998c0
  'MX FE SLIM 24V': {
    bgColor: '#68CCCA',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // f1a9952e-5652-4d42-bff9-8760e6625213
  'FZE Ultimate': {
    bgColor: '#A4DD00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // b11a1b2e-ceec-49a6-bbb5-5d713d057889
  'KNOCKAUT TITAN FusionONE': {
    bgColor: '#A4DD00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // 95a889bf-6e67-481c-8308-fd355413fa6d
  eGate: {
    bgColor: '#A4DD00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // c4667d21-239d-4e38-8f17-98f72bc9137b
  'LX DALA': {
    bgColor: '#FCDC00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // aee74d05-6705-476d-9134-9bc4e6a26b1c
  'LX DIMM RETROFIT': {
    bgColor: '#FCDC00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
  // d90fdc91-75a6-4b5f-a878-88f2796d28e0
  'LX DIMM NO LIMIT': {
    bgColor: '#FCDC00',
    color: '#ce1616',
    textColor: '#000000',
    textDisplay: true,
    variantDisplay: 'full',
  },
}

import { ProjectEditor } from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import { getMetaSvgAttribute } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditorUtil'
import { DeviceAssignment } from '@/apps/brelag/common/models/equipment'
import { Link } from '@/apps/brelag/common/models/link'
import { DeviceHandler } from './deviceHandler'

export interface DeviceArrangementChannelInfo {
  repeating: string
  nonRepeating: string
  all?: string
  prettyName: string // User-facing (1-indexed) channel name
}

export interface LinkArrangementChannelInfo {
  links: Link[]
  repeating: string[][]
  nonRepeating: string[][]
  all: string[][]
  prettyName: string // User-facing (1-indexed) channel name
}

export interface SenderArrangement extends DeviceAssignment {
  equipment_variant: string
  place: string
  channel_1?: DeviceArrangementChannelInfo
  channel_2?: DeviceArrangementChannelInfo
  channel_3?: DeviceArrangementChannelInfo
  channel_4?: DeviceArrangementChannelInfo
  channel_5?: DeviceArrangementChannelInfo
  channel_6?: DeviceArrangementChannelInfo
  channel_7?: DeviceArrangementChannelInfo
  channel_8?: DeviceArrangementChannelInfo
  remark?: string
}

export interface ReceiverArrangement extends DeviceAssignment {
  equipment_variant: string
  remark?: string
  externalConfig: {
    [key: string]: any
  }
  mergedSenders: {
    [channel: string]: DeviceArrangementChannelInfo
  }
  mergedLinks: {
    [channel: string]: LinkArrangementChannelInfo
  }
}

export interface GroupsArrangement {
  group: string
  receivers: string[]
}

/**
 * Output for receiver arrangement view
 */
export function getReceiverArrangements(
  projectEditor: ProjectEditor
): ReceiverArrangement[] {
  const arrangements: ReceiverArrangement[] = []
  for (const receiver of projectEditor.receivers) {
    // Receiver can contain 'svgObjects' which can not be stringified
    const { meta, ...rest } = receiver
    const receiverArrangement: ReceiverArrangement = JSON.parse(
      JSON.stringify(rest)
    )

    const links = projectEditor.deviceHandler.findLinks(
      undefined,
      receiver.id,
      undefined
    )
    const mergedLinks = mergeLinks(links, false, projectEditor.deviceHandler)
    const equipment =
      projectEditor.equipmentHandler.getEquipmentFromDevice(receiver)

    let remark = ''
    const remarks = getMetaSvgAttribute(receiver, 'textLines') as string[]
    if (remarks) {
      remark = remarks[0]
    }
    receiverArrangement.remark = remark

    receiverArrangement.mergedLinks = mergedLinks
    receiverArrangement.equipment_variant =
      DeviceAssignment.prettyDeviceVariantName(receiver)

    // Only show config values that have a dataID. Use the special externalName if available
    receiverArrangement.externalConfig = DeviceAssignment.getExternalConfig(
      receiverArrangement.config,
      equipment.config_editor_layout
    )

    receiverArrangement.mergedSenders = {}
    for (const channel of Object.keys(mergedLinks)) {
      const senders = {
        nonRepeating: '',
        repeating: '',
        prettyName: mergedLinks[channel].prettyName,
      }
      if (mergedLinks[channel].nonRepeating.length > 0) {
        for (const seq of mergedLinks[channel].nonRepeating) {
          if (seq.length === 1) {
            senders.nonRepeating += `${seq[0]}, `
          } else {
            senders.nonRepeating += `${seq[0]} - ${seq[seq.length - 1]}, `
          }
        }
        if (senders.nonRepeating !== '') {
          senders.nonRepeating = senders.nonRepeating.substr(
            0,
            senders.nonRepeating.length - 2
          )
        }
      }
      if (mergedLinks[channel].repeating.length > 0) {
        for (const seq of mergedLinks[channel].repeating) {
          if (seq.length === 1) {
            senders.repeating += `${seq[0]}, `
          } else {
            senders.repeating += `${seq[0]} - ${seq[seq.length - 1]}, `
          }
        }
        if (senders.repeating !== '') {
          senders.repeating = senders.repeating.substr(
            0,
            senders.repeating.length - 2
          )
        }
      }
      receiverArrangement.mergedSenders[channel] = senders
    }

    arrangements.push(receiverArrangement)
  }

  return arrangements
}

/**
 * Extract information for group arrangement report
 */
export function getGroupsArrangement(
  projectEditor: ProjectEditor
): GroupsArrangement[] {
  let arrangements: GroupsArrangement[] = []
  for (const group of projectEditor.groupHandler.groups) {
    const receivers = group.deviceIds
      .map((id) => {
        return projectEditor.deviceHandler.getDeviceAssignmentById(id)
      })
      .filter((r) => !!r)
    const receiverNumbers = receivers.map((r) => r.device_number)
    const receiverNumberSequences = parseAndMatch(receiverNumbers)
    // merge consecutive receivers
    const receiversOut = receiverNumberSequences.map((seq) => {
      if (seq.length === 1) {
        return seq[0]
      } else {
        return `${seq[0]} - ${seq[seq.length - 1]}`
      }
    })
    const arrangement: GroupsArrangement = {
      group: group.name,
      receivers: receiversOut,
    }
    arrangements.push(arrangement)
  }
  arrangements = arrangements.sort((a, b) => a.group.localeCompare(b.group))
  return arrangements
}

/**
 * Extracts and formats all relevant information for sender arrangement report
 */
export function getSenderArrangements(
  projectEditor: ProjectEditor
): SenderArrangement[] {
  const arrangements: SenderArrangement[] = []
  const length = projectEditor.senders.length
  for (let i = 0; i < length; i++) {
    const sender = projectEditor.senders[i]
    // Add info about building & floor
    // sender can contain 'meta' which can not be stringified
    const { meta, ...rest } = sender
    const arrangement: SenderArrangement = JSON.parse(JSON.stringify(rest))
    const floor = projectEditor.floorsMap.get(sender.floor)
    const building = projectEditor.buildingsMap.get(floor.building)
    arrangement.place = `${building.title} / ${floor.title}`
    let remark = ''
    const remarks = getMetaSvgAttribute(sender, 'textLines') as string[]
    if (remarks) {
      remark = remarks[0]
    }
    arrangement.remark = remark

    arrangement.equipment_variant =
      DeviceAssignment.prettyDeviceVariantName(sender)
    const links = projectEditor.deviceHandler.findLinks(
      sender.id,
      undefined,
      undefined
    )
    let groupIds: string[] = []
    // find groups
    links.forEach((link) => {
      if (link.options.groups) {
        groupIds = [...groupIds, ...link.options.groups]
      }
    })
    // make unique
    groupIds = [...new Set(groupIds)]
    const groups = groupIds
      .map((g) => projectEditor.groupHandler.getGroup(g))
      .filter((g) => !!g)
      .sort((a, b) => a.name.localeCompare(b.name))

    const groupsPerChannel: {
      [channel: string]: {
        repeating: string
        nonRepeating: string
      }
    } = {}

    groups.forEach((g) => {
      g.links.forEach((l) => {
        if (l.senderId === sender.id) {
          if (!groupsPerChannel[l.channel]) {
            groupsPerChannel[l.channel] = {
              repeating: '',
              nonRepeating: '',
            }
          }
          let repeating = true
          // check if all options are repeating
          for (const o in l.options) {
            if (!l.options[o].keyRepeaterOnly) {
              repeating = false
              break
            }
          }
          if (repeating) {
            groupsPerChannel[l.channel].repeating += `${g.name}, `
          } else {
            groupsPerChannel[l.channel].nonRepeating += `${g.name}, `
          }
        }
      })
    })

    // only keep links without groups
    const linksWithoutGroup = links.filter((link) => {
      if (
        link.options.groups === undefined ||
        link.options.groups.length === 0
      ) {
        // check if receiver is linked through group
        const groups = projectEditor.groupHandler.findLinks(
          link.receiver,
          link.sender,
          link.sender_channel
        )
        return groups.length === 0
      } else {
        return false
      }
    })
    const mergedLinks = mergeLinks(
      linksWithoutGroup,
      true,
      projectEditor.deviceHandler
    )

    for (const channel of [0, 1, 2, 3, 4, 5, 6, 7]) {
      const key = `channel_${channel + 1}`
      arrangement[key] = {
        nonRepeating: '',
        repeating: '',
        all: '',
      }
      // add groups
      if (
        groupsPerChannel[channel] &&
        groupsPerChannel[channel].nonRepeating.length > 0
      ) {
        arrangement[key].nonRepeating += groupsPerChannel[channel].nonRepeating
        arrangement[key].all += groupsPerChannel[channel].nonRepeating
      }
      if (
        groupsPerChannel[channel] &&
        groupsPerChannel[channel].repeating.length > 0
      ) {
        arrangement[key].repeating += groupsPerChannel[channel].repeating
        arrangement[key].all += groupsPerChannel[channel].repeating
      }
      // add individual devices
      if (
        mergedLinks[channel] &&
        mergedLinks[channel].nonRepeating.length > 0
      ) {
        for (const seq of mergedLinks[channel].nonRepeating) {
          if (seq.length === 1) {
            arrangement[key].nonRepeating += `${seq[0]}, `
          } else {
            arrangement[key].nonRepeating += `${seq[0]} - ${
              seq[seq.length - 1]
            }, `
          }
        }
      }
      if (mergedLinks[channel] && mergedLinks[channel].repeating.length > 0) {
        for (const seq of mergedLinks[channel].repeating) {
          if (seq.length === 1) {
            arrangement[key].repeating += `${seq[0]}, `
          } else {
            arrangement[key].repeating += `${seq[0]} - ${seq[seq.length - 1]}, `
          }
        }
      }
      if (
        mergedLinks[channel] &&
        mergedLinks[channel].all &&
        mergedLinks[channel].all.length > 0
      ) {
        for (const seq of mergedLinks[channel].all) {
          if (seq.length === 1) {
            arrangement[key].all += `${seq[0]}, `
          } else {
            arrangement[key].all += `${seq[0]} - ${seq[seq.length - 1]}, `
          }
        }
      }
      // remove trailing ', '
      if (arrangement[key].nonRepeating !== '') {
        arrangement[key].nonRepeating = arrangement[key].nonRepeating.substr(
          0,
          arrangement[key].nonRepeating.length - 2
        )
      }
      if (arrangement[key].repeating !== '') {
        arrangement[key].repeating = arrangement[key].repeating.substr(
          0,
          arrangement[key].repeating.length - 2
        )
      }
      if (arrangement[key].all !== '') {
        arrangement[key].all = arrangement[key].all.substr(
          0,
          arrangement[key].all.length - 2
        )
      }
    }
    arrangements.push(arrangement)
  }
  return arrangements
}

/**
 * Merges links of all devices in project as sets of consecutive linked devices
 * @param links
 * @param forSender
 */
export function mergeLinks(
  links: Link[],
  forSender: boolean,
  deviceHandler: DeviceHandler
): {
  [channel: string]: LinkArrangementChannelInfo
} {
  const getDeviceNumbers = (
    forSender: boolean,
    RepeaterOnly: boolean,
    _links: Link[]
  ) => {
    const deviceNumbers = []
    _links.forEach((link) => {
      if (
        RepeaterOnly === undefined ||
        (RepeaterOnly &&
          link.options.keyRepeaterOnly &&
          link.options.keyRepeaterOnly !== 'false') ||
        (!RepeaterOnly &&
          (!link.options.keyRepeaterOnly ||
            link.options.keyRepeaterOnly === 'false'))
      ) {
        if (forSender) {
          const device = deviceHandler.receivers.get(link.receiver)
          if (device) {
            deviceNumbers.push(device.device_number)
          }
        } else {
          const device = deviceHandler.senders.get(link.sender)
          if (device) {
            deviceNumbers.push(device.device_number)
          }
        }
      }
    })
    return deviceNumbers.sort()
  }

  // Divide links by channel
  const out: {
    [channel: string]: LinkArrangementChannelInfo
  } = {}
  for (const link of links) {
    if (out.hasOwnProperty(link.sender_channel)) {
      out[link.sender_channel].links.push(link)
    } else {
      out[link.sender_channel] = {
        links: [link],
        repeating: [],
        nonRepeating: [],
        all: [],
        prettyName: `Kanal ${link.sender_channel + 1}`,
      }
    }
  }

  for (const channel of Object.keys(out)) {
    // Split between repeating and non-repeating, fetch sorted device ids
    const nonRepeating = getDeviceNumbers(forSender, false, out[channel].links)
    const repeating = getDeviceNumbers(forSender, true, out[channel].links)
    const allIds = getDeviceNumbers(forSender, undefined, out[channel].links)
    const repeatingOut = parseAndMatch(repeating)
    const nonRepeatingOut = parseAndMatch(nonRepeating)
    const allOut = parseAndMatch(allIds)
    out[channel].repeating = repeatingOut
    out[channel].nonRepeating = nonRepeatingOut
    out[channel].all = allOut
  }

  return out
}

/**
 * Helper function for merge links below
 * @param getDeviceNumbers
 */
function parseAndMatch(getDeviceNumbers: string[]): string[][] {
  // The ids have the format 'xx.yy.zzzzz' -> split
  const prefixMap: Map<string, string[]> = new Map()
  const getDeviceNumbersSplit = getDeviceNumbers.map((link) => link.split('.'))
  getDeviceNumbersSplit.forEach((split) => {
    // Create 'xx.yy.' prefix
    // Strip the 'E: ' and 'S: '
    const prefix = `${split[0].slice(3, split[0].length)}.${split[1]}.`

    // Push 'zzzzz'
    if (prefixMap.has(prefix)) {
      prefixMap.get(prefix).push(split[2])
    } else {
      prefixMap.set(prefix, [split[2]])
    }
  })

  // Now we can look for consecutive ranges and combine them
  const out: Map<string, string[][]> = new Map()
  const outDeviceIds: string[][] = []
  prefixMap.forEach((value, key) => {
    out.set(key, [[]])
    outDeviceIds.push([])

    const mergedDevices = out.get(key)
    value.forEach((deviceNumber) => {
      const lastSequence = mergedDevices[mergedDevices.length - 1]
      if (lastSequence.length === 0) {
        lastSequence.push(deviceNumber)
        outDeviceIds[outDeviceIds.length - 1].push(key + deviceNumber)
      } else {
        const lastDeviceNumber = lastSequence[lastSequence.length - 1]
        if (parseInt(deviceNumber) === parseInt(lastDeviceNumber) + 1) {
          lastSequence.push(deviceNumber)
          outDeviceIds[outDeviceIds.length - 1].push(key + deviceNumber)
        } else {
          mergedDevices.push([deviceNumber])
          outDeviceIds.push([key + deviceNumber])
        }
      }
    })
  })
  return outDeviceIds
}

import Vue from 'vue'
import { ApiClientV2, Collection } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  ListFilter,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'
import moment from 'moment'

enum KnockautBackupType {
  DAILY = 0,
  WEEKLY = 1,
  MONTHLY = 2,
  SEMESTRAL = 3,
  CUSTOM = 4,
}

function sizeToMegabyte(size: number) {
  return (size / 1024 / 1024).toFixed(2)
}

export class KnockautBackup extends TransientBaseObject {
  project: string
  backup_id: string
  name: string
  backup_created_at: string
  size: number
  backup_type: number
  file: string

  static apiUrl = 'brelag/knockaut-backup'
  static langPath: string = 'apps.brelag.models.knockaut.knockaut-backup'
  static objectType: string = 'KnockautBackup'
  static fields: ModelField[] = []
  static listFields: ListModelField[] = [
    { key: 'name' },
    {
      key: 'size',
      transform: (size: number) => {
        return `${sizeToMegabyte(size)} MB`
      },
    },
    {
      key: 'backup_created_at',
      transform: (backup_created_at: string) => {
        return moment(backup_created_at).format('DD.MM.YYYY HH:mm')
      },
    },
  ]
  static listFilters: ListFilter[] = []

  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<KnockautBackup, KnockautBackup>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(KNOCKAUT_BACKUP_DEFAULT))
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...KNOCKAUT_BACKUP_DEFAULT },
    }
  }

  static downloadBackup(
    apiClient: ApiClientV2,
    backupId: string
  ): Promise<string> {
    return apiClient.customGet(
      `brelag/knockaut-backup/${backupId}/download-file`
    )
  }

  static restoreFromBackup(
    apiClient: ApiClientV2,
    backupId: string
  ): Promise<boolean> {
    return apiClient.customPost(
      `brelag/knockaut-backup/${backupId}/restore-backup`
    )
  }
}

export const KNOCKAUT_BACKUP_DEFAULT: KnockautBackup = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  project: '',
  backup_id: '',
  name: '',
  backup_created_at: '',
  size: 0,
  backup_type: KnockautBackupType.CUSTOM,
  file: '',
}

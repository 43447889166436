import Vue from 'vue'
import VueI18n, { Locale, Path } from 'vue-i18n'
import en from './en'
import de from './de'
import axios from 'axios'
import { TransientBaseObject } from '@/models/core/base'

export enum LocaleOption {
  EN = 'en',
  DE = 'de',
}

declare let process: {
  env: {
    NODE_ENV: string
  }
}

Vue.use(VueI18n)

let silentTranslationWarn = true
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  // This can be enabled during development to check for any missing translations
  silentTranslationWarn = true
}
const defaultLocale = LocaleOption.DE

// TODO: Default Language should be configurable by process.env.DEFAULT_LANG or something
export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'de',
  silentTranslationWarn,
  messages: {
    de,
    en,
  },
})
i18n.missing = (locale: Locale, key: Path): string | void => {
  // Default for missing translations is key. Developers see warnings in console for missing translations.
  // console.log('Missing translation', locale, key, vm)
  return key
}
const loadedLanguages = ['de'] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(
        /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`
      ).then((msgs) => {
        i18n.setLocaleMessage(lang, msgs.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

export function getTranslation(text: string, plural: boolean = false) {
  if (plural) {
    return i18n.tc(text, 2)
  } else {
    return i18n.tc(text)
  }
}

export function createSuccessMessage(
  object: typeof TransientBaseObject | string
): string {
  if (typeof object === 'string') {
    return `${object} ${i18n.tc('components.common.createdSuccessfully')}`
  } else if (object.prettyName()) {
    return `${object.prettyName()} ${i18n.tc(
      'components.common.createdSuccessfully'
    )}`
  } else {
    return i18n.tc('components.common.createdSuccessfully')
  }
}

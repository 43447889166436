import { Location, Route, RouteConfig } from 'vue-router'

import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import MainMenu from './components/MainMenu.vue'
import BrelagAdminDashboard from './components/BrelagAdminDashboard.vue'
import { updateStateForRoute } from '@/apps/routingUtils'
import { clientAppRegistryGet } from '@/apps/clientAppRegistry'
import { brelagAdminRouteName, VIEW_ID } from '@/apps/brelag/brelag-admin/app'

function beforeEnterBrelagAdminApp(to: Route): Promise<void | Location> {
  const objectList = ['event-log', 'album', 'image', 'equipment_group']
  return updateStateForRoute(to, objectList)
}

let route: RouteConfig = { path: '' }

route = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: brelagAdminRouteName('dashboard') },
  components: {
    default: RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterBrelagAdminApp,
  },
  children: [
    {
      path: 'dashboard',
      name: brelagAdminRouteName('dashboard'),
      component: BrelagAdminDashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'mandator',
      name: brelagAdminRouteName('mandator-root'),
      redirect: { name: brelagAdminRouteName('mandator-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'list',
          name: brelagAdminRouteName('mandator-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "mandator-list" */ './components/mandator/MandatorList.vue'
            ),
          meta: {
            requiresList: {
              organisation: true,
            },
          },
        },
        {
          path: 'create',
          name: brelagAdminRouteName('organisation-create'),
          component: () =>
            import(
              /* webpackChunkName: "mandator-form" */ './components/mandator/MandatorForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagAdminRouteName('organisation-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "mandator-form" */ './components/mandator/MandatorForm.vue'
            ),
        },
      ],
    },
    {
      path: 'admin',
      name: brelagAdminRouteName('admin-root'),
      redirect: { name: brelagAdminRouteName('admin-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagAdminRouteName('admin-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin-list" */ './components/admin/MandatorAdminList.vue'
            ),
        },
        {
          path: 'create',
          name: brelagAdminRouteName('admin-create'),
          component: () =>
            import(
              /* webpackChunkName: "admin-form" */ './components/admin/MandatorAdminForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagAdminRouteName('profile-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin-form" */ './components/admin/MandatorAdminForm.vue'
            ),
        },
      ],
    },
    {
      path: 'supporter',
      name: brelagAdminRouteName('supporter-root'),
      redirect: { name: brelagAdminRouteName('supporter-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagAdminRouteName('supporter-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin-list" */ './components/admin/SupporterList.vue'
            ),
        },
        {
          path: 'create',
          name: brelagAdminRouteName('supporter-create'),
          component: () =>
            import(
              /* webpackChunkName: "admin-form" */ './components/admin/SupporterForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagAdminRouteName('supporter-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "admin-form" */ './components/admin/SupporterForm.vue'
            ),
        },
      ],
    },
    {
      path: 'user',
      name: brelagAdminRouteName('user-root'),
      redirect: { name: brelagAdminRouteName('user-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagAdminRouteName('user-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "user-list" */ './components/admin/MandatorUserList.vue'
            ),
        },
      ],
    },
    {
      path: 'offer',
      name: brelagAdminRouteName('offer-root'),
      redirect: { name: brelagAdminRouteName('offer-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagAdminRouteName('offer-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "offer-list" */ './components/offer/OfferList.vue'
            ),
          meta: {
            requiresList: {},
          },
        },
        {
          path: 'create',
          name: brelagAdminRouteName('offer-create'),
          component: () =>
            import(
              /* webpackChunkName: "offer-form" */ './components/offer/OfferForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagAdminRouteName('offer-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "offer-form" */ './components/offer/OfferForm.vue'
            ),
        },
      ],
    },
    {
      path: 'album',
      name: brelagAdminRouteName('album-root'),
      redirect: { name: brelagAdminRouteName('album-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [],
    },
    {
      path: 'knockaut-module',
      name: brelagAdminRouteName('knockaut-module-root'),
      redirect: { name: brelagAdminRouteName('knockaut-module-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagAdminRouteName('knockaut-module-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "knockaut-module-list" */ './components/knockaut-module/KnockautModuleList.vue'
            ),
          meta: {
            requiresList: {},
          },
        },
        {
          path: 'create',
          name: brelagAdminRouteName('knockaut-module-create'),
          component: () =>
            import(
              /* webpackChunkName: "knockaut-module-form" */ './components/knockaut-module/KnockautModuleForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagAdminRouteName('knockaut-module-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "knockaut-module-form" */ './components/knockaut-module/KnockautModuleForm.vue'
            ),
        },
      ],
    },
  ],
}

export default route

import { RawLocation, Route } from 'vue-router'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { CollectionPagination } from '@/api/ApiClient'
import { Store } from 'vuex'
import { ErrorHandler } from 'vue-router/types/router'

type Dictionary<T> = { [key: string]: T }
const prefixSeparator = '~'

export class RouterHandler {
  private _vm: Vue
  private _router: VueRouter
  private _store: Store<any>
  public DEFAULT_PAGE_SIZE = 10

  public constructor() {}

  static install(vue): void {
    vue.prototype.$routerHandler = new RouterHandler()
  }

  public init(vm: Vue): void {
    this._vm = vm
    this._router = vm.$router
    this._store = vm.$store
  }

  static prefixObject(
    prefix: string,
    obj: Dictionary<string> | Dictionary<number>
  ): Dictionary<string> {
    const newObj = {}
    Object.keys(obj).forEach((key: string) => {
      newObj[`${prefix}${key}`] = obj[key]
    })
    return newObj
  }

  public query(prefix: string): Dictionary<string> {
    const query = {}
    Object.keys(this._vm.$route.query).forEach((key: string) => {
      if (key.startsWith(prefix)) {
        const substring = key.substring(prefix.length)
        if (substring !== 'pageSize' && substring !== 'page') {
          query[substring] = this._vm.$route.query[key]
        }
      } else if (key.indexOf(prefixSeparator) < 0) {
        // Filter out pagination parametres and if this key is alredy defined by
        // prefix-specific filter
        if (key !== 'pageSize' && key !== 'page' && query[key] === undefined) {
          query[key] = this._vm.$route.query[key]
        }
      }
    })
    return query
  }

  public pagination(prefix: string): CollectionPagination {
    let pageSize
    let page
    Object.keys(this._vm.$route.query).forEach((key: string) => {
      if (key.startsWith(prefix)) {
        const substring = key.substring(prefix.length)
        if (substring === 'pageSize' || substring === 'page_size') {
          pageSize = parseInt(this._vm.$route.query[key] as string)
        } else if (substring === 'page') {
          page = parseInt(this._vm.$route.query[key] as string)
        }
      } else if (key.indexOf(prefixSeparator) < 0) {
        if (
          (key === 'pageSize' || key === 'page_size') &&
          pageSize === undefined
        ) {
          pageSize = parseInt(this._vm.$route.query[key] as string)
        } else if (key === 'page' && page === undefined) {
          page = parseInt(this._vm.$route.query[key] as string)
        }
      }
    })
    if (!page) {
      page = 1
    }
    if (!pageSize) {
      pageSize = this.DEFAULT_PAGE_SIZE
    }
    return {
      page,
      pageSize,
    }
  }

  public push(
    location: RawLocation,
    onComplete?: Function,
    onAbort?: ErrorHandler
  ): void {
    if (!this._store.getters['global/navigationIsActive']) {
      return this._router.push(location, onComplete, onAbort)
    }
  }

  public resolve(to: RawLocation, current?: Route, append?: boolean) {
    if (!this._store.getters['global/navigationIsActive']) {
      return this._router.resolve(to, current, append)
    }
  }

  public replace(
    location: RawLocation,
    onComplete?: Function,
    onAbort?: ErrorHandler
  ): void {
    if (!this._store.getters['global/navigationIsActive']) {
      return this._router.replace(location, onComplete, onAbort)
    }
  }

  private removePrefixedProps(prefix: string, object: any) {
    if (prefix === '') {
      return object
    }
    Object.keys(object).forEach((key: string) => {
      if (key.startsWith(prefix)) {
        const substring = key.substring(prefix.length)
        if (substring !== 'pageSize' && substring !== 'page') {
          delete object[key]
        }
      }
    })
    return object
  }

  public removeFromQuery(prefix: string, key: string): void {
    const prefixedString = `${prefix}${key}`

    const redirect = {
      path: this._vm.$route.path,
      query: JSON.parse(JSON.stringify(this._vm.$route.query)),
    }
    delete redirect.query[prefixedString]
    return this.replace(redirect)
  }

  public setQuery(prefix: string, query: Dictionary<string>): void {
    const prefixedQuery = RouterHandler.prefixObject(prefix, query)

    const redirect = {
      path: this._vm.$route.path,
      query: {
        ...this.removePrefixedProps(
          prefix,
          // Important to do parse-stringify, because vue-router would not detect changes in the query object directly
          JSON.parse(JSON.stringify(this._vm.$route.query))
        ),
        ...prefixedQuery,
      },
    }
    return this.replace(redirect)
  }

  public setPage(prefix: string, page: number): void {
    const oldPagination = this.pagination(prefix)
    const newPagination = RouterHandler.prefixObject(prefix, {
      page: page ? page : 1,
      pageSize: oldPagination.pageSize
        ? oldPagination.pageSize
        : this.DEFAULT_PAGE_SIZE,
    })

    const redirect = {
      path: this._vm.$route.path,
      query: {
        ...this._vm.$route.query,
        ...newPagination,
      },
    }
    return this.replace(redirect)
  }

  get idString() {
    return 'id'
  }

  public getDetailLinkTo(
    name: string,
    id: string,
    copy: boolean = false,
    extraParams,
    extraQuery
  ) {
    let params = {
      [this.idString]: id,
      templateId: '',
    }
    if (copy) {
      params = {
        [this.idString]: '0',
        templateId: id,
      }
    }

    return {
      name,
      params: {
        ...params,
        ...extraParams,
      },
      query: {
        ...extraQuery,
      },
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import hotkeys from 'hotkeys-js'
import VueCropper from 'vue-cropperjs'

import { TransientBaseObject } from '@/models/core/base'

@Component({
  components: {
    VueCropper,
  },
  props: {
    originalDownloadUrl: {
      required: true,
    },
    isActive: {
      required: true,
    },
  },
  data() {
    return {
      cropImg: '',
    }
  },
})
export default class CropModal extends Vue {
  modelClass: typeof TransientBaseObject
  $refs: {
    cropper: any
  }
  cropImg: string

  mounted() {
    hotkeys('esc', (event) => {
      event.preventDefault()
      this.cancel()
    })
  }

  cancel() {
    this.$emit('cancel')
  }

  rotate() {
    this.$refs.cropper.rotate(90)
    // Zoom out so that the whole image is in view after rotating
    this.$refs.cropper.zoomTo(0)
  }

  zoomIn() {
    this.$refs.cropper.relativeZoom(0.1)
  }

  zoomOut() {
    this.$refs.cropper.relativeZoom(-0.1)
  }

  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  askCropImage() {
    this.$buefy.dialog.confirm({
      message:
        'Sind Sie sicher, dass Sie den Bildausschnitt überschreiben wollen?',
      onConfirm: () => this.cropImage(),
    })
  }

  async cropImage() {
    // get image data for post processing, e.g. upload or setting image src
    this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    const blob = this.dataURLtoBlob(this.cropImg)
    this.$emit('save', blob)
  }
}

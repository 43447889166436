import components from '@/lang/en/components'
import models from '@/lang/en/models'
import admin from '@/apps/admin/lang/en'
import brelag from '@/apps/brelag/common/lang/en'

const apps = {
  admin,
  brelag,
}

export default {
  components,
  models,
  apps,
}

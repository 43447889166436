
import Vue from 'vue'
import Component from 'vue-class-component'
import SwwConfigEditor from '@/apps/brelag/mandator-user/components/editor/sww/SwwConfigEditor.vue'
import { createDefaultSww } from '@/apps/brelag/common/models/brelagSwwDefault'

@Component({
  name: 'formly_sww-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    SwwConfigEditor,
  },
  data() {
    return {
      buttonConfigModalActive: false,
    }
  },
})
export default class SwwField extends Vue {
  buttonConfigModalActive: boolean

  mounted() {
    this.maybeApplyDefault()
  }

  maybeApplyDefault() {
    if (!this.$props.model[this.$props.field.key]) {
      // Load default config
      this.$props.model[this.$props.field.key] = createDefaultSww()
    }
  }

  activateModal() {
    this.maybeApplyDefault()
    this.buttonConfigModalActive = true
  }

  get swwConfigActive() {
    if (
      this.$props.field.extraData &&
      this.$props.field.extraData.dependencyKey
    ) {
      const depKey = this.$props.field.extraData.dependencyKey
      if (this.$props.model[depKey]) {
        return this.$props.model[depKey]
      } else {
        return false
      }
    } else {
      return true
    }
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator'
import { INT_TO_NETMASK_MAP } from '@/apps/brelag/common/models/eGateConfig'

@Component({
  name: 'formly_brelag-netmask-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class BrelagNetmaskField extends Vue {
  get parsedField() {
    return INT_TO_NETMASK_MAP[this.$props.model[this.$props.field.key]]
  }

  onInput(value) {
    this.$props.model[this.$props.field.key] = parseInt(value)
  }
}

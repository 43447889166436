import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import { simpleCopy } from '@/util/util'

export const EGATE_CONFIG_DEFAULT = {
  dhcp: 1,
  ip: '198.168.0.1',
  netmask: 24,
  gateway: '192.168.0.0',
  dns: '192.168.0.0',
  port: 1318,
}

function ip2int(ip) {
  return (
    ip.split('.').reduce(function (ipInt, octet) {
      return (ipInt << 8) + parseInt(octet, 10)
    }, 0) >>> 0
  )
}

export const INT_TO_NETMASK_MAP = {
  0: '0.0.0.0',
  1: '128.0.0.0',
  2: '192.0.0.0',
  3: '224.0.0.0',
  4: '240.0.0.0',
  5: '248.0.0.0',
  6: '252.0.0.0',
  7: '254.0.0.0',
  8: '255.0.0.0',
  9: '255.128.0.0',
  10: '255.192.0.0',
  11: '255.224.0.0',
  12: '255.240.0.0',
  13: '255.248.0.0',
  14: '255.252.0.0',
  15: '255.254.0.0',
  16: '255.255.0.0',
  17: '255.255.128.0',
  18: '255.255.192.0',
  19: '255.255.224.0',
  20: '255.255.240.0',
  21: '255.255.248.0',
  22: '255.255.252.0',
  23: '255.255.254.0',
  24: '255.255.255.0',
  25: '255.255.255.128',
  26: '255.255.255.192',
  27: '255.255.255.224',
  28: '255.255.255.240',
  29: '255.255.255.248',
  30: '255.255.255.252',
  31: '255.255.255.254',
  32: '255.255.255.255',
}

function int2ip(ipInt) {
  return (
    (ipInt >>> 24) +
    '.' +
    ((ipInt >> 16) & 255) +
    '.' +
    ((ipInt >> 8) & 255) +
    '.' +
    (ipInt & 255)
  )
}

export function parseIp(ip: string): number {
  const valid = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip)
  if (valid) {
    return ip2int(ip)
  } else {
    return 0
  }
}

function parseNetmask(mask: string): number {
  const countCharOccurences = (string, char) => string.split(char).length - 1
  const decimalToBinary = (dec) => (dec >>> 0).toString(2)
  const getNetMaskParts = (nmask) => nmask.split('.').map(Number)
  const netmask2CIDR = (netmask) =>
    countCharOccurences(
      getNetMaskParts(netmask)
        .map((part) => decimalToBinary(part))
        .join(''),
      '1'
    )
  const valid = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(
    mask
  )
  if (valid) {
    return netmask2CIDR(mask)
  } else {
    return 0
  }
}

export function getDataIdeGateConfig(config): {
  dataID: number
  value: number
}[] {
  const dataList = []
  if (!config) {
    config = simpleCopy(EGATE_CONFIG_DEFAULT)
  }
  dataList.push({
    dataID: 1,
    value: config.dhcp,
  })
  dataList.push({
    dataID: 2,
    value: parseIp(config.ip),
  })
  dataList.push({
    dataID: 3,
    value: config.netmask,
  })
  dataList.push({
    dataID: 4,
    value: parseIp(config.gateway),
  })
  dataList.push({
    dataID: 5,
    value: parseIp(config.dns),
  })
  dataList.push({
    dataID: 6,
    value: parseInt(config.port),
  })
  return dataList
}

export const eGateConfig = {
  title: 'eGate',
  model: JSON.parse(JSON.stringify(EGATE_CONFIG_DEFAULT)),
  description: [],
  fields: [
    {
      key: 'dhcp',
      type: FormFieldType.CHECKBOX,
      properties: {
        label: 'DHCP',
        extraData: {
          dataID: 1,
        },
      },
    },
    {
      key: 'ip',
      type: FormFieldType.IP_FIELD,
      display: '!model.dhcp',
      properties: {
        label: 'IP Adresse',
        extraData: {
          dataID: 2,
        },
      },
    },
    {
      key: 'netmask',
      type: FormFieldType.BRELAG_NETMASK_FIELD,
      display: '!model.dhcp',
      properties: {
        label: 'Maske',
        extraData: {
          dataID: 3,
        },
      },
    },
    {
      key: 'gateway',
      type: FormFieldType.IP_FIELD,
      display: '!model.dhcp',
      properties: {
        label: 'Gateway',
        extraData: {
          dataID: 4,
        },
      },
    },
    {
      key: 'dns',
      type: FormFieldType.IP_FIELD,
      display: '!model.dhcp',
      properties: {
        label: 'DNS',
        extraData: {
          dataID: 5,
        },
      },
    },
    {
      key: 'port',
      type: FormFieldType.NUMBER_FIELD,
      properties: {
        label: 'Port',
        extraData: {
          dataID: 6,
        },
      },
    },
  ],
}

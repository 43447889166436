export const DEFAULT_LAYOUT_KEY_CONFIGS = {
  // 'Typ 39',
  0xcf: [
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 2,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 2,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
  ],
  // 'Typ 3',
  0xc0: [
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
  ],
  // 'Typ 18',
  0xcc: [
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
  ],
  // 'Typ 46',
  0xff: [
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 2,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 2,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 3,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 3,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
  ],
  // 'Typ 41',
  0xfc: [
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 1,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 2,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Indication2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 2,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
  ],
  // 'Typ 2',
  0x44: [
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-up',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 1,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: 'arrow-down',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 2,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
    {
      Time2: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Text: {
        value: '',
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      IndicationRls: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Channel: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
    },
  ],
}

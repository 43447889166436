
import { Component, Vue } from 'vue-property-decorator'
import UploadForm, { UploadState } from '@/components/common/UploadForm.vue'

import { ImageObject } from '@/apps/brelag/common/models/image'

@Component({
  name: 'formly_image-upload-field',
  components: {
    UploadForm,
  },
  props: ['form', 'field', 'model', 'to'],
  data() {
    return {
      imageUrl: null,
      imageWidth: '',
      imageHeight: '',
    }
  },
})
export default class ImageUploadField extends Vue {
  imageUrl: string
  imageWidth: string
  imageHeight: string

  updatedValue(value: UploadState[]) {
    if (value.length > 0) {
      const image = value[0]
      if (image.success) {
        this.$props.model[this.$props.field.key] = image.response.id
      }
    }
  }

  mounted() {
    if (
      this.$props.model.id === 0 ||
      !this.$props.model[this.$props.field.key]
    ) {
      return
    }

    let url
    if (
      this.$props.field.modelClass &&
      this.$props.field.modelClass === ImageObject.objectType
    ) {
      this.$apiv2
        .get<ImageObject>(ImageObject, this.$props.model[this.$props.field.key])
        .then((image) => {
          this.imageUrl = image.url
          this.imageWidth = image.specs.width.toString()
          this.imageHeight = image.specs.height.toString()
        })
    } else if (this.$props.field.imageUrl) {
      url = this.$props.field.imageUrl.replace('%', this.$props.model.id)
    }

    if (url && url.length > 0) {
      // The random part at the end is necessary, because otherwise Vue seems to cache the image and does not re-download if it has been changed
      this.imageUrl = `${url}?${Math.random()}`
    }
  }

  get additionalData() {
    // TODO: add means to input title and image meta data
    return {
      title: 'title',
      meta: JSON.stringify({}),
      organisation:
        this.$store.getters['global/organisation'] &&
        this.$store.getters['global/organisation'].id,
    }
  }

  get uploadUrl() {
    return this.$props.field.uploadUrl
  }

  get name() {
    return this.$props.field.name
  }

  get extensions() {
    if (this.$props.field.extensions) {
      return this.$props.field.extensions
    } else {
      return ['gif', 'jpg', 'jpeg', 'png']
    }
  }
}

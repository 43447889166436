import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=8ed974a0&scoped=true&"
import script from "./FormBuilder.vue?vue&type=script&lang=ts&"
export * from "./FormBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=8ed974a0&prod&lang=scss&scoped=true&"
import style1 from "./FormBuilder.vue?vue&type=style&index=1&id=8ed974a0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ed974a0",
  null
  
)

export default component.exports
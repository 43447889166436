import Vue from 'vue'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import { Building } from '@/apps/brelag/common/models/building'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  ListFilter,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'
import { CustomAction } from '@/components/common/interfaces'

export interface FloorPlanSettings {
  hideRepeatingLinks?: boolean
}

export interface FloorPatchSerializer {
  id: string
  floor_plan_settings?: FloorPlanSettings
  ordering?: number
}

export class Floor extends TransientBaseObject {
  building: string
  title: string
  prefix: string
  ordering: number
  original_file: string
  processed_file: string
  exported_file: string
  floor_plan_settings: FloorPlanSettings | null

  static apiUrl = 'brelag/floor'
  static langPath: string = 'apps.brelag.models.floor'
  static objectType: string = 'floor'
  static uploadUrl: string = '/api/v1/brelag/floor/upload-file'
  static originalDownloadUrl: string = '/api/v1/brelag/floor/%/original-file'
  static processedDownloadUrl: string = '/api/v1/brelag/floor/%/processed-file'
  static exportedDownloadUrl: string = '/api/v1/brelag/floor/%/exported-file'
  static fields: ModelField[] = [
    { key: 'title' },
    {
      key: 'building',
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        showFilter: false,
        relatedObjectProperty: 'title',
        modelClass: Building.objectType,
      },
    },
    {
      key: 'prefix',
      required: true,
      editable: false,
      tooltip: () => 'Optimal: 2 Zeichen',
    },
    {
      key: 'ordering',
      required: false,
      formFieldType: FormFieldType.ORDERING_FIELD,
    },
    {
      key: 'processed_file',
      required: false,
      formFieldType: FormFieldType.IMAGE_CROP,
      formProperties: {
        title: 'Stockwerkplan',
        original_key: 'original_file',
        modelClass: Floor.objectType,
        helpText:
          'Der Stockwerkplan wird als Hintergrundbild für den Projekt Editor benutzt. Erlaubte Formate: .PNG, .JPG, .PDF. Beachte: Ändern des Bildausschnitts kann im Projekt Editor platzierte Elemente verschieben.',
        dropFileText: 'Bild oder PDF-Datei hineinziehen zum Hochladen',
        confirmUploadMessage:
          'Das Hochladen eines neuen Plans setzt den aktuellen Ausschnitt zurück. Wollen Sie fortfahren?',
        uploadUrl: Floor.uploadUrl,
        originalDownloadUrl: Floor.originalDownloadUrl,
        processedDownloadUrl: Floor.processedDownloadUrl,
        exportedDownloadUrl: Floor.exportedDownloadUrl,
        name: 'file',
        extensions: ['jpg', 'jpeg', 'png', 'pdf'],
        accept: 'image/png,image/jpeg,application/pdf',
      },
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'building_title' },
    { key: 'title' },
    { key: 'prefix' },
  ]
  static listFilters: ListFilter[] = []
  static joins: Collection.RelatedAnnotation<Floor>[] = [
    {
      relatedModelClass: Building,
      relatedObjectProperty: 'title',
    },
  ]

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: Floor.formFields(),
      model: { ...FLOOR_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Floor, Floor>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(FLOOR_DEFAULT))
  }

  static insertBefore(vm: Vue, insert: Floor, before: Floor): Promise<void> {
    return vm.$apiv2.customPost('brelag/floor/insert-before', {
      insert: insert.id,
      before: before && before.id,
    })
  }

  static getProcessedDownloadUrl(floorId: string): string {
    // prevent caching
    return `${Floor.processedDownloadUrl.replace(
      '%',
      floorId
    )}?${Math.random()}`
  }

  static async beforeSaveHook(
    apiClient: ApiClientV2,
    floor: Floor
  ): Promise<any> {
    if (!floor.ordering && floor.ordering !== 0) {
      floor.ordering = -1
    }
  }

  static async copyFloor(apiClient: ApiClientV2, id: string) {
    return apiClient.customPost(`brelag/floor/${id}/copy`)
  }

  static copyFloorAction: CustomAction = {
    icon: 'mdi-content-copy',
    label: 'Stockwerk kopieren',
    type: 'is-black',
    callback: null,
  }
}

export const FLOOR_DEFAULT: Floor = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  building: '',
  prefix: '',
  original_file: '',
  processed_file: '',
  exported_file: '',
  title: '',
  ordering: null,
  floor_plan_settings: {
    hideRepeatingLinks: true,
  },
}


import axios from 'axios'
import BaseForm from '@/components/common/BaseForm.vue'
import store from '@/store/index'
import {
  ProfileActivation,
  ProfileActivationRequest,
} from '@/models/core/models'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'profile_activation',
  components: {
    'base-form': BaseForm,
  },
  props: {},
  data() {
    return {
      activateProfileRequest: {
        username: this.$route.query.username || '',
        email: this.$route.query.email || '',
        password1: '',
        password2: '',
      },
      profileActivation: {
        id: '',
        nonce: '',
        pin: '',
      },
    }
  },
})
export default class ActivateProfile extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  errorMessage: string = ''
  activateProfileRequest: ProfileActivationRequest
  profileActivation: ProfileActivation
  agbAccepted = false

  get activation() {
    return this.profileActivation.nonce !== ''
  }

  get canSave(): boolean {
    if (this.activation) {
      return this.agbAccepted
    } else {
      return this.passwordsMatch
    }
  }

  mounted() {
    axios
      .post(
        '/api/v1/auth/logout',
        {
          user: {
            id: store.state.global.profileId,
            is_authenticated: store.state.global.is_authenticated,
          },
        },
        store.state.global.axiosConfig
      )
      .then((response) => {
        store.dispatch({
          type: 'global/logoutUser',
        })
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  enterKey() {
    if (!this.activation) {
      this.requestActivateProfile()
    } else {
      this.activateProfile()
    }
  }

  requestActivateProfile() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    if (this.$store.state.global.usernameSameAsEmail) {
      this.activateProfileRequest.email = this.activateProfileRequest.username
    }
    this.$api
      .requestActivateProfile(this.activateProfileRequest)
      .then((response) => {
        loadingComponent.close()
        this.$refs.baseForm.clearError()
        this.profileActivation.nonce = response.data.nonce
        this.profileActivation.id = response.data.profile
      })
      .catch((error) => {
        loadingComponent.close()
        this.$refs.baseForm.handleError(error)
      })
  }

  activateProfile() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    this.$api
      .activateProfile(this.profileActivation)
      .then((response) => {
        loadingComponent.close()
        this.$router.push({ name: 'login' })
      })
      .catch((error) => {
        loadingComponent.close()
        this.profileActivation.nonce = ''
        this.profileActivation.pin = ''
        this.$refs.baseForm.handleError(error)
      })
  }

  get passwordsMatch() {
    if (
      this.activateProfileRequest.password1 ===
      this.activateProfileRequest.password2
    ) {
      return true
    } else {
      if (
        this.activateProfileRequest.password1 !== '' &&
        this.activateProfileRequest.password2 !== ''
      ) {
        this.errorMessage = this.$tc('models.auth.passwordNoMatch')
        return false
      } else {
        return true
      }
    }
  }
}

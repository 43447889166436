
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  ClientAppSetting,
  ClientAppSettingKind,
  CLIENT_APP_SETTING_KIND_DEFAULT,
  CLIENT_APP_SETTING_DEFAULT,
  ClientApp,
} from '@/models/client/models'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Context } from '@/api/ApiClientV2'
import { createSuccessMessage } from '@/lang/setup'

@Component({})
export default class ClientAppSettings extends Vue {
  @Prop({ required: true })
  clientApp: string

  settings: ClientAppSetting[] = []
  settingKinds: ClientAppSettingKind[] = []
  destroySubject = new Subject<void>()

  mounted() {
    this.getSettings()
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getSettings()
      })
  }

  destroyed() {
    this.destroySubject.next()
    this.destroySubject.complete()
  }

  saveSettings() {
    this.$apiv2.refreshData()
  }

  async getSettings() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      this.settingKinds = await this.getSettingKinds()
      this.settings = await ClientApp.getSettings(this.$apiv2, this.clientApp)
    } catch (error) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(error),
        type: 'is-danger',
      })
    }
    loadingComponent.close()
  }

  async deleteSetting(setting: ClientAppSetting) {
    const key = setting.key
    const id = setting.id
    const object_seq = setting.object_seq
    if (id === '0') {
      this.getSettings()
      return
    }
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      await ClientApp.deleteSetting(
        this.$apiv2,
        this.clientApp,
        key,
        object_seq
      )
      await this.getSettings()
    } catch (error) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(error),
        type: 'is-danger',
      })
    }
    loadingComponent.close()
  }

  addSetting() {
    this.settings.push(JSON.parse(JSON.stringify(CLIENT_APP_SETTING_DEFAULT)))
  }

  async getSettingKinds(): Promise<ClientAppSettingKind[]> {
    try {
      const context: Context = {
        filter: {
          client_app: this.clientApp,
        },
        pagination: {},
      }
      return await this.$apiv2.getListItems<ClientAppSettingKind>(
        ClientAppSettingKind,
        context
      )
    } catch (error) {
      console.warn(error)
      return Promise.resolve([])
    }
  }

  async addDefaultSettingKind() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      await this.$apiv2.create(ClientAppSettingKind, {
        ...CLIENT_APP_SETTING_KIND_DEFAULT,
        name: 'default',
        handle: 'default',
        client_app: this.clientApp,
      })
      this.settingKinds = await this.getSettingKinds()
      this.$buefy.toast.open({
        message: createSuccessMessage(ClientAppSettingKind),
        type: 'is-success',
      })
    } catch (error) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(error),
        type: 'is-danger',
      })
    }
    loadingComponent.close()
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator'

import {
  SwwConfig,
  SWW_CHANNELS,
  CMD_LIST,
  PRIORITIES,
  SWW_SENSORS,
} from '@/apps/brelag/common/models/swwParams'
import { reverseString } from '@/util/util'

@Component({
  components: {},
  props: {
    rule: {
      required: true,
    },
  },
  data() {
    return {
      SWW_CHANNELS,
      CMD_LIST,
      PRIORITIES,
      SWW_SENSORS,
      lowerValue: '< unterer Schwellwert',
      lessThan: '<',
    }
  },
})
export default class SwwRule extends Vue {
  config: SwwConfig
  action: number = null
  conditions: number[] = null

  parseConditions() {
    let conditionBits = ['0', '0', '0', '0', '0', '0', '0', '0']
    let actionBits = ['0', '0', '0', '0', '0', '0', '0', '0']
    for (let i = 0; i < 8; i++) {
      if (this.$props.rule.channel.value === i) {
        conditionBits[i] = '0'
        if (this.action === 0) {
          actionBits[i] = '0'
        } else {
          actionBits[i] = '1'
        }
      } else {
        if (this.conditions[i] === 0) {
          actionBits[i] = '0'
          conditionBits[i] = '0'
        } else if (this.conditions[i] === 1) {
          actionBits[i] = '1'
          conditionBits[i] = '1'
        } else {
          actionBits[i] = '0'
          conditionBits[i] = '1'
        }
      }
    }
    this.$props.rule.channelCondition.value = parseInt(
      conditionBits.reverse().join(''),
      2
    )
    this.$props.rule.onActive.value = parseInt(actionBits.reverse().join(''), 2)
  }

  changeCondition(index: number) {
    if (this.conditions[index] === 0 || this.conditions[index] === 1) {
      Vue.set(this.conditions, index, this.conditions[index] + 1)
    } else {
      Vue.set(this.conditions, index, 0)
    }
    this.parseConditions()
  }

  @Watch('action')
  actionChanged() {
    this.parseConditions()
  }

  @Watch('rule.channel.value')
  channelChanged() {
    this.parseConditions()
  }

  mounted() {
    let actionBitsString = this.$props.rule.onActive.value.toString(2)
    actionBitsString = ('00000000' + actionBitsString).substring(
      actionBitsString.length
    )
    actionBitsString = reverseString(actionBitsString)
    let conditionBitsString =
      this.$props.rule.channelCondition.value.toString(2)
    conditionBitsString = ('00000000' + conditionBitsString).substring(
      conditionBitsString.length
    )
    conditionBitsString = reverseString(conditionBitsString)

    this.conditions = [0, 0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < 8; i++) {
      if (this.$props.rule.channel.value === i) {
        if (actionBitsString[i] === '0') {
          this.action = 0
        } else {
          this.action = 1
        }
      } else {
        if (actionBitsString[i] === '0' && conditionBitsString[i] === '0') {
          Vue.set(this.conditions, i, 0)
        } else if (
          actionBitsString[i] === '1' &&
          conditionBitsString[i] === '1'
        ) {
          Vue.set(this.conditions, i, 1)
        } else {
          Vue.set(this.conditions, i, 2)
        }
      }
    }
  }
}

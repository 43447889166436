import {
  MaxFlexConfig,
  MaxFlexKeyConfig,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import { simpleCopy } from '@/util/util'

export const WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS = {
  // Gateway Sender. Article Nr: 4012134/99998
  wescoGatewaySenderArticleNr: '4012134/99998',
  // Gateway Receiver. Article Nr: 4012134/99997
  wescoGatewayReceiverArticleNr: '4012134/99997',
  // Steuertastatur weiss/schwarz. (MaxFlex 8-Fach) Typ 46, alles Kanal 1, Befehl=None
  wescoSTFWhiteArticleNr: '4012135', // Article Nr: 4012135
  wescoSTFBlackArticleNr: '4012136', // Article Nr: 4012136
  // Lichttaster LTF für Kochfeld weiss/schwarz. (MaxFlex 1-Fach) Typ 1, Kanal 2, Befehl=None
  wescoCooktopLTFWhiteArticleNr: '4012765', // Article Nr: 4012765
  wescoCooktopLTFBlackArticleNr: '4012766', // Article Nr: 4012766
  // Lichttaster LTF für Effektlicht weiss/schwarz. (MaxFlex 1-Fach) Typ 1, Kanal 3, Befehl=None
  wescoEffectLTFWhiteArticleNr: '4012138', // Article Nr: 4012138
  wescoEffectLTFBlackArticleNr: '4012139', // Article Nr: 4012139
  // Anbindung Licht Extern Kochfeld LEF (Kochfeldlicht).  LX Dimm no Limit, Kanal 2 am Gateway
  wescoCooktopLEFArticleNr: '4012767', // Article Nr: 4012767
  // Anbindung Licht Extern Effektlicht LEF (Effektlicht).  LX Dimm no Limit, Kanal  am Gateway
  wescoEffectLEFArticleNr: '4012143', // Article Nr: 4012143
  // Anbindung Zu-/Abluftlösungen ZAF 230V
  wescoVentilationMxFeUltraArticleNr: '4013322', // (MX FE ULTRA)
}

const DefaultKeyConfig: MaxFlexKeyConfig = {
  Dummy2: { value: 0 },
  Time2: { value: 0 },
  Dummy5: { value: 0 },
  Indication2: { value: 0 },
  Time1: { value: 0 },
  Icon: { value: '' },
  TimeRls: { value: 0 },
  IndicationRls: { value: 0 },
  Command2: { value: 0 },
  Text: { value: '' },
  CommandRls: { value: 0 },
  Dummy1: { value: 0 },
  DelayRls: { value: 0 },
  Indication1: { value: 0 },
  Dummy4: { value: 0 },
  Priority: { value: 0 },
  Command1: { value: 0 },
  Dummy3: { value: 0 },
  CommandGrp: { value: 0 },
  Dummy6: { value: 0 },
  Channel: { value: 0 },
  Dummy7: { value: 0 },
}

const getDefaultKeysConfig = (): MaxFlexKeyConfig[] => {
  const out: MaxFlexKeyConfig[] = []
  for (let i = 0; i < 8; i++) {
    out.push({
      ...DefaultKeyConfig,
    })
  }
  return simpleCopy(out)
}

const getDefaultKochfeldKeysConfig = (): MaxFlexKeyConfig[] => {
  const out: MaxFlexKeyConfig[] = getDefaultKeysConfig()
  out[6].Channel.value = 1
  return out
}

const getDefaultEffektlichtKeysConfig = (): MaxFlexKeyConfig[] => {
  const out: MaxFlexKeyConfig[] = getDefaultKeysConfig()
  out[6].Channel.value = 2
  return out
}

// Default configs for specific maxflex types
export const WESCO_MAXFLEX_STEUERTASTATUR_DEFAULT_CONFIG: MaxFlexConfig = {
  KeyLayout: { value: 255 },
  TxAddress1: { value: 1 },
  TxAddress2: { value: 1 },
  TxAddress3: { value: 1 },
  TxAddress4: { value: 1 },
  TxAddress5: { value: 1 },
  TxAddress6: { value: 1 },
  PasswordNew1: { value: 0 },
  PasswordNew2: { value: 0 },
  PasswordNew3: { value: 0 },
  PasswordNew4: { value: 0 },
  PasswordNew5: { value: 0 },
  PasswordNew6: { value: 0 },
  PasswordOld1: { value: 0 },
  PasswordOld2: { value: 0 },
  PasswordOld3: { value: 0 },
  PasswordOld4: { value: 0 },
  PasswordOld5: { value: 0 },
  PasswordOld6: { value: 0 },
  TxType: { value: 0 },
  Bitfield: { value: 0 },
  LocatingKey: { value: 0 },
  LocatingIntensity: { value: 100 },
  LocatingPeriod: { value: 100 },
  FeedbackIntensity: { value: 100 },
  keys: getDefaultKeysConfig(),
}

export const WESCO_MAXFLEX_LICHTTASTER_KOCHFELD_DEFAULT_CONFIG: MaxFlexConfig =
  {
    ...WESCO_MAXFLEX_STEUERTASTATUR_DEFAULT_CONFIG,
    KeyLayout: { value: 64 },
    keys: getDefaultKochfeldKeysConfig(),
  }

export const WESCO_MAXFLEX_LICHTTASTER_EFFEKTLICHT_DEFAULT_CONFIG: MaxFlexConfig =
  {
    ...WESCO_MAXFLEX_STEUERTASTATUR_DEFAULT_CONFIG,
    KeyLayout: { value: 64 },
    keys: getDefaultEffektlichtKeysConfig(),
  }

export const WESCO_MX_FE_ULTRA_VENTILATION_DEFAULT_CONFIG = {
  keyButtonSwitch: 0,
  keyMinimumTravelTime: 65000,
  keyNeverLock: 0,
  keyNoLuffing: 0,
  keyRotationInversion: 0,
  keyZeroCurrentDetectionThreshold: 200,
}

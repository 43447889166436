
import { CollectionSubscriber } from '@/api/ApiClient'
import { PRETTY_NAMES } from '@/models/core/defaults'
import EditableCell from './EditableCell.vue'
import { BaseObject, LifeCycleState } from '@/models/core/base'
import ObjectStateTag from '../common/ObjectStateTag.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import ParentSelectors from './ParentSelectors.vue'
import ListPagination from './ListPagination.vue'

import { getClientAppOfRoute } from '@/apps/routingUtils'
import { clientAppRouteName } from '@/apps/clientAppRegistry'
import { objectFromMap } from '@/util/util'
import { DataStateDisplay } from '@/util/DataStateDisplay'
import { getObjectTypesUp } from '@/models/objectRegistry'
import { copyTextToClipboard } from '@/util/clipboard'
import MultilineTooltip from '@/components/common/MultilineTooltip.vue'

interface Column {
  label: string
  fieldName: string
  sortable?: boolean
  sortFieldName?: string
  type?: string
  transform?: () => {}
}

@Component({
  name: 'base-list',
  methods: {
    copyTextToClipboard,
  },
  components: {
    'object-state-tag': ObjectStateTag,
    'editable-cell': EditableCell,
    ParentSelectors,
    ListPagination,
    MultilineTooltip,
  },
  props: {
    objectType: {
      required: true,
    },
    objectCollection: {
      required: true,
    },
    addButtonText: {
      default: '',
    },
    columns: {
      default: () => [],
    },
    customActions: {
      default: () => [],
    },
    hasActions: {
      default: true,
    },
    canCopy: {
      default: false,
    },
    canAdd: {
      default: true,
    },
    canDelete: {
      default: true,
    },
    canSelect: {
      default: false,
    },
    singleSelection: {
      default: false,
    },
    preSelectedRows: {
      default: () => [],
    },
    unselectableRows: {
      default: () => [],
    },
    hasDetailView: {
      default: true,
    },
    detailExtraParams: {
      default: () => {
        return
      },
    },
    createRouteExtras: {
      default: () => {
        return
      },
    },
    hasLifeCycle: {
      default: true,
    },
    detailRouteName: {
      default: '',
    },
    createRouteName: {
      default: '',
    },
    customDelete: {
      default: null,
    },
    customDetail: {
      default: null,
    },
    loading: {
      default: false,
    },
    errorMessage: {
      default: () => {
        return {
          cantSelectRow: 'Row can not be selected',
        }
      },
    },
    helpMessage: {
      default: null,
    },
  },
  data() {
    return {
      rowSelections: {},
      columnsClone: this.$props.columns,
    }
  },
})
export default class BaseList extends Vue {
  objectCollection: CollectionSubscriber
  sortOrder: string = 'desc'
  defaultSortOrder: string = 'desc'
  errorMessages: string[] = []
  error: boolean = false
  rowSelections: object
  columnsClone: Column[] = []
  delay: number = 400
  clicks: number = 0
  timer = null

  created() {
    if (!this.$props.objectCollection) {
      this.$router.replace({
        name: 'error',
        params: {
          errors: JSON.stringify([
            `BaseList: The objectCollection for type '${this.$props.objectType}' is null/undefined!`,
          ]),
        },
      })
    }
  }

  mounted() {
    this.objectCollection = this.$props.objectCollection
    this.objectCollection.status.readyPromise.then(() => {
      return
    })
    this.$props.preSelectedRows.forEach((rowId: string) => {
      this.rowSelections[rowId] = rowId
    })
  }

  isSelectable(rowId: string) {
    return !(this.$props.unselectableRows.indexOf(rowId) > -1)
  }

  onPageChange(page) {
    if (!this.$store.getters['global/navigationIsActive']) {
      const redirect = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page,
        },
      }
      this.$router.push(redirect)
    }
  }

  onSort(field, order) {
    let sortField = field
    this.columnsClone.forEach((column: Column) => {
      if (column.fieldName === field) {
        if (column.sortFieldName) {
          sortField = column.sortFieldName
        }
      }
    })

    const redirect = {
      path: this.$route.path,
      query: {
        ...this.$route.query,
        order_by: sortField + (order === 'desc' ? '_dsc' : ''),
      },
    }
    this.$router.push(redirect)
  }

  copyRowData(row: any) {
    this.clicks++
    if (this.clicks === 1) {
      // We copy the ID already because Firefox does not allow this function inside the timeout handler
      copyTextToClipboard(row.id)
      this.timer = setTimeout(() => {
        this.$buefy.toast.open({
          message: 'Copied ID!',
          type: 'is-success',
        })
        this.clicks = 0
      }, this.delay)
    } else {
      clearTimeout(this.timer)
      copyTextToClipboard(JSON.stringify(row, null, 2))
      this.$buefy.toast.open({
        message: 'Copied data!',
        type: 'is-success',
      })
      this.clicks = 0
    }
  }

  onSelect(row) {
    if (this.$props.canSelect) {
      this.select(row)
    }
  }

  get initialSort() {
    if (this.objectCollection.filter && this.objectCollection.filter.order_by) {
      const match =
        this.objectCollection.filter.order_by.match(/^([a-z_]*?)(_dsc)?$/)
      if (match.length >= 2) {
        return [match[1], match[2] ? 'desc' : 'asc']
      }
    }
    return []
  }

  get idString() {
    return 'id'
  }

  getCreateRouteName() {
    if (this.$props.createRouteName === '') {
      const clientApp = getClientAppOfRoute(this.$route)
      return clientApp
        ? clientAppRouteName(
            clientApp.view_id,
            this.$props.objectType + '-create'
          )
        : ''
    }
    return this.$props.createRouteName
  }

  getDetailRouteName() {
    if (this.$props.detailRouteName === '') {
      const clientApp = getClientAppOfRoute(this.$route)
      return clientApp
        ? clientAppRouteName(
            clientApp.view_id,
            this.$props.objectType + '-detail'
          )
        : ''
    }
    return this.$props.detailRouteName
  }

  addNew() {
    let createRouteExtras = this.$props.createRouteExtras

    if (!createRouteExtras) {
      // no explicit create route extras given. generate query ids from context
      // use ancestors objectTypes
      let ancestors = getObjectTypesUp(this.$props.objectType)
      createRouteExtras = {
        query: objectFromMap(
          this.$store.getters['global/idsFromContext'](ancestors)
        ),
      }
    }

    return {
      name: this.getCreateRouteName(),
      ...createRouteExtras,
    }
  }

  getDetailLinkTo(row, copy: boolean = false) {
    if (this.$props.customDetail !== null) {
      return this.$props.customDetail(row)
    }

    let params = {
      [this.idString]: row.id,
      templateId: '',
    }
    if (copy) {
      params = {
        [this.idString]: '0',
        templateId: row.id,
      }
    }
    return {
      name: this.getDetailRouteName(),
      params: {
        ...params,
        ...this.$props.detailExtraParams,
      },
    }
  }

  confirmDelete(object: BaseObject) {
    if (this.$props.customDelete !== null) {
      this.$props.customDelete(object)
      return
    }

    this.$buefy.dialog.confirm({
      message: 'Are you sure that you want to delete this object?',
      onConfirm: () => this.deleteObject(object),
    })
  }

  deleteObject(object: BaseObject) {
    let purge = false
    if (object.object_state === LifeCycleState.Deleted) {
      purge = true
    }
    return this.$api
      .delete(this.$props.objectType, object.id, purge)
      .then(() => {
        // If this object was in current context selection, remove it
        return this.$store.dispatch('global/deselectObjectType', {
          objectType: this.$props.objectType,
          id: object.id,
        })
      })
      .then(() => {
        this.$buefy.toast.open({
          message: 'Successfully deleted!',
          type: 'is-success',
        })
        this.error = false
        this.$emit('deleted', object)
      })
      .catch((error) => {
        this.errorMessages = this.$errorHandler.errorToStrings(error)
        this.error = true
      })
  }

  async cellEdited(row, column, newValue) {
    row[column.fieldName] = newValue
    await this.$api.update(this.$props.objectType, row)
    this.$emit('cell-edited', row, column.fieldName, newValue)
    this.$buefy.toast.open({
      message: 'Successfully saved!',
      type: 'is-success',
    })
  }

  transformField(row, column) {
    if (column.transform !== undefined) {
      return column.transform(row[column.fieldName])
    } else {
      return row[column.fieldName]
    }
  }

  get prettyName() {
    if (this.$props.addButtonText === '') {
      if (this.$props.objectType in PRETTY_NAMES) {
        // use proper pretty name in the case of two words
        return PRETTY_NAMES[this.$props.objectType]
      } else {
        return (
          this.$props.objectType.charAt(0).toUpperCase() +
          this.$props.objectType.slice(1)
        )
      }
    } else {
      return this.$props.addButtonText
    }
  }

  isAsync(column) {
    return column.async === true
  }

  isAsyncLoading(row, column) {
    return row[column.fieldName] === DataStateDisplay.Loading
  }

  isType(column, type: string) {
    if (column.type !== undefined) {
      return column.type === type
    }
  }

  emitSelected() {
    const selected = []
    for (const id in this.rowSelections) {
      if (this.rowSelections[id] && this.rowSelections[id] !== null) {
        selected.push(this.rowSelections[id])
      }
    }
    this.$emit('row-selection', selected)
  }

  handleError(error) {
    this.errorMessages = this.$errorHandler.errorToStrings(error)
    this.error = true
  }

  select(row: any): void {
    if (!this.isSelectable(row.id)) {
      this.$buefy.toast.open({
        message: this.$props.errorMessage.cantSelectRow,
        type: 'is-warning',
      })
      return
    }
    if (this.rowSelections[row.id]) {
      this.$set(this.rowSelections, row.id, null)
    } else {
      if (this.$props.singleSelection) {
        for (const id in this.rowSelections) {
          if (this.rowSelections[id]) {
            this.$set(this.rowSelections, id, null)
          }
        }
      }
      this.$set(this.rowSelections, row.id, row)
    }
    this.emitSelected()
  }
}

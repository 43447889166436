import { ClientAppEntry, clientAppRouteName } from '@/apps/clientAppRegistry'

export const VIEW_ID = 'brelag-developer'

export const CLIENT_APP: ClientAppEntry = {
  name: 'Brelag Developer',
  path: 'brelag-developer',
  icon: 'mdi-code-tags',
  defaultPathName: 'dashboard',
}

export function brelagDeveloperRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName)
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { TheMask } from 'vue-the-mask'
import hotkeys from 'hotkeys-js'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'

import {
  getMaxFlexLayoutByValue,
  MaxFlexConfig,
  LocatingKeyButtonMap,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import MaxFlexLayoutView from './MaxFlexLayoutView.vue'
import MaxFlexButtonConfigEditor from './MaxFlexButtonConfigEditor.vue'

@Component({
  name: 'maxflex-config-editor',
  components: {
    MaxFlexLayoutView,
    MaxFlexButtonConfigEditor,
    TheMask,
  },
  props: {
    config: {
      required: true,
    },
  },
  data() {
    return {
      txAddressError: false,
      newPasswordError: false,
      buttonConfigModalActive: false,
      txTokens: {
        F: {
          pattern: /[1-8]/,
        },
      },
      pwTokens: {
        F: {
          pattern: /[0-8]/,
        },
      },
    }
  },
})
export default class MaxFlexConfigEditor extends Vue {
  config: MaxFlexConfig
  originalConfig: MaxFlexConfig
  txAddressError: boolean
  newPasswordError: boolean
  buttonConfigModalActive: boolean
  selectedButtonConfig: {
    buttonNum: number
    config: MaxFlexConfig
  } = null

  mounted() {
    hotkeys('esc', { scope: 'MaxFlexConfigEditor', keyup: true }, (event) => {
      // only fire event on keyup to avoid closing confirm dialog directly
      if (event.type === 'keyup') {
        event.preventDefault()
        this.cancel()
      }
    })
    hotkeys.setScope('MaxFlexConfigEditor')

    // keep original config to detect changes
    this.originalConfig = JSON.parse(JSON.stringify(this.config))
  }

  destroyed() {
    hotkeys.unbind('esc', 'MaxFlexConfigEditor')
    hotkeys.setScope('EditModal') // TODO
  }

  cancel() {
    if (isEqual(this.config, this.originalConfig)) {
      // no changes, close immediatly
      this.closeModal()
    } else {
      this.$buefy.dialog.confirm({
        message: 'Sind Sie sicher, dass Sie die Änderung verwerfen möchten?',
        onConfirm: () => {
          // revert changes
          // need to merge into config object, cannot reassign this.config to a new object
          merge(this.config, this.originalConfig)
          this.closeModal()
        },
      })
    }
  }

  closeModal() {
    this.$emit('close')
  }

  changeLayout() {
    this.$emit('changeLayout')
  }

  get layout() {
    return getMaxFlexLayoutByValue(this.config.KeyLayout.value)
  }

  get txAddress() {
    return `${this.config.TxAddress1.value}${this.config.TxAddress2.value}${this.config.TxAddress3.value}${this.config.TxAddress4.value}${this.config.TxAddress5.value}${this.config.TxAddress6.value}`
  }

  get passwordNew() {
    return `${String.fromCharCode(
      this.config.PasswordNew1.value
    )}${String.fromCharCode(
      this.config.PasswordNew2.value
    )}${String.fromCharCode(
      this.config.PasswordNew3.value
    )}${String.fromCharCode(
      this.config.PasswordNew4.value
    )}${String.fromCharCode(
      this.config.PasswordNew5.value
    )}${String.fromCharCode(this.config.PasswordNew6.value)}`
  }

  passwordNewChanged(value) {
    this.newPasswordError = false
    if (!value) {
      // value is empty -> Reset password
      this.config.PasswordNew1.value = 48
      this.config.PasswordNew2.value = 48
      this.config.PasswordNew3.value = 48
      this.config.PasswordNew4.value = 48
      this.config.PasswordNew5.value = 48
      this.config.PasswordNew6.value = 48
    } else if (value.length !== 6) {
      // We get a string of length 0 to 6 with values 0-8
      this.newPasswordError = true
      return
    } else {
      this.config.PasswordNew1.value = value[0].charCodeAt(0)
      this.config.PasswordNew2.value = value[1].charCodeAt(0)
      this.config.PasswordNew3.value = value[2].charCodeAt(0)
      this.config.PasswordNew4.value = value[3].charCodeAt(0)
      this.config.PasswordNew5.value = value[4].charCodeAt(0)
      this.config.PasswordNew6.value = value[5].charCodeAt(0)
    }
  }

  txAddressChanged(value) {
    // We get a string of length 0 to 6 with values 1-8
    if (value.length !== 6) {
      this.txAddressError = true
      return
    } else {
      this.txAddressError = false
    }
    this.config.TxAddress1.value = parseInt(value[0])
    this.config.TxAddress2.value = parseInt(value[1])
    this.config.TxAddress3.value = parseInt(value[2])
    this.config.TxAddress4.value = parseInt(value[3])
    this.config.TxAddress5.value = parseInt(value[4])
    this.config.TxAddress6.value = parseInt(value[5])

    this.checkUpdateLinked()
  }

  checkUpdateLinked() {
    if (
      this.config.TxAddress1.value !== this.originalConfig.TxAddress1.value ||
      this.config.TxAddress2.value !== this.originalConfig.TxAddress2.value ||
      this.config.TxAddress3.value !== this.originalConfig.TxAddress3.value ||
      this.config.TxAddress4.value !== this.originalConfig.TxAddress4.value ||
      this.config.TxAddress5.value !== this.originalConfig.TxAddress5.value ||
      this.config.TxAddress6.value !== this.originalConfig.TxAddress6.value ||
      (this.originalConfig.TxType.value === 0 &&
        this.config.TxType.value !== 0) ||
      (this.originalConfig.TxType.value !== 0 && this.config.TxType.value === 0)
    ) {
      // TxAddress changed OR type changed away from standard OR type changed back to standard
      // -> should update linked receivers
      this.config.updateLinked = true
    } else {
      this.config.updateLinked = false
    }
  }

  selectKey(layoutName: string, blockNum: number) {
    this.buttonConfigModalActive = true
    this.selectedButtonConfig = {
      buttonNum: blockNum,
      config: this.config,
    }
  }

  onButtonConfigChanged(nightLED: boolean, buttonNum: number) {
    if (nightLED) {
      // Night LED has been turned on for this button
      this.config.LocatingKey.value = LocatingKeyButtonMap[buttonNum]
    } else {
      this.config.LocatingKey.value = 0
    }
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'dashboard-collection-tile',
  props: {
    collection: {
      required: true,
    },
    name: {
      required: true,
    },
    createRoute: {
      required: false,
    },
    listRoute: {
      required: true,
    },
    routeNameFunction: {
      required: true,
    },
  },
})
export default class DashboardCollectionTile extends Vue {}

import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  Ancestor,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

import { FormFieldType } from '@/components/common/forms/formBuilderHelper'

import { Organisation } from '@/models/core/organisation'

import { Project } from '@/apps/brelag/common/models/project'

import { Collection, ApiClientV2 } from '@/api/ApiClientV2'

import Vue from 'vue'
import { FloorDump } from '@/apps/brelag/mandator-user/project-editor/projectEditor'

export class Building extends TransientBaseObject {
  project: string
  title: string
  prefix: string
  ordering: number
  floors?: FloorDump[]

  static apiUrl = 'brelag/building'
  static langPath: string = 'apps.brelag.models.building'
  static objectType: string = 'building'
  static fields: ModelField[] = [
    { key: 'title' },
    {
      key: 'project',
      required: true,
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        editable: false,
        relatedObjectProperty: 'title',
        modelClass: 'project',
        filter: [Organisation.objectType],
      },
    },
    {
      key: 'prefix',
      required: true,
      editable: false,
      tooltip: () => 'Optimal: 2 Zeichen',
    },
    {
      key: 'ordering',
      required: false,
      formFieldType: FormFieldType.ORDERING_FIELD,
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'title' },
    { key: 'project_title' },
    { key: 'prefix' },
  ]
  public static ancestors: Ancestor[] = [
    {
      modelClass: Project,
      mainParent: true,
    },
  ]
  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static joins: Collection.RelatedAnnotation<Building>[] = [
    {
      relatedModelClass: Project,
      relatedObjectProperty: 'title',
    },
  ]

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: Building.formFields(),
      model: { ...BUILDING_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(vm: Vue, filter, pagination) {
    return vm.$apiv2.subscribe<Building, Building>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(BUILDING_DEFAULT))
  }

  static insertBefore(
    vm: Vue,
    insert: Building,
    before: Building
  ): Promise<void> {
    return vm.$apiv2.customPost('brelag/building/insert-before', {
      insert: insert.id,
      before: before && before.id,
    })
  }

  static async beforeSaveHook(
    apiClient: ApiClientV2,
    building: Building
  ): Promise<any> {
    if (!building.ordering && building.ordering !== 0) {
      building.ordering = -1
    }
  }
}

export const BUILDING_DEFAULT: Building = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  project: '',
  prefix: '',
  title: '',
  ordering: null,
}

import {
  FormFieldType,
  FormBuilderHelper,
} from '@/components/common/forms/formBuilderHelper'
import Vue from 'vue'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import {
  SvgPosition,
  SvgAttributes,
  CreateDeviceSerializer,
  BatchDeleteDevicesInputSerializer,
} from '@/apps/brelag/mandator-user/models/editor'
import { ClientApp, ClientAppSetting } from '@/models/client/models'
import { eGateConfig } from '@/apps/brelag/common/models/eGateConfig'
import { ImageObject } from '@/apps/brelag/common/models/image'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  ListFilter,
  Ancestor,
  CellType,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'
import { MAX_FLEX_TYPE_MAP } from './maxflex/maxflex'
import { ProjectEditor } from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import { getTranslation } from '@/lang/setup'
import * as Sentry from '@sentry/browser'

export enum BrelagConfigKey {
  ULTRA_RECEIVER = 'keyTxAddress',
  MAXFLEX = 'maxflex',
  EGATE = 'egate',
  SWW = 'sww',
}

export enum EquipmentCategory {
  SENDER = 'sender',
  RECEIVER = 'receiver',
  other = 'other',
}

export enum AddressNumeralSystem {
  DECIMAL = 'decimal',
  HEX = 'hex',
  OCTA = 'octa',
}

export function deviceState(
  radio_address: string,
  dirty: boolean,
  dirty_optional: boolean,
  dirty_teaching: boolean
): 'unknown' | 'dirty' | 'done' {
  if (!radio_address) {
    return 'unknown'
  } else if (dirty || dirty_optional || dirty_teaching) {
    return 'dirty'
  } else {
    return 'done'
  }
}

export class DeviceAssignment extends TransientBaseObject {
  project?: string
  floor: string
  category: EquipmentCategory
  number: number
  device_number: string
  radio_address?: string
  dirty: boolean
  dirty_optional: boolean
  dirty_teaching: boolean
  recognized_at: string | null
  recognized_by: string | null
  last_configured_at: string | null
  last_configured_by: string | null
  config: {
    [key: string]: any
  }
  programmer_connection?: {
    RxAddress?: number
    TxChannel?: number
    TxAddress?: number
    [key: string]: any
  }
  programmer_device_info?: {
    SwVersion?: number
    Address?: number
    SerNr?: number
    DeviceType?: number
    [key: string]: any
  }
  programmer_device_configuration?: {
    data: number[]
  }
  programmer_device_configuration_optional?: {
    data: number[]
  }
  meta: {
    svgAttributes: SvgAttributes
    svgObjectPositions?: SvgPosition[]
    svgObjects?: {
      links?: any
      nodes?: any
      markers?: any
      connector?: any
      groups: any[]
    }
  } | null
  equipment?: string
  equipment_variant?: string

  static apiUrl = 'brelag/device-assignment'
  static langPath = 'apps.brelag.models.deviceassignment'
  static objectType = 'deviceassignment'

  static get defaultModel() {
    return JSON.parse(JSON.stringify(DEVICE_ASSIGNMENT_DEFAULT))
  }

  static safeCopy(device: DeviceAssignment): DeviceAssignment {
    const { meta, ...rest } = device
    const data = JSON.parse(JSON.stringify(rest))
    if (meta) {
      const { svgObjects, ...metaRest } = meta
      data.meta = metaRest
    }
    return data
  }

  /**
   * Formats the device into specified numeral system
   * Note: Octal system is special brelag format of normal octal system
   * @param address
   * @param numeralSystem
   */
  static formatDeviceAddress(
    address: number,
    numeralSystem: AddressNumeralSystem
  ): string {
    if (numeralSystem == AddressNumeralSystem.DECIMAL) {
      return address.toString()
    } else if (numeralSystem == AddressNumeralSystem.HEX) {
      return `0x${('000000' + address.toString(16)).substr(-6)}`
    } else if (numeralSystem == AddressNumeralSystem.OCTA) {
      const octalString = ('00000000' + address.toString(8)).substr(-8)
      // Octa + 1 -> instead of 10644120 we generate 21755231
      const octalPlusOne = Array.from(octalString)
        .map((letter) => parseInt(letter) + 1)
        .join('')
      // Format as 2-175523-1
      return `${
        octalPlusOne[0] +
        '-' +
        octalPlusOne.substr(1, 6) +
        '-' +
        octalPlusOne[7]
      }`
    } else {
      return address.toString()
    }
  }

  /**
   * Creates a pretty string from a SW Version number
   * Example: 4900 = 0x00001324 => 13.24
   * @param version
   */
  static prettySwVersion(version: number): string {
    // creates 8 bit hex string. e.g. 4900 -> 00001324
    const hexString = ('00000000' + version.toString(16)).substr(-8)
    // Take last two digits
    const suffix = hexString.substring(6, 8)
    // Take first 6 digits and remove any zeros at the beginning
    const prefix = parseInt(hexString.substring(0, 6)).toString()
    return `${prefix}.${suffix}`
  }

  static prettyDeviceVariantName(device: DeviceAssignment): string {
    const variant =
      ProjectEditor.getInstance().equipmentHandler.getVariantFromDeviceAssignment(
        device
      )
    if (variant) {
      if (ProjectEditor.getInstance().deviceHandler.isMaxFlex(device.id)) {
        // add max flex type
        const type = MAX_FLEX_TYPE_MAP[device.config.maxflex.KeyLayout.value]
        return `${variant.device_variant_name} ${type}`
      } else {
        return variant.device_variant_name
      }
    } else {
      Sentry.captureMessage('could not get variant from device assignment')
      console.warn('could not get variant from device assignment')
      return ''
    }
  }

  static getInternalConfig(config: any, equipmentConfig: any) {
    return DeviceAssignment.getExternalConfig(
      config,
      equipmentConfig,
      true,
      false,
      true
    )
  }

  /**
   * Returns a valid configuration of a device config given its corresponding equipment config
   * @param config device config
   * @param equipmentConfig equipment default config (can include .extraData)
   * @param internalConfig if true, will return the internal key names, otherwise the external key names (if present)
   * @param defaultKeyName if true, will use the default key names
   * @param showDefaultValues if true, will return all values of config, even those that are same as default value
   */
  static getExternalConfig(
    config: any,
    equipmentConfig: any,
    internalConfig: boolean = false,
    defaultKeyName: boolean = false,
    showDefaultValues: boolean = false
  ) {
    const equipmentConfigFields =
      FormBuilderHelper.getFieldsFromConfig(equipmentConfig)
    const parsedConfig = {}

    const hasDataId = (key: string, equipmentConfigFields: any[]): boolean => {
      let hasDataId = false
      for (const config of equipmentConfigFields) {
        if (key === config.key) {
          if (
            config.properties &&
            config.properties.extraData &&
            config.properties.extraData.dataID
          ) {
            hasDataId = true
          }
        }
      }
      return hasDataId
    }

    /**
     * Gets the internal/external name of the key if specified, otherwise returns default key name
     * @param key
     * @param equipmentConfigFields
     * @param internalConfig
     */
    const keyName = (
      key: string,
      equipmentConfigFields: any,
      internalConfig: boolean = false
    ): string => {
      let keyName = key
      for (const config of equipmentConfigFields) {
        if (key === config.key) {
          if (config.properties && config.properties.extraData) {
            if (internalConfig && config.properties.extraData.internalName) {
              keyName = config.properties.extraData.internalName
            } else if (
              !internalConfig &&
              config.properties.extraData.externalName
            ) {
              keyName = config.properties.extraData.externalName
            }
          }
        }
      }
      return keyName
    }

    const prettyKeys = {}
    for (const key of Object.keys(config)) {
      if (
        (hasDataId(key, equipmentConfigFields) &&
          key !== 'svgAttributes' &&
          key !== 'groups' &&
          key !== 'configuration',
        key !== BrelagConfigKey.SWW && key !== BrelagConfigKey.MAXFLEX)
      ) {
        parsedConfig[key] = config[key]
        if (defaultKeyName) {
          prettyKeys[key] = key
        } else {
          prettyKeys[key] = keyName(key, equipmentConfigFields, internalConfig)
        }
      }
    }

    // if displayOrder is defined, use it for sorting
    const fields =
      equipmentConfig.displayOrder ||
      equipmentConfigFields.map((config) => config.key)

    const sortedConfig = {}
    for (const key of fields) {
      if (parsedConfig.hasOwnProperty(key)) {
        // only show non default values, except for egate -> show all
        if (equipmentConfig.model[BrelagConfigKey.EGATE]) {
          // egate: show all
          sortedConfig[prettyKeys[key]] = parsedConfig[key]
        } else {
          // only show if not default
          if (
            showDefaultValues ||
            parsedConfig[key] !== equipmentConfig.model[key]
          ) {
            sortedConfig[prettyKeys[key]] = parsedConfig[key]
          }
        }
      }
    }
    return sortedConfig
  }

  static async batchCreate(
    apiClient: ApiClientV2,
    model: CreateDeviceSerializer
  ): Promise<DeviceAssignment[]> {
    const response = await apiClient.customPost(
      'brelag/device-assignment/batch-create',
      model
    )
    return response.devices
  }

  static async batchDelete(
    apiClient: ApiClientV2,
    model: BatchDeleteDevicesInputSerializer
  ): Promise<number> {
    const response = await apiClient.customPost(
      'brelag/device-assignment/batch-delete',
      model
    )
    return response.deleted
  }
}

export class EquipmentGroup extends TransientBaseObject {
  organisation: string
  name: string
  ordering: number
  hidden: boolean

  static apiUrl = 'brelag/equipment-group'
  static langPath = 'apps.brelag.models.equipmentgroup'
  static objectType = 'equipment_group'
  static useApiClientV2 = true
  static fields = [
    { key: 'name' },
    {
      key: 'hidden',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label:
          'Versteckt. Wenn true, wird Standardmässig nicht angezeigt, sondern muss pro Mandant aktiviert werden.',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'ordering',
      required: false,
      formFieldType: FormFieldType.ORDERING_FIELD,
    },
  ]
  static listFields = [
    { key: 'name' },
    { key: 'ordering' },
    {
      key: 'hidden',
      transform: (is_activated: boolean) => {
        if (is_activated) {
          return getTranslation('models.auth.yes')
        } else {
          return getTranslation('models.auth.no')
        }
      },
    },
  ]

  static formConfig() {
    return {
      fields: EquipmentGroup.formFields(),
      model: { ...EQUIPMENT_GROUP_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<EquipmentGroup, EquipmentGroup>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(EQUIPMENT_GROUP_DEFAULT))
  }

  static insertBefore(
    vm: Vue,
    insert: EquipmentGroup,
    before: EquipmentGroup
  ): Promise<void> {
    return vm.$apiv2.customPost('brelag/equipment-group/insert-before', {
      insert: insert.id,
      before: before && before.id,
    })
  }

  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static async beforeSaveHook(
    apiClient: ApiClientV2,
    group: EquipmentGroup
  ): Promise<any> {
    if (!group.ordering && group.ordering !== 0) {
      group.ordering = -1
    }
  }
}

export interface EquipmentDump extends Equipment {
  equipment_group: EquipmentGroup
  equipment_variants: EquipmentVariant[]
  instruction_steps: InstructionStep[]
}

export class Equipment extends TransientBaseObject {
  organisation?: string
  equipment_group: string | EquipmentGroup
  category: EquipmentCategory
  device_type_name: string
  ordering: number
  tx_capacity?: number
  channel_count?: number
  has_sensors: boolean
  is_virtual: boolean
  hidden: boolean
  config_editor_layout: {
    model: any
    tabs?: any[]
    fields?: any[]
    title?: string
    description?: {
      key: string
      value: string
    }[]
  }
  teaching_options: {
    model: any
    tabs?: any[]
    fields?: any[]
    title?: string
  }

  static apiUrl = 'brelag/equipment'
  static langPath: string = 'apps.brelag.models.equipment'
  static objectType: string = 'equipment'
  static dumpAllUrl = 'brelag/equipment/dump-all'
  static fields: ModelField[] = [
    {
      key: 'category',
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        editable: false,
        options: [
          { text: 'Sender', value: EquipmentCategory.SENDER },
          { text: 'Empfänger', value: EquipmentCategory.RECEIVER },
        ],
      },
    },
    {
      key: 'equipment_group',
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        editable: true,
        relatedObjectProperty: 'name',
        modelClass: EquipmentGroup.objectType,
      },
    },
    {
      key: 'ordering',
      required: false,
      formFieldType: FormFieldType.ORDERING_FIELD,
    },
    { key: 'device_type_name' },
    {
      key: 'tx_capacity',
      required: false,
      formProperties: {
        editable: false,
      },
      display: 'model.category === "receiver"',
    },
    {
      key: 'has_sensors',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label: 'Hat Sensorik',
        trueValue: true,
        falseValue: false,
      },
      display: 'model.category === "sender"',
    },
    {
      key: 'is_virtual',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label: 'Ist virtueller Sender',
        trueValue: true,
        falseValue: false,
      },
      display: 'model.category === "sender"',
    },
    {
      key: 'hidden',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label:
          'Versteckt. Wenn true, wird Standardmässig nicht angezeigt, sondern muss pro Mandant aktiviert werden.',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'channel_count',
      required: false,
      formProperties: {
        editable: false,
      },
      display: 'model.category === "sender"',
    },
    {
      key: 'config_editor_layout',
      required: true,
      undotized: true,
      formFieldType: FormFieldType.EQUIPMENT_CONFIG_EDITOR,
      formProperties: {
        showDescription: true,
      },
    },
    {
      key: 'teaching_options',
      undotized: true,
      formFieldType: FormFieldType.EQUIPMENT_CONFIG_EDITOR,
      formProperties: {
        title: 'Teaching Options',
      },
      display: 'model.category === "receiver"',
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'device_type_name' },
    {
      key: 'category',
    },
    { key: 'ordering' },
    {
      key: 'hidden',
      transform: (is_activated: boolean) => {
        if (is_activated) {
          return getTranslation('models.auth.yes')
        } else {
          return getTranslation('models.auth.no')
        }
      },
    },
  ]
  static listFilters: ListFilter[] = [
    {
      modelClass: EquipmentGroup,
      property: 'name',
      key: 'equipment_group',
    },
  ]

  public static ancestors: Ancestor[] = [
    {
      modelClass: EquipmentGroup,
      mainParent: true,
      requiredForFilter: true,
    },
  ]

  static formConfig() {
    return {
      fields: Equipment.formFields(),
      model: { ...EQUIPMENT_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Equipment, Equipment>(
      vm,
      this,
      filter,
      pagination
    )
  }

  /**
   * Returns the whole equipment dump
   */
  static async equipmentDump(apiClient: ApiClientV2): Promise<EquipmentDump[]> {
    const response = await apiClient.customGet(
      `${this.dumpAllUrl}?${Math.random()}`
    )
    return response.data
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(EQUIPMENT_DEFAULT))
  }

  static insertBefore(
    vm: Vue,
    insert: Equipment,
    before: Equipment
  ): Promise<void> {
    return vm.$apiv2.customPost('brelag/equipment/insert-before', {
      insert: insert.id,
      before: before && before.id,
    })
  }

  static async beforeSaveHook(
    apiClient: ApiClientV2,
    equipment: Equipment
  ): Promise<any> {
    if (equipment.category === EquipmentCategory.SENDER) {
      delete equipment.tx_capacity
      delete equipment.teaching_options
    } else if (equipment.category === EquipmentCategory.RECEIVER) {
      delete equipment.channel_count
    }
    if (!equipment.ordering && equipment.ordering !== 0) {
      equipment.ordering = -1
    }
  }

  /**
   * Returns all teaching options of an equipment that have a dataID property in extraData
   * @param equipment
   */
  static getTeachingOptionsWithDataID(equipment: Equipment): {
    [key: string]: any
  } {
    const config = FormBuilderHelper.getFieldDictFromConfig(
      equipment.teaching_options
    )
    const out = {}
    for (const key of Object.keys(config)) {
      if (
        config[key].properties &&
        config[key].properties.extraData &&
        config[key].properties.extraData.dataID
      ) {
        out[key] = config[key]
      }
    }
    return out
  }

  /**
   * Returns all fields of an equipment that have a dataID property in extraData (or the three special fields)
   * @param equipment
   */
  static getFieldsWithDataID(equipment: Equipment): { [key: string]: any } {
    const config = FormBuilderHelper.getFieldDictFromConfig(
      equipment.config_editor_layout
    )
    const out = {}
    for (const key of Object.keys(config)) {
      if (
        key === BrelagConfigKey.SWW ||
        key === BrelagConfigKey.MAXFLEX ||
        key === BrelagConfigKey.EGATE ||
        (config[key].properties &&
          config[key].properties.extraData &&
          config[key].properties.extraData.dataID)
      ) {
        out[key] = config[key]
      }
    }
    return out
  }
}

export class EquipmentVariant extends TransientBaseObject {
  equipment: string
  model: string
  ordering: number
  article_number: string
  device_variant_name: string // Long form
  short_form: string
  image: string
  image_url: string | null
  active?: boolean
  hidden: boolean

  static apiUrl = 'brelag/equipment-variant'
  static langPath = 'apps.brelag.models.equipmentvariant'
  static objectType = 'equipmentvariant'
  static fields: ModelField[] = [
    {
      key: 'device_variant_name',
    },
    {
      key: 'active',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label: 'Aktiv',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'hidden',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label:
          'Versteckt. Wenn true, wird Standardmässig nicht angezeigt, sondern muss pro Mandant aktiviert werden.',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'article_number',
    },
    {
      key: 'short_form',
    },
    {
      key: 'equipment',
      required: true,
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        relatedObjectProperty: 'device_type_name',
        modelClass: Equipment.objectType,
      },
    },
    {
      key: 'ordering',
      required: false,
      formFieldType: FormFieldType.ORDERING_FIELD,
    },
    {
      key: 'image',
      required: false,
      formFieldType: FormFieldType.IMAGE_UPLOAD,
      formProperties: {
        title: 'Bild',
        uploadUrl: '/api/v1/brelag/image/',
        name: 'file',
        modelClass: ImageObject.objectType,
      },
    },
  ]
  static listFields: ListModelField[] = [
    {
      key: 'equipment_device_type_name',
    },
    { key: 'device_variant_name' },
    { key: 'article_number' },
    { key: 'short_form' },
    {
      key: 'active',
      transform: (is_activated: boolean) => {
        if (is_activated) {
          return getTranslation('models.auth.yes')
        } else {
          return getTranslation('models.auth.no')
        }
      },
    },
    { key: 'image_img', cellType: CellType.IMAGE },
    {
      key: 'hidden',
      transform: (is_activated: boolean) => {
        if (is_activated) {
          return getTranslation('models.auth.yes')
        } else {
          return getTranslation('models.auth.no')
        }
      },
    },
  ]
  static joins: Collection.RelatedAnnotation<EquipmentVariant>[] = [
    {
      relatedModelClass: Equipment,
      relatedObjectProperty: 'device_type_name',
    },
  ]
  static annotations: Collection.Annotation<EquipmentVariant, string>[] = [
    {
      key: 'image_img',
      callback: async (obj, api: ApiClientV2) => {
        try {
          const image = await api.get<ImageObject>(ImageObject, obj.image)
          if (image.url) {
            return {
              id: obj.id,
              annotations: {
                image_img: image.url,
              },
            }
          } else {
            return {
              id: obj.id,
              annotations: {
                image_img: '/img/empty_avatar.png',
              },
            }
          }
        } catch (err) {
          return {
            id: obj.id,
            annotations: {
              image_img: '/img/empty_avatar.png',
            },
          }
        }
      },
    },
  ]

  static formConfig() {
    return {
      fields: EquipmentVariant.formFields(),
      model: { ...EQUIPMENT_VARIANT_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<EquipmentVariant, EquipmentVariant>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(EQUIPMENT_VARIANT_DEFAULT))
  }

  static insertBefore(
    vm: Vue,
    insert: EquipmentVariant,
    before: EquipmentVariant
  ): Promise<void> {
    return vm.$apiv2.customPost('brelag/equipment-variant/insert-before', {
      insert: insert.id,
      before: before && before.id,
    })
  }

  static async beforeSaveHook(
    apiClient: ApiClientV2,
    variant: EquipmentVariant
  ): Promise<any> {
    if (!variant.ordering && variant.ordering !== 0) {
      variant.ordering = -1
    }
  }
}

export enum InstructionCategory {
  configuration = 'configuration',
  recognition = 'recognition',
}

export class InstructionStep extends TransientBaseObject {
  equipment: string
  category: InstructionCategory
  image: string
  title: string
  description: string
  ordering: number

  static apiUrl = 'brelag/instruction-step'
  static langPath = 'apps.brelag.models.instructionstep'
  static objectType = 'instructionstep'
  static fields: ModelField[] = [
    {
      key: 'equipment',
      required: true,
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        editable: false,
        relatedObjectProperty: 'device_type_name',
        modelClass: Equipment.objectType,
      },
    },
    {
      key: 'category',
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        options: [
          {
            text: 'Configuration',
            value: InstructionCategory.configuration.toString(),
          },
          {
            text: 'Detection',
            value: InstructionCategory.recognition.toString(),
          },
        ],
      },
    },
    {
      key: 'ordering',
      required: false,
      formFieldType: FormFieldType.ORDERING_FIELD,
    },
    {
      key: 'title',
    },
    {
      key: 'description',
      required: false,
      formFieldType: FormFieldType.MARKDOWN_FIELD,
    },
    {
      key: 'image',
      required: false,
      formFieldType: FormFieldType.IMAGE_UPLOAD,
      formProperties: {
        title: 'Anweisungsbild',
        imageUrl: `/api/v1/${InstructionStep.apiUrl}/%/image`,
        uploadUrl: '/api/v1/brelag/instruction-step/upload-image',
        name: 'file',
      },
    },
  ]
  static listFields: ListModelField[] = [
    {
      key: 'category',
      transform(category: string) {
        if (category === InstructionCategory.recognition) {
          return 'Detection'
        } else {
          return 'Configuration'
        }
      },
    },
    { key: 'title' },
    { key: 'ordering' },
    {
      key: 'image_img',
      cellType: CellType.IMAGE,
      transform(image: string) {
        return `${image}?${Math.random()}`
      },
    },
  ]
  static annotations: Collection.Annotation<InstructionStep, string>[] = [
    {
      key: 'image_img',
      callback: (obj, api) => {
        const response = {
          id: obj.id,
          annotations: {
            image_img: '/img/empty_avatar.png',
          },
        }
        if (obj.image !== '' && obj.image !== null) {
          response.annotations.image_img = `/api/v1/${InstructionStep.apiUrl}/${obj.id}/image`
        }
        return Promise.resolve(response)
      },
    },
  ]

  static formConfig() {
    return {
      fields: InstructionStep.formFields(),
      model: { ...INSTRUCTION_STEP_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<InstructionStep, InstructionStep>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(INSTRUCTION_STEP_DEFAULT))
  }

  static insertBefore(
    vm: Vue,
    insert: InstructionStep,
    before: InstructionStep
  ): Promise<void> {
    return vm.$apiv2.customPost('brelag/instruction-step/insert-before', {
      insert: insert.id,
      before: before && before.id,
    })
  }

  static async beforeSaveHook(
    apiClient: ApiClientV2,
    instruction: InstructionStep
  ): Promise<any> {
    if (!instruction.ordering && instruction.ordering !== 0) {
      instruction.ordering = -1
    }
  }
}

export const EQUIPMENT_GROUP_DEFAULT: EquipmentGroup = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  name: '',
  ordering: null,
  hidden: false,
}

export const EQUIPMENT_DEFAULT: Equipment = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  category: EquipmentCategory.RECEIVER,
  equipment_group: '',
  device_type_name: '',
  has_sensors: false,
  is_virtual: false,
  config_editor_layout: {
    model: {},
    tabs: [],
    title: '',
    description: [],
  },
  teaching_options: {
    model: {},
    tabs: [],
    title: '',
  },
  ordering: null,
  hidden: false,
}

export const INSTRUCTION_STEP_DEFAULT: InstructionStep = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  category: InstructionCategory.configuration,
  image: '',
  title: '',
  description: '',
  ordering: null,
  equipment: '',
}

export const EQUIPMENT_VARIANT_DEFAULT: EquipmentVariant = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  equipment: '',
  model: '',
  device_variant_name: '',
  article_number: '',
  short_form: '',
  ordering: null,
  image: null,
  image_url: null,
  hidden: false,
}

export const DEVICE_ASSIGNMENT_DEFAULT: DeviceAssignment = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  floor: '',
  number: 0,
  dirty: true,
  dirty_optional: true,
  dirty_teaching: true,
  device_number: '',
  category: EquipmentCategory.RECEIVER,
  meta: {
    svgAttributes: {
      color: '#CE1616',
      bgColor: '#CE1E1E00',
      textColor: '#000000',
    },
  },
  config: null,
  programmer_connection: null,
  programmer_device_info: null,
  programmer_device_configuration: null,
  programmer_device_configuration_optional: null,
  recognized_at: null,
  recognized_by: null,
  last_configured_at: null,
  last_configured_by: null,
}

export async function createEGate(
  brelagApp: ClientApp,
  apiClient: ApiClientV2
) {
  const group = await apiClient.create<EquipmentGroup, EquipmentGroup>(
    EquipmentGroup,
    {
      ...EQUIPMENT_GROUP_DEFAULT,
      organisation: brelagApp.organisation,
      name: 'eGate',
      ordering: -1,
    },
    'create-egate-equipment-group'
  )

  const egateEquipment = await apiClient.create<Equipment, Equipment>(
    Equipment,
    {
      ...EQUIPMENT_DEFAULT,
      organisation: brelagApp.organisation,
      category: EquipmentCategory.RECEIVER,
      equipment_group: group.id,
      device_type_name: 'eGate',
      config_editor_layout: eGateConfig,
      tx_capacity: 0,
      channel_count: 0,
      ordering: -1,
    },
    'create-egate-equipment'
  )

  await apiClient.create(
    EquipmentVariant,
    {
      ...EQUIPMENT_VARIANT_DEFAULT,
      equipment: egateEquipment.id,
      device_variant_name: 'eGate',
      short_form: 'eG',
      article_number: 'eGate',
      ordering: -1,
    },
    'create-egate-equipment-variant'
  )

  const setting: ClientAppSetting = await apiClient.customGet(
    `client/app/${brelagApp.id}/setting/config/`
  )
  setting.value.eGateEquipmentId = egateEquipment.id
  await apiClient.customPut(
    `client/app/${brelagApp.id}/setting/config/`,
    setting,
    'add-egate-equipment-id-to-setting'
  )
}

export default {
  fields: {
    description: {
      label: 'Brief description',
      placeholder: 'Description',
    },
  },
  save: 'Save',
  saveSuccess: 'Successfully saved',
  cancel: 'Cancel',
  delete: 'Delete',
  remove: 'Remove',
  deleteSuccess: 'Successfully deleted',
  detail: 'Detail',
  actions: 'Actions',
  copy: 'Copy',
  select: 'Select',
  selection: 'Selection',
  apply: 'Apply',
  empty: 'Nothing here.',
  required: 'Required',
  loading: 'Loading',
  searchBy: 'Search by',
  search: 'Search',
  filter: 'Filter',
  filterBy: 'Filter by',
  noFilter: 'No filter',
  noSelection: 'No selection',
  no: 'No',
  found: 'found',
  moveUp: 'Move up',
  moveDown: 'Move down',
  createdSuccessfully: 'created successfully',
  leaveWithoutSaveQuestion:
    'Are you sure you want to leave without saving changes?',
  confirmDelete: 'Are you sure that you want to delete this object?',
  uploadForm: {
    dropToUpload: 'Drop files to upload',
    preview: 'Preview',
    name: 'Name',
    size: 'Size',
    status: 'Status',
    chooseFile: 'Choose file',
    noImage: 'No image',
    startUpload: 'Start Upload',
    stopUpload: 'Stop Upload',
    invalidExtension: 'Invalid extension',
  },
  transaction: {
    operationsListed: 'All unfinished operations are listed here.',
    applySuccess: 'Successfully applied!',
    resetSuccess: 'Successfully reset!',
  },
}

import { BehaviorSubject, Subject } from 'rxjs'
import { Store } from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import isEqual from 'lodash.isequal'

import {
  CreateDeviceSerializer,
  AnnotationElement,
  getAnnotationFields,
  EditorConfig,
  AnnotationCategory,
} from '@/apps/brelag/mandator-user/models/editor'
import { DeviceHandler } from '@/apps/brelag/mandator-user/project-editor/deviceHandler'
import { Building } from '@/apps/brelag/common/models/building'
import {
  ProjectDump,
  Project,
  ProjectExport,
  DeviceGroup,
} from '@/apps/brelag/common/models/project'
import {
  DeviceAssignment,
  EquipmentDump,
  EquipmentVariant,
  EquipmentCategory,
  Equipment,
  BrelagConfigKey,
} from '@/apps/brelag/common/models/equipment'
import { EquipmentHandler } from '@/apps/brelag/mandator-user/project-editor/equipmentHandler'
import { ApiClientV2, apiClientV2 } from '@/api/ApiClientV2'
import { LINK_DEFAULT, Link } from '@/apps/brelag/common/models/link'
import { exportPDF } from '@/apps/brelag/mandator-user/project-editor/exportHelper'
import {
  DeviceGroupHandler,
  DeviceGroupNode,
} from '@/apps/brelag/mandator-user/project-editor/deviceGroupHandler'
import { errorToString, errorToStrings } from '@/api/ErrorHandler'
import {
  commonDifferentProperties,
  getMetaSvgAttribute,
} from '@/apps/brelag/mandator-user/project-editor/floorPlanEditorUtil'
import { simpleCopy } from '@/util/util'
import { Customer } from '@/apps/brelag/common/models/customer'
import {
  Floor,
  FloorPatchSerializer,
  FloorPlanSettings,
} from '@/apps/brelag/common/models/floor'
import { FormBuilderConfig } from '@/components/common/forms/formBuilderHelper'
import {
  getMaxFlexLayoutByValue,
  transformLegacyImportMaxFlexConfig,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import { ToastProgrammatic as Toast } from 'buefy'
import { Profile } from '@/models/core/profile'
import {
  SwwChannelText,
  transformLegacyImportSwwConfig,
} from '@/apps/brelag/common/models/swwParams'
import {
  checkProjectHealth,
  ProjectHealthError,
  tryFixError,
} from '@/apps/brelag/mandator-user/project-editor/projectHealthUtil'
import { Organisation } from '@/models/core/organisation'
import { CustomMandatorLayout } from '@/store/SettingsStore'
import {
  WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS,
  WESCO_MAXFLEX_LICHTTASTER_EFFEKTLICHT_DEFAULT_CONFIG,
  WESCO_MAXFLEX_LICHTTASTER_KOCHFELD_DEFAULT_CONFIG,
  WESCO_MAXFLEX_STEUERTASTATUR_DEFAULT_CONFIG,
  WESCO_MX_FE_ULTRA_VENTILATION_DEFAULT_CONFIG,
} from '../custom-mandators/wesco/mandator'

export interface FloorDump extends Floor {
  building: string
  id: string
  ordering: number
  original_file: string
  processed_file: string
  title: string
  prefix: string

  senders?: DeviceAssignment[]
  receivers?: DeviceAssignment[]
}

export interface BuildingState {
  id: string
  title: string
  ordering: number
  floors: Map<string, FloorDump>
}

export interface ProjectState extends ProjectDump {
  buildingsMap: Map<string, BuildingState>
}

export interface VariantPartsCount {
  amount: number
  label: string
  artNr: string
  floor?: string
  category?: string
  variant: EquipmentVariant
}

export interface PartsList {
  floors: Record<
    string,
    {
      floor: Floor
      parts: Record<string, VariantPartsCount>
    }
  >
  total: Record<string, VariantPartsCount>
  // <equipmentVariant: <maxFlexType: number>>
  maxFlexTypes: Record<string, Record<string, number>>
}

export enum TreeNodeType {
  BUILDING = 'building',
  FLOOR = 'floor',
  DEVICE = 'device',
  CHANNEL = 'channel',
  GROUP = 'group',
  LEVEL = 'level',
}

export interface LinkNode {
  text: string
  data: {
    id: string
    type: TreeNodeType
    sender?: string
    floor?: string
    egates?: string[]
    level?: any
  }
  state: {
    selectable: boolean
    selected: boolean
  }
}

export interface DeviceNodeData {
  id: string
  type: TreeNodeType.DEVICE
  deviceIcon?: string
  remark?: string
  dirty?: boolean
  dirty_optional?: boolean
  dirty_teaching?: boolean
  radio_address?: string
  isRepeatingOnly?: boolean
  floor?: string
}

export interface DeviceNodeDataProxy extends DeviceNodeData {
  _parameters?: {
    senderId?: string
    receiverId?: string
    channel?: number
    hasLinkedReceiverId?: boolean
    [key: string]: any
  }
}

export interface DeviceNode {
  text: string
  data: DeviceNodeData
  state: {
    expanded: boolean
    selectable: boolean
    selected: boolean
  }
  children?: LinkNode[]
}

export interface DeviceTree {
  text: string
  data: {
    id: string
    type: TreeNodeType
    building: Building
  }
  state: {
    expanded: boolean
    selectable: boolean
  }
  // Floor
  children: {
    text: string
    data: {
      id: string
      type: TreeNodeType
      floor: FloorDump
    }
    state: {
      selected: boolean
      selectable: boolean
      expanded: boolean
    }
    // Devices
    children: DeviceNode[]
  }[]
}

export class ProjectEditor {
  public isInitialized: boolean = false
  public readySubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public loadingStream: Subject<boolean> = new BehaviorSubject(false)
  public errorStream: Subject<string> = new Subject()

  static instance: ProjectEditor
  private _apiClient: ApiClientV2
  private _mandator: Organisation
  private _project: Project
  private _projectDump: ProjectState = null
  private _projectErrors: ProjectHealthError[] = []
  private _canLock: boolean = true
  private _deviceHandler: DeviceHandler
  private _equipmentHandler: EquipmentHandler
  private _groupHandler: DeviceGroupHandler
  private _store: Store<any> = null

  private _floors: FloorDump[] = []
  private _floorsMap: Map<string, FloorDump> = new Map()
  private _buildingsMap: Map<string, Building> = new Map()

  private _selectedFloor: FloorDump = null
  private _selectedBuilding: string = null

  private _linkedSenders: DeviceAssignment[] = []
  private _unlinkedSenders: DeviceAssignment[] = []

  private _receiverNodes: DeviceNode[] = null

  public reqBatchSize = 20

  // Selection helpers
  private _lastSelectedSenderId: string = null
  private _lastSelectedChannel: number = null

  constructor(store: Store<any>) {
    this._equipmentHandler = new EquipmentHandler()
    this._deviceHandler = new DeviceHandler(this._equipmentHandler)
    this._groupHandler = new DeviceGroupHandler(
      this._deviceHandler,
      this._equipmentHandler,
      store
    )
    this._store = store
  }

  /**
   * Initial loading of a project
   * @param id id of project
   * @param apiClient ApiClient
   * @param refreshEquipmentDump if true, will fetch equipment dump
   */
  public async loadProject(
    id: string,
    apiClient: ApiClientV2,
    refreshEquipmentDump: boolean = true
  ) {
    this.isInitialized = false
    this.readySubject.next(false)

    this.readOnly = true
    this.isLockedByWinApp = false
    this.lockedBy = null

    // Store ApiClient
    this._apiClient = apiClient

    // Load project dump and project
    this._project = await this._apiClient.get<Project>(Project, id)
    const projectResult = Project.getDump(apiClient, id)

    // Load mandator
    this._mandator = await this._apiClient.get<Organisation>(
      Organisation,
      this._project.organisation
    )

    // Load equipment dump
    let equipmentResult = null
    if (refreshEquipmentDump) {
      equipmentResult = this._equipmentHandler.loadEquipment(apiClient)
    }

    const [projectDump, _] = [await projectResult, await equipmentResult]

    // Do a checkup of the project dump to find potential errors
    this._projectErrors = await checkProjectHealth(this, projectDump)

    // Init link handler
    this._projectDump = projectDump
    await this._deviceHandler.loadDeviceHandlerProject(projectDump)
    // Load groups
    const receiverGroups = []
    if (this._project.meta && this._project.meta.receiver_groups) {
      Object.assign(receiverGroups, this._project.meta.receiver_groups)
    }
    await this._groupHandler.loadDeviceGroupHandlerProject(receiverGroups, this)

    // Transform nested objects to Map
    this._projectDump.buildingsMap = new Map()
    this._floors = []
    projectDump.buildings.forEach((building) => {
      this._projectDump.buildingsMap.set(
        building.id,
        JSON.parse(JSON.stringify(building))
      )
      this._buildingsMap.set(building.id, JSON.parse(JSON.stringify(building)))
      this._projectDump.buildingsMap.get(building.id).floors = new Map()
      building.floors.forEach((floor) => {
        this._projectDump.buildingsMap
          .get(building.id)
          .floors.set(floor.id, floor)
        this._floorsMap.set(floor.id, floor)
        this._floors.push(floor)
      })
    })
    if (this._floorsMap.size > 0) {
      // Check if there is a selected floor already and if it belongs to this project
      if (this._selectedFloor && this._floorsMap.has(this._selectedFloor.id)) {
        this.selectFloor(this._selectedFloor.id)
      } else {
        // Select first floor
        this.selectFloor(this._floorsMap.values().next().value.id)
      }
    } else {
      this.selectFloor(null)
    }
    // Parse links
    await this.updateLinkedState()

    // Check if we already have the lock on project
    if (this._projectDump.editing_locked_by !== null) {
      this.lockedBy = await Profile.getFullName(
        this._apiClient,
        this._projectDump.editing_locked_by
      )
      if (
        Project.isProjectLockedByStylerUser(
          this._project,
          this._projectDump.editing_locked_by
        )
      ) {
        this.readOnly = false
        this._canLock = true
      } else {
        this._canLock = false
      }
      this.isLockedByWinApp = Project.lockedFromWindowsApp(this._project)
    }
    if (this._projectDump.editing_unlocked_by !== null) {
      this.unlockedBy = await Profile.getFullName(
        this._apiClient,
        this._projectDump.editing_unlocked_by
      )
    }

    this._deviceHandler.sortLists()

    this.isInitialized = true
    this.readySubject.next(true)
  }

  deInitialize() {
    ProjectEditor.instance = new ProjectEditor(ProjectEditor.instance._store)
  }

  private handleError(error, doThrow?: boolean) {
    this.errorStream.next(errorToString(error))
    if (doThrow !== undefined) {
      this.loadingStream.next(false)
      throw error
    }
  }

  /**
   * Selects first floor if available
   */
  public selectFirstFloor() {
    if (this.floors.length > 0) {
      this._selectedBuilding = this.floors[0].building
      this._selectedFloor = this.floors[0]
    }
  }

  public selectFloor(id: string) {
    if (!this._projectDump) {
      throw new Error('No project loaded yet.')
    }
    this._selectedFloor = null
    this._floors.forEach((floor) => {
      if (floor.id === id) {
        this._selectedBuilding = floor.building
        this._selectedFloor = floor
      }
    })
  }

  async _createWescoDevices(
    model: CreateDeviceSerializer,
    defaultConfig?: {
      [key: string]: any
    }
  ): Promise<DeviceAssignment[]> {
    /**
     * For now we only have one exception. If there are more special cases in the future, this should be expanded. Some ideas:
     * - A CreateDevicesMixin which can be extended by custom implementations
     * - Extend ProjectEditor with custom implementations
     */
    // Get the variant ID's from the settings
    const gatewayReceiverVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoGatewayReceiverArticleNr
      )
    const gatewaySenderVariant = this.equipmentHandler.getVariantFromArticleNr(
      WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoGatewaySenderArticleNr
    )
    const maxflexSTFWhiteVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoSTFWhiteArticleNr
      )
    const maxflexSTFBlackVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoSTFBlackArticleNr
      )
    const maxflexCooktopLTFWhiteVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoCooktopLTFWhiteArticleNr
      )
    const maxflexCooktopLTFBlackVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoCooktopLTFBlackArticleNr
      )
    const maxflexEffectLTFWhiteVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoEffectLTFWhiteArticleNr
      )
    const maxflexEffectLTFBlackVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoEffectLTFBlackArticleNr
      )
    const lxDimmNoLimitCooktopLEFVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoCooktopLEFArticleNr
      )
    const lxDimmNoLimitEffectLEFVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoEffectLEFArticleNr
      )
    const mxFeUltraVentilationVariant =
      this.equipmentHandler.getVariantFromArticleNr(
        WESCO_CUSTOM_EQUIPMENT_ARTICLE_NUMBERS.wescoVentilationMxFeUltraArticleNr
      )
    let newDevices: DeviceAssignment[] = []
    if (
      [
        gatewayReceiverVariant,
        gatewaySenderVariant,
        maxflexSTFWhiteVariant,
        maxflexSTFBlackVariant,
        maxflexCooktopLTFWhiteVariant,
        maxflexCooktopLTFBlackVariant,
        maxflexEffectLTFWhiteVariant,
        maxflexEffectLTFBlackVariant,
        lxDimmNoLimitCooktopLEFVariant,
        lxDimmNoLimitEffectLEFVariant,
        mxFeUltraVentilationVariant,
      ].some((el) => !el)
    ) {
      // if these are not defined, we can't automate anything
      throw new Error(
        'Es konnten nicht alle benötigten Artikel gefunden werden. Bitte kontaktieren Sie den Administrator.'
      )
    }
    // Check if device that we're adding right now is a WESCO gateway
    let isWescoGateway = false
    let wescoGatewayReceiver = null
    let wescoGatewaySender = null
    if (
      model.equipment_variant === gatewaySenderVariant.id ||
      model.equipment_variant === gatewayReceiverVariant.id
    ) {
      // If more than one gateway -> Return error
      if (model.number > 1) {
        throw new Error(
          'Es kann nur ein WESCO Gateway auf einmal erstellt werden.'
        )
      }
      isWescoGateway = true
    }

    // If sender was of wesco-specific MaxFlex type, pre-configure maxflex (e.g. type 46)
    if (
      model.equipment_variant === maxflexSTFBlackVariant.id ||
      model.equipment_variant === maxflexSTFWhiteVariant.id
    ) {
      defaultConfig.maxflex = simpleCopy(
        WESCO_MAXFLEX_STEUERTASTATUR_DEFAULT_CONFIG
      )
      model.config = {
        maxflex: simpleCopy(WESCO_MAXFLEX_STEUERTASTATUR_DEFAULT_CONFIG),
      }
    } else if (
      model.equipment_variant === maxflexCooktopLTFWhiteVariant.id ||
      model.equipment_variant === maxflexCooktopLTFBlackVariant.id
    ) {
      defaultConfig.maxflex = simpleCopy(
        WESCO_MAXFLEX_LICHTTASTER_KOCHFELD_DEFAULT_CONFIG
      )
      model.config = {
        maxflex: simpleCopy(WESCO_MAXFLEX_LICHTTASTER_KOCHFELD_DEFAULT_CONFIG),
      }
    } else if (
      model.equipment_variant === maxflexEffectLTFWhiteVariant.id ||
      model.equipment_variant === maxflexEffectLTFBlackVariant.id
    ) {
      defaultConfig.maxflex = simpleCopy(
        WESCO_MAXFLEX_LICHTTASTER_EFFEKTLICHT_DEFAULT_CONFIG
      )
      model.config = {
        maxflex: simpleCopy(
          WESCO_MAXFLEX_LICHTTASTER_EFFEKTLICHT_DEFAULT_CONFIG
        ),
      }
    } else if (model.equipment_variant === mxFeUltraVentilationVariant.id) {
      defaultConfig = simpleCopy(WESCO_MX_FE_ULTRA_VENTILATION_DEFAULT_CONFIG)
    }
    /* CREATE NEW DEVICES */
    const devices = await this._deviceHandler.createDevices(
      model,
      defaultConfig,
      this.selectedSenderId
    )
    if (isWescoGateway) {
      // Create other version of the gateway
      if (devices.length !== 1) {
        throw new Error(
          `Erstellung des WESCO Gateway hat ${devices.length} Geräte erstellt anstatt nur eines. Aktion wird abgebrochen.`
        )
      }
      const otherGatewayModel: CreateDeviceSerializer = simpleCopy(model)
      otherGatewayModel.number = null
      if (model.equipment_variant === gatewaySenderVariant.id) {
        wescoGatewaySender = devices[0]
        otherGatewayModel.equipment_variant = gatewayReceiverVariant.id
      } else {
        wescoGatewayReceiver = devices[0]
        otherGatewayModel.equipment_variant = gatewaySenderVariant.id
      }
      const otherDevices = await this._deviceHandler.createDevices(
        otherGatewayModel,
        defaultConfig,
        this.selectedSenderId
      )
      if (otherDevices.length !== 1) {
        throw new Error(
          `Erstellung des (zweiten) WESCO Gateway hat ${devices.length} Geräte erstellt anstatt nur eines. Aktion wird abgebrochen.`
        )
      }
      if (otherGatewayModel.equipment_variant === gatewaySenderVariant.id) {
        wescoGatewaySender = otherDevices[0]
      } else {
        wescoGatewayReceiver = otherDevices[0]
      }
      newDevices.push(wescoGatewayReceiver)
      newDevices.push(wescoGatewaySender)
    } else {
      newDevices = devices
    }

    for (const newDevice of newDevices) {
      this._projectDump.device_assignments.push(newDevice)
    }

    const newLinks: Link[] = []
    if (isWescoGateway) {
      // Iterate through all senders of project (that are not of type gatewaySenderVariantId) and
      // teach the newly created gateway receiver onto channel 1 of each sender
      // (option "repeatingOnly" is NOT active)
      const senders = this.deviceHandler.senderList.filter(
        (sender) => sender.equipment_variant !== gatewaySenderVariant.id
      )
      for (const sender of senders) {
        let senderChannel = 0
        if (
          sender.equipment_variant === maxflexCooktopLTFWhiteVariant.id ||
          sender.equipment_variant === maxflexCooktopLTFBlackVariant.id
        ) {
          senderChannel = 1
        } else if (
          sender.equipment_variant === maxflexEffectLTFWhiteVariant.id ||
          sender.equipment_variant === maxflexEffectLTFBlackVariant.id
        ) {
          senderChannel = 2
        }
        // Create link
        const newLink: Link = {
          ...LINK_DEFAULT,
          project: this._projectDump.id,
          sender: sender.id,
          receiver: wescoGatewayReceiver.id,
          sender_channel: senderChannel,
          options: {
            keyRepeaterOnly: 0,
          },
        }
        newLinks.push(newLink)
      }
      // Iterate through all receivers of project (that are not of type gatewayReceiverVariantId) and
      // teach them onto the newly created gateway sender onto channel 1 (option "repeatingOnly" is NOT active)
      const receivers = this.deviceHandler.receiverList.filter(
        (receiver) => receiver.equipment_variant !== gatewayReceiverVariant.id
      )
      for (const receiver of receivers) {
        let senderChannel = 0
        if (receiver.equipment_variant === lxDimmNoLimitCooktopLEFVariant.id) {
          senderChannel = 1
        } else if (
          receiver.equipment_variant === lxDimmNoLimitEffectLEFVariant.id
        ) {
          senderChannel = 2
        }
        // Create link
        const newLink: Link = {
          ...LINK_DEFAULT,
          project: this._projectDump.id,
          sender: wescoGatewaySender.id,
          receiver: receiver.id,
          sender_channel: senderChannel,
          options: {
            keyRepeaterOnly: 0,
          },
        }
        newLinks.push(newLink)
      }
    } else {
      const equipment = this.equipmentHandler.getEquipmentFromVariantId(
        model.equipment_variant
      )
      if (equipment.category === EquipmentCategory.SENDER) {
        /**
         * For each newly created sender:
         * - Iterate through all existing receivers:
         *   - If receiver is WESCO gateway receiver: teach WESCO gateway receiver onto
         *     channel 1 of the newly created sender (option "repeatingOnly" is NOT active)
         *   - Otherwise: teach receiver onto channel 1 of the newly created sender (option "repeatingOnly" is active)
         */
        for (const sender of devices) {
          let senderChannel = 0
          if (
            sender.equipment_variant === maxflexCooktopLTFWhiteVariant.id ||
            sender.equipment_variant === maxflexCooktopLTFBlackVariant.id
          ) {
            senderChannel = 1
          } else if (
            sender.equipment_variant === maxflexEffectLTFWhiteVariant.id ||
            sender.equipment_variant === maxflexEffectLTFBlackVariant.id
          ) {
            senderChannel = 2
          }
          const receivers = this.deviceHandler.receiverList
          for (const receiver of receivers) {
            const newLink: Link = {
              ...LINK_DEFAULT,
              project: this._projectDump.id,
              sender: sender.id,
              receiver: receiver.id,
              sender_channel: senderChannel,
              options: {},
            }
            if (receiver.equipment_variant === gatewayReceiverVariant.id) {
              newLink.options = {
                keyRepeaterOnly: 0,
              }
            } else {
              newLink.options = {
                keyRepeaterOnly: 1,
              }
            }
            newLinks.push(newLink)
          }
        }
      } else if (equipment.category === EquipmentCategory.RECEIVER) {
        /**
         * For each newly created receiver:
         * - Iterate through all existing senders:
         *   - If sender is WESCO gateway senders: teach new receiver onto channel 1 of the gateway sender (option "repeatingOnly" NOT active)
         *   - otherwise: teach new receiver onto channel 1 of the sender (option "repeatingOnly" active)
         */
        for (const receiver of devices) {
          const senders = this.deviceHandler.senderList
          for (const sender of senders) {
            let senderChannel = 0
            if (
              sender.equipment_variant === maxflexCooktopLTFWhiteVariant.id ||
              sender.equipment_variant === maxflexCooktopLTFBlackVariant.id
            ) {
              senderChannel = 1
            } else if (
              sender.equipment_variant === maxflexEffectLTFWhiteVariant.id ||
              sender.equipment_variant === maxflexEffectLTFBlackVariant.id
            ) {
              senderChannel = 2
            } else if (sender.equipment_variant === gatewaySenderVariant.id) {
              if (
                receiver.equipment_variant === lxDimmNoLimitCooktopLEFVariant.id
              ) {
                senderChannel = 1
              } else if (
                receiver.equipment_variant === lxDimmNoLimitEffectLEFVariant.id
              ) {
                senderChannel = 2
              }
            }
            const newLink: Link = {
              ...LINK_DEFAULT,
              project: this._projectDump.id,
              sender: sender.id,
              receiver: receiver.id,
              sender_channel: senderChannel,
              options: {},
            }
            if (sender.equipment_variant === gatewaySenderVariant.id) {
              newLink.options = {
                keyRepeaterOnly: 0,
              }
            } else {
              newLink.options = {
                keyRepeaterOnly: 1,
              }
            }
            newLinks.push(newLink)
          }
        }
      }
    }
    await this.deviceHandler.createLinks(newLinks)
    await this.updateLinkedState()
    if (model.insert_number_if_occupied) {
      // reload project to update other devices
      await this.loadProject(this.project.id, this._apiClient, false)
    }
    return newDevices
  }

  _checkCanCreateDevices(model: CreateDeviceSerializer) {
    if (this.readOnly) {
      throw new Error(
        'Projekt muss gesperrt sein, um neue Geräte hinzuzufügen.'
      )
    }
    if (!model.equipment_variant) {
      throw new Error('Kein Typ ausgewählt.')
    }
    if (!model.floor) {
      throw new Error('Kein Stockwerk ausgewählt.')
    }
  }

  /**
   * Creates new devices
   * @param model
   */
  async createDevices(
    model: CreateDeviceSerializer,
    defaultConfig?: {
      [key: string]: any
    }
  ): Promise<DeviceAssignment[]> {
    // Prepare model
    model.projectId = this.project.id
    if (model.copySelectedDevice) {
      model.equipment_variant = this.selectedSender.equipment_variant
    }

    this._checkCanCreateDevices(model)

    if (
      this.mandator.settings.layout &&
      this.mandator.settings.layout === CustomMandatorLayout.WESCO
    ) {
      return this._createWescoDevices(model, defaultConfig)
    }
    return this._doCreateDevices(model, defaultConfig)
  }

  async _doCreateDevices(
    model: CreateDeviceSerializer,
    defaultConfig?: {
      [key: string]: any
    }
  ): Promise<DeviceAssignment[]> {
    const newDevices: DeviceAssignment[] =
      await this._deviceHandler.createDevices(
        model,
        defaultConfig,
        this.selectedSenderId
      )
    for (const newDevice of newDevices) {
      this._projectDump.device_assignments.push(newDevice)
    }
    await this.updateLinkedState()
    if (model.insert_number_if_occupied) {
      // reload project to update other devices
      await this.loadProject(this.project.id, this._apiClient, false)
    }
    return newDevices
  }

  /**
   * Set editor config to edit this annotation
   * @param annotationId
   */
  public async setEditAnnotationsConfig() {
    const annotationIds = this.selectedAnnotationIds
    if (annotationIds.length === 0) {
      return
    }
    if (annotationIds.length > 1) {
      this.handleError(
        new Error('Annotationen können nur einzeln editiert werden.'),
        true
      )
      return
    }
    const annotationId = annotationIds[0]
    const annotations = []
    const annotation = this.getAnnotationById(annotationId)
    if (!annotation) {
      this.handleError(
        new Error(`SetEditAnnotation: Invalid annotation id: ${annotationId}`)
      )
    }
    annotations.push(annotation)
    const fields = getAnnotationFields(annotation.category)
    const editorConfig: EditorConfig = {
      fields,
      model: annotation.meta.svgAttributes,
      annotations,
      devices: [],
      commonModel: simpleCopy(annotation.meta.svgAttributes),
      commonSvgModel: simpleCopy(annotation.meta.svgAttributes),
      originalModel: simpleCopy(annotation.meta.svgAttributes),
      tabLabel: 'Annotation',
    }
    await this.setEditorConfig([editorConfig])
  }

  public getDeviceEditorConfig(variantId: string): FormBuilderConfig {
    const equipment = this.equipmentHandler.getEquipmentFromVariantId(variantId)
    if (equipment) {
      const config = {
        ...simpleCopy(equipment.config_editor_layout),
        device_type_name: equipment.device_type_name,
      }
      return config
    } else {
      return null
    }
  }

  public async setEditDevicesConfig(
    category?: EquipmentCategory
  ): Promise<void> {
    const commonEditorConfigs: EditorConfig[] = []
    let deviceIds = this.selectedDeviceIds

    if (category) {
      if (category === EquipmentCategory.SENDER) {
        deviceIds = this.selectedSenderIds
      } else {
        deviceIds = this.selectedReceiverIds
      }
    }

    // Gather all configs and models
    const equipments: Map<string, EditorConfig> = new Map()
    const deviceConfigs: Map<string, any[]> = new Map()
    const svgAttributes: Map<string, any[]> = new Map()
    for (const deviceId of deviceIds) {
      const device = this.deviceHandler.getDeviceAssignmentById(deviceId)
      if (!device) {
        continue
      }
      if (!equipments.has(device.equipment)) {
        const equipment = this._equipmentHandler.getEquipmentFromDevice(device)
        equipments.set(device.equipment, {
          model: {},
          ...simpleCopy(equipment.config_editor_layout),
          annotations: [],
          devices: [],
          commonModel: {},
          commonSvgModel: {},
          device_type_name: equipment.device_type_name,
        })
        deviceConfigs.set(device.equipment, [])
        svgAttributes.set(device.equipment, [])
      }
      equipments.get(device.equipment).devices.push(device)
      if (device.config) {
        deviceConfigs.get(device.equipment).push(device.config)
      }
      if (device.meta && device.meta.svgAttributes) {
        svgAttributes.get(device.equipment).push(device.meta.svgAttributes)
      }
    }
    for (const eq of equipments) {
      const equipmentId = eq[0]
      // Find common model
      const [commonModel, diffModel, unmatchedModel] =
        commonDifferentProperties(deviceConfigs.get(equipmentId))
      const [commonSvg, diffSvg, unmatchedSvg] = commonDifferentProperties(
        svgAttributes.get(equipmentId)
      )

      eq[1].model = {
        ...eq[1].model,
        ...commonModel,
      }
      eq[1].commonModel = commonModel

      eq[1].model.svgAttributes = {
        ...commonSvg,
        ...diffSvg,
      }
      eq[1].commonSvgModel = commonSvg
      eq[1].originalModel = simpleCopy(eq[1].model)

      commonEditorConfigs.push(eq[1])
    }
    this.setEditorConfig(commonEditorConfigs)
  }

  /**
   * Starts editing mode for currently selected elements
   */
  public async enterEditMode(): Promise<void> {
    if (
      this.selectedAnnotationIds.length > 0 &&
      this.selectedDeviceIds.length > 0
    ) {
      this.handleError(
        new Error(
          'Geräte und Annotationen können nicht gleichzeitig editiert werden.'
        ),
        true
      )
    }
    if (
      this.selectedDeviceIds.length === 0 &&
      this.selectedAnnotationIds.length === 0
    ) {
      this.handleError(new Error('Nichts zum editieren ausgewählt.'), true)
    }
    if (this.selectedAnnotationIds.length > 0) {
      await this.setEditAnnotationsConfig()
    } else {
      await this.setEditDevicesConfig()
    }
  }

  public async saveEditAnnotations(): Promise<string[]> {
    const annotationIds = this.selectedAnnotationIds
    for (const config of this.editorConfigs) {
      for (const annotationId of annotationIds) {
        const annotation = this.getAnnotationById(annotationId)
        if (!annotation) {
          this.handleError(new Error('Could not find annotation.'), true)
        }
        annotation.meta.svgAttributes = config.model
        this.createOrUpdateInternalAnnotation(annotation)
      }
      await this.updateMeta('annotations', this._project.meta.annotations)
    }
    return annotationIds
  }

  public getAnnotationById(annotationId: string): AnnotationElement | null {
    const ind = this._project.meta.annotations.findIndex(
      (ann) => ann.id === annotationId
    )
    if (ind === -1) {
      return null
    } else {
      return AnnotationElement.safeCopy(this._project.meta.annotations[ind])
    }
  }

  /**
   * Cancel editing device
   */
  public async cancelEditDevice() {
    await this.setEditorConfig([])
  }

  /**
   * Update EquipmentVariant of devices
   * Note: Does not use Transaction as it does not support multiple Updates
   */
  public async updateEquipmentVariant(deviceIds: string[], variantId: string) {
    this.loadingStream.next(true)

    const equipment =
      this._equipmentHandler.getEquipmentFromVariantId(variantId)
    const equipmentConfig = equipment.config_editor_layout.model

    for (const deviceId of deviceIds) {
      const device = this.deviceHandler.getDeviceAssignmentById(deviceId)
      if (!device) {
        continue
      }
      const originalVariant = JSON.parse(
        JSON.stringify(device.equipment_variant)
      )
      const originalConfig = JSON.parse(JSON.stringify(device.config))
      const originalMeta = device.meta
      delete device.meta
      if (device) {
        if (device.equipment_variant !== variantId) {
          device.equipment_variant = variantId
          try {
            // Transfer config that can be transferred
            if (device.config) {
              Object.keys(device.config).forEach((key) => {
                if (equipmentConfig.hasOwnProperty(key)) {
                  equipmentConfig[key] = device.config[key]
                }
              })
            }
            device.config = equipmentConfig

            await this.updateDeviceAssignment(device)
            device.meta = originalMeta
            // unselect
            if (device.category === EquipmentCategory.SENDER) {
              this.unselectSender(device.id)
            } else {
              this.unselectReceiver(device.id)
            }
          } catch (err) {
            device.config = originalConfig
            device.equipment_variant = originalVariant
            device.meta = originalMeta
            this.loadingStream.next(false)
            throw err
          }
        }
      } else {
        this.loadingStream.next(false)
        throw new Error(`UpdateVariant: Invalid device id ${deviceId}`)
      }
    }
    this.loadingStream.next(false)
  }

  /**
   * Changes the floor of a device
   * Note: Does not use Transaction as it does not support multiple Updates
   */
  public async switchFloor(deviceIds: string[], floorId: string) {
    this.loadingStream.next(true)
    for (const deviceId of deviceIds) {
      const device = this.deviceHandler.getDeviceAssignmentById(
        deviceId,
        null,
        true
      )
      if (device) {
        device.floor = floorId
        await this.updateDeviceAssignment(device, false)
        await this.updateLinkedState()
      } else {
        this.loadingStream.next(false)
        throw new Error(`SwitchFloor: Invalid device id ${deviceId}`)
      }
    }
    this.loadingStream.next(false)
  }

  /**
   * Removes placement of devices
   * Note: Does not use Transaction as it does not support multiple Updates
   */
  public async unplaceDevices(deviceIds: string[]): Promise<string[]> {
    this.loadingStream.next(true)
    for (const deviceId of deviceIds) {
      const device = this.deviceHandler.getDeviceAssignmentById(deviceId)
      if (device && device.meta && device.meta.svgObjectPositions) {
        delete device.meta.svgObjectPositions
        try {
          await this.updateDeviceAssignment(device, false)
        } catch (err) {
          // restore
          this.loadingStream.next(false)
        }
      }
    }
    this.loadingStream.next(false)
    return deviceIds
  }

  /**
   * Save the devices that are currently being edited
   */
  public async saveEditDevice(): Promise<string[]> {
    this.loadingStream.next(true)
    const updatedDevices: string[] = []
    let promises = []
    let needSorting = false
    const maxFlex: string[] = []
    for (const editorConfig of this.editorConfigs) {
      const config = editorConfig.model
      const devices = editorConfig.devices
      let svgAttributes = {}
      if (config.svgAttributes) {
        svgAttributes = simpleCopy(config.svgAttributes)
        delete config.svgAttributes
      }
      // Find modified values
      const modifiedConfig = {}
      for (const key of Object.keys(config)) {
        if (!isEqual(config[key], editorConfig.originalModel[key])) {
          modifiedConfig[key] = config[key]
        }
      }

      for (const device of devices) {
        const oldDevice = this._deviceHandler.getDeviceAssignmentById(device.id)
        if (!oldDevice) {
          continue
        }
        // sort again if number or floor changed
        if (
          device.number !== oldDevice.number ||
          device.floor !== oldDevice.floor
        ) {
          needSorting = true
        }
        const deviceConfig = {}
        const equipment: Equipment =
          this._equipmentHandler.getEquipmentFromDevice(device)
        const equipmentConfigDict = Equipment.getFieldsWithDataID(equipment)

        for (const key of Object.keys(equipmentConfigDict)) {
          if (device.config.hasOwnProperty(key)) {
            deviceConfig[key] = device.config[key]
          } else if (config.hasOwnProperty(key)) {
            deviceConfig[key] = config[key]
          } else {
            deviceConfig[key] = equipment.config_editor_layout.model[key]
          }
        }
        for (const key of Object.keys(modifiedConfig)) {
          deviceConfig[key] = modifiedConfig[key]
        }

        device.config = deviceConfig
        if (device.meta) {
          device.meta.svgAttributes = {
            ...device.meta.svgAttributes,
            ...svgAttributes,
          }
        } else {
          device.meta = {
            svgAttributes,
          }
        }
        updatedDevices.push(device.id)
        promises.push(this.updateDeviceAssignment(device, true, false))
        if (promises.length > this.reqBatchSize) {
          await Promise.all(promises)
          promises = []
        }
        if (this._deviceHandler.isMaxFlex(device.id)) {
          maxFlex.push(device.id)
        }
      }
      this.loadingStream.next(false)
    }
    await Promise.all(promises)
    if (needSorting) {
      this._deviceHandler.sortLists()
      this._groupHandler.sortAllGroups()
    }
    maxFlex.forEach(async (mf) => {
      await this.removeUnusedLinksOfMaxFlex(mf)
    })
    await this.updateLinkedState()
    return updatedDevices
  }

  /**
   * Remove links to unused channels of maxflex
   * @param senderId ID of max flex
   */
  public async removeUnusedLinksOfMaxFlex(senderId: string) {
    if (this._deviceHandler.isMaxFlex(senderId)) {
      const sender = this._deviceHandler.getDeviceAssignmentById(senderId)
      const equipment = this._equipmentHandler.getEquipmentFromDevice(sender)
      const numChannels = equipment.channel_count
      const layout = getMaxFlexLayoutByValue(
        sender.config.maxflex.KeyLayout.value
      )
      const buttons = layout.blocks.map((block) => block.button)
      let channelNumbers = buttons.map((button) => {
        return sender.config.maxflex.keys[button].Channel.value
      })
      // make unique
      channelNumbers = [...new Set(channelNumbers)]
      // sort
      channelNumbers = channelNumbers.sort((a, b) => a - b)
      // go through all channels
      for (const c of [...Array(numChannels).keys()]) {
        // if channel not used
        if (channelNumbers.indexOf(c) === -1) {
          // remove linked devices
          const linkedReceivers = this._deviceHandler.getLinkedReceivers(
            senderId,
            c
          )
          const deleteLinks: Link[] = []
          linkedReceivers.forEach((receiver) => {
            const links = this._deviceHandler.findLinks(senderId, receiver, c)
            deleteLinks.push(...links)
          })
          if (deleteLinks.length) {
            await this._deviceHandler.deleteLinks(deleteLinks)
            Toast.open(`Links von Kanal ${c + 1} entfernt`)
          }
          // remove linked groups
          const linkedGroups = this._groupHandler.findGroupsOfSender(
            senderId,
            c
          )
          if (linkedGroups.length) {
            const groupIds = linkedGroups.map((g) => g.id)
            await this._groupHandler.deleteGroupLinks(groupIds, senderId, c)
            Toast.open(`Gruppen Links von Kanal ${c + 1} entfernt`)
          }
        }
      }
    }
  }

  /**
   * Updates a DeviceAssignment to the database
   */
  public async updateDeviceAssignment(
    updatedDevice: DeviceAssignment,
    configUpdate: boolean = true,
    updateLinks: boolean = true
  ): Promise<DeviceAssignment> {
    let updateData: DeviceAssignment
    if (updatedDevice.config === null) {
      updatedDevice.programmer_device_configuration = {
        data: [],
      }
      updatedDevice.programmer_device_configuration_optional = {
        data: [],
      }
    }
    if (updatedDevice.meta === null) {
      delete updatedDevice.meta
    }
    if (updatedDevice.meta && updatedDevice.meta.svgObjects) {
      // svgObject and connector can not be saved as they're not serializable
      const { meta, ...rest } = updatedDevice
      const { svgObjects, ...metaRest } = meta
      updateData = JSON.parse(JSON.stringify(rest))
      updateData.meta = metaRest
    } else {
      updateData = updatedDevice
    }

    if (updateData.config && configUpdate) {
      // Parse config and create Configuration and ConfigurationOptional for Windows Programmer
      const equipment =
        this._equipmentHandler.getEquipmentFromDevice(updatedDevice)
      const {
        configuration,
        configurationOptional,
        dirty,
        dirty_optional,
        updateLinked,
      } = this.deviceHandler.parseConfigWithDataId(
        updateData.config,
        equipment,
        updatedDevice.id
      )

      if (updateLinked) {
        // set linked receivers to dirty
        if (updatedDevice.category === EquipmentCategory.SENDER) {
          // find linked receivers
          const links = this._deviceHandler.findLinks(updatedDevice.id)
          for (const link of links) {
            // Backend can't handle this case automatically, so manually update the devices
            const wasUpdated = this._deviceHandler.setDeviceDirtyTeaching(
              link.receiver,
              true
            )
            if (wasUpdated) {
              // The internal device was updated, just do for backend
              const receiver = this._deviceHandler.getDeviceAssignmentById(
                link.receiver,
                null
              )
              receiver.dirty_teaching = true
              await this._deviceHandler.updateDevice(receiver)
            }
          }
          updateLinks = true
        }
      }

      updateData.programmer_device_configuration = {
        data: configuration,
      }
      updateData.programmer_device_configuration_optional = {
        data: configurationOptional,
      }

      // If device was dirty before, leave it dirty even if config did not change
      updateData.dirty = dirty || updatedDevice.dirty
      updateData.dirty_optional = dirty_optional || updatedDevice.dirty_optional
    }

    updatedDevice = await this.deviceHandler.updateDevice(updateData)

    if (updateLinks) {
      await this.updateLinkedState()
    }

    return updatedDevice
  }

  /**
   * Deletes a device assignment
   * @param device
   * @param reorder if true, all devices with higher numbers will be re-order to fill up any gaps
   * @returns reorderedDeviceIds: List of device ids that were re-ordered
   */
  async deleteDeviceAssignments(
    deviceIds: string[],
    reorder: boolean = false
  ): Promise<string[]> {
    // Check group membership
    const devices = []
    for (const deviceId of deviceIds) {
      const device = this._deviceHandler.getDeviceAssignmentById(deviceId)
      if (device) {
        devices.push(device)
        this._groupHandler.checkForDelete(device)
      }
    }

    await DeviceAssignment.batchDelete(this.apiClient, { ids: deviceIds })

    // Update internal state
    for (const device of devices) {
      this._deviceHandler.deleteInternalDevice(device)
      const ind = this._projectDump.device_assignments.findIndex(
        (dev) => dev.id === device.id
      )
      if (ind > -1) {
        this._projectDump.device_assignments.splice(ind, 1)
      }
    }
    await this.clearSelection()
    await this.updateLinkedState(false)

    let reorderedDeviceIds = []
    if (reorder) {
      devices.sort(
        (a: DeviceAssignment, b: DeviceAssignment) => a.number - b.number
      )
      // Re-ordering can not be done in transaction currently, since it does not support multiple update
      const reordered = await this._reorderDeviceAssignment(devices[0])
      reorderedDeviceIds = [...reorderedDeviceIds, ...reordered]
    }
    return reorderedDeviceIds
  }

  /**
   * Deletes a device assignment
   * @param device
   * @param reorder if true, all devices with higher numbers will be re-order to fill up any gaps
   */
  async _reorderDeviceAssignment(device: DeviceAssignment): Promise<string[]> {
    // Find all devices that need to be updated (device number higher than the number of the deleted device)
    const updateDevices: DeviceAssignment[] = []
    const deviceNr = device.number
    const devicesInFloor = this._deviceHandler.getDevicesInFloor(
      device.floor,
      device.category
    )
    const reservedNrs: number[] = []
    for (const dev of devicesInFloor) {
      // If device has "radio_address", do not re-order
      if (!dev.radio_address && dev.number > deviceNr) {
        updateDevices.push(dev)
      } else {
        reservedNrs.push(dev.number)
      }
    }

    // Assign new numbers
    updateDevices.sort(
      (a: DeviceAssignment, b: DeviceAssignment) => a.number - b.number
    )
    reservedNrs.sort((a: number, b: number) => a - b)
    let number = deviceNr
    for (const dev of updateDevices) {
      dev.number = number
      number += 1
      while (reservedNrs.findIndex((nr) => nr === number) > -1) {
        number += 1
      }
    }

    // Re-ordering can not be done in transaction currently, since it does not support multiple update
    const reorderedIds = []
    for (const dev of updateDevices) {
      // Note: Do not parallelize, could lead to conflicts
      reorderedIds.push(dev.id)
      await this.updateDeviceAssignment(dev, false)
    }
    return reorderedIds
  }

  /**
   * Deselect a device, either a receiver or sender
   * @param id
   */
  public async clearSelection(): Promise<void> {
    await this._store.dispatch('brelag/clearSelection')
    await this.updateLinkedState(true)
  }

  public set readOnly(readOnly: boolean) {
    this._store.dispatch('brelag/setReadOnly', readOnly)
  }

  public get readOnly(): boolean {
    return this._store.getters['brelag/readOnly']
  }

  public set isLockedByWinApp(isLockedByWinApp: boolean) {
    this._store.dispatch('brelag/setLockedByWinApp', isLockedByWinApp)
  }

  public get isLockedByWinApp(): boolean {
    return this._store.getters['brelag/isLockedByWinApp']
  }

  public set lockedBy(lockedBy: string) {
    this._store.dispatch('brelag/setLockedBy', lockedBy)
  }

  public get lockedBy(): string {
    return this._store.getters['brelag/lockedBy']
  }

  public set unlockedBy(unlockedBy: string) {
    this._store.dispatch('brelag/setUnlockedBy', unlockedBy)
  }

  public get unlockedBy(): string {
    return this._store.getters['brelag/unlockedBy']
  }

  public get canLock(): boolean {
    return this._canLock
  }

  public get store() {
    return this._store
  }

  public get apiClient() {
    return this._apiClient
  }

  public get selectedGroup(): DeviceGroup | null {
    return this._store.getters['brelag/selectedGroup']
  }

  public get selectedReceiverIds(): string[] {
    return this._store.getters['brelag/selectedReceivers']
  }

  public get selectedReceiver(): DeviceAssignment | null {
    return this._store.getters['brelag/selectedReceiver']
  }

  public get selectedUnlinkedReceivers(): string[] {
    return this._store.getters['brelag/selectedUnlinkedReceivers']
  }

  public get linkedGroups(): string[] {
    return this._store.getters['brelag/linkedGroups']
  }

  public get linkedReceiverIds(): string[] {
    return this._store.getters['brelag/linkedReceiverIds']
  }

  public get selectedLinkedReceivers(): string[] {
    return this._store.getters['brelag/selectedLinkedReceivers']
  }

  public get editorConfigs(): EditorConfig[] {
    return this._store.getters['brelag/editorConfigs']
  }

  public get unlinkedTeachingOptions(): EditorConfig[] {
    return this._store.getters['brelag/unlinkedTeachingOptions']
  }

  public get unlinkedDeviceGroupTree(): DeviceGroupNode[] {
    return this._store.getters['brelag/unlinkedDeviceGroupTree']
  }

  public get linkedDeviceGroupTree(): DeviceGroupNode[] {
    return this._store.getters['brelag/linkedDeviceGroupTree']
  }

  public get unlinkedReceivers(): DeviceGroupNode[] {
    return this._store.getters['brelag/unlinkedReceivers']
  }

  public get linkedReceivers(): DeviceGroupNode[] {
    return this._store.getters['brelag/linkedReceivers']
  }

  public async setEditorConfig(configs: EditorConfig[]) {
    return await this._store.dispatch('brelag/setEditorConfigs', configs)
  }

  public async setUnlinkedTeachingOptions(configs: EditorConfig[]) {
    return await this._store.dispatch(
      'brelag/setUnlinkedTeachingOptions',
      configs
    )
  }

  public get selectedReceiverId(): string | null {
    if (this.selectedReceiver) {
      return this.selectedReceiver.id
    } else {
      return null
    }
  }

  public get selectedSenderIds(): string[] {
    return this._store.getters['brelag/selectedSenders']
  }

  public get selectedSender(): DeviceAssignment | null {
    return this._store.getters['brelag/selectedSender']
  }

  public get selectedSenderId(): string | null {
    if (this.selectedSender) {
      return this.selectedSender.id
    } else {
      return null
    }
  }

  public get selectedChannel(): number {
    return this._store.getters['brelag/selectedChannel']
  }

  public get buildingsMap(): Map<string, Building> {
    return this._buildingsMap
  }

  public get floorsMap(): Map<string, FloorDump> {
    return this._floorsMap
  }

  /**
   * Select channel on currently selected sender
   * @param channel
   */
  public async selectChannel(channel: number): Promise<void> {
    if (channel === null) {
      // deselect channel
      await this._store.dispatch('brelag/selectChannel', channel)
    } else {
      const equipment = this._equipmentHandler.getEquipmentFromDevice(
        this.selectedSender
      )
      if (equipment.channel_count > channel) {
        await this._store.dispatch('brelag/selectChannel', channel)
      } else {
        console.warn(
          `Invalid channel: ${channel + 1}. Sender only supports ${
            equipment.channel_count
          } channels.`
        )
        // deselect channel
        await this._store.dispatch('brelag/selectChannel', null)
      }
    }
    await this.updateLinkedState(true)
  }

  /**
   * Select sender (channel 0 is selected in dispatch)
   */
  public async selectSender(senderId?: string): Promise<void> {
    await this._store.dispatch('brelag/selectSender', senderId)
    await this.updateLinkedState(true)
  }

  public async unselectSender(senderId?: string): Promise<void> {
    await this._store.dispatch('brelag/removeSenderFromSelection', senderId)
    await this.updateLinkedState(true)
  }

  public async selectSenders(senderIds: string[]): Promise<void> {
    await this._store.dispatch('brelag/selectSenders', senderIds)
    await this.updateLinkedState(true)
  }

  /**
   * Select sender & channel
   */
  public async selectSenderAndChannel(
    senderId: string,
    channel: number
  ): Promise<void> {
    await this._store.dispatch('brelag/selectSenderAndChannel', {
      senderId,
      channel,
    })
    await this.updateLinkedState(true)
  }

  public async addSenderToSelection(senderId: string): Promise<void> {
    await this._store.dispatch('brelag/addSenderToSelection', senderId)
  }

  public async toggleSenderSelection(senderId: string): Promise<void> {
    if (this.selectedSenderIds.findIndex((id) => id === senderId) === -1) {
      await this.addSenderToSelection(senderId)
    } else {
      await this.unselectSender(senderId)
    }
  }

  public async unselectSendersNotInFloor(floor: string): Promise<void> {
    const promises = []
    this.selectedSenderIds.forEach((id) => {
      const sender = this._deviceHandler.getDeviceAssignmentById(id)
      if (sender && sender.floor !== floor) {
        promises.push(
          this._store.dispatch('brelag/removeSenderFromSelection', id)
        )
      }
    })
    await Promise.all(promises)
    await this.updateLinkedState(true)
  }

  public async toggleReceiverSelection(receiverId: string): Promise<void> {
    if (this.selectedReceiverIds.findIndex((id) => id === receiverId) === -1) {
      await this.selectReceiver(receiverId, true)
    } else {
      await this.unselectReceiver(receiverId)
    }
  }

  /**
   * Select receiver
   * @param receiverId
   */
  public async unselectReceiver(receiverId: string): Promise<void> {
    await this._store.dispatch('brelag/removeReceiverFromSelection', receiverId)
    await this.updateLinkedState(true)
  }

  public async setSelectedReceivers(receiverIds: string[]): Promise<void> {
    await this._store.commit('brelag/setSelectedReceivers', receiverIds)
    await this.updateLinkedState(true)
  }

  public async toggleGroupSelection(groupId: string): Promise<void> {
    await this.selectReceiver(null)
    if (
      groupId === null ||
      (this.selectedGroup && this.selectedGroup.id === groupId)
    ) {
      await this.unselectGroups()
    } else {
      return this.selectGroup(groupId)
    }
  }

  public async unselectGroups(): Promise<void> {
    await this._store.dispatch('brelag/selectGroup', null)
    await this.updateLinkedState(true)
  }

  public async selectGroup(groupId: string): Promise<void> {
    await this._store.dispatch(
      'brelag/selectGroup',
      this._groupHandler.getGroup(groupId)
    )
    await this.updateLinkedState(true)
  }

  /**
   * Select receiver
   * @param receiverId
   */
  public async selectReceiver(
    receiverId: string,
    addToSelection: boolean = false
  ): Promise<void> {
    if (receiverId) {
      if (addToSelection) {
        await this._store.dispatch('brelag/addReceiverToSelection', receiverId)
      } else {
        await this._store.dispatch('brelag/selectReceiver', receiverId)
      }
    } else {
      await this._store.dispatch('brelag/selectReceiver', null)
    }
    await this.updateLinkedState(true)
  }

  /**
   * Select receiver
   * @param receiverId
   */
  public async selectReceivers(receiverIds: string[]): Promise<void> {
    await this._store.dispatch('brelag/selectReceivers', receiverIds)
    await this.updateLinkedState(true)
  }

  public get floors(): FloorDump[] {
    return this._floors
  }

  // Formatted to include building prefix
  public get floorOptions(): {
    text: string
    value: string
    floor: FloorDump
    building: Building
  }[] {
    return this._floors.map((floor) => {
      const building = this.buildingsMap.get(floor.building)
      return {
        text: `${building.prefix}: ${floor.prefix}`,
        value: floor.id,
        building,
        floor,
      }
    })
  }

  // Returns those floor options that have an image uploaded
  public get floorOptionsWithFloorPlan(): {
    text: string
    value: string
    floor: FloorDump
  }[] {
    return this.floorOptions.filter((option) => {
      return option.floor.processed_file !== null
    })
  }

  public get selectedFloor(): FloorDump {
    return this._selectedFloor
  }

  public get selectedBuilding(): string {
    return this._selectedBuilding
  }

  public get deviceHandler(): DeviceHandler {
    return this._deviceHandler
  }

  public get projectId(): string {
    return this.project.id
  }

  public get projectObject(): Project {
    return this._project
  }

  public get mandator(): Organisation {
    return this._mandator
  }

  public async getProject() {
    this._project = await this._apiClient.get<Project>(
      Project,
      this._project.id
    )
  }

  public setProjectObject(project: Project): void {
    this._project = project
  }

  public get project(): ProjectState {
    return this._projectDump
  }

  public get projectErrors(): ProjectHealthError[] {
    return this._projectErrors
  }

  public get customer(): Customer {
    return this._projectDump.customer
  }

  public get senders(): DeviceAssignment[] {
    return this.deviceHandler.senderList
  }

  public get receivers(): DeviceAssignment[] {
    return this.deviceHandler.receiverList
  }

  public get devices(): DeviceAssignment[] {
    return this.deviceHandler.deviceList
  }

  public get devicesInFloor(): DeviceAssignment[] {
    return this._deviceHandler.getDevicesInFloor(this.selectedFloor.id)
  }

  public get selectedAnnotationIds(): string[] {
    return this._store.getters['brelag/selectedAnnotations']
  }

  public get selectedDeviceIds(): string[] {
    return this._store.getters['brelag/selectedDevices']
  }

  /**
   * Sort a list of devices according to building, floor, and number
   * @param devices
   */
  public sortDevices(devices: DeviceAssignment[]): DeviceAssignment[] {
    if (this._floorsMap.size > 0) {
      let out: {
        device: DeviceAssignment
        floor_ordering: number
        building_ordering: number
      }[] = []
      const devicesLength = devices.length
      out.length = devicesLength
      for (let i = 0; i < devicesLength; i++) {
        const floor = this._floorsMap.get(devices[i].floor)
        if (floor) {
          const building = this._buildingsMap.get(floor.building)
          out[i] = {
            device: devices[i],
            floor_ordering: floor.ordering,
            building_ordering: building.ordering,
          }
        } else {
          out[i] = {
            device: devices[i],
            floor_ordering: 0,
            building_ordering: 0,
          }
        }
      }
      out = out.sort((a, b) => {
        if (a.building_ordering !== b.building_ordering) {
          return a.building_ordering - b.building_ordering
        } else if (a.floor_ordering !== b.floor_ordering) {
          return a.floor_ordering - b.floor_ordering
        } else {
          return a.device.number - b.device.number
        }
      })
      return out.map((d) => d.device)
    } else {
      return devices.sort((a, b) => {
        return a.number - b.number
      })
    }
  }

  /**
   * Functions checks if an eGate is present in the project
   */
  public get eGates() {
    return this.deviceHandler.eGates
  }

  public get senderReport() {
    return {
      assigned: this._linkedSenders,
      unassigned: this._unlinkedSenders,
    }
  }

  public get groupReport(): DeviceGroup[] {
    if (this.selectedReceiverIds.length === 1) {
      return this._groupHandler.findGroupsOfReceiver(
        this.selectedReceiverIds[0]
      )
    } else {
      return []
    }
  }

  /**
   * Returns all senders that are linked to the selected receiver and channel
   */
  public get linkedSenders(): DeviceAssignment[] {
    return this._linkedSenders
  }

  private senderSelectionCheck() {
    if (!this.selectedSender) {
      throw new Error('Kein Sender ausgewählt.')
    }
    // Don't use if (!this._selectedChannel) since this._selectedChannel can be 0, which is a valid value
    if (this.selectedChannel === undefined || this.selectedChannel === null) {
      throw new Error('Kein Kanal ausgewählt.')
    }
  }

  /**
   * Removes all links of selected sender
   */
  public async unlinkGroups(groupIds?: string[]): Promise<void> {
    this.senderSelectionCheck()
    if (!groupIds) {
      groupIds = this.linkedGroups
    }
    await this._groupHandler.deleteGroupLinks(
      groupIds,
      this.selectedSender.id,
      this.selectedChannel
    )
    await this.updateLinkedState()
  }

  /**
   * Removes all links of selected sender
   */
  public async unlinkReceivers(receiverIds?: string[]): Promise<void> {
    const deleteLinks: Link[] = []
    this.senderSelectionCheck()
    if (!receiverIds) {
      receiverIds = this.linkedReceiverIds
    }
    for (const receiverId of receiverIds) {
      // Check if this connection is protected through a group
      const groups = this._groupHandler.findLinks(
        receiverId,
        this.selectedSender.id,
        this.selectedChannel
      )
      if (groups.length > 0) {
        throw new Error(
          `Kann Link nicht entfernen, da Empfänger verbunden durch Gruppe '${groups[0].name}'`
        )
      }
    }

    for (const receiverId of receiverIds) {
      const links = this.deviceHandler.findLinks(
        this.selectedSender.id,
        receiverId,
        this.selectedChannel
      )
      deleteLinks.push(...links)
    }
    await this._deviceHandler.deleteLinks(deleteLinks)

    await this.updateLinkedState()
  }

  /**
   * Remove a link between selected receiver and sender
   */
  public async unlinkSelectedReceivers(): Promise<void> {
    if (!this.selectedLinkedReceivers) {
      throw new Error('Kein Empfänger ausgewählt.')
    }
    await this.unlinkReceivers(this.selectedLinkedReceivers)
  }

  /**
   * Sets the selected receivers to not dirty
   */
  public async setSelectedReceiversDirty(dirty = false): Promise<void> {
    await Promise.all(
      this.selectedReceiverIds.map((id) =>
        this._deviceHandler
          .setDeviceDirtyAll(id, dirty)
          .catch((error) => console.warn(error))
      )
    )
    // refresh receiver list
    await this.updateLinkedState()
  }

  /**
   * Sets the selected senders to not dirty
   */
  public async setSelectedSendersDirty(dirty = false): Promise<void> {
    await Promise.all(
      this.selectedSenderIds.map((id) =>
        this._deviceHandler
          .setDeviceDirtyAll(id, dirty)
          .catch((error) => console.warn(error))
      )
    )
    await this._updateSendersHierarchy()
  }

  /**
   * Copies all links from a sender/channel to another sender/channel
   * Note: Does not use Transaction as it does not support multiple Updates
   */
  public async copyLinks(
    fromSenderId: string,
    fromChannel: number,
    toSenderId: string,
    toChannel: number
  ): Promise<void> {
    if (fromSenderId === toSenderId && fromChannel === toChannel) {
      return
    }
    const links = this._deviceHandler.findLinks(
      fromSenderId,
      undefined,
      fromChannel
    )
    const newLinks: Link[] = []
    const updateLinks = []

    for (const link of links) {
      const existingLink = this._deviceHandler.findLink(
        toSenderId,
        link.receiver,
        toChannel
      )
      if (existingLink) {
        updateLinks.push({
          id: existingLink.id,
          options: link.options,
        })
      } else {
        newLinks.push({
          ...LINK_DEFAULT,
          project: this._projectDump.id,
          sender: toSenderId,
          receiver: link.receiver,
          sender_channel: toChannel,
          options: link.options,
        })
      }
    }
    await this._deviceHandler.createLinks(newLinks)
    await this._deviceHandler.updateLinkOptions(updateLinks)
    await this.updateLinkedState()
  }

  /**
   * Creates links between senders and receivers.
   * @param configs   Contains the receivers that should be linked
   * @param senderIds Sender ID
   * @param channel   Sender Channel
   */
  public async createLinks(
    configs: EditorConfig[],
    senderIds: string[],
    channel: number
  ): Promise<void> {
    if (senderIds.length === 0) {
      throw new Error('Kein Sender ausgewählt.')
    }
    if (senderIds.length > 1) {
      throw new Error('Nur ein Sender kann auf einmal verlinkt werden.')
    }
    const senderId = senderIds[0]
    // Don't use if (!channel) since channel can be 0, which is a valid value
    if (channel === undefined || channel === null) {
      throw new Error('Kein Kanal ausgewählt.')
    }
    const newLinks: Link[] = []
    const oldLinks: Link[] = []
    for (const editorConfig of configs) {
      for (const receiver of editorConfig.devices) {
        const existingLinks = this._deviceHandler.findLinks(
          senderId,
          receiver.id,
          channel
        )
        if (existingLinks.length) {
          oldLinks.push(
            ...existingLinks.map((l) => {
              l.options = editorConfig.model
              return l
            })
          )
        } else {
          newLinks.push({
            ...LINK_DEFAULT,
            project: this._projectDump.id,
            sender: senderId,
            receiver: receiver.id,
            sender_channel: channel,
            options: editorConfig.model,
          })
        }
      }
    }
    // update old links
    await this._deviceHandler.updateLinkOptions(oldLinks)

    // add new links
    await this._deviceHandler.createLinks(newLinks)
    await this.updateLinkedState()
  }

  /**
   * Function that creates output for PartsList view
   * @param perFloor set to true to create list per floor
   */
  public partsList(): PartsList {
    const partsList: PartsList = {
      floors: {},
      total: {},
      maxFlexTypes: {},
    }
    for (const device of this.devices) {
      if (!partsList.floors.hasOwnProperty(device.floor)) {
        const floor = this.floorsMap.get(device.floor)
        partsList.floors[floor.id] = { floor, parts: {} }
      }
      const variant =
        this._equipmentHandler.getVariantFromDeviceAssignment(device)
      const floorMap = partsList.floors[device.floor]
      if (!floorMap.parts.hasOwnProperty(variant.id)) {
        floorMap.parts[variant.id] = {
          amount: 0,
          artNr: variant.article_number,
          label: variant.device_variant_name,
          category: device.category,
          variant,
        }
      }
      if (!partsList.total.hasOwnProperty(variant.id)) {
        partsList.total[variant.id] = {
          amount: 0,
          artNr: variant.article_number,
          label: variant.device_variant_name,
          category: device.category,
          variant,
        }
      }
      // Count the individual MaxFlex types
      if (this.deviceHandler.isMaxFlexByEquipmentVariant(variant.id)) {
        const keyLayout = device.config?.maxflex?.KeyLayout.value
        if (keyLayout) {
          if (!partsList.maxFlexTypes.hasOwnProperty(variant.id)) {
            partsList.maxFlexTypes[variant.id] = {}
          }
          if (!partsList.maxFlexTypes[variant.id].hasOwnProperty(keyLayout)) {
            partsList.maxFlexTypes[variant.id][keyLayout] = 0
          }
          partsList.maxFlexTypes[variant.id][keyLayout] += 1
        }
      }

      // Update counts
      floorMap.parts[variant.id].amount += 1
      partsList.total[variant.id].amount += 1
    }

    return partsList
  }

  static getInstance(store?: Store<any>) {
    if (!ProjectEditor.instance) {
      if (!store) {
        throw new Error('If no instance available store has to be provided!')
      }
      ProjectEditor.instance = new ProjectEditor(store)
    }
    return ProjectEditor.instance
  }

  public async deleteAnnotation(annotationId: string): Promise<void> {
    const ind = this._project.meta.annotations.findIndex(
      (ann) => ann.id === annotationId
    )
    if (ind > -1) {
      this._project.meta.annotations.splice(ind, 1)
    }
    await this.updateMeta('annotations', this._project.meta.annotations)
    this._projectDump.meta.annotations.splice(
      0,
      this._projectDump.meta.annotations.length,
      ...this._project.meta.annotations
    )
  }

  public async updateAnnotation(annotation: AnnotationElement): Promise<void> {
    this.createOrUpdateInternalAnnotation(annotation)
    await this.updateMeta('annotations', this._project.meta.annotations)
    this._projectDump.meta.annotations.splice(
      0,
      this._projectDump.meta.annotations.length,
      ...this._project.meta.annotations
    )
  }

  /**
   * Update one part of the project meta
   */
  public async updateMeta(
    key: string,
    meta: any,
    getBackend = true
  ): Promise<Project> {
    let project: Project
    if (getBackend) {
      project = await apiClientV2.get<Project>(Project, this._project.id)
    } else {
      project = this._project
    }
    project.meta[key] = meta

    // Use hookoptions to not lock/unlock unnecessarily
    this._project = await this._apiClient.update<Project, Project>(
      Project,
      project,
      {
        beforeSaveOptions: {
          noLock: true,
        },
        afterSaveOptions: {
          noUnlock: true,
        },
      }
    )
    return this._project
  }

  /**
   * Unlocks project for others to edit again
   */
  public async unlockProject(): Promise<void> {
    await Project.unlock(this._apiClient, this._projectDump.id)
    this.readOnly = true
  }

  /**
   * Locks Project so that it can be edited
   */
  public async lockProject(): Promise<void> {
    try {
      await Project.lock(this._apiClient, this._projectDump.id)
      // Refresh
      await this.loadProject(this.project.id, this._apiClient, false)
    } catch (err) {
      let message
      if (
        err.response &&
        err.response.data &&
        err.response.data['editing_locked_by']
      ) {
        message = `Das Projekt ist zur Zeit gesperrt durch Benutzer '${err.response.data['editing_locked_by']}'`
      } else {
        message = errorToStrings(err).join('<br/>')
      }
      await this.loadProject(this.project.id, this._apiClient, false)
      throw new Error(message)
    }
    this.readOnly = false
  }

  /**
   * Calculates the EditorConfig's for the given selection
   * @param receiverIds
   * @param senderId
   * @param channel
   */
  public getTeachingOptions(
    receiverIds: string[],
    senderId: string,
    channel: number,
    editMode = false
  ) {
    const commonEditorConfigs: EditorConfig[] = []

    // Gather all configs and models
    const equipments: Map<string, EditorConfig> = new Map()
    const linkOptions: Map<string, any[]> = new Map()

    const sender = this.deviceHandler.getDeviceAssignmentById(senderId)

    for (const deviceId of receiverIds) {
      const device = this.deviceHandler.getDeviceAssignmentById(deviceId)
      if (!device) {
        continue
      }
      if (!equipments.has(device.equipment)) {
        const equipment = this._equipmentHandler.getEquipmentFromDevice(device)

        equipments.set(device.equipment, {
          model: {},
          ...simpleCopy(equipment.teaching_options),
          annotations: [],
          devices: [],
          device_type_name: equipment.device_type_name,
        })
        linkOptions.set(device.equipment, [])
      }
      equipments.get(device.equipment).devices.push(device)
      const link: Link = this._deviceHandler.findLink(
        senderId,
        device.id,
        channel
      )
      if (link !== null) {
        linkOptions.get(device.equipment).push(link.options)
      }
    }

    for (const eq of equipments) {
      const equipmentId = eq[0]
      // Find common model
      const [commonModel, diffModel, unmatchedModel] =
        commonDifferentProperties(linkOptions.get(equipmentId))

      eq[1].model = {
        ...eq[1].model,
        ...commonModel,
      }

      commonEditorConfigs.push(eq[1])

      if (this._equipmentHandler.isEgate(eq[0])) {
        // If only one eGate is being assigned, we can set the default keyStorageId and keyDesignator
        const eGate: DeviceAssignment = eq[1].devices[0]
        if (!editMode) {
          // TODO: What if several linking... ?
          eq[1].model.keyStorageId = this.deviceHandler.findMinStorageId(eGate)
        }

        if (eq[1].devices.length !== 1) {
          // TODO: When actually doing the linking, throw error
          console.warn('eGate sollten nur einzeln zugewiesen werden.')
        }
        // Set to selected sender number
        if (sender && !editMode) {
          eq[1].model.keyDesignator = sender.number
        }
      }
    }

    return commonEditorConfigs
  }

  /**
   * Prepares teaching options form for selected receivers
   * @param groupView if true, use group selection
   * @param editLinks if true, existing links should be updated. Otherwise, new links should be created
   */
  public async updateTeachingOptions(groupView = false, editLinks = false) {
    let receiverIds = []

    if (groupView) {
      receiverIds = this._groupHandler.receiversInSelectedGroup
    } else {
      if (editLinks) {
        receiverIds = this.selectedReceiverIds
      } else {
        receiverIds = this.selectedUnlinkedReceivers
      }
    }

    const commonEditorConfigs: EditorConfig[] = this.getTeachingOptions(
      receiverIds,
      this.selectedSenderId,
      this.selectedChannel,
      editLinks
    )

    let senderEquipment: Equipment
    if (this.selectedSender) {
      senderEquipment = this._equipmentHandler.getEquipmentFromDevice(
        this.selectedSender
      )
    }
    commonEditorConfigs.forEach((config) => {
      if (config.model.hasOwnProperty('keyTxAddress')) {
        // This is a MX FE Ultra Receiver
        if (config.devices.length === 1) {
          const mxFeUltraReceiver = config.devices[0]
          // We need to check if the receiver has a link with another sender where keyTxAddress is active
          const links = this.deviceHandler.findLinks(
            undefined,
            mxFeUltraReceiver.id
          )
          let keyTxAddressLink: Link | null = null
          for (const link of links) {
            if (link.options.keyTxAddress) {
              keyTxAddressLink = link
              break
            }
          }
          if (!keyTxAddressLink) {
            // Only automatically activate option if its a virtual sender (and not editing an existing link)
            if (senderEquipment?.is_virtual && !editLinks) {
              config.model.keyTxAddress = 1
            }
          } else {
            // Inform user and deactivate activating option
            config.tabs.forEach((tab) => {
              tab.fields.forEach((field) => {
                if (field.key === 'keyTxAddress') {
                  const linkedSender =
                    this.deviceHandler.getDeviceAssignmentById(
                      keyTxAddressLink.sender
                    )
                  field.properties.label += ` (Bereits als Senderadresse beim Sender ${
                    linkedSender.device_number
                  } auf Kanal ${
                    keyTxAddressLink.sender_channel + 1
                  } eingelernt)`
                  field.editable = false
                }
              })
            })
          }
        }
      }
    })
    await this.setUnlinkedTeachingOptions(commonEditorConfigs)
  }

  /**
   * Updates list of linked and unlinked devices
   */
  public async updateLinkedState(onlySelectionChanged = false) {
    // Use last selection for improved updates
    if (
      this._lastSelectedChannel !== null &&
      this._lastSelectedChannel === this.selectedChannel
    ) {
      // console.log('channel did not change', this._lastSelectedChannel)
    }
    if (
      this._lastSelectedSenderId !== null &&
      this._lastSelectedSenderId === this.selectedSenderId
    ) {
      // console.log('sender did not change', this._lastSelectedSenderId)
    }

    const linked: DeviceAssignment[] = []
    let unlinked: DeviceAssignment[] = []
    // Update receivers
    if (this.selectedSenderId) {
      const ids = this._deviceHandler.getLinkedReceivers(
        this.selectedSenderId,
        this.selectedChannel
      )
      const receiversLength = this.receivers.length
      for (let i = 0; i < receiversLength; i++) {
        if (ids.has(this.receivers[i].id)) {
          linked.push(this.receivers[i])
        } else {
          unlinked.push(this.receivers[i])
        }
      }
    } else {
      unlinked = this.receivers
    }

    await this._updateReceiversHierarchy(onlySelectionChanged, linked, unlinked)
    await this._updateSendersHierarchy(onlySelectionChanged)

    // Update senders
    if (this.selectedReceiverId) {
      // TODO: IS THIS NEEEDED HERE??

      const ids = this._deviceHandler.getConnectedSenders(
        this.selectedReceiverId
      )
      const linked: DeviceAssignment[] = []
      const unlinked: DeviceAssignment[] = []
      const sendersLength = this.senders.length
      for (let i = 0; i < sendersLength; i++) {
        if (ids.has(this.senders[i].id)) {
          linked.push(this.senders[i])
        } else {
          unlinked.push(this.senders[i])
        }
      }
      this._linkedSenders = linked
      this._unlinkedSenders = unlinked
    } else {
      this._linkedSenders = []
      this._unlinkedSenders = []
    }

    this._lastSelectedChannel = simpleCopy(this.selectedChannel)
    this._lastSelectedSenderId = simpleCopy(this.selectedSenderId)
  }

  // TODO: make util fuction with dump as input
  public projectStructure(selectable: boolean = false): DeviceTree[] {
    const buildings = []
    for (const building of this._projectDump.buildings) {
      const floors = []
      for (const floor of building.floors) {
        floors.push({
          text: floor.title,
          children: [],
          data: {
            id: floor.id,
            type: TreeNodeType.FLOOR,
            floor,
          },
          state: {
            selectable,
            expanded: this._selectedFloor
              ? this._selectedFloor.id === floor.id
              : false,
          },
        })
      }
      buildings.push({
        text: building.title,
        children: floors,
        data: {
          id: building.id,
          type: TreeNodeType.BUILDING,
          building,
        },
        state: { expanded: true, selectable },
      })
    }
    return buildings
  }

  private additionalChannelText = (
    sender: DeviceAssignment,
    number: number
  ) => {
    if (this._deviceHandler.isSww(sender)) {
      return `(${SwwChannelText[number]})`
    }
    return ''
  }

  private _prepareChannelNode = (
    sender: DeviceAssignment,
    senderEquipment: Equipment,
    channelNumbers: number[],
    egates,
    f: number,
    b: number,
    i: number
  ) => {
    const senderSelected = sender.id === this.selectedSenderId

    return channelNumbers.map((channel, index) => {
      const node = {
        text: `Kanal: ${channel + 1} ${this.additionalChannelText(
          sender,
          channel
        )}`,
        data: {
          id: channel.toString(),
          type: TreeNodeType.CHANNEL,
          egates: egates[channel],
          floor: sender.floor,
          sender: sender.id,
          index,
          indexSender: i,
          indexFloor: f,
          indexBuilding: b,
        },
        state: {
          selectable: true,
          selected: senderSelected && channel === this.selectedChannel,
        },
      }

      // For the equipment with device_type_name: "FM8 PRO", there is special case:
      // It can only be programmed on channel 8, but numChannels is 1 because the mapping
      // is done in the DLL. But to the user we still want to display it as "channel 8"
      if (senderEquipment.device_type_name === 'FM8 PRO' && channel === 0) {
        node.text = 'Kanal 8'
      }

      // For virtual senders, only Channel 8 is programmable, but we want to display it as "Channel 1"
      if (senderEquipment.is_virtual && channel === 7) {
        node.text = 'Kanal 1'
      }

      return node
    })
  }

  private _prepareSenderNodes(senders: DeviceAssignment[]) {
    const newTree = this.projectStructure()

    let i = 0
    for (const sender of senders) {
      const senderEquipment =
        this._equipmentHandler.getEquipmentFromDevice(sender)
      const numChannels = senderEquipment.channel_count
      const deviceIcon = this._equipmentHandler.getDeviceIcon(sender)
      const comments = getMetaSvgAttribute(sender, 'textLines') as string[]
      const remark = comments && comments.length > 0 ? comments[0] : undefined

      let channelNumbers = [...Array(numChannels).keys()]
      // check if maxflex
      if (this._deviceHandler.isMaxFlex(sender.id)) {
        // edit channels list to only show used ones
        const layout = getMaxFlexLayoutByValue(
          sender.config.maxflex.KeyLayout.value
        )
        const buttons = layout.blocks.map((block) => block.button)
        channelNumbers = buttons.map((button) => {
          return sender.config.maxflex.keys[button].Channel.value
        })
        // make unique
        channelNumbers = [...new Set(channelNumbers)]
        // sort
        channelNumbers = channelNumbers.sort((a, b) => a - b)
      }
      if (senderEquipment.is_virtual) {
        channelNumbers = [7]
      }
      // find linked egate
      const egates: { [key: string]: string[] } = {}
      for (const c of channelNumbers) {
        const linkedReceivers = this._deviceHandler.getLinkedReceivers(
          sender.id,
          c
        )
        egates[c] = []
        linkedReceivers.forEach((receiver) => {
          const device = this._deviceHandler.getDeviceAssignmentById(
            receiver,
            EquipmentCategory.RECEIVER,
            false
          )
          if (device && this._equipmentHandler.isEgate(device.equipment)) {
            const link: Link = this._deviceHandler.findLink(
              sender.id,
              device.id,
              c
            )
            if (link) {
              egates[c].push(
                `${device.device_number} - ${link.options.keyStorageId}`
              )
            } else {
              egates[c].push(`${device.device_number}`)
            }
          }
        })
      }

      for (let b = 0; b < newTree.length; b++) {
        const building = newTree[b]
        for (let f = 0; f < building.children.length; f++) {
          const floor = building.children[f]
          if (floor.data.id === sender.floor) {
            floor.children.push({
              text: sender.device_number,
              children: this._prepareChannelNode(
                sender,
                senderEquipment,
                channelNumbers,
                egates,
                f,
                b,
                i
              ),
              data: {
                ...sender,
                type: TreeNodeType.DEVICE,
                deviceIcon,
                remark,
              },
              state: {
                selectable: true,
                selected: false,
                expanded: sender.id === this.selectedSenderId,
              },
            })
          }
        }
      }

      i += 1
    }
    return newTree
  }

  /**
   * Function that calculates the hierarchy/tree of building/floor/sender/channel
   * @param onlySelectionChanged If true, only the selection in the frontend changed, not any data
   */
  private async _updateSendersHierarchy(
    onlySelectionChanged = false
  ): Promise<void> {
    if (onlySelectionChanged) {
      // TODO: Do we need to do anything here? Probably not
      // Copy existing tree
      // const senderTree: DeviceTree = simpleCopy(this._store.getters['brelag/senderTree'])
      // TODO: Remember last selectedSender & channel
      // Only change selected/expanded state if one of those changed
    } else {
      const newTree = this._prepareSenderNodes(this.senders)

      for (const building of newTree) {
        let numDevices = 0
        for (const floor of building.children) {
          numDevices += floor.children.length
          floor.text += ` [${floor.children.length} Sender]`
        }
        building.text += ` [${numDevices} Sender]`
      }

      await this._store.dispatch('brelag/updateSenderTree', {
        newTree,
      })
    }
  }

  private _prepareReceiverNodes(receivers: DeviceAssignment[]) {
    // Create all device nodes
    const linkedReceiverIds: Set<string> = new Set()
    receivers.forEach((receiver) => {
      linkedReceiverIds.add(receiver.id)
    })
    const deviceNodes: DeviceNode[] = []
    for (const receiver of receivers) {
      const selected =
        this.selectedReceiverIds.findIndex((id) => id === receiver.id) > -1
      const deviceIcon = this._equipmentHandler.getDeviceIcon(receiver)
      const comments = getMetaSvgAttribute(receiver, 'textLines') as string[]
      const remark = comments && comments.length > 0 ? comments[0] : undefined

      // Basic Object for data attribute.
      const data: DeviceNodeDataProxy = {
        ...receiver,
        type: TreeNodeType.DEVICE,
        isRepeatingOnly: false, // Default value. (Gets overwritten by the handler below)
        deviceIcon,
        remark,
        _parameters: {
          senderId: this.selectedSenderId,
          receiverId: receiver.id,
          channel: this.selectedChannel,
          hasLinkedReceiverId: linkedReceiverIds.has(receiver.id),
          _deviceHandler: this._deviceHandler,
        },
      }

      // Proxy handler for the above 'data' object.
      // This handler does lazy load the 'data.isRepeatingOnly' property when it gets requested.)
      const dataHandler = {
        get(target: DeviceNodeDataProxy, prop: string) {
          if (prop === 'isRepeatingOnly') {
            let isRepeatingOnly = false
            if (target._parameters.hasLinkedReceiverId) {
              // Check if modified config
              isRepeatingOnly =
                target._parameters._deviceHandler.getIsRepeatingOnly(
                  target._parameters.senderId,
                  target._parameters.receiverId,
                  target._parameters.channel
                )
            }
            return isRepeatingOnly
          }
          return Reflect.get(target, prop)
        },
      }

      const deviceNode: DeviceNode = {
        text: receiver.device_number,
        data: new Proxy<DeviceNodeDataProxy>(data, dataHandler), // define data via proxy
        state: {
          selectable: true,
          selected: selected,
          expanded: selected,
        },
      }
      deviceNodes.push(deviceNode)
    }
    // Save device nodes
    this._receiverNodes = deviceNodes
    return deviceNodes
  }

  private async _updateReceiversHierarchy(
    onlySelectionChanged: boolean,
    linkedReceivers: DeviceAssignment[],
    unlinkedReceivers: DeviceAssignment[]
  ): Promise<void> {
    // TODO: handle onlySelectionChanged
    // if (!onlySelectionChanged || !this._receiverNodes) {
    //   // Data actually changed, update all nodes fully
    //   this._prepareDeviceNodes(linkedReceivers)
    // }

    // Copy existing nodes
    // const receiversNodes = simpleCopy(this._receiverNodes)
    const linkedNodes = this._prepareReceiverNodes(linkedReceivers)
    const unlinkedNodes = this._prepareReceiverNodes(unlinkedReceivers)

    await this._store.dispatch('brelag/updateReceiverNodes', {
      linkedReceivers: linkedNodes,
      unlinkedReceivers: unlinkedNodes,
    })
  }

  public get equipmentHandler(): EquipmentHandler {
    return this._equipmentHandler
  }

  public get groupHandler(): DeviceGroupHandler {
    return this._groupHandler
  }

  public get equipment(): EquipmentDump[] {
    return this._equipmentHandler.equipment
  }

  public get equipmentVariants(): EquipmentVariant[] {
    return this._equipmentHandler.equipmentVariants
  }

  public canExport(): string | null {
    // Check if all floors have device arrow
    for (const floor of this.floors) {
      let hasDeviceArrow = false
      for (const ann of this.project.meta.annotations) {
        if (
          ann.floor === floor.id &&
          ann.category === AnnotationCategory.DEVICE_ARROW
        ) {
          hasDeviceArrow = true
        }
      }
      if (!hasDeviceArrow) {
        return `Stockwerk ${floor.title} hat noch keinen Pfeil. Trotzdem exportieren?`
      }
    }
    return null
  }

  /**
   * Exports floors as PDF
   * @param selectedFloors can be used to export a subset of floors. If undefined, will export all floors
   */
  public async exportProject(selectedFloors: string[]): Promise<void> {
    return await exportPDF(this, selectedFloors)
  }

  public async getVersionLog(pageSize: number): Promise<string[]> {
    const response = await this.apiClient.customGet(
      `${ProjectExport.apiUrl}/`,
      {
        page: 1,
        page_size: pageSize,
        project: this.project.id,
        is_auto_backup: false,
        order_by: 'version_dsc',
      }
    )
    const versions: ProjectExport[] = response.results.sort((a, b) =>
      moment(b.create_time).diff(moment(a.create_time))
    )
    return versions.map(
      (ver) =>
        `V${ver.version}, ${moment(ver.create_time).format('DD.MM.YYYY')}: ${
          ver.description
        }`
    )
  }

  public async getCurrentVersion(): Promise<string> {
    const response = await this.apiClient.customGet(
      `${ProjectExport.apiUrl}/`,
      {
        page: 1,
        page_size: 1,
        project: this.project.id,
        is_auto_backup: false,
        order_by: 'version_dsc',
      }
    )
    if (response.results.length > 0) {
      const version: ProjectExport = response.results[0]
      return version.version
    } else {
      // no project export available, use 1.00
      return '1.00'
    }
  }

  private createOrUpdateInternalAnnotation(annotation: AnnotationElement) {
    const ind = this._project.meta.annotations.findIndex(
      (ann) => ann.id === annotation.id
    )
    if (ind === -1) {
      this._project.meta.annotations.push(annotation)
    } else {
      Vue.set(this._project.meta.annotations, ind, annotation)
    }
  }

  public async updateFloorPlanSettings(
    floor: Floor,
    settings: FloorPlanSettings
  ) {
    await this.apiClient.update<FloorPatchSerializer, Floor>(Floor, {
      id: floor.id,
      floor_plan_settings: settings,
      ordering: floor.ordering,
    })
    await this.loadProject(this.project.id, this.apiClient, false)
  }

  public async loadLegacyProject(
    dump: {
      device_assignments: DeviceAssignment[]
      links: Link[]
      status: string | number
    },
    apiClient: ApiClientV2
  ) {
    // Create the connection and connection_optional parametres
    const equipmentMap: Map<string, Equipment> = new Map()
    const deviceEquipmentMap: Map<string, Equipment> = new Map()
    for (const device of dump.device_assignments) {
      let equipment
      if (equipmentMap.has(device.equipment_variant)) {
        equipment = equipmentMap.get(device.equipment_variant)
      } else {
        const equipmentVariant = await apiClient.get<EquipmentVariant>(
          EquipmentVariant,
          device.equipment_variant
        )
        equipment = await apiClient.get<Equipment>(
          Equipment,
          equipmentVariant.equipment
        )
        equipmentMap.set(device.equipment_variant, equipment)
      }
      deviceEquipmentMap.set(device.id, equipment)

      // For SWW and MaxFlex transform from excel export format to our format
      for (const key of Object.keys(device.config)) {
        if (key == BrelagConfigKey.SWW) {
          device.config[key] = transformLegacyImportSwwConfig(
            device.config[key]
          )
        } else if (key == BrelagConfigKey.MAXFLEX) {
          device.config[key] = transformLegacyImportMaxFlexConfig(
            device.config[key]
          )
        }
      }

      const { configuration, configurationOptional, dirty, dirty_optional } =
        this.deviceHandler.parseConfigWithDataId(device.config, equipment, null)

      device.programmer_device_configuration = {
        data: configuration,
      }
      device.programmer_device_configuration_optional = {
        data: configurationOptional,
      }
      device.dirty = true
    }

    // Prepare link configuration
    for (const link of dump.links) {
      const equipment = deviceEquipmentMap.get(link.receiver)
      this.deviceHandler.prepareLinkConfiguration(link, {
        checkStorageIdUnique: false,
        equipment,
        legacyProject: true,
      })
    }

    // Adjust for legacy ProjectStatus
    if (
      dump.status === '9e9c8fda-9b14-48c7-a220-b072a0a359e7' ||
      dump.status === 'a96086b2-4f0f-4896-84f4-9ed6385e3f11'
    ) {
      dump.status = 0
    } else if (
      dump.status === '13e50340-224d-46a5-bd93-f95f9a6d3505' ||
      dump.status === 'c1d8743f-07ec-49c1-91a5-c1f87b42d1e9'
    ) {
      dump.status = 1
    } else {
      dump.status = 0
    }

    const bgTask = await apiClient.customPost('brelag/project/import-project', {
      dump,
    })
    return bgTask
  }

  async moveEgateStorage(
    eGate: DeviceAssignment,
    storageId: number,
    moveUp: boolean,
    moveToAvailable: boolean = false
  ): Promise<number> {
    return this.deviceHandler.moveEgateStorage(
      eGate,
      storageId,
      moveUp,
      moveToAvailable
    )
  }

  async deleteEgateStorage(
    eGate: DeviceAssignment,
    storageId: number
  ): Promise<void> {
    return this.deviceHandler.deleteEgateStorage(eGate, storageId)
  }

  /**
   * Tries to automatically fix a project error and reloads project
   * @param error Error to fix.
   */
  async tryFixError(error: ProjectHealthError): Promise<void> {
    await tryFixError(error, this)
    // Reload project. One fix could solve several errors.
    await this.loadProject(this.project.id, this.apiClient, false)
  }
}

export default {
  mandator: 'Mandator | Mandators',
  admin: 'Admin | Admins',
  supporter: 'Supporter | Supporters',
  equipment: 'Equipment | Equipment',
  equipmentgroup: 'Equipment Group | Equipment Groups',
  offer: 'Offer | Offers',
  album: 'Album | Albums',
  project: 'Project | Projects',
  customer: 'Customer | Customers',
  user: 'User | Users',
  webshop: 'Webshop | Webshop',
  order: 'Order | Orders',
  phone: 'Phone',
  email: 'E-Mail',
  currency: 'Currency',
  floorPlan: 'Floor Plan',
  projectEditor: 'Project Editor',
  projectImport: 'Project Import',
  knockautDashboard: 'KNOCKAUT',
  knockautXProgrammers: 'KnockautX Programmers',
  knockautLicenseKey: 'Knockaut License',
  knockautModule: 'Module | Modules',
  building: 'Building',
  floor: 'Floor | Floors',
  components: {
    mandator: {
      admin_user_group: 'Admin User Group',
    },
    fields: {
      category: {
        label: 'Category',
        placeholder: 'Select a category',
      },
      price: {
        label: 'Price',
        placeholder: '3.95',
      },
    },
  },
  models: {
    project: {
      fields: {
        organisation: 'Organisation',
        title: 'Title',
        commission: 'Commission',
        status: 'Status',
        customer: 'Customer',
        customer_name: 'Customer',
        meta: {
          commission: 'Commission',
          description: 'Description',
          address: 'Address',
          zip: 'ZIP',
          city: 'City',
          country: 'Country',
          project_leader: 'Project Leader',
          programmer: 'Programmer',
          remarks: 'Remarks',
        },
      },
      placeholders: {
        organisation: 'Organisation',
        title: 'Title',
        commission: 'Commission',
        status: 0,
        customer: '',
        customer_name: '',
        meta: {
          commission: 'Commission',
          description: 'Description',
          address: 'Address',
          zip: 'ZIP',
          city: 'City',
          country: 'Country',
          project_leader: 'Project Leader',
          programmer: 'Programmer',
          remarks: 'Remarks',
        },
      },
    },
    customer: {
      fields: {
        organisation: 'Organisation',
        name: 'Name',
        meta: {
          address: 'Address',
          zip: 'ZIP',
          city: 'City',
          country: 'Country',
          contact_person: 'Contact Person',
          email: 'E-Mail',
          tel_central: 'Tel Central',
          tel_mobile: 'Tel Mobile',
          tel_direct: 'Tel Direct',
        },
      },
      placeholders: {
        organisation: 'Organisation',
        name: 'Name',
        meta: {
          address: 'Address',
          zip: 'ZIP',
          city: 'City',
          country: 'Country',
          contact_person: 'Contact Person',
          email: 'E-Mail',
          tel_central: 'Tel Central',
          tel_mobile: 'Tel Mobile',
          tel_direct: 'Tel Direct',
        },
      },
    },
    building: {
      fields: {
        project: 'Project',
        project_title: 'Project',
        title: 'Title',
        ordering: 'Order',
      },
      placeholders: {
        project: '',
        project_title: '',
        title: '',
        ordering: '',
      },
    },
    plan: {
      fields: {
        building: 'Building',
        building_title: 'Building',
        picture: 'Picture',
        file_name: 'File Name',
        title: 'Title',
        ordering: 'Order',
      },
      placeholders: {
        building: '',
        building_title: '',
        picture: '',
        file_name: '',
        title: '',
        ordering: '',
      },
    },
    equipment: {
      fields: {
        mandator: 'Mandator',
        category: 'Category',
        name: 'Name',
        description: 'Description',
        handle: 'Handle',
        config_editor_layout: 'Config Editor Layout',
        config_schema: 'Config Schema',
      },
      placeholders: {
        mandator: '',
        category: '',
        name: '',
        description: '',
        handle: '',
        config_editor_layout: '{}',
        config_schema: '',
      },
    },
    'knockaut-module': {
      prettyName: 'Knockaut Module',
      fields: {
        name: 'Name',
        author: 'Author',
        description: 'Description',
        version: 'Version',
        price: 'Price',
        guid: 'Library GUID',
        module_guid: 'Module GUID',
        github_url: 'GitHub Repository URL',
        is_subscription: 'Is subscription',
        is_internal: 'Is internal',
        needs_instance: 'Needs instance',
      },
      placeholders: {
        name: '',
        author: '(f.ex. Brelag Schweiz AG)',
        description: '',
        version: '',
        guid: 'The GUID from the library.json file of the Repository',
        module_guid: '',
        github_url: '',
        is_subscription: '',
        is_internal: '',
        needs_instance: '',
      },
    },
  },
}

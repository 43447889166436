export default {
  description: {
    label: 'Brief description',
    placeholder: 'Description',
  },
  orderingField: {
    setAtEnd: 'Set at end',
    setManually: 'Set manually',
  },
  avatar: {
    avatar: 'Avatar',
    currentAvatar: 'Current Avatar',
    resolutionLimit: 'Resolution larger than 10x10, smaller than 1024x1024',
    fileSizeLimit: 'Maximum file size allowed is 200kB',
  },
}

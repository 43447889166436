
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import UploadForm from '@/components/common/UploadForm.vue'

@Component({
  name: 'avatar-upload-form',
  components: {
    UploadForm,
  },
})
export default class AvatarUploadForm extends Vue {
  @Prop({ default: null })
  currentAvatar

  $refs: {
    uploadForm: UploadForm
  }

  avatarUrl(id: string) {
    if (id !== '' && id !== null) {
      return this.$api.getAttachmentUrl(id)
    } else {
      return '/img/empty_avatar.png'
    }
  }

  remove() {
    // remvove avatar
    this.$emit('remove')
  }

  get avatar() {
    if (this.$refs.uploadForm !== undefined) {
      const files = this.$refs.uploadForm.uploadedFiles
      if (files.length > 0) {
        return files[0].response.id
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

import VueI18n from 'vue-i18n'
import { insertPropsInObject } from '@/util/util'

export enum FormFieldType {
  INPUT = 'input',
  CHECKBOX = 'checkbox-field',
  CHECKBOX_WITH_BLOCK = 'checkbox-with-block',
  SELECTION = 'selection',
  IP_FIELD = 'ip-field',
  NUMBER_FIELD = 'number-field',
  ORDERING_FIELD = 'ordering-field',
  DATE_FIELD = 'date-field',
  LABEL = 'label-field',
  IMAGE_UPLOAD = 'image-upload-field',
  IMAGE_CROP = 'image-crop-field',
  MARKDOWN_FIELD = 'markdown-field',
  JSON_FIELD = 'json-field',
  COLOR_FIELD = 'color-field',
  ROTATION_FIELD = 'rotation-field',
  PROFILES_SELECT = 'profiles-select-field',
  RELATED_MODEL_SELECT = 'related-model-select-field',
  BRELAG_NETMASK_FIELD = 'brelag-netmask-field',
  BRELAG_OFFER_SELECT = 'brelag-offer-select-field',
  EQUIPMENT_CONFIG_EDITOR = 'equipment-config-editor-field',
  MAXFLEX_EDITOR = 'maxflex-field',
  EGATE_FIELD = 'egate-field',
  SWW_EDITOR = 'sww-field',
}

interface CustomFieldType {
  // This is the type that the user can define
  type: string
  // This is the actual type that the custom type needs to be replaced with
  realType: string
  validators?: any
}

const IpCustomFieldType: CustomFieldType = {
  type: FormFieldType.IP_FIELD,
  realType: 'input-with-field',
  validators: {
    length: {
      expression(field, model, next) {
        next(
          /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(
            model[field.key]
          )
        )
      },
      message: 'Invalid IP',
    },
  },
}

// TODO: Do we actually need this realType ?
const ProfilesSelectFieldType: CustomFieldType = {
  type: FormFieldType.PROFILES_SELECT,
  realType: 'profiles-select-field',
}

const BrelagNetmaskFieldType: CustomFieldType = {
  type: FormFieldType.BRELAG_NETMASK_FIELD,
  realType: 'brelag-netmask-field',
}
const BrelagOfferSelectFieldType: CustomFieldType = {
  type: FormFieldType.BRELAG_OFFER_SELECT,
  realType: 'brelag-offer-select-field',
}
const EquipmentConfigEditorFieldType: CustomFieldType = {
  type: FormFieldType.EQUIPMENT_CONFIG_EDITOR,
  realType: 'equipment-config-editor-field',
}
const MaxFlexFieldType: CustomFieldType = {
  type: FormFieldType.MAXFLEX_EDITOR,
  realType: 'maxflex-field',
}
const SwwFieldType: CustomFieldType = {
  type: FormFieldType.SWW_EDITOR,
  realType: 'sww-field',
}
const EgateFieldType: CustomFieldType = {
  type: FormFieldType.EGATE_FIELD,
  realType: 'egate-field',
}

const DateFieldType: CustomFieldType = {
  type: FormFieldType.DATE_FIELD,
  realType: 'date-field',
}

const NumberFieldType: CustomFieldType = {
  type: FormFieldType.NUMBER_FIELD,
  realType: 'number-field',
}

const OrderingFieldType: CustomFieldType = {
  type: FormFieldType.ORDERING_FIELD,
  realType: 'ordering-field',
}

const RelatedModelSelectFieldType: CustomFieldType = {
  type: FormFieldType.RELATED_MODEL_SELECT,
  realType: 'related-model-select-field',
}

const ImageUploadFieldType: CustomFieldType = {
  type: FormFieldType.IMAGE_UPLOAD,
  realType: 'image-upload-field',
}

const ImageCropFieldType: CustomFieldType = {
  type: FormFieldType.IMAGE_CROP,
  realType: 'image-crop-field',
}

const MarkdownFieldType: CustomFieldType = {
  type: FormFieldType.MARKDOWN_FIELD,
  realType: 'markdown-field',
}

const JsonFieldType: CustomFieldType = {
  type: FormFieldType.JSON_FIELD,
  realType: 'json-field',
}

const ColorFieldType: CustomFieldType = {
  type: FormFieldType.COLOR_FIELD,
  realType: 'color-field',
}

const RotationFieldType: CustomFieldType = {
  type: FormFieldType.ROTATION_FIELD,
  realType: 'rotation-field',
}

const CheckboxFieldType: CustomFieldType = {
  type: FormFieldType.CHECKBOX,
  realType: 'checkbox-field',
}

const FormFieldMap = {
  [FormFieldType.INPUT]: 'input-with-field',
  [FormFieldType.CHECKBOX]: CheckboxFieldType,
  [FormFieldType.CHECKBOX_WITH_BLOCK]: 'checkbox-with-block',
  [FormFieldType.SELECTION]: 'select-with-field',
  [FormFieldType.IP_FIELD]: IpCustomFieldType,
  [FormFieldType.LABEL]: 'label-field',
  [FormFieldType.NUMBER_FIELD]: NumberFieldType,
  [FormFieldType.ORDERING_FIELD]: OrderingFieldType,
  [FormFieldType.DATE_FIELD]: DateFieldType,
  [FormFieldType.MARKDOWN_FIELD]: MarkdownFieldType,
  [FormFieldType.JSON_FIELD]: JsonFieldType,
  [FormFieldType.COLOR_FIELD]: ColorFieldType,
  [FormFieldType.ROTATION_FIELD]: RotationFieldType,
  [FormFieldType.PROFILES_SELECT]: ProfilesSelectFieldType,
  [FormFieldType.RELATED_MODEL_SELECT]: RelatedModelSelectFieldType,
  [FormFieldType.IMAGE_UPLOAD]: ImageUploadFieldType,
  [FormFieldType.IMAGE_CROP]: ImageCropFieldType,
  [FormFieldType.BRELAG_NETMASK_FIELD]: BrelagNetmaskFieldType,
  [FormFieldType.BRELAG_OFFER_SELECT]: BrelagOfferSelectFieldType,
  [FormFieldType.MAXFLEX_EDITOR]: MaxFlexFieldType,
  [FormFieldType.SWW_EDITOR]: SwwFieldType,
  [FormFieldType.EGATE_FIELD]: EgateFieldType,
  [FormFieldType.EQUIPMENT_CONFIG_EDITOR]: EquipmentConfigEditorFieldType,
}

export const FormFieldQueryBuilderMap = {
  [FormFieldType.PROFILES_SELECT]: (context: { [key: string]: string }) => {
    const query = {}
    if (context.organisation) {
      query['profile~organisation'] = context.organisation
    }
    if (context.group) {
      query['member~group'] = context.group
    }
    return query
  },
}

export interface FormField {
  key: string
  type: FormFieldType
  required?: boolean
  editable?: boolean
  properties?: {
    label?: string
    placeholder?: string
    class?: string
    icon?: string
    message?: string
    options?: string[]
    text?: string
    inputType?: string
    step?: number
    maxlength?: number
    extraData?: {
      dataID: number
      externalName: string
      internalName: string
      dependencyKey: string
    }
  }
  validators?: any
  templateOptions?: any
  display?: string //(field: any, model: any) => boolean
}

interface Tab {
  name: string
  icon?: string
  fields?: FormField[]
}

export interface FormBuilderConfig {
  title?: string
  tabs?: Tab[]
  fields?: FormField[]
  required?: boolean
  model: {
    [key: string]: any
  }
}

export class FormBuilderHelper {
  private _config: FormBuilderConfig
  private _model: object = {} // The model
  private _i18n: VueI18n

  public get config() {
    return this._config
  }

  public get model() {
    return this._model
  }

  public static getCommonFields(fieldLists: Array<FormField[]>) {
    const commonFields: FormField[] = fieldLists[0]
    const unmatchedFields: FormField[] = []
    for (let i = 1; i < fieldLists.length; i++) {
      for (const field of fieldLists[i]) {
        checkFields(fieldLists[i], commonFields, field)
      }
      for (const commonField of commonFields) {
        checkFields(commonFields, fieldLists[i], commonField)
      }
    }

    function checkFields(
      source: FormField[],
      target: FormField[],
      commonField: FormField
    ) {
      if (
        source.findIndex(
          (field) =>
            field.key === commonField.key && field.type === commonField.type
        ) > -1
      ) {
        if (
          target.findIndex(
            (field) =>
              field.key === commonField.key && field.type === commonField.type
          ) > -1
        ) {
          // all good
        } else {
          const ind = commonFields.findIndex(
            (field) =>
              field.key === commonField.key && field.type === commonField.type
          )
          commonFields.splice(ind, 1)
          if (
            unmatchedFields.findIndex(
              (field) =>
                field.key === commonField.key && field.type === commonField.type
            ) === -1
          ) {
            unmatchedFields.push(commonField)
          }
        }
      }
    }

    return {
      commonFields: commonFields,
      unmatchedFields: unmatchedFields,
    }
  }

  public static getFieldDictFromConfig(config: FormBuilderConfig): {
    [key: string]: FormField
  } {
    const fields = this.getFieldsFromConfig(config)
    const out = {}
    for (const field of fields) {
      out[field.key] = field
    }
    return out
  }

  public static getFieldsFromConfig(config: FormBuilderConfig): FormField[] {
    const fields: FormField[] = []
    if (config?.tabs) {
      for (const tab of config.tabs) {
        if (tab.fields) {
          for (const field of tab.fields) {
            fields.push(field)
          }
        }
      }
    }
    if (config?.fields) {
      for (const field of config.fields) {
        fields.push(field)
      }
    }
    return fields
  }

  public static buildErrorForm(exception, i18n: VueI18n) {
    // TODO: Create a nicer error-field
    return new FormBuilderHelper(
      {
        title: 'Error',
        fields: [
          {
            key: 'error',
            type: FormFieldType.LABEL,
            properties: {
              text: exception.toString(),
              label: 'Error Building Form',
            },
          },
        ],
        model: {},
      },
      i18n
    )
  }

  constructor(config: FormBuilderConfig, i18n: VueI18n) {
    this._i18n = i18n
    if (config) {
      this.loadConfig(config, this._i18n)
    } else {
      this.loadConfig(
        {
          model: {},
        },
        this._i18n
      )
    }
    return this
  }

  public loadConfig(config: FormBuilderConfig, i18n: VueI18n) {
    this._i18n = i18n
    try {
      const newConfig: FormBuilderConfig = JSON.parse(JSON.stringify(config))

      // Check for custom pre-defined fields
      if (newConfig.tabs) {
        newConfig.tabs.forEach((tab) => {
          this.parseFields(tab.fields, config.model)
        })
      } else if (newConfig.fields) {
        this.parseFields(newConfig.fields, config.model)
      }
      this._model = config.model
      this._config = newConfig
    } catch (err) {
      const errorForm = FormBuilderHelper.buildErrorForm(err, i18n)
      this._model = errorForm.model
      this._config = errorForm.config
    }
  }

  /* eslint-disable */
  private parseFields(fields: FormField[], model: any) {
    fields.forEach((field) => {
      // If Custom Field Type, do appriopriate transformation
      if (
        FormFieldMap[field.type] &&
        (FormFieldMap[field.type] as CustomFieldType).realType !== undefined
      ) {
        const customField: CustomFieldType = FormFieldMap[
          field.type
        ] as CustomFieldType
        insertPropsInObject(field, {
          type: customField.realType,
          validators: customField.validators,
        })
      } else if (FormFieldMap[field.type]) {
        // It is a standard field type
        insertPropsInObject(field, {
          type: FormFieldMap[field.type],
        })
      } else {
        throw new Error(`Unknown field type: ${field.type}`)
      }

      if (field.type === FormFieldType.SELECTION) {
        insertPropsInObject(field, {
          templateOptions: {
            options: field.properties.options,
          },
        })
      }
      if (field.type === FormFieldType.LABEL) {
        // No need to add anything to the model
        delete model[field.key]
      }
      if (field.type === FormFieldMap[FormFieldType.INPUT]) {
        if (field.properties && field.properties.inputType) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                type: field.properties.inputType,
              },
            },
          })
        }
        if (field.properties && field.properties.step) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                step: field.properties.step,
              },
            },
          })
        }
        if (field.properties && field.properties.maxlength) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                maxlength: field.properties.maxlength,
              },
            },
          })
        }
      }

      if (field.display) {
        insertPropsInObject(field, {
          ...{
            display: field.display,
          },
        })
      }

      // For certain properties, transform field
      if (field.properties) {
        insertPropsInObject(field, {
          ...field.properties,
        })

        if (field.properties.label) {
          let label = this._i18n.t(field.properties.label)
          if (label === '') {
            label = field.properties.label
          }
          insertPropsInObject(field, {
            templateOptions: {
              wrapper: {
                properties: {
                  addons: false,
                  label: `${label}${field.required ? ' *' : ''}`,
                },
              },
            },
          })
        }

        if (field.properties.message) {
          insertPropsInObject(field, {
            templateOptions: {
              wrapper: {
                properties: {
                  message: field.properties.message,
                },
              },
            },
          })
        }

        if (field.properties.class) {
          insertPropsInObject(field, {
            templateOptions: {
              wrapper: {
                properties: {
                  class: field.properties.class,
                },
              },
            },
          })
        }

        if (field.properties.placeholder) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                placeholder: this._i18n.t(field.properties.placeholder),
              },
            },
          })
        }

        if (field.properties.icon) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                icon: `mdi ${field.properties.icon}`,
              },
            },
          })
        }

        if (field.properties.options !== undefined) {
          insertPropsInObject(field, {
            templateOptions: {
              options: field.properties.options,
            },
          })
        }

        if ((model.id && model.id !== '0') || model.id === undefined) {
          if (field.editable !== undefined && field.editable === false) {
            insertPropsInObject(field, {
              templateOptions: {
                properties: {
                  disabled: true,
                },
              },
            })
          }
        }
      }
    })
  }
}

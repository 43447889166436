
import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue'
import { getLocationPreservingState } from '@/apps/routingUtils'

import { brelagSupporterRouteName } from '@/apps/brelag/brelag-supporter/app'

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-chart-pie',
        text: 'Dashboard',
        location: getLocationPreservingState(
          brelagSupporterRouteName('dashboard'),
          this.$router
        ),
      },
      {
        iconName: 'mdi-account-group',
        text: this.$i18n.tc('apps.brelag.mandator', 2),
        location: { name: brelagSupporterRouteName('mandator-root') },
      },
      {
        iconName: 'mdi-folder-multiple',
        text: this.$i18n.tc('apps.brelag.project', 2),
        location: { name: brelagSupporterRouteName('project-root') },
      },
      {
        iconName: 'mdi-import',
        text: this.$i18n.tc('apps.brelag.projectImport', 2),
        location: { name: brelagSupporterRouteName('legacy-project-import') },
      },
      {
        iconName: 'mdi-key',
        text: this.$i18n.tc('apps.brelag.knockautLicenseKey', 2),
        location: {
          name: brelagSupporterRouteName('knockaut-license-key-root'),
        },
      },
      {
        iconName: 'mdi-memory',
        text: this.$i18n.tc('apps.brelag.knockautDashboard', 2),
        location: { name: brelagSupporterRouteName('knockaut-dashboard') },
      },
      {
        iconName: 'mdi-auto-fix',
        text: this.$i18n.tc('apps.brelag.knockautXProgrammers', 2),
        location: { name: brelagSupporterRouteName('knockautx-programmers') },
      },
    ]
  }
}

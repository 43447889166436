import Vue from 'vue'
import moment from 'moment'
import { Collection, ApiClientV2, BASE_URL } from '@/api/ApiClientV2'
import { Customer } from '@/apps/brelag/common/models/customer'
import { Link } from '@/apps/brelag/common/models/link'
import { DeviceAssignment } from '@/apps/brelag/common/models/equipment'
import { AnnotationElement } from '@/apps/brelag/mandator-user/models/editor'
import { Building } from '@/apps/brelag/common/models/building'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
  CellType,
  CellEditType,
} from '@/models/core/base'
import { Organisation } from '@/models/core/organisation'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import { Profile } from '@/models/core/profile'
import { BackgroundTask } from '@/models/core/models'

export interface ProjectDump extends TransientBaseObject {
  organisation: TransientBaseObject
  id: string
  title: string
  commission: string
  status: number
  number: number
  customer: Customer
  device_assignments: DeviceAssignment[]
  meta: Project['meta']
  buildings: Building[]
  links_internal: {
    links: Link[]
  }
  status_modified_at: string
  status_modified_by: string
  editing_locked_at: null | string
  editing_unlocked_at: null | string
  editing_locked_by: string
  editing_unlocked_by: null | string
}

export const COUNTRY_MAP = {
  de: 'Deutschland',
  ch: 'Schweiz',
  au: 'Österreich',
}

export interface DeviceGroup {
  id: string
  name: string
  deviceIds: string[]
  links: {
    senderId: string
    channel: number
    options: {
      // For each equipment-id, save the options
      [key: string]: {
        [key: string]: any
      }
    }
  }[]
}

export interface LabelExportOptions {
  useLongForm: boolean
  printRemark: boolean
  printGNR?: boolean
  label: LabelType
  labelWidth?: number
  labelHeight?: number
  deviceLabelsPerPage?: 1 | 2
}

/**
 * two different labels are available
 * - 25.4 x 10 mm (width x height)
 * - 17.8 x 10 mm
 */
export enum LabelType {
  LABEL254 = 'label254',
  LABEL178 = 'label178',
  LABEL_PRINTER = 'labelPrinter',
}

export enum ProjectLockOrigin {
  STYLER = 'styler',
  WINDOWS_APP = 'windows_app',
  LEGACY_STYLER = 'online',
}

export enum ProjectStatus {
  ENTWURFSPLAN = 0,
  AUSFUEHRUNGSPLAN = 1,
}

export const ProjectStatusLabel = {
  [ProjectStatus.ENTWURFSPLAN]: 'ENTWURFSPLAN',
  [ProjectStatus.AUSFUEHRUNGSPLAN]: 'AUSFÜHRUNGSPLAN',
}

export class Project extends TransientBaseObject {
  organisation: string
  title: string
  commission?: string
  status: number
  customer: string
  number: number
  status_modified_at: string
  status_modified_by: string
  editing_locked_at: string
  editing_unlocked_at: string
  editing_locked_by: string
  editing_unlocked_by: string
  update_time: string
  automatic_unlock_deadline: string | null
  links_internal: {
    links: Link[]
  }
  meta?: {
    commission?: string
    country?: string
    zip?: string
    city?: string
    address?: string
    project_leader?: string
    supervisor?: string
    plan_drawer?: string
    construction_site?: string
    programmer?: string
    remarks?: string
    date?: string
    receiver_groups: DeviceGroup[]
    annotations: AnnotationElement[]
    label_export_options?: LabelExportOptions
  }

  static apiUrl = 'brelag/project'
  static langPath: string = 'apps.brelag.models.project'
  static objectType: string = 'project'
  static fields: ModelField[] = [
    {
      key: 'title',
      required: true,
      formProperties: {
        maxlength: 50,
      },
    },
    {
      key: 'commission',
      required: false,
      formProperties: {
        maxlength: 25,
      },
    },
    {
      key: 'meta.construction_site',
      required: false,
      formProperties: {
        maxlength: 25,
      },
    },
    {
      key: 'customer',
      required: true,
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        relatedObjectProperty: 'pretty_name',
        modelClass: Customer.objectType,
        filters: [Organisation.objectType],
      },
    },
    {
      key: 'status',
      required: true,
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        editable: true,
        options: [
          { text: 'Entwurfsplan', value: ProjectStatus.ENTWURFSPLAN },
          { text: 'Ausführungsplan', value: ProjectStatus.AUSFUEHRUNGSPLAN },
        ],
      },
    },
    {
      key: 'meta.date',
      required: false,
      formFieldType: FormFieldType.DATE_FIELD,
    },
    {
      key: 'meta.address',
      required: false,
      formProperties: {
        maxlength: 50,
      },
    },
    {
      key: 'meta.zip',
      required: false,
      formFieldType: FormFieldType.NUMBER_FIELD,
      formProperties: {
        inputType: 'number',
        step: 1,
        min: 0,
        max: 999999,
      },
    },
    {
      key: 'meta.city',
      required: false,
      formProperties: {
        maxlength: 50,
      },
    },
    {
      key: 'meta.country',
      formFieldType: FormFieldType.SELECTION,
      required: false,
      formProperties: {
        options: [
          { text: 'Schweiz', value: 'ch' },
          { text: 'Deutschland', value: 'de' },
          { text: 'Österreich', value: 'au' },
        ],
      },
    },
    {
      key: 'meta.project_leader',
      required: true,
      formProperties: {
        maxlength: 25,
      },
    },
    {
      key: 'meta.supervisor',
      required: false,
      formProperties: {
        maxlength: 25,
      },
    },
    {
      key: 'meta.programmer',
      required: false,
      formProperties: {
        maxlength: 25,
      },
    },
    {
      key: 'meta.plan_drawer',
      required: false,
      formProperties: {
        maxlength: 25,
      },
    },
    {
      key: 'meta.remarks',
      required: false,
      formProperties: {
        maxlength: 50,
      },
    },
    {
      key: 'meta.annotations',
      required: false,
      undotized: true,
      display: 'false',
    },
    {
      key: 'meta.receiver_groups',
      required: false,
      undotized: true,
      display: 'false',
    },
    {
      key: 'links_internal',
      required: false,
      undotized: true,
      display: 'false',
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'title', searchQuery: 'title_search', sortable: true },
    { key: 'commission', searchQuery: 'commission_search', sortable: true },
    {
      key: 'number',
      sortable: true,
      transform: (number: number) => {
        return Project.convertNumber(number)
      },
    },
    { key: 'customer_number', sortable: true },
    { key: 'customer_name', sortable: true },
    { key: 'meta.city' },
    {
      key: 'create_time',
      sortable: true,
      transform: (date: string) => {
        return moment(date).format('DD.MM.YYYY HH:mm')
      },
    },
    {
      key: 'update_time',
      sortable: true,
      transform: (date: string) => {
        return moment(date).format('DD.MM.YYYY HH:mm')
      },
    },
  ]
  static joins: Collection.RelatedAnnotation<Project>[] = [
    {
      relatedModelClass: Customer,
      relatedObjectProperty: 'name',
    },
    {
      relatedModelClass: Customer,
      relatedObjectProperty: 'number',
    },
  ]
  static annotations: Collection.Annotation<Project, string>[] = [
    {
      key: 'gnr_number',
      callback: (project: Project) => {
        const response = {
          id: project.id,
          annotations: {
            gnr_number: Project.convertNumber(project.number),
          },
        }
        return Promise.resolve(response)
      },
    },
    {
      key: 'locked_by_name',
      callback: async (project: Project, apiv2: ApiClientV2) => {
        const locked_by_name = await Profile.getFullName(
          apiv2,
          project.editing_locked_by
        )
        return {
          id: project.id,
          annotations: {
            locked_by_name,
          },
        }
      },
    },
    {
      key: 'unlocked_by_name',
      callback: async (project: Project, apiv2: ApiClientV2) => {
        const unlocked_by_name = await Profile.getFullName(
          apiv2,
          project.editing_unlocked_by
        )
        return {
          id: project.id,
          annotations: {
            unlocked_by_name,
          },
        }
      },
    },
  ]
  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static convertNumber(number: number): string {
    return `GNR${number.toString().padStart(6, '0')}`
  }

  static formConfig() {
    return {
      fields: Project.formFields(),
      model: Project.defaultModel,
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Project, Project>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(PROJECT_DEFAULT))
  }

  /**
   * Gets dump of project
   * @param apiClient
   * @param id
   */
  static async getDump(apiClient: ApiClientV2, id: string) {
    return apiClient.customGet(`brelag/project/${id}/dump?${Math.random()}`)
  }

  /**
   * Extends the lock on the project
   * @param apiClient
   * @param id id of project
   */
  static async keepLocked(
    apiClient: ApiClientV2,
    id: string
  ): Promise<Project> {
    try {
      const project = await apiClient.customPost(
        `${this.apiUrl}/${id}/keep-locked`
      )
      return project
    } catch (error) {
      // Maybe project was already unlocked, try locking it
      return Project.lock(apiClient, id)
    }
  }

  static lockedFromWindowsApp(project: Project): boolean {
    return (
      project.meta['locked_from'] === null ||
      project.meta['locked_from'] === ProjectLockOrigin.WINDOWS_APP.toString()
    )
  }

  static lockedFromStyler(project: Project): boolean {
    return (
      project.meta['locked_from'] ===
        ProjectLockOrigin.LEGACY_STYLER.toString() ||
      project.meta['locked_from'] === ProjectLockOrigin.STYLER.toString()
    )
  }

  /**
   * Locks a project for editing
   * @param vm
   * @param id id of Project
   */
  static async lock(apiClient: ApiClientV2, id: string): Promise<Project> {
    const project = await apiClient.get<Project>(Project, id)
    if (!project.meta) {
      project.meta = {} as any
    }
    // check if project locked in windows app
    if (project.editing_locked_by && this.lockedFromWindowsApp(project)) {
      throw new Error('Projekt ist in Windows App gesperrt')
    }
    return await apiClient.customPost(`brelag/project/${id}/lock`, {
      locked_from: ProjectLockOrigin.STYLER.toString(),
    })
  }

  /**
   * Unlocks a project from editing
   * @param vm
   * @param id id of Project
   */
  static async unlock(
    apiClient: ApiClientV2,
    id: string,
    unlock_override = false
  ): Promise<Project> {
    const project = await apiClient.get<Project>(Project, id)
    if (project.editing_locked_by === null) {
      return
    }
    if (!unlock_override && this.lockedFromWindowsApp(project)) {
      throw new Error('Kann nicht entsperren, da von Windows App gesperrt.')
    }
    return await apiClient.customPost(`brelag/project/${id}/unlock`, {
      locked_from: ProjectLockOrigin.STYLER.toString(),
    })
  }

  /**
   * Restores an old version of the project from a ProjectExport
   * @param apiClient
   * @param id ID of project
   * @param projectExport ID of Project Export
   */
  static async loadProjectExport(
    apiClient: ApiClientV2,
    id: string,
    projectExport: string
  ) {
    return apiClient.customPost(`brelag/project/${id}/load-project-export`, {
      project_export: projectExport,
    })
  }

  static async deleteProjectWithExports(apiClient: ApiClientV2, id: string) {
    return apiClient.customDelete(
      `brelag/project/${id}/?do_delete_exports=true`
    )
  }

  static async copyProject(
    apiClient: ApiClientV2,
    id: string,
    projectTitle: string,
    copyBuildings: boolean,
    copyFloors: boolean,
    copyDevices: boolean,
    copyLinks: boolean
  ): Promise<BackgroundTask> {
    return apiClient.customPost(`brelag/project/${id}/copy`, {
      id,
      project_title: projectTitle,
      copy_buildings: copyBuildings,
      copy_floors: copyFloors,
      copy_devices: copyDevices,
      copy_links: copyLinks,
    })
  }

  static async changeMandator(
    apiClient: ApiClientV2,
    id: string,
    mandator: string
  ) {
    return await apiClient.customPost(
      `brelag/project/${id}/transfer-project-organisation`,
      {
        organisation: mandator,
      }
    )
  }

  static stripNumberPrefix(
    number: string | null,
    stripLeadingZeros = true
  ): string | null {
    // In the frontend we show the number x with the 'GNR000x' prefix -> Strip the GNR prefix
    // If 'stripLeadingZeros' is true: Strips leading zeros: 'GNR00012' -> '12'
    if (number && typeof number === 'string') {
      let newValue = number
        .replace('G', '')
        .replace('N', '')
        .replace('R', '')
        .replace('g', '')
        .replace('n', '')
        .replace('r', '')
      if (stripLeadingZeros) {
        newValue = newValue.replace(/^0+/, '')
      }
      return newValue
    } else {
      return null
    }
  }

  static isProjectLocked(project: Project): boolean {
    return project.editing_locked_by !== null
  }

  /**
   * Checks if the project is locked by given user (does not consider Project Lock Origin)
   */
  static isProjectLockedByUser(project: Project, profileId: string): boolean {
    if (!this.isProjectLocked(project)) {
      // Project is not locked
      return false
    }
    if (project.editing_locked_by !== profileId) {
      // Project is locked by different user
      return false
    }
    // Project is locked by current user
    return true
  }

  /**
   * Checks if the project is locked by given user from Styler
   */
  static isProjectLockedByStylerUser(
    project: Project,
    profileId: string
  ): boolean {
    return (
      this.isProjectLockedByUser(project, profileId) &&
      this.lockedFromStyler(project)
    )
  }
}

export const PROJECT_DEFAULT: Project = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  title: '',
  commission: '',
  status: 0,
  customer: '',
  number: -1,
  status_modified_at: null,
  status_modified_by: null,
  editing_locked_at: null,
  editing_locked_by: null,
  editing_unlocked_at: null,
  editing_unlocked_by: null,
  update_time: null,
  automatic_unlock_deadline: null,
  meta: {
    date: null,
    country: '',
    zip: '',
    city: '',
    address: '',
    project_leader: '',
    supervisor: '',
    plan_drawer: '',
    construction_site: '',
    programmer: '',
    remarks: '',
    receiver_groups: [],
    annotations: [],
  },
  links_internal: {
    links: [],
  },
}

export class ProjectExport extends TransientBaseObject {
  project: string
  version?: string
  description?: string
  project_status_label: string
  is_auto_backup: boolean

  static apiUrl = 'brelag/project-export'
  static langPath: string = 'apps.brelag.models.projectExport'
  static objectType: string = 'project-export'

  static canDelete(obj: any = null): boolean {
    // only enable delete if permission given
    if (obj && obj._permissions) {
      return obj._permissions.delete
    } else {
      return false
    }
  }

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: ProjectExport.formFields(),
      model: { ...PROJECT_EXPORT_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(vm: Vue, filter, pagination) {
    return vm.$apiv2.subscribe<ProjectExport, ProjectExport>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(PROJECT_EXPORT_DEFAULT))
  }

  static listFields: ListModelField[] = [
    {
      key: 'description',
      cellType: CellType.EDITABLE_CELL,
      editProperties: { type: CellEditType.STRING, width: 500, maxlength: 110 },
    },
    { key: 'project_status_label' },
    { key: 'version' },
    {
      key: 'create_time',
      transform: (date: string) => {
        return moment(date).format('DD.MM.YYYY HH:mm')
      },
    },
  ]

  static async download(apiClient: ApiClientV2, id: string) {
    window.open(BASE_URL + `brelag/project-export/${id}/download-file`)
  }

  static async upload(apiClient: ApiClientV2, id: string, file: File) {
    const formData = new FormData()
    formData.append('project', id)
    formData.append('file', file)
    await apiClient.customPost(
      `brelag/project-export/upload-project-export`,
      formData
    )
  }
}

export const PROJECT_EXPORT_DEFAULT: ProjectExport = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  project: '',
  project_status_label: '',
  is_auto_backup: false,
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { Profile } from '@/models/core/profile'

@Component({
  components: {},
})
export default class ProfileRegistration extends Vue {
  $refs: {
    form: HTMLFormElement
  }
  errorMessage = ''
  user = {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
  }

  mounted() {
    if (
      this.$store.state.global.isLoggedIn ||
      !this.$store.getters['global/registrationEnabled']
    ) {
      this.redirectUser()
    }
  }

  redirectUser() {
    let url = '/'
    if (this.$router.currentRoute.query.afterLoginUrl) {
      url = this.$router.currentRoute.query.afterLoginUrl as string
    }
    this.$router.push({ path: url })
  }

  submit() {
    this.register()
  }

  onVerify(event) {
    this.register()
  }

  async register() {
    if (this.$refs.form.checkValidity()) {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      try {
        await this.$apiv2.create(Profile, this.user)
        this.$buefy.toast.open({
          message: this.$tc('models.auth.successfulRegistration'),
          type: 'is-success',
        })
        this.$routerHandler.push({
          name: 'activate_profile',
          query: {
            username: this.user.username,
            email: this.user.email,
          },
        })
      } catch (error) {
        this.errorMessage = this.$errorHandler.errorToString(error)
      }
      loadingComponent.close()
    }
  }
}

export default {
  mandator: 'Mandant | Mandanten',
  admin: 'Admin | Admins',
  supporter: 'Supporter | Supporter',
  equipment: 'Equipment | Equipment',
  equipmentgroup: 'Equipment Gruppe | Equipment Gruppen',
  offer: 'Angebot | Angebote',
  album: 'Album | Alben',
  project: 'Projekt | Projekte',
  customer: 'Kunde | Kunden',
  user: 'Benutzer | Benutzer',
  webshop: 'Webshop | Webshop',
  order: 'Bestellung | Bestellungen',
  phone: 'Telefon',
  email: 'E-Mail',
  currency: 'Währung',
  floorPlan: 'Bauplan',
  projectEditor: 'Projekt Editor',
  projectImport: 'Projekt Import',
  knockautDashboard: 'KNOCKAUT',
  knockautXProgrammers: 'KnockautX Programmieren',
  knockautLicenseKey: 'Knockaut Lizenz',
  knockautModule: 'Knockaut Modul | Knockaut Module',
  building: 'Gebäude',
  floor: 'Stockwerk | Stockwerke',
  editor: {
    annotations: {
      text: {
        fields: {
          text: 'Text',
          color: 'Farbe',
          bgColor: 'Hintergrundfarbe',
          borderColor: 'Rahmenfarbe',
          size: 'Grösse',
          rotation: 'Rotation [°]',
        },
      },
      device_arrow: {
        fields: {
          orientation: 'Pfeil Richtung',
          size: 'Länge',
          fromText: 'Von:',
          toText: 'Bis:',
        },
      },
      compass: {
        fields: {
          rotation: 'Rotation [°]',
          size: 'Grösse',
        },
      },
      help_line: {
        fields: {
          orientation: 'Orientation',
        },
        placeholders: {
          orientation: 'Orientation',
        },
      },
    },
  },
  components: {
    mandator: {
      admin_user_group: 'Admin Benutzergruppe',
    },
    fields: {
      category: {
        label: 'Kategorie',
        placeholder: 'Wähle Kategorie',
      },
      price: {
        label: 'Preis',
        placeholder: '3.95',
      },
    },
  },
  models: {
    knockaut: {
      knockautproject: {
        prettyName: 'KNOCKAUT FusionONE | KNOCKAUT FusionONE',
        fields: {
          title: 'Titel',
          project_title: 'Projekt',
          meta: 'Meta',
          remote_access_link: 'Fern-Zugangslink',
          fusion_one: 'FusionONE',
          api_key: 'API Key',
          username: 'Benutzername',
          password: 'Passwort',
          symos_password: 'FusionOS Passwort',
          license_key: 'Lizenz Key',
          fusion_one_device_number: 'FusionONE Nummer',
        },
        placeholders: {
          title: '',
          project_title: '',
          meta: '',
          remote_access_link: '',
          fusion_one: '',
          api_key: '',
          username: '',
          password: '',
          symos_password: '',
          license_key: '',
          fusion_one_device_number: '',
        },
      },
      'knockaut-backup': {
        prettyName: 'KNOCKAUT Backup',
        fields: {
          name: 'Name',
          project: 'Projekt',
          backup_id: 'Backup ID',
          backup_created_at: 'Erstellt am',
          size: 'Grösse',
          backup_type: 'Typ',
          file: 'Datei',
        },
      },
    },
    brelagGroupMembership: {
      prettyName: 'Gruppenmitgliedschaft | Gruppenmitgliedschaften',
      fields: {
        group_name: 'Gruppe',
        profile_username: 'Benutzername',
        profile_email: 'E-Mail',
        profile_first_name: 'Vorname',
        profile_last_name: 'Nachname',
      },
    },
    mandator: {
      prettyName: 'Mandant | Mandanten',
      fields: {
        name: 'Name',
        avatar: 'Logo',
        avatar_img: 'Logo',
        password_policy: {
          version: 'Version',
          min_length: 'Min Length',
          alpha_upper: 'Alpha Upper',
          alpha_lower: 'Alpha Lower',
          numeric: 'Numeric',
          special_char: 'Special Char',
        },
        meta: {
          email: 'E-Mail',
          phone: 'Telefon',
          country: 'Land',
          zip: 'PLZ',
          city: 'Ort',
          address: 'Adresse',
          customerNumber: 'Kundennummer',
          coins: 'Coins',
        },
      },
      placeholders: {
        name: 'Name',
        avatar: 'Logo',
        password_policy: {
          version: 'Version',
          min_length: 'Min Length',
          alpha_upper: 'Alpha Upper',
          alpha_lower: 'Alpha Lower',
          numeric: 'Numeric',
          special_char: 'Special Char',
        },
        meta: {
          email: 'E-Mail',
          phone: 'Telefon',
          country: '',
          zip: '',
          city: '',
          address: '',
          customerNumber: '',
        },
      },
    },
    supporter: {
      prettyName: 'Supporter | Supporter',
      fields: {
        username: 'Benutzername',
        email: 'E-Mail',
        first_name: 'Vorname',
        last_name: 'Nachname',
        organisation: 'Organisation',
        avatar: 'Avatar',
        avatar_img: 'Avatar',
        require_vpn_2fa: 'Require VPN 2FA',
        kind: 'Typ',
        is_activated: 'Aktiviert',
        is_active: 'Aktiv',
        is_activated_and_active: 'Aktiviert',
        meta: {
          phone: 'Telefon',
          gender: 'Anrede',
        },
      },
      placeholders: {
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        organisation: '',
        avatar: '',
        avatar_img: '',
        require_vpn_2fa: '',
        kind: '',
        is_activated: '',
        is_active: '',
        meta: {
          phone: '',
          gender: '',
        },
      },
    },
    project: {
      prettyName: 'Projekt | Projekte',
      fields: {
        organisation: 'Organisation',
        title: 'Projektname',
        commission: 'Kommission',
        status: 'Status',
        customer: 'Kunde',
        number: 'Globalnummer',
        gnr_number: 'Globalnummer',
        customer_number: 'Kd.-Nr.',
        customer_name: 'Kunde',
        editing_locked_by: 'Gesperrt von',
        locked_by_name: 'Gesperrt von',
        create_time: 'Erfassungsdatum',
        update_time: 'Letztes Update',
        meta: {
          commission: 'Kommission',
          description: 'Beschreibung',
          address: 'Adresse',
          zip: 'PLZ',
          city: 'Ort',
          country: 'Land',
          project_leader: 'Projektleiter',
          supervisor: 'Supervisor',
          construction_site: 'Baufeld',
          plan_drawer: 'Planzeichner',
          programmer: 'Programmierer',
          remarks: 'Bemerkungen',
          receiver_groups: 'Empfängergruppen',
          annotations: 'Annotationen',
        },
      },
      placeholders: {
        organisation: 'Organisation',
        title: 'Projektname',
        commission: 'Kommission',
        status: 0,
        description: 'Beschreibung',
        customer: '',
        number: '',
        customer_number: '',
        customer_name: '',
        meta: {
          commission: 'Kommission',
          address: 'Adresse',
          zip: 'PLZ',
          city: 'Ort',
          country: 'Land',
          project_leader: 'Projektleiter',
          supervisor: 'Supervisor',
          construction_site: 'Baufeld',
          plan_drawer: 'Planzeichner',
          programmer: 'Programmierer',
          remarks: 'Bemerkungen',
          receiver_groups: 'Empfängergruppen',
          annotations: 'Annotationen',
        },
      },
    },
    customer: {
      prettyName: 'Kunde | Kunden',
      fields: {
        organisation: 'Organisation',
        name: 'Name',
        number: 'Kundennummer',
        meta: {
          address: 'Adresse',
          zip: 'PLZ',
          city: 'Ort',
          country: 'Land',
          contact_person: 'Kontakt',
          email: 'E-Mail',
          tel_central: 'Tel Zentrale',
          tel_mobile: 'Tel Mobil',
          tel_direct: 'Tel Direkt',
        },
      },
      placeholders: {
        organisation: 'Organisation',
        name: 'Name',
        number: 'Kundennummer',
        meta: {
          address: 'Adresse',
          zip: 'PLZ',
          city: 'Ort',
          country: 'Land',
          contact_person: 'Kontakt',
          email: 'E-Mail',
          tel_central: 'Tel Zentrale',
          tel_mobile: 'Tel Mobil',
          tel_direct: 'Tel Direkt',
        },
      },
    },
    projectExport: {
      prettyName: 'Projekt Export | Projekt Exporte',
      fields: {
        version: 'Version',
        description: 'Beschreibung',
        create_time: 'Datum',
        project_status_label: 'Planart',
      },
      placeholders: {
        version: '',
        description: '',
        create_time: '',
        project_status_label: '',
      },
    },
    building: {
      prettyName: 'Gebäude | Gebäude',
      fields: {
        project: 'Projekt',
        project_title: 'Projekt',
        title: 'Titel',
        prefix: 'Prefix',
        ordering: 'Reihenfolge',
      },
      placeholders: {
        project: '',
        project_title: '',
        title: '',
        prefix: '',
        ordering: '',
      },
    },
    floor: {
      prettyName: 'Stockwerk | Stockwerke',
      fields: {
        building: 'Gebäude',
        building_title: 'Gebäude',
        processed_file: 'Bearbeitetes Bild',
        original_file: 'Original Bild',
        title: 'Title',
        ordering: 'Reihenfolge',
        prefix: 'Prefix',
      },
      placeholders: {
        building: '',
        building_title: '',
        processed_file: '',
        original_file: '',
        title: '',
        ordering: '',
        prefix: '',
      },
    },
    equipmentvariant: {
      prettyName: 'Equipment Variante | Equipment Varianten',
      fields: {
        equipment: 'Equipment',
        equipment_device_type_name: 'Equipment',
        device_variant_name: 'Device Variante Name',
        short_form: 'Kurzform',
        article_number: 'Art. Nummer',
        ordering: 'Reihenfolge',
        active: 'Aktiv',
        image: 'Icon',
        image_img: 'Icon',
        hidden: 'Versteckt',
      },
      placeholders: {
        equipment: '',
        equipment_device_type_name: '',
        device_variant_name: '',
        short_form: '',
        article_number: '',
        ordering: '',
      },
    },
    equipmentgroup: {
      prettyName: 'Equipment Gruppe | Equipment Gruppen',
      fields: {
        organisation: 'Organisation',
        name: 'Name',
        ordering: 'Reihenfolge',
        hidden: 'Versteckt',
      },
      placeholders: {
        organisation: '',
        name: '',
        ordering: '',
      },
    },
    equipment: {
      prettyName: 'Equipment | Equipment',
      fields: {
        equipment_group: 'Equipment Gruppe',
        'equipment-group_name': 'Equipment Gruppe',
        model: 'Model',
        category: 'Kategorie',
        device_type_name: 'Name',
        tx_capacity: 'TX Kapazität',
        has_sensors: 'Hat Sensorik',
        is_virtual: 'Ist virtueller Sender',
        channel_count: 'Anz. Kanäle',
        ordering: 'Reihenfolge',
        config_editor_layout: 'Config Editor Layout',
        hidden: 'Versteckt',
      },
      placeholders: {
        equipment_group: '',
        model: '',
        category: '',
        device_type_name: '',
        tx_capacity: '',
        has_sensors: '',
        is_virtual: '',
        channel_count: '',
        ordering: '',
        config_editor_layout: '{}',
      },
    },
    instructionstep: {
      prettyName: 'Anweisungsschritt | Anweisungsschritte',
      fields: {
        category: 'Typ',
        image: 'Bild',
        image_img: 'Bild',
        title: 'Titel',
        description: 'Beschreibung',
        ordering: 'Reihenfolge',
        equipment: 'Equipment',
      },
      placeholders: {
        category: '',
        image: '',
        title: '',
        description: '',
        ordering: '',
        equipment: '',
      },
    },
    'knockaut-module': {
      prettyName: 'Knockaut Modul',
      fields: {
        name: 'Name',
        author: 'Author',
        description: 'Beschreibung',
        version: 'Version',
        price: 'Preis',
        guid: 'Library GUID',
        module_guid: 'Modul GUID',
        logo: 'Logo',
        logo_img: 'Logo',
        github_url: 'GitHub Repository URL',
        is_subscription: 'Ist Abo',
        is_internal: 'Internes Modul',
        needs_instance: 'Braucht Instanz',
      },
      placeholders: {
        name: '',
        author: '(z.B. Brelag Schweiz AG)',
        description: '',
        version: '',
        guid: 'Die GUID aus dem library.json file des Repositories',
        module_guid: '',
        github_url: '',
        is_subscription: '',
        is_internal: '',
        needs_instance: '',
      },
    },
    'knockaut-license-key': {
      prettyName: 'Knockaut Lizenz',
      fields: {
        validity: 'Gültigkeit',
        assigned_at: 'Zugewiesen am',
        license_key: 'Lizenz',
        project: 'Projekt',
        assigned_by: 'Zugewiesen von',
        project_username: 'Lizenz E-Mail',
      },
      placeholders: {
        validity: '',
        assigned_at: '',
        license_key: '',
        project: '',
        assigned_by: '',
      },
    },
  },
}

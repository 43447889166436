export default {
  firstName: 'Vorname',
  lastName: 'Nachname',
  username: 'Benutzername',
  password: 'Passwort',
  passwordRepeat: 'Passwort (wiederholen)',
  passwordNoMatch: 'Passwörter stimmen nicht überein.',
  changePassword: 'Passwort ändern',
  oldPassword: 'Altes Passwort',
  newPassword: 'Neues Passwort',
  repeatPassword: 'Neues Passwort (wiederholen)',
  forgotPassword: 'Passwort vergessen?',
  activateAccount: 'Aktiviere Account',
  accountActivation: 'Account Aktivierung',
  emailPinInfo:
    'Eine E-Mail mit einem PIN wird an die E-Mail Adresse verschickt.',
  email: 'E-Mail',
  newEmail: 'Neue E-Mail',
  newEmailRepeat: 'Neue E-Mail (wiederholen)',
  changeEmail: 'E-Mail ändern',
  resetPassword: 'Passwort zurücksetzen',
  pinFromEmail: 'PIN (von E-Mail)',
  yes: 'Ja',
  no: 'Nein',
  registration: 'Registration',
  successfulRegistration: 'Erfolgreiche Registrierung',
}

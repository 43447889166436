import uuidv1 from 'uuid'

import {
  EditorModes,
  AnnotationElement,
  AnnotationCategory,
  removeSvgObjects,
  SvgObjectCollection,
  SvgObjectCollectionType,
  Orientation,
  HELP_LINE,
  SvgPosition,
} from '@/apps/brelag/mandator-user/models/editor'
import { FloorPlanEditorState } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditorState'
import { ProjectEditor } from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import {
  SenderArrangement,
  getSenderArrangements,
} from '@/apps/brelag/mandator-user/project-editor/arrangementUtil'
import {
  initView,
  resetView,
  zoomOut,
  zoomIn,
  move,
} from '@/apps/brelag/mandator-user/project-editor/floorPlanViewHelper'
import {
  drawDevice,
  drawAnnotation,
} from '@/apps/brelag/mandator-user/project-editor/floorPlanDrawHelper'
import {
  DeviceAssignment,
  EquipmentCategory,
} from '@/apps/brelag/common/models/equipment'
import { Floor } from '@/apps/brelag/common/models/floor'
import { Store } from 'vuex'
import { Subject, BehaviorSubject } from 'rxjs'
import { errorToString } from '@/api/ErrorHandler'
import { positionChanged } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditorUtil'
import { apiClientV2 } from '@/api/ApiClientV2'
import { simpleCopy } from '@/util/util'

declare const SVG: any

const MAX_ZOOM_LEVEL = 5
const MIN_ZOOM_LEVEL = 0.4

export interface Placement {
  cx: number
  cy: number
  t?: any // can be many things...
}

export class FloorPlanEditor {
  static instance: FloorPlanEditor
  private _state: FloorPlanEditorState
  private _store: Store<any> = null
  private projectEditor: ProjectEditor
  private _unplacedSenders: DeviceAssignment[] = []
  private _unplacedReceivers: DeviceAssignment[] = []
  private svgPt = null

  private senderArrangement: SenderArrangement[] = []

  public mouseClickStream: Subject<Placement> = new Subject()
  public errorStream: Subject<string> = new Subject()
  public loadingStream: Subject<boolean> = new BehaviorSubject(false)

  public constructor(store: Store<any>) {
    this.projectEditor = ProjectEditor.getInstance(store)
    this._store = store
    this._state = new FloorPlanEditorState()
  }

  public get mode() {
    return this._state.mode.mode
  }

  public get editorContainer() {
    return this._state.editorContainer
  }

  public get selectedAnnotationIds(): string[] {
    return this.projectEditor.selectedAnnotationIds
  }

  public get selectedSenderIds(): string[] {
    return this.projectEditor.selectedSenderIds
  }

  public get selectedReceiverIds(): string[] {
    return this.projectEditor.selectedReceiverIds
  }

  public get selectedDeviceIds(): string[] {
    return this.selectedSenderIds.concat(this.selectedReceiverIds)
  }

  public get selectedElementIds(): string[] {
    return this.selectedDeviceIds.concat(this.selectedAnnotationIds)
  }

  public get unplacedSenders() {
    return this._unplacedSenders
  }

  public get unplacedReceivers() {
    return this._unplacedReceivers
  }

  /**
   * Updates list of unplaced devices
   */
  public updateUnplaced() {
    const filterUnplaced = (devices: DeviceAssignment[]) => {
      return devices.filter((sender) => {
        if (sender.floor === this.projectEditor.selectedFloor.id) {
          // Virtual senders can not be placed
          if (this.projectEditor.equipmentHandler.isVirtualSender(sender)) {
            return false
          }
          if (!sender.meta) {
            return true
          } else {
            if (!sender.meta.svgObjectPositions) {
              return true
            }
            sender.meta.svgObjectPositions.every(
              (attribute) => attribute === undefined
            )
          }
        }
      })
    }
    this._unplacedSenders = filterUnplaced(this.projectEditor.senders)
    this._unplacedReceivers = filterUnplaced(this.projectEditor.receivers)
  }

  static getInstance(store: Store<any>) {
    if (!FloorPlanEditor.instance) {
      FloorPlanEditor.instance = new FloorPlanEditor(store)
    }
    return FloorPlanEditor.instance
  }

  public async initFloorPlan(svg: any) {
    this.projectEditor = ProjectEditor.getInstance(this._store)
    this._state.editorContainer.svg = svg
    // Create an SVGPoint for future math
    this.svgPt = svg.node.createSVGPoint()

    this.refreshSenderArrangement()

    await this.setMode(EditorModes.PAN_ZOOM)
    if (this.projectEditor.selectedFloor) {
      await this.setFloor(this.projectEditor.selectedFloor.id)
    } else if (this.projectEditor.floors.length > 0) {
      await this.setFloor(this.projectEditor.floors[0].id)
    }
  }

  refreshSenderArrangement() {
    this.senderArrangement = getSenderArrangements(this.projectEditor)
  }

  private handleError(error, loadingNext?: boolean, doThrow?: boolean) {
    this.errorStream.next(errorToString(error))
    if (loadingNext !== undefined) {
      this.loadingStream.next(loadingNext)
    }
    if (doThrow !== undefined) {
      throw error
    }
  }

  /**
   * Set floor. Loads all elements of that floor.
   * @param floor
   */
  public async setFloor(floor: string) {
    this.loadingStream.next(true)
    this.projectEditor = ProjectEditor.getInstance()
    this.projectEditor.selectFloor(floor)

    // Reset view, remove svg elements and load plan
    initView(this._state.editorContainer)
    await this.clearSelectedElements()

    this._state.elements.annotations.forEach((element) => {
      removeSvgObjects(element.svg)
    })
    this._state.elements.devices.forEach((element) => {
      removeSvgObjects(element.svg)
    })
    this._state.elements.annotations.clear()
    this._state.elements.devices.clear()

    if (this._state.editorContainer.projectPlanSvg !== null) {
      this._state.editorContainer.projectPlanSvg.remove()
    }

    const floorObj = await apiClientV2.get<Floor>(
      Floor,
      this.projectEditor.selectedFloor.id
    )

    if (floorObj.processed_file !== null) {
      // prevent caching
      const imageUrl = Floor.getProcessedDownloadUrl(
        this.projectEditor.selectedFloor.id
      )

      try {
        this._state.editorContainer.projectPlanSvg =
          this._state.editorContainer.svg.image(
            imageUrl,
            this._state.editorContainer.svg.viewbox().width,
            this._state.editorContainer.svg.viewbox().height
          )
      } catch (error) {
        this.handleError(
          new Error('Kein gültiger Bauplan vorhanden.'),
          false,
          true
        )
      }
    }

    this.resetView()

    for (const device of this.projectEditor.devicesInFloor) {
      if (device.meta && device.meta.svgObjectPositions) {
        await this.placeDevice(device.id, undefined)
      }
    }
    for (const annotation of this.annotationsInFloor) {
      await this.placeAnnotation(JSON.parse(JSON.stringify(annotation)))
    }

    // Initially need to do it twice, because containerBox is not set yet
    this.resetView()
    await this.setMode(EditorModes.PAN_ZOOM)

    // Find unplaced devices
    this.updateUnplaced()

    this.loadingStream.next(false)
    return
  }

  /**
   * Set mode
   * @param mode
   */
  public async setMode(mode: EditorModes) {
    // always set mode explicitly to avoid errors when coming back to tab
    // Check for pre-exit and pre-enter hooks
    if (this._state.mode.preExitHook) {
      await this._state.mode.preExitHook(this)
    }
    if (this._state.mode.preEnterHook) {
      await this._state.mode.preEnterHook(this)
    }
    switch (mode) {
      case EditorModes.PAN_ZOOM:
        return this.setPanZoomMode()
      case EditorModes.PLACEMENT:
        return this.setPlacementMode()
      case EditorModes.SELECT:
        return this.setSelectMode()
      case EditorModes.POINTING:
        return this.setPointingMode()
      default:
        throw new Error('Unknown editor mode.')
    }
  }

  private setPointingMode() {
    if (this.projectEditor.readOnly) {
      throw new Error(
        'Can not set to place mode while project in read-only mode.'
      )
    }
    this.setElementsDraggy(false)
    this._state.editorContainer.svg.panZoom({
      zoomMin: MIN_ZOOM_LEVEL,
      zoomMax: MAX_ZOOM_LEVEL,
    })
    this._state.mode = {
      mode: EditorModes.POINTING,
      preExitHook: this.exitPointingModeHook,
    }

    // Set mouse cursor style
    this._state.editorContainer.svg.node.style.cursor = 'crosshair'

    this._state.editorContainer.svg.on(
      'click',
      (event) => {
        // Get point in global SVG space
        function cursorPoint(svg, evt, pt) {
          pt.x = evt.clientX
          pt.y = evt.clientY
          return pt.matrixTransform(svg.getScreenCTM().inverse())
        }
        const loc = cursorPoint(
          this._state.editorContainer.svg.node,
          event,
          this.svgPt
        )
        this.mouseClickStream.next({ cx: loc.x, cy: loc.y })
      },
      false
    )
  }

  private exitPointingModeHook(handler: FloorPlanEditor) {
    handler._state.editorContainer.svg.off()
    // Set mouse cursor style
    handler._state.editorContainer.svg.node.style.cursor = 'unset'

    handler.exitPlacementModeHook(handler)
  }

  public async getMousePlacement() {
    await this.setMode(EditorModes.POINTING)
  }

  private setPanZoomMode() {
    this.setElementsDraggy(true)
    this._state.editorContainer.svg.panZoom({
      zoomMin: MIN_ZOOM_LEVEL,
      zoomMax: MAX_ZOOM_LEVEL,
    })
    this._state.mode = {
      mode: EditorModes.PAN_ZOOM,
    }
  }

  private prepareElementsForSelectionMode() {
    this._state.elements.devices.forEach((deviceSvg) => {
      this.prepareSvgObjectsForSelectionMode(deviceSvg)
    })
    this._state.elements.annotations.forEach((annotation) => {
      this.prepareSvgObjectsForSelectionMode(annotation)
    })
  }

  private prepareSvgObjectsForSelectionMode(
    svgCollection: SvgObjectCollection
  ) {
    for (const obj of svgCollection.svg.groups) {
      // obj.draggy()
      obj.on('mousedown', (ev) => {
        this._state.editorContainer.svg.panZoom(false)
      })
      obj.on('mouseup', async (ev) => {
        try {
          // catch ctrl + click
          if (ev.ctrlKey) {
            if (svgCollection.type === SvgObjectCollectionType.ANNOTATION) {
              if (this.isSelected(svgCollection.id)) {
                this.clearSelectedAnnotation(svgCollection.id)
              } else {
                await this.addElementsToSelection([], [svgCollection.id])
              }
            } else {
              if (this.isSelected(svgCollection.id)) {
                this.clearSelectedDevice(svgCollection.id)
              } else {
                await this.addElementsToSelection([svgCollection.id], [])
              }
            }
          } else {
            // normal click
            if (svgCollection.type === SvgObjectCollectionType.ANNOTATION) {
              await this.clearSelectedElements()
              await this.setAnnotationSelection([svgCollection.id])
            } else {
              await this.clearSelectedElements()
              await this.setDeviceSelection([svgCollection.id])
            }
          }
        } catch (error) {
          this.handleError(error)
        }
      })
    }
  }

  private prepareSvgObjectsForPlacementMode(
    svgCollection: SvgObjectCollection
  ) {
    for (const obj of svgCollection.svg.groups) {
      obj.draggy()
      obj.on('mousedown', (ev) => {
        this._state.editorContainer.svg.panZoom(false)
      })
      obj.on('mouseup', async (ev) => {
        if (ev.ctrlKey) {
          // catch ctrl + click
          if (svgCollection.type === SvgObjectCollectionType.ANNOTATION) {
            if (this.isSelected(svgCollection.id)) {
              this.clearSelectedAnnotation(svgCollection.id)
            } else {
              await this.addElementsToSelection([], [svgCollection.id])
            }
          } else {
            if (this.isSelected(svgCollection.id)) {
              this.clearSelectedDevice(svgCollection.id)
            } else {
              await this.addElementsToSelection([svgCollection.id], [])
            }
          }
        } else {
          // normal selection
          this.loadingStream.next(true)
          let update = false
          let updateAny = false
          try {
            if (svgCollection.type === SvgObjectCollectionType.ANNOTATION) {
              await this.clearSelectedElements()
              await this.setAnnotationSelection([svgCollection.id])
            } else {
              if (!this.isSelected(svgCollection.id)) {
                await this.clearSelectedElements()
                await this.setDeviceSelection([svgCollection.id])
              }
            }

            this._state.editorContainer.svg.panZoom(true)

            for (const annotationId of this.selectedAnnotationIds) {
              const annotation =
                this.projectEditor.getAnnotationById(annotationId)
              const annSvg = this._state.elements.annotations.get(annotationId)
              const groups = annSvg.svg.groups
              // Only save position changed more than a certain pixel delta
              for (let i = 0; i < groups.length; i++) {
                let cx = groups[i].cx()
                let cy = groups[i].cy()
                const t = groups[i].transform()
                if (annotation.category === AnnotationCategory.DEVICE_ARROW) {
                  cx = t.x
                  cy = t.y
                }
                update =
                  update ||
                  positionChanged(cx, annotation.meta.svgObjectPositions[i].cx)
                update =
                  update ||
                  positionChanged(cy, annotation.meta.svgObjectPositions[i].cy)
                if (
                  i === 0 &&
                  annotation.category === AnnotationCategory.TEXT
                ) {
                  annotation.meta.svgObjectPositions[i] = this.snapToHelpLine(
                    groups[0]
                  )
                } else {
                  annotation.meta.svgObjectPositions[i] = {
                    cx,
                    cy,
                    t,
                  }
                }
              }
              if (update) {
                await this.projectEditor.updateAnnotation(annotation)
                await this.redrawAnnotations([annotation.id])
              }
            }
            update = false
            // save translation of one device to be able to apply it to other devices
            const translation: { x: number; y: number; id: string }[] = [
              {
                x: 0,
                y: 0,
                id: '',
              },
              {
                x: 0,
                y: 0,
                id: '',
              },
            ]
            for (const deviceId of this.selectedDeviceIds) {
              update = false
              const device =
                this.projectEditor.deviceHandler.getDeviceAssignmentById(
                  deviceId
                )
              const deviceSvg = this._state.elements.devices.get(deviceId)
              const groups = deviceSvg.svg.groups
              for (let i = 0; i < groups.length; i++) {
                const cx = groups[i].cx()
                const cy = groups[i].cy()

                update =
                  update ||
                  positionChanged(cx, device.meta.svgObjectPositions[i].cx)
                update =
                  update ||
                  positionChanged(cy, device.meta.svgObjectPositions[i].cy)
                if (update) {
                  updateAny = true
                  translation[i] = {
                    x: cx - device.meta.svgObjectPositions[i].cx,
                    y: cy - device.meta.svgObjectPositions[i].cy,
                    id: device.id,
                  }
                }
                // snap to helpline
                // only for description group (i === 0)
                if (i === 0) {
                  device.meta.svgObjectPositions[i] = this.snapToHelpLine(
                    groups[i]
                  )
                } else {
                  device.meta.svgObjectPositions[i] = {
                    cx,
                    cy,
                  }
                }
              }
              if (update) {
                await this.projectEditor.updateDeviceAssignment(device, false)
              }
            }
            // apply translation to other devices
            if (updateAny) {
              for (const deviceId of this.selectedDeviceIds) {
                // only apply translation to other devices, not to self
                if (
                  translation[0].id !== deviceId ||
                  translation[1].id !== deviceId
                ) {
                  const device =
                    this.projectEditor.deviceHandler.getDeviceAssignmentById(
                      deviceId
                    )
                  const deviceSvg = this._state.elements.devices.get(deviceId)
                  const groups = deviceSvg.svg.groups
                  for (let i = 0; i < groups.length; i++) {
                    if (translation[i].id !== deviceId) {
                      const cx = groups[i].cx()
                      const cy = groups[i].cy()

                      if (i === 0) {
                        device.meta.svgObjectPositions[i] = this.snapToHelpLine(
                          groups[i],
                          cx + translation[i].x,
                          cy + translation[i].y
                        )
                      } else {
                        device.meta.svgObjectPositions[i] = {
                          cx: cx + translation[i].x,
                          cy: cy + translation[i].y,
                        }
                      }
                    }
                  }
                  await this.projectEditor.updateDeviceAssignment(device, false)
                }
              }
              // redraw all selected devices
              await this.redrawDevices(this.selectedDeviceIds)
            }
          } catch (err) {
            this.handleError(err)
          }
          this.loadingStream.next(false)
        }
      })
    }
  }

  private snapToHelpLine(
    group,
    x: number = undefined,
    y: number = undefined
  ): SvgPosition {
    const svgObjectPosition: SvgPosition = {
      cx: x !== undefined ? x : group.cx(),
      cy: y !== undefined ? y : group.cy(),
    }
    const helpLine = this.helpLine
    if (helpLine) {
      const bbox = group.node.getBBox()
      if (helpLine.meta.svgAttributes.orientation === Orientation.DOWN) {
        // vertical help line: add half width plus 5 (half width of help line)
        svgObjectPosition.cx =
          helpLine.meta.svgObjectPositions[0].cx +
          ((bbox && bbox.width) || 0) / 2 +
          5
      } else {
        // horizontal help line: add half height plus 5 (half width of help line)
        svgObjectPosition.cy =
          helpLine.meta.svgObjectPositions[0].cy +
          ((bbox && bbox.height) || 0) / 2 +
          5
      }
    }
    return svgObjectPosition
  }

  /**
   * Snap all devices that are currently selected to help line
   */
  public snapSelectedDevicesToHelpLine() {
    this.redrawDevices(this.selectedDeviceIds, true)
  }

  /**
   * Prepares all elements for 'placement' mode. Adds event listeners and re-connects elements
   * @param elements
   */
  private prepareElementsForPlacementMode() {
    this._state.elements.devices.forEach((deviceSvg, id) => {
      this.prepareSvgObjectsForPlacementMode(deviceSvg)
    })
    this._state.elements.annotations.forEach((annotation) => {
      this.prepareSvgObjectsForPlacementMode(annotation)
    })
  }

  /**
   * Enter 'placement' mode
   */
  private setPlacementMode() {
    if (this.projectEditor.readOnly) {
      throw new Error(
        'Can not set to placement mode while project in read-only mode.'
      )
    }
    // Add event listeners to all elements
    this.prepareElementsForPlacementMode()

    this.setElementsDraggy(false)
    this._state.editorContainer.svg.panZoom({
      zoomMin: MIN_ZOOM_LEVEL,
      zoomMax: MAX_ZOOM_LEVEL,
    })
    this._state.mode = {
      mode: EditorModes.PLACEMENT,
      preExitHook: this.exitPlacementModeHook,
    }
  }

  private async exitPlacementModeHook(handler: FloorPlanEditor): Promise<void> {
    // Clear all event handlers
    handler._state.elements.annotations.forEach((element) => {
      AnnotationElement.disableEventHandlers(element.svg)
    })
    handler._state.elements.devices.forEach((element) => {
      AnnotationElement.disableEventHandlers(element.svg)
    })

    // Unselect all
    await handler.clearSelectedElements()
  }

  /**
   * Enter 'select' mode
   */
  private setSelectMode() {
    // this.setElementsDraggy(true)
    this.prepareElementsForSelectionMode()
    this._state.editorContainer.svg.panZoom(false)

    this._state.editorContainer.svg.on(
      'mousedown',
      (ev) => {
        if (this.mode !== EditorModes.SELECT) {
          return
        }
        // catch ctrl + click
        if (ev.ctrlKey) {
          // do nothing
        } else {
          if (this._state.editorContainer.selectionBox !== null) {
            this._state.editorContainer.selectionBox.remove()
            this._state.editorContainer.selectionBox = null
          }
          this._state.editorContainer.selectionBox =
            this._state.editorContainer.svg.rect()
          this._state.editorContainer.selectionBox.attr({
            stroke: '#1d77b6',
            'fill-opacity': 0.15,
            'stroke-width': 2,
          })
          this._state.editorContainer.selectionBox.draw(ev)
        }
      },
      false
    )

    this._state.editorContainer.svg.on(
      'mouseup',
      async (ev) => {
        if (this.mode !== EditorModes.SELECT) {
          return
        }
        // catch ctrl + click
        if (ev.ctrlKey) {
          // do nothing
        } else {
          try {
            await this.selectElementsInBox(
              this._state.editorContainer.selectionBox
            )
            if (this._state.editorContainer.selectionBox !== null) {
              this._state.editorContainer.selectionBox.remove()
              this._state.editorContainer.selectionBox = null
            }
          } catch (err) {
            this.handleError(err)
          }
        }
      },
      false
    )

    this._state.mode = {
      mode: EditorModes.SELECT,
      preExitHook: this.exitSelectModeHook,
    }
  }

  /**
   * Exit 'select' mode
   * @param handler
   */
  private exitSelectModeHook(handler: FloorPlanEditor) {
    if (handler._state.editorContainer.selectionBox !== null) {
      handler._state.editorContainer.selectionBox.remove()
      handler._state.editorContainer.selectionBox = null
    }
    handler._state.editorContainer.svg.off()
    // Clear all event handlers
    handler._state.elements.annotations.forEach((element) => {
      AnnotationElement.disableEventHandlers(element.svg)
    })
    handler._state.elements.devices.forEach((element) => {
      AnnotationElement.disableEventHandlers(element.svg)
    })
  }

  /**
   * Evaluates a selection box for elements inside of it and adds them to the current selection
   * @param selectionBox
   */
  private async selectElementsInBox(selectionBox) {
    if (selectionBox === null) {
      return
    }
    const startX = selectionBox.node.x.animVal.value
    const startY = selectionBox.node.y.animVal.value
    const stopX = startX + selectionBox.node.width.animVal.value
    const stopY = startY + selectionBox.node.height.animVal.value

    // check if box to small -> treat as click -> return
    if (
      Math.round(startX) === Math.round(stopX) &&
      Math.round(startY) === Math.round(stopY)
    ) {
      return
    }

    await this.clearSelectedElements()

    const evaluateElement = (svgObject) => {
      const svgHeight = svgObject.node.getBBox().height
      const svgWidth = svgObject.node.getBBox().width
      const svgX = svgObject.cx()
      const svgY = svgObject.cy()

      if (svgY + svgHeight < startY) {
        return false // svg too high
      } else if (svgY > stopY) {
        return false // svg too low
      } else if (svgX + svgWidth < startX) {
        return false // svg too far left
      } else if (svgX > stopX) {
        return false // svg too far right
      } else {
        // Overlap
        return true
      }
    }

    const deviceSelection: string[] = []
    const annotationSelection: string[] = []
    this._state.elements.devices.forEach((device, id) => {
      let insideBox = false
      device.svg.groups.forEach((obj) => {
        insideBox = insideBox || evaluateElement(obj)
      })
      if (insideBox) {
        deviceSelection.push(id)
      }
    })
    this._state.elements.annotations.forEach((device, id) => {
      let insideBox = false
      device.svg.groups.forEach((obj) => {
        insideBox = insideBox || evaluateElement(obj)
      })
      if (insideBox) {
        annotationSelection.push(id)
      }
    })
    await this.setElementSelection(deviceSelection, annotationSelection)
  }

  /**
   * Set zoom level
   * @param zoomLevel
   */
  private setZoomLevel(zoomLevel: number) {
    this._state.editorContainer.svg.zoom(zoomLevel)
    this._state.zoomLevel = zoomLevel
  }

  /**
   * Sets all elements to draggy (or not)
   * @param value if true, set ot draggy
   */
  private setElementsDraggy(value: boolean): void {
    this._state.elements.annotations.forEach((element) => {
      AnnotationElement.setDraggy(element.svg, value)
    })
    this._state.elements.devices.forEach((element) => {
      AnnotationElement.setDraggy(element.svg, value)
    })
    return
  }

  /**
   * A 'device' consists of several graphical elements which can be divided into two separate
   * groups: Description group and device group. The description group contains the icon for the
   * device type, device nr, device description and channel information. The device group is the
   * position of the device in the floor plan with a connection to the description group.
   * These groups are connected but can be moved around individually.
   */
  public async createDevice(model, deviceEditorConfig) {
    if (deviceEditorConfig) {
      await this.projectEditor.createDevices(model, deviceEditorConfig.model)
    } else {
      await this.projectEditor.createDevices(model)
    }

    // We don't automatically draw the device, we just put it to the unplaced
    this.updateUnplaced()
  }

  public async deleteSelected(reorder?: boolean): Promise<string[]> {
    let reorderedDeviceIds = []
    for (const deviceId of this.selectedDeviceIds) {
      reorderedDeviceIds = await this.deleteDevice(deviceId, reorder)
    }
    for (const annId of this.selectedAnnotationIds) {
      await this.deleteAnnotation(annId)
    }
    await this.clearSelectedElements()
    return reorderedDeviceIds
  }

  /**
   * Deletes a device
   */
  public async deleteDevice(
    deviceId: string,
    reorder: boolean = false
  ): Promise<string[]> {
    const reorderedDeviceIds = await this.projectEditor.deleteDeviceAssignments(
      [deviceId],
      reorder
    )
    removeSvgObjects(this._state.elements.devices.get(deviceId).svg)
    this._state.elements.devices.delete(deviceId)
    this.updateUnplaced()
    return reorderedDeviceIds
  }

  /**
   * Adds an already existing device into the floor plan
   * @param device
   */
  public async placeDevice(
    deviceId: string,
    devicePlacement?: Placement,
    replace = false
  ) {
    let isNewPlacement = true
    const device =
      this.projectEditor.deviceHandler.getDeviceAssignmentById(deviceId)
    if (!device) {
      throw new Error(`PlaceDevice: Invalid device id ${deviceId}`)
    }

    if (device.meta && device.meta.svgObjectPositions) {
      isNewPlacement = false
    }

    const variant = this.projectEditor.equipmentHandler.getVariantFromId(
      device.equipment_variant
    )
    const floorPlanSettings =
      this.projectEditor.selectedFloor.floor_plan_settings

    const { descGroup, deviceGroup, connection, color, bgColor } = drawDevice(
      this._state.editorContainer,
      device,
      variant,
      this.senderArrangement,
      floorPlanSettings,
      devicePlacement
    )

    if (isNewPlacement || replace) {
      device.floor = this.projectEditor.selectedFloor.id
      device.meta = {
        svgObjectPositions: [
          this.snapToHelpLine(descGroup),
          {
            cx: deviceGroup.cx(),
            cy: deviceGroup.cy(),
          },
        ],
        svgAttributes: {
          color,
          bgColor,
          ...device.meta.svgAttributes,
        },
      }
    }

    const svgObjectCollection: SvgObjectCollection = {
      svg: {
        connection,
        groups: [descGroup, deviceGroup],
      },
      svgAttributes: device.meta.svgAttributes,
      type: SvgObjectCollectionType.DEVICE,
      id: device.id,
    }

    if (this.isSelected(device.id)) {
      AnnotationElement.selectize(svgObjectCollection.svg, true)
    }

    this._state.elements.devices.set(device.id, svgObjectCollection)

    if (this.mode === EditorModes.PLACEMENT) {
      this.prepareSvgObjectsForPlacementMode(
        this._state.elements.devices.get(device.id)
      )
    } else if (this.mode === EditorModes.SELECT) {
      this.prepareSvgObjectsForSelectionMode(
        this._state.elements.devices.get(device.id)
      )
    }

    this.updateUnplaced()

    if (isNewPlacement || replace) {
      try {
        await this.projectEditor.updateDeviceAssignment(device, false)
        await this.redrawDevices([device.id], false, false)
      } catch (error) {
        // Un-draw device
        removeSvgObjects({
          groups: [descGroup, deviceGroup],
        })
        throw error
      }
    }
  }

  /**
   * Delete an annotation
   * @param annotation
   */
  public async deleteAnnotation(annotationId: string) {
    await this.projectEditor.deleteAnnotation(annotationId)
    removeSvgObjects(this._state.elements.annotations.get(annotationId).svg)
    this._state.elements.annotations.delete(annotationId)
  }

  public async createAnnotation(
    annotation: AnnotationElement,
    placements: Placement[]
  ): Promise<string> {
    this.loadingStream.next(true)

    if (annotation.category === AnnotationCategory.DEVICE_ARROW) {
      // Only one device_arrow annotation is allowed per floor
      this.annotationsInFloor.forEach((ann) => {
        if (ann.category === AnnotationCategory.DEVICE_ARROW) {
          throw new Error(
            'Nur ein Geräte Pfeil kann pro Stockwerk platziert werden.'
          )
        }
      })
    }

    if (annotation.category === AnnotationCategory.HELP_LINE) {
      // only one help line per project
      this.annotations.forEach((ann) => {
        if (ann.category === AnnotationCategory.HELP_LINE) {
          throw new Error(
            'Nur eine Hilfslinie kann pro Projekt platziert werden.'
          )
        }
      })
    }

    const uuid = uuidv1()
    annotation.id = uuid
    annotation.category = annotation.category as AnnotationCategory
    annotation.meta = {
      svgObjectPositions: placements,
      svgAttributes: annotation.meta.svgAttributes,
    }
    annotation.floor = this.projectEditor.selectedFloor.id

    this.placeAnnotation(annotation)
    try {
      await this.projectEditor.updateAnnotation(annotation)
    } catch (error) {
      this.handleError(error, true)
    }

    this.loadingStream.next(false)
    return uuid
  }

  /**
   * Adds an Annotation
   * @param description
   */
  public async placeAnnotation(annotation: AnnotationElement, replace = false) {
    if (
      annotation.category === AnnotationCategory.HELP_LINE &&
      !this._state.helpLineActive
    ) {
      return
    }
    this.loadingStream.next(true)
    const placements = annotation.meta.svgObjectPositions

    const svgObjects = drawAnnotation(
      this._state.editorContainer,
      annotation,
      placements
    )

    let isNewPlacement = true
    if (annotation.meta && annotation.meta.svgObjectPositions) {
      isNewPlacement = false
    }
    if (isNewPlacement || replace) {
      this.snapToHelpLine(svgObjects)
    }

    const svgObjectCollection: SvgObjectCollection = {
      svg: {
        groups: svgObjects,
      },
      svgAttributes: annotation.meta.svgAttributes,
      type: SvgObjectCollectionType.ANNOTATION,
      id: annotation.id,
    }

    if (this.isSelected(annotation.id)) {
      AnnotationElement.selectize(svgObjectCollection.svg, true)
    }

    this._state.elements.annotations.set(annotation.id, svgObjectCollection)

    if (this.mode === EditorModes.PLACEMENT) {
      this.prepareSvgObjectsForPlacementMode(
        this._state.elements.annotations.get(annotation.id)
      )
    }
    this.loadingStream.next(false)
  }

  public async setDeviceSelection(deviceIds: string[]) {
    return await this.setElementSelection(deviceIds, [])
  }

  public async setAnnotationSelection(annotationIds: string[]) {
    return await this.setElementSelection([], annotationIds)
  }

  /**
   * Set selection to the current selection of elements
   * @param element
   */
  public async setElementSelection(
    deviceIds: string[],
    annotationIds: string[]
  ) {
    this.clearSelectize()
    const receivers = []
    const senders = []
    for (const deviceId of deviceIds) {
      const device =
        this.projectEditor.deviceHandler.getDeviceAssignmentById(deviceId)
      if (device && device.category === EquipmentCategory.SENDER) {
        senders.push(device.id)
      } else if (device && device.category === EquipmentCategory.RECEIVER) {
        receivers.push(device.id)
      }
    }
    await this._store.dispatch('brelag/setSelectedSenders', senders)
    await this._store.dispatch('brelag/setSelectedReceivers', receivers)
    await this._store.dispatch('brelag/setSelectedAnnotations', annotationIds)

    await this.projectEditor.updateLinkedState(true)
    await this.projectEditor.updateTeachingOptions(false, false)

    if (
      this.mode === EditorModes.PLACEMENT ||
      this.mode === EditorModes.SELECT
    ) {
      for (const deviceId of deviceIds) {
        const device = this._state.elements.devices.get(deviceId)
        if (device) {
          AnnotationElement.selectize(device.svg, true)
        }
      }
      for (const annId of annotationIds) {
        const annotation = this._state.elements.annotations.get(annId)
        AnnotationElement.selectize(annotation.svg, true)
      }
    }
  }

  /**
   * Adds elements to selection (keeping previously selected elements)
   */
  public async addElementsToSelection(
    deviceIds: string[],
    annotationIds: string[]
  ) {
    for (const deviceId of deviceIds) {
      const device =
        this.projectEditor.deviceHandler.getDeviceAssignmentById(deviceId)
      if (device && device.category === EquipmentCategory.SENDER) {
        await this._store.dispatch('brelag/addSenderToSelection', device.id)
      } else if (device && device.category === EquipmentCategory.RECEIVER) {
        await this._store.dispatch('brelag/addReceiverToSelection', device.id)
      }
    }
    await this.projectEditor.updateLinkedState(true)
    await this.projectEditor.updateTeachingOptions(false, false)
    for (const annotationId of annotationIds) {
      this._store.commit('brelag/addAnnotationToSelection', annotationId)
    }

    if (
      this.mode === EditorModes.PLACEMENT ||
      this.mode === EditorModes.SELECT
    ) {
      for (const deviceId of deviceIds) {
        const device = this._state.elements.devices.get(deviceId)
        if (device) {
          AnnotationElement.selectize(device.svg, true)
        }
      }
      for (const annId of annotationIds) {
        const annotation = this._state.elements.annotations.get(annId)
        if (annotation) {
          AnnotationElement.selectize(annotation.svg, true)
        }
      }
    }
  }

  /**
   * Clears all elements that are currently selected
   */
  public async clearSelectedElements() {
    try {
      await this.projectEditor.cancelEditDevice()
      this.clearSelectize()
      await this.projectEditor.clearSelection()

      // Clear leftove selection points
      const points = SVG.select('.svg_select_points_point')
      if (points.members) {
        for (const member of points.members) {
          member.remove()
        }
      }
    } catch (err) {
      this.handleError(err)
    }
  }

  public async clearSelectize() {
    this._state.elements.annotations.forEach((element) => {
      AnnotationElement.selectize(element.svg, false)
    })
    this._state.elements.devices.forEach((element) => {
      AnnotationElement.selectize(element.svg, false)
    })
  }

  public async clearSelectedDevice(id: string) {
    try {
      const device =
        this.projectEditor.deviceHandler.getDeviceAssignmentById(id)
      if (device && device.category === EquipmentCategory.SENDER) {
        this._store.commit('brelag/removeSenderFromSelection', id)
      } else if (device && device.category === EquipmentCategory.RECEIVER) {
        this._store.commit('brelag/removeReceiverFromSelection', id)
      }
      const deviceSvg = this._state.elements.devices.get(id)
      AnnotationElement.selectize(deviceSvg.svg, false)
    } catch (err) {
      this.handleError(err)
    }
  }

  public async clearSelectedAnnotation(id: string) {
    try {
      this._store.commit('brelag/removeAnnotationFromSelection', id)
      const deviceSvg = this._state.elements.annotations.get(id)
      AnnotationElement.selectize(deviceSvg.svg, false)
    } catch (err) {
      this.handleError(err)
    }
  }

  public isSelected(id: string) {
    return this.selectedElementIds.indexOf(id) > -1
  }

  public async redrawDevices(
    deviceIds: string[],
    replace = false,
    showLoading = true
  ) {
    if (showLoading) this.loadingStream.next(true)
    await this.redrawElements(deviceIds, [], replace)
    await this.updateUnplaced()
    if (showLoading) this.loadingStream.next(false)
  }

  public async redrawAnnotations(ids: string[], replace = false) {
    this.loadingStream.next(true)
    await this.redrawElements([], ids, replace)
    this.loadingStream.next(false)
  }

  /**
   * Re-draw elements
   * @param elements elements to redraw
   * @param removeElements currently drawn devices that should be removed
   */

  public async redrawElements(
    deviceIds: string[],
    annotationIds: string[],
    replace = false
  ) {
    for (const deviceId of deviceIds) {
      const deviceSvg = this._state.elements.devices.get(deviceId)
      if (deviceSvg) {
        removeSvgObjects(deviceSvg.svg)
      }
      this._state.elements.devices.delete(deviceId)

      const device =
        this.projectEditor.deviceHandler.getDeviceAssignmentById(deviceId)
      if (
        device.floor === this.projectEditor.selectedFloor.id &&
        device.meta &&
        device.meta.svgObjectPositions
      ) {
        await this.placeDevice(deviceId, undefined, replace)
      }
    }
    for (const annotationId of annotationIds) {
      const annotationSvg = this._state.elements.annotations.get(annotationId)
      removeSvgObjects(annotationSvg.svg)
      this._state.elements.annotations.delete(annotationId)
      const annotation = this.projectEditor.getAnnotationById(annotationId)

      if (annotation.category === AnnotationCategory.DEVICE_ARROW) {
        // Special case, need to remove marker as well
        try {
          SVG.get('deviceArrowMarker').remove()
        } catch (err) {
          // Ignore
        }
      }

      await this.placeAnnotation(annotation, replace)
    }
  }

  public move(direction) {
    move(this._state.editorContainer, direction)
  }

  public zoomIn() {
    const zoomLevel = zoomIn(this._state.editorContainer)
    return this.setZoomLevel(zoomLevel)
  }

  public zoomOut() {
    const zoomLevel = zoomOut(this._state.editorContainer)
    return this.setZoomLevel(zoomLevel)
  }

  /**
   * Resets view so that all elements are visible
   */
  public resetView() {
    resetView(this._state.editorContainer)
  }

  public get annotations(): AnnotationElement[] {
    if (this.projectEditor.project.meta.annotations) {
      return this.projectEditor.project.meta.annotations
    } else {
      return []
    }
  }

  public get annotationsInFloor(): AnnotationElement[] {
    return this.annotations.filter(
      (annotation) => annotation.floor === this.projectEditor.selectedFloor.id
    )
  }

  public get helpLineActive(): boolean {
    return this._state.helpLineActive
  }

  public get helpLine(): AnnotationElement {
    if (this._state.helpLineActive) {
      return this.annotations.find((ann) => {
        return ann.category === AnnotationCategory.HELP_LINE
      })
    }
    return undefined
  }

  public setHelpLineActive(value: boolean) {
    this._state.helpLineActive = value
    const helpLine = this.annotations.find((ann) => {
      return ann.category === AnnotationCategory.HELP_LINE
    })
    if (!helpLine) {
      // create new helpline
      this.createAnnotation(HELP_LINE.annotation, [
        {
          cx: 500,
          cy: 0,
        },
      ])
    } else {
      if (value) {
        this.placeAnnotation(helpLine)
      } else {
        this.redrawAnnotations([helpLine.id])
      }
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { getModelClass } from '@/models/objectRegistry'
import { TransientBaseObject } from '@/models/core/base'
import BaseListV2 from '@/components/common/BaseListV2.vue'

@Component({
  name: 'formly_related-model-field',
  components: {
    BaseListV2,
  },
  props: ['form', 'field', 'model', 'to'],
  data() {
    return {
      modelClass: getModelClass(this.$props.field.modelClass),
      showList: false,
      relatedObjectProperty: null,
      selection: null,
      originalRelatedModel: null,
      loading: false,
    }
  },
})
export default class RelatedModelField extends Vue {
  modelClass: typeof TransientBaseObject
  showList: boolean = false
  relatedObjectProperty: any
  selection: any
  originalRelatedModel: any
  loading: boolean = false
  selectionId: string

  get preselectedRows(): string[] {
    let id
    if (this.$props.model[this.$props.field.key]) {
      id = this.$props.model[this.$props.field.key]
    } else if (this.$route.query[this.$props.field.key]) {
      id = this.$route.query[this.$props.field.key]
      this.$props.model[this.$props.field.key] = id
    }
    if (id) {
      return [id]
    } else {
      return []
    }
  }

  async created() {
    let id
    if (this.$props.model[this.$props.field.key]) {
      id = this.$props.model[this.$props.field.key]
    } else if (this.$route.query[this.$props.field.key]) {
      id = this.$route.query[this.$props.field.key]
      this.$props.model[this.$props.field.key] = id
    }

    if (id) {
      this.loading = true
      try {
        this.originalRelatedModel = await this.$apiv2.get(this.modelClass, id)
        this.relatedObjectProperty =
          this.originalRelatedModel[this.$props.field.relatedObjectProperty]
        this.loading = false
      } catch (err) {
        // TODO: Error msg!
        this.loading = false
      }
    }
  }

  get collectionFilter() {
    let collectionFilter = {}
    if (this.$props.field.filters) {
      for (const filter of this.$props.field.filters) {
        if (filter === 'organisation') {
          collectionFilter['organisation'] =
            this.$store.getters['global/organisation'].id
        } else {
          console.warn(`Filter by collection ${filter} not supported yet.`)
        }
      }
    }
    return collectionFilter
  }

  get showFilter() {
    if (this.$props.field.showFilter === undefined) {
      return true
    } else {
      return this.$props.field.showFilter
    }
  }

  get isEditable() {
    if (this.$props.field.editable === undefined) {
      return true
    } else {
      if (
        this.$props.field.editable === false &&
        this.$props.model.id !== '0'
      ) {
        return false
      } else {
        return true
      }
    }
  }

  onSelect(selection) {
    this.showList = false
    if (selection.length > 0) {
      this.selection = selection[0]
      Vue.set(this.$props.model, this.$props.field.key, this.selection.id)
      this.relatedObjectProperty = null
      if (this.$props.field.relatedObjectProperty) {
        this.relatedObjectProperty =
          selection[0][this.$props.field.relatedObjectProperty]
      }
    } else {
      this.selection = null
    }
  }

  openListSelection() {
    this.showList = true
  }

  get prettyName(): string {
    let prettyName = this.modelClass.prettyName()
    if (this.$props.field && this.$props.field.required) {
      // add star for required field
      prettyName += ' *'
    }
    return prettyName
  }
}

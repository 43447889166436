
import { Vue, Component } from 'vue-property-decorator'

import { Context } from '@/api/ApiClientV2'
import { KnockautProject } from '../../common/models/knockaut'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet'

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
  },
  data: () => {
    return {
      zoom: 7.4,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
      },
    }
  },
})
export default class KnockautMap extends Vue {
  center = latLng(46.6, 8.3054129)
  knockautProjects: KnockautProject[] = []

  mounted() {
    this.getKnockautProjects()
  }

  get locations() {
    return this.knockautProjects
      .filter((project) => project.longitude !== null)
      .map((project) => {
        return {
          location: latLng(project.latitude, project.longitude),
          id: project.id,
          title: project.title,
        }
      })
  }

  async getKnockautProjects() {
    const loading = this.$buefy.loading.open({ container: null })
    try {
      const context: Context = {
        filter: {
          // organisation: this.organisation,
        },
        pagination: {
          page: 1,
          pageSize: 150,
        },
      }
      const response = await this.$apiv2.getList<KnockautProject>(
        KnockautProject,
        context
      )
      this.knockautProjects = response.results
    } catch (error) {
      this.knockautProjects = []
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(error),
        type: 'is-danger',
        duration: 5000,
      })
    }
    loading.close()
  }
}

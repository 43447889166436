import {
  Equipment,
  EquipmentDump,
  EquipmentVariant,
  EquipmentGroup,
  EquipmentCategory,
  DeviceAssignment,
  BrelagConfigKey,
} from '@/apps/brelag/common/models/equipment'
import { ApiClientV2 } from '@/api/ApiClientV2'
import { FormBuilderHelper } from '@/components/common/forms/formBuilderHelper'

export interface EquipmentTree {
  group: EquipmentGroup
  equipments: {
    equipment: Equipment
    variants: EquipmentVariant[]
  }[]
}

export class EquipmentHandler {
  private _equipmentDump: EquipmentDump[]
  private _senderEquipmentTree: EquipmentTree[] = []
  private _receiverEquipmentTree: EquipmentTree[] = []
  private _equipmentGroups: EquipmentGroup[] = []
  private _equipmentVariants: EquipmentVariant[] = []
  private _senderEquipmentVariants: EquipmentVariant[] = []
  private _receiverEquipmentVariants: EquipmentVariant[] = []
  private _variantToEquipmentMap: Map<string, Equipment> = new Map()

  private _eGateEquipmentIds: string[] = []
  public get eGateEquipmentIds(): string[] {
    return this._eGateEquipmentIds
  }

  private _ultraReceiverEquipmentIds: string[] = []

  public isUltraReceiver(device: DeviceAssignment): boolean {
    return (
      this._ultraReceiverEquipmentIds.findIndex(
        (id) => id === device.equipment
      ) !== -1
    )
  }

  public isEgate(equipmentId: string): boolean {
    return this._eGateEquipmentIds.findIndex((id) => id === equipmentId) !== -1
  }

  /***
   * Returns true if device is of type Equipment with is_virtual set to true
   */
  public isVirtualSender(device: DeviceAssignment): boolean {
    if (device.category === EquipmentCategory.SENDER) {
      const equipment = this.getEquipmentFromDevice(device)
      return equipment.is_virtual
    } else {
      return false
    }
  }

  public async loadEquipment(apiClient: ApiClientV2, dump?: EquipmentDump[]) {
    // Load equipment dump
    if (dump === undefined) {
      if (location.hostname === 'localhost' && false) {
        const localStorageDumpKey = 'equipment-dump'
        // If we're runnign locally, use equipment dump from local storage if available
        const localDump = localStorage.getItem(localStorageDumpKey)
        if (localDump) {
          dump = JSON.parse(localDump)
        } else {
          dump = await Equipment.equipmentDump(apiClient)
          localStorage.setItem(localStorageDumpKey, JSON.stringify(dump))
        }
      } else {
        dump = await Equipment.equipmentDump(apiClient)
      }
    }
    try {
      this.parseDump(dump)
    } catch (error) {
      throw new Error(
        'Error parsing Equipment dump, did not load new Dump. Error: ' + error
      )
    }
  }

  private parseDump(dump: EquipmentDump[]) {
    // Get all EquipmentGroups
    const equipmentTree: EquipmentTree[] = []
    const senderVariants = []
    const receiverVariants = []
    const variants = []
    const groups = new Map()
    const modelToVariantMap = new Map()
    const variantToEquipmentMap = new Map()
    this._eGateEquipmentIds = []
    this._equipmentDump = null
    this._senderEquipmentTree = []
    this._receiverEquipmentTree = []
    this._equipmentGroups = []
    this._equipmentVariants = []
    this._senderEquipmentVariants = []
    this._receiverEquipmentVariants = []
    this._variantToEquipmentMap = new Map()

    // Gather all EquipmentGroups
    for (const equipment of dump) {
      let fields = equipment.config_editor_layout.fields
        ? equipment.config_editor_layout.fields
        : []
      if (equipment.config_editor_layout.tabs) {
        for (const tab of equipment.config_editor_layout.tabs) {
          fields = fields.concat(tab.fields)
        }
      }
      for (const field of fields) {
        if (field.key === BrelagConfigKey.EGATE) {
          this._eGateEquipmentIds.push(equipment.id)
        }
      }
      const config = FormBuilderHelper.getFieldDictFromConfig(
        equipment.teaching_options
      )
      for (const key of Object.keys(config)) {
        if (key === BrelagConfigKey.ULTRA_RECEIVER) {
          this._ultraReceiverEquipmentIds.push(equipment.id)
        }
      }

      if (
        equipmentTree.findIndex(
          (tree) => tree.group.id === equipment.equipment_group.id
        ) === -1
      ) {
        const group = equipment.equipment_group

        groups.set(group.id, group)

        equipmentTree.push({
          group,
          equipments: [],
        })
      }

      const group =
        equipmentTree[
          equipmentTree.findIndex(
            (tree) => tree.group.id === equipment.equipment_group.id
          )
        ]

      if (equipment.equipment_variants) {
        equipment.equipment_variants.forEach((variant) => {
          variants.push(variant)
          if (equipment.category === EquipmentCategory.RECEIVER) {
            receiverVariants.push(variant)
          }
          if (equipment.category === EquipmentCategory.SENDER) {
            senderVariants.push(variant)
          }

          modelToVariantMap.set(variant.model, variant)
          variantToEquipmentMap.set(variant.id, equipment)
        })
      }
      group.equipments.push({
        equipment,
        variants: equipment.equipment_variants,
      })
    }

    this._equipmentVariants = variants
    this._receiverEquipmentVariants = receiverVariants
    this._senderEquipmentVariants = senderVariants
    this._variantToEquipmentMap = variantToEquipmentMap
    this._equipmentDump = dump
    this._equipmentGroups = Array.from(groups.values())

    // Create sender/receiver trees
    for (const tree of equipmentTree) {
      const senderEquipment = tree.equipments.filter(
        (eq) => eq.equipment.category === EquipmentCategory.SENDER
      )
      this._senderEquipmentTree.push({
        equipments: senderEquipment,
        group: tree.group,
      })

      const receiverEquipment = tree.equipments.filter(
        (eq) => eq.equipment.category === EquipmentCategory.RECEIVER
      )
      this._receiverEquipmentTree.push({
        equipments: receiverEquipment,
        group: tree.group,
      })
    }
    // Sort by ordering
    this._receiverEquipmentTree.sort(
      (A, B) => A.group.ordering - B.group.ordering
    )
  }

  public getCategoryFromVariant(variantId: string): EquipmentCategory {
    const variant = this.getVariantFromId(variantId)

    if (variant) {
      const ind = this.equipment.findIndex((eq) => eq.id === variant.equipment)
      return this.equipment[ind].category
    } else {
      throw new Error(
        `Could not find EquipmentVariant for model with id ${variantId}`
      )
    }
  }

  /**
   * Returns EquipmentVariant of a DeviceAssignment
   * @param id
   */
  public getVariantFromDeviceAssignment(
    device: DeviceAssignment
  ): EquipmentVariant {
    // TODO: Could be improved using Map
    const ind = this._equipmentVariants.findIndex(
      (variant) => variant.id === device.equipment_variant
    )
    return this._equipmentVariants[ind]
  }

  /**
   * Returns Equipment from DeviceAssignment
   * @param id
   */
  public getEquipmentFromDevice(device: DeviceAssignment): Equipment {
    return this._variantToEquipmentMap.get(device.equipment_variant)
  }

  /**
   * Returns Equipment from EquipmentVariant id
   * @param variantId
   */
  public getEquipmentFromVariantId(variantId: string): Equipment {
    return this._variantToEquipmentMap.get(variantId)
  }

  /**
   * Returns Equipment from EquipmentVariant article_number
   * @param variantId
   */
  public getVariantFromArticleNr(articleNr: string): EquipmentVariant | null {
    let variant = null
    const index = this._equipmentVariants.findIndex(
      (variant) => variant.article_number === articleNr
    )
    if (index > -1) {
      variant = this._equipmentVariants[index]
    }
    return variant
  }

  public getVariantFromId(variantId: string): EquipmentVariant | null {
    let variant = null
    const index = this._equipmentVariants.findIndex(
      (variant) => variant.id === variantId
    )
    if (index > -1) {
      variant = this._equipmentVariants[index]
    }
    return variant
  }

  public getDeviceIcon(device: DeviceAssignment): string | null {
    const variant = this.getVariantFromDeviceAssignment(device)
    return variant.image_url
  }

  /**
   * Test if a given channel number is valid for this equipment.
   * @param sender device to check
   * @param channel channel The channel number to check
   * @return true if the channel number is available, throws otherwise
   */
  public checkChannelValid(sender: DeviceAssignment, channel: number): boolean {
    const equipment = this.getEquipmentFromDevice(sender)
    if (channel < 0) {
      throw new Error('Kanal kann nicht kleiner als 0 sein')
    }
    if (channel > equipment.channel_count) {
      throw new Error(
        `Kanal kann nicht grösser als ${equipment.channel_count} sein`
      )
    }
    return true
  }

  // TODO: delete?
  public get equipment(): EquipmentDump[] {
    return this._equipmentDump
  }

  public get equipmentGroups(): EquipmentGroup[] {
    return this._equipmentGroups
  }

  public get equipmentVariants(): EquipmentVariant[] {
    return this._equipmentVariants
  }

  public get senderEquipmentVariants(): EquipmentVariant[] {
    return this._senderEquipmentVariants
  }

  public get receiverEquipmentVariants(): EquipmentVariant[] {
    return this._receiverEquipmentVariants
  }

  public get receiverEquipmentTree() {
    return this._receiverEquipmentTree
  }

  public get senderEquipmentTree() {
    return this._senderEquipmentTree
  }
}

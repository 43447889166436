function isObject(o) {
  return o instanceof Object && o.constructor === Object
}

/**
 * Turn error into strings
 * @param error
 * @param prettyString only show response data and not things like "Error: Request failed with status code 400"
 */
export function errorToStrings(error: any, prettyString = false): string[] {
  let errorMessages = []
  if (error.response === undefined) {
    if (error.message) {
      errorMessages = [error.message]
      return errorMessages
    } else {
      try {
        const errMsg = error.toString()
        return [errMsg]
      } catch (_) {
        return ['Unknown error.']
      }
    }
  }
  if (error.response.data && isObject(error.response.data)) {
    if (!prettyString) {
      errorMessages = [error.message]
    }
    for (const key in error.response.data) {
      if (error.response.data.hasOwnProperty(key)) {
        if (prettyString) {
          errorMessages.push(error.response.data[key])
        } else {
          errorMessages.push(key + ': ' + error.response.data[key])
        }
      }
    }
  } else if (
    error.response.data.constructor === Array &&
    error.response.data.length > 0
  ) {
    errorMessages = [error.response.data[0]]
  } else if (error.message) {
    errorMessages = [error.message]
  } else {
    errorMessages = [error]
  }
  return errorMessages
}

export function errorToString(error: any): string {
  return errorToStrings(error).join('\n')
}

export function errorToPrettyString(error: any): string {
  return errorToStrings(error, true).join('\n')
}

export class ErrorHandler {
  static install(vue): void {
    vue.prototype.$errorHandler = new ErrorHandler()
  }

  errorToString(error: any): string {
    return errorToString(error)
  }

  errorToStrings(error: any): string[] {
    return errorToStrings(error)
  }

  errorToPrettyString(error: any): string {
    return errorToPrettyString(error)
  }

  errorToPrettyStrings(error: any): string[] {
    return errorToStrings(error, true)
  }
}


import { Component, Vue } from 'vue-property-decorator'
import BaseListV2 from '@/components/common/BaseListV2.vue'

import { Offer } from '@/models/commerce/offer'

@Component({
  name: 'formly_brelag-offer-select-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    BaseListV2,
  },
  data() {
    return {
      Offer,
    }
  },
})
export default class BrelagOfferSelectField extends Vue {
  $refs: {
    offerList: BaseListV2<Offer>
  }

  created() {
    if (!this.$props.model['meta.offers']) {
      this.$props.model['meta.offers'] = []
    }
  }

  rowSelection(offerIds) {
    this.$props.model['meta.offers'] = offerIds
  }
}


import { CommonContext } from '@/store/GlobalStore'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'

@Component({
  name: 'client-app-selector',
  computed: {
    ...mapState('global', ['context']),
  },
})
export default class ClientAppSelector extends Vue {
  context: CommonContext
}

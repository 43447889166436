
import { CollectionSubscriber } from '@/api/ApiClient'
import EditableCell from '@/components/common/EditableCell.vue'
import { Token } from '@/models/core/models'
import { TOKEN_DEFAULT } from '@/mock/tokens'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'token-list',
  components: {
    'editable-cell': EditableCell,
  },
  props: {
    user: {
      required: true,
    },
  },
  data() {
    return {
      tokens: this.$api.subscribeListInComponent(this, 'token', {
        profile: this.$props.user,
      }),
    }
  },
})
export default class TokenList extends Vue {
  tokens: CollectionSubscriber
  sortField: string = 'enabled'
  sortOrder: string = 'desc'
  defaultSortOrder: string = 'desc'
  showRevoked: boolean = false
  newToken: Token = JSON.parse(JSON.stringify(TOKEN_DEFAULT))
  showNewTokenKey: boolean = false
  errorMessage: string = ''
  error: boolean = false

  newTokenKey = {
    key: '',
    hash: '',
  }

  onPageChange(page) {
    this.tokens.setPagination(page)
  }

  revokeToken(token: Token) {
    this.$api.revokeToken(token.id).then((response) => {
      this.$buefy.toast.open({
        message: 'Successfully revoked!',
        type: 'is-success',
      })
    })
  }

  saveToken(token: Token, value) {
    token.label = value
    this.$api.update('token', token).then((response) => {
      this.$buefy.toast.open({
        message: 'Successfully saved!',
        type: 'is-success',
      })
    })
  }

  addToken() {
    this.newToken.profile = this.$props.user
    this.$api
      .create('token', this.newToken)
      .then((response) => {
        this.newTokenKey.key = response.data.key
        this.newTokenKey.hash = response.data.truncated_key_hash
        this.showNewTokenKey = true
        this.newToken = JSON.parse(JSON.stringify(TOKEN_DEFAULT))
      })
      .catch((error) => {
        this.errorMessage = error.response.data
        this.error = true
      })
  }

  closeNewTokenKeyModal() {
    this.newTokenKey.key = ''
    this.newTokenKey.hash = ''
    this.showNewTokenKey = false
  }

  editLabel(row) {
    row.is_dirty = true
  }

  get visibleTokens() {
    if (!this.showRevoked) {
      const t = []
      this.tokens.objects.forEach((token) => {
        if (token.enabled) {
          t.push(token)
        }
      })
      return t
    } else {
      return this.tokens.objects
    }
  }
}


import moment from 'moment'
import Vue from 'vue'
import Component from 'vue-class-component'

import { ApiListSubscription } from '@/api/ApiClientV2'
import {
  Transaction,
  TransactionState,
  TransactionStateMap,
} from '@/models/core/models'

@Component({
  data: function () {
    return {
      transactions: Transaction.collection(
        this,
        {
          state: [TransactionState.LastOperationFailed, TransactionState.Open],
        },
        {
          page: 1,
          pageSize: 10,
        },
        60000
      ),
    }
  },
})
export default class TransactionDropdown extends Vue {
  numElementsDisplay: number = 5
  transactions: ApiListSubscription<Transaction>

  get failedTransactions(): Transaction[] {
    if (!this.transactions) {
      return []
    }
    return this.transactions.objects.filter((transaction: Transaction) => {
      transaction.state === TransactionState.LastOperationFailed
    })
  }

  get openTransactions(): Transaction[] {
    if (!this.transactions) {
      return []
    }
    return this.transactions.objects.filter((transaction: Transaction) => {
      transaction.state === TransactionState.Open
    })
  }

  timePassed(timeSinceEpoch) {
    return moment(timeSinceEpoch).fromNow()
  }

  prettyState(state) {
    return TransactionStateMap[state]
  }

  async commit(transaction: Transaction) {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      await Transaction.commit(this.$apiv2, transaction.id)
      await this.transactions.refresh()
      this.$buefy.toast.open({
        message: this.$i18n.tc('components.common.transaction.applySuccess'),
        type: 'is-success',
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(err),
        type: 'is-danger',
      })
    }
    loadingComponent.close()
  }

  async rollback(transaction: Transaction) {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      await Transaction.rollback(this.$apiv2, transaction.id)
      await this.transactions.refresh()
      this.$buefy.toast.open({
        message: this.$i18n.tc('components.common.transaction.resetSuccess'),
        type: 'is-success',
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(err),
        type: 'is-danger',
      })
    }
    loadingComponent.close()
  }
}

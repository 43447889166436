
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'
import BaseForm from '@/components/common/BaseForm.vue'
import AvatarUploadForm from '@/components/common/AvatarUploadForm.vue'

import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard'
import { CommonContext } from '@/store/GlobalStore'
import store from '@/store/index'
import { Profile } from '@/models/core/profile'
import TokenList from './TokenList.vue'

import { adminRouteName } from '../../app'

@Component({
  name: 'user-form',
  components: {
    'token-list': TokenList,
    'base-form': BaseForm,
    AvatarUploadForm,
  },
  props: {
    id: {
      default: '',
    },
    kind: {
      default: 'human',
    },
  },
  data() {
    return {
      user: Profile,
      loading: true,
      originalAvatar: null,
    }
  },
  computed: {
    ...mapState('global', ['context']),
  },
  mixins: [BeforeLeaveGuard],
  methods: {
    adminRouteName,
  },
})
export default class UserForm extends Vue {
  $refs: {
    baseForm: BaseForm
    uploadForm: AvatarUploadForm
  }
  context: CommonContext
  user: Profile
  loading: boolean = false
  canAddToken: boolean = false
  repeatPassword: string = ''
  originalAvatar: string

  mounted() {
    this.loading = true

    this.$api.get('profile', this.profileId).then((response) => {
      this.originalAvatar = response.avatar
      this.user = response
      if (this.profileId === '0') {
        this.user.kind = this.$props.kind
      }
      this.loading = false
      this.canAddToken = response._permissions.add_token
    })
  }

  get profileId() {
    if (this.$props.id === '') {
      // when we are entering the component via 'user-profile' route which does not have id parameter
      return this.$store.state.global.profileId
    } else {
      return this.$props.id
    }
  }

  refreshAvatar() {
    if (this.profileId === store.state.global.profileId) {
      this.$api.get('profile', this.profileId).then((response) => {
        store.commit('global/setProfile', response)
      })
    }
  }

  get transformedUser() {
    if (this.profileId === '0') {
      this.user.organisation = this.context.selection.organisation.id
    }
    if (this.$refs.uploadForm !== undefined) {
      if (this.$refs.uploadForm.avatar) {
        this.user.avatar = this.$refs.uploadForm.avatar
      }
    }
    return this.user
  }

  kindChanged(kind: string) {
    if (this.isMachine) {
      this.user.email = ''
      this.user.first_name = ''
      this.user.last_name = ''
    }
  }

  get isOwnProfile() {
    return this.profileId === this.$store.state.global.profileId
  }

  get canResetPin() {
    return (
      this.profileId !== '0' &&
      !this.isMachine &&
      !this.isOwnProfile &&
      this.user._permissions &&
      this.user._permissions.reset_pin_counter
    )
  }

  get canUpdate2fa() {
    return (
      !this.isMachine &&
      !this.isOwnProfile &&
      (this.profileId === '0' ||
        (this.user._permissions && this.user._permissions.update_2fa))
    )
  }

  get isMachine() {
    return this.user.kind === 'machine'
  }

  resetPinCounters(user: Profile) {
    this.$api
      .resetPinCounters(this.user)
      .then((response) => {
        this.$buefy.toast.open({
          message: 'Successfully reset!',
          type: 'is-success',
        })
      })
      .catch((error) => {
        this.$refs.baseForm.handleError(error)
      })
  }

  get completedLocation() {
    if (this.$route.params.app_handle) {
      return {
        name: adminRouteName('profile-list'),
      }
    } else {
      return '/'
    }
  }
}

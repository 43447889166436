import Vue from 'vue'
import {
  TransientBaseObject,
  TRANSIENT_BASE_OBJECT_DEFAULT,
  ModelField,
  ListModelField,
  Ancestor,
} from '@/models/core/base'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import { Project } from '@/apps/brelag/common/models/project'
import { DeviceAssignment } from '@/apps/brelag/common/models/equipment'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'

export class KnockautProject extends TransientBaseObject {
  project: string
  title: string
  meta: Record<string, any>
  remote_access_link: string
  longitude: number
  latitude: number
  fusion_one: string
  api_key: string
  username: string | null
  password: string | null
  symos_password: string
  license_key: string | null
  tuya_user_id: string
  azure_function_token: string
  is_backup_enabled: boolean

  static apiUrl = 'brelag/knockaut-project'
  static langPath = 'apps.brelag.models.knockaut.knockautproject'
  static objectType = 'knockautproject'
  static useApiClientV2 = true
  static fields: ModelField[] = [
    {
      key: 'project',
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        relatedObjectProperty: 'title',
        modelClass: Project.objectType,
      },
    },
    {
      key: 'title',
    },
  ]

  static listFields: ListModelField[] = [
    { key: 'title' },
    { key: 'project_title' },
    { key: 'fusion_one_device_number' },
  ]

  public static ancestors: Ancestor[] = [
    {
      modelClass: Project,
      mainParent: true,
    },
  ]

  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static joins: Collection.RelatedAnnotation<KnockautProject>[] = [
    {
      relatedModelClass: DeviceAssignment,
      relatedObjectType: 'fusion_one',
      relatedObjectProperty: 'device_number',
    },
    {
      relatedModelClass: Project,
      relatedObjectProperty: 'title',
    },
  ]

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: KnockautProject.formFields(),
      model: { ...KNOCKAUT_PROJECT_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(vm: Vue, filter, pagination) {
    return vm.$apiv2.subscribe<KnockautProject, KnockautProject>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static installModule(
    apiClient: ApiClientV2,
    knockautProject: string,
    module: string
  ): Promise<boolean> {
    return apiClient.customPost(
      `brelag/knockaut-project/${knockautProject}/install-module`,
      {
        module: module,
      }
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(KNOCKAUT_PROJECT_DEFAULT))
  }

  /**
   * Gets info of knockaut project
   * @param vm
   * @param id
   */
  static async getInfo(apiClient: ApiClientV2): Promise<{
    fusion_one_equipment_id: string
    fusion_one_equipment_variant_id: string
  }> {
    return apiClient.customGet(`brelag/knockaut-project/info`)
  }

  static async syncTuyaId(
    apiClient: ApiClientV2,
    knockautProject: string,
    email: string,
    password: string,
    country_code: number = 41
  ) {
    return apiClient.customPost(
      `brelag/knockaut-project/${knockautProject}/sync-tuya-uid`,
      {
        email,
        password,
        country_code,
      }
    )
  }

  static async changeRemoteAccessPassword(
    apiClient: ApiClientV2,
    knockautProject: string,
    oldPassword: string,
    newPassword: string
  ) {
    return apiClient.customPost(
      `brelag/knockaut-project/${knockautProject}/change-remote-access-password`,
      {
        old_password: oldPassword,
        new_password: newPassword,
      }
    )
  }

  static async changeSymOsPassword(
    apiClient: ApiClientV2,
    knockautProject: string,
    oldPassword: string,
    newPassword: string
  ) {
    return apiClient.customPost(
      `brelag/knockaut-project/${knockautProject}/change-symos-password`,
      {
        old_password: oldPassword,
        new_password: newPassword,
      }
    )
  }

  public static unsubscribeConnectAndCareReminder(
    apiClient: ApiClientV2,
    knockautProject: string
  ): Promise<{ success: boolean }> {
    return apiClient.customPost(
      `brelag/knockaut-project/${knockautProject}/unsubscribe-connect-and-care-reminder`
    )
  }
}

export const KNOCKAUT_PROJECT_DEFAULT: KnockautProject = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  project: '',
  title: '',
  meta: {},
  remote_access_link: '',
  fusion_one: '',
  api_key: '',
  latitude: null,
  longitude: null,
  username: '',
  password: '',
  symos_password: '',
  license_key: '',
  tuya_user_id: '',
  azure_function_token: '',
  is_backup_enabled: false,
}

export interface KnockautProjectPatchSerializer {
  id: string
  title?: string
  meta?: any
  username?: string
  password?: string
  symos_password?: string
  remote_access_link?: string
  license_key?: string
}

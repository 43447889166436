import uuidv1 from 'uuid'
import { CreateDeviceSerializer } from '@/apps/brelag/mandator-user/models/editor'
import {
  EquipmentCategory,
  DeviceAssignment,
  Equipment,
  BrelagConfigKey,
} from '@/apps/brelag/common/models/equipment'
import { ProjectDump } from '@/apps/brelag/common/models/project'
import { EquipmentHandler } from '@/apps/brelag/mandator-user/project-editor/equipmentHandler'
import Vue from 'vue'
import { ApiClientV2, apiClientV2 } from '@/api/ApiClientV2'
import { Link, LinkOptions } from '@/apps/brelag/common/models/link'
import { simpleCopy } from '@/util/util'
import {
  getDataIdeGateConfig,
  EGATE_CONFIG_DEFAULT,
} from '@/apps/brelag/common/models/eGateConfig'
import {
  getDataIdMaxflexConfig,
  MAXFLEX_DEFAULT_CONFIG,
  MaxFlexConfig,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import {
  getDataIdSwwConfiguration,
  SWW_DEFAULT_CONFIG,
} from '@/apps/brelag/common/models/swwParams'
import { ProjectEditor } from './projectEditor'
import { getDefaultSvgAttributes } from './floorPlanDrawUtil'

interface IDevices {
  [EquipmentCategory.SENDER]: Map<string, DeviceAssignment>
  [EquipmentCategory.RECEIVER]: Map<string, DeviceAssignment>
}

const validValueCheck = (value) => {
  if (value === null) {
    throw Error(`Configuration has null value`)
  }
  if (!Number.isInteger(value)) {
    throw Error(`Configuration has non-integer value ${value}`)
  }
}

export class DeviceHandler {
  private _linksArray: Link[]
  private _linksIdToLinkMap: Map<string, Link> = new Map()
  private _linkToIdMap: Map<string, string> = new Map()
  linksMap: Map<string, Map<number, Set<string>>> = new Map()

  private _devices: IDevices = {
    [EquipmentCategory.SENDER]: new Map<string, DeviceAssignment>(),
    [EquipmentCategory.RECEIVER]: new Map<string, DeviceAssignment>(),
  }
  private _apiClient: ApiClientV2
  private _senderList: DeviceAssignment[] = []
  private _receiverList: DeviceAssignment[] = []
  private _projectId: string

  private _eGates: DeviceAssignment[] = []
  public get eGates(): DeviceAssignment[] {
    return this._eGates
  }

  private _equipmentHandler: EquipmentHandler

  constructor(equipmentHandler: EquipmentHandler) {
    this._equipmentHandler = equipmentHandler
    this._apiClient = apiClientV2
  }

  /**
   * Load project
   * @param project
   */
  async loadDeviceHandlerProject(project: ProjectDump) {
    this._projectId = project.id

    // Clear everything
    this.linksMap.clear()
    this._linksIdToLinkMap.clear()
    this._linkToIdMap.clear()
    this._linksArray = []
    this._devices[EquipmentCategory.SENDER].clear()
    this._devices[EquipmentCategory.RECEIVER].clear()
    this.receivers.clear()
    this.senders.clear()
    this._senderList.splice(0, this._senderList.length)
    this._receiverList.splice(0, this._receiverList.length)
    this._eGates.splice(0, this._eGates.length)

    // First, add all sender and receivers
    project.device_assignments.forEach((assignment) => {
      if (assignment.category === EquipmentCategory.RECEIVER) {
        this.receivers.set(assignment.id, assignment)
        this._receiverList.push(assignment)
      } else {
        this.senders.set(assignment.id, assignment)
        this._senderList.push(assignment)
      }

      // Check for eGates
      if (this._equipmentHandler.isEgate(assignment.equipment)) {
        this._eGates.push(assignment)
      }
    })
    // sort now so we don't have to sort later
    this.sortLists()

    // load all links and link helpers (A link is unique as sender-receiver-channel relation)
    if (project.links_internal && project.links_internal.links) {
      this._linksArray = project.links_internal.links
      const length = this._linksArray.length
      for (let i = 0; i < length; i++) {
        this._addLink(
          this._linksArray[i].sender,
          this._linksArray[i].receiver,
          this._linksArray[i]
        )
      }
    }
  }

  get senderList(): DeviceAssignment[] {
    return this._senderList
  }

  get receiverList(): DeviceAssignment[] {
    return this._receiverList
  }

  get deviceList(): DeviceAssignment[] {
    return this.senderList.concat(this.receiverList)
  }

  get links(): Link[] {
    return this._linksArray
  }

  public sortLists() {
    this._senderList = ProjectEditor.instance.sortDevices(this._senderList)
    this._receiverList = ProjectEditor.instance.sortDevices(this._receiverList)
  }

  /**
   * Returns copy of DeviceAssignment
   * @param id
   * @param category
   * @param copy whether to return a copy or object directly
   */
  public getDeviceAssignmentById(
    id: string,
    category?: EquipmentCategory,
    copy = true
  ): DeviceAssignment | null {
    if (category) {
      return this._devices[category].get(id)
    } else {
      if (this._devices[EquipmentCategory.RECEIVER].has(id)) {
        if (copy) {
          return DeviceAssignment.safeCopy(
            this._devices[EquipmentCategory.RECEIVER].get(id)
          )
        } else {
          return this._devices[EquipmentCategory.RECEIVER].get(id)
        }
      } else if (this._devices[EquipmentCategory.SENDER].has(id)) {
        if (copy) {
          return DeviceAssignment.safeCopy(
            this._devices[EquipmentCategory.SENDER].get(id)
          )
        } else {
          return this._devices[EquipmentCategory.SENDER].get(id)
        }
      } else {
        return null
      }
    }
  }

  public isMaxFlex(id: string): boolean {
    const device = this.getDeviceAssignmentById(id, null, false)
    if (device) {
      const equipment = this._equipmentHandler.getEquipmentFromDevice(device)
      const equipmentConfigDict = Equipment.getFieldsWithDataID(equipment)
      return equipmentConfigDict.hasOwnProperty(BrelagConfigKey.MAXFLEX)
    } else {
      return false
    }
  }

  public isMaxFlexByEquipmentVariant(variantId: string): boolean {
    const equipment =
      this._equipmentHandler.getEquipmentFromVariantId(variantId)
    const equipmentConfigDict = Equipment.getFieldsWithDataID(equipment)
    return equipmentConfigDict.hasOwnProperty(BrelagConfigKey.MAXFLEX)
  }

  public isSww(device: DeviceAssignment): boolean {
    if (device) {
      const equipment = this._equipmentHandler.getEquipmentFromDevice(device)
      const equipmentConfigDict = Equipment.getFieldsWithDataID(equipment)
      return equipmentConfigDict.hasOwnProperty(BrelagConfigKey.SWW)
    } else {
      return false
    }
  }

  public get senders(): Map<string, DeviceAssignment> {
    return this._devices[EquipmentCategory.SENDER] as Map<
      string,
      DeviceAssignment
    >
  }

  public get receivers(): Map<string, DeviceAssignment> {
    return this._devices[EquipmentCategory.RECEIVER] as Map<
      string,
      DeviceAssignment
    >
  }

  /**
   * Remove device
   * @param id
   */
  async deleteInternalDevice(device: DeviceAssignment): Promise<void> {
    const id = device.id

    // Remove the device from all lists and maps
    if (this.senders.has(id)) {
      this.senders.delete(id)
    } else if (this.receivers.has(id)) {
      this.receivers.delete(id)
    }
    if (this._devices[EquipmentCategory.SENDER].has(id)) {
      this._devices[EquipmentCategory.SENDER].delete(id)
    }
    if (this._devices[EquipmentCategory.RECEIVER].has(id)) {
      this._devices[EquipmentCategory.RECEIVER].delete(id)
    }
    let ind = this._receiverList.findIndex((dev) => dev.id === id)
    if (ind > -1) {
      this._receiverList.splice(ind, 1)
    }
    ind = this._senderList.findIndex((dev) => dev.id === id)
    if (ind > -1) {
      this._senderList.splice(ind, 1)
    }

    // Check for eGates
    if (this._equipmentHandler.isEgate(device.equipment)) {
      const ind = this._eGates.findIndex((dev) => dev.id === device.id)
      if (ind > -1) {
        this._eGates.splice(ind, 1)
      }
    }

    // Delete links of the device
    let links: Link[] = []
    if (device.category === EquipmentCategory.SENDER) {
      links = this.findLinks(device.id)
    } else {
      links = this.findLinks(undefined, device.id)
    }
    await this.deleteLinks(links)
  }

  /**
   * Update device
   */
  public async updateDevice(
    updatedDevice: DeviceAssignment
  ): Promise<DeviceAssignment> {
    // Do not send these values, as they might be done by a user the current user has no "view" permission for
    delete updatedDevice.last_configured_by
    delete updatedDevice.recognized_by

    // Update server object
    updatedDevice = await this._apiClient.update<
      DeviceAssignment,
      DeviceAssignment
    >(DeviceAssignment, updatedDevice)

    // Update internal object
    this.updateInternalDevice(updatedDevice)

    return updatedDevice
  }

  /**
   * Update internal device
   */
  public updateInternalDevice(updatedDevice: DeviceAssignment): void {
    const id = updatedDevice.id
    if (updatedDevice.category === EquipmentCategory.SENDER) {
      this._devices[EquipmentCategory.SENDER].set(id, updatedDevice)
    } else if (updatedDevice.category === EquipmentCategory.RECEIVER) {
      this._devices[EquipmentCategory.RECEIVER].set(id, updatedDevice)
    } else {
      throw new Error(`Invalid device category ${updatedDevice.category}`)
    }

    if (updatedDevice.category === EquipmentCategory.RECEIVER) {
      const ind = this._receiverList.findIndex((dev) => dev.id === id)
      if (ind > -1) {
        Vue.set(this._receiverList, ind, updatedDevice)
      }
    } else if (updatedDevice.category === EquipmentCategory.SENDER) {
      const ind = this._senderList.findIndex((dev) => dev.id === id)
      if (ind > -1) {
        Vue.set(this._senderList, ind, updatedDevice)
      }
    }
  }

  /**
   * Returns all devices that have sensors
   */
  public getSensors(): DeviceAssignment[] {
    const sensors: DeviceAssignment[] = []
    for (const sender of this._senderList) {
      const equipment = this._equipmentHandler.getEquipmentFromVariantId(
        sender.equipment_variant
      )
      if (equipment.has_sensors) {
        sensors.push(sender)
      }
    }
    return sensors
  }

  /**
   * Returns all devices of a category in floor
   */
  public getDevicesInFloor(
    floorId: string,
    category?: EquipmentCategory
  ): DeviceAssignment[] {
    let devicesInFloor: DeviceAssignment[]
    if (category) {
      devicesInFloor = Array.from(
        this._devices[category].values() as DeviceAssignment[]
      )
    } else {
      // Both categories
      devicesInFloor = Array.from(
        this._devices[EquipmentCategory.RECEIVER].values()
      )
      devicesInFloor = devicesInFloor.concat(
        Array.from(this._devices[EquipmentCategory.SENDER].values())
      )
    }

    return devicesInFloor
      .filter((device: DeviceAssignment) => device.floor === floorId)
      .sort((a: DeviceAssignment, b: DeviceAssignment) => a.number - b.number)
  }

  /**
   * Creates new DeviceAssignments
   * @param model
   */
  async createDevices(
    model: CreateDeviceSerializer,
    defaultConfig?: {
      [key: string]: any
    },
    selectedDeviceId?: string
  ): Promise<DeviceAssignment[]> {
    /** PREPARE **/

    // First we check if all start numbers are available
    const category = this._equipmentHandler.getCategoryFromVariant(
      model.equipment_variant
    )
    const equipment = this._equipmentHandler.getEquipmentFromVariantId(
      model.equipment_variant
    )
    model.dirty = true
    model.dirty_optional = true
    model.dirty_teaching = true
    if (equipment.config_editor_layout.model) {
      model.config = equipment.config_editor_layout.model
    } else {
      model.config = {}
    }

    for (const key of Object.keys(model.config)) {
      // Special cases
      if (key === BrelagConfigKey.EGATE) {
        model.config[BrelagConfigKey.EGATE] = simpleCopy(EGATE_CONFIG_DEFAULT)
      } else if (key === BrelagConfigKey.MAXFLEX) {
        model.config[BrelagConfigKey.MAXFLEX] = simpleCopy(
          MAXFLEX_DEFAULT_CONFIG
        )
      } else if (key === BrelagConfigKey.SWW) {
        if (equipment.config_editor_layout.model[BrelagConfigKey.SWW]) {
          model.config[BrelagConfigKey.SWW] = simpleCopy(
            equipment.config_editor_layout.model[BrelagConfigKey.SWW]
          )
        } else {
          model.config[BrelagConfigKey.SWW] = simpleCopy(SWW_DEFAULT_CONFIG)
        }
      }
    }

    // If additional config is provided, use that
    if (defaultConfig) {
      model.config = {
        ...model.config,
        ...defaultConfig,
      }
    }

    // If we're copying an existing device, use that copy
    if (model.copySelectedDevice) {
      if (!selectedDeviceId) {
        throw new Error('Kein Gerät ausgewählt.')
      }
      if (category === EquipmentCategory.RECEIVER) {
        throw new Error('Nur Sender können kopiert werden.')
      }
      const deviceToCopy = this.getDeviceAssignmentById(selectedDeviceId)
      model.config = {
        ...model.config,
        ...deviceToCopy.config,
      }
      model.meta.svgAttributes = {
        ...model.meta.svgAttributes,
        ...deviceToCopy.meta.svgAttributes,
      }
    } else {
      // Use default svg attributes
      if (
        equipment.category === EquipmentCategory.SENDER &&
        model.meta.svgAttributes.linksDisplay === undefined
      ) {
        model.meta.svgAttributes.linksDisplay = true
      }
      model.meta.svgAttributes = {
        ...model.meta.svgAttributes,
        ...getDefaultSvgAttributes(equipment),
      }
    }

    const { configuration, configurationOptional, dirty, dirty_optional } =
      this.parseConfigWithDataId(model.config, equipment, null)

    model.programmer_device_configuration = {
      data: configuration,
    }
    model.programmer_device_configuration_optional = {
      data: configurationOptional,
    }

    const deviceNrsInPlan: number[] = Array.from(
      this._devices[category].values()
    )
      .filter((device) => (device as DeviceAssignment).floor === model.floor)
      .map((device: DeviceAssignment) => device.number)
      .sort()

    let startNr
    const numbers = []
    if (model.number) {
      startNr = model.number
    } else {
      // Get next lowest number (without filling up gaps)
      startNr = 0
      deviceNrsInPlan.forEach((nr) => {
        if (nr > startNr) {
          startNr = nr
        }
      })
      startNr += 1
    }
    // insert only possible when adding single device
    if (model.num_devices > 1 || !model.insert_number_if_occupied) {
      model.insert_number_if_occupied = false
    }
    for (let i = 0; i < model.num_devices; i++) {
      const nr = parseInt(startNr) + i
      if (
        deviceNrsInPlan.findIndex((a) => a === nr) > -1 &&
        !model.insert_number_if_occupied
      ) {
        throw new Error(`Gerät mit Nummer ${nr} existiert bereits.`)
      } else {
        numbers.push(nr)
      }
    }
    /* END PREPARE */

    /** START CREATE  **/
    let devices: DeviceAssignment[] = []
    if (model.insert_number_if_occupied) {
      // no transaction if insert_number_if_occupied activated
      const modelCopy: CreateDeviceSerializer = simpleCopy(model)
      delete modelCopy.num_devices
      const response = await apiClientV2.create<
        CreateDeviceSerializer,
        DeviceAssignment
      >(DeviceAssignment, modelCopy)
      devices.push(response)
    } else {
      model.number = numbers[0]
      const response = await DeviceAssignment.batchCreate(apiClientV2, model)
      devices = response
    }

    // Update the internal state
    for (const device of devices) {
      this.createInternalDevice(device)
    }

    if (model.copySelectedDevice && model.copyLinks) {
      // Create transaction
      const links = this.findLinks(selectedDeviceId)

      // Copy links of selected sender as well
      if (category === EquipmentCategory.RECEIVER) {
        throw new Error('Nur Sender können kopiert werden.')
      }
      for (const device of devices) {
        const newLinks: Link[] = []
        for (const link of links) {
          const linkData: Link = simpleCopy(link)
          linkData.sender = device.id
          newLinks.push(linkData)
        }

        await this.createLinks(newLinks)
      }
    }

    return devices
  }

  public prepareLinkConfiguration(
    link: Link,
    options: {
      checkStorageIdUnique?: boolean
      equipment?: Equipment
      legacyProject?: boolean
    } = {
      checkStorageIdUnique: true,
      equipment: null,
      legacyProject: false,
    }
  ): Link {
    let equipment: Equipment
    let receiver: DeviceAssignment
    if (options.equipment) {
      equipment = options.equipment
    } else {
      // Parse options
      receiver = this.getDeviceAssignmentById(
        link.receiver,
        EquipmentCategory.RECEIVER,
        false
      )

      if (!receiver) {
        throw new Error(`Receiver with id ${link.receiver} not found.`)
      }

      equipment = this._equipmentHandler.getEquipmentFromDevice(receiver)
    }
    const teachingOptionsDict =
      Equipment.getTeachingOptionsWithDataID(equipment)

    if (!link.options) {
      link.options = {}
    }

    for (const key of Object.keys(teachingOptionsDict)) {
      if (!link.options.hasOwnProperty(key)) {
        link.options[key] = simpleCopy(equipment.teaching_options.model[key])
      }

      // keyStorageId of eGate must be unique
      if (key === 'keyStorageId' && options.checkStorageIdUnique) {
        const links = this.findLinks(undefined, link.receiver)
        for (const _link of links) {
          if (
            _link.options.keyStorageId === link.options.keyStorageId &&
            _link.id !== link.id
          ) {
            const storageId = this.findMinStorageId(receiver)
            throw new Error(
              `eGate Speicherplatz mit ID ${link.options.keyStorageId} ist bereits besetzt. Nächster freier Speicherplatz: ${storageId}`
            )
          }
        }
      }

      // If we assign a receiver to a virtual sender
      // set 'keyVirtual' to 1, otherwise set to 0
      if (key === 'keyVirtual' && !options.legacyProject) {
        const sender = this.getDeviceAssignmentById(
          link.sender,
          EquipmentCategory.SENDER,
          false
        )
        const senderEquipment =
          this._equipmentHandler.getEquipmentFromDevice(sender)
        if (senderEquipment.is_virtual) {
          link.options[key] = 1
          // Internally channel is always set to 7
          link.sender_channel = 7
        } else {
          link.options[key] = 0
        }
      }
    }

    // Delete keys that are not supposed to be there (anymore)
    for (const key of Object.keys(link.options)) {
      if (!teachingOptionsDict.hasOwnProperty(key)) {
        if (key !== 'groups') {
          delete link.options[key]
        }
      }
    }

    link.options.configuration = this.createLinkConfiguration(link, equipment)
    return link
  }

  /**
   * Creates the link configuration as needed by DLL in WinApp
   */
  public createLinkConfiguration(link: Link, equipment: Equipment): number[] {
    const dataIdValueList: {
      dataID: number
      value?: number | string | boolean | null
    }[] = []
    let maxDataId = 0

    const teachingOptionsDict =
      Equipment.getTeachingOptionsWithDataID(equipment)

    for (const key of Object.keys(teachingOptionsDict)) {
      const dataID = teachingOptionsDict[key].properties.extraData.dataID
      let value
      if (link.options[key] === true) {
        value = 1
      } else if (link.options[key] === false) {
        value = 0
      } else {
        value = parseInt(link.options[key])
      }

      if (value === null || value === undefined || isNaN(value)) {
        value = 0
      }
      if (dataID > maxDataId) {
        maxDataId = dataID
      }
      dataIdValueList.push({
        dataID,
        value,
      })
    }

    const configuration = new Array(maxDataId).fill(0)
    for (const config of dataIdValueList) {
      if (config.value === true) {
        configuration[config.dataID - 1] = 1
      } else if (config.value === false) {
        configuration[config.dataID - 1] = 0
      } else {
        configuration[config.dataID - 1] = parseInt(config.value as string)
      }
    }

    // Double check that only integers and no null values
    for (const value of configuration) {
      validValueCheck(value)
    }

    return configuration
  }

  private _getReceiverCapacity(receiver: DeviceAssignment) {
    const receiverEquipment =
      this._equipmentHandler.getEquipmentFromDevice(receiver)
    const links = this.findLinks(undefined, receiver.id)
    return receiverEquipment.tx_capacity - links.length
  }

  /**
   * Creates links between Sender and Receiver on a specific channel
   * @param linkData
   * @param apiClient api client if request should be done in transaction
   */
  async createLinks(links: Link[]): Promise<void> {
    if (links.length === 0) {
      return
    }

    const newLinks: Link[] = []
    const newLinksPerReceiver: Map<string, number> = new Map()
    const dirtyReceiverIds = new Set<string>()
    for (const _link of links) {
      _link.id = uuidv1()
      newLinks.push(this.prepareLinkConfiguration(_link))
      const receiver = this.getDeviceAssignmentById(
        _link.receiver,
        EquipmentCategory.RECEIVER,
        false
      )
      const sender = this.getDeviceAssignmentById(
        _link.sender,
        EquipmentCategory.SENDER,
        false
      )
      if (receiver.category !== EquipmentCategory.RECEIVER) {
        throw new Error('Empfängerauswahl ist nicht vom Typ "Empfänger"')
      }
      if (sender.category !== EquipmentCategory.SENDER) {
        throw new Error('Senderauswahl ist nicht vom Typ "Sender"')
      }
      // Check that valid sender channel
      this._equipmentHandler.checkChannelValid(sender, _link.sender_channel)

      // Gather number of links that will be added per receiver
      if (newLinksPerReceiver.has(_link.receiver)) {
        newLinksPerReceiver.set(
          _link.receiver,
          newLinksPerReceiver.get(_link.receiver) + 1
        )
      } else {
        newLinksPerReceiver.set(_link.receiver, 1)
      }
      // add receivers to be marked as dirty
      dirtyReceiverIds.add(_link.receiver)
    }
    // Check that receiver have enough capacity
    newLinksPerReceiver.forEach((value, key, map) => {
      const receiver = this.getDeviceAssignmentById(
        key,
        EquipmentCategory.RECEIVER,
        false
      )
      const capacity = this._getReceiverCapacity(receiver)
      if (capacity < value) {
        throw new Error(
          `Empfänger ${receiver.device_number} hat nicht genügend Platz.`
        )
      }
    })

    await Link.createLinks(apiClientV2, this._projectId, newLinks)

    this._linksArray = [...this._linksArray, ...newLinks]
    for (const _link of newLinks) {
      this._addLink(_link.sender, _link.receiver, _link)
    }
    for (const receiver of dirtyReceiverIds) {
      this.setDeviceDirtyTeaching(receiver)
    }
  }

  /**
   * Deletes list of links
   * @param links List of links that should be deleted
   */
  async deleteLinks(links: Link[]): Promise<void> {
    if (links.length === 0) {
      return
    }

    await Link.deleteLinks(apiClientV2, this._projectId, links)

    // Delete internal link
    const dirtyReceiverIds = new Set<string>()
    for (const link of links) {
      // delete from linksarray
      const idx = this._linksArray.findIndex((_link) => _link.id === link.id)
      if (idx > -1) {
        this._linksArray.splice(idx, 1)
      }
      // delete from maps
      const senderId = link.sender
      const receiverId = link.receiver
      const channel = link.sender_channel
      try {
        this.linksMap.get(senderId).get(channel).delete(receiverId)
      } catch (error) {
        // Ignore for now, linksMap should be removed
        console.warn(error)
      }
      this._linksIdToLinkMap.delete(link.id)
      this._linkToIdMap.delete(`${senderId}_${receiverId}_${channel}`)
      dirtyReceiverIds.add(link.receiver)
    }

    for (const receiverId of dirtyReceiverIds) {
      this.setDeviceDirtyTeaching(receiverId)
    }
  }

  async updateLinkOptions(
    linksToUpdate: {
      id: string
      options: LinkOptions
    }[]
  ): Promise<void> {
    if (linksToUpdate.length === 0) {
      return
    }
    const dirtyReceiverIds = new Set<string>()

    const updatedLinks: Link[] = []
    for (const updateLink of linksToUpdate) {
      let link = this.getLink(updateLink.id)
      link.options = updateLink.options
      link = this.prepareLinkConfiguration(link)

      dirtyReceiverIds.add(link.receiver)
      updatedLinks.push(link)
    }

    await Link.updateLinks(apiClientV2, this._projectId, updatedLinks)

    for (const updatedLink of updatedLinks) {
      const idx = this._linksArray.findIndex(
        (_link) => _link.id === updatedLink.id
      )
      if (idx !== -1) {
        this._linksArray[idx] = updatedLink
      }
    }

    for (const receiverId of dirtyReceiverIds) {
      this.setDeviceDirtyTeaching(receiverId)
    }
  }

  /**
   * Adds a new connection between sender and receiver on specified channel
   * @param senderId DeviceAssignment Sender id
   * @param receiverId DeviceAssignment Receiver id
   * @param link Link
   */
  private _addLink(senderId: string, receiverId: string, link: Link): void {
    this._linksIdToLinkMap.set(link.id, link)
    this._linkToIdMap.set(
      `${senderId}_${receiverId}_${link.sender_channel}`,
      link.id
    )

    // Validate data
    const channel = link.sender_channel

    // Everything ok, we can add the link
    const channels = this.linksMap.get(senderId)
    if (channels === undefined) {
      this.linksMap.set(senderId, new Map([[channel, new Set([receiverId])]]))
    } else if (channels.get(channel) === undefined) {
      this.linksMap.get(senderId).set(channel, new Set([receiverId]))
    } else {
      this.linksMap.get(senderId).get(channel).add(receiverId)
    }
  }

  /**
   * Checks if a link exists
   */
  linkExists(senderId: string, receiverId: string, channel: number): boolean {
    return (
      this.linksMap.get(senderId) &&
      this.linksMap.get(senderId).get(channel) &&
      this.linksMap.get(senderId).get(channel).has(receiverId)
    )
  }

  /**
   * Updates dirty property of device
   * @param dirty
   */
  async setDeviceDirty(deviceId: string, dirty: boolean = true): Promise<void> {
    const device = this.getDeviceAssignmentById(deviceId, null)
    if (device && device.dirty === !dirty) {
      device.dirty = dirty
      await this.updateDevice(device)
    }
  }

  async setDeviceDirtyAll(
    deviceId: string,
    dirty: boolean = true
  ): Promise<void> {
    const device = this.getDeviceAssignmentById(deviceId, null)
    if (device) {
      device.dirty = dirty
      device.dirty_optional = dirty
      device.dirty_teaching = dirty
      await this.updateDevice(device)
    }
  }

  /**
   * Updates dirty_teaching property of device
   * @param deviceId ID of Device
   * @param dirty_teaching value to be set for teaching flag
   * @returns returns true if device was updated, false otherwise (e.g. was already in correct state)
   */
  setDeviceDirtyTeaching(
    deviceId: string,
    dirty_teaching: boolean = true
  ): boolean {
    const device = this.getDeviceAssignmentById(deviceId, null)
    if (device && device.dirty_teaching === !dirty_teaching) {
      device.dirty_teaching = dirty_teaching
      // The device is udpated on the backend automatically, just update the internal state
      this.updateInternalDevice(device)
      return true
    }
    return false
  }

  /**
   * Search for links
   * @param senderId
   * @param receiverId
   * @param channel
   * @param repeatingOnly: If true, will only return links that are repeatingOnly set to true.
   *                       If false, will only return links that are repeatingOnly set to false OR don't have the property at all
   *                       If undefined, will return all
   */
  findLinks(
    senderId?: string,
    receiverId?: string,
    channel?: number,
    repeatingOnly?: boolean
  ): Link[] {
    const length = this._linksArray.length
    const result = []
    // for loop seems to be faster than Array.filter
    for (let i = 0; i < length; i++) {
      if (
        (senderId === undefined || this._linksArray[i].sender === senderId) &&
        (receiverId === undefined ||
          this._linksArray[i].receiver === receiverId) &&
        (channel === undefined ||
          this._linksArray[i].sender_channel === channel)
      ) {
        const link = this._linksArray[i]
        if (repeatingOnly === undefined) {
          result.push(simpleCopy(link))
        } else if (repeatingOnly === Link.isRepeatingOnly(link)) {
          result.push(simpleCopy(link))
        }
      }
    }
    return result
  }

  getLink(linkId: string): Link | undefined {
    return this._linksIdToLinkMap.get(linkId)
  }

  /**
   * Search for links
   * @param senderId
   * @param receiverId
   * @param channel
   */
  findLink(senderId: string, receiverId: string, channel: number): Link | null {
    if (senderId == null) {
      return null
    }
    const fastAccessIdentifier = `${senderId}_${receiverId}_${channel}`
    try {
      if (!this._linkToIdMap.has(fastAccessIdentifier)) {
        return null
      }
      const link = this._linksIdToLinkMap.get(
        this._linkToIdMap.get(fastAccessIdentifier)
      )
      if (link) {
        return simpleCopy(link)
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }

  /**
   * Returns all senders that are connected to a receiver
   */
  getConnectedSenders(receiverId: string): Set<string> {
    const senders = new Set<string>()
    this.linksMap.forEach((sender, senderId) => {
      let senderFound = false
      sender.forEach((receivers, channel) => {
        if (receivers.has(receiverId)) {
          senderFound = true
        }
      })
      if (senderFound) {
        senders.add(senderId)
      }
    })
    return senders
  }

  /**
   * Returns all receivers that don't belong to a sender in a channel
   */
  getUnlinkedReceivers(senderId: string, channel: number): Set<string> {
    if (
      !this.linksMap.get(senderId) ||
      !this.linksMap.get(senderId).get(channel)
    ) {
      return new Set()
    }
    const out = new Set<string>()
    for (const receiver of this._receiverList) {
      if (!this.linkExists(senderId, receiver.id, channel)) {
        out.add(receiver.id)
      }
    }
    return out
  }

  /**
   * Returns all receivers that belong to a sender in a channel
   */
  getLinkedReceivers(senderId: string, channel: number): Set<string> {
    if (
      !this.linksMap.get(senderId) ||
      !this.linksMap.get(senderId).get(channel)
    ) {
      return new Set()
    }
    return this.linksMap.get(senderId).get(channel)
  }

  /**
   * Returns all receivers that belong to a sender across all channels
   * @param senderId Sender id
   */
  getAllConnectedReceivers(senderId: string): Set<string> {
    const out = new Set<string>()
    if (!this.linksMap.get(senderId)) {
      return out
    }
    this.linksMap
      .get(senderId)
      .forEach((receivers, channel) => receivers.forEach(out.add, out))
    return out
  }

  /**
   * Parse config and create Configuration and ConfigurationOptional for Windows Programmer
   * @param deviceConfig Device config
   * @param deviceId If given, will compare to existing device, otherwise it's a new device
   */
  public parseConfigWithDataId(
    deviceConfig,
    equipment: Equipment,
    deviceId?: string
  ): {
    configuration: number[]
    configurationOptional: number[]
    dirty: boolean
    dirty_optional: boolean
    updateLinked: boolean
  } {
    let dataIdValueList: {
      dataID: number
      value: number | boolean | string
    }[] = []
    let dataIdValueListOptional: {
      dataID: number
      value: number | boolean | string
    }[] = []
    let maxDataId = 0
    let maxDataIdOptional = 0
    const equipmentConfigDict = Equipment.getFieldsWithDataID(equipment)

    for (const key of Object.keys(equipmentConfigDict)) {
      // Special cases
      if (key === BrelagConfigKey.EGATE) {
        if (!deviceConfig.hasOwnProperty(key)) {
          deviceConfig[key] = simpleCopy(EGATE_CONFIG_DEFAULT)
        }
        dataIdValueList = dataIdValueList.concat(
          getDataIdeGateConfig(deviceConfig[BrelagConfigKey.EGATE])
        )
        maxDataId = 6
      } else if (key === BrelagConfigKey.MAXFLEX) {
        if (!deviceConfig.hasOwnProperty(key)) {
          deviceConfig[key] = simpleCopy(MAXFLEX_DEFAULT_CONFIG)
        }
        dataIdValueList = dataIdValueList.concat(
          getDataIdMaxflexConfig(deviceConfig[BrelagConfigKey.MAXFLEX])
        )
        maxDataId = 196
      } else if (key === BrelagConfigKey.SWW) {
        if (
          equipmentConfigDict[key].properties &&
          equipmentConfigDict[key].properties.extraData.dependencyKey
        ) {
          const depKey =
            equipmentConfigDict[key].properties.extraData.dependencyKey
          if (!deviceConfig.hasOwnProperty(depKey) || !deviceConfig[depKey]) {
            continue
          }
        }
        if (!deviceConfig.hasOwnProperty(key)) {
          if (equipment.config_editor_layout.model[BrelagConfigKey.SWW]) {
            deviceConfig[key] = simpleCopy(
              equipment.config_editor_layout.model[BrelagConfigKey.SWW]
            )
          } else {
            deviceConfig[key] = simpleCopy(SWW_DEFAULT_CONFIG)
          }
        }

        dataIdValueListOptional = dataIdValueListOptional.concat(
          getDataIdSwwConfiguration(deviceConfig[BrelagConfigKey.SWW])
        )
        maxDataIdOptional = 161
      } else if (deviceConfig[key] && typeof deviceConfig[key] === 'string') {
        // String key
        const startDataID = equipmentConfigDict[key].properties.extraData.dataID
        // Maxlength needs to be present
        let maxLength = parseInt(equipmentConfigDict[key].properties.maxlength)
        if (!maxLength) {
          maxLength = deviceConfig[key].length
        }

        // If 'dependencyKey' key is present, it's an optional configuration
        if (equipmentConfigDict[key].properties.extraData.dependencyKey) {
          // Check if dependency is fulfilled
          const depKey =
            equipmentConfigDict[key].properties.extraData.dependencyKey
          if (deviceConfig.hasOwnProperty(depKey) && deviceConfig[depKey]) {
            for (let i = 0; i < maxLength; i++) {
              const dataID = startDataID + i
              if (dataID > maxDataIdOptional) {
                maxDataIdOptional = dataID
              }
              let value = deviceConfig[key].charCodeAt(i)
              if (value === null || value === undefined || isNaN(value)) {
                value = 0
              }
              dataIdValueListOptional.push({
                dataID,
                value,
              })
            }
          }
        } else {
          for (let i = 0; i < maxLength; i++) {
            const dataID = startDataID + i
            if (dataID > maxDataId) {
              maxDataId = dataID
            }
            let value = deviceConfig[key].charCodeAt(i)
            if (value === null || value === undefined || isNaN(value)) {
              value = 0
            }
            dataIdValueList.push({
              dataID,
              value,
            })
          }
        }
      } else {
        // Standard config key
        const dataID = equipmentConfigDict[key].properties.extraData.dataID
        let value
        if (!deviceConfig.hasOwnProperty(key)) {
          deviceConfig[key] = simpleCopy(
            equipment.config_editor_layout.model[key]
          )
        }

        // Make sure it's an int
        if (deviceConfig[key] === true) {
          value = 1
        } else if (deviceConfig[key] === false) {
          value = 0
        } else {
          value = parseInt(deviceConfig[key])
        }

        if (value === null || value === undefined || isNaN(value)) {
          value = 0
        }

        // If 'dependencyKey' key is present, it's an optional configuration
        if (equipmentConfigDict[key].properties.extraData.dependencyKey) {
          // Check if dependency is fulfilled
          const depKey =
            equipmentConfigDict[key].properties.extraData.dependencyKey
          if (deviceConfig.hasOwnProperty(depKey) && deviceConfig[depKey]) {
            if (dataID > maxDataIdOptional) {
              maxDataIdOptional = dataID
            }
            dataIdValueListOptional.push({
              dataID,
              value,
            })
          }
        } else {
          if (dataID > maxDataId) {
            maxDataId = dataID
          }
          dataIdValueList.push({
            dataID,
            value,
          })
        }
      }
    }

    for (const key of Object.keys(deviceConfig)) {
      if (!equipmentConfigDict.hasOwnProperty(key)) {
        delete deviceConfig[key]
      }
    }

    let original: DeviceAssignment
    let originalProgrammerConfig
    let originalProgrammerConfigOptional
    if (deviceId) {
      try {
        original = this.getDeviceAssignmentById(deviceId)
        if (original && original.programmer_device_configuration) {
          originalProgrammerConfig =
            original.programmer_device_configuration.data
        }
        if (original && original.programmer_device_configuration_optional) {
          originalProgrammerConfigOptional =
            original.programmer_device_configuration_optional.data
        }
      } catch (err) {
        // Ignore
      }
    }

    const configuration = new Array(maxDataId).fill(0)
    const configurationOptional = new Array(maxDataIdOptional).fill(0)
    for (const config of dataIdValueList) {
      if (config.value === true) {
        configuration[config.dataID - 1] = 1
      } else if (config.value === false) {
        configuration[config.dataID - 1] = 0
      } else {
        configuration[config.dataID - 1] = parseInt(config.value as string)
      }
    }
    for (const config of dataIdValueListOptional) {
      if (config.value === true) {
        configurationOptional[config.dataID - 1] = 1
      } else if (config.value === false) {
        configurationOptional[config.dataID - 1] = 0
      } else {
        configurationOptional[config.dataID - 1] = parseInt(
          config.value as string
        )
      }
    }

    // Check if configuration changed
    let dirty = false
    let dirty_optional = false
    if (originalProgrammerConfig) {
      if (
        JSON.stringify(originalProgrammerConfig) !==
        JSON.stringify(configuration)
      ) {
        dirty = true
      }
    }
    // TODO
    if (originalProgrammerConfigOptional) {
      if (
        JSON.stringify(originalProgrammerConfigOptional) !==
        JSON.stringify(configurationOptional)
      ) {
        dirty_optional = true
      }
    }

    // Double check that only integers and no null values
    for (const value of configuration) {
      validValueCheck(value)
    }
    for (const value of configurationOptional) {
      validValueCheck(value)
    }

    // check if need to update linked receivers
    let updateLinked = false
    if (deviceId) {
      if (this.isMaxFlex(deviceId)) {
        if (
          deviceConfig.maxflex &&
          (deviceConfig.maxflex as MaxFlexConfig).updateLinked === true
        ) {
          updateLinked = true
          deviceConfig.maxflex.updateLinked = false
        }
        // remove property -> should not be stored
        if (deviceConfig.maxflex) {
          delete deviceConfig.maxflex.updateLinked
        }
      } else {
        for (const key in deviceConfig) {
          if (
            equipmentConfigDict[key] &&
            equipmentConfigDict[key].properties &&
            equipmentConfigDict[key].properties &&
            equipmentConfigDict[key].properties.extraData &&
            equipmentConfigDict[key].properties.extraData
              .updateLinkedOnChange === true
          ) {
            // key has property 'updateLinkedOnChange'
            if (deviceConfig[key] !== original.config[key]) {
              // config changed -> update linked receivers
              updateLinked = true
            }
          }
        }
      }
    }

    return {
      configuration,
      configurationOptional,
      dirty,
      dirty_optional,
      updateLinked,
    }
  }

  /**
   * Checks if a link exists and if it is repeating only
   * @param senderId
   * @param receiverId
   * @param channel
   */
  public getIsRepeatingOnly(
    senderId: string,
    receiverId: string,
    channel: number
  ): boolean {
    let isRepeatingOnly = false
    const link = this.findLink(senderId, receiverId, channel)
    if (link) {
      isRepeatingOnly = Link.isRepeatingOnly(link)
    }
    return isRepeatingOnly
  }

  /**
   * Checks if a device can be deleted
   * @param device
   */
  public checkForDelete(device: DeviceAssignment) {
    let links = []
    // Check if there are links
    if (device.category === EquipmentCategory.RECEIVER) {
      links = this.findLinks(undefined, device.id)
    } else {
      links = this.findLinks(device.id)
    }
    if (links.length > 0) {
      throw new Error(
        `Kann Gerät nicht löschen, da es mit ${links.length} anderen Geräten verlinkt ist.`
      )
    }
  }

  /**
   * Create a new device
   * @param model
   */
  private createInternalDevice(device: DeviceAssignment): DeviceAssignment {
    const category = device.category
    if (!this._devices[category]) {
      throw new Error('Invalid category: ' + category)
    }
    this._devices[category].set(device.id, device)
    if (category === EquipmentCategory.RECEIVER) {
      this.receivers.set(device.id, device)
      this._receiverList.push(device)
      this._receiverList = ProjectEditor.instance.sortDevices(
        this._receiverList
      )
    } else if (category === EquipmentCategory.SENDER) {
      this.senders.set(device.id, device)
      this._senderList.push(device)
      this._senderList = ProjectEditor.instance.sortDevices(this._senderList)
    }

    // Check for eGates
    if (this._equipmentHandler.isEgate(device.equipment)) {
      this._eGates.push(device)
    }

    return device
  }

  findMinStorageId(eGate: DeviceAssignment): number {
    const equipment = this._equipmentHandler.getEquipmentFromDevice(eGate)
    const links = this.findLinks(undefined, eGate.id)
    let minStorageId = 1
    const storageIds: number[] = links.map((link) => link.options.keyStorageId)
    let foundFreeStorageId = false
    while (!foundFreeStorageId) {
      if (storageIds.includes(minStorageId)) {
        minStorageId += 1
        if (minStorageId > equipment.tx_capacity) {
          throw new Error('Kein freier Speicherplatz verfügbar!')
        }
      } else {
        foundFreeStorageId = true
      }
    }
    return minStorageId
  }

  async deleteEgateStorage(
    eGate: DeviceAssignment,
    storageId: number
  ): Promise<void> {
    if (!this._equipmentHandler.isEgate(eGate.equipment)) {
      throw new Error(`Gerät ${eGate.device_number} ist kein eGate.`)
    }
    const links = this.findLinks(undefined, eGate.id)
    // Find Link with specified storageId
    const link = links.find((link) => link.options.keyStorageId === storageId)
    await this.deleteLinks([link])
  }

  async moveEgateStorage(
    eGate: DeviceAssignment,
    storageId: number,
    moveUp: boolean = false,
    moveToAvailable: boolean = false
  ): Promise<number> {
    if (!this._equipmentHandler.isEgate(eGate.equipment)) {
      throw new Error(`Gerät ${eGate.device_number} ist kein eGate.`)
    }
    if (moveUp && storageId === 1) {
      throw new Error('Kann nicht weiter nach oben verschoben werden.')
    }
    const equipment = await this._equipmentHandler.getEquipmentFromDevice(eGate)
    if (!moveUp && storageId === equipment.tx_capacity) {
      throw new Error('Kann nicht weiter nach unten verschoben werden.')
    }
    const links = this.findLinks(undefined, eGate.id)
    const linkInd = links.findIndex(
      (link) => link.options.keyStorageId === storageId
    )
    const link = links[linkInd]
    let prevLink = undefined

    if (moveToAvailable) {
      // Move to next available space
      if (moveUp) {
        let minStorageId = 1
        for (const _link of links) {
          if (
            _link.options.keyStorageId >= minStorageId &&
            _link.options.keyStorageId < link.options.keyStorageId
          ) {
            minStorageId = _link.options.keyStorageId + 1
          }
        }
        link.options.keyStorageId = minStorageId
      } else {
        let maxStorageId = equipment.tx_capacity
        for (const _link of links) {
          if (
            _link.options.keyStorageId <= maxStorageId &&
            _link.options.keyStorageId > link.options.keyStorageId
          ) {
            maxStorageId = _link.options.keyStorageId - 1
          }
        }
        link.options.keyStorageId = maxStorageId
      }
    } else {
      let prevLinkInd
      if (moveUp) {
        prevLinkInd = links.findIndex(
          (link) => link.options.keyStorageId === storageId - 1
        )
      } else {
        prevLinkInd = links.findIndex(
          (link) => link.options.keyStorageId === storageId + 1
        )
      }

      if (prevLinkInd > -1) {
        // Need to swap places
        prevLink = links[prevLinkInd]
      }

      if (moveUp) {
        link.options.keyStorageId -= 1
      } else {
        link.options.keyStorageId += 1
      }
      if (prevLink) {
        if (moveUp) {
          prevLink.options.keyStorageId += 1
        } else {
          prevLink.options.keyStorageId -= 1
        }
      }
    }

    await this.updateLinkOptions([
      {
        id: link.id,
        options: link.options,
      },
    ])
    if (prevLink) {
      await this.updateLinkOptions([
        { id: prevLink.id, options: prevLink.options },
      ])
    }

    return link.options.keyStorageId
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'

import { adminRouteName } from '../app'
import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue'

@Component({
  name: 'main-menu',
  components: {
    'main-menu-entry': MainMenuEntry,
  },
  data() {
    return {
      entries: [
        {
          iconName: 'mdi-chart-pie',
          location: { name: adminRouteName('dashboard') },
          text: 'Dashboard',
        },
        {
          iconName: 'mdi-domain',
          location: { name: adminRouteName('organisation-list') },
          text: this.$i18n.tc('models.core.organisation.prettyName', 2),
        },
        {
          iconName: 'mdi-account',
          location: { name: adminRouteName('profile-root') },
          text: this.$i18n.tc('models.core.user.prettyName', 2),
        },
        {
          iconName: 'mdi-google-circles-communities',
          location: { name: adminRouteName('role-root') },
          text: this.$i18n.tc('models.core.role.prettyName', 2),
        },
        {
          iconName: 'mdi-account-group',
          location: { name: adminRouteName('group-root') },
          text: this.$i18n.tc('models.core.group.prettyName', 2),
        },
        {
          iconName: 'mdi-lock-outline',
          location: { name: adminRouteName('object-authorization-root') },
          text: this.$i18n.tc('models.core.objectauthorization.prettyName', 2),
        },
        {
          iconName: 'mdi-puzzle',
          location: { name: adminRouteName('client-app-root') },
          text: this.$i18n.tc('models.client.clientapp.prettyName', 2),
        },
      ],
    }
  },
})
export default class MainMenu extends Vue {}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navbarHidden),expression:"!navbarHidden"}],staticClass:"navbar dashboard-nav has-shadow",class:{ 'is-pushed': _vm.isPushed, 'dashboard-nav-logged-out': !_vm.isLoggedIn }},[_c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"navbar-brand"},[_c('button',{class:_vm.menuButtonClass,on:{"click":_vm.switchReaderMode}},[(!_vm.readerModeEnabled)?_c('i',{staticClass:"mdi mdi-menu mdi-24px"}):_c('i',{staticClass:"mdi mdi-close mdi-24px"})]),_c('router-link',{staticClass:"is-hidden-mobile",attrs:{"to":{ name: 'overview' },"exact":""}},[_c('img',{staticClass:"nav-logo",attrs:{"src":"/img/brelag/styler.png","alt":"Logo","height":"30"}})])],1),(_vm.$store.state.global.isLoggedIn)?_c('div',{staticClass:"navbar-menu is-mobile"},[_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item has-dropdown is-hoverable profile-dropdown"},[(_vm.$store.state.global.profile)?_c('a',{staticClass:"navbar-link"},[_c('figure',{staticClass:"image is-32x32",staticStyle:{"margin-right":"0.5em"}},[_c('img',{attrs:{"src":_vm.getAvatar()}})]),_vm._v(" "+_vm._s(_vm.$store.state.global.profile.first_name ? `${_vm.$store.state.global.profile.first_name} ${_vm.$store.state.global.profile.last_name}` : _vm.$store.state.global.profile.username)+" ")]):_vm._e(),_c('div',{staticClass:"navbar-dropdown is-right"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
                name: 'request_change_email',
                params: {
                  id: _vm.$store.state.global.profileId,
                  old_email: _vm.$store.state.global.profile.email,
                },
              }}},[_c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-email"})]),_c('span',[_vm._v(_vm._s(_vm.$tc('models.auth.changeEmail')))])]),_c('router-link',{staticClass:"navbar-item",attrs:{"to":{ name: 'change-password' }}},[_c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-lock-outline"})]),_c('span',[_vm._v(_vm._s(_vm.$tc('models.auth.changePassword')))])]),_c('a',{staticClass:"navbar-item",on:{"click":function($event){return _vm.logout()}}},[_vm._m(0),_c('span',[_vm._v("Logout")])])],1)]),_vm._m(1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"icon is-small nav-icon"},[_c('i',{staticClass:"mdi mdi-power"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{staticClass:"brelag-logo",attrs:{"src":"/img/brelag/brelag.png","alt":"Logo","height":"41"}})])
}]

export { render, staticRenderFns }

import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue'
import { getLocationPreservingState } from '@/apps/routingUtils'

import { brelagDeveloperRouteName } from '@/apps/brelag/brelag-developer/app'

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-chart-pie',
        text: 'Dashboard',
        location: getLocationPreservingState(
          brelagDeveloperRouteName('dashboard'),
          this.$router
        ),
      },
      {
        iconName: 'mdi-cellphone-link',
        text: this.$i18n.tc('apps.brelag.equipmentgroup', 2),
        location: { name: brelagDeveloperRouteName('equipment-group-root') },
      },
      {
        iconName: 'mdi-cellphone',
        text: this.$i18n.tc('apps.brelag.equipment', 2),
        location: { name: brelagDeveloperRouteName('equipment-root') },
      },
    ]
  }
}

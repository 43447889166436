
import Vue from 'vue'
import Component from 'vue-class-component'
import hotkeys from 'hotkeys-js'

import { SwwConfig, SWW_CHANNELS } from '@/apps/brelag/common/models/swwParams'
import { createDefaultSww } from '@/apps/brelag/common/models/brelagSwwDefault'
import SwwChannel from './SwwChannel.vue'
import SwwRule from './SwwRule.vue'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'

@Component({
  components: {
    SwwChannel,
    SwwRule,
  },
  props: {
    config: {
      default: () => createDefaultSww(),
    },
  },
  data() {
    return {
      SWW_CHANNELS,
      activeChannel: 0,
      activeRule: 0,
    }
  },
})
export default class SwwConfigEditor extends Vue {
  config: SwwConfig
  originalConfig: SwwConfig

  mounted() {
    hotkeys('esc', { scope: 'SwwConfigEditor', keyup: true }, (event) => {
      // only fire event on keyup to avoid closing confirm dialog directly
      if (event.type === 'keyup') {
        event.preventDefault()
        this.cancel()
      }
    })
    hotkeys.setScope('SwwConfigEditor')

    if (this.$props.config.delay === null) {
      this.$props.config.delay = {
        value: 1,
      }
    }
    // keep original config to detect changes
    this.originalConfig = JSON.parse(JSON.stringify(this.config))
  }

  destroyed() {
    hotkeys.unbind('esc', 'SwwConfigEditor')
    hotkeys.setScope('EditModal')
  }

  closeModal() {
    this.$emit('close')
  }

  cancel() {
    if (isEqual(this.config, this.originalConfig)) {
      // no changes, close immediatly
      this.closeModal()
    } else {
      this.$buefy.dialog.confirm({
        message: 'Sind Sie sicher, dass Sie die Änderung verwerfen möchten?',
        onConfirm: () => {
          // revert changes
          // need to merge into config object, cannot reassign this.config to a new object
          merge(this.config, this.originalConfig)
          this.closeModal()
        },
      })
    }
  }
}

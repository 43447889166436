import {
  SwwConfig,
  SWW_DEFAULT_CONFIG,
} from '@/apps/brelag/common/models/swwParams'

import { simpleCopy } from '@/util/util'

/**
 * According to Grundregeln&Standardwerte Document from Brelag
 */
export function createDefaultSww(): SwwConfig {
  const config: SwwConfig = simpleCopy(SWW_DEFAULT_CONFIG)

  /* STORM CHANNEL 0 */
  /*******************/
  config.channels[0].enable = {
    value: 1,
  }
  config.channels[0].sensor = {
    value: 1, // Wind
  }
  config.channels[0].valueLower = {
    value: 7,
  }
  config.channels[0].valueUpper = {
    value: 9,
  }

  config.rules[0].channel = {
    value: 0,
  }
  config.rules[0].cmd = {
    value: 0x03, // Auf
  }
  config.rules[0].lock = {
    value: 1,
  }
  config.rules[0].priority = {
    value: 3,
  }

  config.rules[1].channel = {
    value: 0,
  }
  config.rules[1].cmd = {
    value: 0x15, // Entsperren
  }
  config.rules[1].unlock = {
    value: 1,
  }
  config.rules[1].priority = {
    value: 3,
  }

  /* WIND CHANNEL 1 */
  /*******************/
  config.channels[1].enable = {
    value: 1,
  }
  config.channels[1].sensor = {
    value: 1, // Wind
  }
  config.channels[1].valueLower = {
    value: 3,
  }
  config.channels[1].valueUpper = {
    value: 5,
  }

  config.rules[2].channel = {
    value: 1,
  }
  config.rules[2].cmd = {
    value: 0x03, // Auf
  }
  config.rules[2].lock = {
    value: 1,
  }
  config.rules[2].priority = {
    value: 3,
  }

  config.rules[3].channel = {
    value: 1,
  }
  config.rules[3].cmd = {
    value: 0x15, // Entsperren
  }
  config.rules[3].unlock = {
    value: 1,
  }
  config.rules[3].priority = {
    value: 3,
  }

  /* SUN CHANNEL 2 */
  /*******************/
  config.channels[2].enable = {
    value: 1,
  }
  config.channels[2].sensor = {
    value: 0, // Light
  }
  config.channels[2].valueLower = {
    value: 8,
  }
  config.channels[2].valueUpper = {
    value: 10,
  }

  config.rules[3].channel = {
    value: 2,
  }
  config.rules[3].cmd = {
    value: 0x04, // Ab
  }
  config.rules[3].priority = {
    value: 0,
  }
  config.rules[3].channelCondition = {
    value: 0,
  }

  config.rules[4].channel = {
    value: 2,
  }
  config.rules[4].cmd = {
    value: 0x15, // Entsperren
  }
  config.rules[4].priority = {
    value: 0,
  }
  config.rules[4].channelCondition = {
    value: 0,
  }

  /* NIGHT CHANNEL 3 */
  /*******************/
  config.channels[3].enable = {
    value: 1,
  }
  config.channels[3].sensor = {
    value: 0, // Light
  }
  config.channels[3].valueLower = {
    value: 2,
  }
  config.channels[3].valueUpper = {
    value: 3,
  }

  config.rules[5].channel = {
    value: 3,
  }
  config.rules[5].cmd = {
    value: 0x03, // Auf
  }
  config.rules[5].priority = {
    value: 0,
  }

  config.rules[6].channel = {
    value: 3,
  }
  config.rules[6].cmd = {
    value: 0x04, // Ab
  }
  config.rules[6].priority = {
    value: 0,
  }

  /* RAIN CHANNEL 4 */
  /*******************/
  config.channels[4].enable = {
    value: 1,
  }
  config.channels[4].sensor = {
    value: 2, // Rain
  }
  config.channels[4].valueLower = {
    value: 15,
  }
  config.channels[4].valueUpper = {
    value: 15,
  }

  config.rules[7].channel = {
    value: 4,
  }
  config.rules[7].cmd = {
    value: 0x03, // Auf
  }
  config.rules[7].priority = {
    value: 0,
  }

  config.rules[8].channel = {
    value: 4,
  }
  config.rules[8].cmd = {
    value: 0x00, // Kein Befehl
  }
  config.rules[8].priority = {
    value: 0,
  }

  /* K6 CHANNEL 5 */
  /*******************/
  config.channels[5].enable = {
    value: 1,
  }
  config.channels[5].sensor = {
    value: 0, // Light
  }
  config.channels[5].valueLower = {
    value: 8,
  }
  config.channels[5].valueUpper = {
    value: 10,
  }

  config.rules[9].channel = {
    value: 5,
  }
  config.rules[9].cmd = {
    value: 0x04, // Ab
  }
  config.rules[9].priority = {
    value: 0,
  }
  config.rules[9].channelCondition = {
    value: 0,
  }

  config.rules[10].channel = {
    value: 5,
  }
  config.rules[10].cmd = {
    value: 0x00, // Kein Befehl
  }
  config.rules[10].priority = {
    value: 0,
  }
  config.rules[10].channelCondition = {
    value: 0,
  }

  /* K7 CHANNEL 6 */
  /*******************/
  config.channels[6].enable = {
    value: 1,
  }
  config.channels[6].sensor = {
    value: 0, // Light
  }
  config.channels[6].valueLower = {
    value: 8,
  }
  config.channels[6].valueUpper = {
    value: 10,
  }

  config.rules[11].channel = {
    value: 6,
  }
  config.rules[11].cmd = {
    value: 0x10, // Pos
  }
  config.rules[11].priority = {
    value: 0,
  }
  config.rules[11].channelCondition = {
    value: 0,
  }

  config.rules[12].channel = {
    value: 6,
  }
  config.rules[12].cmd = {
    value: 0x00, // Kein Befehl
  }
  config.rules[12].priority = {
    value: 0,
  }
  config.rules[12].channelCondition = {
    value: 0,
  }

  /* K8 CHANNEL 6 */
  /*******************/
  config.channels[7].enable = {
    value: 1,
  }
  config.channels[7].sensor = {
    value: 0, // Light
  }
  config.channels[7].valueLower = {
    value: 8,
  }
  config.channels[7].valueUpper = {
    value: 10,
  }

  config.rules[13].channel = {
    value: 7,
  }
  config.rules[13].cmd = {
    value: 0x10, // Pos
  }
  config.rules[13].priority = {
    value: 0,
  }
  config.rules[13].channelCondition = {
    value: 0,
  }

  config.rules[14].channel = {
    value: 7,
  }
  config.rules[14].cmd = {
    value: 0x00, // Kein Befehl
  }
  config.rules[14].priority = {
    value: 0,
  }
  config.rules[14].channelCondition = {
    value: 0,
  }

  return config
}

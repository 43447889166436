import { AnnotationElement, Orientation } from '../models/editor'
import { Placement } from './floorPlanEditor'
import {
  DEFAULT_FONT,
  addBackgroundColorToContainer,
  addBorderColorToContainer,
} from './floorPlanDrawHelper'
import { getMetaSvgAttribute } from './floorPlanEditorUtil'
import { SVG_COMPASS } from './shapes/svg_compass'
import { Equipment } from '../../common/models/equipment'
import { SVG_DEFAULTS } from './defaultSvgAttributes'
import { simpleCopy } from '@/util/util'

export function drawArrowAnnotation(
  annotation: AnnotationElement,
  placement: Placement,
  editorContainer,
  svgObjects: any[]
) {
  const arrowColor = '#0bb10b'
  const size = annotation.meta.svgAttributes.size
  let p1X = 0
  let p1Y = 0
  let p2X = 0
  let p2Y = 0
  let fromTextX, fromTextY
  let toTextX, toTextY

  if (annotation.meta.svgAttributes.orientation === Orientation.RIGHT) {
    p1X = -size
    fromTextX = p1X + 30
    fromTextY = p1Y - 30
    toTextX = p1X + 30
    toTextY = p1Y + 30
  } else if (annotation.meta.svgAttributes.orientation === Orientation.LEFT) {
    p2X = -size
    fromTextX = p1X - 30
    fromTextY = p1Y + 30
    toTextX = p1X - 30
    toTextY = p1Y - 30
  } else if (annotation.meta.svgAttributes.orientation === Orientation.DOWN) {
    p1Y = -size
    fromTextX = p1X + 30
    fromTextY = p1Y + 30
    toTextX = p1X - 30
    toTextY = p1Y + 30
  } else {
    p2Y = -size
    fromTextX = p1X - 30
    fromTextY = p1Y - 30
    toTextX = p1X + 30
    toTextY = p1Y - 30
  }
  const group = editorContainer.svg.group()

  const line = group
    .line(p1X, p1Y, p2X, p2Y)
    .attr({ stroke: arrowColor, 'stroke-width': 40 })
  const fromText = annotation.meta.svgAttributes.fromText
  const toText = annotation.meta.svgAttributes.toText
  const fromTextObj = group
    .text(`VON ${fromText}`)
    .font({
      ...DEFAULT_FONT,
      fill: arrowColor,
      weight: 600,
    })
    .cx(fromTextX)
    .cy(fromTextY)
  const toTextObj = group
    .text(`BIS ${toText}`)
    .font({
      ...DEFAULT_FONT,
      fill: arrowColor,
      weight: 600,
    })
    .cx(toTextX)
    .cy(toTextY)
  if (annotation.meta.svgAttributes.orientation === Orientation.DOWN) {
    fromTextObj.rotate(90)
    toTextObj.rotate(90)
  } else if (annotation.meta.svgAttributes.orientation === Orientation.UP) {
    fromTextObj.rotate(-90)
    toTextObj.rotate(-90)
  }
  const arrowMarker = group
    .marker(40, 40, function (add) {
      add.path('M0,0 L0,12 L9,6 z')
    })
    .attr({ id: 'deviceArrowMarker', fill: arrowColor })
  arrowMarker.size(10, 10)
  arrowMarker.ref(3, 6)
  line.marker('end', arrowMarker)
  group.transform(placement.t)
  svgObjects.push(group)
}

export function drawTextAnnotation(
  annotation: AnnotationElement,
  placement: Placement,
  editorContainer,
  svgObjects: any[]
) {
  const svgObject = editorContainer.svg.group()
  const text = svgObject.text(annotation.meta.svgAttributes.text)

  text.font({
    family: 'Helvetica',
    size: annotation.meta.svgAttributes.size,
    fill: annotation.meta.svgAttributes.color,
  })
  // background
  const bgColor = getMetaSvgAttribute(annotation, 'bgColor') as string
  let rect = null
  if (bgColor) {
    rect = addBackgroundColorToContainer(svgObject, text, bgColor)
    rect.cx(placement.cx)
    rect.cy(placement.cy)
  }
  // border
  const borderColor = getMetaSvgAttribute(annotation, 'borderColor') as string
  let border = null
  if (borderColor) {
    border = addBorderColorToContainer(svgObject, text, borderColor)
    border.cx(placement.cx)
    border.cy(placement.cy)
  }

  // Place object
  text.cx(placement.cx)
  text.cy(placement.cy)

  // rotate after placement
  const rotation = getMetaSvgAttribute(annotation, 'rotation')
  if (rotation) {
    text.rotate(rotation)
    if (rect) {
      rect.rotate(rotation)
    }
    if (border) {
      border.rotate(rotation)
    }
  }
  svgObjects.push(svgObject)
}

export function drawCompassAnnotation(
  annotation: AnnotationElement,
  placement: Placement,
  editorContainer,
  svgObjects: any[]
) {
  const svgObject = editorContainer.svg.group()
  svgObject.svg(SVG_COMPASS)
  const compass = svgObject.children()[0]
  const bbox = compass.bbox()
  const padding = 2
  compass
    .rect()
    .attr('x', bbox.x - padding)
    .attr('y', bbox.y - padding)
    .attr('width', bbox.width + padding * 2)
    .attr('height', bbox.height + padding * 2)
    .style('fill', '#000000')
    .style('fill-opacity', 0)
    .back()
  const rotation = getMetaSvgAttribute(annotation, 'rotation')
  if (rotation) {
    svgObject
      .children()[0]
      .children()
      .forEach((svg) => {
        svg.rotate(rotation)
      })
  }
  const size = getMetaSvgAttribute(annotation, 'size')
  if (size) {
    svgObject.children()[0].size(size)
  }

  // Place object
  compass.cx(placement.cx)
  compass.cy(placement.cy)
  svgObjects.push(svgObject)
}

export function drawHelpLineAnnotation(
  annotation: AnnotationElement,
  placement: Placement,
  editorContainer,
  svgObjects: any[]
) {
  const svgObject = editorContainer.svg.group()
  if (annotation.meta.svgAttributes.orientation === Orientation.DOWN) {
    svgObject.line(0, 0, 0, 2000).stroke({ width: 9, color: '#1d77b6' })
  } else {
    svgObject.line(0, 0, 2000, 0).stroke({ width: 9, color: '#1d77b6' })
  }

  // Place object
  svgObject.cx(placement.cx)
  svgObject.cy(placement.cy)
  svgObjects.push(svgObject)
}

// Returns default SVG attributes for equipment if available based on device_type_name
export function getDefaultSvgAttributes(equipment: Equipment) {
  if (SVG_DEFAULTS.hasOwnProperty(equipment.device_type_name)) {
    return simpleCopy(SVG_DEFAULTS[equipment.device_type_name])
  } else {
    return {}
  }
}

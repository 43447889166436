import components from '@/lang/de/components'
import models from '@/lang/de/models'
import admin from '@/apps/admin/lang/de'
import brelag from '@/apps/brelag/common/lang/de'
import wesco from '@/apps/brelag/mandator-user/custom-mandators/wesco/lang/de/index'
import knockaut from '@/lang/de/knockaut'

const apps = {
  admin,
  brelag,
}

export default {
  components,
  models,
  apps,
  wesco,
  knockaut,
}

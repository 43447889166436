
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Component from 'vue-class-component'

import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue'
import { getLocationPreservingState } from '@/apps/routingUtils'
import { brelagUserRouteName } from '@/apps/brelag/mandator-user/app'
import { CustomMandatorLayout } from '@/store/SettingsStore'

@Component({
  components: {
    MainMenuEntry,
  },
  computed: {
    ...mapGetters('settings', ['mandatorLayout']),
  },
})
export default class MainMenu extends Vue {
  mandatorLayout: CustomMandatorLayout
  get menuEntries() {
    let menuEntries = []
    if (this.mandatorLayout === CustomMandatorLayout.WESCO) {
      menuEntries = [
        {
          iconName: 'mdi-chart-pie',
          text: 'Dashboard',
          location: getLocationPreservingState(
            brelagUserRouteName('wesco-dashboard'),
            this.$router
          ),
        },
        {
          iconName: 'mdi-card-account-details',
          text: undefined,
          location: getLocationPreservingState(
            brelagUserRouteName('wesco-customer-root'),
            this.$router
          ),
        },
        {
          iconName: 'mdi-folder-multiple',
          text: undefined,
          location: getLocationPreservingState(
            brelagUserRouteName('wesco-project-root'),
            this.$router
          ),
        },
      ]
    } else {
      menuEntries = [
        {
          iconName: 'mdi-chart-pie',
          text: 'Dashboard',
          location: getLocationPreservingState(
            brelagUserRouteName('dashboard'),
            this.$router
          ),
        },
        {
          iconName: 'mdi-card-account-details',
          text: undefined,
          location: getLocationPreservingState(
            brelagUserRouteName('customer-root'),
            this.$router
          ),
        },
        {
          iconName: 'mdi-folder-multiple',
          text: undefined,
          location: getLocationPreservingState(
            brelagUserRouteName('project-root'),
            this.$router
          ),
        },
      ]
      menuEntries.push({
        text: 'KNOCKAUT',
        iconSrc: '/img/brelag/KA_Faust_black_white.svg',
        location: getLocationPreservingState(
          brelagUserRouteName('knockaut-list'),
          this.$router
        ),
      })
    }

    return menuEntries
  }

  get text() {
    return {
      [brelagUserRouteName('dashboard')]: 'Dashboard',
      [brelagUserRouteName('knockaut-list')]: 'KNOCKAUT',
      [brelagUserRouteName('customer-root')]: this.$i18n.tc(
        'apps.brelag.customer',
        2
      ),
      [brelagUserRouteName('project-root')]: this.$i18n.tc(
        'apps.brelag.project',
        2
      ),
      // WESCO
      [brelagUserRouteName('wesco-dashboard')]: 'Dashboard',
      [brelagUserRouteName('wesco-customer-root')]: this.$i18n.tc(
        'wesco.customer.prettyName',
        2
      ),
      [brelagUserRouteName('wesco-project-root')]: this.$i18n.tc(
        'wesco.project.prettyName',
        2
      ),
    }
  }
}

export default {
  clientapp: {
    prettyName: 'Client App | Client Apps',
    fields: {
      name: 'Name',
      description: 'Description',
      handle: 'Handle',
      view_id: 'View ID',
      organisation_name: 'Organisation',
    },
    addSetting: 'Add Setting',
    addDefaultSettingKind: 'Add Default Setting Kind',
  },
  clientappsettingkind: {
    prettyName: 'Client App Setting Kind | Client App Setting Kinds',
  },
}


import Vue from 'vue'
import Component from 'vue-class-component'

import { routePageSize, routeHasPagination } from '@/apps/routingUtils'

@Component({
  name: 'list-pagination',
  props: {
    label: {
      default: 'Show',
    },
  },
})
export default class ListPagination extends Vue {
  get showPageSizeSelect() {
    // TODO: Should be defined in model, not route
    return routeHasPagination(this.$route)
  }

  get currentPageSize() {
    return routePageSize(this.$route)
  }

  get pageSizes() {
    const routePagination = this.$route.meta.hasPagination
    if (!routePagination) {
      return []
    }
    return routePagination.pageSizes
  }

  paginationChange(pageSize: string) {
    // TODO: use routerHandler
    if (!this.$store.getters['global/navigationIsActive']) {
      const redirect = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page_size: pageSize,
          page: undefined,
        },
      }
      this.$router.push(redirect)
    }
  }
}

import { render, staticRenderFns } from "./OrganisationSelector.vue?vue&type=template&id=3b9536db&scoped=true&"
import script from "./OrganisationSelector.vue?vue&type=script&lang=ts&"
export * from "./OrganisationSelector.vue?vue&type=script&lang=ts&"
import style0 from "./OrganisationSelector.vue?vue&type=style&index=0&id=3b9536db&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b9536db",
  null
  
)

export default component.exports
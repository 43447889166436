
import BaseForm from '@/components/common/BaseForm.vue'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'change_password',
  components: {
    'base-form': BaseForm,
  },
})
export default class ChangePassword extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  passwords = {
    old_password: '',
    new_password1: '',
    new_password2: '',
  }
  errorMessage: string = ''

  changePassword() {
    this.$api
      .changePassword(this.passwords, this.$store.state.global.profileId)
      .then((response) => {
        this.$router.push('/')
      })
      .catch((error) => {
        this.$refs.baseForm.handleError(error)
      })
  }

  get passwordsMatch() {
    if (this.passwords.new_password1 === this.passwords.new_password2) {
      return true
    } else {
      if (
        this.passwords.new_password1 !== '' &&
        this.passwords.new_password2 !== ''
      ) {
        this.errorMessage = this.$tc('models.auth.passwordNoMatch')
        return false
      } else {
        return true
      }
    }
  }
}

/* tslint:disable:variable-name */

import { CLIENT_APP_DEFAULT } from '@/models/client/defaults'
import {
  TransientBaseObject,
  TRANSIENT_BASE_OBJECT_DEFAULT,
  ListModelField,
} from '@/models/core/base'
import Vue from 'vue'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import { Organisation } from '../core/organisation'

export class ClientApp extends TransientBaseObject {
  organisation: string
  handle: string
  name: string
  description: string
  view_id: string

  static apiUrl = 'client/app'
  static langPath: string = 'models.client.clientapp'
  static objectType: string = 'client-app'
  static listFields: ListModelField[] = [
    {
      key: 'name',
    },
    {
      key: 'description',
    },
    {
      key: 'handle',
    },
    {
      key: 'view_id',
    },
    {
      key: 'organisation_name',
    },
  ]

  static get joins(): Collection.RelatedAnnotation<ClientApp>[] {
    return [
      {
        relatedModelClass: Organisation,
        relatedObjectProperty: 'name',
      },
    ]
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(CLIENT_APP_DEFAULT))
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<ClientApp, ClientApp>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static async getSettings(
    apiClient: ApiClientV2,
    clientAppId: string
  ): Promise<ClientAppSetting[]> {
    const response = await apiClient.customGet(
      `client/app/${clientAppId}/setting/`
    )
    return response.results
  }

  static async getSetting(
    apiClient: ApiClientV2,
    clientAppId: string,
    key: string
  ): Promise<ClientAppSetting> {
    const response = await apiClient.customGet(
      `client/app/${clientAppId}/setting/${key}/`
    )
    return response
  }

  static async deleteSetting(
    apiClient: ApiClientV2,
    clientAppId: string,
    key: string,
    object_seq: number
  ): Promise<void> {
    await apiClient.customDelete(
      `client/app/${clientAppId}/setting/${key}/?object_seq=${object_seq}`
    )
  }

  static insertBefore(
    vm: Vue,
    insert: ClientApp,
    before: ClientApp
  ): Promise<void> {
    return vm.$apiv2.customPost(`client/app/${insert.id}/insert-before`, {
      other: before && before.id,
    })
  }
}

export class ClientAppSettingKind extends TransientBaseObject {
  name: string
  description: string
  client_app: string
  handle: string

  static apiUrl = 'client/app-setting-kind'
  static langPath: string = 'models.client.clientappsettingkind'
  static objectType: string = 'clientappsettingkind'

  static get defaultModel() {
    return JSON.parse(JSON.stringify(CLIENT_APP_SETTING_KIND_DEFAULT))
  }
}

export class ClientAppSetting extends TransientBaseObject {
  kind?: string
  key: string
  value: any
  last_modified?: string
  client_app?: string
  object_seq?: number

  static apiUrl = 'client/app/~/setting'
  static langPath: string = 'models.client.clientappsetting'
  static objectType: string = 'clientappsetting'

  static get defaultModel() {
    return JSON.parse(JSON.stringify(CLIENT_APP_SETTING_DEFAULT))
  }

  static customCreateUrl(setting: ClientAppSetting): string {
    return `client/app/${setting.client_app}/setting/`
  }

  static customUpdateUrl(setting: ClientAppSetting): string {
    return `client/app/${setting.client_app}/setting/${setting.key}/`
  }
}

export const CLIENT_APP_SETTING_KIND_DEFAULT: ClientAppSettingKind = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  client_app: '',
  handle: '',
}

export const CLIENT_APP_SETTING_DEFAULT: ClientAppSetting = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  client_app: '',
  kind: '',
  key: '',
  value: {},
}

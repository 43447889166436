import {
  VIEW_ID as VIEW_ID_ADMIN,
  CLIENT_APP as CLIENT_APP_ADMIN,
} from './admin/app'
import {
  VIEW_ID as VIEW_ID_BRELAG_ADMIN,
  CLIENT_APP as CLIENT_APP_BRELAG_ADMIN,
} from './brelag/brelag-admin/app'
import {
  VIEW_ID as VIEW_ID_BRELAG_DEVELOPER,
  CLIENT_APP as CLIENT_APP_BRELAG_DEVELOPER,
} from './brelag/brelag-developer/app'
import {
  VIEW_ID as VIEW_ID_BRELAG_SUPPORTER,
  CLIENT_APP as CLIENT_APP_BRELAG_SUPPORTER,
} from './brelag/brelag-supporter/app'
import {
  VIEW_ID as VIEW_ID_BRELAG_MANDATOR,
  CLIENT_APP as CLIENT_APP_BRELAG_MANDATOR,
} from './brelag/mandator-admin/app'
import {
  VIEW_ID as VIEW_ID_BRELAG_USER,
  CLIENT_APP as CLIENT_APP_BRELAG_USER,
} from './brelag/mandator-user/app'

export interface ClientAppEntry {
  name: string
  path: string
  icon: string
  defaultPathName: string
  store?: {
    name: string
    module: any
  }
  mockEndpoints?: any[]
}

export interface ClientAppRegistry {
  [viewId: string]: ClientAppEntry
}

let CLIENT_APP_REGISTRY: ClientAppRegistry = {
  [VIEW_ID_ADMIN]: CLIENT_APP_ADMIN,
}

CLIENT_APP_REGISTRY = {
  ...CLIENT_APP_REGISTRY,
  [VIEW_ID_BRELAG_ADMIN]: CLIENT_APP_BRELAG_ADMIN,
  [VIEW_ID_BRELAG_DEVELOPER]: CLIENT_APP_BRELAG_DEVELOPER,
  [VIEW_ID_BRELAG_SUPPORTER]: CLIENT_APP_BRELAG_SUPPORTER,
  [VIEW_ID_BRELAG_MANDATOR]: CLIENT_APP_BRELAG_MANDATOR,
  [VIEW_ID_BRELAG_USER]: CLIENT_APP_BRELAG_USER,
}

export function clientAppList() {
  return Object.keys(CLIENT_APP_REGISTRY).map((key) => {
    return {
      view_id: key,
      name: CLIENT_APP_REGISTRY[key].name,
    }
  })
}

export function clientAppMockEndpoints() {
  const endpoints: any[] = []

  for (const viewId in CLIENT_APP_REGISTRY) {
    // Register client app mock endpoints
    if (CLIENT_APP_REGISTRY[viewId].mockEndpoints) {
      endpoints.concat(CLIENT_APP_REGISTRY[viewId].mockEndpoints)
    }
  }

  return endpoints
}

export function clientAppRouteName(viewId: string, routeName: string) {
  if (clientAppIsKnown(viewId)) {
    return `${viewId}-${routeName}`
  } else {
    throw new Error(`Client app '${viewId}' is not known.`)
  }
}

export function clientAppDefaultRouteName(viewId: string) {
  if (clientAppIsKnown(viewId)) {
    return `${viewId}-${clientAppRegistryGet(viewId, 'defaultPathName')}`
  } else {
    throw new Error(`Client app '${viewId}' is not known.`)
  }
}

export function clientAppIsKnown(viewId: string) {
  return CLIENT_APP_REGISTRY.hasOwnProperty(viewId)
}

export function clientAppRegistryGet(viewId: string, property: string) {
  const entry = CLIENT_APP_REGISTRY[viewId]

  if (!entry) {
    throw new Error(`Client app '${viewId}' is not defined in registry.`)
  }
  if (!entry.hasOwnProperty(property)) {
    throw new Error(
      `Property '${property}' is not defined for client app ${viewId}.`
    )
  }
  return entry[property]
}


import Vue from 'vue'
import Component from 'vue-class-component'
import {
  MaxFlexButtonFunction,
  LocatingKeyButtonMap,
  MaxFlexKeyConfig,
} from '@/apps/brelag/common/models/maxflex/maxflex'

@Component({
  props: {
    height: {
      required: true,
    },
    width: {
      required: true,
    },
    isHighlighted: {
      default: false,
    },
    keyNum: {
      required: true,
    },
    config: {
      required: false,
    },
  },
  data() {
    return {
      MaxFlexButtonFunction,
    }
  },
})
export default class MaxFlexButton extends Vue {
  get keyConfig(): MaxFlexKeyConfig {
    if (this.$props.config) {
      return this.$props.config.keys[this.$props.keyNum]
    } else {
      return null
    }
  }

  get icon(): string {
    if (this.keyConfig && this.keyConfig.Icon && this.keyConfig.Icon.value) {
      return this.keyConfig.Icon.value
    } else {
      return ''
    }
  }

  get text(): string {
    if (this.keyConfig && this.keyConfig.Text && this.keyConfig.Text.value) {
      return this.keyConfig.Text.value
    } else {
      return ''
    }
  }

  get nightLED() {
    return (
      LocatingKeyButtonMap[this.$props.keyNum] ===
      this.$props.config.LocatingKey.value
    )
  }

  get computedHeight() {
    if (this.$props.height === 1) {
      return '25%'
    } else if (this.$props.height === 2) {
      return '50%'
    } else {
      return '100'
    }
  }

  get computedWidth() {
    if (this.$props.width === 1) {
      return '50%'
    } else {
      return '100%'
    }
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'

import MaxFlexButton from '@/apps/brelag/mandator-user/components/editor/maxflex/MaxFlexButton.vue'

@Component({
  components: {
    MaxFlexButton,
  },
  props: {
    layout: {
      required: true,
    },
    combinedHighlight: {
      default: true,
    },
    config: {
      required: false,
    },
    bigView: {
      default: false,
    },
  },
  data() {
    return {
      isHighlighted: false,
    }
  },
})
export default class MaxFlexLayoutView extends Vue {
  $refs: {
    blocks: any
  }
  isHighlighted: boolean

  mounted() {
    if (this.$refs.blocks) {
      this.$refs.blocks.forEach((block) => {
        const element = block.$el
        if (element) {
          if (this.$props.combinedHighlight) {
            // We want to highlight all buttons of a layout if user hovers over one of the buttons
            element.addEventListener('mouseover', this.mouseOverHandler)
            element.addEventListener('mouseout', this.mouseOutHandler)
          }
          element.addEventListener('click', (event) =>
            this.clickHandler(event, block.keyNum)
          )
        }
      })
    }
  }

  mouseOverHandler() {
    this.isHighlighted = true
  }

  mouseOutHandler() {
    this.isHighlighted = false
  }

  clickHandler(event, keyNum) {
    this.$emit('click', this.$props.layout.value, keyNum)
  }

  destroyed() {
    if (this.$refs.blocks) {
      this.$refs.blocks.forEach((block) => {
        const element = block.$el
        if (element) {
          if (this.$props.combinedHighlight) {
            element.removeElementListener('mouseover', this.mouseOverHandler)
            element.removeElementListener('mouseout', this.mouseOutHandler)
          }
          element.removeElementListener('click', this.clickHandler)
        }
      })
    }
  }
}

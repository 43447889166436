import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import { CollectionPagination } from '@/api/ApiClient'
import { getTranslation } from '@/lang/setup'
import { DEFAULT_ICON_MAP } from '@/models/core/icons'
import dotize from 'dotize'
import _ from 'lodash'

export enum RestAction {
  DELETE = 'delete',
}

export interface Ancestor {
  modelClass: typeof TransientBaseObject
  requiredForFilter?: boolean
  mainParent?: boolean
}

export interface ListFilter {
  modelClass: typeof TransientBaseObject
  property: string
  key?: string
}

export interface SearchFieldInterface {
  label: string
  searchQuery: string
}

export enum LifeCycleState {
  Draft = 'draft',
  PendingApproval = 'pending-approval',
  Approved = 'approved',
  PendingDeletion = 'pending-deletion',
  Deleted = 'deleted',
  Deactivated = 'deactivated',
}

export interface Annotation {
  property: string
  callback: Function
}

export interface ListModelField extends ModelField {
  transform?: (fieldValue: any) => any | ((fieldValue: any) => any)[]
  class?: (
    row?: any,
    index?: number
  ) => string | ((row?: any, index?: number) => string)[]
  linkTo?: (row?: any) => any
  sortable?: boolean
  searchQuery?: string
  editProperties?: {
    type: CellEditType
    min?: number
    max?: number
    step?: number
    digits?: number
    maxlength?: number
    width?: number
  }
}

export enum CellEditType {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
}

export enum CellType {
  BUTTON = 'button',
  LINK = 'link',
  CODE = 'code',
  TAG = 'tag',
  ICON = 'icon',
  EDITABLE_CELL = 'editable_cell',
  IMAGE = 'image',
  TEXT = 'text',
}

export interface BaseListColumn {
  label: string
  fieldName: string
  sortable?: boolean
  sortFieldName?: string
  cells: {
    type?: CellType
    tooltip?: (row?: any, index?: number) => string
    transform?: (fieldValue?: any) => string
    class?: (row?: any) => string
    linkTo?: (row?: any) => { name: string; query: any }
    icon?: string
  }[]
}

export enum BaseListEventType {
  REORDER = 'reorder',
  DELETE = 'delete',
  UPDATE = 'update',
  CREATE = 'create',
}

export interface ModelField {
  key: string
  formFieldType?: FormFieldType
  required?: boolean
  cellType?: CellType | CellType[]
  icon?: string
  virtual?: boolean
  undotized?: boolean // Usually, a field like {a: {b: 2}} will be transformed to {a.b: 2}. If 'undotized' is set to true, this transformation is not done
  editable?: boolean
  tooltip?: (row: any) => {} | any

  formProperties?: {
    options?: { text: string; value: string | number }[]
    [key: string]: any
  }

  display?: string
}

export abstract class TransientBaseObject {
  id: string
  create_time?: string
  creator?: string
  object_state?: LifeCycleState
  review_requester?: string
  _permissions: {
    [s: string]: boolean
  }

  /** Model Description **/
  // Type of the object as defined in backend
  static objectType: string = undefined
  // URL endpoint to this object type
  static apiUrl: string = undefined
  // If true, there should no trailing slash be appended at the end of the url
  static noTrailingSlash: boolean = false
  // The default view id (client app) of this object type
  static defaultViewId: string = undefined
  // Ancestors
  static ancestors: Ancestor[] = []
  // Annotations
  static annotations: Collection.Annotation<TransientBaseObject, any>[] = []
  // Data joins
  static joins: Collection.RelatedAnnotation<TransientBaseObject>[] = []
  // Defines if model has a life cycle and whether to show to user
  static hasLifeCycle: boolean = false
  static showLifeCycle: boolean = false
  // Path to language file
  static langPath: string = undefined
  static restActions: {
    [RestAction.DELETE]: boolean
  } = {
    [RestAction.DELETE]: true,
  }
  // Default model to be used for new objects
  static defaultModel: any = undefined
  // Defines whether this model uses the new ApiClientV2
  static useApiClientV2: boolean = false

  /** List and Form Definitions **/
  static fields: ModelField[] = []
  static listFields: ListModelField[] = []
  static listFilters: ListFilter[] = []
  static defaultPagination: CollectionPagination = null

  static detailLinkQuery(_context: { [key: string]: string }) {
    return {}
  }

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static prettyName(plural: boolean = false): string {
    return TransientBaseObject.getTranslation(
      this.langPath,
      { key: 'prettyName' },
      plural
    )
  }

  static getTranslation(langPath, field: ModelField, plural: boolean = false) {
    return getTranslation(`${langPath}.${field.key}`, plural)
  }

  /**
   * Returns icon for a field
   * @param field field name (e.g. 'description', 'meta.phone', ..)
   * @param customIcons custom icons that each model can define
   */
  static getIcon(field: ModelField) {
    if (field.icon !== undefined) {
      return field.icon
    } else {
      // For nested fieldnames like 'meta.phone', only look at last part (e.g. 'phone')
      const fieldName = field.key.split('.')[field.key.split('.').length - 1]

      // Check if field name is in default icons, otherwise return default
      return DEFAULT_ICON_MAP[fieldName]
        ? DEFAULT_ICON_MAP[fieldName]
        : 'mdi-text'
    }
  }

  static getFormFieldLabel(langPath: string, field: ModelField) {
    return `${langPath}.fields.${field.key}`
  }

  static getPlaceholder(langPath: string, field: ModelField) {
    return `${langPath}.placeholders.${field.key}`
  }

  static defaultFormFields(langPath: string, fields: ModelField[]) {
    const formFields = []
    fields.forEach((field) => {
      formFields.push({
        key: field.key,
        type:
          field.formFieldType !== undefined
            ? field.formFieldType
            : FormFieldType.INPUT,
        required: field.required !== undefined ? field.required : true,
        properties: {
          label: this.getFormFieldLabel(langPath, field),
          placeholder: this.getPlaceholder(langPath, field),
          icon: this.getIcon(field),
          ...field.formProperties,
        },
        display: field.display,
      })
    })
    return formFields
  }

  static defaultColumns(
    langPath: string,
    fields: ListModelField[]
  ): BaseListColumn[] {
    const columns: BaseListColumn[] = []
    fields.forEach((field) => {
      const cells = []
      // field has multiple cells
      if (field.cellType && Array.isArray(field.cellType)) {
        for (let i = 0; i < field.cellType.length; i++) {
          cells.push({
            type: (field.cellType && field.cellType[i]) || CellType.TEXT,
            transform: (field.transform && field.transform[i]) || undefined,
            tooltip: (field.tooltip && field.tooltip[i]) || undefined,
            class: (field.class && field.class[i]) || undefined,
            linkTo: (field.linkTo && field.linkTo[i]) || undefined,
            icon: field.icon,
          })
        }
      }
      // field has only one cell
      else {
        cells.push({
          type: field.cellType || CellType.TEXT,
          transform: field.transform,
          tooltip: field.tooltip,
          class: field.class,
          linkTo: field.linkTo,
          icon: field.icon,
          editProperties: field.editProperties,
        })
      }
      columns.push({
        label: this.getFormFieldLabel(langPath, field),
        fieldName: field.key,
        sortable: field.sortable,
        cells: cells,
      })
    })
    return columns
  }

  static parseModel(model: object, fields: ModelField[]) {
    const undotized = {}
    const model_copy = JSON.parse(JSON.stringify(model))
    fields.forEach((field: ModelField) => {
      if (field.undotized && _.get(model_copy, field.key)) {
        undotized[field.key] = _.get(model_copy, field.key)
        _.unset(model_copy, field.key)
      }
    })
    const out = dotize.convert(model_copy)
    Object.keys(undotized).forEach((key) => {
      out[key] = undotized[key]
    })
    return out
  }

  static searchFields(
    langPath: string = this.langPath,
    fields: ListModelField[] = this.listFields
  ): SearchFieldInterface[] {
    const searchFields: SearchFieldInterface[] = []
    fields.forEach((field) => {
      if (field.searchQuery && field.searchQuery.length > 0) {
        searchFields.push({
          label: this.getFormFieldLabel(langPath, field),
          searchQuery: field.searchQuery,
        })
      }
    })
    return searchFields
  }

  static isValidFilter(filter): boolean {
    // Check if all required parameters are fulfilled
    const ancestors = this.ancestors || []
    for (const ancestor of ancestors) {
      if (
        // if ancestor is required AND
        //    it is not in the filter OR
        //    it is in the filter but undefined OR empty
        ancestor.requiredForFilter &&
        (!filter.hasOwnProperty(ancestor.modelClass.objectType) ||
          (filter.hasOwnProperty(ancestor.modelClass.objectType) &&
            (filter[ancestor.modelClass.objectType] === undefined ||
              filter[ancestor.modelClass.objectType] === '')))
      ) {
        // the id of a required filter parameter is not given,
        // e.g. model_id not given for classification filter
        // hence filter is not valid
        return false
      }
    }
    return true
  }

  static canDelete(): boolean {
    return this.restActions[RestAction.DELETE]
  }

  /**
   * Function to be called before saving object
   */
  static beforeSaveHook: (
    apiClient: ApiClientV2,
    obj: TransientBaseObject,
    hookOptions?: Collection.HookOption
  ) => Promise<any> = undefined

  /**
   * Function to be called after saving object
   */
  static afterSaveHook: (
    apiClient: ApiClientV2,
    obj: TransientBaseObject,
    hookOptions?: Collection.HookOption,
    responseData?: any
  ) => Promise<any> = undefined

  /**
   * Returns a custom URL for creating object (without '/api/v1')
   */
  static customCreateUrl: (obj: TransientBaseObject) => string = undefined

  /**
   * Returns a custom URL for updating object (without '/api/v1')
   */
  static customUpdateUrl: (obj: TransientBaseObject) => string = undefined
}

export class BaseObject extends TransientBaseObject {
  public static annotations: Collection.Annotation<BaseObject & any, any>[] = []
  public static joins: Collection.RelatedAnnotation<BaseObject & any>[] = []
  public static hasLifeCycle: boolean = true
  public static showLifeCycle: boolean = true
}

export const TRANSIENT_BASE_OBJECT_DEFAULT: TransientBaseObject = {
  id: '0',
  _permissions: {},
}


import axios from 'axios'
import { CookieStorage } from 'cookie-storage'
import store from '@/store/index'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'

@Component({
  name: 'login',
  components: {},
  computed: {
    ...mapGetters('global', ['registrationEnabled']),
  },
})
export default class Login extends Vue {
  wrongLogin: boolean = false
  errorMessage = ''
  user = {
    username: '',
    password: '',
  }

  mounted() {
    if (this.$store.state.global.isLoggedIn) {
      this.redirectUser()
    }
  }

  redirectUser() {
    let url = '/'
    if (this.$router.currentRoute.query.afterLoginUrl) {
      url = this.$router.currentRoute.query.afterLoginUrl as string
    }
    this.$router.push({ path: url })
  }

  enterKey() {
    this.login()
  }

  onVerify(event) {
    this.login()
  }

  login() {
    axios
      .post('/api/v1/auth/login', this.user)
      .then((response) => {
        // Successful login
        const cookieStorage = new CookieStorage()
        const csrf = cookieStorage.getItem('csrftoken')
        response.data.csrfToken = csrf
        store
          .dispatch({
            type: 'global/loginUser',
            data: response.data,
          })
          .then(() => {
            this.redirectUser()
          })
      })
      .catch((error) => {
        this.errorMessage = this.$errorHandler.errorToString(error)
        this.wrongLogin = true
      })
  }
}

import {
  BaseObject,
  ListModelField,
  ModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import Vue from 'vue'
import {
  CustomLayoutSettings,
  CustomMandatorLayout,
} from '@/store/SettingsStore'

export interface VerifiedAddress {
  address_line_1: string
  address_line_2: string
  zip: string
  town: string
  province: string
  country: string
}

export class Organisation extends BaseObject {
  name: string
  password_policy?: {
    version: number
    min_length: number
    alpha_upper: boolean
    alpha_lower: boolean
    numeric: boolean
    special_char: boolean
  }
  lifecycle_policy?: {
    enable_reviews: boolean
    require_reviewer: boolean
  }
  meta?: {
    [key: string]: any
  }
  settings: {
    // Defines which frontend layout is used for the mandator
    layout?: CustomMandatorLayout
    // Custom settings for the specific layout
    layoutSettings?: CustomLayoutSettings

    // Using these settings, it is possible to enable "hidden" equipments for a mandator
    enabledHiddenObjects?: {
      equipmentGroups?: string[]
      equipments?: string[]
      equipmentVariants?: string[]
    }
  }
  avatar?: string
  slug?: string
  slug_unique?: string
  email_template_namespace?: string
  verified_address?: VerifiedAddress
  // Whether organisation is a productive organisation (e.g. not a test user, demo user ,...)
  is_productive: boolean

  static showLifeCycle: boolean = false
  static apiUrl = 'organisation'
  static objectType: string = 'organisation'
  static langPath: string = 'models.core.organisation'
  static fields: ModelField[] = [{ key: 'name', icon: 'mdi-account' }]

  static listFields: ListModelField[] = [
    { key: 'name', sortable: true },
    // { key: 'avatar_img', cellType: CellType.IMAGE },
  ]

  static annotations: Collection.Annotation<Organisation, string>[] = [
    // {
    //   key: 'avatar_img',
    //   callback: getAvatarAnnotation,
    // },
  ]
  static joins: Collection.RelatedAnnotation<Organisation>[] = []

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(vm: Vue, filter, pagination) {
    return vm.$apiv2.subscribe<Organisation, Organisation>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...ORGANISATION_DEFAULT },
    }
  }

  static specialize(
    additionalFields: ModelField[],
    additionalColumns: ModelField[],
    additionalAnnotations: Collection.Annotation<Organisation, string>[] = [],
    langPath?: string
  ): new () => Organisation {
    class CustomOrganisation extends Organisation {
      static langPath: string = langPath ? langPath : Organisation.langPath
      static fields: ModelField[] = Organisation.fields.concat(additionalFields)
      static listFields: ListModelField[] =
        Organisation.listFields.concat(additionalColumns)
      static annotations: Collection.Annotation<Organisation, string>[] =
        Organisation.annotations.concat(additionalAnnotations)
    }
    return CustomOrganisation
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(ORGANISATION_DEFAULT))
  }

  static async deactivate(apiClient: ApiClientV2, organisation: Organisation) {
    await apiClient.customPost(`organisation/${organisation.id}/deactivate`)
  }

  static async activate(apiClient: ApiClientV2, organisation: Organisation) {
    await apiClient.customPost(`organisation/${organisation.id}/activate`)
  }

  static async updatePasswordPolicy(
    apiClient: ApiClientV2,
    organisation: string,
    passwordPolicy: Organisation['password_policy']
  ) {
    await apiClient.customPost(
      `organisation/${organisation}/update-password-policy`,
      passwordPolicy
    )
  }
}

export const ORGANISATION_DEFAULT: Organisation = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  is_productive: true,
  password_policy: {
    version: 1,
    min_length: 5,
    alpha_upper: true,
    alpha_lower: true,
    numeric: false,
    special_char: false,
  },
  lifecycle_policy: {
    enable_reviews: false,
    require_reviewer: false,
  },
  meta: {},
  settings: {
    layout: CustomMandatorLayout.DEFAULT,
    enabledHiddenObjects: {
      equipmentGroups: [],
      equipments: [],
      equipmentVariants: [],
    },
  },
  avatar: '',
  slug: '',
  slug_unique: '',
  verified_address: {
    address_line_1: '',
    address_line_2: '',
    zip: '',
    town: '',
    province: '',
    country: '',
  },
}


import { Component, Vue, Watch } from 'vue-property-decorator'

import EventFeed from '@/apps/brelag/common/components/EventFeed.vue'

@Component({
  name: 'brelag-mandator-dashboard',
  components: {
    EventFeed,
  },
})
export default class BrelagMandatorDashboard extends Vue {
  $refs: {
    eventFeed: any
  }

  @Watch('$route')
  organisationChanged() {
    this.$refs.eventFeed.refresh()
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import BaseFormV2 from '@/components/common/forms/BaseFormV2.vue'
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard'
import { adminRouteName } from '@/apps/admin/app'

import { Group } from '@/models/core/models'

@Component({
  name: 'group-form',
  components: {
    BaseFormV2,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      Group,
    }
  },
  mixins: [BeforeLeaveGuard],
})
export default class GroupForm extends Vue {
  navigateOnSave = true

  beforeSaveHook(group: Group) {
    // if new group
    if (this.$props.id === '0') {
      // if a new group is created, don't navigate away in order to be able to add users
      this.navigateOnSave = false
      group.organisation = this.$store.getters['global/organisation'].id
    }
  }

  onCreated(group: Group) {
    // now that the group has been created, we want to show the details to add users
    // and navigate on save
    this.navigateOnSave = true
    this.$routerHandler.push({
      name: adminRouteName('group-detail'),
      params: {
        id: group.id,
      },
    })
  }
}

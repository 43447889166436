import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import { Location, Route, RouteConfig } from 'vue-router'

import MainMenu from '@/apps/brelag/mandator-user/components/MainMenu.vue'

import BrelagUserDashboard from './components/BrelagUserDashboard.vue'
import { updateStateForRoute } from '@/apps/routingUtils'
import { clientAppRegistryGet } from '@/apps/clientAppRegistry'
import { brelagUserRouteName, VIEW_ID } from '@/apps/brelag/mandator-user/app'

function beforeEnterBrelagUserApp(to: Route): Promise<void | Location> {
  const objectList = ['customer', 'project', 'building', 'floor']
  return updateStateForRoute(to, objectList)
}

let route: RouteConfig = { path: '' }

const wescoRoutes = [
  {
    path: 'wesco-dashboard',
    name: brelagUserRouteName('wesco-dashboard'),
    component: () => import('./custom-mandators/wesco/WescoDashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: 'wesco-customer',
    name: brelagUserRouteName('wesco-customer-root'),
    redirect: { name: brelagUserRouteName('wesco-customer-list') },
    component: RouterPassThrough,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: brelagUserRouteName('wesco-customer-list'),
        props: true,
        component: () =>
          import('./custom-mandators/wesco/WescoCustomerList.vue'),
        meta: {},
      },
      {
        path: 'create',
        name: brelagUserRouteName('wesco-customer-create'),
        component: () =>
          import('./custom-mandators/wesco/WescoCustomerForm.vue'),
        props: { id: '0' },
      },
      {
        path: ':id',
        name: brelagUserRouteName('wesco-customer-detail'),
        props: true,
        component: () =>
          import('./custom-mandators/wesco/WescoCustomerForm.vue'),
      },
    ],
  },
  {
    path: 'wesco-project',
    name: brelagUserRouteName('wesco-project-root'),
    redirect: (to) => {
      return {
        name: brelagUserRouteName('wesco-project-list'),
        query: {
          order_by: 'create_time_dsc',
        },
      }
    },
    component: RouterPassThrough,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: brelagUserRouteName('wesco-project-list'),
        props: true,
        component: () =>
          import('./custom-mandators/wesco/WescoProjectList.vue'),
      },
      {
        path: 'create',
        name: brelagUserRouteName('wesco-project-create'),
        component: () =>
          import('./custom-mandators/wesco/WescoProjectForm.vue'),
        props: { id: '0' },
      },
      {
        path: ':id/edit',
        name: brelagUserRouteName('wesco-project-edit'),
        component: () =>
          import('./custom-mandators/wesco/WescoProjectForm.vue'),
        props: true,
      },
      {
        path: ':id',
        name: brelagUserRouteName('wesco-project-editor'),
        props: true,
        component: () =>
          import('./custom-mandators/wesco/WescoProjectEditorView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'wesco-project',
            name: brelagUserRouteName('wesco-project-editor-detail'),
            props: true,
            component: () =>
              import('./custom-mandators/wesco/WescoProjectDetail.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'wesco-planer',
            name: brelagUserRouteName('wesco-planer'),
            props: true,
            component: () => import('./components/editor/planer/Planer.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
]

route = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: brelagUserRouteName('dashboard') },
  components: {
    default: RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterBrelagUserApp,
  },
  children: [
    {
      path: 'dashboard',
      name: brelagUserRouteName('dashboard'),
      component: BrelagUserDashboard,
      meta: { requiresAuth: true },
    },
    ...wescoRoutes,
    {
      path: 'knockaut-list',
      name: brelagUserRouteName('knockaut-list'),
      component: () => import('./components/knockaut/KnockautList.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: 'customer',
      name: brelagUserRouteName('customer-root'),
      redirect: { name: brelagUserRouteName('customer-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagUserRouteName('customer-list'),
          props: true,
          component: () => import('./components/customer/CustomerList.vue'),
          meta: {},
        },
        {
          path: 'create',
          name: brelagUserRouteName('customer-create'),
          component: () => import('./components/customer/CustomerForm.vue'),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagUserRouteName('customer-detail'),
          props: true,
          component: () => import('./components/customer/CustomerForm.vue'),
        },
      ],
    },
    {
      path: 'project',
      name: brelagUserRouteName('project-root'),
      redirect: (to) => {
        return {
          name: brelagUserRouteName('project-list'),
          query: {
            order_by: 'create_time_dsc',
          },
        }
      },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagUserRouteName('project-list'),
          props: true,
          component: () => import('./components/project/ProjectList.vue'),
        },
        {
          path: 'create',
          name: brelagUserRouteName('project-create'),
          component: () => import('./components/project/ProjectForm.vue'),
          props: { id: '0' },
        },
        {
          path: ':id/edit',
          name: brelagUserRouteName('project-edit'),
          component: () => import('./components/project/ProjectForm.vue'),
          props: true,
        },
        {
          path: ':id',
          name: brelagUserRouteName('project-editor'),
          props: true,
          component: () => import('./components/editor/ProjectEditorView.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'project',
              name: brelagUserRouteName('project-editor-detail'),
              props: true,
              component: () => import('./components/editor/ProjectDetail.vue'),
              meta: { requiresAuth: true },
            },
            {
              path: 'planer',
              name: brelagUserRouteName('planer'),
              props: true,
              component: () => import('./components/editor/planer/Planer.vue'),
              meta: { requiresAuth: true },
            },
            {
              path: 'floor-plan',
              name: brelagUserRouteName('floor-plan'),
              props: true,
              component: () =>
                import(
                  './components/editor/floor-plan/FloorPlanEditorView.vue'
                ),
              meta: { requiresAuth: true },
            },
            {
              path: 'parts-list',
              name: brelagUserRouteName('parts-list'),
              props: true,
              component: () =>
                import('./components/editor/parts-list/PartsList.vue'),
              meta: { requiresAuth: true },
            },
            {
              path: 'settings',
              name: brelagUserRouteName('settings'),
              props: true,
              component: () =>
                import('./components/editor/settings/Settings.vue'),
              meta: { requiresAuth: true },
            },
            {
              path: 'knockaut',
              name: brelagUserRouteName('knockaut'),
              props: true,
              component: () => import('./components/knockaut/Knockaut.vue'),
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: 'knockaut/:knockautId',
          name: brelagUserRouteName('knockaut-editor'),
          props: true,
          component: () => import('./components/knockaut/KnockautEditor.vue'),
        },
      ],
    },
    {
      path: 'building',
      name: brelagUserRouteName('building-root'),
      redirect: { name: brelagUserRouteName('building-create') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'create',
          name: brelagUserRouteName('building-create'),
          component: () => import('./components/building/BuildingForm.vue'),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagUserRouteName('building-detail'),
          props: true,
          component: () => import('./components/building/BuildingForm.vue'),
        },
      ],
    },
    {
      path: 'floor',
      name: brelagUserRouteName('floor-root'),
      redirect: { name: brelagUserRouteName('floor-create') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'create',
          name: brelagUserRouteName('floor-create'),
          component: () => import('./components/floor/FloorForm.vue'),
          props: { id: '0' },
          meta: {
            requiresList: {
              building: true,
              project: true,
            },
          },
        },
        {
          path: ':id',
          name: brelagUserRouteName('floor-detail'),
          props: true,
          component: () => import('./components/floor/FloorForm.vue'),
          meta: {
            requiresList: {
              building: true,
              project: true,
            },
          },
        },
      ],
    },
  ],
}

export default route

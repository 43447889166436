
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

@Component({
  name: 'search-fields',
  props: {
    label: {
      required: true,
    },
    searchQuery: {
      required: true,
    },
    placeholder: String,
    icon: {
      default: 'magnify',
    },
  },
  data() {
    return {
      searchTerm: (this.$route.query[this.$props.searchQuery] as string) || '',
    }
  },
})
export default class SearchField extends Vue {
  searchTerm: string
  searchQuery: string
  label: string

  @Watch('$route.query')
  onRouteChanged() {
    this.searchTerm = (this.$route.query[this.searchQuery] as string) || ''
  }

  mounted() {
    this.onRouteChanged()
  }

  get placeholderField() {
    if (this.$props.placeholder) {
      return this.$props.placeholder
    } else {
      return this.$tc('components.common.searchBy') + ' ' + this.label
    }
  }

  performSearch() {
    if (this.searchTerm) {
      this.$routerHandler.setQuery('', { [this.searchQuery]: this.searchTerm })
    } else {
      this.$routerHandler.removeFromQuery('', this.searchQuery)
    }
  }
}

import Vue from 'vue'
import { ApiClientV2, Collection } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  ListFilter,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'
import moment from 'moment'
import { Currency } from '@/models/commerce/offer'
import { KnockautProject } from './knockaut'

export type LicenseLength = 1 | 3

export interface LicenseOffer {
  length: LicenseLength
  months: number
  title: string
  color: string
  price: number
  currency: Currency
  vat_rate: string
  description: string
}

export const LICENSE_OFFERS: LicenseOffer[] = [
  {
    length: 1,
    title: 'Lizenz 1 Jahr',
    color: '#ffc300',
    price: 66,
    currency: Currency.CHF,
    vat_rate: '8.1',
    months: 12,
    description: '12 Monate',
  },
  {
    length: 3,
    title: 'Lizenz 3 Jahre',
    color: '#fc3a00',
    price: 198,
    currency: Currency.CHF,
    vat_rate: '8.1',
    months: 42,
    description: '42 Monate (6 Gratis Monate)',
  },
]

export interface OutputOdooCustomerSerializer {
  customer_id: number | null
  customer_email: string | null
}

export class KnockautLicenseKey extends TransientBaseObject {
  project: string
  license_key: string
  assigned_at: string
  assigned_by: string
  validity: LicenseLength

  static apiUrl = 'brelag/knockaut-license-key'
  static langPath: string = 'apps.brelag.models.knockaut-license-key'
  static objectType: string = 'KnockautLicenseKey'
  static fields: ModelField[] = []
  static listFields: ListModelField[] = [
    { key: 'validity' },
    { key: 'project_username' },
    { key: 'assigned_by' },
    {
      key: 'assigned_at',
      transform: (assigned_at: string) => {
        return moment(assigned_at).format('DD.MM.YYYY HH:mm')
      },
    },
  ]
  static listFilters: ListFilter[] = []
  static joins: Collection.RelatedAnnotation<KnockautLicenseKey>[] = [
    {
      relatedModelClass: KnockautProject,
      relatedObjectType: 'project',
      relatedObjectProperty: 'username',
    },
  ]

  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<KnockautLicenseKey, KnockautLicenseKey>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(KNOCKAUT_LICENSE_KEY_DEFAULT))
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...KNOCKAUT_LICENSE_KEY_DEFAULT },
    }
  }
}

export const KNOCKAUT_LICENSE_KEY_DEFAULT: KnockautLicenseKey = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  project: '',
  validity: 1,
  license_key: '',
  assigned_at: '',
  assigned_by: '',
}


import Vue from 'vue'
import Component from 'vue-class-component'
import {
  eGateConfig,
  EGATE_CONFIG_DEFAULT,
} from '@/apps/brelag/common/models/eGateConfig'
import FormBuilder from '@/components/common/forms/FormBuilder.vue'

@Component({
  name: 'formly_egate-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    FormBuilder,
  },
  data() {
    return {
      eGateConfig,
      formBuilderForm: {},
    }
  },
})
export default class EgateField extends Vue {
  eGateConfig: any

  get config() {
    return {
      ...this.eGateConfig,
      ...{
        model: this.$props.model[this.$props.field.key]
          ? this.$props.model[this.$props.field.key]
          : JSON.parse(JSON.stringify(EGATE_CONFIG_DEFAULT)),
      },
    }
  }

  mounted() {
    this.maybeApplyDefault()
  }

  maybeApplyDefault() {
    if (!this.$props.model[this.$props.field.key]) {
      // Load default config
      Vue.set(
        this.$props.model,
        this.$props.field.key,
        JSON.parse(JSON.stringify(EGATE_CONFIG_DEFAULT))
      )
    }
  }
}

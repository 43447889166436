
import { Component, Vue } from 'vue-property-decorator'
import { codemirror } from 'vue-codemirror'

@Component({
  name: 'formly_json-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    codemirror,
  },
  data() {
    return {
      editorOptions: {
        // codemirror options
        tabSize: 4,
        mode: {
          name: 'javascript',
          json: true,
        },
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
      },
    }
  },
})
export default class JsonField extends Vue {
  jsonString = ''
  errorMessage = ''

  mounted() {
    if (this.$props.model[this.$props.field.key]) {
      this.jsonString = JSON.stringify(
        this.$props.model[this.$props.field.key],
        null,
        2
      )
    }
  }

  validateJSON() {
    try {
      this.$props.model[this.$props.field.key] = JSON.parse(this.jsonString)
      this.errorMessage = ''
    } catch (err) {
      this.errorMessage = this.$errorHandler.errorToString(err)
    }
  }

  get label() {
    return this.$props.field.templateOptions.wrapper.properties.label
  }
}

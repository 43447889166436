import Vue from 'vue'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

export enum Currency {
  CHF = 'CHF',
}

export enum PaymentHandlingChoices {
  MANUALLY = 'MAN',
  AUTOMATICALLY = 'AUT',
}

export class Offer extends TransientBaseObject {
  organisation: string
  title: string
  description: string
  is_active: boolean
  meta: {
    [key: string]: any
  }
  vat_rate: number
  price: number
  currency: Currency

  static apiUrl = 'commerce/offer'
  static langPath: string = 'models.commerce.offer'
  static objectType: string = 'offer'
  static fields: ModelField[] = [
    { key: 'title' },
    { key: 'description' },
    { key: 'meta.article_number' },
    {
      key: 'is_active',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        label: 'Aktiv',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'vat_rate',
      formProperties: {
        inputType: 'number',
        step: 0.01,
        min: 0,
      },
      required: true,
      formFieldType: FormFieldType.NUMBER_FIELD,
    },
    {
      key: 'meta.num_coins',
      formProperties: {
        inputType: 'number',
        min: 1,
        step: 1,
      },
      required: true,
      formFieldType: FormFieldType.NUMBER_FIELD,
    },
    {
      key: 'meta.color',
      formFieldType: FormFieldType.COLOR_FIELD,
    },
    {
      key: 'price',
      formProperties: {
        inputType: 'number',
        step: 0.01,
        min: 0,
      },
      required: true,
      formFieldType: FormFieldType.NUMBER_FIELD,
    },
    {
      key: 'currency',
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        options: [
          {
            text: 'CHF',
            value: Currency.CHF,
          },
        ],
      },
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'title' },
    { key: 'description' },
    {
      key: 'vat_rate',
      transform: (price: string) => {
        return parseFloat(price).toFixed(2)
      },
    },
    { key: 'meta.num_coins' },
    {
      key: 'price',
      transform: (price: string) => {
        return parseFloat(price).toFixed(2)
      },
    },
    { key: 'currency' },
  ]

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: Offer.formFields(),
      model: { ...OFFER_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Offer, Offer>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(OFFER_DEFAULT))
  }

  static pricePerCoin(offer: Offer): string | null {
    if (offer.meta && offer.meta.num_coins !== 0) {
      return (offer.price / offer.meta.num_coins).toFixed(2)
    } else {
      return null
    }
  }

  static async buyOffer(
    url: string,
    apiClient: ApiClientV2,
    fail_url: string,
    abort_url: string,
    success_url: string,
    offer: string,
    organisation: string,
    payment_handling: PaymentHandlingChoices
  ): Promise<{
    order_id: string
    notify_url: string
    redirect_url: string
  }> {
    return apiClient.customPost(url, {
      fail_url,
      abort_url,
      success_url,
      offer,
      organisation,
      payment_handling,
    })
  }
}

export const OFFER_DEFAULT: Offer = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  title: '',
  description: '',
  is_active: true,
  vat_rate: 7.7,
  meta: {
    num_coins: 0,
    color: '#000000',
  },
  currency: Currency.CHF,
  price: 0,
}

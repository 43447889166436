
import Vue from 'vue'
import Component from 'vue-class-component'
import VueUploadComponent from 'vue-upload-component'
import { TransientBaseObject } from '@/models/core/base'
import { Watch } from 'vue-property-decorator'
import { getTranslation } from '@/lang/setup'

export interface UploadState {
  active: boolean
  blob: string
  data: Object
  error: string
  file: File
  fileObject: boolean
  headers: Object
  id: string
  name: string
  postAction: string
  progress: string
  putAction: string
  response: TransientBaseObject
  size: number
  speed: number
  success: boolean
  thumb: string
  timeout: number
  type: string
  xhr: XMLHttpRequest
}

declare var window: any

@Component({
  components: {
    'file-upload': VueUploadComponent,
  },
  props: {
    uploadUrl: {
      default: '/api/v1/avatar/',
    },
    name: {
      default: 'image',
    },
    multiple: {
      default: true,
    },
    title: {
      required: false,
    },
    maxSize: {
      default: 1024 * 1024 * 100, // 100 MB
    },
    extensions: {
      default: () => undefined,
    },
    accept: {
      default: 'image/png,image/gif,image/jpeg,image/webp',
    },
    dropFileText: {
      default: getTranslation('components.common.uploadForm.dropToUpload'),
    },
    autoUpload: {
      default: false,
    },
    confirmUploadMessage: {
      default: null,
    },
    additionalData: {
      default: () => ({}),
    },
  },
  data() {
    return {
      files: [],
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      chunkEnabled: false,
      chunk: {
        action: this.$props.uploadUrl,
        minSize: 1 * 1024 * 1024,
        maxActive: 3,
        maxRetries: 3,
      },
      thread: 3,
      postAction: this.$props.uploadUrl,
      headers: {
        ...this.$store.state.global.axiosConfig.headers,
      },
    }
  },
})
export default class UploadForm extends Vue {
  $refs: {
    upload: any
  }
  files: any[]

  @Watch('files')
  onFilesChanged() {
    let success = true
    this.files.forEach((file) => {
      if (!file.success) {
        success = false
      }
    })
    if (success) {
      this.$emit('success')
    }
  }

  get uploadedFiles() {
    return this.files.filter((file) => {
      return file.success
    })
  }

  updatedValue(value: UploadState) {
    this.$emit('input', value)
  }

  // add, update, remove File Event
  inputFile(newFile, oldFile) {
    if (newFile && oldFile) {
      // update
      if (newFile.active && !oldFile.active) {
        // beforeSend
        // TODO: Could check for size requirements
        // this.$refs.upload.update(newFile, { error: 'size' })
      }
      if (newFile.progress !== oldFile.progress) {
        // progress
      }
      if (newFile.error && !oldFile.error) {
        // error
        if (newFile.response[this.$props.name]) {
          let errorResponse = newFile.response[this.$props.name]
          if (errorResponse instanceof Array) {
            newFile.error = errorResponse[0]
          } else if (errorResponse instanceof String) {
            newFile.error = errorResponse
          }
        }
        if (newFile.error === 'extension') {
          newFile.error = this.$i18n.tc(
            'components.common.uploadForm.invalidExtension'
          )
        } else if (newFile.response && newFile.response.image) {
          if (newFile.response.image instanceof Array) {
            newFile.error = newFile.response.image[0]
          } else if (newFile.response.image instanceof String) {
            newFile.error = newFile.response.image
          }
        }
        console.log('error', newFile)
      }
      if (newFile.success && !oldFile.success) {
        // success
      }
    }
    if (!newFile && oldFile) {
      // remove
      if (oldFile.success && oldFile.response.id) {
        // TODO: Could remove from server here
        // $.ajax({
        //   type: 'DELETE',
        //   url: '/upload/delete?id=' + oldFile.response.id,
        // })
      }
    }
    // Automatically activate upload
    if (
      Boolean(newFile) !== Boolean(oldFile) ||
      oldFile.error !== newFile.error
    ) {
      if (this.$props.autoUpload && !this.$refs.upload.active) {
        this.$refs.upload.active = true
      }
    }
  }

  inputFilter(newFile, oldFile, prevent) {
    if (newFile && !oldFile) {
      // Before adding a file
      // Filter system files or hide files
      if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
        return prevent()
      }
      // Filter php html js file
      if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
        return prevent()
      }
    }
    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
      // Thumbnails
      newFile.thumb = ''
      if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
        newFile.thumb = newFile.blob
      }
    }
  }
}

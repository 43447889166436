import moment from 'moment'
import axios from 'axios'

import { svgAsPngUri } from 'save-svg-as-png'

let dataUri = ''
declare let require

const pdfMake = require('pdfmake/build/pdfmake.js')
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { FloorPlanEditor } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditor'
import {
  PartsList,
  ProjectEditor,
} from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import {
  ReceiverArrangement,
  SenderArrangement,
  DeviceArrangementChannelInfo,
  GroupsArrangement,
} from '@/apps/brelag/mandator-user/project-editor/arrangementUtil'
import store from '@/store'
import { Floor } from '@/apps/brelag/common/models/floor'
import { apiClientV2 } from '@/api/ApiClientV2'
import {
  Project,
  ProjectDump,
  ProjectStatusLabel,
} from '@/apps/brelag/common/models/project'
import { Customer } from '@/apps/brelag/common/models/customer'
import {
  DeviceAssignment,
  BrelagConfigKey,
} from '@/apps/brelag/common/models/equipment'
import { INT_TO_NETMASK_MAP } from '@/apps/brelag/common/models/eGateConfig'
import {
  getMaxFlexLayoutByValue,
  MaxFlexButtonFunction,
  MaxFlexKeyConfig,
  LocatingKeyButtonMap,
  MAX_FLEX_TYPE_MAP,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import { EXPORT_LAYOUT_EMPTY } from './shapes/layout_empty'
import { EXPORT_LAYOUT_BG_IMAGE } from './shapes/layout_bg_image'
import { EXPORT_LAYOUT_BG_IMAGE_GREY } from './shapes/layout_bg_image_grey'
import { BRELAG_LOGO } from './shapes/brelag_logo'
import * as Buffer from 'buffer'
import { Building } from '../../common/models/building'
;(pdfMake as any).vfs = pdfFonts.pdfMake.vfs

const paths = require('material-design-icons-svg/paths')
const icons = require('material-design-icons-svg')(paths)

async function loadFloorPlan(projectEditor, canvas, floorId) {
  return new Promise(async (resolve, reject) => {
    await createFloorPlanPng(projectEditor, floorId, canvas, resolve)
  })
}

async function getMandatorLogo(): Promise<string> {
  const organisation = store.getters['global/organisation']
  if (organisation.avatar) {
    const url = apiClientV2.getAttachmentUrl(organisation.avatar)
    const config = {
      ...store.state.global.axiosConfig,
      responseType: 'arraybuffer',
    }
    // get image as base64 string
    const result = await axios.get(url, config).then((response) => {
      const prefix = `data:${response.headers['content-type']};base64,`
      return (
        prefix + Buffer.Buffer.from(response.data, 'binary').toString('base64')
      )
    })
    return result
  } else {
    return Promise.resolve('')
  }
}

/**
 * Exports floors as PDF
 * @param projectEditor instance of project editor
 * @param selectedFloors can be used to export a subset of floors. If undefined, will export all floors
 * @returns
 */
export async function exportPDF(
  projectEditor: ProjectEditor,
  selectedFloors: string[]
) {
  const logo = await getMandatorLogo()
  const project = projectEditor.project
  const projectNr = Project.convertNumber(project.number)
  const projectLeader = project.meta.project_leader
  const supervisor = project.meta.supervisor
  const plan_drawer = project.meta.plan_drawer
  const programmer = project.meta.programmer
  let date
  if (project.meta.date) {
    date = moment(project.meta.date).format('DD.MM.YYYY')
  } else {
    date = moment(new Date()).format('DD.MM.YYYY')
  }

  const floorImageMap: Map<string, string> = new Map()

  // Get all parts list of current floor and divide into three columns
  const getPartsColumns = (floor: Floor) => {
    const partsList = []
    const floorParts = projectEditor.partsList().floors[floor.id]
    if (floorParts && floorParts.parts) {
      for (const id of Object.keys(floorParts.parts)) {
        const part = floorParts.parts[id]
        partsList.push({
          style: 'footer_parts',
          text: `${part.amount} x ${part.label}`,
        })
      }
    }

    const partsColumns = [
      {
        stack: [],
        width: '32%',
      },
      {
        stack: [],
        width: '32%',
      },
      {
        stack: [],
        width: '32%',
      },
    ]
    for (let i = 0; i < partsList.length; i++) {
      if (i < 8) {
        partsColumns[0].stack.push(partsList[i])
      } else if (i < 16) {
        partsColumns[1].stack.push(partsList[i])
      } else if (i < 24) {
        partsColumns[2].stack.push(partsList[i])
      }
    }
    return partsColumns
  }

  const floorList: Floor[] = []
  const buildingMap: Map<string, Building> = new Map()
  const addBuildingPrefix = project.buildings.length > 1
  for (const building of project.buildings) {
    buildingMap.set(building.id, building)
    for (const floor of building.floors) {
      // If a subset of floors is defined, only include the floor if its in there
      if (selectedFloors) {
        if (selectedFloors.includes(floor.id)) {
          floorList.push(floor)
        }
      } else {
        floorList.push(floor)
      }
    }
  }

  // only add fields that exist to avoid unnecessary commas
  let title = project.title
  if (project.commission) {
    title += ` (${project.commission})`
  }
  if (project.meta.address) {
    title += `, ${project.meta.address}`
  }
  if (project.meta.zip || project.meta.city) {
    title += `, ${project.meta.zip} ${project.meta.city}`
  }
  const currentVersion = await projectEditor.getCurrentVersion()
  const filename = `V${currentVersion}, ${title}, ${date}.pdf`

  const CHANGELOG_MAX_LENGTH = 8
  const versionLog = await projectEditor.getVersionLog(CHANGELOG_MAX_LENGTH)
  const changeLog = []
  for (const change of versionLog) {
    if (changeLog.length < CHANGELOG_MAX_LENGTH) {
      changeLog.push({
        style: 'footer_parts',
        text: change,
      })
    }
  }

  const numFloors = floorList.length
  const pagesContents = []
  let i = 0
  for (const floor of floorList) {
    // Draw SVG onto canvas which can then be exported as image
    const canvas = document.createElement('canvas') as HTMLCanvasElement

    let imgSrc = EXPORT_LAYOUT_EMPTY
    if (floor.processed_file) {
      try {
        await loadFloorPlan(projectEditor, canvas, floor.id)
        imgSrc = dataUri // canvas.toDataURL('image/png')
      } catch (err) {
        // No valid image, create report anyway
      }
    }
    floorImageMap.set(floor.id, imgSrc)

    const status = `${ProjectStatusLabel[project.status]}    `

    const content: any[] = [
      {
        columns: [
          // margin [left, top, right, bottom]
          {
            text: 'Globalnr.:',
            style: 'highlight',
            margin: [10, 28, 5, 0],
            width: 'auto',
          },
          {
            text: projectNr,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
          {
            text: 'Projektleitung:',
            style: 'highlight',
            margin: [0, 28, 5, 0],
            width: 'auto',
          },
          {
            text: projectLeader,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
          {
            text: 'Supervisor:',
            style: 'highlight',
            margin: [0, 28, 5, 0],
            width: 'auto',
          },
          {
            text: supervisor,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
          {
            text: 'Planzeichnung:',
            style: 'highlight',
            margin: [0, 28, 5, 0],
            width: 'auto',
          },
          {
            text: plan_drawer,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
          {
            text: 'Programmierung:',
            style: 'highlight',
            margin: [0, 28, 5, 0],
            width: 'auto',
          },
          {
            text: programmer,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
          {
            text: 'Plandatum:',
            style: 'highlight',
            margin: [83, 28, 5, 0],
            width: 'auto',
          },
          {
            text: date,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
          {
            text: 'Geschoss:',
            style: 'highlight',
            margin: [0, 28, 5, 0],
            width: 'auto',
          },
          {
            text: floor.title,
            style: 'normal',
            margin: [0, 28, 0, 0],
            width: 100,
          },
        ],
      },
    ]
    if (imgSrc !== '') {
      content.push({
        image: imgSrc,
        fit: [1160, 580],
        margin: [0, 20, 0, 0],
        alignment: 'center',
        pageBreak: i + 1 < numFloors ? 'after' : '',
      })
    }
    let floorText = floor.prefix
    if (addBuildingPrefix) {
      floorText = `${buildingMap.get(floor.building).prefix}: ${floor.prefix}`
    }
    pagesContents.push([
      [
        {
          text: status,
          style: 'header',
          margin: [820, -35, 0, 0],
        },
        {
          text: floorText,
          style: 'header_big',
          margin: [720, -25, 0, 0],
        },
      ],
      content,
    ])
    i++
  }

  let headerTitleStyle = 'header'
  if (title.length > 90) {
    headerTitleStyle = 'header_small'
  }

  // get footer of current page
  const getFooter = (currentPage: number) => {
    return [
      {
        columns: [
          {
            text: filename,
            style: 'footer_normal',
            margin: [65, 8.3, 0, 0],
            width: 554,
          },
          {
            stack: changeLog,
            style: 'footer_parts',
            width: 362,
            margin: [0, -58, 0, 0],
          },
          {
            columns: getPartsColumns(floorList[currentPage - 1]),
            fontSize: 16,
            margin: [0, -58, 0, 0],
            width: 280,
          },
        ],
      },
    ]
  }

  // // only for testing (cannot use function in playground)
  // const getFooter = [
  //   {
  //     columns: [
  //       {
  //         text: filename,
  //         style: 'footer_normal',
  //         margin: [65, 8.3, 0, 0],
  //         width: 554,
  //       },
  //       {
  //         stack: changeLog,
  //         style: 'footer_parts',
  //         width: 362,
  //         margin: [0, -58, 0, 0],
  //       },
  //       {
  //         columns: getPartsColumns(floorList[0]),
  //         fontSize: 16,
  //         margin: [0, -58, 0, 0],
  //         width: 280,
  //       },
  //     ],
  //   },
  // ]

  let header = []
  if (logo) {
    header = [
      {
        columns: [
          {
            image: logo,
            fit: [140, 25], // make logo fit into rectangle
            margin: [28, 20, 0, 0],
          },
          {
            text: title,
            style: headerTitleStyle,
            margin: [-600, 25, 0, 0],
          },
        ],
      },
    ]
  } else {
    header = [
      {
        columns: [
          {
            text: title,
            style: headerTitleStyle,
            margin: [280, 25, 200, 0],
            width: '81%',
          },
        ],
      },
    ]
  }

  const dd = {
    pageSize: 'A3',
    pageOrientation: 'landscape',
    pageMargins: [20, 60, 40, 40],

    background: {
      image: 'bgImage',
      width: 1190,
    },

    header: header,
    footer: getFooter,

    content: pagesContents,

    styles: {
      header: {
        fontSize: 15,
        bold: true,
        // if custom logo -> black text on white background, else white text on blue background
        color: logo ? '#000000' : '#FFFFFF',
        margin: [20, 20, 20, 20],
        alignment: 'center',
      },
      header_small: {
        fontSize: 13,
        bold: true,
        color: logo ? '#000000' : '#FFFFFF',
        margin: [20, 20, 20, 20],
        alignment: 'center',
      },
      header_big: {
        fontSize: 22,
        bold: true,
        color: logo ? '#000000' : '#FFFFFF',
        alignment: 'right',
        margin: [20, 20, 20, 20],
      },
      footer_highlight: {
        fontSize: 9,
        // if custom logo -> grey else blue
        color: logo ? '#6c6c6c' : '#1d77b6',
      },
      footer_normal: {
        fontSize: 9,
        alignment: 'left',
      },
      footer_parts: {
        fontSize: 7,
        alignment: 'left',
      },
      highlight: {
        fontSize: 8,
        bold: true,
        color: logo ? '#6c6c6c' : '#1d77b6',
      },
      normal: {
        fontSize: 8,
      },
    },
    // Image converted using https://www.site24x7.com/tools/image-to-datauri.html
    images: {
      bgImage: logo ? EXPORT_LAYOUT_BG_IMAGE_GREY : EXPORT_LAYOUT_BG_IMAGE,
    },
  }

  // Following line can be used for debugging in http://pdfmake.org/playground.html
  // console.log(JSON.stringify(dd, null, 2))

  const pdf = pdfMake.createPdf(dd)
  pdf.download(filename)

  if (projectEditor.readOnly) {
    return
  } else {
    // Update exported files
    const axiosConfig = JSON.parse(
      JSON.stringify(store.state.global.axiosConfig)
    )
    axiosConfig.headers['Content-Type'] = 'multipart/form-data'
    for (const id of floorImageMap.keys()) {
      const blob = dataURLtoBlob(floorImageMap.get(id))
      const formData = new FormData()
      formData.append('file', blob, 'exported_file.png')
      const result = await axios.post(Floor.uploadUrl, formData, axiosConfig)
      if (result.status === 200) {
        const floor = await apiClientV2.get<Floor>(Floor, id)
        floor.exported_file = result.data.id
        try {
          await apiClientV2.update(Floor, floor, {
            beforeSaveOptions: {
              noLock: true,
            },
            afterSaveOptions: {
              noUnlock: true,
            },
          })
        } catch (err) {
          // Maybe project is locked
          console.warn(err)
        }
      }
    }
  }
}

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export async function createFloorPlanPng(
  projectEditor: ProjectEditor,
  floorId: string,
  canvas,
  cb
) {
  let svg
  try {
    svg = document.getElementById('drawing')
  } catch (err) {
    throw new Error(
      'SVG nicht gefunden. Export funktioniert nur im Bauplan Ansicht.'
    )
  }
  try {
    const editor = FloorPlanEditor.getInstance(projectEditor.store)
    await editor.setFloor(floorId)

    // This is not ideal, but the image load callback seems buggy and sometimes the image is not fully loaded yet
    const sleep = (m) => new Promise((r) => setTimeout(r, m))
    await sleep(3)

    // The PDF library creates a grey border on the edge of images with a transparent background.
    // Set the background to white to eliminate it
    const floorPlan = svg.firstChild
    floorPlan.style.backgroundColor = 'white'
    svgAsPngUri(
      floorPlan,
      {
        top: floorPlan.viewBox.baseVal.y,
        left: floorPlan.viewBox.baseVal.x,
        scale: 2,
      },
      function (uri) {
        dataUri = uri
        cb(uri)
      }
    )
  } catch (err) {
    // No valid plan
    cb(null)
    return
  }
}

function getExportHeader(project: ProjectDump, logo: string = '') {
  const customer: Customer = project.customer
  const addressArray = []
  if (project.meta.address) {
    addressArray.push(project.meta.address)
  }
  if (project.meta.zip || project.meta.city) {
    addressArray.push(`${project.meta.zip} ${project.meta.city}`.trim())
  }

  const customerName = customer.name
    ? customer.name
    : `Kd.-Nr.: ${customer.number}`
  return [
    {
      margin: [40, 30, 40, 0],
      columns: [
        [
          {
            image: logo || BRELAG_LOGO,
            fit: [140, 35], // make logo fit into rectangle
          },
        ],
        [
          {
            text: `${Project.convertNumber(project.number)}`,
            style: {
              bold: true,
              alignment: 'center',
            },
          },
          {
            text: project.commission
              ? `${project.title} (${project.commission})`
              : project.title,
            style: {
              bold: true,
              alignment: 'center',
            },
          },
          {
            text: addressArray.join(', '),
            style: {
              alignment: 'center',
            },
          },
        ],
        [
          {
            text: `${customerName}`,
            style: {
              alignment: 'right',
            },
          },
          {
            text: `${customer.meta.address}`,
            style: {
              alignment: 'right',
            },
          },
          {
            text: `${customer.meta.zip} ${customer.meta.city}`,
            style: {
              alignment: 'right',
            },
          },
        ],
      ],
    },
  ]
}

function getExportFooter(currentPage: string, pageCount: number, name: string) {
  return [
    {
      margin: [40, 0, 40, 0],
      columns: [
        {
          text: name,
          alignment: 'left',
        },
        {
          text: `${currentPage.toString()} / ${pageCount}`,
          alignment: 'center',
        },
        {
          text: `Druck: ${moment(new Date()).format('DD.MM.YYYY')}`,
          alignment: 'right',
        },
      ],
    },
  ]
}

export async function exportArrangementReceivers(
  project: ProjectDump,
  receivers: ReceiverArrangement[],
  selectedFloors: Floor[] = null
) {
  const logo = await getMandatorLogo()
  const content = []
  const partsList = []

  const createTable = (partsContent: any[], title: string) => {
    const out: any[] = [
      {
        text: title,
        style: 'tableHeader',
        margin: [0, 10, 0, 0],
      },
    ]
    if (partsContent.length === 0) {
      out.push({
        text: 'Keine Empfänger',
        margin: [0, 10, 0, 0],
      })
    } else {
      out.push({
        style: 'tableExample',
        table: {
          widths: [70, 70, 200, 220, 170],
          headerRows: 1,
          body: [
            [
              { text: 'Nr.', style: 'tableHeader', alignment: 'left' },
              { text: 'Typ', style: 'tableHeader', alignment: 'left' },
              { text: 'Sender', style: 'tableHeader', alignment: 'left' },
              { text: 'Config', style: 'tableHeader', alignment: 'left' },
              { text: 'Bemerkung', style: 'tableHeader', alignment: 'left' },
            ],
            ...partsContent,
          ],
        },
      })
    }
    return out
  }

  const createRow = (receiver) => {
    let receiverText = ''
    for (const channel of Object.keys(receiver.mergedSenders)) {
      if (receiverText !== '') {
        receiverText += '\n'
      }
      receiverText += `${receiver.mergedSenders[channel].prettyName}:`
      receiverText += `\n${receiver.mergedSenders[channel].nonRepeating}`
      if (receiver.mergedSenders[channel].repeating !== '') {
        receiverText += `\nR: ${receiver.mergedSenders[channel].repeating}`
      }
    }

    let config = ''
    for (const key of Object.keys(receiver.externalConfig)) {
      if (key === BrelagConfigKey.EGATE) {
        config += `DHCP: ${receiver.externalConfig[key].dhcp}\n`
        config += `IP: ${receiver.externalConfig[key].ip}\n`
        config += `Maske: ${receiver.externalConfig[key].netmask}\n`
        config += `Gateway: ${receiver.externalConfig[key].gateway}\n`
        config += `DNS: ${receiver.externalConfig[key].dns}\n`
        config += `Port: ${receiver.externalConfig[key].port}\n`
      } else {
        config += `${key}: ${receiver.externalConfig[key]}\n`
      }
    }
    return [
      {
        text: receiver.device_number,
        alignment: 'left',
      },
      {
        text: receiver.equipment_variant,
        alignment: 'left',
      },
      {
        text: receiverText,
        alignment: 'left',
      },
      {
        text: config,
        alignment: 'left',
      },
      {
        text: receiver.remark,
        alignment: 'left',
      },
    ]
  }

  if (selectedFloors) {
    for (const floor of selectedFloors) {
      const floorPartsList = []
      for (const receiver of receivers) {
        if (receiver.floor === floor.id) {
          floorPartsList.push(createRow(receiver))
        }
      }
      content.push(createTable(floorPartsList, floor.title))
    }
  } else {
    for (const receiver of receivers) {
      partsList.push(createRow(receiver))
    }
    content.push(createTable(partsList, 'Alle Stockwerke'))
  }

  const dd = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 80, 40, 40],

    header: getExportHeader(project, logo),
    footer: function (currentPage, pageCount) {
      return getExportFooter(currentPage, pageCount, 'Empfänger-Anordnung')
    },
    content,
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
  }

  // Following line can be used for debugging in http://pdfmake.org/playground.html
  // console.log(JSON.stringify(dd, null, 2))

  const pdf = pdfMake.createPdf(dd)
  const date = moment(new Date()).format('DD.MM.YYYY')
  pdf.download(`EmpfaengerAnordnung_${date}.pdf`)
}

/**
 *
 * @param project
 * @param eGate
 * @param columns
 * @param fontSize manual override of font size
 */
export async function exportArrangementEGate(
  project: ProjectDump,
  eGate: DeviceAssignment,
  columns: {
    storageId: number
    sender: string
  }[][],
  fontSize?: number
) {
  const logo = await getMandatorLogo()
  const places = []
  let i = 0
  let maxSenderNameLength = 0
  for (const column of columns) {
    places.push([])
    for (const part of column) {
      if (part.sender.length > maxSenderNameLength) {
        maxSenderNameLength = part.sender.length
      }
      places[i].push([
        {
          text: part.storageId,
          alignment: 'left',
        },
        {
          text: part.sender,
          alignment: 'left',
          style: 'sender',
        },
      ])
    }
    i += 1
  }

  const columnTable = (i) => {
    return {
      style: 'tableExample',
      table: {
        widths: [20, 60],
        headerRows: 1,
        body: [
          [
            { text: 'Platz', style: 'tableHeader', alignment: 'left' },
            { text: 'Sender-Kanal', style: 'tableHeader', alignment: 'left' },
          ],
          ...places[i],
        ],
      },
    }
  }

  const getFontSize = () => {
    if (maxSenderNameLength < 15) {
      return 8
    } else if (maxSenderNameLength < 17) {
      return 7
    } else if (maxSenderNameLength < 19) {
      return 6
    } else if (maxSenderNameLength < 21) {
      return 5
    } else {
      return 4
    }
  }

  const dd = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 80, 40, 40],

    header: getExportHeader(project, logo),
    footer: function (currentPage, pageCount) {
      return getExportFooter(currentPage, pageCount, 'eGate ID-Anordnung')
    },
    content: [
      {
        columns: [
          [
            {
              text: `Empfänger-Nr: ${eGate.device_number}`,
            },
            {
              text: `DHCP: ${eGate.config.egate.dhcp ? 'Ja' : 'Nein'}`,
            },
            {
              text: `IP Adresse: ${eGate.config.egate.ip}`,
            },
            {
              text: `Maske: ${INT_TO_NETMASK_MAP[eGate.config.egate.netmask]}`,
            },
          ],
          [
            {
              text: `Gateway: ${eGate.config.egate.gateway}`,
            },
            {
              text: `DNS: ${eGate.config.egate.dns}`,
            },
            {
              text: `Port: ${eGate.config.egate.port}`,
            },
          ],
        ],
      },
      {
        columns: [
          columnTable(0),
          columnTable(1),
          columnTable(2),
          columnTable(3),
          columnTable(4),
        ],
      },
    ],

    styles: {
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: 'black',
      },
      sender: {
        fontSize: fontSize ? fontSize : getFontSize(),
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
  }

  // Following line can be used for debugging in http://pdfmake.org/playground.html
  // console.log(JSON.stringify(dd, null, 2))
  const pdf = pdfMake.createPdf(dd)
  pdf.download('eGate_ID-Anordnung.pdf')
}

export async function exportArrangementSenders(
  project: ProjectDump,
  senders: SenderArrangement[],
  selectedFloors: Floor[] = null
) {
  const logo = await getMandatorLogo()
  const projectEditor: ProjectEditor = ProjectEditor.instance
  const content = []
  const partsList = []
  const maxflexRows = []

  const createTable = (partsContent: any[], title: string) => {
    const out: any[] = [
      {
        text: title,
        style: 'tableHeader',
        margin: [0, 10, 0, 0],
      },
    ]
    if (partsContent.length === 0) {
      out.push({
        text: 'Keine Sender',
        margin: [0, 10, 0, 0],
      })
    } else {
      out.push({
        style: 'tableExample',
        table: {
          widths: [65, 70, 70, 50, 50, 50, 50, 50, 50, 50, 50, 55],
          heights: [],
          headerRows: 1,
          body: [
            [
              { text: 'Nr.', style: 'tableHeader', alignment: 'left' },
              { text: 'Ort', style: 'tableHeader', alignment: 'left' },
              { text: 'Typ', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 1', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 2', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 3', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 4', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 5', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 6', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 7', style: 'tableHeader', alignment: 'left' },
              { text: 'Kanal 8', style: 'tableHeader', alignment: 'left' },
              { text: 'Bemerkung', style: 'tableHeader', alignment: 'left' },
            ],
            ...partsContent,
          ],
        },
      })
    }
    return out
  }

  const parseChannel = (channel: DeviceArrangementChannelInfo) => {
    let text = ''
    if (channel) {
      text += channel.nonRepeating
      if (channel.repeating !== '') {
        text += `\n${channel.repeating}`
      }
    }
    return text
  }

  const createRow = (sender) => {
    return [
      {
        text: sender.device_number,
        alignment: 'left',
      },
      {
        text: sender.place,
        alignment: 'left',
      },
      {
        text: sender.equipment_variant,
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_1),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_2),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_3),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_4),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_5),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_6),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_7),
        alignment: 'left',
      },
      {
        text: parseChannel(sender.channel_8),
        alignment: 'left',
      },
      {
        text: sender.remark,
        alignment: 'left',
      },
    ]
  }

  const createMaxFlexRow = (sender) => {
    const layout = getMaxFlexLayoutByValue(
      sender.config.maxflex.KeyLayout.value
    )
    layout.blocks
    const body = []
    const heights = []

    for (let i = 0; i < layout.blocks.length; i++) {
      const block = layout.blocks[i]
      const keyConfig: MaxFlexKeyConfig =
        sender.config.maxflex.keys[block.button]
      const nightLEDEnabled =
        LocatingKeyButtonMap[block.button] ===
        sender.config.maxflex.LocatingKey.value
      if (block.width === 1) {
        i += 1
        const block2 = layout.blocks[i]
        const keyConfig2 = sender.config.maxflex.keys[block2.button]
        const nightLEDEnabled2 =
          LocatingKeyButtonMap[block2.button] ===
          sender.config.maxflex.LocatingKey.value
        body.push([
          maxFlexButton(keyConfig, nightLEDEnabled, block.width),
          maxFlexButton(keyConfig2, nightLEDEnabled2, block.width),
        ])
      } else {
        // width must be 2,
        body.push([
          maxFlexButton(keyConfig, nightLEDEnabled, block.width),
          // add second empty column to fix display of layouts where first row is width 2 and others 1
          {},
        ])
      }
      // The heights don't work out exactly as multiples of 25 (probably some table margins)
      // -> manual adjustment
      if (block.height === 1) {
        heights.push(25)
      } else if (block.height === 2) {
        heights.push(55)
      } else if (block.height === 4) {
        heights.push(116)
      }
    }
    // one layoutTable per device
    const layoutTable = {
      // fixed width per device to fill row from left
      width: 150,
      stack: [
        {
          text: `${sender.device_number} - ${sender.equipment_variant}`,
        },
        {
          table: {
            widths: [55, 55],
            heights,
            headerRows: 0,
            body,
          },
        },
      ],
    }
    if (maxflexRows.length === 0) {
      maxflexRows.push({
        columns: [],
        pageBreak: 'before',
      })
    } else if (maxflexRows[maxflexRows.length - 1].columns.length === 5) {
      if (maxflexRows.length % 3 === 0) {
        maxflexRows.push({
          columns: [],
          pageBreak: 'before',
        })
      } else {
        maxflexRows.push({
          columns: [],
          // margin top for row 2 and 3 per page
          margin: [0, 10, 0, 0],
        })
      }
    }
    maxflexRows[maxflexRows.length - 1].columns.push(layoutTable)
  }

  if (selectedFloors) {
    for (const floor of selectedFloors) {
      const floorPartsList = []
      for (const sender of senders) {
        if (sender.floor === floor.id) {
          floorPartsList.push(createRow(sender))
          if (projectEditor.deviceHandler.isMaxFlex(sender.id)) {
            createMaxFlexRow(sender)
          }
        }
      }
      content.push(createTable(floorPartsList, floor.title))
    }
  } else {
    for (const sender of senders) {
      partsList.push(createRow(sender))
      if (projectEditor.deviceHandler.isMaxFlex(sender.id)) {
        createMaxFlexRow(sender)
      }
    }
    content.push(createTable(partsList, 'Alle Stockwerke'))
  }

  const dd = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 80, 40, 40],

    header: getExportHeader(project, logo),
    footer: function (currentPage, pageCount) {
      return getExportFooter(currentPage, pageCount, 'Sender-Anordnung')
    },
    content: [...content, ...maxflexRows],

    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
  }

  // Following line can be used for debugging in http://pdfmake.org/playground.html
  // console.log(JSON.stringify(dd, null, 2))
  const pdf = pdfMake.createPdf(dd)
  const date = moment(new Date()).format('DD.MM.YYYY')
  pdf.download(`SenderAnordnung_${date}.pdf`)
}

function maxFlexButton(
  keyConfig: MaxFlexKeyConfig,
  nightLEDEnabled: boolean,
  width: number
) {
  const text = `Kanal ${keyConfig.Channel.value + 1}: ${
    MaxFlexButtonFunction[keyConfig.CommandGrp.value].name
  }`
  const icon = (keyConfig.Icon && keyConfig.Icon.value) || ''
  const iconSVG = icon ? icons.getSVG(icon) : ''
  const textField = (keyConfig.Text && keyConfig.Text.value) || ''
  const nightLED = nightLEDEnabled ? icons.getSVG('checkbox-blank') : ''
  return maxFlexButtonLayout(text, textField, nightLED, iconSVG, width)
}

function maxFlexButtonLayout(
  text: string,
  textField: string,
  nightLED: string,
  icon: string,
  width: number
) {
  const columns = []
  if (nightLED) {
    columns.push({
      stack: [
        {
          svg: nightLED,
          width: 10,
        },
      ],
      alignment: 'center',
    })
  }
  if (icon) {
    columns.push({
      stack: [
        {
          svg: icon,
          width: 10,
        },
      ],
      alignment: 'center',
    })
  }
  return {
    stack: [
      {
        text,
        fontSize: 7,
        alignment: 'center',
      },
      {
        text: textField,
        fontSize: 6,
        alignment: 'center',
      },
      {
        columns,
      },
    ],
    colSpan: width,
  }
}

export async function exportArrangementGroups(
  project: ProjectDump,
  groups: GroupsArrangement[]
) {
  const logo = await getMandatorLogo()
  const dd = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 80, 40, 40],

    header: getExportHeader(project, logo),
    footer: function (currentPage, pageCount) {
      return getExportFooter(currentPage, pageCount, 'Gruppen-Anordnung')
    },
    content: [
      {
        style: 'tableExample',
        table: {
          widths: [100, 645],
          headerRows: 1,
          body: [
            [
              { text: 'Gruppe', style: 'tableHeader', alignment: 'left' },
              { text: 'Empfänger', style: 'tableHeader', alignment: 'left' },
            ],
            ...groups.map((group) => {
              return [
                {
                  text: group.group,
                  alignment: 'left',
                },
                {
                  text: group.receivers.join(', '),
                  alignment: 'left',
                },
              ]
            }),
          ],
        },
      },
    ],

    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
  }
  // Following line can be used for debugging in http://pdfmake.org/playground.html
  // console.log(JSON.stringify(dd, null, 2))
  const pdf = pdfMake.createPdf(dd)
  pdf.download('GruppenAnordnung.pdf')
}

export async function exportPartslist(
  project: ProjectDump,
  _partsList: PartsList,
  selectedFloors: Floor[] = null
) {
  const logo = await getMandatorLogo()
  const content = []

  const createTable = (partsContent, title) => {
    // margin: [left, top, right, bottom]
    return [
      {
        text: title,
        style: 'tableHeader',
        margin: [0, 10, 0, 0],
      },
      {
        style: 'tableExample',
        table: {
          widths: [80, '*', 32],
          headerRows: 1,
          body: [
            [
              {
                text: 'Artikel Nummer',
                style: 'tableHeader',
                alignment: 'left',
              },
              { text: 'Bezeichnung', style: 'tableHeader', alignment: 'left' },
              { text: 'Anzahl', style: 'tableHeader', alignment: 'left' },
            ],
            ...partsContent,
          ],
        },
      },
    ]
  }

  if (!selectedFloors) {
    // Export the total
    let partsList = []
    for (const part of Object.values(_partsList.total)) {
      let label = part.label
      if (_partsList.maxFlexTypes[part.variant.id]) {
        for (const key of Object.keys(
          _partsList.maxFlexTypes[part.variant.id]
        )) {
          label += `\n`
          label += `${MAX_FLEX_TYPE_MAP[key]}: ${
            _partsList.maxFlexTypes[part.variant.id][key]
          }`
        }
      }
      partsList.push([
        {
          text: part.artNr,
          alignment: 'right',
        },
        {
          text: label,
          alignment: 'left',
        },
        {
          text: part.amount,
          alignment: 'right',
        },
      ])
    }
    partsList = partsList.sort((a, b) => {
      return parseInt(a.artNr) - parseInt(b.artNr)
    })
    content.push(createTable(partsList, 'Total'))
  } else {
    // Split parts into separate floors
    for (const floor of selectedFloors) {
      let partsList = []
      if (_partsList.floors[floor.id] && _partsList.floors[floor.id].parts) {
        for (const part of Object.values(_partsList.floors[floor.id].parts)) {
          partsList.push([
            {
              text: part.artNr,
              alignment: 'right',
            },
            {
              text: part.label,
              alignment: 'left',
            },
            {
              text: part.amount,
              alignment: 'right',
            },
          ])
        }
        partsList = partsList.sort((a, b) => {
          return parseInt(a.artNr) - parseInt(b.artNr)
        })
      }
      content.push(createTable(partsList, floor.title))
    }
  }

  const dd = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 80, 40, 40],

    header: getExportHeader(project, logo),
    footer: function (currentPage, pageCount) {
      return getExportFooter(currentPage, pageCount, 'Stückliste')
    },
    content,
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
  }

  const pdf = pdfMake.createPdf(dd)
  const date = moment(new Date()).format('DD.MM.YYYY')
  pdf.download(`Stueckliste_${date}.pdf`)
}

import {
  FormFieldType,
  FormBuilderConfig,
} from '@/components/common/forms/formBuilderHelper'
import { DeviceAssignment } from '@/apps/brelag/common/models/equipment'
import { ModelField, TransientBaseObject } from '@/models/core/base'
import { ConnectionPoint } from '../project-editor/floorPlanDrawHelper'

export enum EditorCommands {
  ZOOM_IN = 'floor-plan-editor/zoom-in',
  ZOOM_OUT = 'floor-plan-editor/zoom-out',
  RESET_VIEW = 'floor-plan-editor/reset-view',
  MOVE_LEFT = 'floor-plan-editor/move-left',
  MOVE_RIGHT = 'floor-plan-editor/move-right',
  MOVE_UP = 'floor-plan-editor/move-up',
  MOVE_DOWN = 'floor-plan-editor/move-down',
  ADD_ELEMENT = 'floor-plan-editor/add-object',
  SET_MODE = 'floor-plan-editor/set-mode',
}

export enum EditorModes {
  // Only move around, nothing can be changed
  PAN_ZOOM = 'pan-zoom',
  PLACEMENT = 'placement',
  SELECT = 'select',
  POINTING = 'pointing',
}

export interface ElementDescription {
  name: string
  icon?: string
  annotation: AnnotationElement
  numPlacements: number
}

export interface SvgPosition {
  cx: number
  cy: number
  t?: any
}

export enum VariantDisplayOption {
  NONE = 'none',
  FULL = 'full',
  SHORT = 'short',
}

export interface SvgObject {
  groups: any[]
  links?: any
  nodes?: any
  markers?: any
  connector?: any
  connection?: any
}

export interface EditorConfig extends FormBuilderConfig {
  devices: DeviceAssignment[]
  annotations: AnnotationElement[]
  tabLabel?: string
  originalModel: {
    [key: string]: any
  }
  commonModel: {
    [key: string]: any
  }
  commonSvgModel: {
    [key: string]: any
  }
}

export interface SvgAttributes {
  // Color of device and connector
  color?: string
  // Background color of device description box
  bgColor?: string
  // Option whether to show full variant name, short form or none at all in floor plan
  variantDisplay?: VariantDisplayOption

  textDisplay?: boolean
  // remarks
  textLines?: string[]

  text?: string
  size?: number
  rotate?: boolean
  orientation?: Orientation

  linksDisplay?: boolean

  connectionPoint?: ConnectionPoint

  // any other keys
  [key: string]: string | number | boolean | string[]
}

export enum AnnotationCategory {
  RECT = 'rect',
  CIRCLE = 'circle',
  ARROW = 'arrow',
  TEXT = 'text',
  DEVICE_ARROW = 'device-arrow',
  COMPASS = 'compass',
  LINE = 'line',
  HELP_LINE = 'help-line',
}

export function removeSvgObjects(svg: SvgObject): void {
  const svgObjects = []
  if (svg) {
    if (svg.groups) {
      svg.groups.forEach((obj) => {
        svgObjects.push(obj)
      })
      svg.groups.splice(0, svg.groups.length)
    }
    if (svg.links) {
      svgObjects.push(svg.links)
    }
    if (svg.markers) {
      svgObjects.push(svg.markers)
    }
    if (svg.nodes) {
      svgObjects.push(svg.nodes)
    }
    if (svg.connection) {
      svgObjects.push(svg.connection)
    }
  }

  // Remove from SVG
  svgObjects.forEach((obj) => {
    obj.selectize(false)
    obj.off()
    obj.remove()
  })
  delete svg.links
  delete svg.markers
  delete svg.nodes
}

export class AnnotationElement {
  id: string
  meta: {
    svgObjectPositions: SvgPosition[]
    svgAttributes?: SvgAttributes
    svgObjects?: SvgObject
  } | null
  category: AnnotationCategory
  floor: string
  langPath: string
  fields: ModelField[]

  static safeCopy(element: AnnotationElement): AnnotationElement {
    const { meta, ...rest } = element
    const data = JSON.parse(JSON.stringify(rest))
    if (meta) {
      const { svgObjects, ...metaRest } = meta
      data.meta = metaRest
    }
    return data
  }

  static disableEventHandlers(element: SvgObject): void {
    element.groups.forEach((obj) => {
      obj.off()
    })
  }

  static setDraggy(element: SvgObject, value: boolean): void {
    element.groups.forEach((obj) => {
      obj.draggy(value)
    })
  }

  static selectize(element: SvgObject, value): void {
    element.groups.forEach((obj) => {
      if (value === true) {
        obj.selectize({
          deepSelect: true,
          points: [],
          rotationPoint: false,
        })
      } else {
        // Deselect only if selected
        if (
          obj._memory &&
          obj._memory._selectHandler &&
          obj._memory._selectHandler.rectSelection.isSelected
        ) {
          obj.selectize(value)
        }
      }
    })
  }
}

export enum SvgObjectCollectionType {
  DEVICE = 'device',
  ANNOTATION = 'annotation',
}
export interface SvgObjectCollection {
  svg: SvgObject
  svgAttributes: SvgAttributes
  type: SvgObjectCollectionType
  id: string
}

export function getAnnotationFields(category: AnnotationCategory) {
  for (const desc of ANNOTATION_DESCRIPTIONS) {
    if (desc.annotation.category === category) {
      return TransientBaseObject.defaultFormFields(
        desc.annotation.langPath,
        desc.annotation.fields
      )
    }
  }
  if (category === AnnotationCategory.HELP_LINE) {
    return TransientBaseObject.defaultFormFields(
      HELP_LINE.annotation.langPath,
      HELP_LINE.annotation.fields
    )
  }
  return []
}

export enum Orientation {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

export const ANNOTATION_DESCRIPTIONS: ElementDescription[] = [
  {
    name: 'Text',
    icon: 'mdi mdi-format-text',
    numPlacements: 1,
    annotation: {
      category: AnnotationCategory.TEXT,
      floor: null,
      id: null,
      langPath: 'apps.brelag.editor.annotations.text',
      fields: [
        {
          key: 'text',
          formFieldType: FormFieldType.INPUT,
          formProperties: {
            inputType: 'textarea',
            icon: '',
          },
        },
        {
          key: 'color',
          formFieldType: FormFieldType.COLOR_FIELD,
        },
        {
          key: 'bgColor',
          formFieldType: FormFieldType.COLOR_FIELD,
          formProperties: {
            title: 'Hintergrundfarbe',
          },
          required: false,
        },
        {
          key: 'borderColor',
          formFieldType: FormFieldType.COLOR_FIELD,
          formProperties: {
            title: 'Rahmenfarbe',
          },
          required: false,
        },
        {
          key: 'size',
          formProperties: {
            inputType: 'number',
          },
          required: true,
          formFieldType: FormFieldType.NUMBER_FIELD,
        },
        {
          key: 'rotation',
          formProperties: {
            inputType: 'number',
          },
          required: true,
          formFieldType: FormFieldType.ROTATION_FIELD,
        },
      ],
      meta: {
        svgAttributes: {
          text: 'Lorem ipsum dolor',
          color: '#000000',
          bgColor: null,
          textColor: null,
          size: 20,
        },
        svgObjectPositions: [],
      },
    },
  },
  {
    name: 'Kompass',
    icon: 'mdi mdi-compass-outline',
    numPlacements: 1,
    annotation: {
      category: AnnotationCategory.COMPASS,
      floor: null,
      id: null,
      langPath: 'apps.brelag.editor.annotations.compass',
      fields: [
        {
          key: 'rotation',
          formProperties: {
            inputType: 'number',
          },
          required: true,
          formFieldType: FormFieldType.ROTATION_FIELD,
        },
        {
          key: 'size',
          formProperties: {
            inputType: 'number',
          },
          required: true,
          formFieldType: FormFieldType.NUMBER_FIELD,
        },
      ],
      meta: {
        svgAttributes: {
          rotation: 0,
          size: 50,
        },
        svgObjectPositions: [],
      },
    },
  },
  {
    name: 'Pfeil',
    icon: 'mdi mdi-arrow-right-bold-box-outline',
    numPlacements: 1,
    annotation: {
      category: AnnotationCategory.DEVICE_ARROW,
      floor: null,
      id: null,
      langPath: 'apps.brelag.editor.annotations.device_arrow',
      fields: [
        {
          key: 'orientation',
          formFieldType: FormFieldType.SELECTION,
          required: false,
          formProperties: {
            options: [
              { text: 'Links', value: Orientation.LEFT },
              { text: 'Rechts', value: Orientation.RIGHT },
              { text: 'Oben', value: Orientation.UP },
              { text: 'Unten', value: Orientation.DOWN },
            ],
          },
        },
        {
          key: 'size',
          formProperties: {
            inputType: 'number',
          },
          required: true,
          formFieldType: FormFieldType.NUMBER_FIELD,
        },
        {
          key: 'fromText',
        },
        {
          key: 'toText',
        },
      ],
      meta: {
        svgAttributes: {
          orientation: Orientation.LEFT,
          size: 200,
          fromText: '...',
          toText: '...',
        },
        svgObjectPositions: [],
      },
    },
  },
]

export const HELP_LINE: ElementDescription = {
  name: 'Hilfslinie',
  icon: 'mdi mdi-color-helper',
  numPlacements: 1,
  annotation: {
    category: AnnotationCategory.HELP_LINE,
    floor: null,
    id: null,
    langPath: 'apps.brelag.editor.annotations.help_line',
    fields: [
      {
        key: 'orientation',
        formProperties: {
          options: [
            { text: 'horizontal', value: Orientation.RIGHT },
            { text: 'vertikal', value: Orientation.DOWN },
          ],
        },
        required: true,
        formFieldType: FormFieldType.SELECTION,
      },
    ],
    meta: {
      svgAttributes: {
        orientation: Orientation.RIGHT,
      },
      svgObjectPositions: [],
    },
  },
}

export interface CreateDeviceSerializer {
  // REST
  equipment_variant: string
  floor: string
  dirty?: boolean
  dirty_optional?: boolean
  dirty_teaching?: boolean
  number?: number
  insert_number_if_occupied?: boolean
  name?: string
  comment?: string
  meta: {
    svgAttributes: SvgAttributes
  }
  programmer_device_configuration: {
    data: number[]
  }
  programmer_device_configuration_optional: {
    data: number[]
  }
  config: {
    [key: string]: any
  }
  projectId: string
  num_devices: number

  // Internal
  copySelectedDevice?: boolean
  copyLinks?: boolean
}

export interface BatchDeleteDevicesInputSerializer {
  ids: string[]
}

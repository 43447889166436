
import BaseForm from '@/components/common/BaseForm.vue'
import { EmailUpdate } from '@/models/core/models'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'verify_change_email',
  components: {
    'base-form': BaseForm,
  },
  props: {
    id: {
      default: '',
    },
    email: {
      default: '',
    },
    nonce: {
      default: '',
    },
  },
  data() {
    return {
      changeEmailVerification: {
        nonce: this.$props.nonce,
        pin: '',
      },
    }
  },
})
export default class VerifyChangeEmail extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  changeEmailVerification: EmailUpdate

  mounted() {
    // If the nonce is empty (e.g. after refreshing page) we can not retrieve it and the process needs to start again
    if (
      this.$props.nonce === '' ||
      this.$props.id !== this.$store.state.global.profileId
    ) {
      this.$router.push({ name: 'profile-list' })
    }
  }

  verifyChangeEmail() {
    this.$api
      .updateEmail(this.$props.id, this.changeEmailVerification)
      .then((response) => {
        this.$buefy.toast.open({
          message: 'Successfully changed E-Mail!',
          type: 'is-success',
        })
        this.$router.push({ name: 'profile-list' })
      })
      .catch((error) => {
        this.$refs.baseForm.handleError(error)
      })
  }
}

import { ApiClientV2 } from '@/api/ApiClientV2'
import { Project } from './project'

export interface LinkOptions {
  [key: string]: any
  configuration?: number[]
  groups?: string[]
}

export interface SenderChannel {
  sender: string
  channel: number
}

export class Link {
  id: string
  sender: string
  sender_channel: number
  receiver: string
  project: string
  options: LinkOptions

  /**
   * Returns true if link between sender and receiver is "repeatingOnly"
   * @param link
   */
  static isRepeatingOnly(link: Link): boolean {
    if (link.options.keyRepeaterOnly && link.options.keyRepeaterOnly === 1) {
      return true
    } else {
      return false
    }
  }

  /**
   * Returns true if link between sender and receiver has property 'keyStorageId', meaning the receiver in the link is an eGate/FusionONE
   * @param link
   */
  static hasEgateConfig(link: Link): boolean {
    return link.options.hasOwnProperty('keyStorageId')
  }

  static async createLinks(
    apiClient: ApiClientV2,
    projectId: string,
    links: Link[]
  ): Promise<Project> {
    return await apiClient.customPost(
      `brelag/project/${projectId}/create-links`,
      {
        links,
      }
    )
  }

  static async deleteLinks(
    apiClient: ApiClientV2,
    projectId: string,
    links: Link[]
  ): Promise<Project> {
    return await apiClient.customPost(
      `brelag/project/${projectId}/delete-links`,
      {
        links,
      }
    )
  }

  static async updateLinks(
    apiClient: ApiClientV2,
    projectId: string,
    links: Link[]
  ): Promise<Project> {
    return await apiClient.customPost(
      `brelag/project/${projectId}/update-links`,
      {
        links,
      }
    )
  }
}

export const LINK_DEFAULT: Link = {
  id: '',
  sender: '',
  sender_channel: 0,
  receiver: '',
  project: '',
  options: {},
}

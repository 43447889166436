import { Organisation } from '@/models/core/organisation'
import { ActionContext, Module } from 'vuex'

export enum CustomMandatorLayout {
  DEFAULT = 'Standard',
  WESCO = 'WESCO', // Special layout for "WESCO AG"
}

export type CustomLayoutSettings = Record<string, any>

// Settings specific to Mandator
export interface MandatorSettings {
  // Which Frontend Layout to use
  customLayout: CustomMandatorLayout
}

export interface UserSettings {}

export class SettingsState {
  userSettings: UserSettings = {}
  mandatorSettings: MandatorSettings = {
    customLayout: CustomMandatorLayout.DEFAULT,
  }
}

export function getDefaultCustomLayoutSettings(
  layoutType: CustomMandatorLayout
): CustomLayoutSettings {
  switch (layoutType) {
    default:
      return null
  }
}

export class SettingsModule<R> implements Module<SettingsState, R> {
  namespaced = true
  state = new SettingsState()
  getters = {
    mandatorLayout: (state: SettingsState) =>
      state.mandatorSettings.customLayout,
    isWescoLayout: (state: SettingsState) =>
      state.mandatorSettings.customLayout === CustomMandatorLayout.WESCO,
  }
  actions = {
    async setMandator(
      { commit }: ActionContext<SettingsState, R>,
      { mandator }: { mandator: Organisation }
    ) {
      commit('setMandatorLayout', mandator.settings.layout)
    },
  }
  mutations = {
    setMandatorLayout(
      state: SettingsState,
      layout: CustomMandatorLayout | null
    ) {
      if (layout) {
        state.mandatorSettings.customLayout = layout
      } else {
        state.mandatorSettings.customLayout = CustomMandatorLayout.DEFAULT
      }
    },
  }
}

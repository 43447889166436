export default {
  offer: {
    prettyName: 'Angebot | Angebote',
    fields: {
      organisation: 'Organisation',
      title: 'Name',
      description: 'Beschreibung',
      is_active: 'Aktiv',
      vat_rate: 'MWST [%]',
      meta: {
        num_coins: 'Anz. Coins',
        article_number: 'Artikel Nummer',
        color: 'Farbe',
      },
      price: 'Preis',
      currency: 'Währung',
    },
    placeholders: {
      organisation: '',
      title: '',
      description: '',
      is_active: 'Aktiv',
      vat_rate: 'MWST',
      meta: {
        num_coins: '',
        article_number: '',
        color: '',
      },
      price: '',
      currency: '',
    },
  },
  order: {
    prettyName: 'Bestellung | Bestellungen',
    fields: {
      organisation: 'Organisation',
      number: 'Nummer',
      title: 'Name',
      description: 'Beschreibung',
      vat_rate: 'MWST [%]',
      create_time: 'Datum',
      meta: {
        num_coins: 'Anz. Coins',
      },
      price: 'Preis',
      currency: 'Währung',
      status: 'Status',
    },
  },
}

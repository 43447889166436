export default {
  description: {
    label: 'Beschreibung',
    placeholder: 'Platzhalter',
  },
  orderingField: {
    setAtEnd: 'Ans Ende setzen',
    setManually: 'Manuell setzen',
  },
  avatar: {
    avatar: 'Logo',
    currentAvatar: 'Aktuelles Logo',
    resolutionLimit: 'Auflösung grösser als 10x10, kleiner als 1024x1024',
    fileSizeLimit: 'Maximale Filegrösse 200kb',
  },
}

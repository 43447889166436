/**
 * Takes an array of objects and creates a CSV file using the object keys as header.
 * @param args all arguments
 * @returns string buffer
 */
export function convertArrayOfObjectsToCSV(args: {
  data: any[]
  columnDelimiter?: string
  lineDelimiter?: string
}): string {
  let result
  let ctr
  const data = args.data || null
  if (data == null || !data.length) {
    return null
  }

  const columnDelimiter = args.columnDelimiter || ','
  const lineDelimiter = args.lineDelimiter || '\n'

  const keys = Object.keys(data[0])
  result = `sep=${columnDelimiter}\n`
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach((item) => {
    ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter
      }

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export function downloadCSV(args: { csv: string; filename?: string }) {
  const csv = args.csv || null
  if (csv == null) {
    return
  }

  const filename = args.filename || 'export.csv'

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

  const link = document.createElement('a')
  if (link.download !== undefined) {
    // feature detection, Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    // link.style = 'visibility:hidden';
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

// until Object.fromEntries() is available ...
export function objectFromMap(map) {
  const obj = {}
  map.forEach((value, key) => (obj[key] = value))
  return obj
}

/**
 * Inserts an object into another object without breaking reactivity
 */
// TODO: This should have some unit tests
export function insertPropsInObject(target: object, props: object) {
  Object.keys(props).forEach((key) => {
    if (target[key]) {
      // Check if its a nested object
      // See here for explanation https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
      if (Object.prototype.toString.call(props[key]) === '[object Object]') {
        insertPropsInObject(target[key], props[key])
      } else {
        // We can assign it
        target[key] = props[key]
      }
    } else {
      target[key] = props[key]
    }
  })
}

/**
 * Simple copy using JSON parse/stringify
 * @param obj
 */
export function simpleCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Reverses a string
 * @param str
 */
export function reverseString(str: string): string {
  // Step 1. Use the split() method to return a new array
  const splitString = str.split('') // const splitString = "hello".split("");
  // ["h", "e", "l", "l", "o"]

  // Step 2. Use the reverse() method to reverse the new created array
  const reverseArray = splitString.reverse() // const reverseArray = ["h", "e", "l", "l", "o"].reverse();
  // ["o", "l", "l", "e", "h"]

  // Step 3. Use the join() method to join all elements of the array into a string
  const joinArray = reverseArray.join('') // const joinArray = ["o", "l", "l", "e", "h"].join("");
  // "olleh"

  //Step 4. Return the reversed string
  return joinArray // "olleh"
}

/**
 * Use media query to check if screen size is mobile
 */
export function isMobile(): boolean {
  return window.matchMedia('(max-width: 767px)').matches
}

/**
 * Set document title (showns in browser tab)
 * @param title
 */
export function setDocumentTitle(title: string): void {
  window.document.title = title
}


import { Component, Vue } from 'vue-property-decorator'
import debounce from 'lodash.debounce'
import { codemirror } from 'vue-codemirror'
import { defaultEditorOptions } from '@/util/CodeMirror'
import parseJson from 'json-parse-better-errors'
import Prism from 'vue-prismjs'
import 'prismjs/themes/prism.css'

import FormBuilder from '@/components/common/forms/FormBuilder.vue'
import EquipmentEditorHelp from '@/apps/brelag/brelag-admin/components/equipment/EquipmentEditorHelp.vue'

@Component({
  name: 'equipment-config-editor-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    codemirror,
    FormBuilder,
    EquipmentEditorHelp,
    Prism,
  },
  data() {
    return {
      settingEditorErrors: [],
      editorOptions: defaultEditorOptions,
      layoutSideBySide: true,
      settingEditorRaw: '',
      editorConfig: { data: {}, fields: [] },
      formBuilderForm: {},
    }
  },
})
export default class EquipmentConfigField extends Vue {
  $refs: {
    configBuilder: any
    configBuilderr: any
  }
  settingEditorErrors: any[]
  configEditorLayoutString: string = ''
  settingEditorRaw: string = ''
  editorConfig: any
  formBuilderForm: any
  configBuilderReady: boolean = false
  debouncedSettingEditorRawChanged = debounce(this.settingEditorRawChanged, 100)

  mounted() {
    this.refreshEditorRaw()
    this.configBuilderReady = true
  }

  refreshEditorRaw() {
    if (!this.$props.model[this.$props.field.key]) {
      this.settingEditorRaw = JSON.stringify(
        {
          title: 'Editor',
          model: {},
          tabs: [],
        },
        null,
        2
      )
    } else {
      this.settingEditorRaw = JSON.stringify(
        this.$props.model[this.$props.field.key],
        null,
        2
      )
    }
    this.settingEditorRawChanged()
  }

  removeDescriptionField(index) {
    this.$props.model[this.$props.field.key].description.splice(index, 1)
    this.refreshEditorRaw()
  }

  addDescriptionField() {
    if (this.$props.model[this.$props.field.key].description) {
      this.$props.model[this.$props.field.key].description.push({
        key: '',
        value: '',
      })
    } else {
      Vue.set(this.$props.model[this.$props.field.key], 'description', [
        { key: '', value: '' },
      ])
    }
    this.refreshEditorRaw()
  }

  get configOutput() {
    if (this.configBuilderReady && this.$refs.configBuilder !== undefined) {
      return JSON.stringify(this.$refs.configBuilder.model, null, 2)
    } else if (
      this.configBuilderReady &&
      this.$refs.configBuilderr !== undefined
    ) {
      return JSON.stringify(this.$refs.configBuilderr.model, null, 2)
    } else {
      return ''
    }
  }

  clearErrors() {
    this.settingEditorErrors = []
  }

  settingEditorRawChanged() {
    this.clearErrors()
    try {
      const newLayout = parseJson(this.settingEditorRaw)
      this.editorConfig = newLayout
      this.settingEditorErrors = []
      this.$props.model[this.$props.field.key] = this.editorConfig
    } catch (e) {
      this.settingEditorErrors.push(e.toString())
    }
  }

  handleError(exception) {
    this.settingEditorErrors.push(exception.toString())
  }
}

import { ApiClientV2, TransactionCreateData } from '@/api/ApiClientV2'
import {
  Transaction,
  TransactionState,
  TransactionInterface,
} from '@/models/core/models'

export interface TransactionHandlerOptions {
  handle: string
}

// TRANSACTIONS DISABLED FOR NOW
export const DISABLE_TRANSACTIONS = true

export class TransactionHandler {
  apiClients: Map<string, ApiClientV2> = new Map()
  transactionsMap: Map<string, TransactionInterface> = new Map()

  public async createApiClient(
    options: TransactionHandlerOptions,
    transactionCreateData: TransactionCreateData
  ): Promise<ApiClientV2> {
    let apiClient = this.apiClients.get(options.handle)
    if (DISABLE_TRANSACTIONS) {
      return Promise.resolve(new ApiClientV2())
    }

    if (
      apiClient !== undefined &&
      apiClient.transactionInterface &&
      !Transaction.isFinal(
        apiClient.transactionInterface.state as TransactionState
      )
    ) {
      throw new Error(
        `there exists already an api client with an unfinished transaction by the handle '${options.handle}'`
      )
    }

    // create a new instance
    apiClient = new ApiClientV2()
    const transactionInterface = this.getTransactionInterface(options.handle)
    transactionInterface.id = null
    transactionInterface.state = TransactionState.Undefined
    transactionInterface.operations = []
    await apiClient.openTransaction(transactionCreateData, transactionInterface)
    this.apiClients.set(options.handle, apiClient)
    return apiClient
  }

  public getTransactionInterface(
    handle: TransactionHandlerOptions['handle']
  ): TransactionInterface {
    let transactionsMap = this.transactionsMap.get(handle)
    if (transactionsMap === undefined) {
      transactionsMap = {
        id: null,
        meta: {
          handle,
          title: undefined,
          description: undefined,
        },
        state: TransactionState.Undefined,
        operations: [],
      }
      this.transactionsMap.set(handle, transactionsMap)
    }
    return transactionsMap
  }

  public async rollbackTransaction(
    handle: TransactionHandlerOptions['handle']
  ): Promise<Transaction> {
    if (DISABLE_TRANSACTIONS) {
      return Promise.resolve(null)
    } else {
      return this.apiClients.get(handle).rollbackTransaction()
    }
  }
}

export const transactionHandler = new TransactionHandler()

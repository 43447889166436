
import isEqual from 'lodash.isequal'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import store from '@/store/index'
import { LifeCycleState } from '@/models/core/base'
import ObjectStateTag from './ObjectStateTag.vue'
import {
  routeHasClientApp,
  getLocationPreservingState,
  getClientAppOfRoute,
} from '@/apps/routingUtils'
import { clientAppRouteName } from '@/apps/clientAppRegistry'
import ParentSelectors from './ParentSelectors.vue'
import ObjectBreadcrumbs from './ObjectBreadcrumbs.vue'
import { prepareUpdateObject } from './forms/formUtil'

@Component({
  name: 'base-form',
  components: {
    ObjectStateTag,
    ParentSelectors,
    ObjectBreadcrumbs,
  },
  props: {
    title: {
      required: true,
    },
    contentType: {
      required: true,
    },
    dataObject: {
      required: true,
    },
    columnWidth: {
      default: 'is-12',
    },
    customSubmit: {
      default: false,
    },
    customCreate: {
      default: null,
    },
    onCreateFinished: {
      default: null,
    },
    onUpdateFinished: {
      default: null,
    },
    onCustomSubmit: {
      default: null,
    },
    customUpdate: {
      default: null,
    },
    preUpdateHook: {
      default: null,
    },
    hasError: {
      default: false,
    },
    errorMessage: {
      default: '',
    },
    hasLifeCycle: {
      default: true,
    },
    canSave: {
      default: true,
    },
    warningMessages: {
      default: () => [],
    },
    completedLocation: {
      default: null,
    },
  },
})
export default class BaseForm extends Vue {
  contentType: string
  dataObject: any
  loading: boolean = false
  originalDataObject: object = {}
  formValidation = {
    isValid: true,
    errorMessages: [],
  }
  countOriginalChange: number = 0

  @Watch('$props.dataObject', { deep: true })
  dataObjectChange() {
    // Only updates originaldataobject if properties were missing previously,
    if (JSON.stringify(this.$props.dataObject)) {
      this.countOriginalChange++
      if (this.countOriginalChange > 1) {
        if (this.isSame(this.originalDataObject, this.$props.dataObject)) {
          this.originalDataObject = JSON.parse(
            JSON.stringify(this.$props.dataObject)
          )
        }
      } else {
        this.originalDataObject = JSON.parse(
          JSON.stringify(this.$props.dataObject)
        )
      }
    }
  }

  navigateWhenCompleted() {
    if (this.$props.completedLocation !== null) {
      // a location was given via props
      this.$router.push(this.$props.completedLocation)
      return
    } else {
      // try to find a decent route
      if (routeHasClientApp(this.$route)) {
        const clientApp = getClientAppOfRoute(this.$route)
        const routeName = clientAppRouteName(
          clientApp.view_id,
          this.$props.contentType + '-list'
        )
        const location = getLocationPreservingState(routeName, this.$router)
        if (this.$router.resolve(location).route.matched.length > 0) {
          // only go there if the location matches something
          this.$router.push(location)
          return
        }
      }
    }

    // go somewhere at least
    this.$router.back()
  }

  publish() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    return this.$api
      .publish(this.$props.contentType, this.dataObject.id)
      .then((response) => {
        loadingComponent.close()
        this.$buefy.toast.open({
          message: 'Successfully published!',
          type: 'is-success',
        })
        this.navigateWhenCompleted()
      })
      .catch((error) => {
        loadingComponent.close()
        this.handleError(error)
      })
  }

  deleteObject() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    return this.$api
      .delete(this.$props.contentType, this.dataObject.id)
      .then((response) => {
        loadingComponent.close()
        this.$buefy.toast.open({
          message: 'Successfully deleted!',
          type: 'is-success',
        })
        this.navigateWhenCompleted()
      })
      .catch((error) => {
        loadingComponent.close()
        this.handleError(error)
      })
  }

  accept() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    return this.$api
      .accept(this.$props.contentType, this.dataObject.id)
      .then((response) => {
        loadingComponent.close()
        this.$buefy.toast.open({
          message: 'Successfully accepted!',
          type: 'is-success',
        })
        this.navigateWhenCompleted()
      })
      .catch((error) => {
        loadingComponent.close()
        this.handleError(error)
      })
  }

  reject() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    return this.$api
      .reject(this.$props.contentType, this.dataObject.id)
      .then((response) => {
        loadingComponent.close()
        this.$buefy.toast.open({
          message: 'Successfully rejected!',
          type: 'is-success',
        })
        this.navigateWhenCompleted()
      })
      .catch((error) => {
        loadingComponent.close()
        this.handleError(error)
      })
  }

  save() {
    if (!this.$props.canSave) {
      return
    }
    if (this.$props.customSubmit) {
      this.$props.onCustomSubmit()
      return
    }
    if (this.$props.preUpdateHook !== null) {
      this.$props.preUpdateHook()
    }
    return Promise.resolve(this.dataObject).then((dataObject) => {
      let promise: Promise<any>
      let isUpdate = true
      if (!dataObject.id || dataObject.id === '0' || dataObject.id === '') {
        isUpdate = false
        if (this.$props.customCreate !== null) {
          promise = this.$props.customCreate(dataObject)
          if (!promise) {
            // for cases where customCreate does not return promise
            return
          }
        } else {
          promise = this.$api.create(this.$props.contentType, dataObject)
        }
      } else {
        if (this.$props.customUpdate !== null) {
          this.$props.customUpdate(dataObject)
          return
        }
        // Only send updated properties
        const updateDiff = prepareUpdateObject(
          dataObject,
          this.originalDataObject
        )
        promise = this.$api.update(this.$props.contentType, updateDiff)
      }
      const loadingComponent = this.$buefy.loading.open({ container: null })
      return promise
        .then((response) => {
          loadingComponent.close()
          if (!isUpdate) {
            if (this.$props.onCreateFinished !== null) {
              this.$props.onCreateFinished(response)
            }
          } else {
            if (this.$props.onUpdateFinished !== null) {
              this.$props.onUpdateFinished(this.$props.dataObject)
            }
          }
          this.originalDataObject = JSON.parse(
            JSON.stringify(this.$props.dataObject)
          )
          this.navigateWhenCompleted()
        })
        .catch((error) => {
          loadingComponent.close()
          this.handleError(error)
        })
    })
  }

  clearError() {
    this.formValidation.errorMessages = []
    this.formValidation.isValid = true
  }

  handleError(error) {
    this.formValidation.errorMessages = this.$errorHandler.errorToStrings(error)
    this.formValidation.isValid = false
  }

  isSame(obj1, obj2) {
    for (const v in obj1) {
      if (
        (obj1[v] === '0' && obj2[v] !== '0') ||
        (obj1[v] !== '0' && obj2[v] === '0')
      ) {
        continue
      } else if (!isEqual(obj1[v], obj2[v])) {
        return false
      }
    }
    return true
  }

  get isEditable() {
    if (
      this.dataObject.object_state === LifeCycleState.PendingApproval ||
      this.dataObject.object_state === LifeCycleState.PendingDeletion
    ) {
      return false
    }
    if (
      this.dataObject._permissions &&
      this.dataObject._permissions.hasOwnProperty('edit')
    ) {
      return this.dataObject._permissions.edit
    } else {
      return true
    }
  }

  get canApprove() {
    return (this.isPendingApproval || this.isPendingDeletion) && this.canAccept
  }

  get canReject() {
    return this.isPendingApproval || this.isPendingDeletion
  }

  get isDraft() {
    return this.dataObject.object_state === LifeCycleState.Draft
  }

  get isPendingApproval() {
    return this.dataObject.object_state === LifeCycleState.PendingApproval
  }

  get isPendingDeletion() {
    return this.dataObject.object_state === LifeCycleState.PendingDeletion
  }

  get isReviewRequester() {
    if (this.dataObject.review_requester) {
      return this.dataObject.review_requester === store.state.global.profileId
    } else {
      return false
    }
  }

  get canAccept() {
    if (this.$props.contentType === 'classification') {
      return !this.isReviewRequester
    } else {
      return (
        !this.isReviewRequester &&
        this.dataObject._permissions &&
        this.dataObject._permissions.edit
      )
    }
  }

  get canPublish() {
    return this.isDraft && !this.isNewObject
  }

  get isNewObject() {
    return this.dataObject.id === undefined || this.dataObject.id === '0'
  }

  get rejectButtonText(): string {
    let text = 'Deletion'
    if (this.isPendingApproval) {
      text = 'Approval'
    }
    return 'Reject ' + text
  }

  get approveButtonText(): string {
    let text = 'Deletion'
    if (this.isPendingApproval) {
      text = 'Approval'
    }
    return 'Confirm ' + text
  }

  isDirty() {
    // Check if data object has been changed (by user)
    // return (JSON.stringify(this.$props.dataObject) !== JSON.stringify(this.originalDataObject))
    return !isEqual(this.$props.dataObject, this.originalDataObject)
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { PRETTY_NAMES } from '@/models/core/defaults'
import { getObjectTypesUp } from '@/models/objectRegistry'
import { getViewId } from '@/models/objectRegistry'
import { clientAppRouteName } from '@/apps/clientAppRegistry'

@Component({
  name: 'object-breadcrumbs',
  props: {
    objectType: {
      required: true,
    },
    showLabels: {
      default: true,
    },
  },
})
export default class ParentSelectors extends Vue {
  get breadcrumbs() {
    return getObjectTypesUp(this.$props.objectType).map((objectType, index) => {
      const obj = this.$store.getters['global/object'](objectType)
      const label = PRETTY_NAMES[objectType]

      const objectViewId = getViewId(objectType)

      const clientApps = this.$store.getters['global/collection']('client-app')

      const destinationApps = clientApps.objects.filter(
        (app) => app.view_id === objectViewId
      )

      let to

      if (destinationApps.length === 1 && obj !== null) {
        to = {
          name: clientAppRouteName(objectViewId, `${objectType}-detail`),
          params: {
            id: obj.id,
            app_handle: destinationApps[0].handle,
          },
        }
      }

      if (obj) {
        return {
          index,
          label,
          name: obj.name,
          to,
        }
      } else {
        return {
          index,
          label,
        }
      }
    })
  }
}

import { apiClientV2 } from '@/api/ApiClientV2'
import { ApiClientPlugin } from '@/api/ApiClientPlugin'
import { ApiClientPluginV2 } from '@/api/ApiClientPluginV2'
import App from './App.vue'
import { BackgroundTaskHandler } from '@/api/BackgroundTaskHandler'
import { AppInsightHandler } from '@/api/AppInsightHandler'
import { TransactionHandlerPlugin } from '@/api/TransactionHandlerPlugin'
import { ErrorHandler } from '@/api/ErrorHandler'
import Buefy from 'buefy'
import VueCodemirror from 'vue-codemirror'
import { createPinia, PiniaVuePlugin } from 'pinia'

import ErrorPage from './ErrorPage.vue'
import router from '@/router/index'
import store from '@/store/index'
import Vue from 'vue'
import Router from 'vue-router'
import { i18n, getTranslation } from '@/lang/setup'
import VueRx from 'vue-rx'
import fullscreen from 'vue-fullscreen'

import { RouterHandler } from './router/routerHandler'
import { formatSize, formatDate } from '@/util/filters'
import { initForms } from '@/forms'

import { FloorPlanEditor } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditor'
import { ProjectEditor } from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import { setBrelagEnabled } from '@/apps/brelag/common/util'

// local import FontAwesome icons
import './assets/css/brands.css'
import './assets/css/solid.css'
import './assets/css/light.css'
import './assets/css/fontawesome.css'

/** INSTALL VEE-VALIDATE RULES **/
import { required, integer, between } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
extend('required', {
  ...required,
  message: 'Dieses Feld ist benötigt.',
})
extend('integer', {
  ...integer,
  message: 'Dieses Feld muss ein Integer sein.',
})
extend('positive', (value) => {
  return value >= 0
})
extend('between', {
  ...between,
})

declare let process: {
  env: {
    NODE_ENV: string
    VUE_APP_AZURE_APPLICATION_INSIGHTS?: string
  }
}

// @ts-ignore
import('./styles/brelagTheme.scss')

FloorPlanEditor.getInstance(store)
ProjectEditor.getInstance(store)

Vue.use(new ApiClientPlugin())
Vue.use(new ApiClientPluginV2())
Vue.use(new TransactionHandlerPlugin())

Vue.use(Router)
Vue.use(RouterHandler)
Vue.use(BackgroundTaskHandler)
Vue.use(ErrorHandler)

Vue.use(Buefy, {
  defaultDialogCancelText: getTranslation('components.common.cancel'),
})
Vue.use(
  VueCodemirror /* {
  options: { theme: 'base16-dark', ... },
  events: ['scroll', ...]
} */
)
Vue.use(VueRx)
Vue.use(fullscreen)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

if (process.env.VUE_APP_AZURE_APPLICATION_INSIGHTS) {
  Vue.use(AppInsightHandler, {
    instrumentationKey: process.env.VUE_APP_AZURE_APPLICATION_INSIGHTS,
  })
}

Vue.filter('formatSize', formatSize)
Vue.filter('formatDate', formatDate)

import L from 'leaflet'

declare let require: any
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

initForms()

import { LicenseManager } from '@ag-grid-enterprise/core'
LicenseManager.setLicenseKey(
  'CompanyName=Brelag Schweiz AG,LicensedApplication=Knockaut_Styler,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021666,ExpiryDate=28_October_2022_[v2]_MTY2NjkxMTYwMDAwMA==35d50f8a7c774882f37f85b2b11be666'
)

export function initStyler(): Vue {
  apiClientV2.axiosConfig = store.state.global.axiosConfig

  let vm: Vue
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'test') {
    vm = new Vue({
      router,
      store,
      i18n,
      pinia,
      render: (h) => h(App),
    })
  } else {
    vm = new Vue({
      el: '#app',
      router,
      store,
      i18n,
      pinia,
      render: (h) => h(App),
    })
  }
  setBrelagEnabled(vm.$store)

  // Init the RouterHandler with the Vue instance
  vm.$routerHandler.init(vm)
  window['vm'] = vm
  return vm
}

store
  .dispatch('global/initialize', { router })
  .then(() => {
    initStyler()
  })
  .catch(() => {
    // If we land here, the server is not responding. It's either offline or rejecting our request
    // for some reason (e.g. requires VPN connection). Simply show an error page.
    new Vue({
      el: '#app',
      render: (h) => h(ErrorPage),
    })
  })


import { CommonContext } from '@/store/GlobalStore'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'

@Component({
  name: 'organisation-selector',
  computed: {
    ...mapState('global', ['context']),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showOrgSwitchButton: {
      type: Boolean,
      default: true,
    },
    initialOrgId: {
      type: String,
      default: '',
    },
  },
})
export default class OrganisationSelector extends Vue {
  context: CommonContext
  isModalActive: boolean = false
  selectedOrg = this.$store.getters['global/organisation']
  itemsPerPage: number = 10
  columns: Array<any> = [
    {
      field: 'name',
      label: 'Name',
      searchable: true,
    },
    {
      field: 'meta.address',
      label: 'Adresse',
      searchable: true,
    },
    {
      field: 'meta.city',
      label: 'Ort',
      searchable: true,
    },
  ]

  selectOrg(organisation) {
    this.selectedOrg = organisation
    this.$emit('selected', organisation)
    this.close()
  }

  close() {
    this.$emit('input', false)
    this.isModalActive = false
  }

  get hasMultipleOrgs(): boolean {
    return this.context.organisations.objects.length > 1
  }

  mounted() {
    if (
      this.$props.initialOrgId &&
      this.context.organisations.objects.length > 0
    ) {
      const org = this.context.organisations.objects.find(
        (o) => o.id === this.$props.initialOrgId
      )
      this.selectOrg(org)
    } else if (this.selectedOrg) {
      this.selectOrg(this.selectedOrg)
    }
  }
}

import {
  ALBUM_DEFAULT,
  GROUP_DEFAULT,
  GROUP_MEMBERSHIP_DEFAULT,
  OBJECT_AUTHORIZATION_DEFAULT,
  ROLE_DEFAULT,
  TRANSACTION_DEFAULT,
  ATTACHMENT_DEFAULT,
  contentTypes,
} from './defaults'
import {
  CollectionSubscriber,
  CollectionFilter,
  CollectionPagination,
} from '@/api/ApiClient'
import {
  FormFieldType,
  FormFieldQueryBuilderMap,
} from '@/components/common/forms/formBuilderHelper'
import Vue from 'vue'
import { Collection, ApiClientV2 } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  CellType,
} from '@/models/core/base'
import { Organisation } from '@/models/core/organisation'
import { getModelClass } from '../objectRegistry'

export class Attachment extends TransientBaseObject {
  kind: string
  mime_type: string
  encoding: string
  file: string

  static apiUrl = 'attachment'
  static langPath: string = 'models.core.attachment'
  static objectType: string = 'attachment'

  static get defaultModel() {
    return JSON.parse(JSON.stringify(ATTACHMENT_DEFAULT))
  }
}

export class Role extends TransientBaseObject {
  name: string
  description: string
  organisation: string
  permissions: object

  static apiUrl = 'role'
  static langPath: string = 'models.core.role'
  static objectType: string = 'role'

  static get defaultModel() {
    return JSON.parse(JSON.stringify(ROLE_DEFAULT))
  }
}

export class ObjectAuthorization extends TransientBaseObject {
  organisation: string
  object_type: string
  object_id: string
  role: string
  note: string
  propagation: ObjectAuthorization.Propagation
  group: string

  static apiUrl = 'object-authorization'
  static langPath: string = 'models.core.objectauthorization'
  static objectType: string = 'object-authorization'

  static listFields: ListModelField[] = [
    {
      key: 'role_name',
    },
    {
      key: 'group_name',
    },
    {
      key: 'object_type',
      sortable: true,
    },
    {
      key: 'object_name',
      tooltip: (row) => {
        return {
          label: row.tooltip,
          icon: 'mdi-information-variant',
        }
      },
    },
    {
      key: 'note',
      searchQuery: 'note_search',
      cellType: CellType.EDITABLE_CELL,
    },
  ]

  static get ancestors() {
    return [
      {
        modelClass: Organisation,
      },
      {
        modelClass: Role,
      },
      {
        modelClass: Group,
      },
    ]
  }
  static get joins(): Collection.RelatedAnnotation<ObjectAuthorization>[] {
    return [
      {
        relatedModelClass: Role,
        relatedObjectProperty: 'name',
      },
      {
        relatedModelClass: Group,
        relatedObjectProperty: 'name',
      },
    ]
  }
  static get annotations(): Collection.Annotation<
    ObjectAuthorization,
    string
  >[] {
    return [
      {
        key: 'object_name',
        callback: async (
          authorization: ObjectAuthorization,
          api: ApiClientV2
        ) => {
          let name = ''
          try {
            const apiName = contentTypes.find(
              (t) => t.name === authorization.object_type
            ).apiName
            const modelClass = getModelClass(apiName)
            const obj: any = await api.get(modelClass, authorization.object_id)
            if (!obj) {
              name = authorization.object_id
            } else if (obj && obj.name) {
              name = obj.name
            } else if (obj && obj.title) {
              name = obj.title
            } else {
              name = authorization.object_id
            }
            return {
              id: authorization.id,
              annotations: {
                object_name: name,
                tooltip: authorization.object_id,
              },
            }
          } catch (error) {
            console.warn('error', error)
            return {
              id: authorization.id,
              annotations: {
                object_name: authorization.object_id,
                tooltip: authorization.object_id,
              },
            }
          }
        },
      },
    ]
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<ObjectAuthorization, ObjectAuthorization>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(OBJECT_AUTHORIZATION_DEFAULT))
  }
}

export namespace ObjectAuthorization {
  export enum Propagation {
    BOTH = 'both',
    PARENTS = 'parents',
    CHILDREN = 'children',
    NONE = 'none',
  }
}

export class Group extends TransientBaseObject {
  name: string
  description: string
  organisation: string

  static apiUrl = 'group'
  public static objectType: string = 'group'
  public static langPath: string = 'models.core.group'
  static listFields: ListModelField[] = [
    { key: 'name' },
    { key: 'description' },
  ]
  static fields: ModelField[] = [
    { key: 'name' },
    { key: 'description', required: false },
    {
      key: 'profiles',
      required: false,
      virtual: true,
      formFieldType: FormFieldType.PROFILES_SELECT,
    },
  ]
  static detailLinkQuery(context: { [key: string]: string }) {
    return FormFieldQueryBuilderMap[FormFieldType.PROFILES_SELECT](context)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Group, Group>(vm, this, filter, pagination)
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...GROUP_DEFAULT },
    }
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(GROUP_DEFAULT))
  }
}

export class GroupMembership extends TransientBaseObject {
  group: string
  profile: string

  static apiUrl = 'group-membership'
  public static objectType: string = 'group-membership'
  static langPath: string = 'models.core.groupmembership'
  static listFields: ListModelField[] = [
    { key: 'profile_username' },
    { key: 'profile_email' },
  ]

  static collection(
    vm: Vue,
    filter: CollectionFilter,
    pagination: CollectionPagination
  ) {
    const collection: CollectionSubscriber = vm.$api.subscribeListInComponent(
      vm,
      this.objectType,
      filter,
      pagination,
      [],
      [
        { relatedObjectType: 'profile', relatedObjectProperty: 'username' },
        { relatedObjectType: 'profile', relatedObjectProperty: 'email' },
        { relatedObjectType: 'group', relatedObjectProperty: 'name' },
      ]
    )
    return collection
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(GROUP_MEMBERSHIP_DEFAULT))
  }
}

export class Token extends TransientBaseObject {
  label: string
  key_hash: string
  profile: string
  enabled: boolean
}

export class PasswordResetRequest {
  username: string
  email: string
  password1: string
  password2: string
}

export class PasswordReset {
  id: string
  pin: string
  nonce: string
}

export class EmailUpdate {
  pin: string
  nonce: string
}

export class RequestEmailUpdate {
  email1: string
  email2: string
}

export class ProfileActivationRequest {
  username: string
  email: string
  password1: string
  password2: string
}

export class ProfileActivation {
  id: string
  nonce: string
  pin: string
}

export class BackgroundTask extends TransientBaseObject {
  state: string
  description: string
  result?: any
  progress?: {
    ratio: number
  }
}

export class Album extends TransientBaseObject {
  name: string
  description: string
  organisation: string
  public: boolean

  static langPath: string = 'models.core.album'
  static objectType: string = 'album'
  static fields: ModelField[] = [
    { key: 'name' },
    { key: 'description' },
    { key: 'public' },
  ]
  static listFields: ListModelField[] = [
    { key: 'name' },
    { key: 'description' },
    { key: 'public' },
  ]

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    const config = {
      fields: Album.formFields(),
      model: { ...ALBUM_DEFAULT },
    }
    config.fields.forEach((field) => {
      if (field.key === 'public') {
        field.type = 'checkbox'
      }
    })
    return config
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }
}

export class Image extends TransientBaseObject {
  name: string
  album: string
  attachment: string
}

export enum TransactionState {
  // in frontend only
  Undefined = 'undefined',
  PendingRequest = 'pending-request',
  // in backend
  Open = 'open',
  // Sealed: The transaction contains an operation which prevents more operations to be addedd, e.g. an update operation
  Sealed = 'sealed',
  LastOperationFailed = 'last-operation-failed',
  Commit = 'commit',
  CommitFailed = 'commit-failed',
  CommitSucceeded = 'commit-succeeded',
  Rollback = 'rollback',
  RollbackSucceeded = 'rollback-succeeded',
  RollbackFailed = 'rollback-failed',
}

export const TransactionStateMap = {
  [TransactionState.Undefined]: 'Undefined',
  [TransactionState.PendingRequest]: 'Pending Request',
  [TransactionState.Open]: 'Open',
  [TransactionState.Sealed]: 'Sealed',
  [TransactionState.LastOperationFailed]: 'Last Operation Failed',
  [TransactionState.Commit]: 'Commit',
  [TransactionState.CommitFailed]: 'Commit Failed',
  [TransactionState.CommitSucceeded]: 'Commit Succeeded',
  [TransactionState.Rollback]: 'Rollback',
  [TransactionState.RollbackSucceeded]: 'Rollback Succeeded',
  [TransactionState.RollbackFailed]: 'Rollback Failed',
}

export enum TransactionOperationKind {
  Undefined = '',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum TransactionOperationState {
  // in frontend only
  Requested = 'requested',
  RequestFailed = 'request-failed',
  // in backend
  Initialized = 'initialized',
  Started = 'started',
  Completed = 'completed',
  Failed = 'failed',
  CommitSucceeded = 'commit-succeeded',
  CommitFailed = 'commit-failed',
  RollbackSucceeded = 'rollback-succeeded',
  RollbackFailed = 'rollback-failed',
}

export interface TransactionOperation {
  handle: string
  state: TransactionOperationState | string
  sequence?: number | string
  errorMessages: string[]
}

// plain object which is allowed to be made reactive by vue
export interface TransactionInterface {
  id: string
  state: TransactionState | string
  operations: TransactionOperation[]
  meta: Transaction['meta']
}

export class Transaction extends TransientBaseObject {
  idempotency_key: string
  state?: TransactionState
  organisation?: string
  profile?: string
  last_operation?: string
  last_operation_time?: string
  meta: {
    handle?: string
    title?: string
    description?: string
    operations_total?: number
    expiration_timeout?: number // expiration timeout in seconds
    [x: string]: any // optional other properties
  }

  static apiUrl = 'transaction'
  public static objectType: string = 'transaction'
  public static langPath: string = 'models.core.transaction'
  static fields: ModelField[] = [
    { key: 'idempotency_key' },
    { key: 'organisation_name' },
    { key: 'meta' },
  ]
  static listFields: ListModelField[] = [
    { key: 'id' },
    { key: 'idempotency_key' },
    { key: 'organisation_name' },
    { key: 'state' },
  ]
  static joins: Collection.RelatedAnnotation<Organisation>[] = [
    {
      relatedModelClass: Organisation,
      relatedObjectProperty: 'name',
    },
  ]
  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: CollectionFilter,
    pagination: CollectionPagination,
    refreshPeriod?: number
  ) {
    return vm.$apiv2.subscribe<Transaction, Transaction>(
      vm,
      this,
      filter,
      pagination,
      undefined,
      undefined,
      refreshPeriod
    )
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...TRANSACTION_DEFAULT },
    }
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(TRANSACTION_DEFAULT))
  }

  static generateIdempotencyKey() {
    // Chould also use UUIDv4. We just have to ensure uniqueness per user
    // which now is pretty sure with unix timestamp plus some random number
    return `${Date.now()} ${Math.random()}`
  }

  /**
   * Commit the transaction
   * @param vm
   * @param id id of Transaction
   */
  static async commit(apiClient: ApiClientV2, id: string) {
    return apiClient.customPost(`transaction/${id}/commit`)
  }

  /**
   * Rollback the transaction
   * @param vm
   * @param id id of Transaction
   */
  static async rollback(apiClient: ApiClientV2, id: string) {
    return apiClient.customPost(`transaction/${id}/rollback`)
  }

  /**
   * Returns true when the transaction is in a final state
   * @param transaction the transaction to check
   */
  static isFinal(transactionState: TransactionState): boolean {
    // TODO: implement this as a non-static method
    return [
      TransactionState.CommitSucceeded,
      TransactionState.RollbackSucceeded,
    ].includes(transactionState)
  }

  /**
   * Returns true when the transaction is in a failure state
   * @param transaction the transaction to check
   */
  static isFailed(transactionState: TransactionState): boolean {
    // TODO: implement this as a non-static method
    return [
      TransactionState.CommitFailed,
      TransactionState.LastOperationFailed,
      TransactionState.RollbackFailed,
    ].includes(transactionState)
  }

  /**
   * Returns true when the transaction can be rolled back
   * @param transaction the transaction to check
   */
  static isRollbackable(transactionState: TransactionState): boolean {
    // TODO: implement this as a non-static method
    return [
      TransactionState.CommitFailed,
      TransactionState.LastOperationFailed,
      TransactionState.Open,
      TransactionState.Sealed,
    ].includes(transactionState)
  }
}

<template>
  <router-view name="main-menu" />
</template>

<script>
export default {
  name: 'RouterPassThroughMainMenu',
}
</script>

<style scoped></style>

import { simpleCopy } from '@/util/util'

export interface SwwConfig {
  delay: {
    value: number
  }
  channels: {
    fix: {
      value: 0 | 1 | string
    }
    enable: {
      value: 0 | 1 | string
    }
    activationInversion: {
      value: 0 | 1 | string
    }
    sensor: {
      value: number | string
    }
    valueLower: {
      value: number | string
    }
    valueUpper: {
      value: number | string
    }
  }[]
  rules: {
    channel: {
      value: number | string
    }
    onActive: {
      value: number | string
    }
    cmd: {
      value: number | string
    }
    lock: {
      value: 0 | 1 | string
    }
    unlock: {
      value: 0 | 1 | string
    }
    priority: {
      value: 0 | 1 | 2 | 3 | string
    }
    channelCondition: {
      value: number | string
    }
  }[]
}

function createSwwChannelConfig() {
  const channels = []
  for (let i = 0; i < 8; i++) {
    channels.push({
      fix: {
        value: 0,
      },
      enable: {
        value: 0,
      },
      activationInversion: {
        value: 0,
      },
      sensor: {
        value: 0,
      },
      valueLower: {
        value: 8,
      },
      valueUpper: {
        value: 120,
      },
    })
  }
  return channels
}

function createRuleConfig() {
  const rules = []
  for (let i = 0; i < 16; i++) {
    rules.push({
      channel: {
        value: 0,
      },
      onActive: {
        value: 0,
      },
      cmd: {
        value: 0,
      },
      lock: {
        value: 0,
      },
      unlock: {
        value: 0,
      },
      priority: {
        value: 0,
      },
      channelCondition: {
        value: 0,
      },
    })
  }
  return rules
}

export const transformLegacyImportSwwConfig = function (
  config: any
): SwwConfig {
  const rules = []
  const channels = []
  for (const channel of config.channels) {
    channels.push({
      fix: {
        value: channel.fix,
      },
      enable: {
        value: channel.enable,
      },
      activationInversion: {
        value: channel.activationInversion,
      },
      sensor: {
        value: channel.sensor,
      },
      valueLower: {
        value: channel.valueLower,
      },
      valueUpper: {
        value: channel.valueUpper,
      },
    })
  }
  for (const rule of config.rules) {
    rules.push({
      channel: {
        value: rule.channel,
      },
      onActive: {
        value: rule.onActive,
      },
      cmd: {
        value: rule.cmd,
      },
      lock: {
        value: rule.lock,
      },
      unlock: {
        value: rule.unlock,
      },
      priority: {
        value: rule.priority,
      },
      channelCondition: {
        value: rule.channelCondition,
      },
    })
  }
  const parsedConfig: SwwConfig = {
    delay: {
      value: config.delay,
    },
    channels,
    rules,
  }
  return parsedConfig
}

export const getDataIdSwwConfiguration = function (config: SwwConfig): {
  dataID: number
  value: number
}[] {
  const dataList = []
  if (config === null || config === undefined) {
    config = simpleCopy(SWW_DEFAULT_CONFIG)
  }
  dataList.push({
    dataID: 1,
    value: parseInt(
      ((config.delay.value * 1000) / (128 * 1.024) + 0.5).toString()
    ),
  })
  let i = 0
  for (const channel of config.channels) {
    const offset = i * 6
    dataList.push({
      dataID: 2 + offset,
      value: parseInt(channel.fix.value as string),
    })
    dataList.push({
      dataID: 3 + offset,
      value: parseInt(channel.enable.value as string),
    })
    dataList.push({
      dataID: 4 + offset,
      value: parseInt(channel.activationInversion.value as string),
    })
    dataList.push({
      dataID: 5 + offset,
      value: parseInt(channel.sensor.value as string),
    })
    dataList.push({
      dataID: 6 + offset,
      value: parseInt(channel.valueLower.value as string),
    })
    dataList.push({
      dataID: 7 + offset,
      value: parseInt(channel.valueUpper.value as string),
    })
    i++
  }

  i = 0
  for (const rule of config.rules) {
    const offset = i * 7
    dataList.push({
      dataID: 50 + offset,
      value: parseInt(rule.channel.value as string),
    })
    dataList.push({
      dataID: 51 + offset,
      value: parseInt(rule.onActive.value as string),
    })
    dataList.push({
      dataID: 52 + offset,
      value: parseInt(rule.cmd.value as string),
    })
    dataList.push({
      dataID: 53 + offset,
      value: parseInt(rule.lock.value as string),
    })
    dataList.push({
      dataID: 54 + offset,
      value: parseInt(rule.unlock.value as string),
    })
    dataList.push({
      dataID: 55 + offset,
      value: parseInt(rule.priority.value as string),
    })
    dataList.push({
      dataID: 56 + offset,
      value: parseInt(rule.channelCondition.value as string),
    })
    i++
  }

  return dataList
}

export const SWW_DEFAULT_CONFIG: SwwConfig = {
  delay: {
    value: 1,
  },
  channels: createSwwChannelConfig(),
  rules: createRuleConfig(),
}

export enum SwwChannelType {
  STORM = 'Sturm',
  WIND = 'Wind',
  SUN = 'Sonne',
  NIGHT = 'Nacht',
  RAIN = 'Regen',
  K6 = 'K6',
  K7 = 'K7',
  K8 = 'K8',
  NONE = '-',
}

export const SwwChannelText = [
  'Sturm',
  'Wind',
  'Sonne',
  'Nacht',
  'Regen',
  'Individuell',
  'Individuell',
  'Individuell',
]

export interface SwwChannel {
  name: SwwChannelType
  value: number
}

export const SWW_CHANNELS: SwwChannel[] = [
  {
    name: SwwChannelType.STORM,
    value: 0,
  },
  {
    name: SwwChannelType.WIND,
    value: 1,
  },
  {
    name: SwwChannelType.SUN,
    value: 2,
  },
  {
    name: SwwChannelType.NIGHT,
    value: 3,
  },
  {
    name: SwwChannelType.RAIN,
    value: 4,
  },
  {
    name: SwwChannelType.K6,
    value: 5,
  },
  {
    name: SwwChannelType.K7,
    value: 6,
  },
  {
    name: SwwChannelType.K8,
    value: 7,
  },
  {
    name: SwwChannelType.NONE,
    value: 8,
  },
]

export enum SwwSensorType {
  LIGHT = 'Licht',
  WIND = 'Wind',
  RAIN = 'Regen',
  S4 = 'S4',
  S5 = 'S5',
  S6 = 'S6',
  S7 = 'S7',
  GOLDCAP = 'Goldcap',
}

export interface SwwSensor {
  name: SwwSensorType
  value: number
}

export const SWW_SENSOR_VALUES = [
  {
    unit: '[lx]',
    options: [
      /* INTERNAL ONLY
      {
        name: '0 - 5',
        value: 0,
      },*/
      {
        name: '5 - 8',
        value: 8,
      },
      {
        name: '8 - 10',
        value: 16,
      },
      {
        name: '10 - 30',
        value: 24,
      },
      {
        name: '30 - 100',
        value: 32,
      },
      {
        name: "100 - 5'000",
        value: 40,
      },
      {
        name: "5'000 - 10'000",
        value: 48,
      },
      {
        name: "10'000 - 12'000",
        value: 56,
      },
      {
        name: "12'000 - 15'000",
        value: 64,
      },
      {
        name: "15'000 - 20'000",
        value: 72,
      },
      {
        name: "20'000 - 25'000",
        value: 80,
      },
      {
        name: "25'000 - 30'000",
        value: 88,
      },
      {
        name: "30'000 - 40'000",
        value: 96,
      },
      {
        name: "40'000 - 60'000",
        value: 104,
      },
      {
        name: "60'000 - 80'000",
        value: 112,
      },
      {
        name: ">80'000",
        value: 120,
      },
    ],
  },
  {
    unit: '[km/h]',
    options: [
      /* INTERNAL ONLY
      {
        name: '0 - 10',
        value: 0,
      },*/
      {
        name: '10 - 15',
        value: 8,
      },
      {
        name: '15 - 20',
        value: 16,
      },
      {
        name: '20 - 25',
        value: 24,
      },
      {
        name: '25 - 30',
        value: 32,
      },
      {
        name: '30 - 35',
        value: 40,
      },
      {
        name: '35 - 40',
        value: 48,
      },
      {
        name: '40 - 50',
        value: 56,
      },
      {
        name: '50 - 60',
        value: 64,
      },
      {
        name: '60 - 70',
        value: 72,
      },
      {
        name: '70 - 80',
        value: 80,
      },
      {
        name: '80 - 90',
        value: 88,
      },
      {
        name: '90 - 100',
        value: 96,
      },
      {
        name: '100 - 110',
        value: 104,
      },
      {
        name: '110 - 120',
        value: 112,
      },
      {
        name: '>120',
        value: 120,
      },
    ],
  },
  {
    unit: '',
    options: [
      /* INTERNAL ONLY
      {
        name: '150min ohne Regen',
        value: 0
      },*/
      {
        name: '140min ohne Regen',
        value: 8,
      },
      {
        name: '130min ohne Regen',
        value: 16,
      },
      {
        name: '120min ohne Regen',
        value: 24,
      },
      {
        name: '110min ohne Regen',
        value: 32,
      },
      {
        name: '100min ohne Regen',
        value: 40,
      },
      {
        name: '90min ohne Regen',
        value: 48,
      },
      {
        name: '80min ohne Regen',
        value: 56,
      },
      {
        name: '70min ohne Regen',
        value: 64,
      },
      {
        name: '60min ohne Regen',
        value: 72,
      },
      {
        name: '50min ohne Regen',
        value: 80,
      },
      {
        name: '40min ohne Regen',
        value: 88,
      },
      {
        name: '30min ohne Regen',
        value: 96,
      },
      {
        name: '20min ohne Regen',
        value: 104,
      },
      {
        name: '10min ohne Regen',
        value: 112,
      },
      {
        name: 'Regen erkannt',
        value: 120,
      },
    ],
  }, // Only default values 1 - 15
  {}, // Only default values 1 - 15
  {}, // Only default values 1 - 15
  {}, // Only default values 1 - 15
  {}, // Only default values 1 - 15
  {
    unit: '[V]',
    options: [
      /* INTERNAL ONLY
      {
        name: '< 3.5',
        value: 0,
      },*/
      {
        name: '3.5 - 3.75',
        value: 16,
      },
      {
        name: '3.75 - 4',
        value: 32,
      },
      {
        name: '4 - 4.25',
        value: 48,
      },
      {
        name: '4.25 - 4.5',
        value: 64,
      },
      {
        name: '4.5 - 4.75',
        value: 80,
      },
      {
        name: '4.75 - 5',
        value: 96,
      },
      {
        name: '> 5',
        value: 112,
      },
    ],
  },
]

export const SWW_SENSORS: SwwSensor[] = [
  {
    name: SwwSensorType.LIGHT,
    value: 0,
  },
  {
    name: SwwSensorType.WIND,
    value: 1,
  },
  {
    name: SwwSensorType.RAIN,
    value: 2,
  },
  {
    name: SwwSensorType.S4,
    value: 3,
  },
  {
    name: SwwSensorType.S5,
    value: 4,
  },
  {
    name: SwwSensorType.S6,
    value: 5,
  },
  {
    name: SwwSensorType.S7,
    value: 6,
  },
  {
    name: SwwSensorType.GOLDCAP,
    value: 7,
  },
]

export const CMD_LIST = [
  {
    name: '-',
    value: 0x00,
  },
  {
    name: 'Auf',
    value: 0x03,
  },
  {
    name: 'Ab',
    value: 0x04,
  },
  {
    name: 'Ab 2',
    value: 0x16,
  },
  {
    name: 'Stopp',
    value: 0x05,
  },
  /*{
    name: 'Anlage Auf',
    value: 0x07,
  },
  {
    name: 'Anlage Ab',
    value: 0x08,
  },
  {
    name: 'Anlage Stopp',
    value: 0x09,
  },*/
  {
    name: 'Pos',
    value: 0x10,
  } /*
  {
    name: 'Pos ohne Stopp',
    value: 0x17,
  },
  {
    name: 'Abschatten',
    value: 0x1a,
  },
  {
    name: 'Anlage Disable User',
    value: 0x12,
  },
  {
    name: 'Anlage Enable User',
    value: 0x13,
  },*/,
  {
    name: 'Sperren',
    value: 0x14,
  },
  {
    name: 'Entsperren',
    value: 0x15,
  },
]

export const PRIORITIES = [
  {
    name: '0',
    value: 0,
  },
  {
    name: '1',
    value: 1,
  },
  {
    name: '2',
    value: 2,
  },
  {
    name: '3',
    value: 3,
  },
]

export const SVG_COMPASS = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="500pt" height="500pt" viewBox="0 0 500 500" version="1.1">
<defs>
<g>
<symbol overflow="visible" id="glyph0-0">
<path style="stroke:none;" d="M 2.34375 8.3125 L 2.34375 -33.140625 L 25.84375 -33.140625 L 25.84375 8.3125 Z M 4.984375 5.6875 L 23.21875 5.6875 L 23.21875 -30.5 L 4.984375 -30.5 Z M 4.984375 5.6875 "/>
</symbol>
<symbol overflow="visible" id="glyph0-1">
<path style="stroke:none;" d="M 4.609375 -34.265625 L 10.859375 -34.265625 L 26.046875 -5.59375 L 26.046875 -34.265625 L 30.546875 -34.265625 L 30.546875 0 L 24.296875 0 L 9.109375 -28.65625 L 9.109375 0 L 4.609375 0 Z M 4.609375 -34.265625 "/>
</symbol>
<symbol overflow="visible" id="glyph0-2">
<path style="stroke:none;" d="M 25.15625 -33.140625 L 25.15625 -28.625 C 23.394531 -29.457031 21.734375 -30.082031 20.171875 -30.5 C 18.609375 -30.914062 17.101562 -31.125 15.65625 -31.125 C 13.125 -31.125 11.171875 -30.632812 9.796875 -29.65625 C 8.429688 -28.675781 7.75 -27.28125 7.75 -25.46875 C 7.75 -23.957031 8.203125 -22.816406 9.109375 -22.046875 C 10.023438 -21.273438 11.753906 -20.648438 14.296875 -20.171875 L 17.09375 -19.59375 C 20.550781 -18.9375 23.101562 -17.773438 24.75 -16.109375 C 26.394531 -14.453125 27.21875 -12.234375 27.21875 -9.453125 C 27.21875 -6.128906 26.101562 -3.609375 23.875 -1.890625 C 21.65625 -0.179688 18.394531 0.671875 14.09375 0.671875 C 12.46875 0.671875 10.738281 0.484375 8.90625 0.109375 C 7.082031 -0.253906 5.191406 -0.796875 3.234375 -1.515625 L 3.234375 -6.28125 C 5.117188 -5.226562 6.960938 -4.429688 8.765625 -3.890625 C 10.566406 -3.359375 12.34375 -3.09375 14.09375 -3.09375 C 16.738281 -3.09375 18.78125 -3.613281 20.21875 -4.65625 C 21.65625 -5.695312 22.375 -7.179688 22.375 -9.109375 C 22.375 -10.796875 21.859375 -12.113281 20.828125 -13.0625 C 19.796875 -14.007812 18.101562 -14.71875 15.75 -15.1875 L 12.921875 -15.75 C 9.460938 -16.4375 6.957031 -17.515625 5.40625 -18.984375 C 3.863281 -20.453125 3.09375 -22.492188 3.09375 -25.109375 C 3.09375 -28.140625 4.160156 -30.523438 6.296875 -32.265625 C 8.429688 -34.015625 11.375 -34.890625 15.125 -34.890625 C 16.726562 -34.890625 18.363281 -34.742188 20.03125 -34.453125 C 21.695312 -34.160156 23.40625 -33.722656 25.15625 -33.140625 Z M 25.15625 -33.140625 "/>
</symbol>
<symbol overflow="visible" id="glyph0-3">
<path style="stroke:none;" d="M 1.5625 -34.265625 L 6.25 -34.265625 L 13.453125 -5.296875 L 20.625 -34.265625 L 25.84375 -34.265625 L 33.046875 -5.296875 L 40.234375 -34.265625 L 44.9375 -34.265625 L 36.328125 0 L 30.5 0 L 23.265625 -29.75 L 15.96875 0 L 10.140625 0 Z M 1.5625 -34.265625 "/>
</symbol>
<symbol overflow="visible" id="glyph0-4">
<path style="stroke:none;" d="M 4.609375 -34.265625 L 26.28125 -34.265625 L 26.28125 -30.359375 L 9.25 -30.359375 L 9.25 -20.21875 L 25.5625 -20.21875 L 25.5625 -16.3125 L 9.25 -16.3125 L 9.25 -3.90625 L 26.6875 -3.90625 L 26.6875 0 L 4.609375 0 Z M 4.609375 -34.265625 "/>
</symbol>
</g>
</defs>
<g id="surface1">
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.999662 0.169138 L 29.999662 49.049937 L 34.999804 44.049795 Z M 29.999662 0.169138 " transform="matrix(2.105409,-2.105409,2.105409,2.105409,80.900315,208.684352)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.690747 0.16821 L 29.691675 49.049937 L 24.690605 43.963521 Z M 29.690747 0.16821 " transform="matrix(2.105409,-2.105409,2.105409,2.105409,80.900315,208.684352)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 30.000097 0.168321 L 30.000097 49.049121 L 34.999312 44.049906 Z M 30.000097 0.168321 " transform="matrix(2.105409,2.105409,-2.105409,2.105409,288.066918,83.010492)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.691183 0.169249 L 29.691183 49.050048 L 24.691041 43.96456 Z M 29.691183 0.169249 " transform="matrix(2.105409,2.105409,-2.105409,2.105409,288.066918,83.010492)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 30.000209 0.168813 L 30.000209 49.049613 L 35.000351 44.04947 Z M 30.000209 0.168813 " transform="matrix(-2.105409,2.105409,-2.105409,-2.105409,413.740778,290.177094)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.690367 0.168813 L 29.690367 49.049613 L 24.690224 43.964124 Z M 29.690367 0.168813 " transform="matrix(-2.105409,2.105409,-2.105409,-2.105409,413.740778,290.177094)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 30.0007 0.168702 L 29.999773 49.050429 L 34.999915 44.050287 Z M 30.0007 0.168702 " transform="matrix(-2.105409,-2.105409,2.105409,-2.105409,206.574175,415.850954)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.690859 0.168702 L 29.690859 49.049501 L 24.690716 43.964013 Z M 29.690859 0.168702 " transform="matrix(-2.105409,-2.105409,2.105409,-2.105409,206.574175,415.850954)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.999502 0.168986 L 29.999502 49.049674 L 35.00039 44.049864 Z M 29.999502 0.168986 " transform="matrix(3.625138,0,0,3.625138,139.126583,71.078807)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.691325 0.168986 L 29.691325 49.049674 L 24.690437 43.963661 Z M 29.691325 0.168986 " transform="matrix(3.625138,0,0,3.625138,139.126583,71.078807)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 30.000354 0.168264 L 30.000354 49.050029 L 35.000164 44.050219 Z M 30.000354 0.168264 " transform="matrix(0,3.625138,-3.625138,0,425.672479,141.236776)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.691099 0.168264 L 29.691099 49.050029 L 24.691288 43.964015 Z M 29.691099 0.168264 " transform="matrix(0,3.625138,-3.625138,0,425.672479,141.236776)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.999631 0.16849 L 29.999631 49.050255 L 35.000519 44.049367 Z M 29.999631 0.16849 " transform="matrix(-3.625138,0,0,-3.625138,355.51451,427.782673)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.691454 0.16849 L 29.691454 49.050255 L 24.690566 43.964241 Z M 29.691454 0.16849 " transform="matrix(-3.625138,0,0,-3.625138,355.51451,427.782673)"/>
<path style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.999857 0.169212 L 29.999857 49.0499 L 34.999668 44.05009 Z M 29.999857 0.169212 " transform="matrix(0,-3.625138,3.625138,0,68.968614,357.624704)"/>
<path style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.297646;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.690602 0.169212 L 29.690602 49.0499 L 24.690792 43.963886 Z M 29.690602 0.169212 " transform="matrix(0,-3.625138,3.625138,0,68.968614,357.624704)"/>
<g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
  <use xlink:href="#glyph0-1" x="228.5" y="60.108093"/>
</g>
<path style="fill:none;stroke-width:0.912792;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 29.838 3.308 L 30.638 3.308 L 32.582 6.978 L 32.582 3.308 L 33.158 3.308 L 33.158 7.694 L 32.358 7.694 L 30.414 4.026 L 30.414 7.694 L 29.838 7.694 Z M 29.248 2.1245 " transform="matrix(7.8125,0,0,7.8125,0,0)"/>
<g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
  <use xlink:href="#glyph0-2" x="231.945312" y="469.9245"/>
</g>
<path style="fill:none;stroke-width:0.912792;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 32.909 55.9085 L 32.909 56.4865 C 32.6835 56.38 32.471 56.3 32.271 56.2465 C 32.071 56.1935 31.878 56.1665 31.693 56.1665 C 31.369 56.1665 31.119 56.2295 30.943 56.3545 C 30.768 56.48 30.681 56.6585 30.681 56.8905 C 30.681 57.084 30.739 57.23 30.855 57.3285 C 30.972 57.4275 31.1935 57.5075 31.519 57.5685 L 31.877 57.6425 C 32.3195 57.7265 32.646 57.8755 32.857 58.0885 C 33.0675 58.3005 33.173 58.5845 33.173 58.9405 C 33.173 59.366 33.03 59.6885 32.745 59.9085 C 32.461 60.1275 32.0435 60.2365 31.493 60.2365 C 31.285 60.2365 31.0635 60.2125 30.829 60.1645 C 30.5955 60.118 30.3535 60.0485 30.103 59.9565 L 30.103 59.3465 C 30.344 59.4815 30.58 59.5835 30.811 59.6525 C 31.0415 59.7205 31.269 59.7545 31.493 59.7545 C 31.8315 59.7545 32.093 59.688 32.277 59.5545 C 32.461 59.4215 32.553 59.2315 32.553 58.9845 C 32.553 58.7685 32.487 58.6 32.355 58.4785 C 32.223 58.3575 32.006 58.2665 31.705 58.2065 L 31.343 58.1345 C 30.9 58.0465 30.5795 57.9085 30.381 57.7205 C 30.1835 57.5325 30.085 57.2715 30.085 56.9365 C 30.085 56.5485 30.2215 56.2435 30.495 56.0205 C 30.768 55.7965 31.145 55.6845 31.625 55.6845 C 31.83 55.6845 32.0395 55.7035 32.253 55.7405 C 32.466 55.778 32.685 55.834 32.909 55.9085 Z M 29.689 54.581 " transform="matrix(7.8125,0,0,7.8125,0,0)"/>
<g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
  <use xlink:href="#glyph0-3" x="14.414062" y="265.319031"/>
</g>
<path style="fill:none;stroke-width:0.912792;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 2.045 29.575 L 2.645 29.575 L 3.567 33.283 L 4.485 29.575 L 5.153 29.575 L 6.075 33.283 L 6.995 29.575 L 7.597 29.575 L 6.495 33.961 L 5.749 33.961 L 4.823 30.153 L 3.889 33.961 L 3.143 33.961 Z M 1.845 28.3915 " transform="matrix(7.8125,0,0,7.8125,0,0)"/>
<g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
  <use xlink:href="#glyph0-4" x="441.652344" y="265.319031"/>
</g>
<path style="fill:none;stroke-width:0.912792;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 57.1215 29.575 L 59.8955 29.575 L 59.8955 30.075 L 57.7155 30.075 L 57.7155 31.373 L 59.8035 31.373 L 59.8035 31.873 L 57.7155 31.873 L 57.7155 33.461 L 59.9475 33.461 L 59.9475 33.961 L 57.1215 33.961 Z M 56.5315 28.3915 " transform="matrix(7.8125,0,0,7.8125,0,0)"/>
</g>
</svg>
`

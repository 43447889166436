import { EditorCommands } from '@/apps/brelag/mandator-user/models/editor'

const MOVE_UNIT = 15
const ZOOM_FACTOR = 0.3
const MAX_ZOOM_LEVEL = 5
const MIN_ZOOM_LEVEL = 0.4

const WORKSPACE_WIDTH = 1024
const WORKSPACE_HEIGHT = 668

/**
 * Initializes the view to default values
 * @param editorContainer
 */
export function initView(editorContainer) {
  const viewbox = editorContainer.svg.viewbox()
  viewbox.x = 0
  viewbox.y = 0
  viewbox.width = WORKSPACE_WIDTH
  viewbox.height = WORKSPACE_HEIGHT
  editorContainer.svg.viewbox(viewbox)
}

/**
 * Resets view so that all elements are visible
 * @param editorContainer
 */
export function resetView(editorContainer) {
  const viewbox = editorContainer.svg.viewbox()
  const containerBox = editorContainer.svg.node.getBBox()
  viewbox.x = containerBox.x
  viewbox.y = containerBox.y
  if (containerBox.width !== 0) {
    viewbox.width = containerBox.width
  }
  if (containerBox.height !== 0) {
    viewbox.height = containerBox.height
  }
  editorContainer.svg.viewbox(viewbox)
}

export function move(editorContainer, direction: EditorCommands) {
  const viewbox = editorContainer.svg.viewbox()
  switch (direction) {
    case EditorCommands.MOVE_LEFT:
      viewbox.x += MOVE_UNIT
      break
    case EditorCommands.MOVE_RIGHT:
      viewbox.x -= MOVE_UNIT
      break
    case EditorCommands.MOVE_UP:
      viewbox.y += MOVE_UNIT
      break
    case EditorCommands.MOVE_DOWN:
      viewbox.y -= MOVE_UNIT
      break
    default:
      break
  }
  editorContainer.svg.viewbox(viewbox)
}

export function zoomIn(editorContainer) {
  const zoomLevel = editorContainer.svg.zoom() * (1 + ZOOM_FACTOR)
  return zoomLevel > MAX_ZOOM_LEVEL ? MAX_ZOOM_LEVEL : zoomLevel
}

export function zoomOut(editorContainer) {
  const zoomLevel = editorContainer.svg.zoom() * (1 - ZOOM_FACTOR)
  return zoomLevel < MIN_ZOOM_LEVEL ? MIN_ZOOM_LEVEL : zoomLevel
}

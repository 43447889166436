
import isEqual from 'lodash.isequal'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'embedded-base-form',
  props: {
    title: {
      required: true,
    },
    contentType: {
      required: true,
    },
    url: {
      default: null,
    },
    dataObject: {
      required: true,
    },
    columnWidth: {
      default: 'is-12',
    },
    customSubmit: {
      default: false,
    },
    hasError: {
      default: false,
    },
    errorMessage: {
      default: '',
    },
  },
})
export default class EmbeddedBaseForm extends Vue {
  contentType: string
  url: string
  dataObject: any
  originalDataObject: object = {}
  loading: boolean = false
  formValidation = {
    isValid: true,
    errorMessages: [],
  }
  formOpen: boolean = false

  mounted() {
    Vue.set(
      this,
      'originalDataObject',
      JSON.parse(JSON.stringify(this.$props.dataObject))
    )
  }

  submit() {
    if (this.$props.customSubmit) {
      this.$emit('custom_submit', true)
    } else {
      return Promise.resolve(this.dataObject).then((dataObject) => {
        let promise: Promise<any>
        let isUpdate = true
        if (!dataObject.id || dataObject.id === '0' || dataObject.id === '') {
          if (this.url !== null) {
            promise = this.$api.customCreate(this.url, dataObject)
          } else {
            promise = this.$api.create(this.contentType, dataObject)
          }
          isUpdate = false
        } else {
          promise = this.$api.update(this.contentType, dataObject)
        }
        const loadingComponent = this.$buefy.loading.open({ container: null })
        return promise
          .then((response) => {
            loadingComponent.close()
            Vue.set(
              this,
              'originalDataObject',
              JSON.parse(JSON.stringify(dataObject))
            )
            if (!isUpdate) {
              this.$emit('create_finished', response)
            } else {
              this.$emit('update_finished', response)
            }
          })
          .catch((error) => {
            loadingComponent.close()
            this.handleError(error)
          })
      })
    }
  }

  handleError(error) {
    if (error.response.data && this.isObject(error.response.data)) {
      this.formValidation.errorMessages = [error.message]
      for (const key in error.response.data) {
        this.formValidation.errorMessages.push(
          key + ': ' + error.response.data[key]
        )
      }
    } else if (
      error.response.data.constructor === Array &&
      error.response.data.length > 0
    ) {
      this.formValidation.errorMessages = [error.response.data[0]]
    } else if (error.message) {
      this.formValidation.errorMessages = [error.message]
    } else {
      this.formValidation.errorMessages = [error]
    }
    this.formValidation.isValid = false
  }

  isObject(o) {
    return o instanceof Object && o.constructor === Object
  }

  get isEditable() {
    if (
      this.dataObject._permissions &&
      this.dataObject._permissions.hasOwnProperty('edit')
    ) {
      return this.dataObject._permissions.edit
    } else {
      return true
    }
  }

  get isDirty() {
    return !isEqual(this.dataObject, this.originalDataObject)
  }
}

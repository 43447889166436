
import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'
import { CollectionFilter } from '@/api/ApiClient'
import KnockautDetailModal from '@/apps/brelag/brelag-supporter/components/KnockautDetailModal.vue'
import KnockautMap from '@/apps/brelag/brelag-supporter/components/KnockautMap.vue'

import { Organisation } from '@/models/core/organisation'
import { KnockautProject } from '@/apps/brelag/common/models/knockaut'
import { CustomAction } from '@/components/common/interfaces'
import OrganisationSelector from '@/components/navigation/sidebar/OrganisationSelector.vue'

@Component({
  components: {
    BaseListV2,
    KnockautDetailModal,
    KnockautMap,
    OrganisationSelector,
  },
  methods: {},
  data() {
    return {
      KnockautProject,
    }
  },
})
export default class SupporterKnockautDashboard extends Vue {
  $refs: {
    knockautList: BaseListV2<KnockautProject>
  }
  mandators: Organisation[] = []
  projectFilter: CollectionFilter = {}
  selectedMandator: string = null
  selectedProject: KnockautProject = null
  detailModalActive = false

  created() {
    this.filterProjects()
  }

  async mounted() {
    try {
      const context = {
        filter: {
          organisation: this.$store.getters['global/organisation'].id,
        },
        pagination: {
          page: 1,
          pageSize: 100,
        },
      }
      const mandators = await this.$apiv2.getListItems<Organisation>(
        Organisation,
        context
      )
      this.mandators = [
        { id: null, name: '- Kein Filter -' } as Organisation,
        ...mandators,
      ]
    } catch (error) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(error),
        type: 'is-danger',
        duration: 5000,
      })
    }
  }

  mandatorSelected(newOrg: Organisation): void {
    this.selectedMandator = newOrg.id
    this.filterProjects()
  }

  filterProjects() {
    this.projectFilter = {
      organisation: this.selectedMandator,
    }
  }

  customActions(): CustomAction[] {
    const actions: CustomAction[] = [
      {
        label: 'Details',
        type: 'is-info',
        icon: 'mdi-arrow-expand',
        callback: async (project: KnockautProject) => {
          this.selectedProject = project
          this.detailModalActive = true
        },
      },
    ]
    return actions
  }
}

import isEqual from 'lodash.isequal'
import axios, { AxiosRequestConfig } from 'axios'

import {
  BACKGROUND_TASK_DEFAULT,
  OBJECT_AUTHORIZATION_DEFAULT,
  ROLE_DEFAULT,
} from '@/models/core/defaults'
import store from '@/store/index'
import { DataStateDisplay } from '@/util/DataStateDisplay'
import stringify from 'json-stable-stringify'
import {
  EmailUpdate,
  PasswordReset,
  PasswordResetRequest,
  ProfileActivation,
  ProfileActivationRequest,
  RequestEmailUpdate,
} from '@/models/core/models'
import Vue from 'vue'
import { getApiUrl } from '@/models/objectRegistry'
import { MockHandler } from '@/mock/adapter'
import { CLIENT_APP_DEFAULT } from '@/models/client/defaults'
import { ORGANISATION_DEFAULT } from '@/models/core/organisation'
import { PROFILE_DEFAULT, Profile } from '@/models/core/profile'
import { simpleCopy } from '@/util/util'
import {
  KnockautLicenseKey,
  OutputOdooCustomerSerializer,
} from '@/apps/brelag/common/models/knockautLicenseKey'

export const defaultAxiosConfig: AxiosRequestConfig = {
  headers: {
    'X-CSRFTOKEN': '',
  },
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
}

export const urlsWithoutTrailingSlash = new Set()
urlsWithoutTrailingSlash.add('/config')

/**
 * Construct url from objectType
 * @returns {string}
 */
function constructUrl(url: string, noTrailingSlash: boolean = false): string {
  url = getApiUrl(url)
  // trailing slash check has to be done before adding prefix
  const hasNoTrailingSlash = urlsWithoutTrailingSlash.has(url)
  const prefix = '/api/v1/'
  if (!url.startsWith(prefix)) {
    url = prefix + url
  }
  if (hasNoTrailingSlash || noTrailingSlash) {
    return url
  } else {
    return url + '/'
  }
}

export class Collection {
  refreshing: boolean = false
  objectType: string
  collectionSubscribers = new Map<string, CollectionSubscriber>()
  valuePrototype = {
    size: undefined,
    objects: [],
  }

  /**
   * Create new Collection.
   * @param {string} objectType
   */
  constructor(objectType: string) {
    this.objectType = objectType
  }

  /**
   * Subscribe a new CollectionSubscriber.
   * @param filter
   * @param {CollectionPagination} pagination
   * @returns {CollectionSubscriber}
   */
  subscribe(
    filter,
    pagination: CollectionPagination,
    config: AxiosRequestConfig,
    annotations: any[] = [],
    joins: any[] = []
  ): CollectionSubscriber {
    let subscriber = this.collectionSubscribers.get(stringify(filter))
    if (!subscriber) {
      subscriber = new CollectionSubscriber(
        this,
        this.objectType,
        this.valuePrototype,
        filter,
        pagination,
        constructUrl(this.objectType),
        config,
        annotations,
        joins
      )
      this.collectionSubscribers.set(subscriber.key, subscriber)
    }
    subscriber.addTarget(annotations, joins)
    return subscriber
  }

  /**
   * Unsubscribe a CollectionSubscriber from the collection.
   * @param {CollectionSubscriber} subscriber
   */
  unSubscribe(subscriber: CollectionSubscriber): void {
    subscriber.removeTarget()
    if (subscriber.refCount === 0) {
      // delete it. Javascript garbage collector should delete subscriber since it has no references anymore
      this.collectionSubscribers.delete(subscriber.key)
    }
  }

  /**
   * Unsubscribe all
   */
  unSubscribeAll(): void {
    this.collectionSubscribers.forEach((subscriber) => {
      subscriber.removeTarget()
      this.collectionSubscribers.delete(subscriber.key)
    })
  }

  public get(
    id: string,
    noTrailingSlash: boolean = false,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ): Promise<object> {
    let url = constructUrl(this.objectType) + id
    if (!noTrailingSlash) {
      url = url + '/'
    }
    return axios
      .get(url, config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  public delete(
    id: string,
    purge: boolean = false,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ) {
    let url = constructUrl(this.objectType) + id + '/'
    if (purge) {
      url = url + '?purge=true'
    }
    return axios
      .delete(url, config)
      .then(() => {
        return this.refresh()
      })
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  public accept(
    id: string,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ) {
    const endpointName = '/accept'
    return this.customPost(id, endpointName, config)
  }

  public publish(
    id: string,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ) {
    const endpointName = '/publish'
    return this.customPost(id, endpointName, config)
  }

  public reject(
    id: string,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ) {
    const endpointName = '/reject'
    return this.customPost(id, endpointName, config)
  }

  public customPost(
    id: string,
    endpoint: string,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ) {
    const axiosConfig: AxiosRequestConfig = JSON.parse(JSON.stringify(config))
    return axios
      .post(constructUrl(this.objectType) + id + endpoint, {}, axiosConfig)
      .then(() => {
        return this.refresh()
      })
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  /**
   * Create new object and refresh collection if available
   * @param data
   * @param config for axios request
   * @returns {Promise<never | any[]>}
   */
  public create(
    data: any,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ) {
    const axiosConfig: AxiosRequestConfig = JSON.parse(JSON.stringify(config))
    if (this.objectType === 'avatar') {
      axiosConfig.headers['Content-Type'] = 'multipart/form-data'
    }
    if (this.objectType === 'firmware_img') {
      axiosConfig.headers['Content-Type'] = 'application/octet-stream'
    }
    return axios
      .post(constructUrl(this.objectType), data, axiosConfig)
      .then((response) => {
        return Promise.all([this.refresh(), Promise.resolve(response)])
      })
      .then((response) => {
        return response[1]
      })
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  public update(
    data: any,
    config: AxiosRequestConfig = store.state.global.axiosConfig
  ): Promise<void> {
    const axiosConfig: AxiosRequestConfig = JSON.parse(JSON.stringify(config))
    return axios
      .patch(constructUrl(this.objectType) + data.id + '/', data, axiosConfig)
      .then(() => {
        return this.refresh()
      })
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  /**
   * Refreshes all collection subscribers.
   * @returns {Promise<void>}
   */
  refresh(): Promise<void> {
    this.refreshing = true
    const refreshPromises: Array<Promise<boolean>> = []
    this.collectionSubscribers.forEach((subscriber) => {
      refreshPromises.push(subscriber.refresh())
    })
    return Promise.all(refreshPromises)
      .catch((error) => {
        console.warn(error)
      })
      .then(() => {
        this.refreshing = false
      })
  }
}

export class CollectionSubscriber {
  axiosConfig: AxiosRequestConfig = store.state.global.axiosConfig
  refCount: number = 0
  objects: any[] = []
  result: any
  info: CollectionInfo
  status: CollectionStatus
  filter: CollectionFilter | null
  pagination: CollectionPagination
  url: string
  key: string
  objectType: string
  targets: Set<object>
  annotations: any[] = []
  joins: any[] = []

  // the link to be able to unsubscribe
  private _collection: Collection

  constructor(
    collection: Collection,
    objectType: string,
    valuePrototype: any,
    filter: CollectionFilter | null,
    pagination: CollectionPagination,
    url: string,
    config: AxiosRequestConfig,
    annotations: any[],
    joins: any[]
  ) {
    this._collection = collection
    this.result = JSON.parse(JSON.stringify(valuePrototype))
    this.filter = filter
    this.key = stringify(filter)
    this.objectType = objectType
    this.pagination = pagination
    this.info = {}
    this.url = url
    this.axiosConfig = config
    this.annotations = annotations
    this.joins = joins
    this.status = {
      fetching: false,
      ready: false,
    }

    // If filter value is 'null', we leave the collection empty.
    if (filter === null) {
      this.status.ready = true
      this.status.readyPromise = Promise.resolve(true)
    } else {
      this.status.readyPromise = this.refresh().then((response) => {
        this.status.ready = response
        return response
      })
    }
  }

  /**
   * Set collection filter.
   * @param {CollectionFilter} filter: When null, the collection is emptied.
   */
  public setFilter(
    filter: CollectionFilter | null,
    noRefresh: boolean = false
  ): Promise<boolean> {
    this.filter = filter
    if (!noRefresh) {
      return this.refresh()
    }
  }

  public addFilter(filter: CollectionFilter) {
    if (this.filter === null) {
      this.filter = {}
    }

    for (const key in filter) {
      this.filter[key] = filter[key]
    }
    return this.refresh()
  }

  addTarget(annotations: any[], joins: any[]): void {
    // TODO: Do we want to just add annotations/joins or should it be a new collection
    // if annotations/joins are different?
    annotations.forEach((annotation) => {
      let exists = false
      this.annotations.forEach((anno) => {
        if (anno.property === annotation.property) {
          exists = true
        }
      })
      if (!exists) {
        this.annotations.push(annotation)
      }
    })
    joins.forEach((join) => {
      let exists = false
      this.joins.forEach((jo) => {
        if (
          jo.relatedObjectType === join.relatedObjectType &&
          jo.relatedObjectProperty === join.relatedObjectProperty
        ) {
          exists = true
        }
      })
      if (!exists) {
        this.joins.push(join)
      }
    })

    // this.targets.add(target);
    this.refCount++
  }

  removeTarget(): void {
    // this.targets.delete(target);
    this.refCount--
  }

  /**
   * Refreshes collection.
   * @returns {Promise<boolean>}
   */
  public refresh(): Promise<boolean> {
    if (this.filter !== null) {
      this.status.fetching = true
      return axios
        .get(this.constructUrl(), this.axiosConfig)
        .then((response) => {
          this.objects.splice(0, this.objects.length, ...response.data.results)
          Vue.set(this.info, 'size', response.data.size)
          this.status.fetching = false

          // TODO: Error handling for this
          // Do not fail completely if this fails.
          try {
            this.objects.forEach((object) => {
              this.annotations.forEach((annotation) => {
                const origObject = JSON.parse(JSON.stringify(object))
                const property = annotation.property
                // TODO: We dont always want to set the property to loading
                // (e.g. if we just want to transform the property)
                Vue.set(object, property, DataStateDisplay.Loading)
                annotation
                  .callback(object, apiClient, origObject)
                  .then((value) => Vue.set(object, property, value))
                  .catch((error) => {
                    console.error(
                      `Could not annotate object with property ${property}`,
                      object,
                      error
                    )
                    Vue.set(object, property, DataStateDisplay.Error)
                  })
              })

              this.joins.forEach((join) => {
                const relatedObjectType = join.relatedObjectType
                const relatedObjectProperty = join.relatedObjectProperty
                const newProperty = `${relatedObjectType}_${relatedObjectProperty}`
                const annotationCb = (object) => {
                  if (object[relatedObjectType]) {
                    return apiClient
                      .get(relatedObjectType, object[relatedObjectType])
                      .then((response) => response[relatedObjectProperty])
                  } else if (object[relatedObjectType] === null) {
                    return Promise.resolve(DataStateDisplay.OkNoData)
                  } else {
                    throw new Error(
                      `${relatedObjectType} does not exist on object.`
                    )
                  }
                }
                apiClient.annotateObject(object, newProperty, annotationCb)
              })
            })
          } catch (exception) {
            console.error(exception)
          }

          return true
        })
        .catch(() => {
          this.status.fetching = false
          return false
        })
    } else {
      this.objects.splice(0, this.objects.length)
      Vue.set(this.info, 'size', 0)
      this.status.fetching = false
      return Promise.resolve(true)
    }
  }

  /**
   * Set collection pagination.
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise<boolean>}
   */
  public setPagination(
    page?: number,
    pageSize?: number,
    noRefresh: boolean = false
  ): Promise<boolean> {
    if (page) {
      this.pagination.page = page
    }
    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
    if (!noRefresh) {
      return this.refresh()
    }
  }

  public setFilterAndPagination(
    filter: CollectionFilter,
    pagination: CollectionPagination
  ) {
    // Only need to refresh if something changed
    if (
      !isEqual(stringify(filter), stringify(this.filter)) ||
      !isEqual(stringify(pagination), stringify(this.pagination))
    ) {
      this.filter = filter
      if (pagination.page) {
        this.pagination.page = pagination.page
      }
      if (pagination.pageSize) {
        this.pagination.pageSize = pagination.pageSize
      }
      return this.refresh()
    }
  }

  /**
   * Set collection order.
   * @param {string} field
   * @param {string} order
   * @returns {Promise<boolean>}
   */
  public setOrder(field: string, order?: string): Promise<boolean> {
    if (this.filter === null) {
      this.filter = {}
    }
    if (order) {
      if (order === 'desc' && !field.endsWith('_dsc')) {
        if (!field.endsWith('_asc')) {
          // Add _dsc
          this.filter.order_by = field + '_dsc'
        } else {
          // Slice off _asc and add _dsc:
          this.filter.order_by = field.slice(0, -4) + '_dsc'
        }
      } else {
        this.filter.order_by = field
      }
    } else {
      this.filter.order_by = field
    }
    return this.refresh()
  }

  /**
   * Dispose collection subscriber by
   * unsubscribing itself from its collection
   */
  public dispose() {
    this._collection.unSubscribe(this)
  }

  /**
   * Constructs url based on filter and pagination settings
   * @returns {string}
   */
  private constructUrl(): string {
    let params = '?'
    for (const key in this.filter) {
      if (this.filter[key] !== undefined && this.filter[key] !== null) {
        params += key + '=' + this.filter[key] + '&'
      }
    }
    if (this.pagination !== undefined && this.pagination.page !== undefined) {
      params += 'page=' + this.pagination.page
      if (this.pagination.pageSize !== undefined) {
        params += '&page_size=' + this.pagination.pageSize
      }
      params += '&show_size=true'
    } else {
      params = params.slice(0, params.length - 1)
    }
    return this.url + params
  }
}

export class ApiClient {
  // e.g. <'organisation', Collection>>
  collections = new Map<string, Collection>()
  defaultModelsMap = {
    role: ROLE_DEFAULT,
    organisation: ORGANISATION_DEFAULT,
    profile: PROFILE_DEFAULT,
    'object-authorization': OBJECT_AUTHORIZATION_DEFAULT,
    'background-task': BACKGROUND_TASK_DEFAULT,
    'client-app': CLIENT_APP_DEFAULT,
  }
  axiosConfig: AxiosRequestConfig = undefined

  constructor(axiosConfig?: AxiosRequestConfig) {
    if (axiosConfig) {
      this.axiosConfig = simpleCopy(axiosConfig)
    } else {
      console.log(defaultAxiosConfig)
      this.axiosConfig = simpleCopy(defaultAxiosConfig)
    }

    MockHandler.getInstance(axios)
  }

  /**
   * Create new object of type objectType
   * @param {string} objectType
   * @param data
   * @returns {Promise<any[]>}
   */
  public create(objectType: string, data: any) {
    const collection = this.getOrCreateCollection(objectType)
    return collection.create(data, this.axiosConfig)
  }

  /**
   * Create a new object using a custom url
   * @param {string} url URL where to POST
   * @param data
   * @returns {Promise<AxiosResponse>}
   */
  public customCreate(url: string, data: any) {
    return axios
      .post(constructUrl(url, true), data, this.axiosConfig)
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  /**
   * Update object of type objectType
   * @param {string} objectType
   * @param data
   * @returns {Promise<boolean[]>}
   */
  public update(objectType: string, data: any): Promise<void> {
    const collection = this.getOrCreateCollection(objectType)
    return collection.update(data, this.axiosConfig)
  }

  public accept(objectType: string, data: any): Promise<void> {
    const collection = this.getOrCreateCollection(objectType)
    return collection.accept(data, this.axiosConfig)
  }

  public publish(objectType: string, data: any): Promise<void> {
    const collection = this.getOrCreateCollection(objectType)
    return collection.publish(data, this.axiosConfig)
  }

  public reject(objectType: string, data: any): Promise<void> {
    const collection = this.getOrCreateCollection(objectType)
    return collection.reject(data, this.axiosConfig)
  }

  /**
   * Update object using custom url
   * @param {string} url
   * @param data
   * @returns {Promise<AxiosResponse>}
   */
  public customUpdate(url: string, data: any) {
    return axios
      .patch(constructUrl(url, true), data, this.axiosConfig)
      .catch((error) => {
        console.warn(error)
        return Promise.reject(error)
      })
  }

  /**
   * Fetch a list for one-off usage only
   */
  public getList<T>(
    objectType: string,
    filter: CollectionFilter | null = {},
    pagination: CollectionPagination = {},
    ordering: CollectionOrdering = null
  ): Promise<ObjectList<T>> {
    const collection = this.getOrCreateCollection(objectType)
    const subscriber: CollectionSubscriber = collection.subscribe(
      filter,
      pagination,
      this.axiosConfig
    )
    return subscriber.status.readyPromise.then(() => {
      const response: ObjectList<T> = {
        size: subscriber.info.size,
        results: subscriber.objects,
      }
      subscriber.dispose()
      return response
    })
  }

  /**
   * Subscribe to collection
   * You must take care of unsubscribing yourself given the
   * returned collection
   * @param {string} objectType
   * @param {CollectionFilter} filter When null, the collection is not fetched.
   * @param {CollectionPagination} pagination
   * @returns {CollectionSubscriber}
   */
  public subscribeList<T>(
    objectType: string,
    filter: CollectionFilter | null = {},
    pagination: CollectionPagination = {},
    ordering: CollectionOrdering = null,
    annotations: any[] = [],
    joins: any[] = []
  ): CollectionSubscriber {
    // Get or create collection and add new subscriber
    const collection = this.getOrCreateCollection(objectType)
    const subscriber: CollectionSubscriber = collection.subscribe(
      filter,
      pagination,
      this.axiosConfig,
      annotations,
      joins
    )
    return subscriber
  }

  /**
   * Unsubscribe all collections
   */
  public unsubscribeAll(): void {
    this.collections.forEach((collection) => {
      collection.unSubscribeAll()
    })
    this.collections.clear()
  }

  /**
   * Subscribe to collection
   * The collection will be unsubscribed when the Vue lifecycle hook
   * 'destroyed' is called of the passed Vue instance
   * @param {Vue} vm the instance of which the destroyed hook is used for unsubscribing
   * @param {string} objectType
   * @param {CollectionFilter} filter When null, the collection is not fetched.
   * @param {CollectionPagination} pagination
   * @returns {CollectionSubscriber}
   */
  public subscribeListInComponent(
    vm: Vue,
    objectType: string,
    filter: CollectionFilter | null = {},
    pagination: CollectionPagination = {},
    annotations?: any[],
    joins?: any[]
  ): CollectionSubscriber {
    const subscriber = this.subscribeList<any>(
      objectType,
      filter,
      pagination,
      null,
      annotations,
      joins
    )
    vm.$on('hook:destroyed', () => {
      subscriber.dispose()
    })
    return subscriber
  }

  public customGet(url: string, query: object = {}): Promise<any> {
    url = constructUrl(url, true) + '?'
    for (const key in query) {
      url += '&' + key + '=' + query[key]
    }
    return axios.get(url, this.axiosConfig)
  }

  public find(objectType: string, query: object = {}): Promise<any> {
    let url = constructUrl(objectType) + 'find?'
    for (const key in query) {
      url += '&' + key + '=' + query[key]
    }
    return axios.get(url, this.axiosConfig)
  }

  public get(
    objectType: string,
    id: string,
    noTrailingSlash: boolean = false
  ): Promise<any> {
    if (id === undefined || id === null || id === '') {
      return
    }
    if (id === '0') {
      return this.getDefault(objectType)
    }
    const collection = this.getOrCreateCollection(objectType)
    return collection.get(id, noTrailingSlash, this.axiosConfig)
  }

  public delete(objectType: string, id: string, purge: boolean = false) {
    const collection = this.getOrCreateCollection(objectType)
    return collection.delete(id, purge, this.axiosConfig)
  }

  public getAttachmentUrl(id: string) {
    if (id === undefined || id === null) {
      return
    }
    const collection = this.getOrCreateCollection('attachment')
    return `${constructUrl(
      collection.objectType
    )}${id}/content?${Math.random()}`
  }

  public revokeToken(id: string) {
    const collection = this.getOrCreateCollection('token')
    const url = constructUrl(collection.objectType) + id + '/revoke'
    return axios.post(url, {}, this.axiosConfig).then((response) => {
      return collection.refresh()
    })
  }

  public changePassword(data, profile) {
    return axios.post(
      '/api/v1/profile/' + profile + '/change-password',
      data,
      this.axiosConfig
    )
  }

  public resetPinCounters(profile: Profile) {
    return axios.post(
      '/api/v1/profile/' + profile.id + '/reset-pin-counters',
      {},
      this.axiosConfig
    )
  }

  public requestResetPassword(startReset: PasswordResetRequest) {
    return axios.post(
      '/api/v1/profile/request-password-reset',
      startReset,
      this.axiosConfig
    )
  }

  public resetPassword(resetPw: PasswordReset) {
    return axios.post(
      '/api/v1/profile/' + resetPw.id + '/reset-password',
      resetPw,
      this.axiosConfig
    )
  }

  public requestUpdateEmail(
    profileId: string,
    requestEmailUpdate: RequestEmailUpdate
  ) {
    return axios.post(
      '/api/v1/profile/' + profileId + '/request-email-update',
      requestEmailUpdate,
      this.axiosConfig
    )
  }

  public updateEmail(profileId: string, updateEmail: EmailUpdate) {
    return axios.post(
      '/api/v1/profile/' + profileId + '/update-email',
      updateEmail,
      this.axiosConfig
    )
  }

  public requestActivateProfile(
    activateProfileRequest: ProfileActivationRequest
  ) {
    return axios.post(
      '/api/v1/profile/request-activation',
      activateProfileRequest,
      this.axiosConfig
    )
  }

  public requestLicenseRenewal(
    projectId: string,
    validationCode: string,
    subscriptionLength: 1 | 3,
    assignedBy: string,
    odooCustomerId: number
  ): Promise<KnockautLicenseKey> {
    return axios.post(
      `/api/v1/brelag/knockaut-license-key/assign-license-key`,
      {
        project: projectId,
        verification_code: validationCode,
        validity: subscriptionLength,
        assigned_by: assignedBy,
        odoo_customer_id: odooCustomerId,
      },
      simpleCopy(defaultAxiosConfig)
    )
  }

  public tryGetOdooCustomer(
    licenseUsername: string,
    customerEmail?: string
  ): Promise<{ data: OutputOdooCustomerSerializer }> {
    return axios.post(
      '/api/v1/brelag/knockaut-license-key/try-get-odoo-customer-id',
      {
        license_username: licenseUsername,
        customer_email: customerEmail,
      },
      simpleCopy(defaultAxiosConfig)
    )
  }

  public activateProfile(activateProfile: ProfileActivation) {
    return axios.post(
      '/api/v1/profile/' + activateProfile.id + '/activate',
      activateProfile,
      this.axiosConfig
    )
  }

  public refreshData() {
    const promises = []
    this.collections.forEach((collection: Collection, key: string) => {
      promises.push(collection.refresh())
    })
    return Promise.all(promises)
  }

  public annotateObject(object: any, property: string, annotationCb: any) {
    Vue.set(object, property, DataStateDisplay.Loading)
    annotationCb(object, this)
      .then((value) => Vue.set(object, property, value))
      .catch((error) => {
        console.error(
          `Could not annotate object with property ${property}`,
          object,
          error
        )
        Vue.set(object, property, DataStateDisplay.Error)
      })
  }

  public annotateObjectWithRelated(
    object: any,
    relatedObjectType: string,
    relatedObjectProperty: string
  ) {
    // TODO: Would be nice if 'relatedObjectProperty' was a list, if several properties of same object are needed
    const newProperty = `${relatedObjectType}_${relatedObjectProperty}`
    const annotationCb = (object) => {
      if (object[relatedObjectType]) {
        return this.get(relatedObjectType, object[relatedObjectType]).then(
          (response) => response[relatedObjectProperty]
        )
      } else if (object[relatedObjectType] === null) {
        return Promise.resolve(DataStateDisplay.OkNoData)
      } else {
        throw new Error(`${relatedObjectType} does not exist on object.`)
      }
    }
    this.annotateObject(object, newProperty, annotationCb)
  }

  private getDefault(objectType: string) {
    const defaultObject = this.defaultModelsMap[objectType]
    if (!defaultObject) {
      return Promise.reject(`Default object for ${objectType} is not defined`)
    }
    return Promise.resolve(JSON.parse(JSON.stringify(defaultObject)))
  }

  private getOrCreateCollection(objectType: string): Collection {
    let collection = this.collections.get(objectType)
    if (!collection) {
      collection = new Collection(objectType)
      this.collections.set(objectType, collection)
    }
    return collection
  }
}

export const apiClient = new ApiClient()

export interface CollectionStatus {
  fetching: boolean // true when a request is underway
  ready: boolean // true when first request is finished
  readyPromise?: Promise<boolean> // Promise that resolves when first request finishes
}

export interface CollectionInfo {
  size?: number
}

export interface CollectionFilter {
  order_by?: string
  [K: string]: any
}

export interface CollectionPagination {
  page?: number
  pageSize?: number
}

export interface CollectionOrdering {
  field: string
  order?: 'asc' | 'desc' | 'dsc'
}

export interface ObjectList<T> {
  size?: number
  results: T[]
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CellEditType } from '@/models/core/base'

@Component({})
export default class EditableCell extends Vue {
  @Prop({ required: true })
  value: any
  @Prop({
    default: () => {
      return { type: CellEditType.STRING }
    },
  })
  editProperties: {
    type: CellEditType
    min?: number
    max?: number
    step?: number
    digits?: number
    maxlength?: number
    width?: number
  }

  cellValue = null
  originalCellValue: string = ''
  editMode: boolean = false
  CellEditType = CellEditType
  $refs: {
    stringInput
    numberInput
  }

  mounted() {
    if (this.value !== undefined && this.value !== null) {
      this.cellValue = this.value
      this.originalCellValue = JSON.parse(JSON.stringify(this.value))
    }
  }

  @Watch('value')
  cellValueChanged() {
    this.cellValue = this.value
    this.originalCellValue = JSON.parse(JSON.stringify(this.value))
    this.editMode = false
  }

  editCell() {
    this.editMode = true
    if (this.editProperties.type === CellEditType.NUMBER) {
      setTimeout(() => {
        this.$refs.numberInput.focus()
      }, 150)
    } else if (this.editProperties.type === CellEditType.STRING) {
      setTimeout(() => {
        this.$refs.stringInput.focus()
      }, 150)
    }
  }

  discardCell() {
    if (this.editProperties.type === CellEditType.DATE) {
      this.cellValue = new Date(
        JSON.parse(JSON.stringify(this.originalCellValue))
      )
    } else {
      this.cellValue = JSON.parse(JSON.stringify(this.originalCellValue))
    }
    this.editMode = false
  }

  saveCell() {
    this.editMode = false
    this.originalCellValue = JSON.parse(JSON.stringify(this.cellValue))
    this.$emit('save', this.cellValue)
  }

  formatNumber(n: string): string {
    return parseFloat(n).toFixed(this.editProperties.digits || 0)
  }
}

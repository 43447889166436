
import { CollectionSubscriber } from '@/api/ApiClient'
import BaseForm from '@/components/common/BaseForm.vue'
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard'
import { ROLE_DEFAULT } from '@/models/core/defaults'
import {
  PERMISSIONS_DEFAULT,
  ROLE_DEPENDENCY_TREE,
} from '@/models/core/defaults'
import DependencyTree from './DependencyTree.vue'
import { Role } from '@/models/core/models'
import Vue from 'vue'
import Component from 'vue-class-component'
import { copyTextToClipboard } from '@/util/clipboard'

@Component({
  name: 'role-form',
  components: {
    'base-form': BaseForm,
    'dependency-tree': DependencyTree,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      role: JSON.parse(JSON.stringify(ROLE_DEFAULT)),
      depTreeItems: JSON.parse(JSON.stringify(ROLE_DEPENDENCY_TREE)),
      showUnhandled: false,
      additionalPermissions: {},
    }
  },
  mixins: [BeforeLeaveGuard],
})
export default class RoleForm extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  role: Role
  loading: boolean = false
  additionalPermissions
  importText = ''
  errorMessage = ''
  propagate = true

  mounted() {
    this.loading = true
    this.$api.get('role', this.$props.id).then((response) => {
      this.role = this.orderAndFilterPermissions(response)
      this.loading = false
    })
  }

  get roleTransform() {
    if (this.$props.id === '0') {
      this.role.organisation = this.$store.getters['global/organisation'].id
    }

    // apply the additional permissions we dont know about back
    for (const objType in this.additionalPermissions) {
      for (const permissionName in this.additionalPermissions[objType]) {
        this.role.permissions[objType][permissionName] =
          this.additionalPermissions[objType][permissionName]
      }
    }
    return this.role
  }

  orderAndFilterPermissions(role) {
    const formattedPermissions = {}
    let out: Role
    for (const objType in role.permissions) {
      if (role.permissions.hasOwnProperty(objType)) {
        formattedPermissions[objType] = {}
        for (const permissionName in PERMISSIONS_DEFAULT[objType]) {
          if (role.permissions[objType].hasOwnProperty(permissionName)) {
            formattedPermissions[objType][permissionName] =
              role.permissions[objType][permissionName]
          }
        }

        // For permissions that are not registered with the frontend yet, we just add them to the end
        for (const permissionName in role.permissions[objType]) {
          if (this.additionalPermissions[objType] === undefined) {
            Vue.set(this.additionalPermissions, objType, {})
          }
          if (PERMISSIONS_DEFAULT[objType] === undefined) {
            Vue.set(
              this.additionalPermissions[objType],
              permissionName,
              role.permissions[objType][permissionName]
            )
          } else if (
            !PERMISSIONS_DEFAULT[objType].hasOwnProperty(permissionName)
          ) {
            Vue.set(
              this.additionalPermissions[objType],
              permissionName,
              role.permissions[objType][permissionName]
            )
          }
        }
      }
    }
    out = role
    out.permissions = formattedPermissions
    return out
  }

  get unhandledPermissions() {
    let out = {}
    Object.keys(this.additionalPermissions).forEach((objectType) => {
      Object.keys(this.additionalPermissions[objectType]).forEach((perm) => {
        if (perm !== 'owner') {
          if (out[objectType] === undefined) {
            out[objectType] = {}
          }
          out[objectType][perm] = this.additionalPermissions[objectType][perm]
        }
      })
    })
    return out
  }

  updateRole() {}

  exportRole(onlyTrue = false) {
    const roleCopy: Role = JSON.parse(JSON.stringify(this.role))
    if (onlyTrue) {
      for (let obj in roleCopy.permissions) {
        for (let permission in roleCopy.permissions[obj]) {
          if (!roleCopy.permissions[obj][permission]) {
            // only keep true permissions
            delete roleCopy.permissions[obj][permission]
          }
        }
      }
      for (let obj in roleCopy.permissions) {
        if (Object.keys(roleCopy.permissions[obj]).length === 0) {
          // only keep objects that have any true permission
          delete roleCopy.permissions[obj]
        }
      }
    }
    // empty unnecessary fields
    roleCopy.id = ''
    roleCopy.organisation = ''
    roleCopy.create_time = ''
    roleCopy.creator = ''
    roleCopy._permissions = {}
    // sort
    const orderedPermissions = {}
    Object.keys(roleCopy.permissions)
      .sort()
      .forEach((key) => {
        orderedPermissions[key] = roleCopy.permissions[key]
      })
    roleCopy.permissions = orderedPermissions
    for (let obj in roleCopy.permissions) {
      const ordered = {}
      Object.keys(roleCopy.permissions[obj])
        .sort()
        .forEach((key) => {
          ordered[key] = roleCopy.permissions[obj][key]
        })
      roleCopy.permissions[obj] = ordered
    }
    // copy to clipboard
    copyTextToClipboard(JSON.stringify(roleCopy, null, 2))
    this.$buefy.toast.open({
      message: 'Copied role to clipboard',
      type: 'is-success',
    })
  }

  validateJSON() {
    try {
      const role: Role = JSON.parse(this.importText)
      this.errorMessage = ''
    } catch (err) {
      this.errorMessage = this.$errorHandler.errorToString(err)
    }
  }

  importRole() {
    // do not propagate
    this.propagate = false
    try {
      const role: Role = JSON.parse(this.importText)
      for (let obj in role.permissions) {
        for (let permission in role.permissions[obj]) {
          if (
            this.role.permissions[obj] &&
            this.role.permissions[obj][permission] !== undefined
          ) {
            this.role.permissions[obj][permission] =
              role.permissions[obj][permission]
          }
        }
      }
      this.role = this.orderAndFilterPermissions(this.role)
      this.$buefy.toast.open({
        message: 'Role imported',
        type: 'is-success',
      })
    } catch (err) {
      this.errorMessage = this.$errorHandler.errorToString(err)
    }
  }
}

import Vue from 'vue'
import VueFormly from 'vue-formly'
import VueFormlyBuefy from 'vue-formly-buefy'

Vue.use(VueFormly)
Vue.use(VueFormlyBuefy)

import LabelField from '@/components/common/forms/editor-fields/LabelField.vue'
import RelatedModelField from '@/components/common/forms/editor-fields/RelatedModelField.vue'
import ImageCropField from '@/components/common/forms/editor-fields/ImageCropField.vue'
import MarkdownField from '@/components/common/forms/editor-fields/MarkdownField.vue'
import ColorField from '@/components/common/forms/editor-fields/ColorField.vue'
import RotationField from '@/components/common/forms/editor-fields/RotationField.vue'
import JsonField from '@/components/common/forms/editor-fields/JsonField.vue'
import DateField from '@/components/common/forms/editor-fields/DateField.vue'
import NumberField from '@/components/common/forms/editor-fields/NumberField.vue'
import OrderingField from '@/components/common/forms/editor-fields/OrderingField.vue'
import CheckboxField from '@/components/common/forms/editor-fields/CheckboxField.vue'

import ProfilesSelectField from '@/apps/admin/components/group/ProfilesSelectField.vue'

import ImageUploadField from '@/apps/brelag/common/components/editor-fields/ImageUploadField.vue'
import EquipmentConfigField from '@/apps/brelag/brelag-admin/components/equipment/EquipmentConfigField.vue'
import BrelagOfferSelectField from '@/apps/brelag/brelag-admin/components/offer/BrelagOfferSelectField.vue'
import MaxFlexField from '@/apps/brelag/mandator-user/components/editor/maxflex/MaxFlexField.vue'
import SwwField from '@/apps/brelag/mandator-user/components/editor/sww/SwwField.vue'
import BrelagNetmaskField from '@/apps/brelag/mandator-user/components/editor/egate/BrelagNetmaskField.vue'
import EgateField from '@/apps/brelag/mandator-user/components/editor/egate/EgateField.vue'

import { FormFieldType } from '@/components/common/forms/formBuilderHelper'

export function initForms() {
  VueFormly.addType(FormFieldType.LABEL, LabelField)
  // VueFormly.addType(FormFieldType., PictureField)
  VueFormly.addType(FormFieldType.RELATED_MODEL_SELECT, RelatedModelField)
  VueFormly.addType(FormFieldType.NUMBER_FIELD, NumberField)
  VueFormly.addType(FormFieldType.ORDERING_FIELD, OrderingField)
  VueFormly.addType(FormFieldType.PROFILES_SELECT, ProfilesSelectField)
  VueFormly.addType(FormFieldType.MARKDOWN_FIELD, MarkdownField)
  VueFormly.addType(FormFieldType.COLOR_FIELD, ColorField)
  VueFormly.addType(FormFieldType.ROTATION_FIELD, RotationField)
  VueFormly.addType(FormFieldType.JSON_FIELD, JsonField)
  VueFormly.addType(FormFieldType.DATE_FIELD, DateField)
  VueFormly.addType(FormFieldType.CHECKBOX, CheckboxField)
  VueFormly.addType(FormFieldType.IMAGE_CROP, ImageCropField)
  VueFormly.addType(FormFieldType.IMAGE_UPLOAD, ImageUploadField)
  VueFormly.addType(FormFieldType.BRELAG_OFFER_SELECT, BrelagOfferSelectField)
  VueFormly.addType(FormFieldType.EQUIPMENT_CONFIG_EDITOR, EquipmentConfigField)
  VueFormly.addType(FormFieldType.EGATE_FIELD, EgateField)
  VueFormly.addType(FormFieldType.BRELAG_NETMASK_FIELD, BrelagNetmaskField)
  VueFormly.addType(FormFieldType.MAXFLEX_EDITOR, MaxFlexField)
  VueFormly.addType(FormFieldType.SWW_EDITOR, SwwField)
}

export default {
  role: {
    prettyName: 'Rolle | Rollen',
  },
  user: {
    prettyName: 'Benutzer | Benutzer',
  },
  objectauthorization: {
    prettyName: 'Objekt Berechtigung | Objekt Berechtigungen',
    fields: {
      role_name: 'Rolle',
      group_name: 'Gruppe',
      object_type: 'Objekt Typ',
      object_name: 'Objekt Name',
      note: 'Notiz',
    },
  },
  organisation: {
    prettyName: 'Organisation | Organisationen',
    fields: {
      name: 'Name',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      password_policy: {
        version: 'Version',
        min_length: 'Min Length',
        alpha_upper: 'Alpha Upper',
        alpha_lower: 'Alpha Lower',
        numeric: 'Numeric',
        special_char: 'Special Char',
      },
      meta: {
        email: 'E-Mail',
        phone: 'Telefon',
        country: 'Land',
        zip: 'PLZ',
        city: 'Ort',
        address: 'Adresse',
      },
    },
    placeholders: {
      name: 'Name',
      avatar: 'Avatar',
      password_policy: {
        version: 'Version',
        min_length: 'Min Length',
        alpha_upper: 'Alpha Upper',
        alpha_lower: 'Alpha Lower',
        numeric: 'Numeric',
        special_char: 'Special Char',
      },
      meta: {
        email: 'E-Mail',
        phone: 'Telefon',
        country: '',
        zip: '',
        city: '',
        address: '',
      },
    },
  },
  group: {
    prettyName: 'Gruppe | Gruppen',
    fields: {
      name: 'Name',
      description: 'Beschreibung',
      organisation: 'Organisation',
      profiles: 'Profile',
    },
    placeholders: {
      name: 'Benutzer Gruppe A',
      description: '',
      profiles: '',
    },
  },
  profile: {
    prettyName: 'Benutzer | Benutzer',
    fields: {
      username: 'Benutzername',
      email: 'E-Mail',
      first_name: 'Vorname',
      last_name: 'Nachname',
      organisation: 'Organisation',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      require_vpn_2fa: 'Require VPN 2FA',
      kind: 'Typ',
      is_activated: 'Aktiviert',
      is_active: 'Aktiv',
      is_activated_and_active: 'Aktiviert',
      meta: {
        phone: 'Telefon',
        gender: 'Anrede',
      },
    },
    placeholders: {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      organisation: '',
      avatar: '',
      avatar_img: '',
      require_vpn_2fa: '',
      kind: '',
      is_activated: '',
      is_active: '',
      meta: {
        phone: '',
        gender: '',
      },
    },
  },
  groupmembership: {
    prettyName: 'Gruppenmitgliedschaft | Gruppenmitgliedschaften',
    fields: {
      group_name: 'Gruppe',
      profile_username: 'Benutzername',
      profile_email: 'E-Mail',
    },
    placeholders: {
      group: '',
      profile: '',
      require_vpn_2fa: 'Require VPN 2FA',
      kind: 'Kind',
      meta: {
        phone: 'Phone',
      },
    },
  },
  album: {
    prettyName: 'Album | Alben',
    fields: {
      name: 'Name',
      description: 'Beschreibung',
      organisation: 'Organisation',
      public: 'Öffentlich',
    },
    placeholders: {
      name: '',
      description: '',
      organisation: '',
      public: '',
    },
  },
}

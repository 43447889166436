
import { Vue, Component } from 'vue-property-decorator'
import { ClientApp } from '@/models/client/models'
import BaseListV2 from '@/components/common/BaseListV2.vue'

@Component({
  components: {
    BaseListV2,
  },
})
export default class ClientAppList extends Vue {
  ClientApp = ClientApp

  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    }
  }
}

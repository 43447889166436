
import { Vue, Component, Prop } from 'vue-property-decorator'

import { KnockautProject } from '../../common/models/knockaut'

@Component({
  components: {},
})
export default class KnockautDetailModal extends Vue {
  @Prop({ default: false })
  modalActive: boolean
  @Prop({ required: true })
  knockautProject: KnockautProject

  save() {
    this.cancel()
  }

  cancel() {
    this.$emit('cancel')
  }
}

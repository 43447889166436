
import { CollectionSubscriber } from '@/api/ApiClient'
import BaseList from '@/components/common/BaseList.vue'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'role-list',
  components: {
    'base-list': BaseList,
  },
  data() {
    return {
      roles: this.$store.state.global.context.roles,
    }
  },
})
export default class RoleList extends Vue {
  roles = CollectionSubscriber
  columns = [
    {
      label: 'Name',
      fieldName: 'name',
      sortable: true,
    },
    {
      label: 'Description',
      fieldName: 'description',
    },
  ]

  mounted() {
    this.roles = this.$store.state.global.context.roles
  }
}

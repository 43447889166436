
import { Component, Vue, Watch } from 'vue-property-decorator'

import { CollectionSubscriber } from '@/api/ApiClient'
import DashboardCollectionTile from '@/apps/brelag/common/components/DashboardCollectionTile.vue'
import { brelagUserRouteName } from '@/apps/brelag/mandator-user/app'
import { Wallet } from '@/apps/brelag/common/models/wallet'

@Component({
  name: 'brelag-user-dashboard',
  methods: {
    brelagUserRouteName,
  },
  components: {
    DashboardCollectionTile,
  },
  data() {
    return {
      customers: this.$api.subscribeListInComponent(
        this,
        'customer',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
      projects: this.$api.subscribeListInComponent(
        this,
        'project',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
    }
  },
})
export default class BrelagUserDashboard extends Vue {
  customers: CollectionSubscriber
  projects: CollectionSubscriber
  loading: boolean = false
  numOrganisationCoins: string = null
  numUserCoins: string = null

  async mounted() {
    this.loading = true
    try {
      await this.refreshWallets()
    } catch (err) {
      this.handleError(err)
    }
    this.loading = false
  }

  async refreshWallets() {
    try {
      const wallet: Wallet = await this.$apiv2.find<Wallet>(Wallet, {
        organisation: this.$store.getters['global/organisation'].id,
        owner: '',
      })
      this.numOrganisationCoins = parseFloat(wallet.balance).toFixed()
    } catch (err) {
      // Wallet does not exist yet -> balance 0
      this.numOrganisationCoins = '0'
    }
    try {
      const wallet: Wallet = await this.$apiv2.find<Wallet>(Wallet, {
        organisation: this.$store.getters['global/organisation'].id,
        owner: this.$store.getters['global/profileId'],
      })
      this.numUserCoins = parseFloat(wallet.balance).toFixed()
    } catch (err) {
      // Wallet does not exist yet -> balance 0
      this.numUserCoins = '0'
    }
  }

  handleError(error) {
    this.$buefy.toast.open({
      message: this.$errorHandler.errorToString(error),
      type: 'is-danger',
      duration: 5000,
    })
  }

  @Watch('$route.query')
  organisationChanged() {
    // TODO: This is a temporary solution before ApiClient supports watching for a variable
    this.customers.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
    this.projects.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
    this.refreshWallets()
  }
}

import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import { Location, Route, RouteConfig } from 'vue-router'

import MainMenu from '@/apps/brelag/brelag-developer/components/MainMenu.vue'

import BrelagDeveloperDashboard from '@/apps/brelag/brelag-developer/components/BrelagDeveloperDashboard.vue'
import { updateStateForRoute } from '@/apps/routingUtils'
import { clientAppRegistryGet } from '@/apps/clientAppRegistry'
import {
  brelagDeveloperRouteName,
  VIEW_ID,
} from '@/apps/brelag/brelag-developer/app'

function beforeEnterBrelagUserApp(to: Route): Promise<void | Location> {
  const objectList = ['equipment_group']
  return updateStateForRoute(to, objectList)
}

let route: RouteConfig = { path: '' }

route = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: brelagDeveloperRouteName('dashboard') },
  components: {
    default: RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterBrelagUserApp,
  },
  children: [
    {
      path: 'dashboard',
      name: brelagDeveloperRouteName('dashboard'),
      component: BrelagDeveloperDashboard,
      meta: { requiresAuth: true },
    },
    {
      path: 'equipment-group',
      name: brelagDeveloperRouteName('equipment-group-root'),
      redirect: { name: brelagDeveloperRouteName('equipmentgroup-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagDeveloperRouteName('equipmentgroup-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "equipment-group-list" */ '../brelag-admin/components/equipment/EquipmentGroupList.vue'
            ),
        },
        {
          path: 'create',
          name: brelagDeveloperRouteName('equipment_group-create'),
          component: () =>
            import(
              /* webpackChunkName: "equipment-group-form" */ '../brelag-admin/components/equipment/EquipmentGroupForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagDeveloperRouteName('equipment_group-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "equipment-group-form" */ '../brelag-admin/components/equipment/EquipmentGroupForm.vue'
            ),
        },
      ],
    },
    {
      path: 'equipment',
      name: brelagDeveloperRouteName('equipment-root'),
      redirect: { name: brelagDeveloperRouteName('equipment-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagDeveloperRouteName('equipment-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "equipment-list" */ '../brelag-admin/components/equipment/EquipmentList.vue'
            ),
          meta: {
            requiresList: {
              equipment_group: true,
            },
            hasQuery: {
              equipment_group: true,
            },
          },
        },
        {
          path: 'create',
          name: brelagDeveloperRouteName('equipment-create'),
          component: () =>
            import(
              /* webpackChunkName: "equipment-form" */ '../brelag-admin/components/equipment/EquipmentForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagDeveloperRouteName('equipment-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "equipment-form" */ '../brelag-admin/components/equipment/EquipmentForm.vue'
            ),
        },
        {
          path: 'instruction-step/create',
          name: brelagDeveloperRouteName('instructionstep-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "instruction-step-form" */ '../brelag-admin/components/equipment/InstructionStepForm.vue'
            ),
        },
        {
          path: 'instruction-step/:id',
          name: brelagDeveloperRouteName('instructionstep-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "instruction-step-form" */ '../brelag-admin/components/equipment/InstructionStepForm.vue'
            ),
        },
        {
          path: 'equipment-variant/create',
          name: brelagDeveloperRouteName('equipmentvariant-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "equipment-variant-form" */ '../brelag-admin/components/equipment/EquipmentVariantForm.vue'
            ),
        },
        {
          path: 'equipment-variant/:id',
          name: brelagDeveloperRouteName('equipmentvariant-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "equipment-variant-form" */ '../brelag-admin/components/equipment/EquipmentVariantForm.vue'
            ),
        },
      ],
    },
  ],
}

export default route


import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'

import { Group } from '@/models/core/models'

@Component({
  name: 'group-list',
  components: {
    BaseListV2,
  },
  data() {
    return {
      Group,
    }
  },
})
export default class GroupList extends Vue {
  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
      order_by: 'name',
    }
  }
}

import svgjs from 'svg.js'
import {
  AnnotationCategory,
  AnnotationElement,
  VariantDisplayOption,
} from '@/apps/brelag/mandator-user/models/editor'
import {
  EquipmentCategory,
  DeviceAssignment,
  EquipmentVariant,
} from '@/apps/brelag/common/models/equipment'
import { SenderArrangement } from '@/apps/brelag/mandator-user/project-editor/arrangementUtil'
import { Placement } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditor'
import { getMetaSvgAttribute } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditorUtil'
import {
  drawArrowAnnotation,
  drawTextAnnotation,
  drawCompassAnnotation,
  drawHelpLineAnnotation,
} from './floorPlanDrawUtil'
import { FloorPlanSettings } from '../../common/models/floor'
import { ProjectEditor } from './projectEditor'
import { simpleCopy } from '@/util/util'

const DEFAULT_FONT_TYPE = 'Helvetica'
const DEFAULT_FONT_COLOR = '#424242'
const RECEIVER_COLOR = '#F81A2F'
const SENDER_COLOR = '#1d77b6'
export const DEFAULT_FONT = {
  fill: DEFAULT_FONT_COLOR,
  family: DEFAULT_FONT_TYPE,
  size: 10,
  color: '#FFFFFF',
}

export enum ConnectionPoint {
  DEFAULT = undefined,
  BOTTOM = 1,
  RIGHT = 2,
  TOP = 3,
  LEFT = 4,
}

/**
 * Draw an Annotation
 * @param editorContainer
 * @param annotation
 * @param placements
 */
export function drawAnnotation(
  editorContainer,
  annotation: AnnotationElement,
  placements: Placement[]
): any[] {
  const svgObjects = []
  if (placements.length < 1) {
    throw new Error('Need one placement')
  }
  if (annotation.category === AnnotationCategory.TEXT) {
    drawTextAnnotation(annotation, placements[0], editorContainer, svgObjects)
  } else if (annotation.category === AnnotationCategory.COMPASS) {
    drawCompassAnnotation(
      annotation,
      placements[0],
      editorContainer,
      svgObjects
    )
  } else if (annotation.category === AnnotationCategory.DEVICE_ARROW) {
    drawArrowAnnotation(annotation, placements[0], editorContainer, svgObjects)
  } else if (annotation.category === AnnotationCategory.HELP_LINE) {
    drawHelpLineAnnotation(
      annotation,
      placements[0],
      editorContainer,
      svgObjects
    )
  } else {
    console.warn('Unknown description category.')
  }

  return svgObjects
}

function formatArrangement(
  add,
  channel: string,
  nonRepeating: string,
  repeating: string,
  floorPlanSettings: FloorPlanSettings | null,
  textColor: string
) {
  let hideRepeatingLinks = false
  if (floorPlanSettings) {
    hideRepeatingLinks = floorPlanSettings.hideRepeatingLinks
  }
  const arrangementFont = {
    ...DEFAULT_FONT,
    size: 8,
    fill: textColor,
  }
  let splitNonRepeating = []
  if (nonRepeating) {
    splitNonRepeating = nonRepeating.split(',').map((a) => a.trim())
  }
  let splitRepeating = []
  if (repeating) {
    splitRepeating = repeating.split(',').map((a) => a.trim())
  }

  if (splitNonRepeating.length > 0) {
    let firstLine = true
    for (let i = 0; i < splitNonRepeating.length; i++) {
      let text = splitNonRepeating[i]
      if (text.lastIndexOf('-') > 0) {
        // This is a range (e.g. 00001 - 0002) -> One per line
      } else {
        i++
        if (splitNonRepeating[i]) {
          text += ', ' + splitNonRepeating[i]
        }
      }
      if (firstLine) {
        add.tspan(`${channel}: ${text}`).font(arrangementFont).newLine()
      } else {
        add
          .tspan(`${text}`)
          .dx(9) // indent because no channel number
          .font(arrangementFont)
          .newLine()
      }
      firstLine = false
    }
  }
  if (!hideRepeatingLinks && splitRepeating.length > 0) {
    let firstLine = true
    for (let i = 0; i < splitRepeating.length; i++) {
      let text = 'R: ' + splitRepeating[i]
      if (text.lastIndexOf('-') > 0) {
        // This is a range (e.g. 00001 - 0002) -> One per line
      } else {
        i++
        if (splitRepeating[i]) {
          text += ', ' + splitRepeating[i]
        }
      }
      if (firstLine) {
        add.tspan(`${channel}: ${text}`).font(arrangementFont).newLine()
      } else {
        add
          .tspan(`${text}`)
          .dx(9) // indent because no channel number
          .font(arrangementFont)
          .newLine()
      }
      firstLine = false
    }
  }
}

/**
 * Virtual Senders are handled in a special way:
 * 1) They cant be placed on floorplan
 * 2) If they are connected to an FE Ultra, we show the SenderArrangement of the Virtual sender with the FE Ultra
 * @param device
 * @param senderArrangements
 */
function findVirtualSenderArrangement(
  device: DeviceAssignment,
  senderArrangements: SenderArrangement[]
): SenderArrangement {
  if (ProjectEditor.instance.equipmentHandler.isUltraReceiver(device)) {
    // 1) Get links of receiver
    const links = ProjectEditor.instance.deviceHandler.findLinks(
      undefined,
      device.id
    )
    // 2) If there is a virtual sender connected -> Get links of that sender
    for (const link of links) {
      // Only if "Als Senderadresse verwenden" is active
      if (link.options.keyTxAddress) {
        const sender =
          ProjectEditor.instance.deviceHandler.getDeviceAssignmentById(
            link.sender
          )
        if (ProjectEditor.instance.equipmentHandler.isVirtualSender(sender)) {
          // Get sender arrangement of virtual sender
          const arrangement: SenderArrangement = simpleCopy(
            senderArrangements.find((el) => el.id === sender.id)
          )
          // The virtual sender uses channel 8, but we want to display it as channel 1 to users
          arrangement.channel_1 = simpleCopy(arrangement.channel_8)
          arrangement.channel_8 = null
          return arrangement
        }
      }
    }
  }
  return null
}

/**
 * Draw a device
 * @param editorContainer
 * @param device
 * @param senderArrangements
 * @param devicePlacement
 */
export function drawDevice(
  editorContainer,
  device: DeviceAssignment,
  variant: EquipmentVariant,
  senderArrangements: SenderArrangement[],
  floorPlanSettings: FloorPlanSettings | null,
  devicePlacement?: Placement
) {
  // Create the description and device group
  const descGroup = editorContainer.svg.group()
  const deviceGroup = editorContainer.svg.group()
  const equipmentCategory = device.category

  const color = getDeviceColor(device)
  let bgColor = color

  if (
    device.meta &&
    device.meta.svgAttributes &&
    device.meta.svgAttributes.bgColor
  ) {
    bgColor = device.meta.svgAttributes.bgColor
  }

  const showComment = getMetaSvgAttribute(device, 'textDisplay') as boolean

  let textColor = getMetaSvgAttribute(device, 'textColor') as string
  if (!textColor) {
    textColor = '#000000'
  }

  // Draw device number
  const deviceNumber = descGroup
    .text((add) => {
      add
        .tspan(device.device_number)
        .font({
          ...DEFAULT_FONT,
          fill: textColor,
        })
        .newLine()

      if (device.meta && device.meta.svgAttributes) {
        // Either draw full variant name, short form or none
        const varDesc = device.meta.svgAttributes.variantDisplay
        if (varDesc) {
          if (varDesc === VariantDisplayOption.FULL) {
            add
              .tspan(DeviceAssignment.prettyDeviceVariantName(device))
              .font({
                ...DEFAULT_FONT,
                fill: textColor,
              })
              .newLine()
          } else if (varDesc === VariantDisplayOption.SHORT) {
            add
              .tspan(variant.short_form)
              .font({
                ...DEFAULT_FONT,
                fill: textColor,
              })
              .newLine()
          }
        }

        // Draw device comment
        const comments = getMetaSvgAttribute(device, 'textLines') as string[]
        if (comments && showComment) {
          for (const comment of comments) {
            add
              .tspan(comment)
              .font({
                ...DEFAULT_FONT,
                fill: textColor,
              })
              .newLine()
          }
        }
      }

      let linksDisplay = getMetaSvgAttribute(device, 'linksDisplay')
      let arrangement = senderArrangements.find((send) => send.id === device.id)

      if (
        showComment &&
        ProjectEditor.instance.equipmentHandler.isUltraReceiver(device)
      ) {
        const virtualArrangement = findVirtualSenderArrangement(
          device,
          senderArrangements
        )
        if (virtualArrangement) {
          arrangement = virtualArrangement
          // Show links only if comments enabled on receiver
          linksDisplay = showComment
        }
      }

      // if linksDisplay is not set, show links (=default behavior)
      // have to set to false to not show
      if (arrangement && linksDisplay !== false) {
        // Add channel info
        if (arrangement.channel_1 && arrangement.channel_1.all) {
          formatArrangement(
            add,
            '1',
            arrangement.channel_1.nonRepeating,
            arrangement.channel_1.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_2 && arrangement.channel_2.all) {
          formatArrangement(
            add,
            '2',
            arrangement.channel_2.nonRepeating,
            arrangement.channel_2.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_3 && arrangement.channel_3.all) {
          formatArrangement(
            add,
            '3',
            arrangement.channel_3.nonRepeating,
            arrangement.channel_3.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_4 && arrangement.channel_4.all) {
          formatArrangement(
            add,
            '4',
            arrangement.channel_4.nonRepeating,
            arrangement.channel_4.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_5 && arrangement.channel_5.all) {
          formatArrangement(
            add,
            '5',
            arrangement.channel_5.nonRepeating,
            arrangement.channel_5.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_6 && arrangement.channel_6.all) {
          formatArrangement(
            add,
            '6',
            arrangement.channel_6.nonRepeating,
            arrangement.channel_6.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_7 && arrangement.channel_7.all) {
          formatArrangement(
            add,
            '7',
            arrangement.channel_7.nonRepeating,
            arrangement.channel_7.repeating,
            floorPlanSettings,
            textColor
          )
        }
        if (arrangement.channel_8 && arrangement.channel_8.all) {
          formatArrangement(
            add,
            '8',
            arrangement.channel_8.nonRepeating,
            arrangement.channel_8.repeating,
            floorPlanSettings,
            textColor
          )
        }
      }
    })
    .leading(0.8)

  const rotate = getMetaSvgAttribute(device, 'rotate') as boolean

  let bgRect
  if (equipmentCategory === EquipmentCategory.SENDER) {
    bgRect = drawSender(descGroup, deviceGroup, color, bgColor)
  } else if (equipmentCategory === EquipmentCategory.RECEIVER) {
    bgRect = drawReceiver(descGroup, deviceGroup, color, bgColor)
  } else {
    throw new Error(`Unknown equipment type ${equipmentCategory}.`)
  }

  // border
  const borderColor = getMetaSvgAttribute(device, 'borderColor') as string
  let border = null
  if (borderColor) {
    const padding = 0
    border = addBorderColorToContainer(
      descGroup,
      descGroup,
      borderColor,
      padding
    )
  }

  // Place device
  if (device.meta && device.meta.svgObjectPositions) {
    deviceNumber.cx(device.meta.svgObjectPositions[0].cx)
    deviceNumber.cy(device.meta.svgObjectPositions[0].cy)
    bgRect.cx(device.meta.svgObjectPositions[0].cx)
    bgRect.cy(device.meta.svgObjectPositions[0].cy)
    if (border) {
      border.cx(device.meta.svgObjectPositions[0].cx)
      border.cy(device.meta.svgObjectPositions[0].cy)
    }
    deviceGroup.cx(device.meta.svgObjectPositions[1].cx)
    deviceGroup.cy(device.meta.svgObjectPositions[1].cy)
  } else if (devicePlacement) {
    let numberDistance = 200
    // for receivers number is closer to device per default
    if (equipmentCategory === EquipmentCategory.RECEIVER) {
      numberDistance = 70
    }

    const y =
      devicePlacement.cy > 450
        ? devicePlacement.cy + numberDistance
        : devicePlacement.cy - numberDistance
    deviceNumber.cx(devicePlacement.cx)
    deviceNumber.cy(y)
    bgRect.cx(devicePlacement.cx)
    bgRect.cy(y)
    if (border) {
      border.cx(devicePlacement.cx)
      border.cy(y)
    }
    deviceGroup.cx(devicePlacement.cx)
    deviceGroup.cy(devicePlacement.cy)
  }

  if (rotate) {
    // Set transformation origin to center of element
    const bbox = deviceNumber.node.getBBox()
    const tOriginX = bbox.x + bbox.width / 2
    const tOriginY = bbox.y + bbox.height / 2
    deviceNumber.style('transform: rotate(-90deg)')
    deviceNumber.style(`transform-origin: ${tOriginX}px ${tOriginY}px`)
    bgRect.style('transform: rotate(-90deg)')
    bgRect.style(`transform-origin: ${tOriginX}px ${tOriginY}px`)
    if (border) {
      border.style('transform: rotate(-90deg)')
      border.style(`transform-origin: ${tOriginX}px ${tOriginY}px`)
    }
  }

  /*
  2 | 1
  -----
  3 | 4
  used for senders
  */
  const getQuadrant = (
    x: number,
    y: number,
    centerX: number,
    centerY: number
  ) => {
    if (x >= centerX) {
      return y >= centerY ? 1 : 4
    } else {
      return y >= centerY ? 2 : 3
    }
  }

  /*
    1
  2   4
    3
  used for receivers
  */
  const getSector = (
    x: number,
    y: number,
    centerX: number,
    centerY: number
  ) => {
    const x2 = x - centerX
    const y2 = y - centerY
    if (y2 >= x2) {
      return x2 >= -y2 ? 3 : 2
    } else {
      return x2 >= -y2 ? 4 : 1
    }
  }

  // Draw a connecting line between the deviceNumber and deviceGroup
  const xMargin = 3
  const yMargin = 3
  let connectionX = deviceNumber.cx()
  let connectionY
  if (equipmentCategory === EquipmentCategory.RECEIVER) {
    // for receivers, the connection line should go to the middle of edge of the box
    const bbox = deviceNumber.node.getBBox()
    let sector = getSector(
      deviceNumber.cx(),
      deviceNumber.cy(),
      deviceGroup.cx(),
      deviceGroup.cy()
    )
    let height = bbox.height
    let width = bbox.width
    if (rotate) {
      height = bbox.width
      width = bbox.height
    }

    // overwrite connection point if it is set in attributes
    const connectionPoint = getMetaSvgAttribute(
      device,
      'connectionPoint'
    ) as ConnectionPoint
    if (connectionPoint) {
      sector = connectionPoint
    }

    if (sector === 1) {
      // connecting point: bottom
      connectionX = deviceNumber.cx()
      connectionY = deviceNumber.cy() + (height / 2 + yMargin)
    } else if (sector === 2) {
      // connecting point: right
      connectionX = deviceNumber.cx() + (width / 2 + xMargin)
      connectionY = deviceNumber.cy()
    } else if (sector === 3) {
      // connecting point: top
      connectionX = deviceNumber.cx()
      connectionY = deviceNumber.cy() - (height / 2 + yMargin)
    } else {
      // connecting point: left
      connectionX = deviceNumber.cx() - (width / 2 + xMargin)
      connectionY = deviceNumber.cy()
    }
  } else {
    // for senders, the connection line should go to the corner of the box
    const bbox = deviceNumber.node.getBBox()
    const quadrant = getQuadrant(
      deviceNumber.cx(),
      deviceNumber.cy(),
      deviceGroup.cx(),
      deviceGroup.cy()
    )
    let height = bbox.height
    let width = bbox.width
    if (rotate) {
      height = bbox.width
      width = bbox.height
    }
    if (quadrant === 1) {
      connectionX = deviceNumber.cx() - (width / 2 + xMargin)
      connectionY = deviceNumber.cy() - (height / 2 + yMargin)
    } else if (quadrant === 2) {
      connectionX = deviceNumber.cx() + (width / 2 + xMargin)
      connectionY = deviceNumber.cy() - (height / 2 + yMargin)
    } else if (quadrant === 3) {
      connectionX = deviceNumber.cx() + (width / 2 + xMargin)
      connectionY = deviceNumber.cy() + (height / 2 + yMargin)
    } else {
      connectionX = deviceNumber.cx() - (width / 2 + xMargin)
      connectionY = deviceNumber.cy() + (height / 2 + yMargin)
    }
  }

  // add the line
  const connection = editorContainer.svg
    .line(deviceGroup.cx(), deviceGroup.cy(), connectionX, connectionY)
    .attr({ stroke: color, 'stroke-width': 1 })

  return {
    descGroup,
    deviceGroup,
    connection,
    color,
    bgColor,
  }
}

function drawReceiver(descGroup, deviceGroup, color: string, bgColor: string) {
  // Add circle as device representation
  deviceGroup.circle(7).attr({
    fill: color,
  })

  return addBackgroundColorToContainer(descGroup, descGroup, bgColor)
}

function drawSender(descGroup, deviceGroup, color: string, bgColor: string) {
  // Add rectangle as device representation
  deviceGroup.rect(12, 12).attr({
    fill: color,
  })

  return addBackgroundColorToContainer(descGroup, descGroup, bgColor)
}

function getDeviceColor(device: DeviceAssignment): string {
  if (
    device.meta &&
    device.meta.svgAttributes &&
    device.meta.svgAttributes.color
  ) {
    return device.meta.svgAttributes.color
  } else {
    if (device.category === EquipmentCategory.SENDER) {
      return SENDER_COLOR
    } else {
      return RECEIVER_COLOR
    }
  }
}

/**
 * Adds a rectangle of a specific color to the background of a container
 * @param node
 * @param container
 * @param color
 */
export function addBackgroundColorToContainer(
  node: svgjs.Container,
  container: svgjs.Container,
  color: string = 'white'
) {
  const padding = 3
  const bbox = container.bbox()
  return node
    .rect()
    .attr('x', bbox.x - padding)
    .attr('y', bbox.y - padding)
    .attr('width', bbox.width + padding * 2)
    .attr('height', bbox.height + padding * 2)
    .style('fill', color)
    .back()
}

/**
 * Adds a rectangle border of a specific color to a container
 * @param node
 * @param container
 * @param color
 */
export function addBorderColorToContainer(
  node: svgjs.Container,
  container: svgjs.Container,
  color: string = 'black',
  padding: number = 3
) {
  const bbox = container.bbox()
  return node
    .rect()
    .attr('x', bbox.x - padding)
    .attr('y', bbox.y - padding)
    .attr('width', bbox.width + padding * 2)
    .attr('height', bbox.height + padding * 2)
    .style('stroke', color)
    .style('fill-opacity', 0)
    .back()
}


import Vue from 'vue'
import Component from 'vue-class-component'
import * as controls from 'vue-formly-buefy/dist/vue-formly-buefy-controls.min.js'

import { FormBuilderHelper } from './formBuilderHelper'

@Component({
  name: 'form-builder',
  components: {},
  props: {
    config: {
      required: true,
    },
    form: {
      required: true,
    },
  },
})
export default class FormBuilder extends Vue {
  get fields() {
    return this.formHelper.config.fields
  }

  get model(): any {
    return this.formHelper.model
  }

  get formHelper() {
    return new FormBuilderHelper(this.$props.config, this.$i18n)
  }

  get formConfig() {
    return this.formHelper.config
  }
}

import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import { Location, Route, RouteConfig } from 'vue-router'

import MainMenu from '@/apps/brelag/brelag-supporter/components/MainMenu.vue'

import BrelagSupporterDashboard from '@/apps/brelag/brelag-supporter/components/BrelagSupporterDashboard.vue'
import SupporterKnockautDashboard from '@/apps/brelag/brelag-supporter/views/SupporterKnockautDashboard.vue'
import KnockautXProgrammers from '@/apps/brelag/brelag-supporter/knockautx-programmers/KnockautXProgrammers.vue'
import { updateStateForRoute } from '@/apps/routingUtils'
import { clientAppRegistryGet } from '@/apps/clientAppRegistry'
import {
  brelagSupporterRouteName,
  VIEW_ID,
} from '@/apps/brelag/brelag-supporter/app'

function beforeEnterBrelagUserApp(to: Route): Promise<void | Location> {
  const objectList = []
  return updateStateForRoute(to, objectList)
}

let route: RouteConfig = { path: '' }

route = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: brelagSupporterRouteName('dashboard') },
  components: {
    default: RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterBrelagUserApp,
  },
  children: [
    {
      path: 'dashboard',
      name: brelagSupporterRouteName('dashboard'),
      component: BrelagSupporterDashboard,
      meta: { requiresAuth: true },
    },
    {
      path: 'mandator',
      name: brelagSupporterRouteName('mandator-root'),
      redirect: { name: brelagSupporterRouteName('mandator-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'list',
          name: brelagSupporterRouteName('mandator-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "supporter-mandator-list" */ '../brelag-supporter/components/MandatorList.vue'
            ),
          meta: {
            requiresList: {
              organisation: true,
            },
          },
        },
        {
          path: 'create',
          name: brelagSupporterRouteName('organisation-create'),
          component: () =>
            import(
              /* webpackChunkName: "mandator-form" */ '../brelag-admin/components/mandator/MandatorForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagSupporterRouteName('organisation-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "mandator-form" */ '../brelag-admin/components/mandator/MandatorForm.vue'
            ),
        },
      ],
    },
    {
      path: 'project',
      name: brelagSupporterRouteName('project-root'),
      redirect: { name: brelagSupporterRouteName('project-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagSupporterRouteName('project-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "project-list" */ '../brelag-supporter/components/SupporterProjectList.vue'
            ),
        },
      ],
    },
    {
      path: 'legacy-project-import',
      name: brelagSupporterRouteName('legacy-project-import'),
      component: () =>
        import(
          '../brelag-admin/components/project-import/LegacyProjectImport.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'knockaut-license-key',
      name: brelagSupporterRouteName('knockaut-license-key-root'),
      redirect: { name: brelagSupporterRouteName('knockaut-license-key-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagSupporterRouteName('knockaut-license-key-list'),
          props: true,
          component: () =>
            import('../brelag-supporter/components/KnockautLicenseKeyList.vue'),
        },
      ],
    },
    {
      path: 'knockaut-dashboard',
      name: brelagSupporterRouteName('knockaut-dashboard'),
      component: SupporterKnockautDashboard,
      meta: {
        requiresAuth: true,
      },
      children: [],
    },
    {
      path: 'knockautx-programmers',
      name: brelagSupporterRouteName('knockautx-programmers'),
      component: KnockautXProgrammers,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'siegenia',
          name: brelagSupporterRouteName('siegenia-programmer'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "supporter-mandator-list" */ '../brelag-supporter/knockautx-programmers/siegenia/Siegenia.vue'
            ),
        },
      ],
    },
  ],
}

export default route

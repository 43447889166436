
import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'

import { Organisation } from '@/models/core/organisation'

@Component({
  name: 'organisation-list',
  components: {
    BaseListV2,
  },
  data() {
    return {
      Organisation,
    }
  },
})
export default class OrganisationList extends Vue {}

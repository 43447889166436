
import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue'
import { getLocationPreservingState } from '@/apps/routingUtils'

import { brelagAdminRouteName } from '@/apps/brelag/brelag-admin/app'

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'main-menu',
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-chart-pie',
        text: 'Dashboard',
        location: getLocationPreservingState(
          brelagAdminRouteName('dashboard'),
          this.$router
        ),
      },
      {
        iconName: 'mdi-account-group',
        text: this.$i18n.tc('apps.brelag.mandator', 2),
        location: { name: brelagAdminRouteName('mandator-root') },
      },
      {
        iconName: 'mdi-account-supervisor',
        text: this.$i18n.tc('apps.brelag.supporter', 2),
        location: { name: brelagAdminRouteName('supporter-root') },
      },
      {
        iconName: 'mdi-account-star',
        text: this.$i18n.tc('apps.brelag.admin', 2),
        location: { name: brelagAdminRouteName('admin-root') },
      },
      {
        iconName: 'mdi-account',
        text: this.$i18n.tc('apps.brelag.user', 2),
        location: { name: brelagAdminRouteName('user-root') },
      },
      {
        iconName: 'mdi-cash',
        text: this.$i18n.tc('apps.brelag.offer', 2),
        location: { name: brelagAdminRouteName('offer-root') },
      },
      {
        iconName: 'mdi-view-module',
        text: this.$i18n.tc('apps.brelag.knockautModule', 2),
        location: { name: brelagAdminRouteName('knockaut-module-root') },
      },
    ]
  }
}

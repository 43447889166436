import { ProjectDump, Project } from '@/apps/brelag/common/models/project'
import { ProjectEditor } from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import { Transaction, TransactionState } from '@/models/core/models'
import { ApiClientV2 } from '@/api/ApiClientV2'
import { DeviceAssignment } from '../../common/models/equipment'
import { Link } from '../../common/models/link'

export enum ProjectHealthErrorType {
  LINKS_MISSING = 'links-missing',
  LINKS_WRONG_PROJECT = 'links-wrong-project',
  LINK_SENDER_DOES_NOT_EXIST = 'link-sender-does-not-exist',
  LINK_RECEIVER_DOES_NOT_EXIST = 'link-receiver-does-not-exist',
  DUPLICATE_ID = 'duplicate-id',
  TRANSACTION_OPEN = 'transaction-open',
}

export const PROJECT_HEALTH_ERROR_TYPE_MAP = {
  [ProjectHealthErrorType.LINKS_MISSING]: 'Links fehlen',
  [ProjectHealthErrorType.LINKS_WRONG_PROJECT]: 'Link falsches Projekt',
  [ProjectHealthErrorType.LINK_SENDER_DOES_NOT_EXIST]:
    'Sender in Link existiert nicht',
  [ProjectHealthErrorType.LINK_RECEIVER_DOES_NOT_EXIST]:
    'Empfänger in Link existiert nicht',
  [ProjectHealthErrorType.DUPLICATE_ID]: 'Doppelte ID',
  [ProjectHealthErrorType.TRANSACTION_OPEN]: 'Offene Transaktion',
}

export enum ProjectHealthErrorSeverity {
  CRITICAL = 'critical', // Project will fail if not fixed. Do not allow further editing at all until resolved.
  MEDIUM = 'medium', // Project will still work but some parts might be blocked until resolved.
  LOW = 'low', // Project will still work, these are cosmetic issues.
}

export interface ProjectHealthError {
  type: ProjectHealthErrorType // Type of error
  severity: ProjectHealthErrorSeverity // Severity of error
  message: string // Message that will be displayed to user
  objectType: string // Object type error refers to
  objectId: string // Object ID error refers to
  autofixAvailable: boolean // If true, error can be automatically fixed
  autofixMessage?: string // Message to user what the auto-fix will do
}

/**
 * Checks that a project is properly configured based on the project dump.
 * The health check should stay decoupled from the projectEditor/deviceHandler/etc.
 * @param project
 */
export async function checkProjectHealth(
  projectEditor: ProjectEditor,
  project: ProjectDump
): Promise<ProjectHealthError[]> {
  const apiClient = projectEditor.apiClient
  const errors: ProjectHealthError[] = []

  errors.push(...checkProjectProperties())
  errors.push(...checkProjectLinksHealth(projectEditor, project))
  errors.push(...checkGroupsHealth())
  errors.push(...checkAnnotationsHealth())
  errors.push(...checkBuildingsHealth())
  errors.push(...checkFloorsHealth())
  errors.push(...checkDevicesHealth())
  errors.push(...(await checkTransactionsHealth(apiClient, project)))

  return errors
}

/**
 * Checks general project properties
 * @param project
 */
function checkProjectProperties(): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []
  // TODO: Missing implementation: Check JSON schema, status, customer, lock status, ...
  return errors
}

/**
 * Checks groups health
 * @param project
 */
function checkGroupsHealth(): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []
  // TODO: Missing implementation
  return errors
}

/**
 * Checks buildings health
 * @param project
 */
function checkBuildingsHealth(): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []
  // TODO: Missing implementation
  return errors
}

/**
 * Checks floors health
 * @param project
 */
function checkFloorsHealth(): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []
  // TODO: Missing implementation
  return errors
}

/**
 * Checks devices health
 * @param project
 */
function checkDevicesHealth(): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []
  // TODO: Missing implementation
  return errors
}

/**
 * Checks annotations health
 * @param project
 */
function checkAnnotationsHealth(): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []
  // TODO: Missing implementation
  return errors
}

/**
 * Checks transactions health
 * @param project
 */
async function checkTransactionsHealth(
  apiClient: ApiClientV2,
  project: ProjectDump
): Promise<ProjectHealthError[]> {
  const errors: ProjectHealthError[] = []
  const transactionErrors = await checkProjectOpenTransactions(
    apiClient,
    project
  )
  errors.push(...transactionErrors)
  return errors
}

/**
 * Checks that links are properly configured and all corresponding devices exist.
 * @param project
 */
function checkProjectLinksHealth(
  projectEditor: ProjectEditor,
  project: ProjectDump
): ProjectHealthError[] {
  const errors: ProjectHealthError[] = []

  //
  if (!project.links_internal) {
    errors.push({
      type: ProjectHealthErrorType.LINKS_MISSING,
      severity: ProjectHealthErrorSeverity.CRITICAL,
      message: 'Property `links_internal` missing in project.',
      objectType: Project.objectType,
      objectId: project.id,
      autofixAvailable: false,
    })
    return errors
  }
  if (!project.links_internal.links) {
    errors.push({
      type: ProjectHealthErrorType.LINKS_MISSING,
      severity: ProjectHealthErrorSeverity.CRITICAL,
      message: 'Property `links` in `links_internal` missing in project.',
      objectType: Project.objectType,
      objectId: project.id,
      autofixAvailable: false,
    })
    return errors
  }

  const links = project.links_internal.links
  const devices = project.device_assignments
  const deviceMap: Map<string, DeviceAssignment> = new Map()
  for (const device of devices) {
    deviceMap.set(device.id, device)
  }
  const linkIds = new Set()
  for (const link of links) {
    if (linkIds.has(link.id)) {
      errors.push({
        type: ProjectHealthErrorType.DUPLICATE_ID,
        severity: ProjectHealthErrorSeverity.CRITICAL,
        message: `Duplicate link ID: ${link.id}`,
        objectType: 'link',
        objectId: link.id,
        autofixAvailable: false,
      })
    }
    linkIds.add(link.id)

    if (link.project && link.project !== project.id) {
      errors.push({
        type: ProjectHealthErrorType.LINKS_WRONG_PROJECT,
        severity: ProjectHealthErrorSeverity.MEDIUM,
        message: `Link has wrong project ID (Link ID: ${link.id}).`,
        objectType: 'link',
        objectId: link.id,
        autofixAvailable: false,
      })
    }

    // Check that receiver and sender exist
    const receiverId = link.receiver
    const senderId = link.sender
    if (!deviceMap.has(receiverId)) {
      errors.push({
        type: ProjectHealthErrorType.LINK_RECEIVER_DOES_NOT_EXIST,
        severity: ProjectHealthErrorSeverity.CRITICAL,
        message: `Empfänger mit ID ${receiverId} existiert nicht (Link ID: ${link.id}).`,
        objectType: 'link',
        objectId: link.id,
        autofixAvailable: true,
        autofixMessage: 'Löscht den Link.',
      })
      continue
    }
    if (!deviceMap.has(senderId)) {
      errors.push({
        type: ProjectHealthErrorType.LINK_SENDER_DOES_NOT_EXIST,
        severity: ProjectHealthErrorSeverity.CRITICAL,
        message: `Sender mit ID ${receiverId} existiert nicht (Link ID: ${link.id}).`,
        objectType: 'link',
        objectId: link.id,
        autofixAvailable: true,
        autofixMessage: 'Löscht den Link.',
      })
    }

    // TODO: Check link.sender_channel (does sender have that many channels?)
    // TODO: Check that combination of sender, receiver and sender_channel is always unique
  }
  return errors
}

/**
 * Tries to fix the project error
 * @param error
 */
export async function tryFixError(
  error: ProjectHealthError,
  projectEditor: ProjectEditor
): Promise<void> {
  if (!error.autofixAvailable) {
    return Promise.reject('Fehler kann nicht automatisch behoben werden.')
  }

  switch (error.type) {
    case ProjectHealthErrorType.LINK_SENDER_DOES_NOT_EXIST:
      return fixLinkSenderDoesNotExist(error, projectEditor)
    case ProjectHealthErrorType.LINK_RECEIVER_DOES_NOT_EXIST:
      return fixLinkReceiverDoesNotExist(error, projectEditor)
    case ProjectHealthErrorType.TRANSACTION_OPEN:
      return fixTransactionOpen(error, projectEditor)
    default:
      return Promise.reject('Fehler kann nicht automatisch behoben werden.')
  }
}

async function fixTransactionOpen(
  error: ProjectHealthError,
  projectEditor: ProjectEditor
): Promise<void> {
  // Rollback transaction
  await Transaction.rollback(projectEditor.apiClient, error.objectId)
}

async function _checkDeviceDoesNotExistAndGetLink(
  error: ProjectHealthError,
  projectEditor: ProjectEditor,
  isSender: boolean
): Promise<Link> {
  const link = projectEditor.deviceHandler.getLink(error.objectId)
  // Make sure this device really does not exist
  const id = isSender ? link.sender : link.receiver
  try {
    const device = await projectEditor.apiClient.get<DeviceAssignment>(
      DeviceAssignment,
      id
    )
    if (device) {
      return Promise.reject(
        `Gerät mit ID ${device.id} existiert. Bitte kontaktieren sie einen Administrator.`
      )
    }
  } catch (error) {
    // We expect it to fail
  }
  return link
}

async function fixLinkSenderDoesNotExist(
  error: ProjectHealthError,
  projectEditor: ProjectEditor
): Promise<void> {
  const link = await _checkDeviceDoesNotExistAndGetLink(
    error,
    projectEditor,
    true
  )

  // Possible that several links with this sender exist, remove all
  const links = projectEditor.deviceHandler.findLinks(link.sender)
  return projectEditor.deviceHandler.deleteLinks(links)
}

async function fixLinkReceiverDoesNotExist(
  error: ProjectHealthError,
  projectEditor: ProjectEditor
): Promise<void> {
  const link = await _checkDeviceDoesNotExistAndGetLink(
    error,
    projectEditor,
    false
  )

  // Possible that several links with this receiver exist, remove all
  const links = projectEditor.deviceHandler.findLinks(undefined, link.receiver)
  return projectEditor.deviceHandler.deleteLinks(links)
}

/**
 * Checks that links are properly configured and all corresponding devices exist.
 * @param project
 */
async function checkProjectOpenTransactions(
  apiClient: ApiClientV2,
  project: ProjectDump
): Promise<ProjectHealthError[]> {
  const errors: ProjectHealthError[] = []
  const transactions = await apiClient.getListItems<Transaction>(Transaction, {
    filter: {
      state: [TransactionState.Open],
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  })
  for (const transaction of transactions) {
    errors.push({
      type: ProjectHealthErrorType.TRANSACTION_OPEN,
      severity: ProjectHealthErrorSeverity.CRITICAL,
      message: `Es existiert eine nicht abgeschlossene Transaktion: ${transaction.meta.title}`,
      objectType: Transaction.objectType,
      objectId: transaction.id,
      autofixAvailable: true,
      autofixMessage: 'Macht Operation rückgängig.',
    })
  }

  return errors
}

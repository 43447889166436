
import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'
import Prism from 'vue-prismjs'

@Component({
  name: 'brelag-admin-equipment-editor-help',
  components: {
    Prism,
  },
  data() {
    return {
      helpModalOpen: false,
      editorOptions: {
        tabSize: 4,
        mode: {
          name: 'javascript',
          json: true,
        },
        theme: 'base16-light',
        lineNumbers: false,
        line: false,
      },
      columns: [
        {
          field: 'type',
          label: 'Typ',
        },
        {
          field: 'structure',
          label: 'Struktur',
        },
        {
          field: 'description',
          label: 'Beschreibung',
        },
      ],
      fieldTypes: [
        {
          type: 'label',
          structure: `
            {
              "type": "label-field",
              "key": "",
              "properties": {
                "text": "",
                "label": "",
                "tooltip": ""
              }
            }
          `,
          description: 'Info Text Feld.',
        },
        {
          type: 'checkbox-field',
          structure: `
           {
              "key": "",
              "type": "checkbox-field",
              "properties": {
                "label": "",
                "extraData": {}
              }
            }
          `,
          description: 'Checkbox.',
        },
        {
          type: 'input',
          structure: `
            {
              "key": "",
              "type": "input",
              "properties": {
                "label": "",
                "maxlength": 3,
                "extraData": {}
              }
            }
          `,
          description: 'Input',
        },
        {
          type: 'number-field',
          structure: `
            {
              "properties": {
                "extraData": {
                  "dataID": 1,
                  "internalName": "Place",
                  "externalName": "Platzierung"
                },
                "label": "Platz",
                "inputType": "number",
                "min": 0,
                "max": 200
              },
              "type": "number-field",
              "key": "position"
            }
          `,
          description: `Number input. Beachte: Das Model muss nun eine Zahl sein. Die Felder "min" und "max" sind optional`,
        },
        {
          type: 'selection',
          structure: `
            {
              "key": "",
              "type": "selection",
              "properties": {
                "label": "Simple",
                "extraData": {}
              },
              "templateOptions": {
                "options": [
                  { "text": "Jesse", "value": "jesse" },
                  { "text": "John", "value": "john" }
                ],
              }
            }
          `,
          description: 'Auswahlfeld (Dropdown)',
        },
        {
          type: 'sww',
          structure: `
            {
              "key": "sww",
              "type": "sww-field",
              // Optional:
              "properties": {
                "extraData": {
                  "dependencyKey": "chef_fe"
                }
              }
            }
          `,
          description:
            'Beachte: "key" muss auf "sww" gesetzt werden (reserviertes keyword). SWW Feld. Model sollte als "null" gesetzt werden für leere Config. Durch das Setzen des "dependencyKey" Wertes unter "extraData" kann eine Abhängigkeit von einer Checkbox gesetzt werden.',
        },
        {
          type: 'maxflex',
          structure: `
            {
              "key": "maxflex",
              "type": "maxflex-field",
            }
          `,
          description:
            'Beachte: "key" muss auf "maxflex" gesetzt werden (reserviertes keyword). NUR FÜR MAXFLEX ZU BENUTZEN.',
        },
        {
          type: 'egate',
          structure: `
           {
              "type": "egate-field",
              "key": "egate"
            }
          `,
          description:
            'Beachte: "key" muss auf "egate" gesetzt werden (reserviertes keyword). NUR FÜR eGate ZU BENUTZEN.',
        },
      ],
    }
  },
})
export default class EquipmentEditorHelp extends Vue {
  get configStructure() {
    return `
      {
        "title": "",
        "model": {},
        "tabs": [],
        "displayOrder": []
      }
    `
  }

  get tabStructure() {
    return `
      {
        "name": "",
        "icon": "",
        "fields": []
      }
    `
  }

  get fieldStructure() {
    return `
      {
        "key": "",
        "type": "",
        "properties": {
          ...,
          "label": "",
          "extraData": {}
        }
      }
    `
  }

  get sampleConfig() {
    return `
      {
        "title": "title",
        "model": {
          "key2": "Default",
          "name": "jesse"
        },
        "tabs": [
          {
            "name": "name",
            "icon": "mdi mdi-fountain",
            "fields": [
              {
                    "key": "key2",
                    "type": "input",
                    "properties": {
                      "label": "Test Label",
                      "extraData": {
                        "dataID": 2,
                        "internalName": "Internal test",
                        "externalName": "External label"
                      }
                    }
                  }
            ]
          },
          {
            "name": "name",
            "icon": "",
            "fields": [
              {
                "key": "name",
                "type": "selection",
                "properties": {
                  "label": "Simple",
                  "extraData": {
                    "dataID": 1,
                    "internalName": "Internal test",
                    "externalName": "External label",
                    "updateLinkedOnChange": true
                  }
                },
                "templateOptions": {
                  "options": [
                    { "text": "Jesse", "value": "jesse" },
                    { "text": "John", "value": "john" }
                  ]
                }
              }
            ]
          }
        ],
        "displayOrder": [
          "name",
          "key2"
        ]
      }
    `
  }
}

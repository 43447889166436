import { ClientAppEntry, clientAppRouteName } from '@/apps/clientAppRegistry'

export const VIEW_ID = 'brelag-mandator'

export const CLIENT_APP: ClientAppEntry = {
  name: 'Brelag Mandator Admin',
  path: 'brelag-mandator',
  icon: 'mdi-account-group',
  defaultPathName: 'dashboard',
}

export enum BrelagMandatorAppUrls {
  ORDER_LIST = 'order-list',
  ORDER_DETAIL = 'order-detail',
}

export function brelagMandatorRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName)
}

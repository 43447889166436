export default {
  customer: {
    prettyName: 'Auftragsart | Auftragsarten',
    fields: {
      organisation: 'Organisation',
      name: 'Name',
      number: 'Kundennummer',
      meta: {
        address: 'Adresse',
        zip: 'PLZ',
        city: 'Ort',
        country: 'Land',
        contact_person: 'Kontakt',
        email: 'E-Mail',
        tel_central: 'Tel Zentrale',
        tel_mobile: 'Tel Mobil',
        tel_direct: 'Tel Direkt',
      },
    },
    placeholders: {
      organisation: 'Organisation',
      name: 'Name',
      number: 'Kundennummer',
      meta: {
        address: 'Adresse',
        zip: 'PLZ',
        city: 'Ort',
        country: 'Land',
        contact_person: 'Kontakt',
        email: 'E-Mail',
        tel_central: 'Tel Zentrale',
        tel_mobile: 'Tel Mobil',
        tel_direct: 'Tel Direkt',
      },
    },
  },
  project: {
    prettyName: 'Auftrag | Aufträge',
    fields: {
      organisation: 'Organisation',
      title: 'Bezeichnung',
      commission: 'Kommission',
      status: 'Status',
      customer: 'Auftragsart',
      number: 'Globalnummer',
      gnr_number: 'Globalnummer',
      customer_number: 'Kd.-Nr.',
      customer_name: 'Auftragsart',
      editing_locked_by: 'Gesperrt von',
      locked_by_name: 'Gesperrt von',
      create_time: 'Erfassungsdatum',
      update_time: 'Letztes Update',
      meta: {
        commission: 'Kommission',
        description: 'Beschreibung',
        address: 'Adresse',
        zip: 'PLZ',
        city: 'Ort',
        country: 'Land',
        project_leader: 'Projektleiter',
        supervisor: 'Supervisor',
        construction_site: 'Baufeld',
        plan_drawer: 'Planzeichner',
        programmer: 'Programmierer',
        remarks: 'Bemerkungen',
        receiver_groups: 'Empfängergruppen',
        annotations: 'Annotationen',
      },
    },
    placeholders: {
      organisation: 'Organisation',
      title: 'Bezeichnung',
      commission: 'Kommission',
      status: 0,
      description: 'Beschreibung',
      customer: '',
      number: '',
      customer_number: '',
      customer_name: '',
      meta: {
        commission: 'Kommission',
        address: 'Adresse',
        zip: 'PLZ',
        city: 'Ort',
        country: 'Land',
        project_leader: 'Projektleiter',
        supervisor: 'Supervisor',
        construction_site: 'Baufeld',
        plan_drawer: 'Planzeichner',
        programmer: 'Programmierer',
        remarks: 'Bemerkungen',
        receiver_groups: 'Empfängergruppen',
        annotations: 'Annotationen',
      },
    },
  },
}

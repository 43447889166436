
import Vue from 'vue'
import Component from 'vue-class-component'
import axios from 'axios'
import { mapGetters } from 'vuex'
import store from '@/store/index'

import TransactionDropdown from '@/components/core/transaction/TransactionDropdown.vue'
import LanguageSwitcher from './LanguageSwitcher.vue'

import { adminRouteName } from '@/apps/admin/app'

@Component({
  name: 'navigation-bar',
  components: {
    LanguageSwitcher,
    TransactionDropdown,
  },
  methods: {
    adminRouteName,
  },
  computed: {
    ...mapGetters('global', ['navbarHidden']),
  },
})
export default class NavigationBar extends Vue {
  refreshing: boolean = false

  get menuButtonClass(): string {
    let cssClass = 'button is-white menu-button'
    // hide menu button on desktop unless
    // client menu is hidden or
    // reader mode is disabled (no need for close button)
    if (
      !this.$store.getters['global/hideClientAppsMenu'] ||
      !this.$store.getters['global/readerModeEnabled']
    ) {
      cssClass += ' is-hidden-desktop is-hidden-tablet'
    }
    return cssClass
  }

  adminRouteName(name: string) {
    return adminRouteName(name)
  }

  get isPushed() {
    return (
      !this.$store.getters['global/navigationIsCollapsed'] &&
      this.$route.params.app_handle !== undefined &&
      this.isLoggedIn
    )
  }

  get readerModeEnabled() {
    return !this.$store.getters['global/readerModeEnabled']
  }

  get isLoggedIn() {
    return this.$store.state.global.isLoggedIn
  }

  logout() {
    axios
      .post(
        '/api/v1/auth/logout',
        {
          user: {
            id: store.state.global.profileId,
            is_authenticated: store.state.global.is_authenticated,
          },
        },
        store.state.global.axiosConfig
      )
      .then(() => {
        store.dispatch({
          type: 'global/logoutUser',
        })
        this.$router.push('/login')
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  getAvatar() {
    if (store.state.global.profile) {
      return this.$api.getAttachmentUrl(store.state.global.profile.avatar)
    } else {
      return ''
    }
  }

  switchReaderMode() {
    this.$store.commit('global/setReaderMode', {
      value: this.readerModeEnabled,
    })
    if (this.readerModeEnabled) {
      this.$store.commit('global/setNavigationCollapsed', { value: false })
    }
  }
}

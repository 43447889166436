import store from '@/store/index'
import { Location } from 'vue-router'
import { ClientApp } from '@/models/client/models'

export async function beforeEnterOrganisations(to): Promise<void | Location> {
  try {
    await store.dispatch('global/updateContextFilter', {
      objectType: 'organisation',
      filter: {
        order_by: 'name',
      },
    })
    if (to.params.org_slug) {
      // an organisation slug was given, try to select organisation
      await store.dispatch('global/selectContextObjectByProperty', {
        objectType: 'organisation',
        property: 'slug',
        value: to.params.org_slug,
      })
      // successfully looked up organisation via slug

      await store.dispatch('settings/setMandator', {
        mandator: store.getters['global/organisation'],
      })
    } else {
      // no organisation is given in the slug. use the organisation id
      // from the user profile
      const profileOrgId = store.getters['global/profileOrganisationId']
      if (profileOrgId) {
        const organisation = await store.dispatch(
          'global/findObjectByProperty',
          {
            objectType: 'organisation',
            property: 'id',
            value: profileOrgId,
          }
        )
        // redirecting with profile organisation
        return {
          name: 'organisation',
          params: { org_slug: organisation.slug },
          replace: true,
        }
      } else {
        return
      }
    }
  } catch (error) {
    throw new Error('Could not load organisation context')
  }
}

export function beforeEnterOrganisation(to): Promise<void | Location> {
  return store
    .dispatch('global/updateContextFilter', {
      objectType: 'client-app',
      filter: {
        organisation: store.getters['global/object']('organisation').id,
      },
    })
    .then((): Promise<void | Location> => {
      if (to.params.app_handle) {
        // an app handle was given, try to select from apps
        return store
          .dispatch('global/selectContextObjectByProperty', {
            objectType: 'client-app',
            property: 'handle',
            value: to.params.app_handle,
          })
          .then(() => {
            // successfully looked up client app via handle
            return
          })
      } else if (
        to.matched.find((match) => match.name === 'routes-without-client-app')
      ) {
        // allow links that do not need a client app
        return
      } else {
        // no app given, select first app
        return store.dispatch('global/getFirstClientApp').then(
          (clientApp) => {
            // redirecting
            return {
              name: clientApp.view_id,
              params: {
                org_slug: to.params.org_slug,
                app_handle: clientApp.handle,
              },
              replace: true,
            }
          },
          () => {
            // could not get first client app, clear selection
            return store.dispatch('global/selectContextObjectByProperty', {
              objectType: 'client-app',
              property: 'handle',
              value: undefined,
            })
          }
        )
      }
    })
}

/**
 * Check that the 'to' route really belongs to the currently selected client
 * application.
 * @param to route being navigated to
 */
export function requireValidClientAppRoute(to): Promise<void | Location> {
  const clientApp: ClientApp = store.getters['global/object']('client-app')
  if (to.matched.some((match) => match.name === clientApp.view_id)) {
    return Promise.resolve()
  } else {
    return Promise.resolve({
      name: clientApp.view_id,
      params: {
        org_slug: to.params.org_slug,
        app_handle: clientApp.handle,
      },
      replace: true,
    })
  }
}

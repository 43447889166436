import uuidv1 from 'uuid'

import { DeviceGroup, Project } from '@/apps/brelag/common/models/project'
import { apiClientV2 } from '@/api/ApiClientV2'
import {
  EquipmentCategory,
  DeviceAssignment,
} from '@/apps/brelag/common/models/equipment'
import { DeviceHandler } from '@/apps/brelag/mandator-user/project-editor/deviceHandler'
import { LINK_DEFAULT, Link } from '@/apps/brelag/common/models/link'
import { Store } from 'vuex'
import {
  TreeNodeType,
  ProjectEditor,
} from '@/apps/brelag/mandator-user/project-editor/projectEditor'
import { EditorConfig } from '@/apps/brelag/mandator-user/models/editor'
import { EquipmentHandler } from '@/apps/brelag/mandator-user/project-editor/equipmentHandler'
import { getMetaSvgAttribute } from './floorPlanEditorUtil'
import * as Sentry from '@sentry/browser'

export interface DeviceGroupNode {
  text: string
  data: {
    id: string
    type: TreeNodeType
    group: DeviceGroup
  }
  state: {
    expanded: boolean
    selectable: boolean
  }
  children: {
    text: string
    data: {
      id: string
      type: TreeNodeType.DEVICE
    }
    state: {
      expanded: boolean
      selectable: boolean
      selected: boolean
    }
  }[]
}

export class DeviceGroupHandler {
  private _deviceHandler: DeviceHandler
  private _equipmentHandler: EquipmentHandler
  private _projectEditor: ProjectEditor
  private _store: Store<any>
  private _groups: DeviceGroup[] = []

  public constructor(
    deviceHandler: DeviceHandler,
    equipmentHandler: EquipmentHandler,
    store: Store<any>
  ) {
    this._deviceHandler = deviceHandler
    this._equipmentHandler = equipmentHandler
    this._store = store
  }

  /**
   * Initial load of project
   * @param projectId Project ID
   */
  public async loadDeviceGroupHandlerProject(
    groups: DeviceGroup[],
    projectEditor: ProjectEditor
  ) {
    this._projectEditor = projectEditor
    this._groups = groups

    // sort devices now so we don't have to sort later
    await this.sortAllGroups()
  }

  get groups() {
    return this._groups
  }

  /**
   * Updates projects to backend
   */
  private async updateProject(getBackend: boolean = true) {
    await this._projectEditor.updateMeta(
      'receiver_groups',
      this._groups,
      getBackend
    )
    await this.updateDeviceGroupTree()
  }

  /**
   * Create a new device group
   */
  public async createGroup(newGroup: DeviceGroup) {
    if (!newGroup.name) {
      throw new Error('Name darf nicht leer sein.')
    }

    let group
    try {
      group = this._getGroupByName(newGroup.name)
    } catch (err) {
      // Does not exist yet, all good
    }
    if (group) {
      throw new Error('Gruppe mit diesem Namen existiert bereits.')
    }

    newGroup.id = uuidv1()
    this.groups.push(newGroup)
    await this.updateProject()
  }

  /**
   * Edit existing group
   * @param group
   */
  public async editGroup(group: DeviceGroup) {
    const index = this.groups.findIndex((_group) => _group.id === group.id)
    if (index < 0) {
      throw new Error(`Ungültige Gruppen ID ${group.id}`)
    }

    // Currently only name can be edited
    this.groups[index].name = group.name
    await this.updateProject()
  }

  /**
   * Delete a list of device groups
   * @param groupIds
   */
  public async deleteGroups(groupIds: string[]) {
    for (const id of groupIds) {
      await this.deleteGroup(id)
    }
  }

  /**
   * Delete device group. Can only delete if no active links
   */
  public async deleteGroup(groupId: string) {
    const group = this._getGroupById(groupId)
    if (group.links.length > 0) {
      throw new Error(
        `Gruppe hat noch aktive Zuweisung zu Sender: ${
          this._deviceHandler.getDeviceAssignmentById(
            group.links[0].senderId,
            EquipmentCategory.SENDER,
            false
          ).device_number
        }.`
      )
    }
    // No need to update individual links of devices or anything else, since there are no active links anymore

    const ind = this.groups.findIndex((g) => g.id === groupId)
    if (ind > -1) {
      this.groups.splice(ind, 1)
    }

    await this.updateProject()
  }

  /**
   * Add a list of DeviceAssignments to a group
   * @param groupId
   * @param deviceIds ids of DeviceAssignments to add to group
   */
  public async addDevices(groupId: string, deviceIds: string[]) {
    const group: DeviceGroup = this._getGroupById(groupId)

    const newLinks: Link[] = []
    const updateLinks = []
    for (const deviceId of deviceIds) {
      const device = this._deviceHandler.getDeviceAssignmentById(
        deviceId,
        null,
        false
      )
      // Only receivers are added to group
      if (device.category === EquipmentCategory.SENDER) {
        continue
      }

      if (group.deviceIds.findIndex((devId) => devId === deviceId) === -1) {
        group.deviceIds.push(deviceId)
      }

      for (const groupLink of group.links) {
        const links = this._deviceHandler.findLinks(
          groupLink.senderId,
          deviceId,
          groupLink.channel
        )
        if (links.length === 0) {
          // Create new link for this device
          if (!groupLink.options.hasOwnProperty(device.equipment)) {
            const equipment =
              this._equipmentHandler.getEquipmentFromDevice(device)
            groupLink.options[device.equipment] =
              equipment.teaching_options.model
          }

          newLinks.push({
            ...LINK_DEFAULT,
            sender: groupLink.senderId,
            sender_channel: groupLink.channel,
            receiver: deviceId,
            project: this._projectEditor.project.id,
            options: {
              ...groupLink.options[device.equipment],
              groups: [groupId],
            },
          })
        } else if (links.length > 1) {
          throw new Error('Internal Error: More than one link found.')
        } else {
          // This device is already connected to the sender through another group or directly
          // Update the options
          const deviceLink = links[0]
          if (deviceLink.options.groups) {
            const ind = deviceLink.options.groups.findIndex(
              (g) => g === groupId
            )
            if (ind > -1) {
              // The group is already here. This should not happen, but we can leave it.
            } else {
              deviceLink.options.groups.push(groupId)
            }
          } else {
            deviceLink.options.groups = [groupId]
          }
          updateLinks.push({
            id: deviceLink.id,
            options: deviceLink.options,
          })
        }
      }
    }
    this.sortDevicesInGroup(group)

    await this._deviceHandler.createLinks(newLinks)
    await this._deviceHandler.updateLinkOptions(updateLinks)
    await this.updateProject()
    await ProjectEditor.instance.updateLinkedState()
  }

  /**
   * Sort deviceIds array in group
   * @param group
   */
  public async sortDevicesInGroup(group: DeviceGroup) {
    // sort devices in group
    const devices = group.deviceIds.map((id) => {
      return this._deviceHandler.getDeviceAssignmentById(id, null, false)
    })
    group.deviceIds = ProjectEditor.instance
      .sortDevices(devices)
      .map((d) => d.id)
  }

  async sortAllGroups() {
    const groupsLength = this._groups.length
    for (let i = 0; i < groupsLength; i++) {
      this.sortDevicesInGroup(this._groups[i])
    }
    await this.updateDeviceGroupTree()
  }

  /**
   * Removes devices from all groups
   * @param deviceIds
   */
  public async removeFromAllGroups(deviceIds: string[]) {
    // get project once from backend
    await ProjectEditor.instance.getProject()
    const updateBackend = false
    for (const deviceId of deviceIds) {
      const groups = this.findGroupsOfReceiver(deviceId)
      await this.removeDevice(
        groups.map((group) => group.id),
        deviceId,
        updateBackend
      )
    }
    await this.updateProject(false)
  }

  /**
   * Remove devices from groups
   */
  public async removeDevices(
    memberships: {
      groupId: string
      deviceId: string
    }[]
  ) {
    // get project once from backend
    await ProjectEditor.instance.getProject()
    const updateBackend = false
    for (const membership of memberships) {
      await this.removeDevice(
        [membership.groupId],
        membership.deviceId,
        updateBackend
      )
    }
    await this.updateProject(false)
    await ProjectEditor.instance.updateLinkedState()
  }

  /**
   * Remove a device from groups
   * @param groupId
   * @param deviceId
   */
  public async removeDevice(
    groupIds: string[],
    deviceId: string,
    updateBackend = true
  ) {
    const deleteLinks: Link[] = []
    const updateLinks = []
    for (const groupId of groupIds) {
      const group: DeviceGroup = this._getGroupById(groupId)

      // Handle the real links
      for (const groupLink of group.links) {
        const links = this._deviceHandler.findLinks(
          groupLink.senderId,
          deviceId,
          groupLink.channel
        )
        if (links.length === 0) {
          // This should not be the case, but we can leave it
        } else {
          const deviceLink = links[0]
          let deleteLink = false
          if (deviceLink.options.groups) {
            const ind = deviceLink.options.groups.findIndex(
              (g) => g === groupId
            )
            if (ind > -1) {
              deviceLink.options.groups.splice(ind, 1)
            }
            if (deviceLink.options.groups.length > 0) {
              // Device is still connected through another group, just remove our group but leave link
            } else {
              deleteLink = true
            }
          } else {
            deleteLink = true
          }
          if (deleteLink) {
            const links = this._deviceHandler.findLinks(
              groupLink.senderId,
              deviceId,
              groupLink.channel
            )
            deleteLinks.push(...links)
          } else {
            updateLinks.push({
              id: deviceLink.id,
              options: deviceLink.options,
            })
          }
        }
      }

      // Remove device from group
      const ind = group.deviceIds.findIndex((devId) => devId === deviceId)
      if (ind > -1) {
        group.deviceIds.splice(ind, 1)
      }
    }

    await this._deviceHandler.deleteLinks(deleteLinks)
    await this._deviceHandler.updateLinkOptions(updateLinks)
    if (updateBackend) {
      await this.updateProject()
    }
  }

  /**
   * Creates new links between a group and a sender/channel combination
   * @param ids array of group ids
   * @param senderId
   * @param channel
   * @param options
   */
  public async createLinks(
    ids: string[],
    senderIds: string[],
    channel: number,
    configs: EditorConfig[]
  ) {
    const senderId = senderIds[0]
    for (const id of ids) {
      await this.createLink(id, senderId, channel, configs)
    }
  }

  /**
   * Finds group link index for parameters
   * @param groupId
   * @param senderId
   * @param channel
   */
  public findGroupLink(
    groupId: string,
    senderId: string,
    channel: number
  ): number | null {
    const group = this._getGroupById(groupId)
    const links = group.links
    return links.findIndex(
      (link) => link.senderId === senderId && link.channel === channel
    )
  }

  /**
   * Create a link between a group and a sender/channel combination
   * @param id
   * @param senderId
   * @param channel
   * @param options
   */
  public async createLink(
    id: string,
    senderId: string,
    channel: number,
    configs: EditorConfig[]
  ) {
    const group = this._getGroupById(id)
    const newLinks = []
    const updateLinks = []

    const link = {
      channel,
      senderId,
      options: {},
    }
    for (const editorConfig of configs) {
      if (editorConfig.devices.length === 0) {
        continue
      }
      link.options[editorConfig.devices[0].equipment] = editorConfig.model
    }

    for (const deviceId of group.deviceIds) {
      const device = this._deviceHandler.getDeviceAssignmentById(
        deviceId,
        null,
        false
      )
      // options for this equipment don't exist yet, add default
      if (!link.options.hasOwnProperty(device.equipment)) {
        const equipment = this._equipmentHandler.getEquipmentFromDevice(device)
        link.options[device.equipment] = equipment.teaching_options.model
      }

      const deviceLinks = this._deviceHandler.findLinks(
        senderId,
        deviceId,
        channel
      )

      if (deviceLinks.length === 0) {
        // Create a new link
        newLinks.push({
          ...LINK_DEFAULT,
          sender: senderId,
          sender_channel: channel,
          receiver: deviceId,
          project: this._projectEditor.project.id,
          options: {
            ...link.options[device.equipment],
            groups: [id],
          },
        })
      } else {
        // Device already has this link, just add the group
        const deviceLink = deviceLinks[0]
        if (deviceLink.options.groups) {
          const ind = deviceLink.options.groups.findIndex((g) => g === id)
          if (ind > -1) {
            // device already in group, leave as is
          } else {
            deviceLink.options.groups.push(id)
          }
        } else {
          deviceLink.options.groups = [id]
        }
        updateLinks.push({
          id: deviceLink.id,
          options: {
            ...deviceLink.options,
            ...link.options[device.equipment],
          },
        })
      }
    }
    await this._deviceHandler.createLinks(newLinks)
    await this._deviceHandler.updateLinkOptions(updateLinks)
    // add link to group if it doesn't exist yet
    if (this.findGroupLink(group.id, senderId, channel) === -1) {
      group.links.push(link)
    }
    await this.updateProject()
  }

  /**
   * Deletes links between a list of groups and a sender/channel
   * @param groupIds
   * @param senderId
   * @param channel
   */
  public async deleteGroupLinks(
    groupIds: string[],
    senderId: string,
    channel: number
  ): Promise<void> {
    for (const groupId of groupIds) {
      await this.deleteGroupLink(groupId, senderId, channel)
    }
  }

  /**
   * Delete Link between a group and a sender/channel
   * @param groupId Group ID
   * @param senderId Sender ID
   * @param channel Channel
   */
  public async deleteGroupLink(
    groupId: string,
    senderId: string,
    channel: number
  ) {
    const group = this._getGroupById(groupId)
    if (this.findGroupLink(groupId, senderId, channel) === -1) {
      // This does not exist, just return
      return
    }

    const deleteLinks: Link[] = []
    const updateLinks = []
    for (const device of group.deviceIds) {
      const deviceLinks = this._deviceHandler.findLinks(
        senderId,
        device,
        channel
      )

      if (deviceLinks.length === 0) {
        // Should not happen, we can ignore
      } else {
        const deviceLink = deviceLinks[0]
        if (deviceLink.options.groups) {
          const ind = deviceLink.options.groups.findIndex((g) => g === groupId)
          if (ind > -1) {
            deviceLink.options.groups.splice(ind, 1)
          }
          if (deviceLink.options.groups.length > 0) {
            // This device has this connection through another group, just update options
            updateLinks.push({
              id: deviceLink.id,
              options: deviceLink.options,
            })
          } else {
            const links = this._deviceHandler.findLinks(
              senderId,
              device,
              channel
            )
            deleteLinks.push(...links)
          }
        } else {
          const links = this._deviceHandler.findLinks(senderId, device, channel)
          deleteLinks.push(...links)
        }
      }
    }
    await this._deviceHandler.deleteLinks(deleteLinks)
    await this._deviceHandler.updateLinkOptions(updateLinks)
    const linkIdx = this.findGroupLink(groupId, senderId, channel)
    group.links.splice(linkIdx, 1)

    await this.updateProject()
  }

  /**
   * Find group by name
   * @param name
   */
  private _getGroupByName(name: string): DeviceGroup {
    let _group
    this.groups.forEach((group) => {
      if (group.name === name) {
        _group = group
      }
    })
    if (!_group) {
      throw new Error('Gruppe mit diesem Namen existiert nicht.')
    } else {
      return _group
    }
  }

  public getGroup(groupId: string): DeviceGroup {
    return this._getGroupById(groupId)
  }

  /**
   * Find group by id
   * @param id
   */
  private _getGroupById(id: string): DeviceGroup {
    let _group
    this.groups.forEach((group) => {
      if (group.id === id) {
        _group = group
      }
    })
    if (!_group) {
      Sentry.captureMessage(`Gruppe mit ID ${id} existiert nicht.`)
      console.error(`Gruppe mit ID ${id} existiert nicht.`)
    } else {
      return _group
    }
  }

  private get selectedReceivers(): string[] {
    return this._store.getters['brelag/selectedReceivers']
  }

  private get selectedSenders(): string[] {
    return this._store.getters['brelag/selectedSenders']
  }

  private get selectedChannel(): number {
    return this._store.getters['brelag/selectedChannel']
  }

  public get receiversInSelectedGroup(): string[] {
    return this._store.getters['brelag/receiversInSelectedGroup']
  }

  public get selectedSender(): DeviceAssignment | null {
    return this._store.getters['brelag/selectedSender']
  }

  public get selectedSenderId(): string | null {
    if (this.selectedSender) {
      return this.selectedSender.id
    } else {
      return null
    }
  }

  /**
   * Update linked/unlinked Device Group Tree based on sender selection
   */
  public async updateDeviceGroupTree(): Promise<void> {
    const unlinkedGroups: DeviceGroupNode[] = []
    const linkedGroups: DeviceGroupNode[] = []

    for (const group of this.groups) {
      const devs = []
      const devices = group.deviceIds.map((id) => {
        return this._deviceHandler.getDeviceAssignmentById(
          id,
          EquipmentCategory.RECEIVER,
          false
        )
      })
      const length = devices.length
      for (let i = 0; i < length; i++) {
        const device = devices[i]
        try {
          const deviceIcon = this._equipmentHandler.getDeviceIcon(device)
          const comments = getMetaSvgAttribute(device, 'textLines') as string[]
          const remark =
            comments && comments.length > 0 ? comments[0] : undefined

          // Basic Object for device.data attribute.
          const data = {
            ...device,
            type: TreeNodeType.DEVICE,
            isRepeatingOnly: false, // Default value. (Gets overwritten by the handler below)
            deviceIcon,
            remark,
            _parameters: {
              senderId: this.selectedSenderId,
              receiverId: device.id,
              channel: this.selectedChannel,
              _deviceHandler: this._deviceHandler,
            },
          }

          // Proxy handler for the above device.data object.
          // This handler does lazy load the 'device.data.isRepeatingOnly' property when it gets requested.)
          const dataHandler = {
            get(target, prop: string) {
              if (prop === 'isRepeatingOnly') {
                // Check if modified config
                return target._parameters._deviceHandler.getIsRepeatingOnly(
                  target._parameters.senderId,
                  target._parameters.receiverId,
                  target._parameters.channel
                )
              }
              return Reflect.get(target, prop)
            },
          }

          devs.push({
            text: device.device_number,
            data: new Proxy(data, dataHandler), // define device.data via proxy
            state: {
              expanded: true,
              selectable: true,
              selected:
                this.selectedReceivers.findIndex(
                  (receiver) => receiver === device.id
                ) > -1
                  ? true
                  : false,
            },
          })
        } catch (error) {
          console.error(error)
        }
      }
      const groupData = {
        text: group.name,
        data: {
          group: group,
          id: group.id,
          type: TreeNodeType.GROUP,
        },
        children: devs,
        state: {
          expanded: true,
          selectable: true,
        },
      }

      if (this.selectedSenders.length === 0) {
        unlinkedGroups.push(groupData)
      } else {
        const selectedSender = this.selectedSenders[0]
        const selectedChannel = this.selectedChannel
        // Check if this group is linked to selected sender/channel combination
        let isLinked = false
        for (const link of group.links) {
          if (
            link.senderId === selectedSender &&
            link.channel === selectedChannel
          ) {
            isLinked = true
          }
        }
        if (isLinked) {
          linkedGroups.push(groupData)
        } else {
          unlinkedGroups.push(groupData)
        }
      }
    }

    await this._store.dispatch(
      'brelag/updateUnlinkedDeviceGroupTree',
      unlinkedGroups
    )
    await this._store.dispatch(
      'brelag/updateLinkedDeviceGroupTree',
      linkedGroups
    )
  }

  public findGroupsOfReceiver(receiverId: string): DeviceGroup[] {
    const groups = []
    for (const group of this.groups) {
      for (const devId of group.deviceIds) {
        if (devId === receiverId) {
          groups.push(group)
        }
      }
    }
    return groups
  }

  public findGroupsOfSender(senderId: string, channel?: number): DeviceGroup[] {
    const groups = []
    for (const group of this.groups) {
      for (const link of group.links) {
        if (link.senderId === senderId) {
          if (channel !== undefined) {
            if (link.channel === channel) {
              groups.push(group)
            }
          } else {
            groups.push(group)
          }
        }
      }
    }
    return groups
  }

  public findLinks(
    receiverId: string,
    senderId: string,
    channel: number
  ): DeviceGroup[] {
    const groups = []
    for (const group of this.groups) {
      if (group.deviceIds.findIndex((dev) => dev === receiverId) > -1) {
        for (const link of group.links) {
          if (link.senderId === senderId) {
            if (channel !== undefined) {
              if (link.channel === channel) {
                groups.push(group)
              }
            } else {
              groups.push(group)
            }
          }
        }
      }
    }
    return groups
  }

  public checkForDelete(device: DeviceAssignment) {
    if (device.category === EquipmentCategory.RECEIVER) {
      // Check if receiver belongs to a group
      const groups = this.findGroupsOfReceiver(device.id)
      if (groups.length > 0) {
        throw new Error(
          `Kann Empfänger nicht löschen, da er zur Gruppe '${groups[0].name}' gehört.`
        )
      }
    } else {
      // Check if sender is linked to a group
      const groups = this.findGroupsOfSender(device.id)
      if (groups.length > 0) {
        throw new Error(
          `Kann Sender nicht löschen, da er mit Gruppe '${groups[0].name}' verbunden ist.`
        )
      }
    }
  }
}

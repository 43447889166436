
import Vue from 'vue'
import Component from 'vue-class-component'

import {
  MaxFlexLayout,
  MAX_FLEX_LAYOUTS,
  MAX_FLEX_TYPE_MAP,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import MaxFlexLayoutView from '@/apps/brelag/mandator-user/components/editor/maxflex/MaxFlexLayoutView.vue'

@Component({
  name: 'maxflex-list',
  components: {
    MaxFlexLayoutView,
  },
  data() {
    return {
      layouts: MAX_FLEX_LAYOUTS,
      filter: '2',
    }
  },
})
export default class MaxFlexList extends Vue {
  filter: string
  layouts: MaxFlexLayout[]
  MAX_FLEX_TYPE_MAP = MAX_FLEX_TYPE_MAP

  get maxKeys() {
    const keys = [...Array(9).keys()].map((key) => key.toString())
    keys[0] = 'Alle'
    return keys
  }

  get filteredLayouts(): MaxFlexLayout[] {
    if (this.filter !== null && this.filter !== 'Alle') {
      return this.layouts.filter((layout) => {
        return layout.numKeys === parseInt(this.filter)
      })
    } else {
      return this.layouts
    }
  }

  selectLayout(layoutValue: number) {
    this.$emit('selected', layoutValue)
  }

  cancel() {
    this.$emit('cancel')
  }
}

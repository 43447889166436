
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'error-component',
  props: {
    errors: {
      required: false,
    },
  },
})
export default class ErrorComponent extends Vue {
  get parsedErrors() {
    if (this.$props.errors !== undefined) {
      return JSON.parse(this.$props.errors)
    } else {
      return []
    }
  }

  exitError() {
    this.$router.replace({ name: 'overview' })
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import isEqual from 'lodash.isequal'
import _ from 'lodash'

import ObjectStateTag from '../ObjectStateTag.vue'
import {
  routeHasClientApp,
  getLocationPreservingState,
  getClientAppOfRoute,
} from '@/apps/routingUtils'
import { clientAppRouteName } from '@/apps/clientAppRegistry'
import ParentSelectorsV2 from '@/components/common/lists/ParentSelectorsV2.vue'
import FormBuilder from './FormBuilder.vue'
import { FormBuilderConfig } from './formBuilderHelper'
import { insertPropsInObject } from '@/util/util'
import ObjectBreadcrumbsV2 from './ObjectBreadcrumbsV2.vue'

@Component({
  name: 'base-form',
  components: {
    ObjectStateTag,
    ParentSelectorsV2,
    ObjectBreadcrumbsV2,
    FormBuilder,
  },
  props: {
    modelClass: {
      required: true,
    },
    id: {
      required: true,
    },
    templateId: {
      required: false,
    },
    columnWidth: {
      default: 'is-12',
    },
    hasError: {
      default: false,
    },
    errorMessage: {
      default: '',
    },
    hasLifeCycle: {
      default: true,
    },
    beforeSaveHook: {
      default: undefined,
    },
    afterSaveHook: {
      default: undefined,
    },
    warningMessages: {
      default: () => [],
    },
    // whether to navigate back when saving form
    navigateOnSave: {
      default: true,
    },
    completedLocation: {
      default: null,
    },
    customCreate: {
      default: null,
    },
    editable: {
      default: true,
    },
    customConfig: {
      default: null,
    },
    showSelectors: {
      default: true,
    },
  },
  data() {
    return {
      modelLoaded: false,
      config: this.$props.customConfig || this.$props.modelClass.formConfig(),
      objectType: this.$props.modelClass.objectType,
      form: {
        loading: false,
      },
    }
  },
})
export default class BaseFormV2 extends Vue {
  $refs: {
    FormBuilder: FormBuilder
  }
  objectType: string
  originalDataObject: any = null
  formValidation = {
    isValid: true,
    errorMessages: [],
  }
  countOriginalChange: number = 0
  modelLoaded: boolean = false
  config: FormBuilderConfig
  form: {
    $valid: boolean
    loading: boolean
  }

  @Watch('$props.id')
  onIdChange() {
    this.loadForm()
  }

  mounted() {
    this.loadForm()
  }

  parseModel(model: object) {
    return this.$props.modelClass.parseModel(
      model,
      this.$props.modelClass.fields
    )
  }

  setLoading(loading: boolean) {
    this.form.loading = loading
  }

  loadForm() {
    if (this.isNew) {
      // New object, nothing else to do
      this.originalDataObject = this.parseModel(this.config.model)
      this.modelLoaded = true
    } else {
      this.form.loading = true
      const id = this.isFromTemplate ? this.$props.templateId : this.$props.id
      return this.$apiv2
        .get(this.$props.modelClass, id)
        .then((response) => {
          this.$emit('getobject', response)
          let obj = Object.assign({}, response)
          this.originalDataObject = this.parseModel(obj)
          if (this.isFromTemplate) {
            this.originalDataObject.id = '0'
          }
          this.$emit('loaded', this.originalDataObject)
          this.config.model = JSON.parse(
            JSON.stringify(this.originalDataObject)
          )
          this.form.loading = false
          this.modelLoaded = true
        })
        .catch((exception) => {
          this.handleError(exception)
        })
    }
  }

  get isFromTemplate() {
    return this.$props.templateId !== undefined && this.$props.templateId !== ''
  }

  get isNew() {
    return this.$props.id === '0'
  }

  navigateWhenCompleted() {
    if (this.$props.completedLocation !== null) {
      // a location was given via props
      this.$router.push(this.$props.completedLocation)
      return
    } else {
      // try to find a decent route
      if (routeHasClientApp(this.$route)) {
        const clientApp = getClientAppOfRoute(this.$route)
        const routeName = clientAppRouteName(
          clientApp.view_id,
          this.objectType + '-list'
        )
        const location = getLocationPreservingState(routeName, this.$router)
        if (this.$router.resolve(location).route.matched.length > 0) {
          // only go there if the location matches something
          this.$router.push(location)
          return
        }
      }
    }

    // go somewhere at least
    this.$router.back()
  }

  // TODO: Unit test this!
  /**
   * Converts an objects like {'a.b': 1, d: 3} to {a: {b: 1}, d: 3}
   */
  parseDotString(model: object): object {
    let parsedModel = {}
    Object.keys(model).forEach((key) => {
      const keys = key.split('.')
      if (keys.length === 1 && key !== '') {
        parsedModel[keys[0]] = model[keys[0]]
      } else {
        if (parsedModel[keys[0]] === undefined) {
          parsedModel[keys[0]] = this.parseDotString({
            [keys.slice(1, keys.length).join('.')]: model[key],
          })
        } else {
          insertPropsInObject(
            parsedModel[keys[0]],
            this.parseDotString({
              [keys.slice(1, keys.length).join('.')]: model[key],
            })
          )
        }
      }
    })
    return parsedModel
  }

  /**
   * Update a single field of the model
   */
  async updateField(field: string, value: any) {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      const orig = JSON.parse(JSON.stringify(this.originalDataObject))
      if (!orig.hasOwnProperty(field)) {
        throw new Error(`Invalid field: ${field}`)
      }
      orig[field] = value
      await this.$apiv2.update(this.$props.modelClass, orig)
      this.originalDataObject = JSON.parse(JSON.stringify(orig))
      // this.$refs.FormBuilder.model[field] = value
    } catch (error) {
      this.handleError(error)
    }
    loadingComponent.close()
  }

  async save() {
    // TODO: Use something better than 'any'
    const dataObject: any = this.parseDotString(this.$refs.FormBuilder.model)

    if (this.$props.modelClass) {
      // Remove 'virtual' fields (fields that are shown in form but do not belong to model itself)
      this.$props.modelClass.fields.forEach((field) => {
        if (field.virtual && dataObject.hasOwnProperty(field.key)) {
          delete dataObject[field.key]
        }
        if (field.display === false || field.display === 'false') {
          // Field was not displayed, reset to original
          try {
            _.set(
              dataObject,
              field.key,
              _.get(this.originalDataObject, field.key)
            )
          } catch (err) {
            console.warn(err)
          }
        }
      })
    }

    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      if (this.$props.beforeSaveHook !== undefined) {
        await this.$props.beforeSaveHook(dataObject)
      }

      if (!dataObject.id || dataObject.id === '0' || dataObject.id === '') {
        if (this.$props.customCreate !== null) {
          await this.$props.customCreate(dataObject)
        } else {
          await this.$apiv2.create(this.$props.modelClass, dataObject)
        }
      } else {
        await this.$apiv2.update(this.$props.modelClass, dataObject)
      }
      this.originalDataObject = JSON.parse(JSON.stringify(dataObject))

      if (this.$props.afterSaveHook !== undefined) {
        await this.$props.afterSaveHook(dataObject)
      }

      if (this.isNew) {
        this.$emit('created', dataObject)
      }

      if (this.$props.navigateOnSave) {
        this.navigateWhenCompleted()
      }
    } catch (error) {
      this.handleError(error)
    }
    loadingComponent.close()
  }

  clearError() {
    this.formValidation.errorMessages = []
    this.formValidation.isValid = true
  }

  handleError(error) {
    this.formValidation.errorMessages = this.$errorHandler.errorToStrings(error)
    this.formValidation.isValid = false
  }

  get formValid() {
    return this.form.$valid
  }

  isDirty() {
    return !isEqual(
      this.$refs.FormBuilder.model,
      this.parseModel(this.originalDataObject)
    )
  }

  get isNewObject() {
    return this.$props.id === undefined || this.$props.id === '0'
  }

  get prettyName() {
    return this.$props.modelClass.prettyName()
  }
}

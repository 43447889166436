import { ClientAppEntry, clientAppRouteName } from '@/apps/clientAppRegistry'
import { BrelagMockEndpoints } from '@/apps/brelag/common/mock/endpoints'

export const VIEW_ID = 'brelag-admin'

export const CLIENT_APP: ClientAppEntry = {
  name: 'Brelag Admin',
  path: 'brelag-admin',
  icon: 'mdi-domain',
  defaultPathName: 'dashboard',
  mockEndpoints: BrelagMockEndpoints,
}

export function brelagAdminRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName)
}

import isFunction from 'lodash.isfunction'

// common
import { BackgroundTask } from '@/models/core/models'
import { ApiClient } from '@/api/ApiClient'

interface BackgroundTaskContext {
  bgTask: BackgroundTask
  onDoneSuccessCallbacks: Array<() => void>
  onDoneErrorCallbacks: Array<(error) => void>
  onUpdateCallbacks: Array<(bgTask) => void>
  intervalId: NodeJS.Timer
  subscribers: number
  retries: number
  maxRetries: number
}

export enum BackgroundTaskStates {
  CREATED = 'created',
  QUEUED = 'queued',
  RUNNING = 'running',
  SUBMITTED_EXPIRED = 'submitted-expired',
  TRANSITION = 'transition',
  DONE_SUCCESS = 'done-success',
  DONE_SUCCESS_EXPIRED = 'done-success-expired',
  DONE_ERROR = 'done-error',
  DONE_ERROR_EXPIRED = 'done-error-expired',
  FAILED = 'failed',
}

/**
 * Helper class which can be used to subscribe to background task results and execute callbacks when the task is done.
 */
export class BackgroundTaskHandler {
  static install(vue): void {
    vue.prototype.$bgTaskHandler = new BackgroundTaskHandler()
  }

  tasks = new Map<string, BackgroundTaskContext>()
  apiClient: ApiClient

  public constructor() {
    this.apiClient = new ApiClient()
  }

  /**
   * Checks if task is already registered with task handler
   * @param {BackgroundTask} bgTask
   * @returns {boolean}
   */
  public isTaskRegistered(bgTask: BackgroundTask): boolean {
    return this.tasks.has(bgTask.id)
  }

  /**
   * Subscribe to watching a task.
   * @param {BackgroundTask} bgTask The background task to watch
   * @param {Function} onDoneSuccess Function to call when background task succeeded
   * @param {Function) onError Function to call when background task terminated with error
   * @param {Function) onUpdate Function to call when background task was refreshed
   * @param {number} timeout Defines time between refreshing the background task context in ms
   * @param {number} maxRetries Defines the number of times we will check the background task before we give up
   * @returns {number} handle that the caller can use to unsubscribe
   */
  public watchTask(
    bgTask: BackgroundTask,
    onDoneSuccess: () => void,
    onError: (error) => void = () => null,
    onUpdate: (bgTask) => void = () => null,
    timeout: number = 3000,
    maxRetries: number = 10
  ): number {
    let ctx: BackgroundTaskContext
    if (this.tasks.has(bgTask.id)) {
      ctx = this.tasks.get(bgTask.id)
      ctx.onDoneSuccessCallbacks.push(onDoneSuccess)
      ctx.onDoneErrorCallbacks.push(onError)
      ctx.onUpdateCallbacks.push(onUpdate)
      ctx.subscribers++
    } else {
      const intervalId = setInterval(
        () => this.updateBgTask(bgTask.id),
        timeout
      )
      ctx = {
        bgTask,
        onDoneSuccessCallbacks: [onDoneSuccess],
        onDoneErrorCallbacks: [onError],
        onUpdateCallbacks: [onUpdate],
        intervalId,
        subscribers: 1,
        retries: 0,
        maxRetries,
      }
      this.tasks.set(bgTask.id, ctx)
    }
    this.updateBgTask(bgTask.id)
    return ctx.onDoneSuccessCallbacks.length - 1
  }

  /**
   * Unsubscribe from watching a background task
   * @param {string} id ID of the background task
   * @param {number} handle
   */
  public unWatchTask(id: string, handle: number): void {
    const ctx = this.tasks.get(id)
    if (ctx) {
      ctx.subscribers--
      ctx.onDoneSuccessCallbacks[handle] = undefined
      ctx.onDoneErrorCallbacks[handle] = undefined
      ctx.onUpdateCallbacks[handle] = undefined
      if (ctx.subscribers === 0) {
        this.clearTask(ctx)
      }
    }
  }

  /**
   * Fetch background task from database and update the context
   * @param {string} id
   * @returns {Promise<void>}
   */
  private updateBgTask(id: string): Promise<void> {
    // get task
    const ctx = this.tasks.get(id)
    ctx.retries++
    if (ctx.retries > ctx.maxRetries) {
      this.clearTask(ctx)
      this.invokeErrorCallbacks(ctx, 'Task timed out.')
    }
    return this.apiClient
      .get('background-task', id)
      .then((response: BackgroundTask) => {
        ctx.bgTask = response
        this.invokeUpdateCallbacks(ctx)
        if (
          ctx.bgTask.state === BackgroundTaskStates.DONE_SUCCESS ||
          ctx.bgTask.state === BackgroundTaskStates.DONE_SUCCESS_EXPIRED
        ) {
          this.handleErrors(ctx)
          this.invokeCallbacks(ctx)
        } else if (
          ctx.bgTask.state === BackgroundTaskStates.FAILED ||
          ctx.bgTask.state === BackgroundTaskStates.DONE_ERROR ||
          ctx.bgTask.state === BackgroundTaskStates.DONE_ERROR_EXPIRED
        ) {
          this.invokeErrorCallbacks(ctx, ctx.bgTask.result)
          this.clearTask(ctx)
        }
      })
      .catch((error) => {
        // TODO: Add notification to notification center
        console.warn(error)
        this.clearTask(ctx)
      })
  }

  /**
   * Checks if there are any errors in the result. This is possible even though the background task is in a
   * success state
   * @param {BackgroundTaskContext} ctx
   */
  private handleErrors(ctx: BackgroundTaskContext): void {
    if (
      ctx.bgTask.result &&
      ctx.bgTask.result.errors &&
      ctx.bgTask.result.errors.length > 0
    ) {
      this.invokeErrorCallbacks(ctx, ctx.bgTask.result.errors)
    }
  }

  /**
   * Removes the interval and deletes the background task context
   * @param {BackgroundTaskContext} ctx
   */
  private clearTask(ctx: BackgroundTaskContext): void {
    clearInterval(ctx.intervalId)
    this.tasks.delete(ctx.bgTask.id)
  }

  /**
   * Invokes the callbacks for handling errors
   * @param {BackgroundTaskContext} ctx
   * @param result
   */
  private invokeErrorCallbacks(ctx: BackgroundTaskContext, result: any): void {
    ctx.onDoneErrorCallbacks.forEach((cb) => {
      if (cb !== undefined && isFunction(cb)) {
        cb(result)
      }
    })
  }

  /**
   * Invokes the callbacks when background task has been refreshed
   * @param {BackgroundTaskContext} ctx
   */
  private invokeUpdateCallbacks(ctx: BackgroundTaskContext): void {
    ctx.onUpdateCallbacks.forEach((cb) => {
      if (cb !== undefined && isFunction(cb)) {
        cb(ctx.bgTask)
      }
    })
  }

  /**
   * Invokes the callbacks when a background task is finished
   * @param {BackgroundTaskContext} ctx
   */
  private invokeCallbacks(ctx: BackgroundTaskContext): void {
    ctx.onDoneSuccessCallbacks.forEach((cb) => {
      if (cb !== undefined && isFunction(cb)) {
        cb() // TODO: add ctx.bgTask here?
      }
    })
    this.clearTask(ctx)
  }
}

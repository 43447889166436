import { updateStateForRoute } from '../routingUtils'
import { clientAppRegistryGet } from '../clientAppRegistry'
import { VIEW_ID, adminRouteName } from './app'

import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import { Location, Route, RouteConfig } from 'vue-router'

import MainMenu from '@/apps/admin/components/MainMenu.vue'
import AdminDashboard from '@/apps/admin/components/AdminDashboard.vue'

import OrganisationForm from '@/apps/admin/components/organisation/OrganisationForm.vue'
import OrganisationList from '@/apps/admin/components/organisation/OrganisationList.vue'

import UserForm from '@/apps/admin/components/user/UserForm.vue'
import ProfileList from '@/apps/admin/components/user/ProfileList.vue'

/* Permission */
import ObjectAuthorizationForm from '@/apps/admin/components/permission/ObjectAuthorizationForm.vue'
import ObjectAuthorizationList from '@/apps/admin/components/permission/ObjectAuthorizationList.vue'
import RoleForm from '@/apps/admin/components/permission/RoleForm.vue'
import RoleList from '@/apps/admin/components/permission/RoleList.vue'
import GroupForm from '@/apps/admin/components/group/GroupForm.vue'
import GroupList from '@/apps/admin/components/group/GroupList.vue'

/* ClientApp */
import ClientAppForm from '@/apps/admin/components/client-app/ClientAppForm.vue'
import ClientAppList from '@/apps/admin/components/client-app/ClientAppList.vue'

async function beforeEnterAdminApp(to: Route): Promise<void | Location> {
  const objectList = [
    'role',
    'object-authorization',
    'client-app',
    'data/application',
    'group',
  ]
  return updateStateForRoute(to, objectList)
}

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: adminRouteName('organisation-root') },
  components: {
    default: RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterAdminApp,
  },
  children: [
    {
      path: 'organisation',
      name: adminRouteName('organisation-root'),
      redirect: { name: adminRouteName('dashboard') },
      component: RouterPassThrough,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: adminRouteName('dashboard'),
          props: true,
          component: AdminDashboard,
        },
        {
          path: 'list',
          name: adminRouteName('organisation-list'),
          props: true,
          component: OrganisationList,
          meta: {
            requiresList: {
              organisation: true,
            },
            hasPagination: {
              objects: 'organisation',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'organisation',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: adminRouteName('organisation-create'),
          props: { id: '0' },
          component: OrganisationForm,
        },
        {
          path: ':id',
          name: adminRouteName('organisation-detail'),
          props: true,
          component: OrganisationForm,
          meta: {
            detailOf: 'organisation',
          },
        },
      ],
    },
    {
      path: 'user',
      name: adminRouteName('profile-root'),
      redirect: { name: adminRouteName('profile-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: adminRouteName('profile-list'),
          props: true,
          component: ProfileList,
          meta: {},
        },
        {
          path: 'create',
          name: adminRouteName('profile-create'),
          component: UserForm,
          props: { id: '0' },
        },
        {
          path: ':id',
          name: adminRouteName('profile-detail'),
          props: true,
          component: UserForm,
          meta: {},
        },
      ],
    },
    {
      path: 'role',
      name: adminRouteName('role-root'),
      redirect: { name: adminRouteName('role-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: adminRouteName('role-list'),
          props: true,
          component: RoleList,
          meta: {
            requiresList: {
              role: true,
            },
            hasPagination: {
              objects: 'role',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'role',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: adminRouteName('role-create'),
          component: RoleForm,
          props: { id: '0' },
        },
        {
          path: ':id',
          name: adminRouteName('role-detail'),
          props: true,
          component: RoleForm,
        },
      ],
    },
    {
      path: 'group',
      name: adminRouteName('group-root'),
      redirect: { name: adminRouteName('group-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: adminRouteName('group-list'),
          props: true,
          component: GroupList,
          meta: {
            requiresList: {
              group: true,
            },
            hasPagination: {
              objects: 'group',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
          },
        },
        {
          path: 'create',
          name: adminRouteName('group-create'),
          component: GroupForm,
          props: { id: '0' },
        },
        {
          path: ':id',
          name: adminRouteName('group-detail'),
          props: true,
          component: GroupForm,
        },
      ],
    },
    {
      path: 'object-authorization',
      name: adminRouteName('object-authorization-root'),
      redirect: { name: adminRouteName('object-authorization-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: adminRouteName('object-authorization-list'),
          props: true,
          component: ObjectAuthorizationList,
          meta: {
            requiresList: {
              'object-authorization': true,
            },
            hasQuery: {
              role: true,
              // group: true,
            },
            hasPagination: {
              objects: 'object-authorization',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'object-authorization',
              orderingDefault: 'object_type',
            },
          },
        },
        {
          path: 'create',
          name: adminRouteName('object-authorization-create'),
          props: { id: '0' },
          component: ObjectAuthorizationForm,
          meta: {
            requiresList: {
              role: true,
            },
          },
        },
        {
          path: ':id',
          name: adminRouteName('object-authorization-detail'),
          props: true,
          component: ObjectAuthorizationForm,
        },
      ],
    },

    {
      path: 'client-app',
      name: adminRouteName('client-app-root'),
      redirect: { name: adminRouteName('client-app-list') },
      component: RouterPassThrough,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: adminRouteName('client-app-list'),
          props: true,
          component: ClientAppList,
          meta: {},
        },
        {
          path: 'create',
          name: adminRouteName('client-app-create'),
          component: ClientAppForm,
          props: { id: '0' },
        },
        {
          path: ':id',
          name: adminRouteName('client-app-detail'),
          props: true,
          component: ClientAppForm,
        },
      ],
    },
  ],
}

export default route

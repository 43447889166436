
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'

import SideBar from '@/components/navigation/sidebar/SideBar.vue'
import PushSideBar from '@/components/navigation/sidebar/PushSideBar.vue'
import Breadcrumb from '@/components/navigation/Breadcrumb.vue'
import NavFooter from '@/components/navigation/NavFooter.vue'
import { setDocumentTitle } from './util/util'

@Component({
  name: 'app',
  components: {
    Breadcrumb,
    SideBar,
    PushSideBar,
    NavFooter,
  },
  computed: {
    ...mapGetters('global', ['navbarHidden', 'navigationIsCollapsed']),
  },
})
export default class App extends Vue {
  created() {
    const document: any = window.document
    const favicon: any = document.getElementById('favicon')

    setDocumentTitle('Brelag Styler')
    favicon.href = '/img/brelag/favicon_new.ico'
  }

  mounted() {
    this.iframeBreakout()
  }
  /**
   * This is used for the payment system where after a redirect inside an iFrame we want to escape the iFrame
   */

  iframeBreakout() {
    if (top.location != location) {
      top.location.href = document.location.href
    }
  }

  get isPushed() {
    return (
      !this.$store.getters['global/navigationIsCollapsed'] &&
      this.$route.params.app_handle !== undefined &&
      this.isLoggedIn
    )
  }

  get isLoggedIn() {
    return this.$store.state.global.isLoggedIn
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class MultilineTooltip extends Vue {
  @Prop()
  label: string
  @Prop()
  type: string
  @Prop()
  active: boolean
  @Prop()
  position: string
}

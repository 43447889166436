export default {
  fields: {
    description: {
      label: 'Brief description',
      placeholder: 'Description',
    },
  },
  save: 'Speichern',
  saveSuccess: 'Erfolgreich gespeichert',
  cancel: 'Abbrechen',
  delete: 'Löschen',
  remove: 'Entfernen',
  deleteSuccess: 'Erfolgreich gelöscht',
  detail: 'Detail',
  actions: 'Aktionen',
  copy: 'Kopieren',
  select: 'Auswählen',
  selection: 'Auswahl',
  apply: 'Anwenden',
  empty: 'Leer.',
  required: 'Pflichtfeld',
  loading: 'Laden',
  searchBy: 'Suche nach',
  search: 'Suche',
  filter: 'Filter',
  filterBy: 'Filtern nach',
  noFilter: 'Kein Filter',
  noSelection: 'Keine Auswahl',
  no: 'Keine',
  found: 'gefunden',
  moveUp: 'Nach oben verschieben',
  moveDown: 'Nach unten verschieben',
  createdSuccessfully: 'erfolgreich erstellt',
  leaveWithoutSaveQuestion:
    'Sie haben ungespeicherte Änderungen. Sind Sie sicher, dass Sie die Seite verlassen wollen?',
  confirmDelete: 'Sind Sie sicher, dass Sie dieses Objekt löschen wollen?',
  uploadForm: {
    dropToUpload: 'Dateien hineinziehen zum Hochladen',
    preview: 'Vorschau',
    name: 'Name',
    size: 'Grösse',
    status: 'Status',
    chooseFile: 'Datei wählen',
    noImage: 'Kein Bild',
    startUpload: 'Start Upload',
    stopUpload: 'Stopp Upload',
    invalidExtension: 'Ungültige Dateiendung',
  },
  transaction: {
    operationsListed: 'Hier sind alle unbeendeten Operationen aufgelistet.',
    applySuccess: 'Erfolgreich angewendet!',
    resetSuccess: 'Erolgreich zurückgesetzt!',
  },
}

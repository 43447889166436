import Vue from 'vue'
import { Collection } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

export enum ActivityKind {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
  STATE_CHANGE = 'state-change',
}

export class UserInfo {
  organisation: string
  profile: string
  ip_address: string
  user_agent: string
}

export class Activity extends TransientBaseObject {
  activity_kind: ActivityKind
  organisation: string
  project?: string
  payload?: TransientBaseObject & {
    __type__: string
  }
  user_info: UserInfo
  content_type: string
  object_id: string

  static apiUrl = 'event_log/activity/'
  static noTrailingSlash = true
  static objectType: string = 'activity'
  static listFields: ListModelField[] = [
    { key: 'version' },
    { key: 'event_type' },
    { key: 'organisation' },
    { key: 'user_info' },
  ]
  static fields: ModelField[] = []

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Activity, Activity>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(ACTIVITY_DEFAULT))
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...ACTIVITY_DEFAULT },
    }
  }
}

export const ACTIVITY_DEFAULT: Activity = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  activity_kind: ActivityKind.CREATE,
  content_type: '',
  object_id: '',
  organisation: '',
  user_info: {
    organisation: '',
    profile: '',
    user_agent: '',
    ip_address: '',
  },
}


import { Component, Vue, Watch } from 'vue-property-decorator'

import { CollectionSubscriber } from '@/api/ApiClient'
import { brelagAdminRouteName } from '@/apps/brelag/brelag-admin/app'
import DashboardCollectionTile from '@/apps/brelag/common/components/DashboardCollectionTile.vue'
import EventFeed from '@/apps/brelag/common/components/EventFeed.vue'

@Component({
  methods: {
    brelagAdminRouteName,
  },
  components: {
    DashboardCollectionTile,
    EventFeed,
  },
  data() {
    return {
      mandators: this.$api.subscribeListInComponent(
        this,
        'organisation',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
      admins: this.$api.subscribeListInComponent(
        this,
        'profile',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
      // equipmentGroups: this.$api.subscribeListInComponent(
      //   this,
      //   'equipmentgroup',
      //   {
      //     organisation: this.$store.getters['global/organisation'].id,
      //   },
      //   {
      //     page: 1,
      //     pageSize: 1,
      //   }
      // ),
      // equipments: this.$api.subscribeListInComponent(
      //   this,
      //   'equipment',
      //   {
      //     organisation: this.$store.getters['global/organisation'].id,
      //   },
      //   {
      //     page: 1,
      //     pageSize: 1,
      //   }
      // ),
    }
  },
})
export default class BrelagAdminDashboard extends Vue {
  mandators: CollectionSubscriber
  admins: CollectionSubscriber
  // equipmentGroups: CollectionSubscriber
  // equipments: CollectionSubscriber

  @Watch('$route.path')
  organisationChanged() {
    if (
      this.$route.query.organisation !==
      this.$store.getters['global/organisation'].id
    ) {
      // We only listen to global organisation changes
      return
    }

    // TODO: This is a temporary solution before ApiClient supports watching for a variable
    this.mandators.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
    this.admins.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
    // this.equipmentGroups.setFilter({
    //   organisation: this.$store.getters['global/organisation'].id,
    // })
    // this.equipments.setFilter({
    //   organisation: this.$store.getters['global/organisation'].id,
    // })
  }
}

export const BrelagMockEndpoints = [
  // new Endpoint(`commerce/offer/${OFFERS[0].id}/`, OFFERS[0], false),
  // new Endpoint(`commerce/offer/${OFFERS[1].id}/`, OFFERS[1], false),
  // new Endpoint(`commerce/offer/${OFFERS[2].id}/`, OFFERS[2], false),
  // new Endpoint(`commerce/offer/?page=1&page_size=10&show_size=true`, OFFERS),
  // new Endpoint(`commerce/order/?page=1&page_size=10&show_size=true`, ORDERS),
  // new Endpoint(`brelag/customer/${CUSTOMERS[0].id}/`, CUSTOMERS[0], false),
  // new Endpoint(`brelag/customer/${CUSTOMERS[1].id}/`, CUSTOMERS[1], false),
  // new Endpoint(`brelag/customer/?organisation=${orgId}`, CUSTOMERS),
  // new Endpoint(`brelag/project/?organisation=${orgId}`, PROJECTS),
  // new Endpoint(`brelag/building/?organisation=${orgId}`, BUILDINGS),
  // new Endpoint(`brelag/plan/?organisation=${orgId}`, FLOORS),
  // new Endpoint(`image/?album=${ALBUMS[0].id}`, IMAGES),
  // new Endpoint(`album/?organisation=${orgId}`, ALBUMS),
  // new Endpoint(`album/${ALBUMS[0].id}/`, ALBUMS[0], false),
]

import { render, staticRenderFns } from "./BaseListV2.vue?vue&type=template&id=b46f027e&scoped=true&"
import script from "./BaseListV2.vue?vue&type=script&lang=ts&"
export * from "./BaseListV2.vue?vue&type=script&lang=ts&"
import style0 from "./BaseListV2.vue?vue&type=style&index=0&id=b46f027e&prod&lang=scss&scoped=true&"
import style1 from "./BaseListV2.vue?vue&type=style&index=1&id=b46f027e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b46f027e",
  null
  
)

export default component.exports
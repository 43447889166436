import {
  BackgroundTask,
  ObjectAuthorization,
  Role,
  Group,
  GroupMembership,
  Album,
  Transaction,
  Attachment,
} from '@/models/core/models'
import { TRANSIENT_BASE_OBJECT_DEFAULT } from '@/models/core/base'

export const OBJECT_AUTHORIZATION_DEFAULT: ObjectAuthorization = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  object_type: '',
  object_id: '',
  role: '',
  note: '',
  propagation: ObjectAuthorization.Propagation.BOTH,
  group: '',
}

export const GROUP_DEFAULT: Group = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  organisation: '',
}

export const GROUP_MEMBERSHIP_DEFAULT: GroupMembership = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  group: '',
  profile: '',
}

export const BACKGROUND_TASK_DEFAULT: BackgroundTask = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  state: '',
  description: '',
}

export const ALBUM_DEFAULT: Album = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  organisation: '',
  public: true,
}

export const TRANSACTION_DEFAULT: Transaction = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  idempotency_key: undefined,
  meta: {},
}

export const PRETTY_NAMES = {
  'object-authorization': 'Object Authorisation',
  profile: 'User',
  organisation: 'Organisation',
  role: 'Role',
  group: 'Group',
}

export const REMAP_NAMES = {
  profile: 'user',
}

/* tslint:disable:variable-name */
export const PERMISSIONS_DEFAULT = {
  'core.organisation': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    link_profile: false,
    add_role: false,
    add_group: false,
    view_object_authorization: false,
    edit_object_authorization: false,
    add_client_app: false,
    reorder_client_apps: false,
  },
  'core.profile': {
    view: false,
    edit: false,
    authorize: false,
    block: false,
    add_token: false,
    reset_pin_counter: false,
    update_2fa: false,
  },
  'core.role': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
  },
  'core.group': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
  },
  'core.token': {
    view: false,
    edit: false,
    revoke: false,
  },
  'client.clientapp': {
    view: false,
    edit: false,
    delete: false,
    add_setting_kind: false,
    authorize: false,
  },
  'client.clientappsettingkind': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    view_setting: false,
    edit_setting: false,
    access_setting: false,
  },
}

export const ROLE_DEPENDENCY_TREE = [
  {
    name: 'Organisation',
    contentType: 'core.organisation',
    dependencies: [
      'core.profile',
      'core.role',
      'core.group',
      'client.clientapp',
    ],
    children: [
      {
        name: 'Profile',
        contentType: 'core.profile',
        dependencies: ['core.token'],
        children: [
          {
            name: 'Token',
            contentType: 'core.token',
          },
        ],
      },
      {
        name: 'Role',
        contentType: 'core.role',
      },
      {
        name: 'Group',
        contentType: 'core.group',
      },
      {
        name: 'Client Application',
        contentType: 'client.clientapp',
        dependencies: ['client.clientappsettingkind'],
        children: [
          {
            name: 'Client App Setting Kind',
            contentType: 'client.clientappsettingkind',
          },
        ],
      },
    ],
  },
]

export const contentTypes = [
  {
    prettyName: 'Organisation',
    apiName: 'organisation',
    name: 'core.organisation',
    key: 'name',
    collection: null,
  },
  {
    prettyName: 'Profile',
    apiName: 'profile',
    name: 'core.profile',
    key: 'username',
    collection: null,
  },
  {
    prettyName: 'Setting Kind',
    apiName: 'device-setting-kind',
    name: 'device.devicesettingkind',
    key: 'handle',
    collection: null,
  },
  {
    prettyName: 'Client App',
    apiName: 'client/app',
    name: 'client.clientapp',
    key: 'name',
    collection: null,
  },
  {
    prettyName: 'Client App Setting Kind',
    apiName: 'client/app-setting-kind',
    name: 'client.clientappsettingkind',
    key: 'name',
    collection: null,
  },
  {
    prettyName: 'Brelag Project',
    apiName: 'brelag/project',
    name: 'brelag.project',
    key: 'title',
    collection: null,
  },
]

export const ROLE_DEFAULT: Role = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  organisation: '',
  permissions: PERMISSIONS_DEFAULT,
}

export const ATTACHMENT_DEFAULT: Attachment = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  kind: '',
  mime_type: '',
  encoding: '',
  file: '',
}

import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  RestAction,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

import { FormFieldType } from '@/components/common/forms/formBuilderHelper'

import { Organisation } from '@/models/core/organisation'

import { Collection, ApiClientV2 } from '@/api/ApiClientV2'

import Vue from 'vue'
import { getAvatarAnnotation } from '@/models/core/util'
import { getTranslation } from '@/lang/setup'

const profileNameCache: Record<string, string> = {}

export class Profile extends TransientBaseObject {
  username: string
  email: string
  first_name: string
  last_name: string
  organisation?: string
  avatar?: string
  require_vpn_2fa: boolean
  kind: string
  // whether user has completed the activation process (setting a password)
  is_activated: boolean
  // whether user is currently active (can be changed by admin)
  is_active: boolean
  // Whether user is a productive user (e.g. not a test user, demo user ,...)
  is_productive: boolean

  meta?: {
    [key: string]: any
  }

  static apiUrl = 'profile'
  static langPath: string = 'models.core.profile'
  static objectType: string = 'profile'
  static filters = [
    {
      key: 'organisation',
      objectType: 'core.organisation',
    },
    {
      key: 'kind',
    },
    {
      key: 'search',
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'username' },
    { key: 'email' },
    {
      key: 'meta.gender',
      transform: (gender: string) => {
        if (gender === 'f') {
          return 'Frau'
        } else if (gender === 'm') {
          return 'Herr'
        } else {
          return ''
        }
      },
    },
    { key: 'first_name' },
    { key: 'last_name' },
    {
      key: 'is_activated_and_active',
      transform: (is_activated: boolean) => {
        if (is_activated) {
          return getTranslation('models.auth.yes')
        } else {
          return getTranslation('models.auth.no')
        }
      },
    },
    /* { key: 'kind' }, */
    /* { key: 'avatar', columnType: ColumnType.IMAGE }, */
  ]
  static fields: ModelField[] = [
    {
      key: 'organisation',
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        editable: false,
        relatedObjectProperty: 'name',
        modelClass: Organisation.objectType,
      },
    },
    {
      key: 'meta.gender',
      required: false,
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        options: [
          { text: 'Herr', value: 'm' },
          { text: 'Frau', value: 'f' },
        ],
      },
    },
    {
      key: 'email',
      formProperties: {
        editable: false,
      },
    },
    { key: 'first_name', required: false },
    { key: 'last_name', required: false },
    /*
    {
      key: 'kind',
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        options: [
          { text: 'Any', value: null },
          { text: 'Human', value: 'human' },
          { text: 'Machine', value: 'machine' },
        ],
      },
    },
    */
    { key: 'meta.phone', required: false },
  ]
  public static restActions = {
    [RestAction.DELETE]: false,
  }

  static annotations: Collection.Annotation<Profile, any>[] = [
    {
      key: 'avatar_img',
      callback: getAvatarAnnotation,
    },
    {
      key: 'is_activated_and_active',
      callback: async (profile: Profile) => {
        return {
          id: profile.id,
          annotations: {
            is_activated_and_active: profile.is_activated && profile.is_active,
          },
        }
      },
    },
  ]

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Profile, Profile>(vm, this, filter, pagination)
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...PROFILE_DEFAULT },
    }
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(PROFILE_DEFAULT))
  }

  static async unblockAll(apiClient: ApiClientV2, organisationId: string) {
    await this.blockAll(apiClient, organisationId, true)
  }

  static async blockAll(
    apiClient: ApiClientV2,
    organisationId: string,
    unblock: boolean = false
  ) {
    await apiClient.customPost(`profile/block-all`, {
      organisation: organisationId,
      unblock,
    })
  }

  static async blockUser(apiClient: ApiClientV2, profile: Profile) {
    await apiClient.customPost(`profile/${profile.id}/block`)
  }

  static async unblockUser(apiClient: ApiClientV2, profile: Profile) {
    await apiClient.customPost(`profile/${profile.id}/block`, {
      unblock: true,
    })
  }

  static async getFullName(apiClient: ApiClientV2, profileId: null | string) {
    if (!profileId) {
      return
    }
    if (profileNameCache[profileId]) {
      return profileNameCache[profileId]
    }
    let fullName = profileId
    try {
      const profile = await apiClient.get<Profile>(Profile, profileId)
      if (profile) {
        if (profile.first_name && profile.last_name) {
          fullName = `${profile.first_name} ${profile.last_name}`
        } else {
          fullName = profile.username
        }
      }
      profileNameCache[profileId] = fullName
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // no right to see user -> do nothing
      } else {
        throw error
      }
    }
    return fullName
  }
}

export const PROFILE_DEFAULT: Profile = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  is_productive: true,
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  kind: 'human',
  require_vpn_2fa: false,
  meta: {},
  is_activated: false,
  is_active: false,
}

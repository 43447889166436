import { Location, Route, RouteConfig } from 'vue-router'

import MainMenu from './components/MainMenu.vue'
import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import BrelagMandatorDashboard from './components/BrelagMandatorDashboard.vue'
import { updateStateForRoute } from '@/apps/routingUtils'
import { clientAppRegistryGet } from '@/apps/clientAppRegistry'
import {
  brelagMandatorRouteName,
  VIEW_ID,
  BrelagMandatorAppUrls,
} from '@/apps/brelag/mandator-admin/app'

function beforeEnterBrelagMandatorApp(to: Route): Promise<void | Location> {
  const objectList = []
  return updateStateForRoute(to, objectList)
}

let route: RouteConfig = { path: '' }

route = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: brelagMandatorRouteName('dashboard') },
  components: {
    default: RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterBrelagMandatorApp,
  },
  children: [
    {
      path: 'dashboard',
      name: brelagMandatorRouteName('dashboard'),
      component: BrelagMandatorDashboard,
      meta: {
        requiresAuth: true,
        requiresList: {},
      },
    },
    {
      path: 'profile',
      name: brelagMandatorRouteName('profile-root'),
      redirect: { name: brelagMandatorRouteName('profile-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: brelagMandatorRouteName('profile-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "profile-list" */ './components/user/MandatorUserList.vue'
            ),
        },
        {
          path: 'create',
          name: brelagMandatorRouteName('profile-create'),
          component: () =>
            import(
              /* webpackChunkName: "profile-form" */ './components/user/MandatorUserForm.vue'
            ),
          props: { id: '0' },
        },
        {
          path: ':id',
          name: brelagMandatorRouteName('profile-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "profile-form" */ './components/user/MandatorUserForm.vue'
            ),
        },
      ],
    },
    {
      path: 'webshop',
      name: brelagMandatorRouteName('webshop-root'),
      redirect: { name: brelagMandatorRouteName('coin-shop') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'coin-shop',
          name: brelagMandatorRouteName('coin-shop'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "coin-shop" */ './components/coin/CoinShop.vue'
            ),
        },
        {
          path: ':offerId/:orderId/:redirectUrl/payment',
          name: brelagMandatorRouteName('payment'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "payment" */ './components/coin/Payment.vue'
            ),
        },
        {
          path: ':offerId/:orderId/success',
          name: brelagMandatorRouteName('payment-success'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "purchase-success" */ './components/coin/PurchaseSuccess.vue'
            ),
        },
        {
          path: ':offerId/:orderId/failure',
          name: brelagMandatorRouteName('payment-failure'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "purchase-failure" */ './components/coin/PurchaseFailure.vue'
            ),
        },
        {
          path: ':offerId/:orderId/abort',
          name: brelagMandatorRouteName('payment-abort'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "purchase-abort" */ './components/coin/PurchaseAbort.vue'
            ),
        },
      ],
    },
    {
      path: 'order',
      name: brelagMandatorRouteName('order-root'),
      redirect: {
        name: brelagMandatorRouteName(BrelagMandatorAppUrls.ORDER_LIST),
      },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: BrelagMandatorAppUrls.ORDER_LIST,
          name: brelagMandatorRouteName(BrelagMandatorAppUrls.ORDER_LIST),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "order-list" */ './components/order/CoinOrderList.vue'
            ),
        },
        {
          path: ':id/order',
          name: brelagMandatorRouteName(BrelagMandatorAppUrls.ORDER_DETAIL),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "order-detail" */ './components/order/CoinOrderDetail.vue'
            ),
        },
      ],
    },
  ],
}

export default route


import Vue from 'vue'
import Component from 'vue-class-component'
import SearchField from '@/components/common/lists/SearchField.vue'
import { SearchFieldInterface, TransientBaseObject } from '@/models/core/base'

@Component({
  name: 'search-fields',
  props: {
    modelClass: {
      required: true,
    },
  },
  components: {
    SearchField,
  },
})
export default class SearchFields extends Vue {
  modelClass: typeof TransientBaseObject

  get searchFields(): SearchFieldInterface[] {
    return this.modelClass.searchFields()
  }
}

import core from './core'
import client from './client'
import commerce from './commerce'
import auth from './auth'

export default {
  core,
  client,
  commerce,
  auth,
}


import { Component, Vue, Watch } from 'vue-property-decorator'

import {
  SwwConfig,
  SWW_CHANNELS,
  SWW_SENSORS,
  SWW_SENSOR_VALUES,
} from '@/apps/brelag/common/models/swwParams'

@Component({
  components: {},
  props: {
    channel: {
      required: true,
    },
    channelNr: {
      required: true,
    },
  },
  data() {
    return {
      SWW_CHANNELS,
      SWW_SENSORS,
      SWW_SENSOR_VALUES,
    }
  },
})
export default class SwwChannel extends Vue {
  config: SwwConfig
  presetting: number = 0

  created() {
    if (this.$props.channel.fix.value) {
      this.presetting = 2
    } else {
      if (this.$props.channel.enable.value) {
        this.presetting = 1
      } else {
        this.presetting = 0
      }
    }
  }

  @Watch('presetting')
  presettingChanged() {
    if (this.presetting === 0) {
      this.$props.channel.fix.value = 0
      this.$props.channel.enable.value = 0
    } else if (this.presetting === 1) {
      this.$props.channel.fix.value = 0
      this.$props.channel.enable.value = 1
    } else {
      this.$props.channel.fix.value = 1
      this.$props.channel.enable.value = 0
    }
  }
}

import Vue from 'vue'
import { Collection } from '@/api/ApiClientV2'
import moment from 'moment'

import { Currency } from '@/models/commerce/offer'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'

export enum OrderStatus {
  PAYMENT_CREATED = 'created',
  PAYMENT_PENDING = 'payment-pending',
  PAYMENT_SUCCESS = 'payment-success',
  PAYMENT_FAILURE = 'payment-failure',
}

export class Order extends TransientBaseObject {
  organisation: string
  meta: {
    [key: string]: any
  }
  title: string
  number: number
  description: string
  price: string
  currency: Currency
  vat_rate: string
  status: OrderStatus

  static apiUrl = 'commerce/order'
  static langPath: string = 'models.commerce.order'
  static objectType: string = 'order'
  static fields: ModelField[] = [
    { key: 'price', editable: false },
    { key: 'currency', editable: false },
    { key: 'meta.num_coins', editable: false },
    { key: 'status', editable: false },
  ]
  static listFields: ListModelField[] = [
    { key: 'number', sortable: true },
    {
      key: 'create_time',
      transform: (date: string) => {
        return moment(date).format('DD.MM.YYYY HH:mm')
      },
      sortable: true,
    },
    { key: 'title' },
    {
      key: 'price',
      transform: (price: string) => {
        return parseFloat(price).toFixed(2)
      },
    },
    { key: 'meta.num_coins' },
    { key: 'currency' },
  ]

  static formFields() {
    return this.defaultFormFields(this.langPath, this.fields)
  }

  static formConfig() {
    return {
      fields: Order.formFields(),
      model: { ...ORDER_DEFAULT },
    }
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Order, Order>(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(ORDER_DEFAULT))
  }
}

export const ORDER_DEFAULT: Order = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  meta: {},
  title: '',
  description: '',
  number: 0,
  vat_rate: '0',
  price: '0',
  currency: Currency.CHF,
  status: undefined,
}

import { Token } from '@/models/core/models'

export const TOKEN_DEFAULT: Token = {
  id: '',
  label: '',
  key_hash: '',
  profile: '',
  enabled: true,
  _permissions: {},
}

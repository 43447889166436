
import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'

import { Profile } from '@/models/core/profile'
import { CollectionFilter } from '@/api/ApiClient'
import OrganisationSelector from '@/components/navigation/sidebar/OrganisationSelector.vue'
import { Organisation } from '@/models/core/organisation'

@Component({
  name: 'profile-list',
  components: {
    BaseListV2,
    OrganisationSelector,
  },
  data() {
    return {
      Profile,
      kind: this.$route.query.kind ? this.$route.query.kind : 'human',
      organisations: this.$store.state.global.context.organisations,
      selectedOrg: this.$route.query.organisation
        ? this.$route.query.organisation
        : this.$store.getters['global/organisation'].id,
      profileFilter: null,
      usernameSearch: this.$route.query.search
        ? this.$route.query.search
        : null,
    }
  },
})
export default class ProfileList extends Vue {
  profileFilter: CollectionFilter
  selectedOrg: string
  kind: string
  usernameSearch: string

  created() {
    this.filterUsers()
  }

  organisationSelected(newOrg: Organisation): void {
    this.selectedOrg = newOrg.id
    this.filterUsers()
  }

  filterUsers() {
    this.profileFilter = {
      organisation: this.selectedOrg,
      kind: this.kind,
      search: this.usernameSearch,
    }
  }
}

import Vue from 'vue'
import { ApiClientV2, Collection } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ModelField,
  ListModelField,
  ListFilter,
  TRANSIENT_BASE_OBJECT_DEFAULT,
  CellType,
} from '@/models/core/base'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'
import { ImageObject } from './image'

export class KnockautModule extends TransientBaseObject {
  organisation: string
  name: string
  author: string
  description: string
  version: string
  // DecimalField in backend, returned as string with 4 decimal places (e.g. "0.0000")
  price: string
  // Library GUID
  guid: string
  // GUID of Knockaut module
  module_guid: string
  github_url: string
  is_subscription: boolean
  is_internal: boolean
  logo: string
  needs_instance: boolean

  static isFree(module: KnockautModule): boolean {
    return parseInt(module.price) === 0
  }

  static isSubscription(module: KnockautModule): boolean {
    return module.is_subscription === true
  }

  static isInternal(module: KnockautModule): boolean {
    return module.is_internal === true
  }

  static apiUrl = 'brelag/knockaut-module'
  static langPath: string = 'apps.brelag.models.knockaut-module'
  static objectType: string = 'knockaut-module'
  static fields: ModelField[] = [
    {
      key: 'name',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'author',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'is_subscription',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        editable: true,
        label:
          'Wenn aktiviert, wird der Preis als jährliche Abogebühr verrechnet.',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'is_internal',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        editable: true,
        label: 'Wenn aktiviert, wird das Modul nur für Brelag angezeigt.',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'needs_instance',
      formFieldType: FormFieldType.CHECKBOX,
      formProperties: {
        editable: true,
        label:
          'Wenn aktiviert, wird standardmässig eine Instanz vom "Modul GUID" erstellt.',
        trueValue: true,
        falseValue: false,
      },
    },
    {
      key: 'description',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'version',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'price',
      formFieldType: FormFieldType.NUMBER_FIELD,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'guid',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'module_guid',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
      required: true,
    },
    {
      key: 'github_url',
      formFieldType: FormFieldType.INPUT,
      formProperties: {
        editable: true,
      },
    },
    {
      key: 'logo',
      required: false,
      formFieldType: FormFieldType.IMAGE_UPLOAD,
      formProperties: {
        title: 'Logo',
        uploadUrl: '/api/v1/brelag/image/',
        name: 'file',
        modelClass: ImageObject.objectType,
      },
    },
  ]
  static listFields: ListModelField[] = [
    { key: 'name' },
    { key: 'version' },
    { key: 'price' },
    { key: 'is_subscription' },
    { key: 'is_internal' },
    { key: 'needs_instance' },
    {
      key: 'logo_img',
      cellType: CellType.IMAGE,
      transform(image: string) {
        return `${image}?${Math.random()}`
      },
    },
  ]
  static listFilters: ListFilter[] = [
    {
      modelClass: KnockautModule,
      property: 'name',
      key: 'knockaut-module',
    },
  ]
  static annotations: Collection.Annotation<KnockautModule, string>[] = [
    {
      key: 'logo_img',
      callback: async (obj, api: ApiClientV2) => {
        try {
          const image = await api.get<ImageObject>(ImageObject, obj.logo)
          if (image.url) {
            return {
              id: obj.id,
              annotations: {
                logo_img: image.url,
              },
            }
          } else {
            return {
              id: obj.id,
              annotations: {
                logo_img: '/img/empty_avatar.png',
              },
            }
          }
        } catch (err) {
          return {
            id: obj.id,
            annotations: {
              logo_img: '/img/empty_avatar.png',
            },
          }
        }
      },
    },
  ]

  static defaultPagination = {
    page: 1,
    pageSize: 20,
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields)
  }

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<KnockautModule, KnockautModule>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(KNOCKAUT_MODULE_DEFAULT))
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...KNOCKAUT_MODULE_DEFAULT },
    }
  }
}

export class KnockautModuleOrder extends TransientBaseObject {
  module: string
  project: string
  organisation: string
  license_key_hash: string

  static apiUrl = 'brelag/knockaut-module-order'
  static langPath: string = 'apps.brelag.models.knockaut-module-order'
  static objectType: string = 'knockaut-module-order'

  static fields: ModelField[] = []

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<KnockautModuleOrder, KnockautModuleOrder>(
      vm,
      this,
      filter,
      pagination
    )
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(KNOCKAUT_MODULE_ORDER_DEFAULT))
  }
}

export class KnockautModuleInstallation extends TransientBaseObject {
  project: string
  module_order: string
  installed: boolean
  has_instance: boolean
  installed_version: string
  installed_at: string
  last_updated_at: string

  static apiUrl = 'brelag/knockaut-module-installation'
  static langPath: string = 'apps.brelag.models.knockaut-module-installation'
  static objectType: string = 'knockaut-module-installation'

  static fields: ModelField[] = []

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<
      KnockautModuleInstallation,
      KnockautModuleInstallation
    >(vm, this, filter, pagination)
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(KNOCKAUT_MODULE_INSTALLATION_DEFAULT))
  }
}

export const KNOCKAUT_MODULE_DEFAULT: KnockautModule = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  name: '',
  logo: '',
  author: '',
  description: '',
  version: '',
  price: '0.0',
  guid: '',
  module_guid: '',
  github_url: '',
  is_subscription: false,
  is_internal: false,
  needs_instance: false,
}

export const KNOCKAUT_MODULE_ORDER_DEFAULT: KnockautModuleOrder = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  module: '',
  project: '',
  organisation: '',
  license_key_hash: '',
}

export const KNOCKAUT_MODULE_INSTALLATION_DEFAULT: KnockautModuleInstallation =
  {
    ...TRANSIENT_BASE_OBJECT_DEFAULT,
    has_instance: false,
    installed_at: '',
    installed_version: '',
    last_updated_at: '',
    module_order: '',
    installed: false,
    project: '',
  }

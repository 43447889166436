
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'

import { CollectionSubscriber } from '@/api/ApiClient'
import BaseForm from '@/components/common/BaseForm.vue'
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard'
import { contentTypes } from '@/models/core/defaults'
import { Group } from '@/models/core/models'
import { ObjectAuthorization } from '@/models/core/models'
import { GlobalState } from '@/store/GlobalStore'

@Component({
  name: 'object-authorization-form',
  components: {
    'base-form': BaseForm,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      objectAuthorization: ObjectAuthorization,
      contentTypes: contentTypes,
      baseForm: BaseForm,
      collection: null,
      groups: Group.collection(
        this,
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 30,
        }
      ),
      manualMode: false,
    }
  },
  computed: {
    ...mapState('global', {
      roles: (state: GlobalState) => state.context.roles,
    }),
  },
  mixins: [BeforeLeaveGuard],
})
export default class ObjectAuthorizationForm extends Vue {
  groups: CollectionSubscriber
  roles: CollectionSubscriber
  collection: CollectionSubscriber
  objectAuthorization: ObjectAuthorization
  loading: boolean = false
  selected = { id: '' }
  key = ''
  relatedModelInfos = {}
  manualMode: boolean

  relatedModels = {}

  created() {
    this.collection = this.$api.subscribeList('organisation')
  }

  mounted() {
    this.loading = true
    this.$api.get('object-authorization', this.$props.id).then((response) => {
      this.objectAuthorization = response
      this.loading = false
    })
  }

  destroyed() {
    this.collection.dispose()
    this.collection = null
  }

  switchManualMode() {
    if (this.manualMode) {
      if (this.objectAuthorization.object_type) {
        // Check if its a known content type, otherwise dispose collection before switching back
        let knownType = false
        contentTypes.forEach((contentType) => {
          if (contentType.name === this.objectAuthorization.object_type) {
            knownType = true
          }
        })
        if (!knownType) {
          this.objectAuthorization.object_type = ''
          this.onContentTypeChange(null)
        }
      }
    }
    this.manualMode = !this.manualMode
  }

  onContentTypeChange(value) {
    this.loading = true
    contentTypes.forEach((contentType) => {
      if (contentType.name === value) {
        this.collection.dispose()
        // TODO: This should be declared in the contentType probably, or on model..
        if (contentType.name === 'client.clientappsettingkind') {
          this.collection = this.$api.subscribeList(contentType.apiName, {
            client_app: this.$store.getters['global/selectedClientApp'].id,
          })
        } else if (contentType.name === 'brelag/project') {
          this.collection = this.$api.subscribeList(
            contentType.apiName,
            {
              organisation: this.$store.getters['global/organisation'].id,
            },
            {
              page: 1,
              pageSize: 100,
            }
          )
        } else {
          this.collection = this.$api.subscribeList(contentType.apiName, {
            organisation: this.$store.getters['global/organisation'].id,
          })
        }
        this.key = contentType.key
        this.selected = { id: '' }
      }
    })
    this.loading = false
  }

  get transformedObjectAuthorization() {
    if (this.$props.id === '0') {
      this.objectAuthorization.object_id = this.selected.id
      this.objectAuthorization.organisation =
        this.$store.state.global.context.selection.organisation.id
    }
    return this.objectAuthorization
  }

  get rolePlaceholder() {
    if (this.roles && this.roles.objects.length < 1) {
      return 'No roles available.'
    } else {
      return 'Select role.'
    }
  }

  get groupPlaceholder() {
    if (this.groups && this.groups.objects.length < 1) {
      return 'No groups available.'
    } else {
      return 'Select group.'
    }
  }

  get authorizedObjects() {
    return this.collection.objects.filter((obj) => {
      return obj._permissions.authorize
    })
  }

  getRelatedModel(relatedModel: any, row: any): string {
    if (this.relatedModelInfos[row.id]) {
      return this.relatedModelInfos[row.id]
    } else {
      this.$api
        .get(relatedModel.contentType, row[relatedModel.contentType])
        .then((response) => {
          this.$set(this.relatedModelInfos, row.id, response[relatedModel.key])
        })
      return 'Loading...'
    }
  }
}

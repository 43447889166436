export default {
  clientapp: {
    prettyName: 'Client App | Client Apps',
    fields: {
      name: 'Name',
      description: 'Beschreibung',
      handle: 'Handle',
      view_id: 'View ID',
      organisation_name: 'Organisation',
    },
    addSetting: 'Setting hinzufügen',
    addDefaultSettingKind: 'Default Setting Kind hinzufügen',
  },
}

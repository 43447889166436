export const defaultEditorOptions = {
  tabSize: 2,
  indentWithTabs: false,
  styleActiveLine: true,
  lineNumbers: true,
  line: true,
  readOnly: false,
  mode: { name: 'javascript', json: true },
  theme: 'base16-light',
  extraKeys: {
    Tab: (cm) => {
      if (cm.somethingSelected()) {
        cm.indentSelection('add')
      } else {
        cm.replaceSelection(
          cm.getOption('indentWithTabs')
            ? '\t'
            : Array(cm.getOption('indentUnit') + 1).join(' '),
          'end',
          '+input'
        )
      }
    },
  },
}

import axios from 'axios'

export function setBrelagEnabled(store) {
  axios
    .get('/api/v1/brelag/floor/?page=1&page_size=1')
    .then((response) => {
      store.commit('global/setBrelagEnabled', true)
    })
    .catch((error) => {
      if (error.response.status === 403) {
        // Forbidden, but exists
        store.commit('global/setBrelagEnabled', true)
      } else {
        store.commit('global/setBrelagEnabled', false)
      }
    })
}


import Vue from 'vue'
import Component from 'vue-class-component'
import MaxFlexList from '@/apps/brelag/mandator-user/components/editor/maxflex/MaxFlexList.vue'
import MaxFlexConfigEditor from '@/apps/brelag/mandator-user/components/editor/maxflex/MaxFlexConfigEditor.vue'
import {
  MAXFLEX_DEFAULT_CONFIG,
  MaxFlexConfig,
} from '@/apps/brelag/common/models/maxflex/maxflex'
import { DEFAULT_LAYOUT_KEY_CONFIGS } from '@/apps/brelag/common/models/maxflex/defaultLayoutConfigs'
import { simpleCopy } from '@/util/util'

@Component({
  name: 'formly_maxflex-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    MaxFlexList,
    MaxFlexConfigEditor,
  },
  data() {
    return {
      configModalActive: false,
    }
  },
})
export default class MaxFlexField extends Vue {
  previousLayoutValue: number = null
  showLayout = false

  mounted() {
    if (!this.layoutDefined) {
      this.showLayout = true
    }
    if (!this.$props.model[this.$props.field.key]) {
      // Load default config
      this.$props.model[this.$props.field.key] = MAXFLEX_DEFAULT_CONFIG
    }

    // add empty strings if the text and icon fields do not exist to make sure the properties are reactive
    let shouldRefresh = false
    ;(this.$props.model[this.$props.field.key] as MaxFlexConfig).keys.forEach(
      (key) => {
        if (!key.Icon) {
          shouldRefresh = true
          key.Icon = { value: '' }
        }
        if (!key.Text) {
          shouldRefresh = true
          key.Text = { value: '' }
        }
      }
    )
    if (shouldRefresh) {
      this.$props.model[this.$props.field.key] = JSON.parse(
        JSON.stringify(this.$props.model[this.$props.field.key])
      )
    }
  }

  get layoutDefined() {
    return (
      this.$props.model[this.$props.field.key] &&
      this.$props.model[this.$props.field.key].KeyLayout &&
      this.$props.model[this.$props.field.key].KeyLayout.value
    )
  }

  changeLayout() {
    this.showLayout = true
  }

  layoutSelected(value: number) {
    this.showLayout = false
    if (!this.$props.model[this.$props.field.key]) {
      this.$props.model[this.$props.field.key] = simpleCopy(
        MAXFLEX_DEFAULT_CONFIG
      )
    }
    // If we have a default key configuration for this layout, apply this
    if (DEFAULT_LAYOUT_KEY_CONFIGS[value]) {
      this.$props.model[this.$props.field.key]['keys'] = simpleCopy(
        DEFAULT_LAYOUT_KEY_CONFIGS[value]
      )
    }

    Vue.set(this.$props.model[this.$props.field.key], 'KeyLayout', {
      value,
    })
    this.previousLayoutValue = value
  }

  cancel() {
    this.showLayout = false
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import ParentSelectorV2 from '@/components/common/lists/ParentSelectorV2.vue'
import { TransientBaseObject } from '@/models/core/base'

@Component({
  props: {
    modelClass: {
      required: true,
    },
    isForm: {
      default: false,
    },
  },
  components: {
    ParentSelectorV2,
  },
})
export default class ParentSelectorsV2 extends Vue {
  modelClass: typeof TransientBaseObject

  get ancestors() {
    return this.modelClass.ancestors || []
  }
}

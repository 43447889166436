
import Vue from 'vue'
import Component from 'vue-class-component'

import { loadLanguageAsync } from '@/lang/setup'

@Component({
  name: 'language-switcher',
  components: {},
})
export default class LanguageSwitcher extends Vue {
  setLanguage(lang: string) {
    loadLanguageAsync(lang)
  }
}

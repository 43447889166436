// modules
import Router, { RouteConfig } from 'vue-router'
import * as Sentry from '@sentry/browser'

// common
import store from '@/store/index'
import {
  beforeEnterOrganisation,
  beforeEnterOrganisations,
  requireValidClientAppRoute,
} from './routeGuards'

import ErrorComponent from '@/components/common/ErrorComponent.vue'

// Navigation
import MenuClientApps from '@/components/navigation/sidebar/MenuClientApps.vue'
import NavigationBar from '@/components/navigation/NavigationBar.vue'

/* Auth management */
import ChangePassword from '@/components/auth/ChangePassword.vue'
import LogIn from '@/components/auth/LogIn.vue'
import ProfileRegistration from '@/components/auth/ProfileRegistration.vue'
import ProfileActivation from '@/components/auth/ProfileActivation.vue'
import RequestChangeEmail from '@/components/auth/RequestChangeEmail.vue'
import ResetPassword from '@/components/auth/ResetPassword.vue'
import VerifyChangeEmail from '@/components/auth/VerifyChangeEmail.vue'

// Common
import RouterPassThrough from '@/components/common/RouterPassThrough.vue'
import RouterPassThroughNavBar from '@/components/common/RouterPassThroughNavBar.vue'
import RouterPassThroughMainMenu from '@/components/common/RouterPassThroughMainMenu.vue'
import RouterPassThroughMenuClientApps from '@/components/common/RouterPassThroughMenuClientApps.vue'

// Core
import UserForm from '@/apps/admin/components/user/UserForm.vue'

// Admin
import AdminRoute from '@/apps/admin/router'
import ClientAppForm from '@/apps/admin/components/client-app/ClientAppForm.vue'

import BrelagAdminRoute from '@/apps/brelag/brelag-admin/router'
import BrelagDeveloperRoute from '@/apps/brelag/brelag-developer/router'
import BrelagSupporterRoute from '@/apps/brelag/brelag-supporter/router'
import BrelagMandatorRoute from '@/apps/brelag/mandator-admin/router'
import BrelagUserRoute from '@/apps/brelag/mandator-user/router'

// Knockaut
import KnockautLicenseRenewal from '@/components/knockaut/KnockautLicenseRenewal.vue'

const passThroughComponents = {
  default: RouterPassThrough,
  'main-menu': RouterPassThroughMainMenu,
  'pass-through-menu-client-apps': RouterPassThroughMenuClientApps,
  'pass-through-nav-bar': RouterPassThroughNavBar,
}

const CLIENT_APP_ROUTES = [AdminRoute]

const landingPage = {
  name: 'organisations',
}

CLIENT_APP_ROUTES.push(BrelagAdminRoute)
CLIENT_APP_ROUTES.push(BrelagDeveloperRoute)
CLIENT_APP_ROUTES.push(BrelagSupporterRoute)
CLIENT_APP_ROUTES.push(BrelagMandatorRoute)
CLIENT_APP_ROUTES.push(BrelagUserRoute)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'overview',
    redirect: landingPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/error',
    name: 'error',
    props: true,
    component: ErrorComponent,
  },
  {
    path: '/login',
    name: 'login',
    component: LogIn,
  },
  {
    path: '/registration',
    name: 'registration',
    component: ProfileRegistration,
  },
  {
    path: '/reset-password',
    name: 'reset_password',
    props: true,
    component: ResetPassword,
  },
  {
    path: '/request-profile-activation',
    name: 'activate_profile',
    props: true,
    component: ProfileActivation,
  },
  {
    path: '/knockaut-license-renewal',
    name: 'knockaut_license_renewal',
    props: true,
    component: KnockautLicenseRenewal,
  },
  {
    path: '/o',
    name: 'organisations',
    meta: {
      requiresAuth: true,
      beforeEnter: beforeEnterOrganisations,
    },
    components: {
      ...passThroughComponents,
    },
    children: [
      {
        path: ':org_slug',
        name: 'organisation',
        props: true,
        components: {
          'menu-client-apps': MenuClientApps,
          'nav-bar': NavigationBar,
          ...passThroughComponents,
        },
        meta: {
          beforeEnter: beforeEnterOrganisation,
        },
        children: [
          {
            path: '-',
            name: 'routes-without-client-app',
            components: passThroughComponents,
            children: [
              {
                // this route is for creating a client app when there does not exist an admin client app yet
                path: 'client-app-create',
                name: 'bootstrap-client-app-create',
                component: ClientAppForm,
                props: { id: '0' },
              },
              {
                path: 'my-profile',
                name: 'user-profile',
                props: true,
                component: UserForm,
                meta: {},
              },
              {
                path: 'change-password',
                name: 'change-password',
                component: ChangePassword,
              },
              {
                path: ':id/request-change-email',
                name: 'request_change_email',
                props: true,
                component: RequestChangeEmail,
              },
              {
                path: ':id/verify-change-email',
                name: 'verify_change_email',
                props: true,
                component: VerifyChangeEmail,
              },
            ],
          },
          {
            path: ':app_handle',
            name: 'client_app',
            components: passThroughComponents,
            meta: {
              beforeEnter: requireValidClientAppRoute,
            },
            children: CLIENT_APP_ROUTES,
          },
        ],
      },
    ],
  },
]

const appRouter = new Router({ routes: routes })

appRouter.beforeEach((_to, _from, next) => {
  store.commit('global/setNavigationIsActive', { value: true })
  next()
})

appRouter.afterEach(() => {
  store.commit('global/setNavigationIsActive', { value: false })
})

appRouter.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.global.isLoggedIn) {
      next({
        path: '/login',
        query: {
          afterLoginUrl: to.fullPath,
        },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

/**
 * The routeGuardian checks if the matches (parent routes
 * and route itself) of the planned route have a beforeEnter
 * function in its meta object. The beforeEnter function must return a value
 * of type Promise<void | Location>. If the promise
 * resolves to void the navigation can continue,
 * if it resolves to a location, a redirect is performed and
 * no further matches are checked
 * @param to
 * @param from
 * @param next
 */
async function routeGuardian(to, from, next) {
  // console.log('routeGuardian before going to:', to)
  try {
    if (to.matched.length === 0) {
      throw new Error(`Requested location could not be found: ${to.fullPath}`)
    }
    // go through all matched routes and call the beforeEnter function if defined
    for (const match of to.matched) {
      if (match.meta.beforeEnter) {
        const res = await match.meta.beforeEnter(to)
        if (res && res !== false) {
          // a redirection was returned
          // console.debug(`route match named '${match.name}' requested a redirect:`, res)
          next(res)
          return
        }
      }
    }
  } catch (rejected) {
    Sentry.captureException(rejected)
    console.warn(rejected)

    next({
      name: 'error',
      replace: true,
      params: {
        errors: JSON.stringify([rejected.message]),
      },
    })
  }

  // must be called if no handler did so before
  // console.log('no redirect happened')
  next()
}

appRouter.beforeEach(routeGuardian)

export default appRouter


import Vue from 'vue'
import Component from 'vue-class-component'
import { brelagSupporterRouteName } from '@/apps/brelag/brelag-supporter/app'

@Component({
  data() {
    return {}
  },
})
export default class KnockautXProgrammers extends Vue {
  get isMainView() {
    return (
      this.$route.name === brelagSupporterRouteName('knockautx-programmers')
    )
  }

  get viewTitle() {
    return this.menuItems.find(
      (item) => item.location.name === this.$route.name
    )?.label
  }

  get menuItems() {
    return [
      {
        label: 'Siegenia Konfigurator',
        description:
          'Konfiguriert die Lüftungsanlage einer Wohneinheit im Siegenia Projekt.',
        icon: 'alpha-s-box',
        location: { name: brelagSupporterRouteName('siegenia-programmer') },
      },
    ]
  }
}

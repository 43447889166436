import { apiClientV2 } from '@/api/ApiClientV2'
import { Module, ActionContext } from 'vuex'
import { Profile } from '@/models/core/profile'
import { TransientBaseObject } from '@/models/core/base'
import { Customer } from '@/apps/brelag/common/models/customer'

export class EventFeedState {
  profile: Map<string, string> = new Map()
  customer: Map<string, Customer> = new Map()
}

export class EventFeedModule<R> implements Module<EventFeedState, R> {
  namespaced = true
  state = new EventFeedState()
  actions = {
    /**
     * get an object by modelClass and id. if it is present in the
     * the store, this item is returned, otherwise
     * the backend is queried through the api
     */
    async getOrFetchObjectById(
      { state }: ActionContext<EventFeedState, R>,
      { modelClass, id }: { modelClass: typeof TransientBaseObject; id: string }
    ) {
      if (id === undefined) {
        return Promise.reject(
          new Error(
            `Value "undefined" is not allowed for "id" for objectType ${modelClass.objectType}`
          )
        )
      }
      let storedObj = state[modelClass.objectType].get(id)
      if (storedObj === undefined) {
        try {
          storedObj = await apiClientV2.get(modelClass, id)
        } catch (error) {
          // Possible that object was deleted
          storedObj = null
        }
      }
      state[modelClass.objectType].set(id, apiClientV2.get(modelClass, id))
      return Promise.resolve(state[modelClass.objectType].get(id))
    },
    async getFullName(
      { state }: ActionContext<EventFeedState, R>,
      { id }: { id: string }
    ) {
      if (id === undefined) {
        return Promise.reject(
          new Error(`Value "undefined" is not allowed for "id" for Profile`)
        )
      }
      let fullName = state.profile.get(id)
      if (fullName === undefined) {
        try {
          apiClientV2.get<Profile>(Profile, id).then((profile) => {
            if (profile) {
              if (profile.first_name && profile.last_name) {
                fullName = `${profile.first_name} ${profile.last_name}`
              } else {
                fullName = profile.username
              }
            }
          })
          await apiClientV2.get<Profile>(Profile, id)
        } catch (error) {
          // Possible that object was deleted
          fullName = null
        }
      }
      return Promise.resolve(state.profile.get(id))
    },
  }
  mutations = {}
}

import {
  EditorModes,
  SvgObjectCollection,
} from '@/apps/brelag/mandator-user/models/editor'
import { FloorPlanEditor } from '@/apps/brelag/mandator-user/project-editor/floorPlanEditor'

export class FloorPlanEditorState {
  zoomLevel: number = 1
  helpLineActive: boolean = false
  mode: {
    mode: EditorModes
    preEnterHook?: (handler: FloorPlanEditor) => void
    preExitHook?: (handler: FloorPlanEditor) => void
  } = {
    mode: null,
  }
  elements: {
    devices: Map<string, SvgObjectCollection>
    annotations: Map<string, SvgObjectCollection>
  } = {
    devices: new Map(),
    annotations: new Map(),
  }
  editorContainer: {
    svg: any
    projectPlanSvg: any
    selectionBox: any
  } = {
    svg: null,
    projectPlanSvg: null,
    selectionBox: null,
  }
}

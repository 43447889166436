import { ClientAppEntry, clientAppRouteName } from '@/apps/clientAppRegistry'

export const VIEW_ID = 'brelag-supporter'

export const CLIENT_APP: ClientAppEntry = {
  name: 'Brelag Supporter',
  path: 'brelag-supporter',
  icon: 'mdi-hexagon-multiple',
  defaultPathName: 'dashboard',
}

export function brelagSupporterRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName)
}

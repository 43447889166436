
import { Component, Vue, Watch } from 'vue-property-decorator'
import { CollectionSubscriber } from '@/api/ApiClient'
import { brelagDeveloperRouteName } from '@/apps/brelag/brelag-developer/app'
import DashboardCollectionTile from '@/apps/brelag/common/components/DashboardCollectionTile.vue'

@Component({
  methods: {
    brelagDeveloperRouteName,
  },
  components: {
    DashboardCollectionTile,
  },
  data() {
    return {
      equipmentGroups: this.$api.subscribeListInComponent(
        this,
        'equipmentgroup',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
      equipments: this.$api.subscribeListInComponent(
        this,
        'equipment',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
    }
  },
})
export default class BrelagSupporterDashboard extends Vue {
  equipmentGroups: CollectionSubscriber
  equipments: CollectionSubscriber

  @Watch('$route.path')
  organisationChanged() {
    if (
      this.$route.query.organisation !==
      this.$store.getters['global/organisation'].id
    ) {
      // We only listen to global organisation changes
      return
    }
    this.equipmentGroups.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
    this.equipments.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import BaseForm from '@/components/common/BaseForm.vue'
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard'

import { ORGANISATION_DEFAULT, Organisation } from '@/models/core/organisation'
import { ClientApp } from '@/models/client/models'
import { clientAppList } from '@/apps/clientAppRegistry'
import ClientAppSettings from './ClientAppSettings.vue'

@Component({
  name: 'client-app-form',
  components: {
    BaseForm,
    ClientAppSettings,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      clientApp: ClientApp,
    }
  },
  methods: {
    clientAppList,
  },
  mixins: [BeforeLeaveGuard],
})
export default class ClientAppForm extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  clientApp: ClientApp
  loading: boolean = false
  organisation: Organisation = JSON.parse(JSON.stringify(ORGANISATION_DEFAULT))

  mounted() {
    this.loading = true
    this.$api
      .get('client-app', this.$props.id)
      .then((response) => {
        this.clientApp = response
        this.loading = false
        return this.clientApp.organisation
      })
      .then((response) => {
        let orgId = ''
        if (this.$props.id === '0') {
          orgId = this.$store.getters['global/object']('organisation').id
        } else {
          orgId = response
        }
        this.$api.get('organisation', orgId).then((organisation) => {
          this.organisation = organisation
        })
      })
  }

  get completedLocation() {
    if (this.$route.name === 'bootstrap-client-app-create') {
      return {
        name: 'organisation',
      }
    } else {
      return null
    }
  }

  get isNew() {
    return this.$props.id === '0'
  }

  get transformedClientApp() {
    this.clientApp.organisation = this.organisation.id
    return this.clientApp
  }
}

import Vue from 'vue'
import { Collection } from '@/api/ApiClientV2'
import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base'
import { FormFieldType } from '@/components/common/forms/formBuilderHelper'

export class Customer extends TransientBaseObject {
  organisation: string
  name?: string
  number?: string
  prettty_name: string // This is combination of name&number, whichever is defined
  meta?: {
    address: string
    zip: string
    city: string
    country: string
    contact_person: string
    email: string
    tel_central?: string
    tel_mobile?: string
    tel_direct?: string
  }

  static apiUrl = 'brelag/customer'
  static langPath: string = 'apps.brelag.models.customer'
  static objectType: string = 'customer'
  static listFields: ListModelField[] = [
    { key: 'number', searchQuery: 'number_search', sortable: true },
    { key: 'name', searchQuery: 'name_search', sortable: true },
    { key: 'meta.address' },
    { key: 'meta.city' },
    { key: 'meta.email' },
    { key: 'meta.contact_person' },
  ]
  static fields: ModelField[] = [
    {
      key: 'number',
      required: false,
    },
    {
      key: 'name',
      required: false,
    },
    {
      key: 'meta.address',
      required: false,
    },
    {
      key: 'meta.zip',
      required: false,
    },
    {
      key: 'meta.city',
      required: false,
    },
    {
      key: 'meta.country',
      formFieldType: FormFieldType.SELECTION,
      required: false,
      formProperties: {
        options: [
          { text: 'Schweiz', value: 'ch' },
          { text: 'Deutschland', value: 'de' },
          { text: 'Österreich', value: 'au' },
        ],
      },
    },
    {
      key: 'meta.contact_person',
      icon: 'mdi-account-card-details',
      required: false,
    },
    {
      key: 'meta.email',
      required: false,
    },
    {
      key: 'meta.tel_central',
      required: false,
    },
    {
      key: 'meta.tel_mobile',
      required: false,
    },
    {
      key: 'meta.tel_direct',
      required: false,
    },
  ]

  static collection(
    vm: Vue,
    filter: Collection.Filter,
    pagination: Collection.Pagination
  ) {
    return vm.$apiv2.subscribe<Customer, Customer>(vm, this, filter, pagination)
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...CUSTOMER_DEFAULT },
    }
  }

  static get defaultModel() {
    return JSON.parse(JSON.stringify(CUSTOMER_DEFAULT))
  }
}

export const CUSTOMER_DEFAULT: Customer = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  name: '',
  number: '',
  prettty_name: '',
  meta: {
    address: '',
    zip: '',
    city: '',
    country: '',
    contact_person: '',
    tel_central: '',
    tel_mobile: '',
    tel_direct: '',
    email: '',
  },
}

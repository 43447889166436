// modules
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import axios, { AxiosRequestConfig } from 'axios'
import { CookieStorage } from 'cookie-storage'
import { ActionContext, Module } from 'vuex'

// common
import {
  apiClient,
  CollectionSubscriber,
  CollectionFilter,
  CollectionPagination,
} from '@/api/ApiClient'
import { apiClientV2 } from '@/api/ApiClientV2'
import store, { initialStateCopy } from './index'
import { ClientApp } from '@/models/client/models'
import isEmpty from 'lodash.isempty'
import { Organisation } from '@/models/core/organisation'
import { Profile, PROFILE_DEFAULT } from '@/models/core/profile'
import { getStoreName, getModelClass } from '@/models/objectRegistry'
import { isMobile, simpleCopy } from '@/util/util'

export const frontendVersion = '4.6.1'

export interface CommonContext {
  selection: {
    organisation: Organisation | null
    'client-app': ClientApp | null
  }

  organisations: CollectionSubscriber
  applications: CollectionSubscriber
  'client-apps': CollectionSubscriber
  groups: CollectionSubscriber
  roles: CollectionSubscriber
  'object-authorizations': CollectionSubscriber
  'event-logs': CollectionSubscriber
}

const isMobileInit = isMobile()
document.body.classList.toggle('reader-mode', isMobileInit)
const registrationEnabled = false

const defaultAxiosConfig: AxiosRequestConfig = {
  headers: {
    'X-CSRFTOKEN': '',
  },
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
}

export class GlobalState {
  navigationIsActive: boolean = false // TODO: -> move in navigation -> isActive
  navigation: {
    isCollapsed: boolean
    selectionIsCollapsed: boolean
    readerModeEnabled: boolean
    navbarHidden: boolean
    hideClientAppsMenu: boolean
  } = {
    isCollapsed: isMobileInit,
    selectionIsCollapsed: isMobileInit,
    readerModeEnabled: isMobileInit,
    navbarHidden: false,
    hideClientAppsMenu: false,
  }

  profileId: string = ''
  profile: Profile = simpleCopy(PROFILE_DEFAULT)
  backendVersion: string = ''
  frontendVersion: string = frontendVersion
  brelagEnabled: boolean = false
  usernameSameAsEmail: boolean = null
  isStaff: boolean = false
  isLoggedIn: boolean = false
  registrationEnabled: boolean = registrationEnabled
  isDebug: boolean = true
  csrfToken: string = ''
  axiosConfig: AxiosRequestConfig = simpleCopy(defaultAxiosConfig)
  context: CommonContext = {
    selection: {
      organisation: null,
      'client-app': null,
    },
    organisations: null,
    applications: null,
    'client-apps': null,
    groups: null,
    roles: null,
    'object-authorizations': null,
    'event-logs': null,
  }
}

export class GlobalModule<R> implements Module<GlobalState, R> {
  namespaced = true
  state = new GlobalState()

  getters = {
    usernameSameAsEmail: (state) => state.usernameSameAsEmail,
    navigationIsCollapsed: (state) => state.navigation.isCollapsed,
    selectionIsCollapsed: (state) => state.navigation.selectionIsCollapsed,
    navbarHidden: (state) => state.navigation.navbarHidden,
    isLoggedIn: (state) => state.isLoggedIn,
    registrationEnabled: (state) => state.registrationEnabled,
    readerModeEnabled: (state) => state.navigation.readerModeEnabled,
    hideClientAppsMenu: (state) => state.navigation.hideClientAppsMenu,
    navigationIsActive: (state) => state.navigationIsActive,
    profileOrganisationId: (state) => state.profile.organisation,
    profileId: (state) => state.profileId,
    selectedClientApp: (state) => state.context.selection['client-app'],
    contextOrganisations: (state) => state.context.organisations,

    // generic getters
    objectByProperty:
      (state, getters, rootState) =>
      (objectType: string, property: string, value: string) => {
        const storeModule = getStoreName(objectType)
        return (
          rootState[storeModule].context[`${objectType}s`] &&
          rootState[storeModule].context[`${objectType}s`].objects.find(
            (obj) => obj[property] === value
          )
        )
      },

    objectById: (state, getters) => (objectType: string, id: string) => {
      return getters.objectByProperty(objectType, id)
    },

    /**
     * Return a map of a collection with ids as keys and object properties as values
     */
    collectionMap:
      (state, getters) => (objectType: string, property: string) => {
        const lookup = new Map<string, any>()
        for (const obj of getters.collection(objectType).objects) {
          lookup.set(obj.id, obj[property])
        }
        return lookup
      },

    object: (state, getters, rootState) => (objectType: string) => {
      const storeModule = getStoreName(objectType)
      return rootState[storeModule].context.selection[objectType]
    },

    /**
     * Generate a map containing all ids of given objectTypes
     * @param objectTypes List of objectTypes to process
     */
    idsFromContext:
      (state, getters) =>
      (objectTypes: string[]): Map<string, string> => {
        const ids = new Map()
        for (const objectType of objectTypes) {
          const obj = getters.object(objectType)
          if (obj) {
            ids.set(objectType, obj.id)
          }
        }
        return ids
      },

    collection: (state, getters, rootState) => (objectType: string) => {
      const storeModule = getStoreName(objectType)
      return rootState[storeModule].context[`${objectType}s`]
    },

    organisation: (state) => state.context.selection.organisation,
  }

  actions = {
    async initialize(
      {}: ActionContext<GlobalState, R>,
      { router }: { router }
    ): Promise<void> {
      const response = await axios.get('/api/v1/auth/info')
      if (response.data.sentry_dsn) {
        const enableSentryDebug =
          response.data.environment === 'dev' ||
          response.data.environment === 'staging'
        enableSentryDebug &&
          console.info(
            `Enabling Sentry debug in environment '${response.data.environment}'`
          )
        Sentry.init({
          dsn: response.data.sentry_dsn,
          environment: response.data.environment,
          debug: enableSentryDebug,
          release: process.env.RELEASE,
          integrations: [
            new BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
          ],
        })
      }
      store.commit('global/seedProfileInfo', response.data)
      const promises = []
      if (response.data.id) {
        promises.push(
          axios
            .get('/api/v1/profile/' + response.data.id + '/')
            .then((profileResponse) => {
              store.commit('global/setProfile', profileResponse.data)
            })
            .catch((error) => {
              console.warn(error)
            })
        )
      }
      promises.push(
        axios
          .get('/api/v1/version/info')
          .then((infoResponse) => {
            if (infoResponse.data.version) {
              store.commit(
                'global/setBackendVersion',
                infoResponse.data.version
              )
            }
          })
          .catch((error_1) => {
            console.warn(error_1)
          })
      )
      await Promise.all(promises)
      return
    },
    updateContextFilter(
      { commit, rootState }: ActionContext<GlobalState, R>,
      {
        objectType,
        filter,
        pagination,
      }: {
        objectType: string
        filter: CollectionFilter | null
        pagination: CollectionPagination | null
      }
    ): Promise<void> {
      const suffix = objectType.endsWith('s') ? 'es' : 's'
      const contextProperty = objectType + suffix
      const storeName = getStoreName(objectType)
      const moduleState = rootState[storeName]
      let modelClass
      try {
        modelClass = getModelClass(objectType)
      } catch (error) {
        // This is OK, it's an old model
      }

      if (pagination === undefined || isEmpty(pagination)) {
        if (modelClass) {
          pagination = modelClass.defaultPagination
        }
      }

      // check if we have already set a subscriber. if not, create it
      if (moduleState.context[contextProperty] === undefined) {
        throw new Error(
          `Context property ${contextProperty} is not defined in the store. If it is an objectType, check the 'store' property in the object registry.`
        )
      } else if (moduleState.context[contextProperty] === null) {
        let sub
        if (modelClass && modelClass.useApiClientV2) {
          sub = apiClientV2.subscribe(null, modelClass, null, pagination)
        } else {
          sub = apiClient.subscribeList(objectType, null, pagination)
        }
        commit(
          `${storeName}/setContextSubscribers`,
          { [objectType]: sub },
          { root: true }
        )
      }
      return moduleState.context[contextProperty].status.readyPromise.then(
        () => {
          const filterChanged =
            JSON.stringify(moduleState.context[contextProperty].filter) !==
            JSON.stringify(filter)
          const paginationChanged =
            JSON.stringify(moduleState.context[contextProperty].pagination) !==
            JSON.stringify(pagination || {})
          if (filterChanged) {
            // console.log(contextProperty, 'filter changed:', filter)
            moduleState.context[contextProperty].filter = filter
          }
          if (paginationChanged) {
            // console.log(contextProperty, 'pagination changed:', pagination)
            moduleState.context[contextProperty].pagination = pagination || {}
          }
          if (filterChanged || paginationChanged) {
            return moduleState.context[contextProperty]
              .refresh()
              .then((success: boolean) => {
                if (success) {
                  return
                } else {
                  console.warn(`Could not update filter of ${contextProperty}`)
                }
              })
          } else {
            return
          }
        }
      )
    },
    loginUser(
      {}: ActionContext<GlobalState, R>,
      { data }: { data }
    ): Promise<void> {
      // Fetch avatar
      return axios
        .get('/api/v1/profile/' + data.id + '/')
        .then((profile) => {
          store.commit('global/seedProfileInfo', data)
          store.commit('global/setProfile', profile.data)
        })
        .catch((error) => {
          console.warn(error)
        })
    },
    logoutUser(): void {
      return store.commit({
        type: `global/logoutUser`,
      })
    },

    /**
     * get an object by objectType and id. if it is present in the
     * collection of the store, this item is returned, otherwise
     * the backend is queried through the api
     */
    getOrFetchObjectById(
      { getters }: ActionContext<GlobalState, R>,
      { objectType, id }: { objectType: string; id: string }
    ) {
      if (id === undefined) {
        return Promise.reject(
          new Error(
            `Value "undefined" is not allowed for "id" for objectType ${objectType}`
          )
        )
      }
      const objFromStore = getters.objectByProperty(objectType, 'id', id)
      if (objFromStore) {
        return Promise.resolve(objFromStore)
      } else {
        const modelClass = getModelClass(objectType)
        if (modelClass && modelClass.useApiClientV2) {
          return apiClientV2.get(modelClass, id)
        } else {
          return apiClient.get(objectType, id)
        }
      }
    },

    /**
     * set the context object by objectType and id
     */
    selectObjectById(
      { commit, dispatch, rootState }: ActionContext<GlobalState, R>,
      { objectType, id }: { objectType: string; id: string }
    ): Promise<void> {
      const storeName = getStoreName(objectType)
      const moduleState = rootState[storeName]

      if (id) {
        return dispatch('getOrFetchObjectById', { objectType, id })
          .then((object) => {
            if (moduleState.context.selection[objectType] === undefined) {
              throw new Error(
                `Object type ${objectType} is not defined in the store. Check the 'store' property in the object registry.`
              )
            } else if (
              moduleState.context.selection[objectType] === null ||
              moduleState.context.selection[objectType].id !== object.id
            ) {
              commit(
                `${storeName}/setContextSelection`,
                {
                  objectType,
                  object,
                },
                { root: true }
              )
            }
            return
          })
          .catch((error) => {
            if (error.response.status === 404) {
              // This object does not exist, remove it from selection
              console.warn(
                `Object of type ${objectType} with id ${id} not exist`
              )
              return dispatch('deselectObjectType', { objectType })
            } else {
              return Promise.reject(error)
            }
          })
      } else {
        return dispatch('deselectObjectType', { objectType })
      }
    },
    deselectObjectType(
      { commit, rootState }: ActionContext<GlobalState, R>,
      { objectType, id }: { objectType: string; id?: string }
    ): void {
      const storeName = getStoreName(objectType)
      if (id !== undefined) {
        // Check if this id is currently in selection
        const moduleState = rootState[storeName]
        if (moduleState.context.selection[objectType]) {
          if (moduleState.context.selection[objectType].id !== id) {
            // This object is not currently in state, nothing to do
            return
          }
        } else {
          return
        }
      }
      commit(
        `${storeName}/setContextSelection`,
        {
          objectType,
          object: null,
        },
        { root: true }
      )
    },
    findObjectByProperty(
      { state }: ActionContext<GlobalState, R>,
      {
        objectType,
        property,
        value,
      }: { objectType: string; property: string; value: string }
    ): Promise<Organisation> {
      const object = state.context[`${objectType}s`].objects.find(
        (obj) => obj[property] === value
      )
      if (object) {
        return Promise.resolve(object)
      } else {
        return Promise.reject(
          new Error(`${objectType} with '${property} = ${value}' was not found`)
        )
      }
    },

    // find in object list in context, then set
    selectContextObjectByProperty(
      { commit, dispatch, state }: ActionContext<GlobalState, R>,
      {
        objectType,
        property,
        value,
      }: { objectType: string; property: string; value: string }
    ): Promise<void> {
      if (value) {
        return dispatch('findObjectByProperty', {
          objectType,
          property,
          value,
        })
          .then((object) => {
            if (
              state.context.selection[objectType] === null ||
              state.context.selection[objectType].id !== object.id
            ) {
              commit('setContextSelection', {
                objectType,
                object,
              })
            }
            return
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              // This object does not exist, remove it from selection
              console.warn(
                `Object of type ${objectType} with property ${property} not exist`
              )
              return dispatch('deselectObjectType', { objectType })
            } else {
              return Promise.reject(error)
            }
          })
      } else {
        return dispatch('deselectObjectType', { objectType })
      }
    },

    getFirstClientApp({ state }: ActionContext<GlobalState, R>) {
      const clientApp = state.context['client-apps'].objects[0]
      if (clientApp) {
        return Promise.resolve(clientApp)
      } else {
        return Promise.reject(new Error('Could not get first client app'))
      }
    },

    setNavigationCollapsed(
      { commit }: ActionContext<GlobalState, R>,
      { value }: { value: boolean }
    ): void {
      return commit('setNavigationCollapsed', {
        value,
      })
    },

    setSelectionCollapsed(
      { commit }: ActionContext<GlobalState, R>,
      { value }: { value: boolean }
    ): void {
      return commit('setSelectionCollapsed', {
        value,
      })
    },

    setReaderMode(
      { commit }: ActionContext<GlobalState, R>,
      { value }: { value: boolean }
    ): void {
      return commit('setReaderMode', {
        value,
      })
    },

    setNavbarHidden(
      { commit }: ActionContext<GlobalState, R>,
      { value }: { value: boolean }
    ): void {
      return commit('setNavbarHidden', {
        value,
      })
    },
  }

  mutations = {
    setNavigationIsActive(
      state: GlobalState,
      { value }: { value: boolean }
    ): void {
      state.navigationIsActive = value
    },
    setNavbarHidden(state: GlobalState, { value }: { value: boolean }): void {
      state.navigation.navbarHidden = value
    },
    setNavigationCollapsed(
      state: GlobalState,
      { value }: { value: boolean }
    ): void {
      state.navigation.isCollapsed = value
    },
    setSelectionCollapsed(
      state: GlobalState,
      { value }: { value: boolean }
    ): void {
      state.navigation.selectionIsCollapsed = value
    },
    setReaderMode(state: GlobalState, { value }: { value: boolean }): void {
      state.navigation.readerModeEnabled = value
      document.body.classList.toggle('reader-mode', value)
      state.navigation.isCollapsed = true
    },
    setHideClientAppsMenu(
      state: GlobalState,
      { value }: { value: boolean }
    ): void {
      document.body.classList.toggle('hide-client-apps', value)
      state.navigation.hideClientAppsMenu = value
    },
    setContextSubscribers(
      state: GlobalState,
      subs: { [key: string]: CollectionSubscriber }
    ): void {
      Object.keys(subs).forEach((key) => {
        state.context[key + 's'] = subs[key]
      })
    },
    setContextSelection(state: GlobalState, { objectType, object }): void {
      state.context.selection[objectType] = object
    },
    clearContextSelection(state: GlobalState): void {
      // clear context selection except for organisation and client app
      const organisation = state.context.selection.organisation
      const clientApp = state.context.selection['client-app']
      state.context.selection = {
        ...initialStateCopy.global.context.selection,
        organisation,
        'client-app': clientApp,
      }
    },
    seedProfileInfo(state: GlobalState, profileInfo): void {
      state.profileId = profileInfo.id
      state.isLoggedIn = profileInfo.authenticated
      state.isStaff = profileInfo.is_staff
      if (profileInfo.csrfToken === undefined) {
        const cookieStorage = new CookieStorage()
        profileInfo.csrfToken = cookieStorage.getItem('csrftoken')
      }
      state.csrfToken = profileInfo.csrfToken
      state.axiosConfig.headers['X-CSRFTOKEN'] = profileInfo.csrfToken
    },
    setProfile(state: GlobalState, profile): void {
      state.profile = profile
    },
    setBackendVersion(state: GlobalState, version: string): void {
      state.backendVersion = version
    },
    setBrelagEnabled(state: GlobalState, enabled: boolean): void {
      Vue.set(state, 'brelagEnabled', enabled)
      Vue.set(state, 'usernameSameAsEmail', enabled)
    },
    setFrontendVersion(state: GlobalState, version: string): void {
      state.frontendVersion = version
    },
    setRegistrationEnabled(state: GlobalState, value: boolean): void {
      state.registrationEnabled = value
    },
    logoutUser(): void {
      // TODO: Would be nice if this could be specified and not be done manually
      const usernameSameAsEmail = store.state.global.usernameSameAsEmail
      const brelagEnabled = store.state.global.brelagEnabled
      store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
      store.state.global.usernameSameAsEmail = usernameSameAsEmail
      store.state.global.brelagEnabled = brelagEnabled
      apiClient.unsubscribeAll()
    },
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator'
import { CollectionSubscriber } from '@/api/ApiClient'
import { brelagSupporterRouteName } from '@/apps/brelag/brelag-supporter/app'
import DashboardCollectionTile from '@/apps/brelag/common/components/DashboardCollectionTile.vue'

@Component({
  methods: {
    brelagSupporterRouteName,
  },
  components: {
    DashboardCollectionTile,
  },
  data() {
    return {
      mandators: this.$api.subscribeListInComponent(
        this,
        'organisation',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
      admins: this.$api.subscribeListInComponent(
        this,
        'profile',
        {
          organisation: this.$store.getters['global/organisation'].id,
        },
        {
          page: 1,
          pageSize: 1,
        }
      ),
    }
  },
})
export default class BrelagSupporterDashboard extends Vue {
  mandators: CollectionSubscriber
  admins: CollectionSubscriber

  @Watch('$route.path')
  organisationChanged() {
    if (
      this.$route.query.organisation !==
      this.$store.getters['global/organisation'].id
    ) {
      // We only listen to global organisation changes
      return
    }
    this.mandators.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
    this.admins.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    })
  }
}

interface Block {
  height: number
  width: number
  button?: number
}

export interface MaxFlexLayout {
  name: string
  value: number
  numKeys: number
  blocks?: Block[]
}

export const MaxFlexButtonFunction = [
  {
    name: 'NONE',
    value: 0,
  },
  {
    name: 'AUF',
    value: 1,
  },
  {
    name: 'AB',
    value: 2,
  },
  {
    name: 'TOGGLE',
    value: 3,
  },
  {
    name: 'SZENE',
    value: 4,
  },
  {
    name: 'JOKER',
    value: 5,
  },
]

export const MaxFlexJokerButtonCommands = [
  {
    name: 'kein',
    value: 0,
  },
  {
    name: 'AUF Impuls',
    value: 1,
  },
  {
    name: 'AB Impuls',
    value: 2,
  },
  {
    name: 'AUF konstant',
    value: 3,
  },
  {
    name: 'AB konstant',
    value: 4,
  },
  {
    name: 'AB2 konstant',
    value: 5,
  },
  {
    name: 'Position abrufen',
    value: 7,
  },
  {
    name: 'Position speichern',
    value: 14,
  },
  {
    name: 'Letzter Lichtwert',
    value: 13,
  },
  {
    name: 'Grundlicht',
    value: 15,
  },
  {
    name: 'Stopp',
    value: 8,
  },
  {
    name: 'Toggle',
    value: 9,
  },
  {
    name: 'Sperren',
    value: 10,
  },
  {
    name: 'Entsperren',
    value: 11,
  },
  {
    name: 'Click-Off Befehl verwerfen',
    value: 12,
  },
]

export interface MaxFlexKeyConfig {
  /**
   * Channel of the key
   * Value: [0..7]
   */
  Channel: {
    value: number
  }
  /**
   * 0: None No command
   * 1: UP Up command group
   * 2: DOWN Down command group
   * 3: TOG Toggle command group
   * 4: SZE Command group
   * 5: JKR Joker commands
   */
  CommandGrp: {
    value: number
  }
  /**
   * 1st command number if CommandGrp=JKR
   * Values: [0..255]
   */
  Command1: {
    value: number
  }
  /**
   * [0.1s]: Time after which 1st command gets sent
   * Values: [0...60000]
   */
  Time1: {
    value: number
  }
  /**
   * 0: keep State of signalisation LED doesn’t change
   * 1: set State of signalisation LED gets enabled
   * 2: reset State of signalisation LED gets reset
   */
  Indication1: {
    value: number
  }
  // see Command1
  Command2: {
    value: number
  }
  // see Time1
  Time2: {
    value: number
  }
  // see Indication1
  Indication2: {
    value: number
  }
  // see Command 1
  CommandRls: {
    value: number
  }
  // see Time1
  TimeRls: {
    value: number
  }
  // see Indication1
  IndicationRls: {
    value: number
  }
  // see Time1
  DelayRls: {
    value: number
  }
  /**
   * Command priority
   * Values: [0..3]
   */
  Priority: {
    value: number
  }
  Dummy1: {
    value: 0
  }
  Dummy2: {
    value: 0
  }
  Dummy3: {
    value: 0
  }
  Dummy4: {
    value: 0
  }
  Dummy5: {
    value: 0
  }
  Dummy6: {
    value: 0
  }
  Dummy7: {
    value: 0
  }
  Icon: {
    value: string
  }
  Text: {
    value: string
  }
}

export const getDataIdMaxflexConfig = function (config: MaxFlexConfig): {
  dataID: number
  value: number
}[] {
  const dataList = []
  dataList.push({
    dataID: 1,
    value: config.PasswordOld1.value,
  })
  dataList.push({
    dataID: 2,
    value: config.PasswordOld2.value,
  })
  dataList.push({
    dataID: 3,
    value: config.PasswordOld3.value,
  })
  dataList.push({
    dataID: 4,
    value: config.PasswordOld4.value,
  })
  dataList.push({
    dataID: 5,
    value: config.PasswordOld5.value,
  })
  dataList.push({
    dataID: 6,
    value: config.PasswordOld6.value,
  })
  dataList.push({
    dataID: 7,
    value: config.PasswordNew1.value,
  })
  dataList.push({
    dataID: 8,
    value: config.PasswordNew2.value,
  })
  dataList.push({
    dataID: 9,
    value: config.PasswordNew3.value,
  })
  dataList.push({
    dataID: 10,
    value: config.PasswordNew4.value,
  })
  dataList.push({
    dataID: 11,
    value: config.PasswordNew5.value,
  })
  dataList.push({
    dataID: 12,
    value: config.PasswordNew6.value,
  })
  dataList.push({
    dataID: 13,
    value: config.KeyLayout.value,
  })
  dataList.push({
    dataID: 14,
    value: config.FeedbackIntensity.value,
  })
  dataList.push({
    dataID: 15,
    value: config.LocatingKey.value,
  })
  dataList.push({
    dataID: 16,
    value: config.LocatingPeriod.value,
  })
  dataList.push({
    dataID: 17,
    value: config.LocatingIntensity.value,
  })
  dataList.push({
    dataID: 18,
    value: config.Bitfield.value,
  })
  dataList.push({
    dataID: 19,
    value: config.TxType.value,
  })
  dataList.push({
    dataID: 20,
    value: config.TxAddress1.value,
  })
  dataList.push({
    dataID: 21,
    value: config.TxAddress2.value,
  })
  dataList.push({
    dataID: 22,
    value: config.TxAddress3.value,
  })
  dataList.push({
    dataID: 23,
    value: config.TxAddress4.value,
  })
  dataList.push({
    dataID: 24,
    value: config.TxAddress5.value,
  })
  dataList.push({
    dataID: 25,
    value: config.TxAddress6.value,
  })
  // Dummy, not used yet
  for (let i = 26; i < 37; i++) {
    dataList.push({
      dataID: i,
      value: 0,
    })
  }
  let i = 0
  for (const key of config.keys) {
    const offset = i * 20
    dataList.push({
      dataID: 37 + offset,
      value: key.Channel.value,
    })
    dataList.push({
      dataID: 38 + offset,
      value: key.CommandGrp.value,
    })
    dataList.push({
      dataID: 39 + offset,
      value: key.Command1.value,
    })
    dataList.push({
      dataID: 40 + offset,
      value: key.Time1.value,
    })
    dataList.push({
      dataID: 41 + offset,
      value: key.Indication1.value,
    })
    dataList.push({
      dataID: 42 + offset,
      value: key.Command2.value,
    })
    dataList.push({
      dataID: 43 + offset,
      value: key.Time2.value,
    })
    dataList.push({
      dataID: 44 + offset,
      value: key.Indication2.value,
    })
    dataList.push({
      dataID: 45 + offset,
      value: key.CommandRls.value,
    })
    dataList.push({
      dataID: 46 + offset,
      value: key.TimeRls.value,
    })
    dataList.push({
      dataID: 47 + offset,
      value: key.IndicationRls.value,
    })
    dataList.push({
      dataID: 48 + offset,
      value: key.DelayRls.value,
    })
    dataList.push({
      dataID: 49 + offset,
      value: key.Priority.value,
    })
    dataList.push({
      dataID: 50 + offset,
      value: 0,
    })
    dataList.push({
      dataID: 51 + offset,
      value: 0,
    })
    dataList.push({
      dataID: 52 + offset,
      value: 0,
    })
    dataList.push({
      dataID: 53 + offset,
      value: 0,
    })
    dataList.push({
      dataID: 54 + offset,
      value: 0,
    })
    dataList.push({
      dataID: 55 + offset,
      value: 0,
    })
    dataList.push({
      dataID: 56 + offset,
      value: 0,
    })
    i++
  }
  return dataList
}

export interface MaxFlexConfig {
  /**
   * 6 digit password in the range of ‚0‘…‘8‘ [ASCII]. Contains value 0 if no password used.
   */
  PasswordOld1: {
    value: number
  }
  PasswordOld2: {
    value: number
  }
  PasswordOld3: {
    value: number
  }
  PasswordOld4: {
    value: number
  }
  PasswordOld5: {
    value: number
  }
  PasswordOld6: {
    value: number
  }
  PasswordNew1: {
    value: number
  }
  PasswordNew2: {
    value: number
  }
  PasswordNew3: {
    value: number
  }
  PasswordNew4: {
    value: number
  }
  PasswordNew5: {
    value: number
  }
  PasswordNew6: {
    value: number
  }
  /**
   * Bitfield defining the layout of the keys. LSB=key1, MSB=key8.
      0: invisible signalisation LED
      1: visible signalisation LED
      (e.g.: If key 1, 2, 3 and 4 are one group, key 5 and 6 are one group and 7 and 8 are one group, this value would be 0b01010100). See "3: Possible key layouts" for further info.

      Values: [64…255]
   */
  KeyLayout: {
    value: number
  }
  /**
   * Defines the intensity of the key feedback function. If 0, no key feedback happens.
   * Values: [0...100] %
   */
  FeedbackIntensity: {
    value: number
  }
  /**
   * Bitwise definition of the locating key.
      0x00 = No locating key
      0x01 = Key1 is locating key
      0x02 = Key2 is locating key
      0x04 = Key3 is locating key

      Values: [0…128]
   */
  LocatingKey: {
    value: number
  }
  /**
   * Time [0.1s]
      If battery powered or „locating function if external powered“ is set to flashing, this value defines the time between locating flashes.
      Values: [20…250]
   */
  LocatingPeriod: {
    value: number
  }
  /**
   * Defines the intensity of the locating function.
   * Values: [0..100]  [%]
   */
  LocatingIntensity: {
    value: number
  }
  /**
   * 0: Locating function if external powered flashing
   * 1: Locating function if external powered persistent
   * Bit 0
   */
  Bitfield: {
    value: number
  }
  /**
   * 0: Default Transmitter uses default address
   * 1: Master Transmitter uses user editable master address
   * 2: MT Transmitter uses user editable MT address
   * 3: SinglePlus Transmitter uses user editable single address
   * 4: MultiPlus Transmitter uses user editable multi address
   */
  TxType: {
    value: number
  }
  /**
   * If TxType is not set to default, these bytes define the address of the transmitter.
   * Values: 6 x [1…8]
   */
  TxAddress1: {
    value: number
  }
  TxAddress2: {
    value: number
  }
  TxAddress3: {
    value: number
  }
  TxAddress4: {
    value: number
  }
  TxAddress5: {
    value: number
  }
  TxAddress6: {
    value: number
  }
  keys: MaxFlexKeyConfig[]
  updateLinked?: boolean // will not be sent to backend
}

export const transformLegacyImportMaxFlexConfig = function (
  config: any
): MaxFlexConfig {
  const keys = []
  for (const key of config.keys) {
    keys.push({
      Channel: {
        value: key.Channel,
      },
      CommandGrp: {
        value: key.CommandGrp,
      },
      Command1: {
        value: key.Command1,
      },
      Time1: {
        value: key.Time1,
      },
      Indication1: {
        value: key.Indication1,
      },
      Command2: {
        value: key.Command2,
      },
      Time2: {
        value: key.Time2,
      },
      Indication2: {
        value: key.Indication2,
      },
      CommandRls: {
        value: key.CommandRls,
      },
      TimeRls: {
        value: key.TimeRls,
      },
      IndicationRls: {
        value: key.IndicationRls,
      },
      DelayRls: {
        value: key.DelayRls,
      },
      Priority: {
        value: key.Priority,
      },
      Dummy1: {
        value: key.Dummy1,
      },
      Dummy2: {
        value: key.Dummy2,
      },
      Dummy3: {
        value: key.Dummy3,
      },
      Dummy4: {
        value: key.Dummy4,
      },
      Dummy5: {
        value: key.Dummy5,
      },
      Dummy6: {
        value: key.Dummy6,
      },
      Dummy7: {
        value: key.Dummy7,
      },
      Icon: {
        value: '',
      },
      Text: {
        value: '',
      },
    })
  }
  const parsedConfig: MaxFlexConfig = {
    Bitfield: {
      value: config.Bitfield,
    },
    FeedbackIntensity: {
      value: config.FeedbackIntensity,
    },
    KeyLayout: {
      value: config.KeyLayout,
    },
    LocatingIntensity: {
      value: config.LocatingIntensity,
    },
    LocatingKey: {
      value: config.LocatingKey,
    },
    LocatingPeriod: {
      value: config.LocatingPeriod,
    },
    PasswordNew1: {
      value: config.PasswordNew1,
    },
    PasswordNew2: {
      value: config.PasswordNew2,
    },
    PasswordNew3: {
      value: config.PasswordNew3,
    },
    PasswordNew4: {
      value: config.PasswordNew4,
    },
    PasswordNew5: {
      value: config.PasswordNew5,
    },
    PasswordNew6: {
      value: config.PasswordNew6,
    },
    PasswordOld1: {
      value: config.PasswordOld1,
    },
    PasswordOld2: {
      value: config.PasswordOld2,
    },
    PasswordOld3: {
      value: config.PasswordOld3,
    },
    PasswordOld4: {
      value: config.PasswordOld4,
    },
    PasswordOld5: {
      value: config.PasswordOld5,
    },
    PasswordOld6: {
      value: config.PasswordOld6,
    },
    TxAddress1: {
      value: config.TxAddress1,
    },
    TxAddress2: {
      value: config.TxAddress2,
    },
    TxAddress3: {
      value: config.TxAddress3,
    },
    TxAddress4: {
      value: config.TxAddress4,
    },
    TxAddress5: {
      value: config.TxAddress5,
    },
    TxAddress6: {
      value: config.TxAddress6,
    },
    TxType: {
      value: config.TxType,
    },
    keys,
  }
  return parsedConfig
}

function createMaxflexKeyConfig(): MaxFlexKeyConfig[] {
  const keys: MaxFlexKeyConfig[] = []
  for (let i = 0; i < 8; i++) {
    const offset = i * 20
    keys.push({
      Channel: {
        value: 0,
      },
      CommandGrp: {
        value: 0,
      },
      Command1: {
        value: 0,
      },
      Time1: {
        value: 0,
      },
      Indication1: {
        value: 0,
      },
      Command2: {
        value: 0,
      },
      Time2: {
        value: 0,
      },
      Indication2: {
        value: 0,
      },
      CommandRls: {
        value: 0,
      },
      TimeRls: {
        value: 0,
      },
      IndicationRls: {
        value: 0,
      },
      DelayRls: {
        value: 0,
      },
      Priority: {
        value: 0,
      },
      Dummy1: {
        value: 0,
      },
      Dummy2: {
        value: 0,
      },
      Dummy3: {
        value: 0,
      },
      Dummy4: {
        value: 0,
      },
      Dummy5: {
        value: 0,
      },
      Dummy6: {
        value: 0,
      },
      Dummy7: {
        value: 0,
      },
      Icon: {
        value: '',
      },
      Text: {
        value: '',
      },
    })
  }
  return keys
}

export const MAXFLEX_DEFAULT_CONFIG: MaxFlexConfig = {
  PasswordOld1: {
    value: 0,
  },
  PasswordOld2: {
    value: 0,
  },
  PasswordOld3: {
    value: 0,
  },
  PasswordOld4: {
    value: 0,
  },
  PasswordOld5: {
    value: 0,
  },
  PasswordOld6: {
    value: 0,
  },
  PasswordNew1: {
    value: 48,
  },
  PasswordNew2: {
    value: 48,
  },
  PasswordNew3: {
    value: 48,
  },
  PasswordNew4: {
    value: 48,
  },
  PasswordNew5: {
    value: 48,
  },
  PasswordNew6: {
    value: 48,
  },
  KeyLayout: {
    value: 0x40,
  },
  FeedbackIntensity: {
    value: 100,
  },
  LocatingKey: {
    value: 0x00,
  },
  LocatingPeriod: {
    value: 20,
  },
  LocatingIntensity: {
    value: 0,
  },
  Bitfield: {
    value: 0,
  },
  TxType: {
    value: 0,
  },
  TxAddress1: {
    value: 1,
  },
  TxAddress2: {
    value: 1,
  },
  TxAddress3: {
    value: 1,
  },
  TxAddress4: {
    value: 1,
  },
  TxAddress5: {
    value: 1,
  },
  TxAddress6: {
    value: 1,
  },
  keys: createMaxflexKeyConfig(),
}

const BLOCKS: {
  [key: string]: Block
} = {
  BLOCK_1_1: {
    height: 1,
    width: 1,
  },
  BLOCK_1_2: {
    height: 1,
    width: 2,
  },
  BLOCK_2_1: {
    height: 2,
    width: 1,
  },
  BLOCK_2_2: {
    height: 2,
    width: 2,
  },
}

export const LocatingKeyButtonMap = [
  0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x40, 0x80,
]

export const MAX_FLEX_LAYOUTS: MaxFlexLayout[] = [
  {
    name: 'maxflex_1',
    numKeys: 1,
    value: 0x40,
    blocks: [
      {
        height: 4,
        width: 2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_2_2',
    numKeys: 2,
    value: 0x44,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_2_1',
    numKeys: 2,
    value: 0xc0,
    blocks: [
      {
        height: 4,
        width: 1,
        button: 6,
      },
      {
        height: 4,
        width: 1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_3_1',
    numKeys: 3,
    value: 0x45,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_3_2',
    numKeys: 3,
    value: 0x51,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_3_3',
    numKeys: 3,
    value: 0x54,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_3_4',
    numKeys: 3,
    value: 0x4c,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_3_5',
    numKeys: 3,
    value: 0xc4,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_4_1',
    numKeys: 4,
    value: 0x55,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_2',
    numKeys: 4,
    value: 0x5c,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_3',
    numKeys: 4,
    value: 0x74,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_4',
    numKeys: 4,
    value: 0xd4,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_4_5',
    numKeys: 4,
    value: 0x53,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_6',
    numKeys: 4,
    value: 0x47,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_7',
    numKeys: 4,
    value: 0x4d,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_8',
    numKeys: 4,
    value: 0x71,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_4_9',
    numKeys: 4,
    value: 0xd1,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_4_10',
    numKeys: 4,
    value: 0xcc,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_4_11',
    numKeys: 4,
    value: 0xc5,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_1',
    numKeys: 5,
    value: 0x57,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_5_2',
    numKeys: 5,
    value: 0x5d,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_5_3',
    numKeys: 5,
    value: 0x75,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_5_4',
    numKeys: 5,
    value: 0xd5,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_5',
    numKeys: 5,
    value: 0x4f,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_5_6',
    numKeys: 5,
    value: 0xd3,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_2_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_7',
    numKeys: 5,
    value: 0xcd,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_8',
    numKeys: 5,
    value: 0xf1,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_9',
    numKeys: 5,
    value: 0xdc,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_10',
    numKeys: 5,
    value: 0xf4,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_11',
    numKeys: 5,
    value: 0xc7,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_5_12',
    numKeys: 5,
    value: 0x73,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_5_13',
    numKeys: 5,
    value: 0x7c,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_6_1',
    numKeys: 6,
    value: 0x5f,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_6_2',
    numKeys: 6,
    value: 0x77,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_6_3',
    numKeys: 6,
    value: 0xd7,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_6_4',
    numKeys: 6,
    value: 0x7d,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_6_5',
    numKeys: 6,
    value: 0xdd,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_6_6',
    numKeys: 6,
    value: 0xf5,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_6_7',
    numKeys: 6,
    value: 0xcf,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_6_8',
    numKeys: 6,
    value: 0xf3,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_6_9',
    numKeys: 6,
    value: 0xfc,
    blocks: [
      {
        ...BLOCKS.BLOCK_2_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_2_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_7_1',
    numKeys: 7,
    value: 0x7f,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 6,
      },
    ],
  },
  {
    name: 'maxflex_7_2',
    numKeys: 7,
    value: 0xdf,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_7_3',
    numKeys: 7,
    value: 0xf7,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_2,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_7_4',
    numKeys: 7,
    value: 0xfd,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_2,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
  {
    name: 'maxflex_8_1',
    numKeys: 8,
    value: 0xff,
    blocks: [
      {
        ...BLOCKS.BLOCK_1_1,
        button: 0,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 1,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 2,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 3,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 4,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 5,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 6,
      },
      {
        ...BLOCKS.BLOCK_1_1,
        button: 7,
      },
    ],
  },
]

export const MAX_FLEX_TYPE_MAP = {
  0x40: 'Typ 1',
  0x44: 'Typ 2',
  0xc0: 'Typ 3',
  0x45: 'Typ 4',
  0x51: 'Typ 5',
  0x54: 'Typ 6',
  0x4c: 'Typ 7',
  0xc4: 'Typ 8',
  0x55: 'Typ 9',
  0x5c: 'Typ 10',
  0x74: 'Typ 11',
  0xd4: 'Typ 12',
  0x53: 'Typ 13',
  0x47: 'Typ 14',
  0x4d: 'Typ 15',
  0x71: 'Typ 16',
  0xd1: 'Typ 17',
  0xcc: 'Typ 18',
  0xc5: 'Typ 19',
  0x57: 'Typ 20',
  0x5d: 'Typ 21',
  0x75: 'Typ 22',
  0xd5: 'Typ 23',
  0x4f: 'Typ 24',
  0xd3: 'Typ 25',
  0xcd: 'Typ 26',
  0xf1: 'Typ 27',
  0xdc: 'Typ 28',
  0xf4: 'Typ 29',
  0xc7: 'Typ 30',
  0x73: 'Typ 31',
  0x7c: 'Typ 32',
  0x5f: 'Typ 33',
  0x77: 'Typ 34',
  0xd7: 'Typ 35',
  0x7d: 'Typ 36',
  0xdd: 'Typ 37',
  0xf5: 'Typ 38',
  0xcf: 'Typ 39',
  0xf3: 'Typ 40',
  0xfc: 'Typ 41',
  0x7f: 'Typ 42',
  0xdf: 'Typ 43',
  0xf7: 'Typ 44',
  0xfd: 'Typ 45',
  0xff: 'Typ 46',
}

export function getMaxFlexLayoutByValue(value: number): MaxFlexLayout {
  let out: MaxFlexLayout
  MAX_FLEX_LAYOUTS.forEach((layout) => {
    if (layout.value === value) {
      out = layout
    }
  })
  return out
}

import MockAdapter from 'axios-mock-adapter'
import { BrelagMockEndpoints } from '../apps/brelag/common/mock/endpoints'

function addIdAndPermissions(object: any) {
  object.id = 'id_' + object.name
  object._permissions = ['view']
}

export class Endpoint {
  objectType: string
  url: string
  mockData: any[]
  baseUrl = '/api/v1/'
  isList: boolean = true

  constructor(objectType, mockData, isList?) {
    this.objectType = objectType
    this.url = this.baseUrl + objectType
    this.mockData = mockData
    if (isList !== undefined) {
      this.isList = isList
    }
  }
}

const defaultEndpoints = []

/*
defaultEndpoints.push(new Endpoint('auth/info', AUTH_INFO))
defaultEndpoints.push(new Endpoint('client-app/?organisation=e1d28c8d-7162-40ad-a826-a9cc8c027bff',
CLIENT_APPS_0))
defaultEndpoints.push(new Endpoint('client-app/1/', CLIENT_APPS_0[0]))
defaultEndpoints.push(new Endpoint('client-app/2/', CLIENT_APPS_0[1]))
defaultEndpoints.push(new Endpoint('client-app/3/', CLIENT_APPS_0[2]))

defaultEndpoints.push(new Endpoint('client-app/?organisation=de261f1b-e015-48bf-9af0-e8b67c0ab935',
CLIENT_APPS_1))
defaultEndpoints.push(new Endpoint('client-app/4/', CLIENT_APPS_1[0]))
*/

export class MockHandler {
  static instance: MockHandler
  private mock: MockAdapter

  private constructor(axios) {
    this.mock = new MockAdapter(axios, { delayResponse: 0 })
    this.registerMockEndpoints(defaultEndpoints)
    this.registerMockEndpoints(BrelagMockEndpoints)
    this.mock.onAny().passThrough()
  }

  static getInstance(axios?) {
    if (!MockHandler.instance) {
      if (axios !== undefined) {
        MockHandler.instance = new MockHandler(axios)
      } else {
        throw new Error('Need to pass axios when creating new MockHandler')
      }
    }
    return MockHandler.instance
  }

  public registerMockEndpoints(endpoints: Endpoint[]) {
    // Register 'GET' and 'POST' for all endpoints
    endpoints.forEach((endpoint) => {
      if (endpoint.isList) {
        this.mock.onGet(endpoint.url).reply(200, {
          results: endpoint.mockData,
        })

        this.mock.onPost(endpoint.url).reply((config) => {
          const newObject = JSON.parse(config.data)
          addIdAndPermissions(newObject)
          endpoint.mockData.push(newObject)
          return [200, {}]
        })

        this.mock.onPatch(endpoint.url).reply((config) => {
          const newObject = JSON.parse(config.data)
          endpoint.mockData.forEach((obj) => {
            if (obj.id === newObject.id) {
              obj = newObject
            }
          })
          return [200, newObject]
        })
      } else {
        this.mock.onGet(endpoint.url).reply(200, endpoint.mockData)
      }
    })
  }
}

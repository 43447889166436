import isEqual from 'lodash.isequal'
import stringify from 'json-stable-stringify'
import { DeviceAssignment } from '@/apps/brelag/common/models/equipment'
import { AnnotationElement } from '@/apps/brelag/mandator-user/models/editor'

export function positionChanged(
  n1: number,
  n2: number,
  pixelPrecision: number = 3
): boolean {
  if (Math.abs(n1 - n2) <= pixelPrecision) {
    return false
  } else {
    return true
  }
}

/**
 * Finds common properties of an array of objects. Differentiates between same keys and same key/value combo
 * @param objects
 */
export function commonDifferentProperties(objects: any[]) {
  const different = {}
  const unmatched = {}
  let common = {}
  if (objects.length === 0) {
    return [common, different, unmatched]
  }
  common = JSON.parse(JSON.stringify(objects[0]))
  for (let i = 1; i < objects.length; i++) {
    for (const prop in objects[i]) {
      checkProps(objects[i], common, prop)
    }
    for (const commProp in common) {
      checkProps(common, objects[i], commProp)
    }
  }

  return [common, different, unmatched]

  function checkProps(source, target, prop) {
    if (source.hasOwnProperty(prop)) {
      const val = source[prop]
      if (!target.hasOwnProperty(prop)) {
        // note: you could extend this to store values, or number of times you found this key, or whatever
        unmatched[prop] = true
        delete common[prop]
      } else if (!isEqual(stringify(target[prop]), stringify(val))) {
        different[prop] = undefined
        delete common[prop]
      }
    }
  }
}

export function getMetaSvgAttribute(
  element: DeviceAssignment | AnnotationElement,
  attr: string
) {
  if (
    element.meta &&
    element.meta.svgAttributes &&
    (element.meta.svgAttributes[attr] ||
      // explicitly return false instead of null
      element.meta.svgAttributes[attr] === false)
  ) {
    return element.meta.svgAttributes[attr]
  } else {
    return null
  }
}

/**
 * get first line of remarks (Bemerkungszeile 1)
 * @param device
 */
export function getDeviceRemark(device: DeviceAssignment): string {
  const remarks = getMetaSvgAttribute(device, 'textLines') as string[]
  const remark = remarks && remarks.length > 0 ? `${remarks[0]}` : null
  return remark
}


import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'
import { ObjectAuthorization } from '@/models/core/models'

@Component({
  components: {
    BaseListV2,
  },
})
export default class ObjectAuthorizationList extends Vue {
  ObjectAuthorization = ObjectAuthorization

  created() {
    this.$routerHandler.setQuery('', {
      organisation: this.$store.getters['global/organisation'].id,
    })
  }

  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    }
  }
}

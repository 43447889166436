
import { Component, Vue, Watch } from 'vue-property-decorator'

import BaseListCell from './BaseListCell.vue'
import ObjectStateTag from '../common/ObjectStateTag.vue'
import {
  BaseObject,
  LifeCycleState,
  BaseListEventType,
} from '@/models/core/base'
import { getClientAppOfRoute } from '@/apps/routingUtils'
import { clientAppRouteName } from '@/apps/clientAppRegistry'
import { copyTextToClipboard } from '@/util/clipboard'
import { Context, ApiListSubscription } from '@/api/ApiClientV2'
import MultilineTooltip from './MultilineTooltip.vue'

@Component({
  name: 'base-list-table',
  methods: {
    copyTextToClipboard,
  },
  components: {
    ObjectStateTag,
    BaseListCell,
    MultilineTooltip,
  },
  props: {
    modelClass: {
      required: true,
    },
    objectCollection: {
      required: true,
    },
    defaultSortOrder: {
      default: 'desc',
    },
    columns: {
      required: true,
    },
    singleSelection: {
      default: false,
    },
    preSelectedRows: {
      default: () => [],
    },
    unselectableRows: {
      default: () => [],
    },
    detailRouteName: {
      default: '',
    },
    customDelete: {
      default: null,
    },
    hasActions: {
      default: true,
    },
    canCopy: {
      default: false,
    },
    canAdd: {
      default: true,
    },
    canDelete: {
      default: true,
    },
    canSelect: {
      default: false,
    },
    canReorder: {
      default: true,
    },
    canCopyData: {
      default: true,
    },
    customActions: {
      default: () => [],
    },
    hasDetailView: {
      default: true,
    },
    customDetail: {
      default: null,
    },
    detailExtraParams: {
      default: () => {},
    },
    isStriped: {
      default: false,
    },
    detailed: {
      default: false,
    },
    errorMessage: {
      default: () => {
        return {
          cantSelectRow: 'Row cannot be selected',
        }
      },
    },
  },
  data() {
    return {
      rowSelections: {},
    }
  },
})
export default class BaseListTable<T> extends Vue {
  rowSelections: object
  objectCollection: ApiListSubscription<T>
  delay: number = 400
  clicks: number = 0
  timer = null

  mounted() {
    this.setPreselectedRows()
  }

  setPreselectedRows() {
    this.rowSelections = {}
    this.$props.preSelectedRows.forEach((rowId: string) => {
      this.$set(this.rowSelections, rowId, rowId)
    })
  }

  @Watch('$props.preSelectedRows')
  onPreselectedRowsChanged() {
    this.setPreselectedRows()
  }

  @Watch('$props.objectCollection.objects')
  collectionChanged() {
    this.setPreselectedRows()
  }

  get objectType() {
    return this.$props.modelClass.objectType
  }

  async callCustomActionCb(customAction, row) {
    try {
      await customAction.callback(row)
    } catch (err) {
      this.handleError(err)
    }
  }

  context(row) {
    return {
      [this.$props.modelClass.objectType]: row.id,
      organisation: this.$store.getters['global/organisation'].id,
    }
  }

  getValue(index: number, row, column) {
    row = this.parseModel(row)
    if (Array.isArray(row[column.fieldName])) {
      return row[column.fieldName][index]
    } else {
      return row[column.fieldName]
    }
  }

  getDetailRouteName() {
    if (this.$props.detailRouteName === '') {
      const clientApp = getClientAppOfRoute(this.$route)
      return clientApp
        ? clientAppRouteName(clientApp.view_id, this.objectType + '-detail')
        : ''
    }
    return this.$props.detailRouteName
  }

  getDetailLinkTo(row, copy: boolean = false) {
    if (this.$props.customDetail !== null) {
      return this.$props.customDetail(row)
    }

    const extraQuery = this.$props.modelClass.detailLinkQuery(this.context(row))
    return this.$routerHandler.getDetailLinkTo(
      this.getDetailRouteName(),
      row.id,
      copy,
      this.$props.detailExtraParams,
      extraQuery
    )
  }

  get initialSort() {
    if (this.objectCollection.filter && this.objectCollection.filter.order_by) {
      const match =
        this.objectCollection.filter.order_by.match(/^([a-z_]*?)(_dsc)?$/)
      if (match.length >= 2) {
        return [match[1], match[2] ? 'desc' : 'asc']
      }
    }
    return []
  }

  parseModel(model: object) {
    return this.$props.modelClass.parseModel(
      model,
      this.$props.modelClass.listFields
    )
  }

  isSelectable(rowId: string) {
    return !(this.$props.unselectableRows.indexOf(rowId) > -1)
  }

  select(row: any): void {
    if (!this.isSelectable(row.id)) {
      this.$buefy.toast.open({
        message: this.$props.errorMessage.cantSelectRow,
        type: 'is-warning',
      })
      return
    }
    if (this.rowSelections[row.id] !== undefined) {
      this.$set(this.rowSelections, row.id, undefined)
    } else {
      if (this.$props.singleSelection) {
        for (const id in this.rowSelections) {
          if (this.rowSelections[id]) {
            this.$set(this.rowSelections, id, undefined)
          }
        }
        // single selection: emit row
        this.$set(this.rowSelections, row.id, row)
      } else {
        // no single selection: emid id
        this.$set(this.rowSelections, row.id, row.id)
      }
    }
    this.emitSelected()
  }

  emitSelected() {
    const selected = []
    for (const id in this.rowSelections) {
      if (this.rowSelections[id] && this.rowSelections[id] !== undefined) {
        selected.push(this.rowSelections[id])
      }
    }
    this.$emit('row-selection', selected)
  }

  onSelect(row) {
    if (this.$props.canSelect) {
      this.select(row)
    } else if (this.$props.hasDetailView) {
      this.$router.push(this.getDetailLinkTo(row))
    }
  }

  copyRowData(row: any) {
    this.clicks++
    if (this.clicks === 1) {
      // We copy the ID already because Firefox does not allow this function inside the timeout handler
      copyTextToClipboard(row.id)
      this.timer = setTimeout(() => {
        this.$buefy.toast.open({
          message: 'Copied ID!',
          type: 'is-success',
        })
        this.clicks = 0
      }, this.delay)
    } else {
      clearTimeout(this.timer)
      copyTextToClipboard(JSON.stringify(row, null, 2))
      this.$buefy.toast.open({
        message: 'Copied data!',
        type: 'is-success',
      })
      this.clicks = 0
    }
  }

  async insertBefore(insert: BaseObject, before: BaseObject) {
    try {
      await this.$props.modelClass.insertBefore(this, insert, before)
      this.$emit('base-list-event', BaseListEventType.REORDER)
      this.refreshCollection()
    } catch (error) {
      this.handleError(error)
    }
  }

  async moveUp(index) {
    const objects = this.objectCollection.objects
    if (index === 0) {
      // top element of this page
      if (this.objectCollection.pagination.page === 1) {
        // first page -> move to end
        this.insertBefore(objects[index], null)
      } else {
        // not first page -> move one page up
        const context: Context = {
          filter: this.objectCollection.filter,
          pagination: { ...this.objectCollection.pagination },
        }
        context.pagination.page -= 1
        const pageBefore = await this.$apiv2.getListItems<BaseObject>(
          this.$props.modelClass,
          context
        )
        this.insertBefore(
          objects[index],
          pageBefore[this.objectCollection.pagination.pageSize - 1]
        )
      }
    } else {
      // any other element on this page
      this.insertBefore(objects[index], objects[index - 1])
    }
  }

  async moveDown(index) {
    const objects = this.objectCollection.objects
    let before
    if (index + 2 < objects.length) {
      before = objects[index + 2]
    } else {
      if (this.isLastPage()) {
        if (index === objects.length - 1) {
          // last element -> move to first
          const context: Context = {
            filter: this.objectCollection.filter,
            pagination: { page: 1, pageSize: 1 },
          }
          const pageFirst = await this.$apiv2.getListItems<BaseObject>(
            this.$props.modelClass,
            context
          )
          before = pageFirst[0]
        } else {
          // second last element
          // when before is null, the item is moved to the end of the list
          before = null
        }
      } else {
        // move to next page
        const context: Context = {
          filter: this.objectCollection.filter,
          pagination: { ...this.objectCollection.pagination },
        }
        context.pagination.page += 1
        const pageAfter = await this.$apiv2.getListItems<BaseObject>(
          this.$props.modelClass,
          context
        )
        before = pageAfter[index - objects.length + 2]
      }
    }
    this.insertBefore(objects[index], before)
  }

  /**
   * Whether current page is last page
   */
  isLastPage(): boolean {
    const total = this.objectCollection.info.size
    if (!total) return false
    const pageSize = this.objectCollection.pagination.pageSize
    if (!pageSize) return false
    const currentPage = this.objectCollection.pagination.page
    return Math.ceil(total / pageSize) === currentPage
  }

  async refreshCollection() {
    await this.objectCollection.refresh()
  }

  handleError(error) {
    this.$emit('error', error)
  }

  preventRowClick(event) {
    event.stopPropagation()
  }

  getRowClass(row): string {
    let cssClass = ''
    if (this.$props.canSelect || this.$props.hasDetailView) {
      cssClass += ' can-select'
    }
    if (this.rowSelections[row.id] !== undefined) {
      cssClass += ' selected-row'
    }
    return cssClass
  }

  confirmDelete(object: BaseObject) {
    this.$buefy.dialog.confirm({
      message: this.$tc('components.common.confirmDelete'),
      onConfirm: () => {
        if (this.$props.customDelete !== null) {
          this.$props.customDelete(object)
          return
        } else {
          this.deleteObject(object)
        }
      },
    })
  }

  async deleteObject(object: BaseObject) {
    let purge = false
    if (object.object_state === LifeCycleState.Deleted) {
      purge = true
    }
    try {
      await this.$apiv2.delete(this.$props.modelClass, object.id, purge)
      this.$buefy.toast.open({
        message: this.$tc('components.common.deleteSuccess'),
        type: 'is-success',
      })
      this.$emit('base-list-event', BaseListEventType.DELETE)
    } catch (error) {
      this.handleError(error)
    }
  }

  async cellEdited(row, column, newValue) {
    try {
      if (!row.hasOwnProperty(column.fieldName)) {
        throw new Error(`Invalid field: ${column.fieldName}`)
      }
      row[column.fieldName] = newValue
      await this.$apiv2.update(this.$props.modelClass, row)
      this.$buefy.toast.open({
        message: this.$tc('components.common.saveSuccess'),
        type: 'is-success',
      })
      this.$emit('base-list-event', BaseListEventType.UPDATE)
    } catch (error) {
      this.handleError(error)
    }
  }
}

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  beforeRouteLeave(to, fromWhere, next) {
    let ans = true
    try {
      if (this.$refs.baseForm.isDirty() && !this.$refs.baseForm.isNewObject) {
        ans = window.confirm(
          this.$tc('components.common.leaveWithoutSaveQuestion')
        )
      }
      if (ans) {
        next()
      } else {
        next(false)
      }
    } catch (_) {
      next()
    }
  },
})
export class BeforeLeaveGuard extends Vue {}


import { Component, Vue, Watch } from 'vue-property-decorator'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'
import hotkeys from 'hotkeys-js'

import {
  MaxFlexKeyConfig,
  MaxFlexButtonFunction,
  MaxFlexJokerButtonCommands,
  LocatingKeyButtonMap,
  MaxFlexConfig,
} from '@/apps/brelag/common/models/maxflex/maxflex'

@Component({
  props: {
    config: {
      required: true,
    },
    buttonNum: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      infoPanelOpen: false,
      MaxFlexButtonFunction,
      MaxFlexJokerButtonCommands,
      channels: [...Array(8).keys()].map((x) => {
        return {
          name: `Kanal ${x + 1}`,
          value: x,
        }
      }),
    }
  },
})
export default class MaxFlexButtonConfigEditor extends Vue {
  config: MaxFlexConfig
  originalConfig: MaxFlexConfig

  Time1: number = null // [0.1s] saved as 0.1 sec, displayed as sec
  Time2: number = null // [0.1s] saved as 0.1 sec, displayed as sec
  TimeRls: number = null // [0.1s] saved as 0.1 sec, displayed as sec
  DelayRls: number = null // [s]
  Icon = ''
  Text = ''

  created() {
    this.Time1 = this.keyConfig.Time1.value / 10
    this.Time2 = this.keyConfig.Time2.value / 10
    this.TimeRls = this.keyConfig.TimeRls.value / 10
    this.DelayRls = this.keyConfig.DelayRls.value
    if (!this.keyConfig.Icon) {
      this.keyConfig.Icon = { value: '' }
    }
    if (!this.keyConfig.Text) {
      this.keyConfig.Text = { value: '' }
    }
    this.Icon = this.keyConfig.Icon.value
    this.Text = this.keyConfig.Text.value
  }

  mounted() {
    hotkeys(
      'esc',
      { scope: 'MaxFlexButtonConfigEditor', keyup: true },
      (event) => {
        // only fire event on keyup to avoid closing confirm dialog directly
        if (event.type === 'keyup') {
          event.preventDefault()
          this.cancel()
        }
      }
    )
    hotkeys.setScope('MaxFlexButtonConfigEditor')

    // keep original config to detect changes
    this.originalConfig = JSON.parse(JSON.stringify(this.config))
  }

  destroyed() {
    hotkeys.unbind('esc', 'MaxFlexButtonConfigEditor')
    hotkeys.setScope('MaxFlexConfigEditor')
  }

  @Watch('Time1')
  Time1Changed() {
    this.keyConfig.Time1.value = this.Time1 * 10
  }

  @Watch('Time2')
  Time2Changed() {
    this.keyConfig.Time2.value = this.Time2 * 10
  }

  @Watch('TimeRls')
  TimeRlsChanged() {
    this.keyConfig.TimeRls.value = this.TimeRls * 10
  }

  @Watch('DelayRls')
  DelayRlsChanged() {
    this.keyConfig.DelayRls.value = this.DelayRls
  }

  @Watch('Text')
  TextChanged() {
    this.keyConfig.Text.value = this.Text
  }

  @Watch('Icon')
  IconChanged() {
    this.keyConfig.Icon.value = this.Icon
  }

  get nightLED() {
    return (
      LocatingKeyButtonMap[this.$props.buttonNum] ===
      this.config.LocatingKey.value
    )
  }

  get keyConfig(): MaxFlexKeyConfig {
    return this.config.keys[this.$props.buttonNum]
  }

  nightLEDSwitched(value) {
    this.$emit('input', value, this.$props.buttonNum)
  }

  closeModal() {
    this.$emit('close')
  }

  cancel() {
    if (isEqual(this.config, this.originalConfig)) {
      // no changes, close immediatly
      this.closeModal()
    } else {
      this.$buefy.dialog.confirm({
        message: 'Sind Sie sicher, dass Sie die Änderung verwerfen möchten?',
        onConfirm: () => {
          // revert changes
          // need to merge into config object, cannot reassign this.config to a new object
          merge(this.config, this.originalConfig)
          this.closeModal()
        },
      })
    }
  }
}

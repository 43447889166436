
import Vue from 'vue'
import Component from 'vue-class-component'
import BaseListV2 from '@/components/common/BaseListV2.vue'
import { Organisation } from '@/models/core/organisation'
import { Customer } from '@/apps/brelag/common/models/customer'
import { Project } from '@/apps/brelag/common/models/project'
import OrganisationSelector from '@/components/navigation/sidebar/OrganisationSelector.vue'

import {
  Activity,
  ActivityKind,
  UserInfo,
} from '@/apps/brelag/common/models/activity'
import EventItem from './EventItem.vue'

@Component({
  name: 'brelag-event-feed',
  components: {
    EventItem,
    BaseListV2,
    OrganisationSelector,
  },
  props: {
    disableOrganisationSelection: {
      default: false,
    },
    disableCustomerSelection: {
      default: false,
    },
    disableProjectSelection: {
      default: false,
    },
    customerId: {
      required: false,
    },
    projectId: {
      required: false,
    },
  },
  data() {
    return {
      Activity,
      ActivityKind,
    }
  },
})
export default class EventFeed extends Vue {
  projectId: string
  customerId: string
  filter = {}
  selectedOrg: string = null
  selectedCustomer = null
  selectedProject = null
  selectedActivity = null
  organisations = []
  customers = []
  projects = []
  loading = false

  async mounted() {
    this.refresh()
    this.filterEvents()
    this.selectedCustomer = this.$props.customerId
    this.selectedProject = this.$props.projectId
  }

  organisationSelected(newOrg: Organisation): void {
    this.selectedOrg = newOrg.id
    this.filterEvents()
  }

  async getLists() {
    this.organisations = await this.$apiv2.getListItems<Organisation>(
      Organisation,
      { filter: {}, pagination: {} }
    )
    this.customers = await this.$apiv2.getListItems<Customer>(Customer, {
      filter: {
        organisation:
          this.$route.query.organisation ||
          this.$store.getters['global/organisation'].id,
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    })
    this.projects = await this.$apiv2.getListItems<Project>(Project, {
      filter: {
        customer: this.selectedCustomer,
        organisation:
          this.$route.query.organisation ||
          this.$store.getters['global/organisation'].id,
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    })
  }

  onPageChange(page) {
    this.$routerHandler.setPage('', page)
  }

  async filterEvents() {
    this.loading = true
    try {
      this.filter = {
        organisation: this.selectedOrg,
        'payload.customer': this.selectedCustomer,
        'payload.project': this.selectedProject,
        activity_kind: this.selectedActivity,
      }
      await this.getLists()
    } catch (error) {
      this.$buefy.toast.open({
        message: this.$errorHandler.errorToString(error),
        type: 'is-danger',
        duration: 5000,
      })
    }
    this.loading = false
  }

  refresh() {
    this.selectedOrg =
      this.$route.query.organisation ||
      this.$store.getters['global/organisation'].id
  }
}

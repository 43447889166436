
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { getDependencies } from '@/models/objectRegistry'
import { TransientBaseObject } from '@/models/core/base'
import { Context } from '@/api/ApiClientV2'

@Component({})
export default class ParentSelectorV2 extends Vue {
  @Prop({ required: true })
  modelClass: typeof TransientBaseObject
  @Prop({ required: true })
  required: boolean
  @Prop({ default: false })
  isForm: boolean
  @Prop({ required: false })
  customDisplayName: ((option: any) => Promise<void>) | undefined
  @Prop({ required: false })
  filterOption: ((option: any) => boolean) | undefined
  @Prop({ required: false })
  sortOptions: ((option1: any, option2: any) => number) | undefined
  @Prop({ default: false })
  disabled: boolean
  @Prop({ default: null })
  orderBy: string

  label = ''
  value = null
  placeholder = ''
  loading = true
  options = []
  prefix = ''
  ancestorObjectTypes: string[] = []

  @Watch('$route.query')
  onRouteChange() {
    this.getOptions()
  }

  get message() {
    if (this.required && !this.value) {
      return this.$tc('components.common.required')
    } else {
      return ''
    }
  }

  get isDanger() {
    if (this.required && !this.value) {
      return true
    } else {
      return false
    }
  }

  displayName(option): string {
    // TODO: This is not really good
    if ('name' in option) {
      return option.name
    } else if ('handle' in option) {
      return option.handle
    } else if ('title' in option) {
      return option.title
    } else {
      return ''
    }
  }

  mounted() {
    this.label = this.modelClass.prettyName() || ''
    this.ancestorObjectTypes = this.modelClass.ancestors.map(
      (ancestor) => ancestor.modelClass.objectType
    )
    this.getOptions()
  }

  async getOptions() {
    this.loading = true
    this.placeholder = this.$tc('components.common.loading') + '...'

    let deselectedText = `- ${this.$tc('components.common.noFilter')} -`
    if (this.isForm) {
      deselectedText = `- ${this.$tc('components.common.noSelection')} -`
    }
    const filter = this.$routerHandler.query(this.prefix)
    // remove filters that are not ancestors
    Object.keys(filter).forEach((key: string) => {
      if (!this.ancestorObjectTypes.find((objectType) => key === objectType)) {
        delete filter[key]
      }
    })
    filter.organisation = this.$store.getters['global/organisation'].id
    const context: Context = {
      filter: filter,
      pagination: {
        page: 1,
        pageSize: 100,
      },
    }
    if (this.orderBy) {
      context.filter.order_by = this.orderBy
    }
    // empty options already here to not show old options
    this.options = []
    try {
      const results = await this.$apiv2.getListItems<any>(
        this.modelClass,
        context
      )
      // empty again to avoid duplicates if getOptions is called multiple times
      this.options = []
      if (!this.required) {
        this.options.push({
          name: deselectedText,
        })
      }
      this.options = this.options.concat(results)
      if (this.customDisplayName) {
        const promises = []
        this.options.forEach((option) => {
          promises.push(this.customDisplayName(option))
        })
        await Promise.all(promises)
      } else {
        this.options.forEach((option) => {
          option._displayName = this.displayName(option)
        })
      }
      if (this.filterOption) {
        this.options = this.options.filter(this.filterOption)
      }
      if (this.sortOptions) {
        this.options.sort(this.sortOptions)
      }
      if (this.options.length === 0) {
        this.placeholder = `${this.$tc('components.common.no')} ${
          this.label
        } ${this.$tc('components.common.found')}`
      } else {
        if (this.isForm) {
          this.placeholder = `${this.label} ${this.$tc(
            'components.common.select'
          )}`
        } else {
          this.placeholder = `${this.$tc('components.common.filterBy')} ${
            this.label
          }`
        }
      }
      // select option in url
      this.value = this.$routerHandler.query(this.prefix)[
        this.modelClass.objectType
      ]
      // if only one option available, select this option
      if (this.options.length === 1) {
        this.value = this.options[0].id
        this.applyFilter(this.value)
      }
    } catch (err) {
      this.options = []
      this.placeholder = `No ${this.label} found`
      console.warn(err)
    }
    this.loading = false
  }

  applyFilter(id: string) {
    // only apply if filter changed
    if (
      id !== null &&
      this.$routerHandler.query(this.prefix)[this.modelClass.objectType] !== id
    ) {
      // disable filter input function when navigation is in progress
      // otherwise the change of state during navigation will trigger
      // the input function a second time
      if (!this.$store.getters['global/navigationIsActive']) {
        const pagination = this.modelClass.defaultPagination || {}

        const redirect = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            [this.modelClass.objectType]: id,
            ...(pagination as any),
          },
        }

        // clear selected page when changing a filter
        delete redirect.query.page

        for (const item of getDependencies(this.$props.modelClass.objectType)) {
          // delete depending object query parameters
          // e.g. when product changes, model must be cleared
          delete redirect.query[item]
        }
        this.$routerHandler.push(redirect)
      }
    }
  }
}

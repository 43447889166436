import { TransientBaseObject } from '@/models/core/base'
import { Album, Role, Group, ObjectAuthorization } from './core/models'
import { Offer } from './commerce/offer'
import { Order } from './commerce/order'

import { Organisation } from './core/organisation'
import { Profile } from './core/profile'
import { ClientApp } from './client/models'

import {
  DeviceAssignment,
  EquipmentGroup,
  Equipment,
  EquipmentVariant,
  InstructionStep,
} from '@/apps/brelag/common/models/equipment'
import { ImageObject } from '@/apps/brelag/common/models/image'
import { Customer } from '@/apps/brelag/common/models/customer'
import { Project, ProjectExport } from '@/apps/brelag/common/models/project'
import { Building } from '@/apps/brelag/common/models/building'
import { Floor } from '@/apps/brelag/common/models/floor'
import { KnockautProject } from '@/apps/brelag/common/models/knockaut'
import {
  KnockautModule,
  KnockautModuleInstallation,
  KnockautModuleOrder,
} from '@/apps/brelag/common/models/knockautModule'
import { KnockautBackup } from '@/apps/brelag/common/models/knockautBackup'
import { KnockautLicenseKey } from '@/apps/brelag/common/models/knockautLicenseKey'

interface ObjectAncestor {
  objectType: string
  mainParent?: boolean
  requiredForFilter?: boolean
}

interface ObjectRegistry {
  [objectType: string]: {
    apiUrl?: string
    store?: string
    defaultViewId?: string
    ancestors?: ObjectAncestor[]
    modelClass?: typeof TransientBaseObject
  }
}
// moved to function to avoid circular dependency issues
export function getObjectRegistry(): ObjectRegistry {
  const OBJECT_REGISTRY: ObjectRegistry = {
    /* CORE */
    organisation: {
      modelClass: Organisation,
    },
    profile: {
      modelClass: Profile,
    },
    role: {
      modelClass: Role,
    },
    group: {
      modelClass: Group,
      defaultViewId: 'web-admin',
    },
    'object-authorization': {
      modelClass: ObjectAuthorization,
      defaultViewId: 'web-admin',
      ancestors: [
        {
          objectType: 'role',
        },
      ],
    },
    'client-app': {
      modelClass: ClientApp,
      apiUrl: 'client/app',
    },
    'background-task': {},
    /* BRELAG */
    album: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: Album,
      apiUrl: Album.apiUrl,
    },
    image: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: ImageObject,
    },
    'device-assignment': {
      modelClass: DeviceAssignment,
    },
    fusion_one: {
      modelClass: DeviceAssignment,
    },
    equipmentgroup: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: EquipmentGroup,
      apiUrl: EquipmentGroup.apiUrl,
    },
    equipment_group: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: EquipmentGroup,
      apiUrl: EquipmentGroup.apiUrl,
    },
    equipment: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: Equipment,
      apiUrl: Equipment.apiUrl,
      ancestors: [
        {
          objectType: 'equipment_group',
          mainParent: true,
        },
      ],
    },
    equipmentvariant: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: EquipmentVariant,
      apiUrl: EquipmentVariant.apiUrl,
      ancestors: [
        {
          objectType: 'equipment',
          mainParent: true,
        },
      ],
    },
    'instruction-step': {
      modelClass: InstructionStep,
    },
    offer: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: Offer,
      apiUrl: Offer.apiUrl,
    },
    order: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: Order,
      apiUrl: Order.apiUrl,
    },
    customer: {
      defaultViewId: 'brelag-user',
      store: 'brelag',
      modelClass: Customer,
      apiUrl: Customer.apiUrl,
    },
    project: {
      defaultViewId: 'brelag-user',
      store: 'brelag',
      modelClass: Project,
      apiUrl: Project.apiUrl,
    },
    'project-export': {
      defaultViewId: 'brelag-user',
      store: 'brelag',
      modelClass: ProjectExport,
      apiUrl: ProjectExport.apiUrl,
    },
    building: {
      defaultViewId: 'brelag-user',
      store: 'brelag',
      modelClass: Building,
      apiUrl: Building.apiUrl,
      ancestors: [
        {
          objectType: 'project',
          mainParent: true,
        },
      ],
    },
    floor: {
      defaultViewId: 'brelag-user',
      store: 'brelag',
      modelClass: Floor,
      apiUrl: Floor.apiUrl,
    },
    instructionstep: {
      defaultViewId: 'brelag-admin',
      store: 'brelag',
      modelClass: InstructionStep,
      apiUrl: InstructionStep.apiUrl,
      ancestors: [
        {
          objectType: 'equipment',
          mainParent: true,
        },
      ],
    },
    knockautproject: {
      defaultViewId: 'brelag-user',
      store: 'brelag',
      modelClass: KnockautProject,
      apiUrl: KnockautProject.apiUrl,
      ancestors: [
        {
          objectType: 'project',
          mainParent: true,
        },
      ],
    },
    'knockaut-module': {
      store: 'brelag',
      modelClass: KnockautModule,
      apiUrl: KnockautModule.apiUrl,
      ancestors: [
        {
          objectType: 'organisation',
          mainParent: true,
        },
      ],
    },
    KnockautModuleOrder: {
      store: 'brelag',
      modelClass: KnockautModuleOrder,
      apiUrl: KnockautModuleOrder.apiUrl,
      ancestors: [
        {
          objectType: 'project',
          mainParent: true,
        },
      ],
    },
    KnockautModuleInstallation: {
      store: 'brelag',
      modelClass: KnockautModuleInstallation,
      apiUrl: KnockautModuleInstallation.apiUrl,
      ancestors: [
        {
          objectType: 'project',
          mainParent: true,
        },
      ],
    },
    KnockautBackup: {
      store: 'brelag',
      modelClass: KnockautBackup,
      apiUrl: KnockautBackup.apiUrl,
      ancestors: [
        {
          objectType: 'project',
          mainParent: true,
        },
      ],
    },
    KnockautLicenseKey: {
      store: 'brelag',
      modelClass: KnockautLicenseKey,
      apiUrl: KnockautLicenseKey.apiUrl,
      ancestors: [
        {
          objectType: 'project',
          mainParent: true,
        },
      ],
    },
  }

  return OBJECT_REGISTRY
}

function objectRegistryGet(objectType: string) {
  const entry = getObjectRegistry()[objectType]
  if (!entry) {
    throw new Error(`Object type '${objectType}' is not defined in registry.`)
  }
  return entry
}

export function objectTypeHasProperty(objectType: string, property: string) {
  const entry = objectRegistryGet(objectType)
  return entry.hasOwnProperty(property)
}

export function objectRegistryGetProperty(
  objectType: string,
  property: string
) {
  const entry = objectRegistryGet(objectType)

  if (!entry.hasOwnProperty(property)) {
    throw new Error(
      `Property '${property}' is not defined for object type ${objectType}.`
    )
  }
  return entry[property]
}

export function getApiUrl(objectType: string): string {
  try {
    objectRegistryGet(objectType)
    if (objectTypeHasProperty(objectType, 'apiUrl')) {
      const apiUrl = objectRegistryGetProperty(objectType, 'apiUrl')
      if (typeof apiUrl === 'string') {
        return apiUrl
      }
    }
    return objectType
  } catch (error) {
    return objectType
  }
}

export function getStoreName(objectType: string): string {
  let store
  try {
    store = objectRegistryGetProperty(objectType, 'store')
  } catch (error) {
    store = 'global'
  }
  return store
}

export function getViewId(objectType: string) {
  return objectRegistryGetProperty(objectType, 'defaultViewId')
}

export function getAllAncestors(objectType: string) {
  if (objectTypeHasProperty(objectType, 'ancestors')) {
    return objectRegistryGetProperty(objectType, 'ancestors')
  } else if (objectTypeHasProperty(objectType, 'modelClass')) {
    const modelClass = getModelClass(objectType)
    if (modelClass && modelClass.ancestors) {
      const out = []
      modelClass.ancestors.forEach((ancestor) =>
        out.push({
          objectType: ancestor.modelClass.objectType,
        })
      )
      return out
    } else {
      return []
    }
  } else {
    return []
  }
}

function getMainParent(objectType: string) {
  if (objectTypeHasProperty(objectType, 'ancestors')) {
    const ancestors = objectRegistryGetProperty(objectType, 'ancestors')
    return ancestors.find((ancestor) => ancestor.mainParent === true)
  } else {
    return null
  }
}

export function getPathUp(objectType: string): ObjectAncestor[] {
  const up = getMainParent(objectType)
  if (up) {
    // return list starting with upmost ancestor
    return [...getPathUp(up.objectType), up]
  } else {
    return []
  }
}

export function getObjectTypesUp(objectType: string): string[] {
  return getPathUp(objectType).map((ancestor) => ancestor.objectType)
}

export function getDependencies(objectType: string) {
  const dependencies: string[] = []

  for (const checkedObjectType in getObjectRegistry()) {
    if (objectTypeHasProperty(checkedObjectType, 'ancestors')) {
      const ancestors = objectRegistryGetProperty(
        checkedObjectType,
        'ancestors'
      )
      if (ancestors.some((ancestor) => ancestor.objectType === objectType)) {
        dependencies.push(checkedObjectType)
      }
    }
  }
  return dependencies
}

export function getModelClass(objectType: string): typeof TransientBaseObject {
  return objectRegistryGetProperty(
    objectType,
    'modelClass'
  ) as typeof TransientBaseObject
}

export function getPrettyName(objectType: string): string {
  return getModelClass(objectType).prettyName()
}

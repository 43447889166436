import { EventFeedModule } from './EventFeedStore'
import { GlobalModule } from './GlobalStore'
import { BrelagModule } from '@/store/BrelagStore'
import { SettingsModule } from '@/store/SettingsStore'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    global: new GlobalModule<any>(),
    eventFeed: new EventFeedModule<any>(),
    brelag: new BrelagModule<any>(),
    settings: new SettingsModule<any>(),
  },
})

export default store

export const initialStateCopy = JSON.parse(JSON.stringify(store.state))

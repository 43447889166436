export default {
  role: {
    prettyName: 'Role | Roles',
  },
  user: {
    prettyName: 'User | Users',
  },
  objectauthorization: {
    prettyName: 'Object Authorization | Object Authorizations',
    fields: {
      role_name: 'Role',
      group_name: 'Group',
      object_type: 'Object Type',
      object_name: 'Object Name',
      note: 'Note',
    },
  },
  organisation: {
    prettyName: 'Organisation | Organisations',
    fields: {
      name: 'Name',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      object_state: 'State',
      password_policy: {
        version: 'Version',
        min_length: 'Min Length',
        alpha_upper: 'Alpha Upper',
        alpha_lower: 'Alpha Lower',
        numeric: 'Numeric',
        special_char: 'Special Char',
      },
      meta: {
        email: 'E-Mail',
        phone: 'Phone',
        website: 'Website',
        commercialRegisterNumber: 'Commercial Register Number',
        commercialRegisterLocation: 'Commercial Register Location',
        vatIdNumber: 'VAT ID Number',
        taxNumber: 'Tax Number',
        gln: 'GLN',
      },
      verified_address: {
        address_line_1: 'Street',
        address_line_2: '',
        town: 'Town',
        zip: 'ZIP Code',
        province: 'Province',
        country: 'Country',
      },
    },
    placeholders: {
      name: 'Name',
      avatar: 'Avatar',
      password_policy: {
        version: 'Version',
        min_length: 'Min Length',
        alpha_upper: 'Alpha Upper',
        alpha_lower: 'Alpha Lower',
        numeric: 'Numeric',
        special_char: 'Special Char',
      },
      meta: {
        email: 'E-Mail',
        phone: 'Phone',
      },
    },
  },
  group: {
    prettyName: 'Group | Groups',
    fields: {
      name: 'Name',
      description: 'Description',
      organisation: 'Organisation',
      profiles: 'Profiles',
    },
    placeholders: {
      name: 'UserGroup A',
      description: '',
      profiles: '',
    },
  },
  profile: {
    prettyName: 'Profile | Profiles',
    fields: {
      username: 'Username',
      email: 'E-Mail',
      first_name: 'First Name',
      last_name: 'Last Name',
      organisation: 'Organisation',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      require_vpn_2fa: 'Require VPN 2FA',
      kind: 'Kind',
      is_activated: 'Activated',
      is_active: 'Active',
      is_activated_and_active: 'Activated',
      meta: {
        phone: 'Phone',
        gender: 'Gender',
      },
    },
    placeholders: {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      organisation: '',
      avatar: '',
      avatar_img: '',
      require_vpn_2fa: '',
      kind: '',
      is_activated: '',
      is_active: '',
      meta: {
        phone: '',
        gender: '',
      },
    },
  },
  groupmembership: {
    prettyName: 'Group Membership | Group Memberships',
    fields: {
      group_name: 'Group',
      profile_username: 'Username',
      profile_email: 'E-Mail',
    },
    placeholders: {
      group: '',
      profile: '',
      require_vpn_2fa: 'Require VPN 2FA',
      kind: 'Kind',
      meta: {
        phone: 'Phone',
      },
    },
  },
  album: {
    fields: {
      name: 'Name',
      description: 'Description',
      organisation: 'Organisation',
      public: 'Public',
    },
    placeholders: {
      name: '',
      description: '',
      organisation: '',
      public: '',
    },
  },
}


import Vue from 'vue'
import Component from 'vue-class-component'
import OrganisationSelector from './OrganisationSelector.vue'
import ClientAppSelector from './ClientAppSelector.vue'
import { mapState } from 'vuex'
import { CommonContext } from '@/store/GlobalStore'

@Component({
  name: 'push-sidebar',
  components: {
    OrganisationSelector,
    ClientAppSelector,
  },
  computed: {
    ...mapState('global', ['context']),
  },
  data() {
    return {
      isModalActive: false,
    }
  },
})
export default class PushSideBar extends Vue {
  context: CommonContext

  get frontendVersion() {
    return this.$store.state.global.frontendVersion
  }

  get isCollapsed() {
    return (
      this.$store.getters['global/navigationIsCollapsed'] ||
      this.$route.params.app_handle === undefined ||
      !this.isLoggedIn
    )
  }

  get isLoggedIn() {
    return this.$store.state.global.isLoggedIn
  }

  get selectionIsCollapsed() {
    return this.$store.getters['global/selectionIsCollapsed']
  }

  get navigationTitle() {
    if (
      this.$store.getters['global/selectedClientApp'] !== null &&
      this.$store.getters['global/selectedClientApp'].name
    ) {
      return this.$store.getters['global/selectedClientApp'].name
    } else {
      return 'Navigation'
    }
  }

  get hasOrganisationSelection() {
    const organisations = this.$store.getters['global/contextOrganisations']
    return organisations && organisations.objects.length > 1
  }

  selectOrganisation(organisation) {
    if (!this.$store.getters['global/navigationIsActive']) {
      // when the state changes the value of the dropdown is updated which
      // triggers the @input handler which is not wanted
      if (
        organisation &&
        this.context.selection.organisation !== organisation
      ) {
        this.$store.commit('global/clearContextSelection')
        this.$router
          .push({
            name: 'organisation',
            params: {
              org_slug: organisation.slug,
            },
          })
          .catch(() => {})
      }
    }
  }

  collapse() {
    this.$store.commit('global/setNavigationCollapsed', { value: true })
    // hide client app menu also on mobile
    this.$store.commit('global/setReaderMode', { value: true })
  }

  toggleSelectionOpen() {
    this.$store.commit('global/setSelectionCollapsed', {
      value: !this.selectionIsCollapsed,
    })
  }
}

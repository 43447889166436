
import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'

import { Organisation } from '@/models/core/organisation'
import { Profile } from '@/models/core/profile'
import {
  Activity,
  ActivityKind,
  UserInfo,
} from '@/apps/brelag/common/models/activity'
import { getModelClass } from '@/models/objectRegistry'

@Component({
  name: 'brelag-event-item',
  methods: {
    moment,
  },
  props: {
    activity: {
      required: true,
    },
  },
  data() {
    return {
      ActivityKind,
      avatar: null,
      organisationName: null,
      errorMsg: undefined,
      relatedObject: null,
      eventTypes: {
        'brelag.deviceassignment': {
          field: 'device_number',
          text: 'das Gerät mit Nummer',
          objectType: 'device-assignment',
        },
        'brelag.project': {
          field: 'title',
          text: 'das Projekt mit Projektname',
          objectType: 'project',
        },
        'brelag.equipment': {
          field: 'device_type_name',
          text: 'das Equipment',
          objectType: 'equipment',
        },
        'brelag.equipmentgroup': {
          field: 'name',
          text: 'die Equipment Gruppe',
          objectType: 'equipment_group',
        },
        'brelag.equipmentvariant': {
          field: 'device_variant_name',
          text: 'die Equipment Variante',
          objectType: 'equipmentvariant',
        },
        'brelag.instructionstep': {
          field: 'title',
          text: 'den Instructionstep',
          objectType: 'instructionstep',
        },
        'brelag.building': {
          field: 'title',
          text: 'das Gebäude',
          objectType: 'building',
        },
        'brelag.floor': {
          field: 'title',
          text: 'den Stock',
          objectType: 'floor',
        },
        'brelag.customer': {
          field: 'name',
          text: 'den Kunden',
          objectType: 'customer',
        },
        'brelag.image': {
          field: 'title',
          text: 'das Bild',
          objectType: 'image',
        },
        'commerce.order': {
          field: 'title',
          text: 'die Bestellung',
          objectType: 'order',
        },
      },
    }
  },
})
export default class EventItem extends Vue {
  avatar: string
  organisationName: string
  loading: boolean = true
  relatedObject: any
  errorMsg: any = undefined
  eventTypes: {
    [key: string]: {
      field: string
      text: string
      objectType: string
    }
  }
  userDisplayName = ''

  async mounted() {
    this.loading = true
    try {
      await this.getOrganisation()
      this.userDisplayName = await Profile.getFullName(
        this.$apiv2,
        this.event.user_info.profile
      )

      if (this.event.activity_kind !== ActivityKind.DELETE) {
        if (this.eventTypes.hasOwnProperty(this.type)) {
          const modelClass = getModelClass(
            this.eventTypes[this.type].objectType
          )
          this.relatedObject = await this.$apiv2.get<typeof modelClass>(
            modelClass,
            this.event.payload.id
          )
        }
      }
      this.errorMsg = undefined
    } catch (err) {
      this.errorMsg = this.$errorHandler.errorToString(err)
    }
    this.loading = false
  }

  async getOrganisation() {
    try {
      const organisation = await this.$apiv2.get<Organisation>(
        Organisation,
        this.$props.activity.user_info.organisation
      )
      this.organisationName = organisation.name
    } catch (err) {
      this.organisationName = null
    }
  }

  get icon(): string {
    switch (this.event.activity_kind) {
      case ActivityKind.CREATE:
        return 'mdi mdi-check-circle-outline'
      case ActivityKind.DELETE:
        return 'mdi mdi-close-circle-outline'
      case ActivityKind.UPDATE:
        return 'mdi mdi-refresh'
      case ActivityKind.STATE_CHANGE:
        return ''
      default:
        return ''
    }
  }

  get actionType(): string {
    switch (this.event.activity_kind) {
      case ActivityKind.CREATE:
        return 'is-success'
      case ActivityKind.DELETE:
        return 'is-danger'
      case ActivityKind.UPDATE:
        return 'is-info'
      case ActivityKind.STATE_CHANGE:
        return ''
      default:
        return ''
    }
  }

  get title(): string {
    return `${this.readableTime} (${moment(this.event.create_time).format(
      'DD.MM.YYYY HH:mm'
    )})`
  }

  get readableTime(): string {
    moment.locale('de')
    return moment(this.event.create_time).fromNow()
  }

  get type(): string {
    return this.event.payload.__type__
  }

  get userInfo(): UserInfo {
    return this.event.user_info
  }

  get event(): Activity {
    return this.$props.activity
  }

  getActionVerb(activityKind: ActivityKind) {
    switch (activityKind) {
      case ActivityKind.CREATE:
        return 'erstellt'
      case ActivityKind.DELETE:
        return 'gelöscht'
      case ActivityKind.UPDATE:
        return 'aktualisiert'
      case ActivityKind.STATE_CHANGE:
        return 'Status geändert'
      default:
        return activityKind
    }
  }
}


import BaseForm from '@/components/common/BaseForm.vue'
import {
  LicenseLength,
  LicenseOffer,
  LICENSE_OFFERS,
} from '@/apps/brelag/common/models/knockautLicenseKey'
import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'
import { KnockautProject } from '@/apps/brelag/common/models/knockaut'

@Component({
  components: {
    BaseForm,
  },
  props: {},
  data() {
    return {
      dataObject: {},
    }
  },
})
export default class KnockautLicenseRenewal extends Vue {
  $refs: {
    baseForm: BaseForm
  }
  projectId: string = null
  validationCode: string = null
  odooCustomerId: number = null
  licenseUsername: string = null
  assignedBy: string = null
  unsubscribe: boolean = false
  isBrelagSupporter = false

  odooCustomerEmail: string = null

  subscriptionLength: LicenseLength = 1

  errorMessage: string = null
  agbAccepted = false
  selectedLicense: LicenseOffer = null

  success = false

  licenses: LicenseOffer[] = LICENSE_OFFERS

  selectLicense(licenseOffer: LicenseOffer) {
    this.selectedLicense = licenseOffer
    this.subscriptionLength = licenseOffer.length
  }

  async mounted() {
    const loadingComponent = this.$buefy.loading.open({ container: null })

    try {
      // Get URL query
      this.projectId = this.$route.query.project_id as string
      this.validationCode = this.$route.query.validation_code as string
      this.odooCustomerId = parseInt(this.$route.query.customer_id as string)

      this.licenseUsername = decodeURIComponent(
        this.$route.query.license_username as string
      )

      if (!this.odooCustomerId) {
        await this.loadCustomerEmailFromLicense()
      }

      if (
        this.$route.query.unsubscribe &&
        this.$route.query.unsubscribe === 'true'
      ) {
        this.unsubscribe = true
      }

      if (
        this.$route.query.is_brelag_supporter &&
        this.$route.query.is_brelag_supporter === 'true'
      ) {
        this.isBrelagSupporter = true
      }

      if (!this.projectId || !this.validationCode) {
        this.errorMessage = 'URL enthält keine Projekt Informationen'
      }
    } catch (error) {
      this.$refs.baseForm.handleError(error)
    }
    loadingComponent.close()
  }

  async loadCustomerEmailFromLicense() {
    try {
      const response = await this.$api.tryGetOdooCustomer(this.licenseUsername)
      this.odooCustomerEmail = response.data.customer_email
      if (
        this.odooCustomerId &&
        response.data.customer_id !== this.odooCustomerId
      ) {
        throw new Error(`Odoo Kunden ID ${this.odooCustomerId} nicht gültig!`)
      } else {
        this.odooCustomerId = response.data.customer_id
      }
    } catch (error) {
      // License not assigned yet
    }
  }

  async searchForCustomerId() {
    const loadingComponent = this.$buefy.loading.open({ container: null })
    try {
      this.$refs.baseForm.clearError()
      await this.loadCustomerFromEmail()
    } catch (error) {
      this.$refs.baseForm.handleError(error)
    }
    loadingComponent.close()
  }

  async loadCustomerFromEmail() {
    const response = await this.$api.tryGetOdooCustomer(
      this.licenseUsername,
      this.odooCustomerEmail
    )
    if (response.data.customer_email !== this.odooCustomerEmail) {
      throw new Error('Odoo customer_email not valid!')
    }
    this.odooCustomerId = response.data.customer_id
  }

  enterKey() {
    this.requestLicenseRenewal()
  }

  async requestLicenseRenewal() {
    const loadingComponent = this.$buefy.loading.open({ container: null })

    try {
      if (this.odooCustomerEmail && !this.odooCustomerId) {
        await this.loadCustomerFromEmail()
      }

      if (!this.odooCustomerId) {
        throw new Error('Keine Customer ID definiert!')
      }

      await this.$api.requestLicenseRenewal(
        this.projectId,
        this.validationCode,
        this.subscriptionLength,
        this.assignedBy,
        this.odooCustomerId
      )
      this.$refs.baseForm.clearError()
      this.success = true
    } catch (error) {
      this.$refs.baseForm.handleError(error)
    }
    loadingComponent.close()
  }

  async disableReminderMail() {
    const loadingComponent = this.$buefy.loading.open({ container: null })

    try {
      await KnockautProject.unsubscribeConnectAndCareReminder(
        this.$apiv2,
        this.projectId
      )
      this.$refs.baseForm.clearError()
      this.success = true
    } catch (error) {
      this.$refs.baseForm.handleError(error)
    }
    loadingComponent.close()
  }

  pricePerMonth(licenseOffer: LicenseOffer) {
    return (licenseOffer.price / licenseOffer.months).toFixed(2)
  }

  get expirationDate(): string {
    return moment(parseInt(this.validationCode) * 1000).format(
      'DD.MM.YYYY HH:mm'
    )
  }

  get canSave(): boolean {
    return (
      this.agbAccepted &&
      !!this.assignedBy &&
      (!!this.odooCustomerEmail || !!this.odooCustomerId) &&
      !!this.selectedLicense &&
      !this.errorMessage
    )
  }
}
